body {
  color: #002d63;
}

b {
  font-weight: 600;
}

.tr15deg {
  transform: rotate(15deg);
}

.tr30deg {
  transform: rotate(30deg);
}

.tr45deg {
  transform: rotate(45deg);
}

.tr60deg {
  transform: rotate(60deg);
}

.tr75deg {
  transform: rotate(75deg);
}

.tr90deg {
  transform: rotate(90deg);
}

.tr105deg {
  transform: rotate(105deg);
}

.tr120deg {
  transform: rotate(120deg);
}

.tr135deg {
  transform: rotate(135deg);
}

.tr150deg {
  transform: rotate(150deg);
}

.tr165deg {
  transform: rotate(165deg);
}

.tr180deg {
  transform: rotate(180deg);
}

.tr195deg {
  transform: rotate(195deg);
}

.tr210deg {
  transform: rotate(210deg);
}

.tr225deg {
  transform: rotate(225deg);
}

.tr240deg {
  transform: rotate(240deg);
}

.tr255deg {
  transform: rotate(255deg);
}

.tr270deg {
  transform: rotate(270deg);
}

.tr285deg {
  transform: rotate(285deg);
}

.tr300deg {
  transform: rotate(300deg);
}

.tr315deg {
  transform: rotate(315deg);
}

.tr330deg {
  transform: rotate(330deg);
}

.tr345deg {
  transform: rotate(345deg);
}

.tr360deg {
  transform: rotate(360deg);
}

::-webkit-scrollbar-track {
  background-color: #4a67ff44;
  border: 3px solid #fff;
}

::-webkit-scrollbar {
  background-color: #fff;
  border-radius: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #fc7785;
  border-radius: 10px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

.bg-gradient {
  background: -webkit-linear-gradient(45deg, #002d63, #4a67ff);
}

.bg-gradient2 {
  background: -webkit-linear-gradient(45deg, #3956f0, #8496f4);
}

.bg-none {
  background: none;
}

.text-gradient {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(45deg, #002d63, #4a67ff);
  -webkit-background-clip: text;
}

.gradient-shadow:before {
  content: "";
  filter: blur(20px);
  opacity: .7;
  border-radius: inherit;
  background: linear-gradient(-45deg, #4a67ff 0%, #fc7785 100%);
  transition: opacity .3s;
  position: absolute;
  inset: 0;
  transform: translate3d(0, 0, 0)scale(1.05);
}

@font-face {
  font-family: Kanit;
  font-style: normal;
  src: url("kanit_thin.e071594f.ttf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Kanit;
  font-style: normal;
  src: url("kanit_extra_light.07685a06.ttf");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Kanit;
  font-style: normal;
  src: url("kanit_light.9ff7d785.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Kanit;
  font-style: normal;
  src: url("kanit_regular.6288591b.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Kanit;
  font-style: normal;
  src: url("kanit_medium.0c257994.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Kanit;
  font-style: normal;
  src: url("kanit_semi_bold.99595f08.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Kanit;
  font-style: normal;
  src: url("kanit_bold.d1949ef2.ttf");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  src: url("poppins_thin.533f7825.ttf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  src: url("poppins_extra_light.5782d82e.ttf");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  src: url("poppins_light.ace0ebd3.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  src: url("poppins_regular.d5d72893.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  src: url("poppins_medium.99ed75b1.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  src: url("poppins_semi_bold.c0f07c7f.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  src: url("poppins_bold.23054c2a.ttf");
  font-weight: 700;
  font-display: swap;
}

*, .poppins-font-style {
  margin: 0;
  font-family: Poppins, sans-serif;
}

h1, h2, h3, h4, h5, h6, p, span, li, strong, input, textarea, select, a, i {
  align-self: center;
}

a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

input, textarea, select, button {
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: 1rem;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

hr {
  border-style: solid;
}

.fi {
  filter: invert();
}

.backdrop {
  backdrop-filter: brightness(.5) blur(5px) drop-shadow(0 4px 8px #000);
  height: 100vh;
}

.backdrop.td100.zi5 {
  height: auto;
}

.fa-secondary {
  opacity: .4;
}

.wsn {
  white-space: nowrap;
}

.bsbb {
  box-sizing: border-box;
}

.cp {
  cursor: pointer;
}

.cg {
  cursor: grab;
}

.pen {
  pointer-events: none;
}

.pea {
  pointer-events: auto;
}

.tdu {
  text-decoration: underline;
}

.ttu {
  text-transform: uppercase;
}

.ttc {
  text-transform: capitalize;
}

.tti {
  text-transform: initial;
}

.usn {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.brnr {
  background-repeat: no-repeat;
}

.oh {
  overflow: hidden;
}

.oxh {
  overflow-x: hidden;
}

.oyh {
  overflow-y: hidden;
}

.ov {
  overflow: visible;
}

.oxv {
  overflow-x: visible;
}

.oyv {
  overflow-y: visible;
}

.vv {
  visibility: visible;
}

.op1 {
  opacity: 1;
}

.oa {
  overflow: auto;
}

.oxa {
  overflow-x: auto;
}

.oya {
  overflow-y: auto;
}

.bpl {
  background-position: 0;
}

.bpc {
  background-position: center;
}

.bpr {
  background-position: 100%;
}

.bscov {
  background-size: cover;
}

.bscon {
  background-size: contain;
}

.opc {
  object-position: center;
}

.ofcov {
  object-fit: cover;
}

.ofcon {
  object-fit: contain;
}

.tpa {
  transition-property: all;
}

.tph {
  transition-property: height;
}

.tpw {
  transition-property: width;
}

.tpo {
  transition-property: opacity;
}

.tpb {
  transition-property: background;
}

.tslp {
  transition: opacity .5s, transform .9s;
}

.tty0 {
  transform: translateY(0);
}

.ttx20 {
  transform: translateY(20px);
}

.wfull {
  width: 100%;
}

.ar {
  animation: 10s linear infinite rotate;
}

.da-cursor {
  cursor: not-allowed !important;
}

.zi-1 {
  z-index: -1;
}

.zi1 {
  z-index: 1;
}

.zi-2 {
  z-index: -2;
}

.zi2 {
  z-index: 2;
}

.zi-3 {
  z-index: -3;
}

.zi3 {
  z-index: 3;
}

.zi-4 {
  z-index: -4;
}

.zi4 {
  z-index: 4;
}

.zi-5 {
  z-index: -5;
}

.zi5 {
  z-index: 5;
}

.zi-6 {
  z-index: -6;
}

.zi6 {
  z-index: 6;
}

.zi-7 {
  z-index: -7;
}

.zi7 {
  z-index: 7;
}

.zi-8 {
  z-index: -8;
}

.zi8 {
  z-index: 8;
}

.zi-9 {
  z-index: -9;
}

.zi9 {
  z-index: 9;
}

.zi-10 {
  z-index: -10;
}

.zi10 {
  z-index: 10;
}

.zi-11 {
  z-index: -11;
}

.zi11 {
  z-index: 11;
}

.zi-12 {
  z-index: -12;
}

.zi12 {
  z-index: 12;
}

.zi-13 {
  z-index: -13;
}

.zi13 {
  z-index: 13;
}

.zi-14 {
  z-index: -14;
}

.zi14 {
  z-index: 14;
}

.zi-15 {
  z-index: -15;
}

.zi15 {
  z-index: 15;
}

.zi-16 {
  z-index: -16;
}

.zi16 {
  z-index: 16;
}

.zi-17 {
  z-index: -17;
}

.zi17 {
  z-index: 17;
}

.zi-18 {
  z-index: -18;
}

.zi18 {
  z-index: 18;
}

.zi-19 {
  z-index: -19;
}

.zi19 {
  z-index: 19;
}

.zi-20 {
  z-index: -20;
}

.zi20 {
  z-index: 20;
}

.td50 {
  transition-duration: 50ms;
}

.td100 {
  -o-transition-duration: .1s;
  transition-duration: .1s;
}

.td300 {
  -o-transition-duration: .3s;
  transition-duration: .3s;
}

.td150 {
  transition-duration: .15s;
}

.td200 {
  transition-duration: .2s;
}

.td250 {
  transition-duration: .25s;
}

.td300 {
  transition-duration: .3s;
}

.td350 {
  transition-duration: .35s;
}

.td400 {
  transition-duration: .4s;
}

.td450 {
  transition-duration: .45s;
}

.td500 {
  transition-duration: .5s;
}

.td550 {
  transition-duration: .55s;
}

.td600 {
  transition-duration: .6s;
}

.td650 {
  transition-duration: .65s;
}

.td700 {
  transition-duration: .7s;
}

.td750 {
  transition-duration: .75s;
}

.td800 {
  transition-duration: .8s;
}

.td850 {
  transition-duration: .85s;
}

.td900 {
  transition-duration: .9s;
}

.td950 {
  transition-duration: .95s;
}

.td1000 {
  transition-duration: 1s;
}

.bsd {
  border-style: dashed;
}

.bss {
  border-style: solid;
}

.bs0px {
  border: 0 solid;
}

.bw0px {
  border-width: 0;
}

.btw0px {
  border-top-width: 0;
}

.brw0px {
  border-right-width: 0;
}

.bbw0px {
  border-bottom-width: 0;
}

.blw0px {
  border-left-width: 0;
}

.bs1px {
  border: 1px solid;
}

.bw1px {
  border-width: 1px;
}

.btw1px {
  border-top-width: 1px;
}

.bbw1px {
  border-bottom-width: 1px;
}

.blw1px {
  border-left-width: 1px;
}

.bs2px {
  border: 2px solid;
}

.bw2px {
  border-width: 2px;
}

.btw2px {
  border-top-width: 2px;
}

.bbw2px {
  border-bottom-width: 2px;
}

.blw2px {
  border-left-width: 2px;
}

.bs3px {
  border: 3px solid;
}

.bw3px {
  border-width: 3px;
}

.btw3px {
  border-top-width: 3px;
}

.brw3px {
  border-right-width: 3px;
}

.bbw3px {
  border-bottom-width: 3px;
}

.blw3px {
  border-left-width: 3px;
}

.bs4px {
  border: 4px solid;
}

.bw4px {
  border-width: 4px;
}

.btw4px {
  border-top-width: 4px;
}

.brw4px {
  border-right-width: 4px;
}

.bbw4px {
  border-bottom-width: 4px;
}

.blw4px {
  border-left-width: 4px;
}

.bs5px {
  border: 5px solid;
}

.bw5px {
  border-width: 5px;
}

.btw5px {
  border-top-width: 5px;
}

.brw5px {
  border-right-width: 5px;
}

.bbw5px {
  border-bottom-width: 5px;
}

.blw5px {
  border-left-width: 5px;
}

.bs6px {
  border: 6px solid;
}

.bw6px {
  border-width: 6px;
}

.btw6px {
  border-top-width: 6px;
}

.brw6px {
  border-right-width: 6px;
}

.bbw6px {
  border-bottom-width: 6px;
}

.blw6px {
  border-left-width: 6px;
}

.bs7px {
  border: 7px solid;
}

.bw7px {
  border-width: 7px;
}

.btw7px {
  border-top-width: 7px;
}

.brw7px {
  border-right-width: 7px;
}

.bbw7px {
  border-bottom-width: 7px;
}

.blw7px {
  border-left-width: 7px;
}

.bs8px {
  border: 8px solid;
}

.bw8px {
  border-width: 8px;
}

.btw8px {
  border-top-width: 8px;
}

.brw8px {
  border-right-width: 8px;
}

.bbw8px {
  border-bottom-width: 8px;
}

.blw8px {
  border-left-width: 8px;
}

.bs9px {
  border: 9px solid;
}

.bw9px {
  border-width: 9px;
}

.btw9px {
  border-top-width: 9px;
}

.brw9px {
  border-right-width: 9px;
}

.bbw9px {
  border-bottom-width: 9px;
}

.blw9px {
  border-left-width: 9px;
}

.fw100 {
  font-weight: 100;
}

.fw200 {
  font-weight: 200;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.fw900 {
  font-weight: 900;
}

.lh0 {
  line-height: 0;
}

.lh1 {
  line-height: 1;
}

.lh2 {
  line-height: 1.25;
}

.lh3 {
  line-height: 1.5;
}

.lh4 {
  line-height: 1.75;
}

.lh5 {
  line-height: 2;
}

.op0 {
  opacity: 0;
}

.op5 {
  opacity: .05;
}

.op10 {
  opacity: .1;
}

.op15 {
  opacity: .15;
}

.op20 {
  opacity: .2;
}

.op25 {
  opacity: .25;
}

.op30 {
  opacity: .3;
}

.op35 {
  opacity: .35;
}

.op40 {
  opacity: .4;
}

.op45 {
  opacity: .45;
}

.op50 {
  opacity: .5;
}

.op55 {
  opacity: .55;
}

.op60 {
  opacity: .6;
}

.op65 {
  opacity: .65;
}

.op75 {
  opacity: .75;
}

.op80 {
  opacity: .8;
}

.op85 {
  opacity: .85;
}

.op90 {
  opacity: .9;
}

.op95 {
  opacity: .95;
}

.op100 {
  opacity: 1;
}

.container {
  box-sizing: border-box;
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center .center-vertically {
  top: 50%;
  transform: translateY(-50%);
}

.center-horizontally {
  left: 50%;
  transform: translateX(-50%);
}

.center-vertically {
  transform: translateY(-50%);
  -webkit-transform: -webkit-translateY(-50%);
  -moz-transform: -moz-translateY(-50%);
  -o-transform: -o-translateY(-50%);
  top: 50%;
}

.filing-assistant-file {
  color: #7fa5a9;
  background-color: #7fa5a9;
}

.filing-assistant-file:before {
  background-color: #7fa5a9;
}

.sharepoint-file {
  color: #038589;
  background-color: #038589;
}

.sharepoint-file:before {
  background-color: #038589;
}

.dropbox-file {
  color: #0160ff;
  background-color: #0160ff;
}

.dropbox-file:before {
  background-color: #0160ff;
}

.gdrive-file {
  color: #f2da00;
  background-color: #f2da00;
}

.gdrive-file:before {
  background-color: #f2da00;
}

.upload-file {
  color: #fb7784;
  background-color: #fb7784;
}

.upload-file:before {
  background-color: #fb7784;
}

.prepopulated-file {
  color: #007ee5;
  background-color: #007ee5;
}

.prepopulated-file:before {
  background-color: #007ee5;
}

.right-img-area {
  height: 549px !important;
  margin-top: 217px !important;
}

.calcluter-sec {
  width: 1487px;
  margin: 0 auto;
}

.font-poppins {
  font-family: Poppins;
}

.c1, .c1-hover:hover {
  color: #fff;
}

.bg1, .bg1-hover:hover {
  background-color: #fff;
}

.bo1, .bo1-hover:hover {
  border-color: #fff;
}

.f1, .f1-hover:hover {
  fill: #fff;
}

.c2, .c2-hover:hover {
  color: #000;
}

.bg2, .bg2-hover:hover {
  background-color: #000;
}

.bo2, .bo2-hover:hover {
  border-color: #000;
}

.f2, .f2-hover:hover {
  fill: #000;
}

.c3, .c3-hover:hover {
  color: #4a67ff;
}

.bg3, .bg3-hover:hover {
  background-color: #4a67ff;
}

.bo3, .bo3-hover:hover {
  border-color: #4a67ff;
}

.f3, .f3-hover:hover {
  fill: #4a67ff;
}

.c4, .c4-hover:hover {
  color: #fc7785;
}

.bg4, .bg4-hover:hover {
  background-color: #fc7785;
}

.bo4, .bo4-hover:hover {
  border-color: #fc7785;
}

.f4, .f4-hover:hover {
  fill: #fc7785;
}

.c5, .c5-hover:hover {
  color: #f0f0f8;
}

.bg5, .bg5-hover:hover {
  background-color: #f0f0f8;
}

.bo5, .bo5-hover:hover {
  border-color: #f0f0f8;
}

.f5, .f5-hover:hover {
  fill: #f0f0f8;
}

.c6, .c6-hover:hover {
  color: #002d63;
}

.bg6, .bg6-hover:hover {
  background-color: #002d63;
}

.bo6, .bo6-hover:hover {
  border-color: #002d63;
}

.f6, .f6-hover:hover {
  fill: #002d63;
}

.c7, .c7-hover:hover {
  color: #40dda1;
}

.bg7, .bg7-hover:hover {
  background-color: #40dda1;
}

.bo7, .bo7-hover:hover {
  border-color: #40dda1;
}

.f7, .f7-hover:hover {
  fill: #40dda1;
}

.c8, .c8-hover:hover {
  color: #ddb640;
}

.bg8, .bg8-hover:hover {
  background-color: #ddb640;
}

.bo8, .bo8-hover:hover {
  border-color: #ddb640;
}

.f8, .f8-hover:hover {
  fill: #ddb640;
}

.c9, .c9-hover:hover {
  color: #dd4040;
}

.bg9, .bg9-hover:hover {
  background-color: #dd4040;
}

.bo9, .bo9-hover:hover {
  border-color: #dd4040;
}

.f9, .f9-hover:hover {
  fill: #dd4040;
}

.c10, .c10-hover:hover {
  color: #038589;
}

.bg10, .bg10-hover:hover {
  background-color: #038589;
}

.bo10, .bo10-hover:hover {
  border-color: #038589;
}

.f10, .f10-hover:hover {
  fill: #038589;
}

.c11, .c11-hover:hover {
  color: #007ee5;
}

.bg11, .bg11-hover:hover {
  background-color: #007ee5;
}

.bo11, .bo11-hover:hover {
  border-color: #007ee5;
}

.f11, .f11-hover:hover {
  fill: #007ee5;
}

.c12, .c12-hover:hover {
  color: #969696;
}

.bg12, .bg12-hover:hover {
  background-color: #969696;
}

.bo12, .bo12-hover:hover {
  border-color: #969696;
}

.f12, .f12-hover:hover {
  fill: #969696;
}

.c13, .c13-hover:hover {
  color: #fb7a27;
}

.bg13, .bg13-hover:hover {
  background-color: #fb7a27;
}

.bo13, .bo13-hover:hover {
  border-color: #fb7a27;
}

.f13, .f13-hover:hover {
  fill: #fb7a27;
}

.c14, .c14-hover:hover {
  color: #505050;
}

.bg14, .bg14-hover:hover {
  background-color: #505050;
}

.bo14, .bo14-hover:hover {
  border-color: #505050;
}

.f14, .f14-hover:hover {
  fill: #505050;
}

.c15, .c15-hover:hover {
  color: #151313;
}

.bg15, .bg15-hover:hover {
  background-color: #151313;
}

.bo15, .bo15-hover:hover {
  border-color: #151313;
}

.f15, .f15-hover:hover {
  fill: #151313;
}

.c16, .c16-hover:hover {
  color: #d9d9d9;
}

.bg16, .bg16-hover:hover {
  background-color: #d9d9d9;
}

.bo16, .bo16-hover:hover {
  border-color: #d9d9d9;
}

.f16, .f16-hover:hover {
  fill: #d9d9d9;
}

.c17, .c17-hover:hover {
  color: #d3d9fc;
}

.bg17, .bg17-hover:hover {
  background-color: #d3d9fc;
}

.bo17, .bo17-hover:hover {
  border-color: #d3d9fc;
}

.f17, .f17-hover:hover {
  fill: #d3d9fc;
}

.c18, .c18-hover:hover {
  color: #6775b9;
}

.bg18, .bg18-hover:hover {
  background-color: #6775b9;
}

.bo18, .bo18-hover:hover {
  border-color: #6775b9;
}

.f18, .f18-hover:hover {
  fill: #6775b9;
}

.c19, .c19-hover:hover {
  color: #f5f6f9;
}

.bg19, .bg19-hover:hover {
  background-color: #f5f6f9;
}

.bo19, .bo19-hover:hover {
  border-color: #f5f6f9;
}

.f19, .f19-hover:hover {
  fill: #f5f6f9;
}

.c20, .c20-hover:hover {
  color: #f0f2fd;
}

.bg20, .bg20-hover:hover {
  background-color: #f0f2fd;
}

.bo20, .bo20-hover:hover {
  border-color: #f0f2fd;
}

.f20, .f20-hover:hover {
  fill: #f0f2fd;
}

.c21, .c21-hover:hover {
  color: #f9f9f9;
}

.bg21, .bg21-hover:hover {
  background-color: #f9f9f9;
}

.bo21, .bo21-hover:hover {
  border-color: #f9f9f9;
}

.f21, .f21-hover:hover {
  fill: #f9f9f9;
}

.c22, .c22-hover:hover {
  color: #f6f6f6;
}

.bg22, .bg22-hover:hover {
  background-color: #f6f6f6;
}

.bo22, .bo22-hover:hover {
  border-color: #f6f6f6;
}

.f22, .f22-hover:hover {
  fill: #f6f6f6;
}

.c23, .c23-hover:hover {
  color: #fd6474;
}

.bg23, .bg23-hover:hover {
  background-color: #fd6474;
}

.bo23, .bo23-hover:hover {
  border-color: #fd6474;
}

.f23, .f23-hover:hover {
  fill: #fd6474;
}

.c24, .c24-hover:hover {
  color: #efefef;
}

.bg24, .bg24-hover:hover {
  background-color: #efefef;
}

.bo24, .bo24-hover:hover {
  border-color: #efefef;
}

.f24, .f24-hover:hover {
  fill: #efefef;
}

.c25, .c25-hover:hover {
  color: #f8f8f8;
}

.bg25, .bg25-hover:hover {
  background-color: #f8f8f8;
}

.bo25, .bo25-hover:hover {
  border-color: #f8f8f8;
}

.f25, .f25-hover:hover {
  fill: #f8f8f8;
}

.c26, .c26-hover:hover {
  color: #0126ed;
}

.bg26, .bg26-hover:hover {
  background-color: #0126ed;
}

.bo26, .bo26-hover:hover {
  border-color: #0126ed;
}

.f26, .f26-hover:hover {
  fill: #0126ed;
}

.c27, .c27-hover:hover {
  color: #5e5f66;
}

.bg27, .bg27-hover:hover {
  background-color: #5e5f66;
}

.bo27, .bo27-hover:hover {
  border-color: #5e5f66;
}

.f27, .f27-hover:hover {
  fill: #5e5f66;
}

.c28, .c28-hover:hover {
  color: #aaacb5;
}

.bg28, .bg28-hover:hover {
  background-color: #aaacb5;
}

.bo28, .bo28-hover:hover {
  border-color: #aaacb5;
}

.f28, .f28-hover:hover {
  fill: #aaacb5;
}

.c29, .c29-hover:hover {
  color: #dde2f2;
}

.bg29, .bg29-hover:hover {
  background-color: #dde2f2;
}

.bo29, .bo29-hover:hover {
  border-color: #dde2f2;
}

.f29, .f29-hover:hover {
  fill: #dde2f2;
}

.c30, .c30-hover:hover {
  color: #09b255;
}

.bg30, .bg30-hover:hover {
  background-color: #09b255;
}

.bo30, .bo30-hover:hover {
  border-color: #09b255;
}

.f30, .f30-hover:hover {
  fill: #09b255;
}

.c31, .c31-hover:hover {
  color: #dbdfee;
}

.bg31, .bg31-hover:hover {
  background-color: #dbdfee;
}

.bo31, .bo31-hover:hover {
  border-color: #dbdfee;
}

.f31, .f31-hover:hover {
  fill: #dbdfee;
}

.c32, .c32-hover:hover {
  color: #737171;
}

.bg32, .bg32-hover:hover {
  background-color: #737171;
}

.bo32, .bo32-hover:hover {
  border-color: #737171;
}

.f32, .f32-hover:hover {
  fill: #737171;
}

.c33, .c33-hover:hover {
  color: #767b90;
}

.bg33, .bg33-hover:hover {
  background-color: #767b90;
}

.bo33, .bo33-hover:hover {
  border-color: #767b90;
}

.f33, .f33-hover:hover {
  fill: #767b90;
}

.c34, .c34-hover:hover {
  color: #15d757;
}

.bg34, .bg34-hover:hover {
  background-color: #15d757;
}

.bo34, .bo34-hover:hover {
  border-color: #15d757;
}

.f34, .f34-hover:hover {
  fill: #15d757;
}

.c35, .c35-hover:hover {
  color: #e8ebf5;
}

.bg35, .bg35-hover:hover {
  background-color: #e8ebf5;
}

.bo35, .bo35-hover:hover {
  border-color: #e8ebf5;
}

.f35, .f35-hover:hover {
  fill: #e8ebf5;
}

.c36, .c36-hover:hover {
  color: #4c6b92;
}

.bg36, .bg36-hover:hover {
  background-color: #4c6b92;
}

.bo36, .bo36-hover:hover {
  border-color: #4c6b92;
}

.f36, .f36-hover:hover {
  fill: #4c6b92;
}

.c37, .c37-hover:hover {
  color: #818181;
}

.bg37, .bg37-hover:hover {
  background-color: #818181;
}

.bo37, .bo37-hover:hover {
  border-color: #818181;
}

.f37, .f37-hover:hover {
  fill: #818181;
}

.c38, .c38-hover:hover {
  color: #f0f2fd;
}

.bg38, .bg38-hover:hover {
  background-color: #f0f2fd;
}

.bo38, .bo38-hover:hover {
  border-color: #f0f2fd;
}

.f38, .f38-hover:hover {
  fill: #f0f2fd;
}

.c39, .c39-hover:hover {
  color: #f99229;
}

.bg39, .bg39-hover:hover {
  background-color: #f99229;
}

.bo39, .bo39-hover:hover {
  border-color: #f99229;
}

.f39, .f39-hover:hover {
  fill: #f99229;
}

.c40, .c40-hover:hover {
  color: #2947ef;
}

.bg40, .bg40-hover:hover {
  background-color: #2947ef;
}

.bo40, .bo40-hover:hover {
  border-color: #2947ef;
}

.f40, .f40-hover:hover {
  fill: #2947ef;
}

.c41, .c41-hover:hover {
  color: #f5f6ff;
}

.bg41, .bg41-hover:hover {
  background-color: #f5f6ff;
}

.bo41, .bo41-hover:hover {
  border-color: #f5f6ff;
}

.f41, .f41-hover:hover {
  fill: #f5f6ff;
}

.tal {
  text-align: left;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.taj {
  text-align: justify;
}

.tas {
  text-align: start;
}

.tae {
  text-align: end;
}

.fs0 {
  font-size: 0;
}

.fs0-25rem {
  font-size: .25rem;
}

.fs0-125rem {
  font-size: .125rem;
}

.fs0-5rem {
  font-size: .5rem;
}

.fs0-75rem {
  font-size: .75rem;
}

.fs0-875rem {
  font-size: .875rem;
}

.fs1rem {
  font-size: 1rem;
}

.fs1-25rem {
  font-size: 1.25rem;
}

.fs1-125rem {
  font-size: 1.125rem;
}

.fs1-5rem {
  font-size: 1.5rem;
}

.fs1-75rem {
  font-size: 1.75rem;
}

.fs1-875rem {
  font-size: 1.875rem;
}

.fs2rem {
  font-size: 2rem;
}

.fs2-25rem {
  font-size: 2.25rem;
}

.fs2-125rem {
  font-size: 2.125rem;
}

.fs2-5rem {
  font-size: 2.5rem;
}

.fs2-75rem {
  font-size: 2.75rem;
}

.fs2-875rem {
  font-size: 2.875rem;
}

.fs3rem {
  font-size: 3rem;
}

.fs3-25rem {
  font-size: 3.25rem;
}

.fs3-125rem {
  font-size: 3.125rem;
}

.fs3-5rem {
  font-size: 3.5rem;
}

.fs3-75rem {
  font-size: 3.75rem;
}

.fs3-875rem {
  font-size: 3.875rem;
}

.fs4rem {
  font-size: 4rem;
}

.fs4-25rem {
  font-size: 4.25rem;
}

.fs4-125rem {
  font-size: 4.125rem;
}

.fs4-5rem {
  font-size: 4.5rem;
}

.fs4-75rem {
  font-size: 4.75rem;
}

.fs4-875rem {
  font-size: 4.875rem;
}

.fs5rem {
  font-size: 5rem;
}

.fs5-25rem {
  font-size: 5.25rem;
}

.fs5-125rem {
  font-size: 5.125rem;
}

.fs5-5rem {
  font-size: 5.5rem;
}

.fs5-75rem {
  font-size: 5.75rem;
}

.fs5-875rem {
  font-size: 5.875rem;
}

.fs6rem {
  font-size: 6rem;
}

.fs6-25rem {
  font-size: 6.25rem;
}

.fs6-125rem {
  font-size: 6.125rem;
}

.fs6-5rem {
  font-size: 6.5rem;
}

.fs6-75rem {
  font-size: 6.75rem;
}

.fs6-875rem {
  font-size: 6.875rem;
}

.fs7rem {
  font-size: 7rem;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
}

.di {
  display: inline;
}

.df {
  display: flex;
}

.fdc {
  flex-direction: column;
}

.fdr {
  flex-direction: row;
}

.fdcr {
  flex-direction: column-reverse;
}

.fdrr {
  flex-direction: row-reverse;
}

.fww {
  flex-wrap: wrap;
}

.jcfs {
  justify-content: flex-start;
}

.jcc {
  justify-content: center;
}

.jcfe {
  justify-content: flex-end;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.jcse {
  justify-content: space-evenly;
}

.jce {
  justify-content: end;
}

.aifs {
  align-items: flex-start;
}

.aic {
  align-items: center;
}

.aife {
  align-items: flex-end;
}

.jss {
  justify-self: start;
}

.jsfs {
  justify-self: flex-start;
}

.jsc {
  justify-self: center;
}

.jsfe {
  justify-self: flex-end;
}

.jse {
  justify-self: end;
}

.ass {
  align-self: start;
}

.asfs {
  align-self: flex-start;
}

.asc {
  align-self: center;
}

.asfe {
  align-self: flex-end;
}

.ase {
  align-self: end;
}

.dif {
  display: inline-flex;
}

.dg {
  display: grid;
}

.brss {
  border-right-style: solid;
}

.brw1px {
  border-right-width: 1px;
}

.brw2px {
  border-right-width: 2px;
}

.gtcr1 {
  grid-template-columns: repeat(1, 1fr);
}

.ftcr1 {
  grid-column: 1 / 1fr;
}

.gtrr1 {
  grid-template-rows: repeat(1, 1fr);
}

.gtcr1a {
  grid-template-columns: repeat(1, auto);
}

.gtrr1a {
  grid-template-rows: repeat(1, auto);
}

.gcs1 {
  grid-column-start: 1;
}

.gce2 {
  grid-column-end: 2;
}

.grs1 {
  grid-row-start: 1;
}

.gre2 {
  grid-row-end: 2;
}

.gtcr2 {
  grid-template-columns: repeat(2, 1fr);
}

.ftcr2 {
  grid-column: 2 / 1fr;
}

.gtrr2 {
  grid-template-rows: repeat(2, 1fr);
}

.gtcr2a {
  grid-template-columns: repeat(2, auto);
}

.gtrr2a {
  grid-template-rows: repeat(2, auto);
}

.gcs2 {
  grid-column-start: 2;
}

.gce3 {
  grid-column-end: 3;
}

.grs2 {
  grid-row-start: 2;
}

.gre3 {
  grid-row-end: 3;
}

.gtcr3 {
  grid-template-columns: repeat(3, 1fr);
}

.ftcr3 {
  grid-column: 3 / 1fr;
}

.gtrr3 {
  grid-template-rows: repeat(3, 1fr);
}

.gtcr3a {
  grid-template-columns: repeat(3, auto);
}

.gtrr3a {
  grid-template-rows: repeat(3, auto);
}

.gcs3 {
  grid-column-start: 3;
}

.gce4 {
  grid-column-end: 4;
}

.grs3 {
  grid-row-start: 3;
}

.gre4 {
  grid-row-end: 4;
}

.gtcr4 {
  grid-template-columns: repeat(4, 1fr);
}

.ftcr4 {
  grid-column: 4 / 1fr;
}

.gtrr4 {
  grid-template-rows: repeat(4, 1fr);
}

.gtcr4a {
  grid-template-columns: repeat(4, auto);
}

.gtrr4a {
  grid-template-rows: repeat(4, auto);
}

.gcs4 {
  grid-column-start: 4;
}

.gce5 {
  grid-column-end: 5;
}

.grs4 {
  grid-row-start: 4;
}

.gre5 {
  grid-row-end: 5;
}

.gtcr5 {
  grid-template-columns: repeat(5, 1fr);
}

.ftcr5 {
  grid-column: 5 / 1fr;
}

.gtrr5 {
  grid-template-rows: repeat(5, 1fr);
}

.gtcr5a {
  grid-template-columns: repeat(5, auto);
}

.gtrr5a {
  grid-template-rows: repeat(5, auto);
}

.gcs5 {
  grid-column-start: 5;
}

.gce6 {
  grid-column-end: 6;
}

.grs5 {
  grid-row-start: 5;
}

.gre6 {
  grid-row-end: 6;
}

.gtcr6 {
  grid-template-columns: repeat(6, 1fr);
}

.ftcr6 {
  grid-column: 6 / 1fr;
}

.gtrr6 {
  grid-template-rows: repeat(6, 1fr);
}

.gtcr6a {
  grid-template-columns: repeat(6, auto);
}

.gtrr6a {
  grid-template-rows: repeat(6, auto);
}

.gcs6 {
  grid-column-start: 6;
}

.gce7 {
  grid-column-end: 7;
}

.grs6 {
  grid-row-start: 6;
}

.gre7 {
  grid-row-end: 7;
}

.gtcr7 {
  grid-template-columns: repeat(7, 1fr);
}

.ftcr7 {
  grid-column: 7 / 1fr;
}

.gtrr7 {
  grid-template-rows: repeat(7, 1fr);
}

.gtcr7a {
  grid-template-columns: repeat(7, auto);
}

.gtrr7a {
  grid-template-rows: repeat(7, auto);
}

.gcs7 {
  grid-column-start: 7;
}

.gce8 {
  grid-column-end: 8;
}

.grs7 {
  grid-row-start: 7;
}

.gre8 {
  grid-row-end: 8;
}

.gtcr8 {
  grid-template-columns: repeat(8, 1fr);
}

.ftcr8 {
  grid-column: 8 / 1fr;
}

.gtrr8 {
  grid-template-rows: repeat(8, 1fr);
}

.gtcr8a {
  grid-template-columns: repeat(8, auto);
}

.gtrr8a {
  grid-template-rows: repeat(8, auto);
}

.gcs8 {
  grid-column-start: 8;
}

.gce9 {
  grid-column-end: 9;
}

.grs8 {
  grid-row-start: 8;
}

.gre9 {
  grid-row-end: 9;
}

.gtcr9 {
  grid-template-columns: repeat(9, 1fr);
}

.ftcr9 {
  grid-column: 9 / 1fr;
}

.gtrr9 {
  grid-template-rows: repeat(9, 1fr);
}

.gtcr9a {
  grid-template-columns: repeat(9, auto);
}

.gtrr9a {
  grid-template-rows: repeat(9, auto);
}

.gcs9 {
  grid-column-start: 9;
}

.gce10 {
  grid-column-end: 10;
}

.grs9 {
  grid-row-start: 9;
}

.gre10 {
  grid-row-end: 10;
}

.gtccr {
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}

.gtcr10 {
  grid-template-columns: repeat(10, 1fr);
}

.ftcr10 {
  grid-column: 10 / 1fr;
}

.gtrr10 {
  grid-template-rows: repeat(10, 1fr);
}

.gtcr10a {
  grid-template-columns: repeat(10, auto);
}

.gtrr10a {
  grid-template-rows: repeat(10, auto);
}

.gcs10 {
  grid-column-start: 10;
}

.gce11 {
  grid-column-end: 11;
}

.grs10 {
  grid-row-start: 10;
}

.gre11 {
  grid-row-end: 11;
}

.gg0-5rem {
  grid-gap: .5rem;
}

.gg1rem {
  grid-gap: 1rem;
}

.rg0-5rem {
  row-gap: .5rem;
}

.rg1rem {
  row-gap: 1rem;
}

.cg0-5rem {
  column-gap: .5rem;
}

.cg1rem {
  column-gap: 1rem;
}

.gg1-5rem {
  grid-gap: 1.5rem;
}

.gg2rem {
  grid-gap: 2rem;
}

.rg1-5rem {
  row-gap: 1.5rem;
}

.rg2rem {
  row-gap: 2rem;
}

.cg1-5rem {
  column-gap: 1.5rem;
}

.cg2rem {
  column-gap: 2rem;
}

.gg2-5rem {
  grid-gap: 2.5rem;
}

.gg3rem {
  grid-gap: 3rem;
}

.rg2-5rem {
  row-gap: 2.5rem;
}

.rg3rem {
  row-gap: 3rem;
}

.cg2-5rem {
  column-gap: 2.5rem;
}

.cg3rem {
  column-gap: 3rem;
}

.gg3-5rem {
  grid-gap: 3.5rem;
}

.gg4rem {
  grid-gap: 4rem;
}

.rg3-5rem {
  row-gap: 3.5rem;
}

.rg4rem {
  row-gap: 4rem;
}

.cg3-5rem {
  column-gap: 3.5rem;
}

.cg4rem {
  column-gap: 4rem;
}

.gg4-5rem {
  grid-gap: 4.5rem;
}

.gg5rem {
  grid-gap: 5rem;
}

.rg4-5rem {
  row-gap: 4.5rem;
}

.rg5rem {
  row-gap: 5rem;
}

.cg4-5rem {
  column-gap: 4.5rem;
}

.cg5rem {
  column-gap: 5rem;
}

.dn {
  display: none;
}

.pa {
  position: absolute;
}

.pr {
  position: relative;
}

.pf {
  position: fixed;
}

.ps {
  -webkit-position: sticky;
  position: sticky;
}

.pst {
  position: static;
}

.t0 {
  top: 0;
}

.r0 {
  right: 0;
}

.b0 {
  bottom: 0;
}

.l0 {
  left: 0;
}

.t100 {
  top: 100%;
}

.r100 {
  right: 100%;
}

.b100 {
  bottom: 100%;
}

.l100 {
  left: 100%;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.fn {
  float: none;
}

.ma {
  margin: auto;
}

.mta, .mya {
  margin-top: auto;
}

.mra, .mxa {
  margin-right: auto;
}

.mba, .mya {
  margin-bottom: auto;
}

.mla, .mxa {
  margin-left: auto;
}

.m0 {
  margin: 0;
}

.mt0, .my0 {
  margin-top: 0;
}

.mr0, .mx0 {
  margin-right: 0;
}

.mb0, .my0 {
  margin-bottom: 0;
}

.ml0, .mx0 {
  margin-left: 0;
}

.p0 {
  padding: 0;
}

.pt0, .py0 {
  padding-top: 0;
}

.pr0, .px0 {
  padding-right: 0;
}

.pb0, .py0 {
  padding-bottom: 0;
}

.pl0, .px0 {
  padding-left: 0;
}

.m10 {
  margin: 10%;
}

.mt10, .my10 {
  margin-top: 10%;
}

.mr10, .mx10 {
  margin-right: 10%;
}

.mb10, .my10 {
  margin-bottom: 10%;
}

.ml10, .mx10 {
  margin-left: 10%;
}

.p10 {
  padding: 10%;
}

.pt10, .py10 {
  padding-top: 10%;
}

.pr10, .px10 {
  padding-right: 10%;
}

.pb10, .py10 {
  padding-bottom: 10%;
}

.pl10, .px10 {
  padding-left: 10%;
}

.m0-5rem {
  margin: .5rem;
}

.m1rem {
  margin: 1rem;
}

.mt0-5rem, .my0-5rem {
  margin-top: .5rem;
}

.mt1rem, .my1rem {
  margin-top: 1rem;
}

.mr0-5rem, .mx0-5rem {
  margin-right: .5rem;
}

.mr1rem, .mx1rem {
  margin-right: 1rem;
}

.mb0-5rem, .my0-5rem {
  margin-bottom: .5rem;
}

.mb1rem, .my1rem {
  margin-bottom: 1rem;
}

.ml0-5rem, .mx0-5rem {
  margin-left: .5rem;
}

.ml1rem, .mx1rem {
  margin-left: 1rem;
}

.p1rem {
  padding: 1rem;
}

.pt0-5rem, .py0-5rem {
  padding-top: .5rem;
}

.pt1rem, .py1rem {
  padding-top: 1rem;
}

.pr0-5rem, .px0-5rem {
  padding-right: .5rem;
}

.pr1rem, .px1rem {
  padding-right: 1rem;
}

.pb0-5rem, .py0-5rem {
  padding-bottom: .5rem;
}

.pb1rem, .py1rem {
  padding-bottom: 1rem;
}

.pl0-5rem, .px0-5rem {
  padding-left: .5rem;
}

.pl1rem, .px1rem {
  padding-left: 1rem;
}

.m20 {
  margin: 20%;
}

.mt20, .my20 {
  margin-top: 20%;
}

.mr20, .mx20 {
  margin-right: 20%;
}

.mb20, .my20 {
  margin-bottom: 20%;
}

.ml20, .mx20 {
  margin-left: 20%;
}

.p20 {
  padding: 20%;
}

.pt20, .py20 {
  padding-top: 20%;
}

.pr20, .px20 {
  padding-right: 20%;
}

.pb20, .py20 {
  padding-bottom: 20%;
}

.pl20, .px20 {
  padding-left: 20%;
}

.m1-5rem {
  margin: 1.5rem;
}

.m2rem {
  margin: 2rem;
}

.mt1-5rem, .my1-5rem {
  margin-top: 1.5rem;
}

.mt2rem, .my2rem {
  margin-top: 2rem;
}

.mr1-5rem, .mx1-5rem {
  margin-right: 1.5rem;
}

.mr2rem, .mx2rem {
  margin-right: 2rem;
}

.mb1-5rem, .my1-5rem {
  margin-bottom: 1.5rem;
}

.mb2rem, .my2rem {
  margin-bottom: 2rem;
}

.ml1-5rem, .mx1-5rem {
  margin-left: 1.5rem;
}

.ml2rem, .mx2rem {
  margin-left: 2rem;
}

.p1-5rem {
  padding: 1.5rem;
}

.p2rem {
  padding: 2rem;
}

.pt1-5rem, .py1-5rem {
  padding-top: 1.5rem;
}

.pt2rem, .py2rem {
  padding-top: 2rem;
}

.pr1-5rem, .px1-5rem {
  padding-right: 1.5rem;
}

.pr2rem, .px2rem {
  padding-right: 2rem;
}

.pb1-5rem, .py1-5rem {
  padding-bottom: 1.5rem;
}

.pb2rem, .py2rem {
  padding-bottom: 2rem;
}

.pl1-5rem, .px1-5rem {
  padding-left: 1.5rem;
}

.pl2rem, .px2rem {
  padding-left: 2rem;
}

.m30 {
  margin: 30%;
}

.mt30, .my30 {
  margin-top: 30%;
}

.mr30, .mx30 {
  margin-right: 30%;
}

.mb30, .my30 {
  margin-bottom: 30%;
}

.ml30, .mx30 {
  margin-left: 30%;
}

.p30 {
  padding: 30%;
}

.pt30, .py30 {
  padding-top: 30%;
}

.pr30, .px30 {
  padding-right: 30%;
}

.pb30, .py30 {
  padding-bottom: 30%;
}

.pl30, .px30 {
  padding-left: 30%;
}

.m2-5rem {
  margin: 2.5rem;
}

.m3rem {
  margin: 3rem;
}

.mt2-5rem, .my2-5rem {
  margin-top: 2.5rem;
}

.mt3rem, .my3rem {
  margin-top: 3rem;
}

.mr2-5rem, .mx2-5rem {
  margin-right: 2.5rem;
}

.mr3rem, .mx3rem {
  margin-right: 3rem;
}

.mb2-5rem, .my2-5rem {
  margin-bottom: 2.5rem;
}

.mb3rem, .my3rem {
  margin-bottom: 3rem;
}

.ml2-5rem, .mx2-5rem {
  margin-left: 2.5rem;
}

.ml3rem, .mx3rem {
  margin-left: 3rem;
}

.p2-5rem {
  padding: 2.5rem;
}

.p3rem {
  padding: 3rem;
}

.pt2-5rem, .py2-5rem {
  padding-top: 2.5rem;
}

.pt3rem, .py3rem {
  padding-top: 3rem;
}

.pr2-5rem, .px2-5rem {
  padding-right: 2.5rem;
}

.pr3rem, .px3rem {
  padding-right: 3rem;
}

.pb2-5rem, .py2-5rem {
  padding-bottom: 2.5rem;
}

.pb3rem, .py3rem {
  padding-bottom: 3rem;
}

.pl2-5rem, .px2-5rem {
  padding-left: 2.5rem;
}

.pl3rem, .px3rem {
  padding-left: 3rem;
}

.m40 {
  margin: 40%;
}

.mt40, .my40 {
  margin-top: 40%;
}

.mr40, .mx40 {
  margin-right: 40%;
}

.mb40, .my40 {
  margin-bottom: 40%;
}

.ml40, .mx40 {
  margin-left: 40%;
}

.p40 {
  padding: 40%;
}

.pt40, .py40 {
  padding-top: 40%;
}

.pr40, .px40 {
  padding-right: 40%;
}

.pb40, .py40 {
  padding-bottom: 40%;
}

.pl40, .px40 {
  padding-left: 40%;
}

.m3-5rem {
  margin: 3.5rem;
}

.m4rem {
  margin: 4rem;
}

.mt3-5rem, .my3-5rem {
  margin-top: 3.5rem;
}

.mt4rem, .my4rem {
  margin-top: 4rem;
}

.mr3-5rem, .mx3-5rem {
  margin-right: 3.5rem;
}

.mr4rem, .mx4rem {
  margin-right: 4rem;
}

.mb3-5rem, .my3-5rem {
  margin-bottom: 3.5rem;
}

.mb4rem, .my4rem {
  margin-bottom: 4rem;
}

.ml3-5rem, .mx3-5rem {
  margin-left: 3.5rem;
}

.ml4rem, .mx4rem {
  margin-left: 4rem;
}

.p3-5rem {
  padding: 3.5rem;
}

.p4rem {
  padding: 4rem;
}

.pt3-5rem, .py3-5rem {
  padding-top: 3.5rem;
}

.pt4rem, .py4rem {
  padding-top: 4rem;
}

.pr3-5rem, .px3-5rem {
  padding-right: 3.5rem;
}

.pr4rem, .px4rem {
  padding-right: 4rem;
}

.pb3-5rem, .py3-5rem {
  padding-bottom: 3.5rem;
}

.pb4rem, .py4rem {
  padding-bottom: 4rem;
}

.pl3-5rem, .px3-5rem {
  padding-left: 3.5rem;
}

.pl4rem, .px4rem {
  padding-left: 4rem;
}

.m50 {
  margin: 50%;
}

.mt50, .my50 {
  margin-top: 50%;
}

.mr50, .mx50 {
  margin-right: 50%;
}

.mb50, .my50 {
  margin-bottom: 50%;
}

.ml50, .mx50 {
  margin-left: 50%;
}

.p50 {
  padding: 50%;
}

.pt50, .py50 {
  padding-top: 50%;
}

.pr50, .px50 {
  padding-right: 50%;
}

.pb50, .py50 {
  padding-bottom: 50%;
}

.pl50, .px50 {
  padding-left: 50%;
}

.m4-5rem {
  margin: 4.5rem;
}

.m5rem {
  margin: 5rem;
}

.mt4-5rem, .my4-5rem {
  margin-top: 4.5rem;
}

.mt5rem, .my5rem {
  margin-top: 5rem;
}

.mr4-5rem, .mx4-5rem {
  margin-right: 4.5rem;
}

.mr5rem, .mx5rem {
  margin-right: 5rem;
}

.mb4-5rem, .my4-5rem {
  margin-bottom: 4.5rem;
}

.mb5rem, .my5rem {
  margin-bottom: 5rem;
}

.ml4-5rem, .mx4-5rem {
  margin-left: 4.5rem;
}

.ml5rem, .mx5rem {
  margin-left: 5rem;
}

.p4-5rem {
  padding: 4.5rem;
}

.p5rem {
  padding: 5rem;
}

.pt4-5rem, .py4-5rem {
  padding-top: 4.5rem;
}

.pt5rem, .py5rem {
  padding-top: 5rem;
}

.pr4-5rem, .px4-5rem {
  padding-right: 4.5rem;
}

.pr5rem, .px5rem {
  padding-right: 5rem;
}

.pb4-5rem, .py4-5rem {
  padding-bottom: 4.5rem;
}

.pb5rem, .py5rem {
  padding-bottom: 5rem;
}

.pl4-5rem, .px4-5rem {
  padding-left: 4.5rem;
}

.pl5rem, .px5rem {
  padding-left: 5rem;
}

.m60 {
  margin: 60%;
}

.mt60, .my60 {
  margin-top: 60%;
}

.mr60, .mx60 {
  margin-right: 60%;
}

.mb60, .my60 {
  margin-bottom: 60%;
}

.ml60, .mx60 {
  margin-left: 60%;
}

.p60 {
  padding: 60%;
}

.pt60, .py60 {
  padding-top: 60%;
}

.pr60, .px60 {
  padding-right: 60%;
}

.pb60, .py60 {
  padding-bottom: 60%;
}

.pl60, .px60 {
  padding-left: 60%;
}

.m5-5rem {
  margin: 5.5rem;
}

.m6rem {
  margin: 6rem;
}

.mt5-5rem, .my5-5rem {
  margin-top: 5.5rem;
}

.mt6rem, .my6rem {
  margin-top: 6rem;
}

.mr5-5rem, .mx5-5rem {
  margin-right: 5.5rem;
}

.mr6rem, .mx6rem {
  margin-right: 6rem;
}

.mb5-5rem, .my5-5rem {
  margin-bottom: 5.5rem;
}

.mb6rem, .my6rem {
  margin-bottom: 6rem;
}

.ml5-5rem, .mx5-5rem {
  margin-left: 5.5rem;
}

.ml6rem, .mx6rem {
  margin-left: 6rem;
}

.p5-5rem {
  padding: 5.5rem;
}

.p6rem {
  padding: 6rem;
}

.pt5-5rem, .py5-5rem {
  padding-top: 5.5rem;
}

.pt6rem, .py6rem {
  padding-top: 6rem;
}

.pr5-5rem, .px5-5rem {
  padding-right: 5.5rem;
}

.pr6rem, .px6rem {
  padding-right: 6rem;
}

.pb5-5rem, .py5-5rem {
  padding-bottom: 5.5rem;
}

.pb6rem, .py6rem {
  padding-bottom: 6rem;
}

.pl5-5rem, .px5-5rem {
  padding-left: 5.5rem;
}

.pl6rem, .px6rem {
  padding-left: 6rem;
}

.m70 {
  margin: 70%;
}

.mt70, .my70 {
  margin-top: 70%;
}

.mr70, .mx70 {
  margin-right: 70%;
}

.mb70, .my70 {
  margin-bottom: 70%;
}

.ml70, .mx70 {
  margin-left: 70%;
}

.p70 {
  padding: 70%;
}

.pt70, .py70 {
  padding-top: 70%;
}

.pr70, .px70 {
  padding-right: 70%;
}

.pb70, .py70 {
  padding-bottom: 70%;
}

.pl70, .px70 {
  padding-left: 70%;
}

.m6-5rem {
  margin: 6.5rem;
}

.m7rem {
  margin: 7rem;
}

.mt6-5rem, .my6-5rem {
  margin-top: 6.5rem;
}

.mt7rem, .my7rem {
  margin-top: 7rem;
}

.mr6-5rem, .mx6-5rem {
  margin-right: 6.5rem;
}

.mr7rem, .mx7rem {
  margin-right: 7rem;
}

.mb6-5rem, .my6-5rem {
  margin-bottom: 6.5rem;
}

.mb7rem, .my7rem {
  margin-bottom: 7rem;
}

.ml6-5rem, .mx6-5rem {
  margin-left: 6.5rem;
}

.ml7rem, .mx7rem {
  margin-left: 7rem;
}

.p6-5rem {
  padding: 6.5rem;
}

.p7rem {
  padding: 7rem;
}

.pt6-5rem, .py6-5rem {
  padding-top: 6.5rem;
}

.pt7rem, .py7rem {
  padding-top: 7rem;
}

.pr6-5rem, .px6-5rem {
  padding-right: 6.5rem;
}

.pr7rem, .px7rem {
  padding-right: 7rem;
}

.pb6-5rem, .py6-5rem {
  padding-bottom: 6.5rem;
}

.pb7rem, .py7rem {
  padding-bottom: 7rem;
}

.pl6-5rem, .px6-5rem {
  padding-left: 6.5rem;
}

.pl7rem, .px7rem {
  padding-left: 7rem;
}

.m80 {
  margin: 80%;
}

.mt80, .my80 {
  margin-top: 80%;
}

.mr80, .mx80 {
  margin-right: 80%;
}

.mb80, .my80 {
  margin-bottom: 80%;
}

.ml80, .mx80 {
  margin-left: 80%;
}

.p80 {
  padding: 80%;
}

.pt80, .py80 {
  padding-top: 80%;
}

.pr80, .px80 {
  padding-right: 80%;
}

.pb80, .py80 {
  padding-bottom: 80%;
}

.pl80, .px80 {
  padding-left: 80%;
}

.m7-5rem {
  margin: 7.5rem;
}

.m8rem {
  margin: 8rem;
}

.mt7-5rem, .my7-5rem {
  margin-top: 7.5rem;
}

.mt8rem, .my8rem {
  margin-top: 8rem;
}

.mr7-5rem, .mx7-5rem {
  margin-right: 7.5rem;
}

.mr8rem, .mx8rem {
  margin-right: 8rem;
}

.mb7-5rem, .my7-5rem {
  margin-bottom: 7.5rem;
}

.mb8rem, .my8rem {
  margin-bottom: 8rem;
}

.ml7-5rem, .mx7-5rem {
  margin-left: 7.5rem;
}

.ml8rem, .mx8rem {
  margin-left: 8rem;
}

.p7-5rem {
  padding: 7.5rem;
}

.p8rem {
  padding: 8rem;
}

.pt7-5rem, .py7-5rem {
  padding-top: 7.5rem;
}

.pt8rem, .py8rem {
  padding-top: 8rem;
}

.pr7-5rem, .px7-5rem {
  padding-right: 7.5rem;
}

.pr8rem, .px8rem {
  padding-right: 8rem;
}

.pb7-5rem, .py7-5rem {
  padding-bottom: 7.5rem;
}

.pb8rem, .py8rem {
  padding-bottom: 8rem;
}

.pl7-5rem, .px7-5rem {
  padding-left: 7.5rem;
}

.pl8rem, .px8rem {
  padding-left: 8rem;
}

.m90 {
  margin: 90%;
}

.mt90, .my90 {
  margin-top: 90%;
}

.mr90, .mx90 {
  margin-right: 90%;
}

.mb90, .my90 {
  margin-bottom: 90%;
}

.ml90, .mx90 {
  margin-left: 90%;
}

.p90 {
  padding: 90%;
}

.pt90, .py90 {
  padding-top: 90%;
}

.pr90, .px90 {
  padding-right: 90%;
}

.pb90, .py90 {
  padding-bottom: 90%;
}

.pl90, .px90 {
  padding-left: 90%;
}

.m8-5rem {
  margin: 8.5rem;
}

.m9rem {
  margin: 9rem;
}

.mt8-5rem, .my8-5rem {
  margin-top: 8.5rem;
}

.mt9rem, .my9rem {
  margin-top: 9rem;
}

.mr8-5rem, .mx8-5rem {
  margin-right: 8.5rem;
}

.mr9rem, .mx9rem {
  margin-right: 9rem;
}

.mb8-5rem, .my8-5rem {
  margin-bottom: 8.5rem;
}

.mb9rem, .my9rem {
  margin-bottom: 9rem;
}

.ml8-5rem, .mx8-5rem {
  margin-left: 8.5rem;
}

.ml9rem, .mx9rem {
  margin-left: 9rem;
}

.p8-5rem {
  padding: 8.5rem;
}

.p9rem {
  padding: 9rem;
}

.pt8-5rem, .py8-5rem {
  padding-top: 8.5rem;
}

.pt9rem, .py9rem {
  padding-top: 9rem;
}

.pr8-5rem, .px8-5rem {
  padding-right: 8.5rem;
}

.pr9rem, .px9rem {
  padding-right: 9rem;
}

.pb8-5rem, .py8-5rem {
  padding-bottom: 8.5rem;
}

.pb9rem, .py9rem {
  padding-bottom: 9rem;
}

.pl8-5rem, .px8-5rem {
  padding-left: 8.5rem;
}

.pl9rem, .px9rem {
  padding-left: 9rem;
}

.m100 {
  margin: 100%;
}

.mt100, .my100 {
  margin-top: 100%;
}

.mr100, .mx100 {
  margin-right: 100%;
}

.mb100, .my100 {
  margin-bottom: 100%;
}

.ml100, .mx100 {
  margin-left: 100%;
}

.p100 {
  padding: 100%;
}

.pt100, .py100 {
  padding-top: 100%;
}

.pr100, .px100 {
  padding-right: 100%;
}

.pb100, .py100 {
  padding-bottom: 100%;
}

.pl100, .px100 {
  padding-left: 100%;
}

.m9-5rem {
  margin: 9.5rem;
}

.m10rem {
  margin: 10rem;
}

.mt9-5rem, .my9-5rem {
  margin-top: 9.5rem;
}

.mt10rem, .my10rem {
  margin-top: 10rem;
}

.mr9-5rem, .mx9-5rem {
  margin-right: 9.5rem;
}

.mr10rem, .mx10rem {
  margin-right: 10rem;
}

.mb9-5rem, .my9-5rem {
  margin-bottom: 9.5rem;
}

.mb10rem, .my10rem {
  margin-bottom: 10rem;
}

.ml9-5rem, .mx9-5rem {
  margin-left: 9.5rem;
}

.ml10rem, .mx10rem {
  margin-left: 10rem;
}

.p9-5rem {
  padding: 9.5rem;
}

.p10rem {
  padding: 10rem;
}

.pt9-5rem, .py9-5rem {
  padding-top: 9.5rem;
}

.pt10rem, .py10rem {
  padding-top: 10rem;
}

.pr9-5rem, .px9-5rem {
  padding-right: 9.5rem;
}

.pr10rem, .px10rem {
  padding-right: 10rem;
}

.pb9-5rem, .py9-5rem {
  padding-bottom: 9.5rem;
}

.pb10rem, .py10rem {
  padding-bottom: 10rem;
}

.pl9-5rem, .px9-5rem {
  padding-left: 9.5rem;
}

.pl10rem, .px10rem {
  padding-left: 10rem;
}

.wa {
  width: auto;
}

.ha {
  height: auto;
}

.w0 {
  width: 0;
}

.h0 {
  height: 0;
}

.maw0 {
  max-width: 0;
}

.mah0 {
  max-height: 0;
}

.mih0 {
  min-height: 0;
}

.w1rem {
  width: 1rem;
}

.w10px {
  width: 10px;
}

.w10 {
  width: 10%;
}

.w10vw {
  width: 10vw;
}

.h1rem {
  height: 1rem;
}

.h10px {
  height: 10px;
}

.h10 {
  height: 10%;
}

.h10vh {
  height: 10vh;
}

.miw1rem {
  min-width: 1rem;
}

.miw100px {
  min-width: 100px;
}

.miw10 {
  min-width: 10%;
}

.miw10vw {
  min-width: 10vw;
}

.mih1rem {
  min-height: 1rem;
}

.mih100px {
  min-height: 100px;
}

.mih10 {
  min-height: 10%;
}

.mih10vh {
  min-height: 10vh;
}

.maw1rem {
  max-width: 1rem;
}

.maw100px {
  max-width: 100px;
}

.maw10 {
  max-width: 10%;
}

.maw10vw {
  max-width: 10vw;
}

.mah1rem {
  max-height: 1rem;
}

.mah100px {
  max-height: 100px;
}

.mah10 {
  max-height: 10%;
}

.mah10vh {
  max-height: 10vh;
}

.w2rem {
  width: 2rem;
}

.w20px {
  width: 20px;
}

.w200px {
  width: 200px;
}

.w20 {
  width: 20%;
}

.w20vw {
  width: 20vw;
}

.h2rem {
  height: 2rem;
}

.h20px {
  height: 20px;
}

.h200px {
  height: 200px;
}

.h20 {
  height: 20%;
}

.h20vh {
  height: 20vh;
}

.miw2rem {
  min-width: 2rem;
}

.miw200px {
  min-width: 200px;
}

.miw20 {
  min-width: 20%;
}

.miw20vw {
  min-width: 20vw;
}

.mih2rem {
  min-height: 2rem;
}

.mih200px {
  min-height: 200px;
}

.mih20 {
  min-height: 20%;
}

.mih20vh {
  min-height: 20vh;
}

.maw2rem {
  max-width: 2rem;
}

.maw200px {
  max-width: 200px;
}

.maw20 {
  max-width: 20%;
}

.maw20vw {
  max-width: 20vw;
}

.mah2rem {
  max-height: 2rem;
}

.mah200px {
  max-height: 200px;
}

.mah20 {
  max-height: 20%;
}

.mah20vh {
  max-height: 20vh;
}

.w3rem {
  width: 3rem;
}

.w30px {
  width: 30px;
}

.w300px {
  width: 300px;
}

.w30 {
  width: 30%;
}

.w30vw {
  width: 30vw;
}

.h3rem {
  height: 3rem;
}

.h30px {
  height: 30px;
}

.h300px {
  height: 300px;
}

.h30 {
  height: 30%;
}

.h30vh {
  height: 30vh;
}

.miw3rem {
  min-width: 3rem;
}

.miw300px {
  min-width: 300px;
}

.miw30 {
  min-width: 30%;
}

.miw30vw {
  min-width: 30vw;
}

.mih3rem {
  min-height: 3rem;
}

.mih300px {
  min-height: 300px;
}

.mih30 {
  min-height: 30%;
}

.mih30vh {
  min-height: 30vh;
}

.maw3rem {
  max-width: 3rem;
}

.maw300px {
  max-width: 300px;
}

.maw30 {
  max-width: 30%;
}

.maw30vw {
  max-width: 30vw;
}

.mah3rem {
  max-height: 3rem;
}

.mah300px {
  max-height: 300px;
}

.mah30 {
  max-height: 30%;
}

.mah30vh {
  max-height: 30vh;
}

.w4rem {
  width: 4rem;
}

.w40px {
  width: 40px;
}

.w400px {
  width: 400px;
}

.w40 {
  width: 40%;
}

.w40vw {
  width: 40vw;
}

.h4rem {
  height: 4rem;
}

.h40px {
  height: 40px;
}

.h400px {
  height: 400px;
}

.h40 {
  height: 40%;
}

.h40vh {
  height: 40vh;
}

.miw4rem {
  min-width: 4rem;
}

.miw400px {
  min-width: 400px;
}

.miw40 {
  min-width: 40%;
}

.miw40vw {
  min-width: 40vw;
}

.mih4rem {
  min-height: 4rem;
}

.mih400px {
  min-height: 400px;
}

.mih40 {
  min-height: 40%;
}

.mih40vh {
  min-height: 40vh;
}

.maw4rem {
  max-width: 4rem;
}

.maw400px {
  max-width: 400px;
}

.maw40 {
  max-width: 40%;
}

.maw40vw {
  max-width: 40vw;
}

.mah4rem {
  max-height: 4rem;
}

.mah400px {
  max-height: 400px;
}

.mah40 {
  max-height: 40%;
}

.mah40vh {
  max-height: 40vh;
}

.w5rem {
  width: 5rem;
}

.w50px {
  width: 50px;
}

.w500px {
  width: 500px;
}

.w50vw {
  width: 50vw;
}

.h5rem {
  height: 5rem;
}

.h50px {
  height: 50px;
}

.h500px {
  height: 500px;
}

.h50 {
  height: 50%;
}

.h50vh {
  height: 50vh;
}

.miw5rem {
  min-width: 5rem;
}

.miw500px {
  min-width: 500px;
}

.miw50 {
  min-width: 50%;
}

.miw50vw {
  min-width: 50vw;
}

.mih5rem {
  min-height: 5rem;
}

.mih500px {
  min-height: 500px;
}

.mih50 {
  min-height: 50%;
}

.mih50vh {
  min-height: 50vh;
}

.maw5rem {
  max-width: 5rem;
}

.maw500px {
  max-width: 500px;
}

.maw50 {
  max-width: 50%;
}

.maw50vw {
  max-width: 50vw;
}

.mah5rem {
  max-height: 5rem;
}

.mah500px {
  max-height: 500px;
}

.mah50 {
  max-height: 50%;
}

.mah50vh {
  max-height: 50vh;
}

.w6rem {
  width: 6rem;
}

.w600px {
  width: 600px;
}

.w60 {
  width: 60%;
}

.w60vw {
  width: 60vw;
}

.h6rem {
  height: 6rem;
}

.h60px {
  height: 60px;
}

.h600px {
  height: 600px;
}

.h60 {
  height: 60%;
}

.h60vh {
  height: 60vh;
}

.miw6rem {
  min-width: 6rem;
}

.miw600px {
  min-width: 600px;
}

.miw60 {
  min-width: 60%;
}

.miw60vw {
  min-width: 60vw;
}

.mih6rem {
  min-height: 6rem;
}

.mih600px {
  min-height: 600px;
}

.mih60 {
  min-height: 60%;
}

.mih60vh {
  min-height: 60vh;
}

.maw6rem {
  max-width: 6rem;
}

.maw600px {
  max-width: 600px;
}

.maw60 {
  max-width: 60%;
}

.maw60vw {
  max-width: 60vw;
}

.mah6rem {
  max-height: 6rem;
}

.mah600px {
  max-height: 600px;
}

.mah60 {
  max-height: 60%;
}

.mah60vh {
  max-height: 60vh;
}

.w7rem {
  width: 7rem;
}

.w70px {
  width: 70px;
}

.w700px {
  width: 700px;
}

.w70 {
  width: 70%;
}

.w70vw {
  width: 70vw;
}

.h7rem {
  height: 7rem;
}

.h70px {
  height: 70px;
}

.h700px {
  height: 700px;
}

.h70 {
  height: 70%;
}

.h70vh {
  height: 70vh;
}

.miw7rem {
  min-width: 7rem;
}

.miw700px {
  min-width: 700px;
}

.miw70 {
  min-width: 70%;
}

.miw70vw {
  min-width: 70vw;
}

.mih7rem {
  min-height: 7rem;
}

.mih700px {
  min-height: 700px;
}

.mih70 {
  min-height: 70%;
}

.mih70vh {
  min-height: 70vh;
}

.maw7rem {
  max-width: 7rem;
}

.maw700px {
  max-width: 700px;
}

.maw70 {
  max-width: 70%;
}

.maw70vw {
  max-width: 70vw;
}

.mah7rem {
  max-height: 7rem;
}

.mah700px {
  max-height: 700px;
}

.mah70 {
  max-height: 70%;
}

.mah70vh {
  max-height: 70vh;
}

.w8rem {
  width: 8rem;
}

.w80px {
  width: 80px;
}

.w800px {
  width: 800px;
}

.w80vw {
  width: 80vw;
}

.h8rem {
  height: 8rem;
}

.h80px {
  height: 80px;
}

.h800px {
  height: 800px;
}

.h80 {
  height: 80%;
}

.h80vh {
  height: 80vh;
}

.miw8rem {
  min-width: 8rem;
}

.miw800px {
  min-width: 800px;
}

.miw80 {
  min-width: 80%;
}

.miw80vw {
  min-width: 80vw;
}

.mih8rem {
  min-height: 8rem;
}

.mih800px {
  min-height: 800px;
}

.mih80 {
  min-height: 80%;
}

.mih80vh {
  min-height: 80vh;
}

.maw8rem {
  max-width: 8rem;
}

.maw800px {
  max-width: 800px;
}

.maw80 {
  max-width: 80%;
}

.maw80vw {
  max-width: 80vw;
}

.mah8rem {
  max-height: 8rem;
}

.mah800px {
  max-height: 800px;
}

.mah80 {
  max-height: 80%;
}

.mah80vh {
  max-height: 80vh;
}

.w9rem {
  width: 9rem;
}

.w90px {
  width: 90px;
}

.w900px {
  width: 900px;
}

.w90 {
  width: 90%;
}

.w90vw {
  width: 90vw;
}

.h9rem {
  height: 9rem;
}

.h90px {
  height: 90px;
}

.h900px {
  height: 900px;
}

.h90 {
  height: 90%;
}

.h90vh {
  height: 90vh;
}

.miw9rem {
  min-width: 9rem;
}

.miw900px {
  min-width: 900px;
}

.miw90 {
  min-width: 90%;
}

.miw90vw {
  min-width: 90vw;
}

.mih9rem {
  min-height: 9rem;
}

.mih900px {
  min-height: 900px;
}

.mih90 {
  min-height: 90%;
}

.mih90vh {
  min-height: 90vh;
}

.maw9rem {
  max-width: 9rem;
}

.maw900px {
  max-width: 900px;
}

.maw90 {
  max-width: 90%;
}

.maw90vw {
  max-width: 90vw;
}

.mah9rem {
  max-height: 9rem;
}

.mah900px {
  max-height: 900px;
}

.mah90 {
  max-height: 90%;
}

.mah90vh {
  max-height: 90vh;
}

.w10rem {
  width: 10rem;
}

.w100px {
  width: 100px;
}

.w1000px {
  width: 1000px;
}

.w100 {
  width: 100%;
}

.w100vw {
  width: 100vw;
}

.h10rem {
  height: 10rem;
}

.h100px {
  height: 100px;
}

.h1000px {
  height: 1000px;
}

.h100 {
  height: 100%;
}

.h100vh {
  height: 100vh;
}

.miw10rem {
  min-width: 10rem;
}

.miw1000px {
  min-width: 1000px;
}

.miw100 {
  min-width: 100%;
}

.miw100vw {
  min-width: 100vw;
}

.mih10rem {
  min-height: 10rem;
}

.mih1000px {
  min-height: 1000px;
}

.mih100 {
  min-height: 100%;
}

.mih100vh {
  min-height: 100vh;
}

.maw10rem {
  max-width: 10rem;
}

.maw1000px {
  max-width: 1000px;
}

.maw100 {
  max-width: 100%;
}

.maw100vw {
  max-width: 100vw;
}

.mah10rem {
  max-height: 10rem;
}

.mah1000px {
  max-height: 1000px;
}

.mah100 {
  max-height: 100%;
}

.mah100vh {
  max-height: 100vh;
}

.or0 {
  order: 0;
}

.br0px {
  border-radius: 0;
}

.btlr0px {
  border-top-left-radius: 0;
}

.btrr0px {
  border-top-right-radius: 0;
}

.bblr0px {
  border-bottom-left-radius: 0;
}

.bbrr0px {
  border-bottom-right-radius: 0;
}

.or1 {
  order: 1;
}

.br5px {
  border-radius: 6px;
}

.br8px {
  border-radius: 8px;
}

.br19px {
  border-radius: 19px;
}

.btlr5px {
  border-top-left-radius: 5px;
}

.btrr5px {
  border-top-right-radius: 5px;
}

.bblr5px {
  border-bottom-left-radius: 5px;
}

.bbrr5px {
  border-bottom-right-radius: 5px;
}

.or2 {
  order: 2;
}

.br10px {
  border-radius: 12px;
}

.br16px {
  border-radius: 16px;
}

.br38px {
  border-radius: 38px;
}

.btlr10px {
  border-top-left-radius: 10px;
}

.btrr10px {
  border-top-right-radius: 10px;
}

.bblr10px {
  border-bottom-left-radius: 10px;
}

.bbrr10px {
  border-bottom-right-radius: 10px;
}

.or3 {
  order: 3;
}

.br15px {
  border-radius: 18px;
}

.br24px {
  border-radius: 24px;
}

.br57px {
  border-radius: 57px;
}

.btlr15px {
  border-top-left-radius: 15px;
}

.btrr15px {
  border-top-right-radius: 15px;
}

.bblr15px {
  border-bottom-left-radius: 15px;
}

.bbrr15px {
  border-bottom-right-radius: 15px;
}

.or4 {
  order: 4;
}

.br20px {
  border-radius: 24px;
}

.br32px {
  border-radius: 32px;
}

.br76px {
  border-radius: 76px;
}

.btlr20px {
  border-top-left-radius: 20px;
}

.btrr20px {
  border-top-right-radius: 20px;
}

.bblr20px {
  border-bottom-left-radius: 20px;
}

.bbrr20px {
  border-bottom-right-radius: 20px;
}

.or5 {
  order: 5;
}

.br25px {
  border-radius: 30px;
}

.br95px {
  border-radius: 95px;
}

.btlr25px {
  border-top-left-radius: 25px;
}

.btrr25px {
  border-top-right-radius: 25px;
}

.bblr25px {
  border-bottom-left-radius: 25px;
}

.bbrr25px {
  border-bottom-right-radius: 25px;
}

.or6 {
  order: 6;
}

.br30px {
  border-radius: 36px;
}

.br48px {
  border-radius: 48px;
}

.br114px {
  border-radius: 114px;
}

.btlr30px {
  border-top-left-radius: 30px;
}

.btrr30px {
  border-top-right-radius: 30px;
}

.bblr30px {
  border-bottom-left-radius: 30px;
}

.bbrr30px {
  border-bottom-right-radius: 30px;
}

.or7 {
  order: 7;
}

.br35px {
  border-radius: 42px;
}

.br56px {
  border-radius: 56px;
}

.br133px {
  border-radius: 133px;
}

.btlr35px {
  border-top-left-radius: 35px;
}

.btrr35px {
  border-top-right-radius: 35px;
}

.bblr35px {
  border-bottom-left-radius: 35px;
}

.bbrr35px {
  border-bottom-right-radius: 35px;
}

.or8 {
  order: 8;
}

.br40px {
  border-radius: 48px;
}

.br64px {
  border-radius: 64px;
}

.br152px {
  border-radius: 152px;
}

.btlr40px {
  border-top-left-radius: 40px;
}

.btrr40px {
  border-top-right-radius: 40px;
}

.bblr40px {
  border-bottom-left-radius: 40px;
}

.bbrr40px {
  border-bottom-right-radius: 40px;
}

.or9 {
  order: 9;
}

.br45px {
  border-radius: 54px;
}

.br72px {
  border-radius: 72px;
}

.br171px {
  border-radius: 171px;
}

.btlr45px {
  border-top-left-radius: 45px;
}

.btrr45px {
  border-top-right-radius: 45px;
}

.bblr45px {
  border-bottom-left-radius: 45px;
}

.bbrr45px {
  border-bottom-right-radius: 45px;
}

.or10 {
  order: 10;
}

.br50px {
  border-radius: 60px;
}

.br80px {
  border-radius: 80px;
}

.br190px {
  border-radius: 190px;
}

.btlr50px {
  border-top-left-radius: 50px;
}

.btrr50px {
  border-top-right-radius: 50px;
}

.bblr50px {
  border-bottom-left-radius: 50px;
}

.bbrr50px {
  border-bottom-right-radius: 50px;
}

.br100 {
  border-radius: 100%;
}

.btlr100 {
  border-top-left-radius: 100%;
}

.btrr100 {
  border-top-right-radius: 100%;
}

.bblr100 {
  border-bottom-left-radius: 100%;
}

.bbrr100 {
  border-bottom-right-radius: 100%;
}

@media (width <= 35.99rem) {
  .c1-xs, .c1-hover-xs:hover {
    color: #fff;
  }

  .bg1-xs, .bg1-hover-xs:hover {
    background-color: #fff;
  }

  .bo1-xs, .bo1-hover-xs:hover {
    border-color: #fff;
  }

  .f1-xs, .f1-hover-xs:hover {
    fill: #fff;
  }

  .c2-xs, .c2-hover-xs:hover {
    color: #000;
  }

  .bg2-xs, .bg2-hover-xs:hover {
    background-color: #000;
  }

  .bo2-xs, .bo2-hover-xs:hover {
    border-color: #000;
  }

  .f2-xs, .f2-hover-xs:hover {
    fill: #000;
  }

  .c3-xs, .c3-hover-xs:hover {
    color: #4a67ff;
  }

  .bg3-xs, .bg3-hover-xs:hover {
    background-color: #4a67ff;
  }

  .bo3-xs, .bo3-hover-xs:hover {
    border-color: #4a67ff;
  }

  .f3-xs, .f3-hover-xs:hover {
    fill: #4a67ff;
  }

  .c4-xs, .c4-hover-xs:hover {
    color: #fc7785;
  }

  .bg4-xs, .bg4-hover-xs:hover {
    background-color: #fc7785;
  }

  .bo4-xs, .bo4-hover-xs:hover {
    border-color: #fc7785;
  }

  .f4-xs, .f4-hover-xs:hover {
    fill: #fc7785;
  }

  .c5-xs, .c5-hover-xs:hover {
    color: #f0f0f8;
  }

  .bg5-xs, .bg5-hover-xs:hover {
    background-color: #f0f0f8;
  }

  .bo5-xs, .bo5-hover-xs:hover {
    border-color: #f0f0f8;
  }

  .f5-xs, .f5-hover-xs:hover {
    fill: #f0f0f8;
  }

  .c6-xs, .c6-hover-xs:hover {
    color: #002d63;
  }

  .bg6-xs, .bg6-hover-xs:hover {
    background-color: #002d63;
  }

  .bo6-xs, .bo6-hover-xs:hover {
    border-color: #002d63;
  }

  .f6-xs, .f6-hover-xs:hover {
    fill: #002d63;
  }

  .c7-xs, .c7-hover-xs:hover {
    color: #40dda1;
  }

  .bg7-xs, .bg7-hover-xs:hover {
    background-color: #40dda1;
  }

  .bo7-xs, .bo7-hover-xs:hover {
    border-color: #40dda1;
  }

  .f7-xs, .f7-hover-xs:hover {
    fill: #40dda1;
  }

  .c8-xs, .c8-hover-xs:hover {
    color: #ddb640;
  }

  .bg8-xs, .bg8-hover-xs:hover {
    background-color: #ddb640;
  }

  .bo8-xs, .bo8-hover-xs:hover {
    border-color: #ddb640;
  }

  .f8-xs, .f8-hover-xs:hover {
    fill: #ddb640;
  }

  .c9-xs, .c9-hover-xs:hover {
    color: #dd4040;
  }

  .bg9-xs, .bg9-hover-xs:hover {
    background-color: #dd4040;
  }

  .bo9-xs, .bo9-hover-xs:hover {
    border-color: #dd4040;
  }

  .f9-xs, .f9-hover-xs:hover {
    fill: #dd4040;
  }

  .c10-xs, .c10-hover-xs:hover {
    color: #038589;
  }

  .bg10-xs, .bg10-hover-xs:hover {
    background-color: #038589;
  }

  .bo10-xs, .bo10-hover-xs:hover {
    border-color: #038589;
  }

  .f10-xs, .f10-hover-xs:hover {
    fill: #038589;
  }

  .c11-xs, .c11-hover-xs:hover {
    color: #007ee5;
  }

  .bg11-xs, .bg11-hover-xs:hover {
    background-color: #007ee5;
  }

  .bo11-xs, .bo11-hover-xs:hover {
    border-color: #007ee5;
  }

  .f11-xs, .f11-hover-xs:hover {
    fill: #007ee5;
  }

  .c12-xs, .c12-hover-xs:hover {
    color: #969696;
  }

  .bg12-xs, .bg12-hover-xs:hover {
    background-color: #969696;
  }

  .bo12-xs, .bo12-hover-xs:hover {
    border-color: #969696;
  }

  .f12-xs, .f12-hover-xs:hover {
    fill: #969696;
  }

  .c13-xs, .c13-hover-xs:hover {
    color: #fb7a27;
  }

  .bg13-xs, .bg13-hover-xs:hover {
    background-color: #fb7a27;
  }

  .bo13-xs, .bo13-hover-xs:hover {
    border-color: #fb7a27;
  }

  .f13-xs, .f13-hover-xs:hover {
    fill: #fb7a27;
  }

  .c14-xs, .c14-hover-xs:hover {
    color: #505050;
  }

  .bg14-xs, .bg14-hover-xs:hover {
    background-color: #505050;
  }

  .bo14-xs, .bo14-hover-xs:hover {
    border-color: #505050;
  }

  .f14-xs, .f14-hover-xs:hover {
    fill: #505050;
  }

  .c15-xs, .c15-hover-xs:hover {
    color: #151313;
  }

  .bg15-xs, .bg15-hover-xs:hover {
    background-color: #151313;
  }

  .bo15-xs, .bo15-hover-xs:hover {
    border-color: #151313;
  }

  .f15-xs, .f15-hover-xs:hover {
    fill: #151313;
  }

  .c16-xs, .c16-hover-xs:hover {
    color: #d9d9d9;
  }

  .bg16-xs, .bg16-hover-xs:hover {
    background-color: #d9d9d9;
  }

  .bo16-xs, .bo16-hover-xs:hover {
    border-color: #d9d9d9;
  }

  .f16-xs, .f16-hover-xs:hover {
    fill: #d9d9d9;
  }

  .c17-xs, .c17-hover-xs:hover {
    color: #d3d9fc;
  }

  .bg17-xs, .bg17-hover-xs:hover {
    background-color: #d3d9fc;
  }

  .bo17-xs, .bo17-hover-xs:hover {
    border-color: #d3d9fc;
  }

  .f17-xs, .f17-hover-xs:hover {
    fill: #d3d9fc;
  }

  .c18-xs, .c18-hover-xs:hover {
    color: #6775b9;
  }

  .bg18-xs, .bg18-hover-xs:hover {
    background-color: #6775b9;
  }

  .bo18-xs, .bo18-hover-xs:hover {
    border-color: #6775b9;
  }

  .f18-xs, .f18-hover-xs:hover {
    fill: #6775b9;
  }

  .c19-xs, .c19-hover-xs:hover {
    color: #f5f6f9;
  }

  .bg19-xs, .bg19-hover-xs:hover {
    background-color: #f5f6f9;
  }

  .bo19-xs, .bo19-hover-xs:hover {
    border-color: #f5f6f9;
  }

  .f19-xs, .f19-hover-xs:hover {
    fill: #f5f6f9;
  }

  .c20-xs, .c20-hover-xs:hover {
    color: #f0f2fd;
  }

  .bg20-xs, .bg20-hover-xs:hover {
    background-color: #f0f2fd;
  }

  .bo20-xs, .bo20-hover-xs:hover {
    border-color: #f0f2fd;
  }

  .f20-xs, .f20-hover-xs:hover {
    fill: #f0f2fd;
  }

  .c21-xs, .c21-hover-xs:hover {
    color: #f9f9f9;
  }

  .bg21-xs, .bg21-hover-xs:hover {
    background-color: #f9f9f9;
  }

  .bo21-xs, .bo21-hover-xs:hover {
    border-color: #f9f9f9;
  }

  .f21-xs, .f21-hover-xs:hover {
    fill: #f9f9f9;
  }

  .c22-xs, .c22-hover-xs:hover {
    color: #f6f6f6;
  }

  .bg22-xs, .bg22-hover-xs:hover {
    background-color: #f6f6f6;
  }

  .bo22-xs, .bo22-hover-xs:hover {
    border-color: #f6f6f6;
  }

  .f22-xs, .f22-hover-xs:hover {
    fill: #f6f6f6;
  }

  .c23-xs, .c23-hover-xs:hover {
    color: #fd6474;
  }

  .bg23-xs, .bg23-hover-xs:hover {
    background-color: #fd6474;
  }

  .bo23-xs, .bo23-hover-xs:hover {
    border-color: #fd6474;
  }

  .f23-xs, .f23-hover-xs:hover {
    fill: #fd6474;
  }

  .c24-xs, .c24-hover-xs:hover {
    color: #efefef;
  }

  .bg24-xs, .bg24-hover-xs:hover {
    background-color: #efefef;
  }

  .bo24-xs, .bo24-hover-xs:hover {
    border-color: #efefef;
  }

  .f24-xs, .f24-hover-xs:hover {
    fill: #efefef;
  }

  .c25-xs, .c25-hover-xs:hover {
    color: #f8f8f8;
  }

  .bg25-xs, .bg25-hover-xs:hover {
    background-color: #f8f8f8;
  }

  .bo25-xs, .bo25-hover-xs:hover {
    border-color: #f8f8f8;
  }

  .f25-xs, .f25-hover-xs:hover {
    fill: #f8f8f8;
  }

  .c26-xs, .c26-hover-xs:hover {
    color: #0126ed;
  }

  .bg26-xs, .bg26-hover-xs:hover {
    background-color: #0126ed;
  }

  .bo26-xs, .bo26-hover-xs:hover {
    border-color: #0126ed;
  }

  .f26-xs, .f26-hover-xs:hover {
    fill: #0126ed;
  }

  .c27-xs, .c27-hover-xs:hover {
    color: #5e5f66;
  }

  .bg27-xs, .bg27-hover-xs:hover {
    background-color: #5e5f66;
  }

  .bo27-xs, .bo27-hover-xs:hover {
    border-color: #5e5f66;
  }

  .f27-xs, .f27-hover-xs:hover {
    fill: #5e5f66;
  }

  .c28-xs, .c28-hover-xs:hover {
    color: #aaacb5;
  }

  .bg28-xs, .bg28-hover-xs:hover {
    background-color: #aaacb5;
  }

  .bo28-xs, .bo28-hover-xs:hover {
    border-color: #aaacb5;
  }

  .f28-xs, .f28-hover-xs:hover {
    fill: #aaacb5;
  }

  .c29-xs, .c29-hover-xs:hover {
    color: #dde2f2;
  }

  .bg29-xs, .bg29-hover-xs:hover {
    background-color: #dde2f2;
  }

  .bo29-xs, .bo29-hover-xs:hover {
    border-color: #dde2f2;
  }

  .f29-xs, .f29-hover-xs:hover {
    fill: #dde2f2;
  }

  .c30-xs, .c30-hover-xs:hover {
    color: #09b255;
  }

  .bg30-xs, .bg30-hover-xs:hover {
    background-color: #09b255;
  }

  .bo30-xs, .bo30-hover-xs:hover {
    border-color: #09b255;
  }

  .f30-xs, .f30-hover-xs:hover {
    fill: #09b255;
  }

  .c31-xs, .c31-hover-xs:hover {
    color: #dbdfee;
  }

  .bg31-xs, .bg31-hover-xs:hover {
    background-color: #dbdfee;
  }

  .bo31-xs, .bo31-hover-xs:hover {
    border-color: #dbdfee;
  }

  .f31-xs, .f31-hover-xs:hover {
    fill: #dbdfee;
  }

  .c32-xs, .c32-hover-xs:hover {
    color: #737171;
  }

  .bg32-xs, .bg32-hover-xs:hover {
    background-color: #737171;
  }

  .bo32-xs, .bo32-hover-xs:hover {
    border-color: #737171;
  }

  .f32-xs, .f32-hover-xs:hover {
    fill: #737171;
  }

  .c33-xs, .c33-hover-xs:hover {
    color: #767b90;
  }

  .bg33-xs, .bg33-hover-xs:hover {
    background-color: #767b90;
  }

  .bo33-xs, .bo33-hover-xs:hover {
    border-color: #767b90;
  }

  .f33-xs, .f33-hover-xs:hover {
    fill: #767b90;
  }

  .c34-xs, .c34-hover-xs:hover {
    color: #15d757;
  }

  .bg34-xs, .bg34-hover-xs:hover {
    background-color: #15d757;
  }

  .bo34-xs, .bo34-hover-xs:hover {
    border-color: #15d757;
  }

  .f34-xs, .f34-hover-xs:hover {
    fill: #15d757;
  }

  .c35-xs, .c35-hover-xs:hover {
    color: #e8ebf5;
  }

  .bg35-xs, .bg35-hover-xs:hover {
    background-color: #e8ebf5;
  }

  .bo35-xs, .bo35-hover-xs:hover {
    border-color: #e8ebf5;
  }

  .f35-xs, .f35-hover-xs:hover {
    fill: #e8ebf5;
  }

  .c36-xs, .c36-hover-xs:hover {
    color: #4c6b92;
  }

  .bg36-xs, .bg36-hover-xs:hover {
    background-color: #4c6b92;
  }

  .bo36-xs, .bo36-hover-xs:hover {
    border-color: #4c6b92;
  }

  .f36-xs, .f36-hover-xs:hover {
    fill: #4c6b92;
  }

  .c37-xs, .c37-hover-xs:hover {
    color: #818181;
  }

  .bg37-xs, .bg37-hover-xs:hover {
    background-color: #818181;
  }

  .bo37-xs, .bo37-hover-xs:hover {
    border-color: #818181;
  }

  .f37-xs, .f37-hover-xs:hover {
    fill: #818181;
  }

  .c38-xs, .c38-hover-xs:hover {
    color: #f0f2fd;
  }

  .bg38-xs, .bg38-hover-xs:hover {
    background-color: #f0f2fd;
  }

  .bo38-xs, .bo38-hover-xs:hover {
    border-color: #f0f2fd;
  }

  .f38-xs, .f38-hover-xs:hover {
    fill: #f0f2fd;
  }

  .c39-xs, .c39-hover-xs:hover {
    color: #f99229;
  }

  .bg39-xs, .bg39-hover-xs:hover {
    background-color: #f99229;
  }

  .bo39-xs, .bo39-hover-xs:hover {
    border-color: #f99229;
  }

  .f39-xs, .f39-hover-xs:hover {
    fill: #f99229;
  }

  .c40-xs, .c40-hover-xs:hover {
    color: #2947ef;
  }

  .bg40-xs, .bg40-hover-xs:hover {
    background-color: #2947ef;
  }

  .bo40-xs, .bo40-hover-xs:hover {
    border-color: #2947ef;
  }

  .f40-xs, .f40-hover-xs:hover {
    fill: #2947ef;
  }

  .c41-xs, .c41-hover-xs:hover {
    color: #f5f6ff;
  }

  .bg41-xs, .bg41-hover-xs:hover {
    background-color: #f5f6ff;
  }

  .bo41-xs, .bo41-hover-xs:hover {
    border-color: #f5f6ff;
  }

  .f41-xs, .f41-hover-xs:hover {
    fill: #f5f6ff;
  }

  .tal-xs {
    text-align: left;
  }

  .tac-xs {
    text-align: center;
  }

  .tar-xs {
    text-align: right;
  }

  .taj-xs {
    text-align: justify;
  }

  .tas-xs {
    text-align: start;
  }

  .tae-xs {
    text-align: end;
  }

  .fs0-xs {
    font-size: 0;
  }

  .fs0-25rem-xs {
    font-size: .25rem;
  }

  .fs0-125rem-xs {
    font-size: .125rem;
  }

  .fs0-5rem-xs {
    font-size: .5rem;
  }

  .fs0-75rem-xs {
    font-size: .75rem;
  }

  .fs0-875rem-xs {
    font-size: .875rem;
  }

  .fs1rem-xs {
    font-size: 1rem;
  }

  .fs1-25rem-xs {
    font-size: 1.25rem;
  }

  .fs1-125rem-xs {
    font-size: 1.125rem;
  }

  .fs1-5rem-xs {
    font-size: 1.5rem;
  }

  .fs1-75rem-xs {
    font-size: 1.75rem;
  }

  .fs1-875rem-xs {
    font-size: 1.875rem;
  }

  .fs2rem-xs {
    font-size: 2rem;
  }

  .fs2-25rem-xs {
    font-size: 2.25rem;
  }

  .fs2-125rem-xs {
    font-size: 2.125rem;
  }

  .fs2-5rem-xs {
    font-size: 2.5rem;
  }

  .fs2-75rem-xs {
    font-size: 2.75rem;
  }

  .fs2-875rem-xs {
    font-size: 2.875rem;
  }

  .fs3rem-xs {
    font-size: 3rem;
  }

  .fs3-25rem-xs {
    font-size: 3.25rem;
  }

  .fs3-125rem-xs {
    font-size: 3.125rem;
  }

  .fs3-5rem-xs {
    font-size: 3.5rem;
  }

  .fs3-75rem-xs {
    font-size: 3.75rem;
  }

  .fs3-875rem-xs {
    font-size: 3.875rem;
  }

  .fs4rem-xs {
    font-size: 4rem;
  }

  .fs4-25rem-xs {
    font-size: 4.25rem;
  }

  .fs4-125rem-xs {
    font-size: 4.125rem;
  }

  .fs4-5rem-xs {
    font-size: 4.5rem;
  }

  .fs4-75rem-xs {
    font-size: 4.75rem;
  }

  .fs4-875rem-xs {
    font-size: 4.875rem;
  }

  .fs5rem-xs {
    font-size: 5rem;
  }

  .fs5-25rem-xs {
    font-size: 5.25rem;
  }

  .fs5-125rem-xs {
    font-size: 5.125rem;
  }

  .fs5-5rem-xs {
    font-size: 5.5rem;
  }

  .fs5-75rem-xs {
    font-size: 5.75rem;
  }

  .fs5-875rem-xs {
    font-size: 5.875rem;
  }

  .fs6rem-xs {
    font-size: 6rem;
  }

  .fs6-25rem-xs {
    font-size: 6.25rem;
  }

  .fs6-125rem-xs {
    font-size: 6.125rem;
  }

  .fs6-5rem-xs {
    font-size: 6.5rem;
  }

  .fs6-75rem-xs {
    font-size: 6.75rem;
  }

  .fs6-875rem-xs {
    font-size: 6.875rem;
  }

  .fs7rem-xs {
    font-size: 7rem;
  }

  .db-xs {
    display: block;
  }

  .dib-xs {
    display: inline-block;
  }

  .di-xs {
    display: inline;
  }

  .df-xs {
    display: flex;
  }

  .fdc-xs {
    flex-direction: column;
  }

  .fdr-xs {
    flex-direction: row;
  }

  .fdcr-xs {
    flex-direction: column-reverse;
  }

  .fdrr-xs {
    flex-direction: row-reverse;
  }

  .fww-xs {
    flex-wrap: wrap;
  }

  .jcfs-xs {
    justify-content: flex-start;
  }

  .jcc-xs {
    justify-content: center;
  }

  .jcfe-xs {
    justify-content: flex-end;
  }

  .jcsb-xs {
    justify-content: space-between;
  }

  .jcsa-xs {
    justify-content: space-around;
  }

  .jcse-xs {
    justify-content: space-evenly;
  }

  .jce-xs {
    justify-content: end;
  }

  .aifs-xs {
    align-items: flex-start;
  }

  .aic-xs {
    align-items: center;
  }

  .aife-xs {
    align-items: flex-end;
  }

  .jss-xs {
    justify-self: start;
  }

  .jsfs-xs {
    justify-self: flex-start;
  }

  .jsc-xs {
    justify-self: center;
  }

  .jsfe-xs {
    justify-self: flex-end;
  }

  .jse-xs {
    justify-self: end;
  }

  .ass-xs {
    align-self: start;
  }

  .asfs-xs {
    align-self: flex-start;
  }

  .asc-xs {
    align-self: center;
  }

  .asfe-xs {
    align-self: flex-end;
  }

  .ase-xs {
    align-self: end;
  }

  .dif-xs {
    display: inline-flex;
  }

  .dg-xs {
    display: grid;
  }

  .brss-xs {
    border-right-style: solid;
  }

  .brw1px-xs {
    border-right-width: 1px;
  }

  .brw2px-xs {
    border-right-width: 2px;
  }

  .gtcr1-xs {
    grid-template-columns: repeat(1, 1fr);
  }

  .ftcr1-xs {
    grid-column: 1 / 1fr;
  }

  .gtrr1-xs {
    grid-template-rows: repeat(1, 1fr);
  }

  .gtcr1a-xs {
    grid-template-columns: repeat(1, auto);
  }

  .gtrr1a-xs {
    grid-template-rows: repeat(1, auto);
  }

  .gcs1-xs {
    grid-column-start: 1;
  }

  .gce2-xs {
    grid-column-end: 2;
  }

  .grs1-xs {
    grid-row-start: 1;
  }

  .gre2-xs {
    grid-row-end: 2;
  }

  .gtcr2-xs {
    grid-template-columns: repeat(2, 1fr);
  }

  .ftcr2-xs {
    grid-column: 2 / 1fr;
  }

  .gtrr2-xs {
    grid-template-rows: repeat(2, 1fr);
  }

  .gtcr2a-xs {
    grid-template-columns: repeat(2, auto);
  }

  .gtrr2a-xs {
    grid-template-rows: repeat(2, auto);
  }

  .gcs2-xs {
    grid-column-start: 2;
  }

  .gce3-xs {
    grid-column-end: 3;
  }

  .grs2-xs {
    grid-row-start: 2;
  }

  .gre3-xs {
    grid-row-end: 3;
  }

  .gtcr3-xs {
    grid-template-columns: repeat(3, 1fr);
  }

  .ftcr3-xs {
    grid-column: 3 / 1fr;
  }

  .gtrr3-xs {
    grid-template-rows: repeat(3, 1fr);
  }

  .gtcr3a-xs {
    grid-template-columns: repeat(3, auto);
  }

  .gtrr3a-xs {
    grid-template-rows: repeat(3, auto);
  }

  .gcs3-xs {
    grid-column-start: 3;
  }

  .gce4-xs {
    grid-column-end: 4;
  }

  .grs3-xs {
    grid-row-start: 3;
  }

  .gre4-xs {
    grid-row-end: 4;
  }

  .gtcr4-xs {
    grid-template-columns: repeat(4, 1fr);
  }

  .ftcr4-xs {
    grid-column: 4 / 1fr;
  }

  .gtrr4-xs {
    grid-template-rows: repeat(4, 1fr);
  }

  .gtcr4a-xs {
    grid-template-columns: repeat(4, auto);
  }

  .gtrr4a-xs {
    grid-template-rows: repeat(4, auto);
  }

  .gcs4-xs {
    grid-column-start: 4;
  }

  .gce5-xs {
    grid-column-end: 5;
  }

  .grs4-xs {
    grid-row-start: 4;
  }

  .gre5-xs {
    grid-row-end: 5;
  }

  .gtcr5-xs {
    grid-template-columns: repeat(5, 1fr);
  }

  .ftcr5-xs {
    grid-column: 5 / 1fr;
  }

  .gtrr5-xs {
    grid-template-rows: repeat(5, 1fr);
  }

  .gtcr5a-xs {
    grid-template-columns: repeat(5, auto);
  }

  .gtrr5a-xs {
    grid-template-rows: repeat(5, auto);
  }

  .gcs5-xs {
    grid-column-start: 5;
  }

  .gce6-xs {
    grid-column-end: 6;
  }

  .grs5-xs {
    grid-row-start: 5;
  }

  .gre6-xs {
    grid-row-end: 6;
  }

  .gtcr6-xs {
    grid-template-columns: repeat(6, 1fr);
  }

  .ftcr6-xs {
    grid-column: 6 / 1fr;
  }

  .gtrr6-xs {
    grid-template-rows: repeat(6, 1fr);
  }

  .gtcr6a-xs {
    grid-template-columns: repeat(6, auto);
  }

  .gtrr6a-xs {
    grid-template-rows: repeat(6, auto);
  }

  .gcs6-xs {
    grid-column-start: 6;
  }

  .gce7-xs {
    grid-column-end: 7;
  }

  .grs6-xs {
    grid-row-start: 6;
  }

  .gre7-xs {
    grid-row-end: 7;
  }

  .gtcr7-xs {
    grid-template-columns: repeat(7, 1fr);
  }

  .ftcr7-xs {
    grid-column: 7 / 1fr;
  }

  .gtrr7-xs {
    grid-template-rows: repeat(7, 1fr);
  }

  .gtcr7a-xs {
    grid-template-columns: repeat(7, auto);
  }

  .gtrr7a-xs {
    grid-template-rows: repeat(7, auto);
  }

  .gcs7-xs {
    grid-column-start: 7;
  }

  .gce8-xs {
    grid-column-end: 8;
  }

  .grs7-xs {
    grid-row-start: 7;
  }

  .gre8-xs {
    grid-row-end: 8;
  }

  .gtcr8-xs {
    grid-template-columns: repeat(8, 1fr);
  }

  .ftcr8-xs {
    grid-column: 8 / 1fr;
  }

  .gtrr8-xs {
    grid-template-rows: repeat(8, 1fr);
  }

  .gtcr8a-xs {
    grid-template-columns: repeat(8, auto);
  }

  .gtrr8a-xs {
    grid-template-rows: repeat(8, auto);
  }

  .gcs8-xs {
    grid-column-start: 8;
  }

  .gce9-xs {
    grid-column-end: 9;
  }

  .grs8-xs {
    grid-row-start: 8;
  }

  .gre9-xs {
    grid-row-end: 9;
  }

  .gtcr9-xs {
    grid-template-columns: repeat(9, 1fr);
  }

  .ftcr9-xs {
    grid-column: 9 / 1fr;
  }

  .gtrr9-xs {
    grid-template-rows: repeat(9, 1fr);
  }

  .gtcr9a-xs {
    grid-template-columns: repeat(9, auto);
  }

  .gtrr9a-xs {
    grid-template-rows: repeat(9, auto);
  }

  .gcs9-xs {
    grid-column-start: 9;
  }

  .gce10-xs {
    grid-column-end: 10;
  }

  .grs9-xs {
    grid-row-start: 9;
  }

  .gre10-xs {
    grid-row-end: 10;
  }

  .gtccr-xs {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }

  .gtcr10-xs {
    grid-template-columns: repeat(10, 1fr);
  }

  .ftcr10-xs {
    grid-column: 10 / 1fr;
  }

  .gtrr10-xs {
    grid-template-rows: repeat(10, 1fr);
  }

  .gtcr10a-xs {
    grid-template-columns: repeat(10, auto);
  }

  .gtrr10a-xs {
    grid-template-rows: repeat(10, auto);
  }

  .gcs10-xs {
    grid-column-start: 10;
  }

  .gce11-xs {
    grid-column-end: 11;
  }

  .grs10-xs {
    grid-row-start: 10;
  }

  .gre11-xs {
    grid-row-end: 11;
  }

  .gg0-5rem-xs {
    grid-gap: .5rem;
  }

  .gg1rem-xs {
    grid-gap: 1rem;
  }

  .rg0-5rem-xs {
    row-gap: .5rem;
  }

  .rg1rem-xs {
    row-gap: 1rem;
  }

  .cg0-5rem-xs {
    column-gap: .5rem;
  }

  .cg1rem-xs {
    column-gap: 1rem;
  }

  .gg1-5rem-xs {
    grid-gap: 1.5rem;
  }

  .gg2rem-xs {
    grid-gap: 2rem;
  }

  .rg1-5rem-xs {
    row-gap: 1.5rem;
  }

  .rg2rem-xs {
    row-gap: 2rem;
  }

  .cg1-5rem-xs {
    column-gap: 1.5rem;
  }

  .cg2rem-xs {
    column-gap: 2rem;
  }

  .gg2-5rem-xs {
    grid-gap: 2.5rem;
  }

  .gg3rem-xs {
    grid-gap: 3rem;
  }

  .rg2-5rem-xs {
    row-gap: 2.5rem;
  }

  .rg3rem-xs {
    row-gap: 3rem;
  }

  .cg2-5rem-xs {
    column-gap: 2.5rem;
  }

  .cg3rem-xs {
    column-gap: 3rem;
  }

  .gg3-5rem-xs {
    grid-gap: 3.5rem;
  }

  .gg4rem-xs {
    grid-gap: 4rem;
  }

  .rg3-5rem-xs {
    row-gap: 3.5rem;
  }

  .rg4rem-xs {
    row-gap: 4rem;
  }

  .cg3-5rem-xs {
    column-gap: 3.5rem;
  }

  .cg4rem-xs {
    column-gap: 4rem;
  }

  .gg4-5rem-xs {
    grid-gap: 4.5rem;
  }

  .gg5rem-xs {
    grid-gap: 5rem;
  }

  .rg4-5rem-xs {
    row-gap: 4.5rem;
  }

  .rg5rem-xs {
    row-gap: 5rem;
  }

  .cg4-5rem-xs {
    column-gap: 4.5rem;
  }

  .cg5rem-xs {
    column-gap: 5rem;
  }

  .dn-xs {
    display: none;
  }

  .pa-xs {
    position: absolute;
  }

  .pr-xs {
    position: relative;
  }

  .pf-xs {
    position: fixed;
  }

  .ps-xs {
    -webkit-position: sticky;
    position: sticky;
  }

  .pst-xs {
    position: static;
  }

  .t0-xs {
    top: 0;
  }

  .r0-xs {
    right: 0;
  }

  .b0-xs {
    bottom: 0;
  }

  .l0-xs {
    left: 0;
  }

  .t100-xs {
    top: 100%;
  }

  .r100-xs {
    right: 100%;
  }

  .b100-xs {
    bottom: 100%;
  }

  .l100-xs {
    left: 100%;
  }

  .fl-xs {
    float: left;
  }

  .fr-xs {
    float: right;
  }

  .fn-xs {
    float: none;
  }

  .ma-xs {
    margin: auto;
  }

  .mta-xs, .mya-xs {
    margin-top: auto;
  }

  .mra-xs, .mxa-xs {
    margin-right: auto;
  }

  .mba-xs, .mya-xs {
    margin-bottom: auto;
  }

  .mla-xs, .mxa-xs {
    margin-left: auto;
  }

  .m0-xs {
    margin: 0;
  }

  .mt0-xs, .my0-xs {
    margin-top: 0;
  }

  .mr0-xs, .mx0-xs {
    margin-right: 0;
  }

  .mb0-xs, .my0-xs {
    margin-bottom: 0;
  }

  .ml0-xs, .mx0-xs {
    margin-left: 0;
  }

  .p0-xs {
    padding: 0;
  }

  .pt0-xs, .py0-xs {
    padding-top: 0;
  }

  .pr0-xs, .px0-xs {
    padding-right: 0;
  }

  .pb0-xs, .py0-xs {
    padding-bottom: 0;
  }

  .pl0-xs, .px0-xs {
    padding-left: 0;
  }

  .m10-xs {
    margin: 10%;
  }

  .mt10-xs, .my10-xs {
    margin-top: 10%;
  }

  .mr10-xs, .mx10-xs {
    margin-right: 10%;
  }

  .mb10-xs, .my10-xs {
    margin-bottom: 10%;
  }

  .ml10-xs, .mx10-xs {
    margin-left: 10%;
  }

  .p10-xs {
    padding: 10%;
  }

  .pt10-xs, .py10-xs {
    padding-top: 10%;
  }

  .pr10-xs, .px10-xs {
    padding-right: 10%;
  }

  .pb10-xs, .py10-xs {
    padding-bottom: 10%;
  }

  .pl10-xs, .px10-xs {
    padding-left: 10%;
  }

  .m0-5rem-xs {
    margin: .5rem;
  }

  .m1rem-xs {
    margin: 1rem;
  }

  .mt0-5rem-xs, .my0-5rem-xs {
    margin-top: .5rem;
  }

  .mt1rem-xs, .my1rem-xs {
    margin-top: 1rem;
  }

  .mr0-5rem-xs, .mx0-5rem-xs {
    margin-right: .5rem;
  }

  .mr1rem-xs, .mx1rem-xs {
    margin-right: 1rem;
  }

  .mb0-5rem-xs, .my0-5rem-xs {
    margin-bottom: .5rem;
  }

  .mb1rem-xs, .my1rem-xs {
    margin-bottom: 1rem;
  }

  .ml0-5rem-xs, .mx0-5rem-xs {
    margin-left: .5rem;
  }

  .ml1rem-xs, .mx1rem-xs {
    margin-left: 1rem;
  }

  .p0-5rem-xs {
    padding: .5rem;
  }

  .p1rem-xs {
    padding: 1rem;
  }

  .pt0-5rem-xs, .py0-5rem-xs {
    padding-top: .5rem;
  }

  .pt1rem-xs, .py1rem-xs {
    padding-top: 1rem;
  }

  .pr0-5rem-xs, .px0-5rem-xs {
    padding-right: .5rem;
  }

  .pr1rem-xs, .px1rem-xs {
    padding-right: 1rem;
  }

  .pb0-5rem-xs, .py0-5rem-xs {
    padding-bottom: .5rem;
  }

  .pb1rem-xs, .py1rem-xs {
    padding-bottom: 1rem;
  }

  .pl0-5rem-xs, .px0-5rem-xs {
    padding-left: .5rem;
  }

  .pl1rem-xs, .px1rem-xs {
    padding-left: 1rem;
  }

  .m20-xs {
    margin: 20%;
  }

  .mt20-xs, .my20-xs {
    margin-top: 20%;
  }

  .mr20-xs, .mx20-xs {
    margin-right: 20%;
  }

  .mb20-xs, .my20-xs {
    margin-bottom: 20%;
  }

  .ml20-xs, .mx20-xs {
    margin-left: 20%;
  }

  .p20-xs {
    padding: 20%;
  }

  .pt20-xs, .py20-xs {
    padding-top: 20%;
  }

  .pr20-xs, .px20-xs {
    padding-right: 20%;
  }

  .pb20-xs, .py20-xs {
    padding-bottom: 20%;
  }

  .pl20-xs, .px20-xs {
    padding-left: 20%;
  }

  .m1-5rem-xs {
    margin: 1.5rem;
  }

  .m2rem-xs {
    margin: 2rem;
  }

  .mt1-5rem-xs, .my1-5rem-xs {
    margin-top: 1.5rem;
  }

  .mt2rem-xs, .my2rem-xs {
    margin-top: 2rem;
  }

  .mr1-5rem-xs, .mx1-5rem-xs {
    margin-right: 1.5rem;
  }

  .mr2rem-xs, .mx2rem-xs {
    margin-right: 2rem;
  }

  .mb1-5rem-xs, .my1-5rem-xs {
    margin-bottom: 1.5rem;
  }

  .mb2rem-xs, .my2rem-xs {
    margin-bottom: 2rem;
  }

  .ml1-5rem-xs, .mx1-5rem-xs {
    margin-left: 1.5rem;
  }

  .ml2rem-xs, .mx2rem-xs {
    margin-left: 2rem;
  }

  .p1-5rem-xs {
    padding: 1.5rem;
  }

  .p2rem-xs {
    padding: 2rem;
  }

  .pt1-5rem-xs, .py1-5rem-xs {
    padding-top: 1.5rem;
  }

  .pt2rem-xs, .py2rem-xs {
    padding-top: 2rem;
  }

  .pr1-5rem-xs, .px1-5rem-xs {
    padding-right: 1.5rem;
  }

  .pr2rem-xs, .px2rem-xs {
    padding-right: 2rem;
  }

  .pb1-5rem-xs, .py1-5rem-xs {
    padding-bottom: 1.5rem;
  }

  .pb2rem-xs, .py2rem-xs {
    padding-bottom: 2rem;
  }

  .pl1-5rem-xs, .px1-5rem-xs {
    padding-left: 1.5rem;
  }

  .pl2rem-xs, .px2rem-xs {
    padding-left: 2rem;
  }

  .m30-xs {
    margin: 30%;
  }

  .mt30-xs, .my30-xs {
    margin-top: 30%;
  }

  .mr30-xs, .mx30-xs {
    margin-right: 30%;
  }

  .mb30-xs, .my30-xs {
    margin-bottom: 30%;
  }

  .ml30-xs, .mx30-xs {
    margin-left: 30%;
  }

  .p30-xs {
    padding: 30%;
  }

  .pt30-xs, .py30-xs {
    padding-top: 30%;
  }

  .pr30-xs, .px30-xs {
    padding-right: 30%;
  }

  .pb30-xs, .py30-xs {
    padding-bottom: 30%;
  }

  .pl30-xs, .px30-xs {
    padding-left: 30%;
  }

  .m2-5rem-xs {
    margin: 2.5rem;
  }

  .m3rem-xs {
    margin: 3rem;
  }

  .mt2-5rem-xs, .my2-5rem-xs {
    margin-top: 2.5rem;
  }

  .mt3rem-xs, .my3rem-xs {
    margin-top: 3rem;
  }

  .mr2-5rem-xs, .mx2-5rem-xs {
    margin-right: 2.5rem;
  }

  .mr3rem-xs, .mx3rem-xs {
    margin-right: 3rem;
  }

  .mb2-5rem-xs, .my2-5rem-xs {
    margin-bottom: 2.5rem;
  }

  .mb3rem-xs, .my3rem-xs {
    margin-bottom: 3rem;
  }

  .ml2-5rem-xs, .mx2-5rem-xs {
    margin-left: 2.5rem;
  }

  .ml3rem-xs, .mx3rem-xs {
    margin-left: 3rem;
  }

  .p2-5rem-xs {
    padding: 2.5rem;
  }

  .p3rem-xs {
    padding: 3rem;
  }

  .pt2-5rem-xs, .py2-5rem-xs {
    padding-top: 2.5rem;
  }

  .pt3rem-xs, .py3rem-xs {
    padding-top: 3rem;
  }

  .pr2-5rem-xs, .px2-5rem-xs {
    padding-right: 2.5rem;
  }

  .pr3rem-xs, .px3rem-xs {
    padding-right: 3rem;
  }

  .pb2-5rem-xs, .py2-5rem-xs {
    padding-bottom: 2.5rem;
  }

  .pb3rem-xs, .py3rem-xs {
    padding-bottom: 3rem;
  }

  .pl2-5rem-xs, .px2-5rem-xs {
    padding-left: 2.5rem;
  }

  .pl3rem-xs, .px3rem-xs {
    padding-left: 3rem;
  }

  .m40-xs {
    margin: 40%;
  }

  .mt40-xs, .my40-xs {
    margin-top: 40%;
  }

  .mr40-xs, .mx40-xs {
    margin-right: 40%;
  }

  .mb40-xs, .my40-xs {
    margin-bottom: 40%;
  }

  .ml40-xs, .mx40-xs {
    margin-left: 40%;
  }

  .p40-xs {
    padding: 40%;
  }

  .pt40-xs, .py40-xs {
    padding-top: 40%;
  }

  .pr40-xs, .px40-xs {
    padding-right: 40%;
  }

  .pb40-xs, .py40-xs {
    padding-bottom: 40%;
  }

  .pl40-xs, .px40-xs {
    padding-left: 40%;
  }

  .m3-5rem-xs {
    margin: 3.5rem;
  }

  .m4rem-xs {
    margin: 4rem;
  }

  .mt3-5rem-xs, .my3-5rem-xs {
    margin-top: 3.5rem;
  }

  .mt4rem-xs, .my4rem-xs {
    margin-top: 4rem;
  }

  .mr3-5rem-xs, .mx3-5rem-xs {
    margin-right: 3.5rem;
  }

  .mr4rem-xs, .mx4rem-xs {
    margin-right: 4rem;
  }

  .mb3-5rem-xs, .my3-5rem-xs {
    margin-bottom: 3.5rem;
  }

  .mb4rem-xs, .my4rem-xs {
    margin-bottom: 4rem;
  }

  .ml3-5rem-xs, .mx3-5rem-xs {
    margin-left: 3.5rem;
  }

  .ml4rem-xs, .mx4rem-xs {
    margin-left: 4rem;
  }

  .p3-5rem-xs {
    padding: 3.5rem;
  }

  .p4rem-xs {
    padding: 4rem;
  }

  .pt3-5rem-xs, .py3-5rem-xs {
    padding-top: 3.5rem;
  }

  .pt4rem-xs, .py4rem-xs {
    padding-top: 4rem;
  }

  .pr3-5rem-xs, .px3-5rem-xs {
    padding-right: 3.5rem;
  }

  .pr4rem-xs, .px4rem-xs {
    padding-right: 4rem;
  }

  .pb3-5rem-xs, .py3-5rem-xs {
    padding-bottom: 3.5rem;
  }

  .pb4rem-xs, .py4rem-xs {
    padding-bottom: 4rem;
  }

  .pl3-5rem-xs, .px3-5rem-xs {
    padding-left: 3.5rem;
  }

  .pl4rem-xs, .px4rem-xs {
    padding-left: 4rem;
  }

  .m50-xs {
    margin: 50%;
  }

  .mt50-xs, .my50-xs {
    margin-top: 50%;
  }

  .mr50-xs, .mx50-xs {
    margin-right: 50%;
  }

  .mb50-xs, .my50-xs {
    margin-bottom: 50%;
  }

  .ml50-xs, .mx50-xs {
    margin-left: 50%;
  }

  .p50-xs {
    padding: 50%;
  }

  .pt50-xs, .py50-xs {
    padding-top: 50%;
  }

  .pr50-xs, .px50-xs {
    padding-right: 50%;
  }

  .pb50-xs, .py50-xs {
    padding-bottom: 50%;
  }

  .pl50-xs, .px50-xs {
    padding-left: 50%;
  }

  .m4-5rem-xs {
    margin: 4.5rem;
  }

  .m5rem-xs {
    margin: 5rem;
  }

  .mt4-5rem-xs, .my4-5rem-xs {
    margin-top: 4.5rem;
  }

  .mt5rem-xs, .my5rem-xs {
    margin-top: 5rem;
  }

  .mr4-5rem-xs, .mx4-5rem-xs {
    margin-right: 4.5rem;
  }

  .mr5rem-xs, .mx5rem-xs {
    margin-right: 5rem;
  }

  .mb4-5rem-xs, .my4-5rem-xs {
    margin-bottom: 4.5rem;
  }

  .mb5rem-xs, .my5rem-xs {
    margin-bottom: 5rem;
  }

  .ml4-5rem-xs, .mx4-5rem-xs {
    margin-left: 4.5rem;
  }

  .ml5rem-xs, .mx5rem-xs {
    margin-left: 5rem;
  }

  .p4-5rem-xs {
    padding: 4.5rem;
  }

  .p5rem-xs {
    padding: 5rem;
  }

  .pt4-5rem-xs, .py4-5rem-xs {
    padding-top: 4.5rem;
  }

  .pt5rem-xs, .py5rem-xs {
    padding-top: 5rem;
  }

  .pr4-5rem-xs, .px4-5rem-xs {
    padding-right: 4.5rem;
  }

  .pr5rem-xs, .px5rem-xs {
    padding-right: 5rem;
  }

  .pb4-5rem-xs, .py4-5rem-xs {
    padding-bottom: 4.5rem;
  }

  .pb5rem-xs, .py5rem-xs {
    padding-bottom: 5rem;
  }

  .pl4-5rem-xs, .px4-5rem-xs {
    padding-left: 4.5rem;
  }

  .pl5rem-xs, .px5rem-xs {
    padding-left: 5rem;
  }

  .m60-xs {
    margin: 60%;
  }

  .mt60-xs, .my60-xs {
    margin-top: 60%;
  }

  .mr60-xs, .mx60-xs {
    margin-right: 60%;
  }

  .mb60-xs, .my60-xs {
    margin-bottom: 60%;
  }

  .ml60-xs, .mx60-xs {
    margin-left: 60%;
  }

  .p60-xs {
    padding: 60%;
  }

  .pt60-xs, .py60-xs {
    padding-top: 60%;
  }

  .pr60-xs, .px60-xs {
    padding-right: 60%;
  }

  .pb60-xs, .py60-xs {
    padding-bottom: 60%;
  }

  .pl60-xs, .px60-xs {
    padding-left: 60%;
  }

  .m5-5rem-xs {
    margin: 5.5rem;
  }

  .m6rem-xs {
    margin: 6rem;
  }

  .mt5-5rem-xs, .my5-5rem-xs {
    margin-top: 5.5rem;
  }

  .mt6rem-xs, .my6rem-xs {
    margin-top: 6rem;
  }

  .mr5-5rem-xs, .mx5-5rem-xs {
    margin-right: 5.5rem;
  }

  .mr6rem-xs, .mx6rem-xs {
    margin-right: 6rem;
  }

  .mb5-5rem-xs, .my5-5rem-xs {
    margin-bottom: 5.5rem;
  }

  .mb6rem-xs, .my6rem-xs {
    margin-bottom: 6rem;
  }

  .ml5-5rem-xs, .mx5-5rem-xs {
    margin-left: 5.5rem;
  }

  .ml6rem-xs, .mx6rem-xs {
    margin-left: 6rem;
  }

  .p5-5rem-xs {
    padding: 5.5rem;
  }

  .p6rem-xs {
    padding: 6rem;
  }

  .pt5-5rem-xs, .py5-5rem-xs {
    padding-top: 5.5rem;
  }

  .pt6rem-xs, .py6rem-xs {
    padding-top: 6rem;
  }

  .pr5-5rem-xs, .px5-5rem-xs {
    padding-right: 5.5rem;
  }

  .pr6rem-xs, .px6rem-xs {
    padding-right: 6rem;
  }

  .pb5-5rem-xs, .py5-5rem-xs {
    padding-bottom: 5.5rem;
  }

  .pb6rem-xs, .py6rem-xs {
    padding-bottom: 6rem;
  }

  .pl5-5rem-xs, .px5-5rem-xs {
    padding-left: 5.5rem;
  }

  .pl6rem-xs, .px6rem-xs {
    padding-left: 6rem;
  }

  .m70-xs {
    margin: 70%;
  }

  .mt70-xs, .my70-xs {
    margin-top: 70%;
  }

  .mr70-xs, .mx70-xs {
    margin-right: 70%;
  }

  .mb70-xs, .my70-xs {
    margin-bottom: 70%;
  }

  .ml70-xs, .mx70-xs {
    margin-left: 70%;
  }

  .p70-xs {
    padding: 70%;
  }

  .pt70-xs, .py70-xs {
    padding-top: 70%;
  }

  .pr70-xs, .px70-xs {
    padding-right: 70%;
  }

  .pb70-xs, .py70-xs {
    padding-bottom: 70%;
  }

  .pl70-xs, .px70-xs {
    padding-left: 70%;
  }

  .m6-5rem-xs {
    margin: 6.5rem;
  }

  .m7rem-xs {
    margin: 7rem;
  }

  .mt6-5rem-xs, .my6-5rem-xs {
    margin-top: 6.5rem;
  }

  .mt7rem-xs, .my7rem-xs {
    margin-top: 7rem;
  }

  .mr6-5rem-xs, .mx6-5rem-xs {
    margin-right: 6.5rem;
  }

  .mr7rem-xs, .mx7rem-xs {
    margin-right: 7rem;
  }

  .mb6-5rem-xs, .my6-5rem-xs {
    margin-bottom: 6.5rem;
  }

  .mb7rem-xs, .my7rem-xs {
    margin-bottom: 7rem;
  }

  .ml6-5rem-xs, .mx6-5rem-xs {
    margin-left: 6.5rem;
  }

  .ml7rem-xs, .mx7rem-xs {
    margin-left: 7rem;
  }

  .p6-5rem-xs {
    padding: 6.5rem;
  }

  .p7rem-xs {
    padding: 7rem;
  }

  .pt6-5rem-xs, .py6-5rem-xs {
    padding-top: 6.5rem;
  }

  .pt7rem-xs, .py7rem-xs {
    padding-top: 7rem;
  }

  .pr6-5rem-xs, .px6-5rem-xs {
    padding-right: 6.5rem;
  }

  .pr7rem-xs, .px7rem-xs {
    padding-right: 7rem;
  }

  .pb6-5rem-xs, .py6-5rem-xs {
    padding-bottom: 6.5rem;
  }

  .pb7rem-xs, .py7rem-xs {
    padding-bottom: 7rem;
  }

  .pl6-5rem-xs, .px6-5rem-xs {
    padding-left: 6.5rem;
  }

  .pl7rem-xs, .px7rem-xs {
    padding-left: 7rem;
  }

  .m80-xs {
    margin: 80%;
  }

  .mt80-xs, .my80-xs {
    margin-top: 80%;
  }

  .mr80-xs, .mx80-xs {
    margin-right: 80%;
  }

  .mb80-xs, .my80-xs {
    margin-bottom: 80%;
  }

  .ml80-xs, .mx80-xs {
    margin-left: 80%;
  }

  .p80-xs {
    padding: 80%;
  }

  .pt80-xs, .py80-xs {
    padding-top: 80%;
  }

  .pr80-xs, .px80-xs {
    padding-right: 80%;
  }

  .pb80-xs, .py80-xs {
    padding-bottom: 80%;
  }

  .pl80-xs, .px80-xs {
    padding-left: 80%;
  }

  .m7-5rem-xs {
    margin: 7.5rem;
  }

  .m8rem-xs {
    margin: 8rem;
  }

  .mt7-5rem-xs, .my7-5rem-xs {
    margin-top: 7.5rem;
  }

  .mt8rem-xs, .my8rem-xs {
    margin-top: 8rem;
  }

  .mr7-5rem-xs, .mx7-5rem-xs {
    margin-right: 7.5rem;
  }

  .mr8rem-xs, .mx8rem-xs {
    margin-right: 8rem;
  }

  .mb7-5rem-xs, .my7-5rem-xs {
    margin-bottom: 7.5rem;
  }

  .mb8rem-xs, .my8rem-xs {
    margin-bottom: 8rem;
  }

  .ml7-5rem-xs, .mx7-5rem-xs {
    margin-left: 7.5rem;
  }

  .ml8rem-xs, .mx8rem-xs {
    margin-left: 8rem;
  }

  .p7-5rem-xs {
    padding: 7.5rem;
  }

  .p8rem-xs {
    padding: 8rem;
  }

  .pt7-5rem-xs, .py7-5rem-xs {
    padding-top: 7.5rem;
  }

  .pt8rem-xs, .py8rem-xs {
    padding-top: 8rem;
  }

  .pr7-5rem-xs, .px7-5rem-xs {
    padding-right: 7.5rem;
  }

  .pr8rem-xs, .px8rem-xs {
    padding-right: 8rem;
  }

  .pb7-5rem-xs, .py7-5rem-xs {
    padding-bottom: 7.5rem;
  }

  .pb8rem-xs, .py8rem-xs {
    padding-bottom: 8rem;
  }

  .pl7-5rem-xs, .px7-5rem-xs {
    padding-left: 7.5rem;
  }

  .pl8rem-xs, .px8rem-xs {
    padding-left: 8rem;
  }

  .m90-xs {
    margin: 90%;
  }

  .mt90-xs, .my90-xs {
    margin-top: 90%;
  }

  .mr90-xs, .mx90-xs {
    margin-right: 90%;
  }

  .mb90-xs, .my90-xs {
    margin-bottom: 90%;
  }

  .ml90-xs, .mx90-xs {
    margin-left: 90%;
  }

  .p90-xs {
    padding: 90%;
  }

  .pt90-xs, .py90-xs {
    padding-top: 90%;
  }

  .pr90-xs, .px90-xs {
    padding-right: 90%;
  }

  .pb90-xs, .py90-xs {
    padding-bottom: 90%;
  }

  .pl90-xs, .px90-xs {
    padding-left: 90%;
  }

  .m8-5rem-xs {
    margin: 8.5rem;
  }

  .m9rem-xs {
    margin: 9rem;
  }

  .mt8-5rem-xs, .my8-5rem-xs {
    margin-top: 8.5rem;
  }

  .mt9rem-xs, .my9rem-xs {
    margin-top: 9rem;
  }

  .mr8-5rem-xs, .mx8-5rem-xs {
    margin-right: 8.5rem;
  }

  .mr9rem-xs, .mx9rem-xs {
    margin-right: 9rem;
  }

  .mb8-5rem-xs, .my8-5rem-xs {
    margin-bottom: 8.5rem;
  }

  .mb9rem-xs, .my9rem-xs {
    margin-bottom: 9rem;
  }

  .ml8-5rem-xs, .mx8-5rem-xs {
    margin-left: 8.5rem;
  }

  .ml9rem-xs, .mx9rem-xs {
    margin-left: 9rem;
  }

  .p8-5rem-xs {
    padding: 8.5rem;
  }

  .p9rem-xs {
    padding: 9rem;
  }

  .pt8-5rem-xs, .py8-5rem-xs {
    padding-top: 8.5rem;
  }

  .pt9rem-xs, .py9rem-xs {
    padding-top: 9rem;
  }

  .pr8-5rem-xs, .px8-5rem-xs {
    padding-right: 8.5rem;
  }

  .pr9rem-xs, .px9rem-xs {
    padding-right: 9rem;
  }

  .pb8-5rem-xs, .py8-5rem-xs {
    padding-bottom: 8.5rem;
  }

  .pb9rem-xs, .py9rem-xs {
    padding-bottom: 9rem;
  }

  .pl8-5rem-xs, .px8-5rem-xs {
    padding-left: 8.5rem;
  }

  .pl9rem-xs, .px9rem-xs {
    padding-left: 9rem;
  }

  .m100-xs {
    margin: 100%;
  }

  .mt100-xs, .my100-xs {
    margin-top: 100%;
  }

  .mr100-xs, .mx100-xs {
    margin-right: 100%;
  }

  .mb100-xs, .my100-xs {
    margin-bottom: 100%;
  }

  .ml100-xs, .mx100-xs {
    margin-left: 100%;
  }

  .p100-xs {
    padding: 100%;
  }

  .pt100-xs, .py100-xs {
    padding-top: 100%;
  }

  .pr100-xs, .px100-xs {
    padding-right: 100%;
  }

  .pb100-xs, .py100-xs {
    padding-bottom: 100%;
  }

  .pl100-xs, .px100-xs {
    padding-left: 100%;
  }

  .m9-5rem-xs {
    margin: 9.5rem;
  }

  .m10rem-xs {
    margin: 10rem;
  }

  .mt9-5rem-xs, .my9-5rem-xs {
    margin-top: 9.5rem;
  }

  .mt10rem-xs, .my10rem-xs {
    margin-top: 10rem;
  }

  .mr9-5rem-xs, .mx9-5rem-xs {
    margin-right: 9.5rem;
  }

  .mr10rem-xs, .mx10rem-xs {
    margin-right: 10rem;
  }

  .mb9-5rem-xs, .my9-5rem-xs {
    margin-bottom: 9.5rem;
  }

  .mb10rem-xs, .my10rem-xs {
    margin-bottom: 10rem;
  }

  .ml9-5rem-xs, .mx9-5rem-xs {
    margin-left: 9.5rem;
  }

  .ml10rem-xs, .mx10rem-xs {
    margin-left: 10rem;
  }

  .p9-5rem-xs {
    padding: 9.5rem;
  }

  .p10rem-xs {
    padding: 10rem;
  }

  .pt9-5rem-xs, .py9-5rem-xs {
    padding-top: 9.5rem;
  }

  .pt10rem-xs, .py10rem-xs {
    padding-top: 10rem;
  }

  .pr9-5rem-xs, .px9-5rem-xs {
    padding-right: 9.5rem;
  }

  .pr10rem-xs, .px10rem-xs {
    padding-right: 10rem;
  }

  .pb9-5rem-xs, .py9-5rem-xs {
    padding-bottom: 9.5rem;
  }

  .pb10rem-xs, .py10rem-xs {
    padding-bottom: 10rem;
  }

  .pl9-5rem-xs, .px9-5rem-xs {
    padding-left: 9.5rem;
  }

  .pl10rem-xs, .px10rem-xs {
    padding-left: 10rem;
  }

  .wa-xs {
    width: auto;
  }

  .ha-xs {
    height: auto;
  }

  .w0-xs {
    width: 0;
  }

  .h0-xs {
    height: 0;
  }

  .maw0-xs {
    max-width: 0;
  }

  .mah0-xs {
    max-height: 0;
  }

  .mih0-xs {
    min-height: 0;
  }

  .w1rem-xs {
    width: 1rem;
  }

  .w10px-xs {
    width: 10px;
  }

  .w10-xs {
    width: 10%;
  }

  .w10vw-xs {
    width: 10vw;
  }

  .h1rem-xs {
    height: 1rem;
  }

  .h10px-xs {
    height: 10px;
  }

  .h10-xs {
    height: 10%;
  }

  .h10vh-xs {
    height: 10vh;
  }

  .miw1rem-xs {
    min-width: 1rem;
  }

  .miw100px-xs {
    min-width: 100px;
  }

  .miw10-xs {
    min-width: 10%;
  }

  .miw10vw-xs {
    min-width: 10vw;
  }

  .mih1rem-xs {
    min-height: 1rem;
  }

  .mih100px-xs {
    min-height: 100px;
  }

  .mih10-xs {
    min-height: 10%;
  }

  .mih10vh-xs {
    min-height: 10vh;
  }

  .maw1rem-xs {
    max-width: 1rem;
  }

  .maw100px-xs {
    max-width: 100px;
  }

  .maw10-xs {
    max-width: 10%;
  }

  .maw10vw-xs {
    max-width: 10vw;
  }

  .mah1rem-xs {
    max-height: 1rem;
  }

  .mah100px-xs {
    max-height: 100px;
  }

  .mah10-xs {
    max-height: 10%;
  }

  .mah10vh-xs {
    max-height: 10vh;
  }

  .w2rem-xs {
    width: 2rem;
  }

  .w20px-xs {
    width: 20px;
  }

  .w200px-xs {
    width: 200px;
  }

  .w20-xs {
    width: 20%;
  }

  .w20vw-xs {
    width: 20vw;
  }

  .h2rem-xs {
    height: 2rem;
  }

  .h20px-xs {
    height: 20px;
  }

  .h200px-xs {
    height: 200px;
  }

  .h20-xs {
    height: 20%;
  }

  .h20vh-xs {
    height: 20vh;
  }

  .miw2rem-xs {
    min-width: 2rem;
  }

  .miw200px-xs {
    min-width: 200px;
  }

  .miw20-xs {
    min-width: 20%;
  }

  .miw20vw-xs {
    min-width: 20vw;
  }

  .mih2rem-xs {
    min-height: 2rem;
  }

  .mih200px-xs {
    min-height: 200px;
  }

  .mih20-xs {
    min-height: 20%;
  }

  .mih20vh-xs {
    min-height: 20vh;
  }

  .maw2rem-xs {
    max-width: 2rem;
  }

  .maw200px-xs {
    max-width: 200px;
  }

  .maw20-xs {
    max-width: 20%;
  }

  .maw20vw-xs {
    max-width: 20vw;
  }

  .mah2rem-xs {
    max-height: 2rem;
  }

  .mah200px-xs {
    max-height: 200px;
  }

  .mah20-xs {
    max-height: 20%;
  }

  .mah20vh-xs {
    max-height: 20vh;
  }

  .w3rem-xs {
    width: 3rem;
  }

  .w30px-xs {
    width: 30px;
  }

  .w300px-xs {
    width: 300px;
  }

  .w30-xs {
    width: 30%;
  }

  .w30vw-xs {
    width: 30vw;
  }

  .h3rem-xs {
    height: 3rem;
  }

  .h30px-xs {
    height: 30px;
  }

  .h300px-xs {
    height: 300px;
  }

  .h30-xs {
    height: 30%;
  }

  .h30vh-xs {
    height: 30vh;
  }

  .miw3rem-xs {
    min-width: 3rem;
  }

  .miw300px-xs {
    min-width: 300px;
  }

  .miw30-xs {
    min-width: 30%;
  }

  .miw30vw-xs {
    min-width: 30vw;
  }

  .mih3rem-xs {
    min-height: 3rem;
  }

  .mih300px-xs {
    min-height: 300px;
  }

  .mih30-xs {
    min-height: 30%;
  }

  .mih30vh-xs {
    min-height: 30vh;
  }

  .maw3rem-xs {
    max-width: 3rem;
  }

  .maw300px-xs {
    max-width: 300px;
  }

  .maw30-xs {
    max-width: 30%;
  }

  .maw30vw-xs {
    max-width: 30vw;
  }

  .mah3rem-xs {
    max-height: 3rem;
  }

  .mah300px-xs {
    max-height: 300px;
  }

  .mah30-xs {
    max-height: 30%;
  }

  .mah30vh-xs {
    max-height: 30vh;
  }

  .w4rem-xs {
    width: 4rem;
  }

  .w40px-xs {
    width: 40px;
  }

  .w400px-xs {
    width: 400px;
  }

  .w40-xs {
    width: 40%;
  }

  .w40vw-xs {
    width: 40vw;
  }

  .h4rem-xs {
    height: 4rem;
  }

  .h40px-xs {
    height: 40px;
  }

  .h400px-xs {
    height: 400px;
  }

  .h40-xs {
    height: 40%;
  }

  .h40vh-xs {
    height: 40vh;
  }

  .miw4rem-xs {
    min-width: 4rem;
  }

  .miw400px-xs {
    min-width: 400px;
  }

  .miw40-xs {
    min-width: 40%;
  }

  .miw40vw-xs {
    min-width: 40vw;
  }

  .mih4rem-xs {
    min-height: 4rem;
  }

  .mih400px-xs {
    min-height: 400px;
  }

  .mih40-xs {
    min-height: 40%;
  }

  .mih40vh-xs {
    min-height: 40vh;
  }

  .maw4rem-xs {
    max-width: 4rem;
  }

  .maw400px-xs {
    max-width: 400px;
  }

  .maw40-xs {
    max-width: 40%;
  }

  .maw40vw-xs {
    max-width: 40vw;
  }

  .mah4rem-xs {
    max-height: 4rem;
  }

  .mah400px-xs {
    max-height: 400px;
  }

  .mah40-xs {
    max-height: 40%;
  }

  .mah40vh-xs {
    max-height: 40vh;
  }

  .w5rem-xs {
    width: 5rem;
  }

  .w50px-xs {
    width: 50px;
  }

  .w500px-xs {
    width: 500px;
  }

  .w50-xs {
    width: 50%;
  }

  .w50vw-xs {
    width: 50vw;
  }

  .h5rem-xs {
    height: 5rem;
  }

  .h50px-xs {
    height: 50px;
  }

  .h500px-xs {
    height: 500px;
  }

  .h50-xs {
    height: 50%;
  }

  .h50vh-xs {
    height: 50vh;
  }

  .miw5rem-xs {
    min-width: 5rem;
  }

  .miw500px-xs {
    min-width: 500px;
  }

  .miw50-xs {
    min-width: 50%;
  }

  .miw50vw-xs {
    min-width: 50vw;
  }

  .mih5rem-xs {
    min-height: 5rem;
  }

  .mih500px-xs {
    min-height: 500px;
  }

  .mih50-xs {
    min-height: 50%;
  }

  .mih50vh-xs {
    min-height: 50vh;
  }

  .maw5rem-xs {
    max-width: 5rem;
  }

  .maw500px-xs {
    max-width: 500px;
  }

  .maw50-xs {
    max-width: 50%;
  }

  .maw50vw-xs {
    max-width: 50vw;
  }

  .mah5rem-xs {
    max-height: 5rem;
  }

  .mah500px-xs {
    max-height: 500px;
  }

  .mah50-xs {
    max-height: 50%;
  }

  .mah50vh-xs {
    max-height: 50vh;
  }

  .w6rem-xs {
    width: 6rem;
  }

  .w60px-xs {
    width: 60px;
  }

  .w600px-xs {
    width: 600px;
  }

  .w60-xs {
    width: 60%;
  }

  .w60vw-xs {
    width: 60vw;
  }

  .h6rem-xs {
    height: 6rem;
  }

  .h60px-xs {
    height: 60px;
  }

  .h600px-xs {
    height: 600px;
  }

  .h60-xs {
    height: 60%;
  }

  .h60vh-xs {
    height: 60vh;
  }

  .miw6rem-xs {
    min-width: 6rem;
  }

  .miw600px-xs {
    min-width: 600px;
  }

  .miw60-xs {
    min-width: 60%;
  }

  .miw60vw-xs {
    min-width: 60vw;
  }

  .mih6rem-xs {
    min-height: 6rem;
  }

  .mih600px-xs {
    min-height: 600px;
  }

  .mih60-xs {
    min-height: 60%;
  }

  .mih60vh-xs {
    min-height: 60vh;
  }

  .maw6rem-xs {
    max-width: 6rem;
  }

  .maw600px-xs {
    max-width: 600px;
  }

  .maw60-xs {
    max-width: 60%;
  }

  .maw60vw-xs {
    max-width: 60vw;
  }

  .mah6rem-xs {
    max-height: 6rem;
  }

  .mah600px-xs {
    max-height: 600px;
  }

  .mah60-xs {
    max-height: 60%;
  }

  .mah60vh-xs {
    max-height: 60vh;
  }

  .w7rem-xs {
    width: 7rem;
  }

  .w70px-xs {
    width: 70px;
  }

  .w700px-xs {
    width: 700px;
  }

  .w70-xs {
    width: 70%;
  }

  .w70vw-xs {
    width: 70vw;
  }

  .h7rem-xs {
    height: 7rem;
  }

  .h70px-xs {
    height: 70px;
  }

  .h700px-xs {
    height: 700px;
  }

  .h70-xs {
    height: 70%;
  }

  .h70vh-xs {
    height: 70vh;
  }

  .miw7rem-xs {
    min-width: 7rem;
  }

  .miw700px-xs {
    min-width: 700px;
  }

  .miw70-xs {
    min-width: 70%;
  }

  .miw70vw-xs {
    min-width: 70vw;
  }

  .mih7rem-xs {
    min-height: 7rem;
  }

  .mih700px-xs {
    min-height: 700px;
  }

  .mih70-xs {
    min-height: 70%;
  }

  .mih70vh-xs {
    min-height: 70vh;
  }

  .maw7rem-xs {
    max-width: 7rem;
  }

  .maw700px-xs {
    max-width: 700px;
  }

  .maw70-xs {
    max-width: 70%;
  }

  .maw70vw-xs {
    max-width: 70vw;
  }

  .mah7rem-xs {
    max-height: 7rem;
  }

  .mah700px-xs {
    max-height: 700px;
  }

  .mah70-xs {
    max-height: 70%;
  }

  .mah70vh-xs {
    max-height: 70vh;
  }

  .w8rem-xs {
    width: 8rem;
  }

  .w80px-xs {
    width: 80px;
  }

  .w800px-xs {
    width: 800px;
  }

  .w80-xs {
    width: 80%;
  }

  .w80vw-xs {
    width: 80vw;
  }

  .h8rem-xs {
    height: 8rem;
  }

  .h80px-xs {
    height: 80px;
  }

  .h800px-xs {
    height: 800px;
  }

  .h80-xs {
    height: 80%;
  }

  .h80vh-xs {
    height: 80vh;
  }

  .miw8rem-xs {
    min-width: 8rem;
  }

  .miw800px-xs {
    min-width: 800px;
  }

  .miw80-xs {
    min-width: 80%;
  }

  .miw80vw-xs {
    min-width: 80vw;
  }

  .mih8rem-xs {
    min-height: 8rem;
  }

  .mih800px-xs {
    min-height: 800px;
  }

  .mih80-xs {
    min-height: 80%;
  }

  .mih80vh-xs {
    min-height: 80vh;
  }

  .maw8rem-xs {
    max-width: 8rem;
  }

  .maw800px-xs {
    max-width: 800px;
  }

  .maw80-xs {
    max-width: 80%;
  }

  .maw80vw-xs {
    max-width: 80vw;
  }

  .mah8rem-xs {
    max-height: 8rem;
  }

  .mah800px-xs {
    max-height: 800px;
  }

  .mah80-xs {
    max-height: 80%;
  }

  .mah80vh-xs {
    max-height: 80vh;
  }

  .w9rem-xs {
    width: 9rem;
  }

  .w90px-xs {
    width: 90px;
  }

  .w900px-xs {
    width: 900px;
  }

  .w90-xs {
    width: 90%;
  }

  .w90vw-xs {
    width: 90vw;
  }

  .h9rem-xs {
    height: 9rem;
  }

  .h90px-xs {
    height: 90px;
  }

  .h900px-xs {
    height: 900px;
  }

  .h90-xs {
    height: 90%;
  }

  .h90vh-xs {
    height: 90vh;
  }

  .miw9rem-xs {
    min-width: 9rem;
  }

  .miw900px-xs {
    min-width: 900px;
  }

  .miw90-xs {
    min-width: 90%;
  }

  .miw90vw-xs {
    min-width: 90vw;
  }

  .mih9rem-xs {
    min-height: 9rem;
  }

  .mih900px-xs {
    min-height: 900px;
  }

  .mih90-xs {
    min-height: 90%;
  }

  .mih90vh-xs {
    min-height: 90vh;
  }

  .maw9rem-xs {
    max-width: 9rem;
  }

  .maw900px-xs {
    max-width: 900px;
  }

  .maw90-xs {
    max-width: 90%;
  }

  .maw90vw-xs {
    max-width: 90vw;
  }

  .mah9rem-xs {
    max-height: 9rem;
  }

  .mah900px-xs {
    max-height: 900px;
  }

  .mah90-xs {
    max-height: 90%;
  }

  .mah90vh-xs {
    max-height: 90vh;
  }

  .w10rem-xs {
    width: 10rem;
  }

  .w100px-xs {
    width: 100px;
  }

  .w1000px-xs {
    width: 1000px;
  }

  .w100-xs {
    width: 100%;
  }

  .w100vw-xs {
    width: 100vw;
  }

  .h10rem-xs {
    height: 10rem;
  }

  .h100px-xs {
    height: 100px;
  }

  .h1000px-xs {
    height: 1000px;
  }

  .h100-xs {
    height: 100%;
  }

  .h100vh-xs {
    height: 100vh;
  }

  .miw10rem-xs {
    min-width: 10rem;
  }

  .miw1000px-xs {
    min-width: 1000px;
  }

  .miw100-xs {
    min-width: 100%;
  }

  .miw100vw-xs {
    min-width: 100vw;
  }

  .mih10rem-xs {
    min-height: 10rem;
  }

  .mih1000px-xs {
    min-height: 1000px;
  }

  .mih100-xs {
    min-height: 100%;
  }

  .mih100vh-xs {
    min-height: 100vh;
  }

  .maw10rem-xs {
    max-width: 10rem;
  }

  .maw1000px-xs {
    max-width: 1000px;
  }

  .maw100-xs {
    max-width: 100%;
  }

  .maw100vw-xs {
    max-width: 100vw;
  }

  .mah10rem-xs {
    max-height: 10rem;
  }

  .mah1000px-xs {
    max-height: 1000px;
  }

  .mah100-xs {
    max-height: 100%;
  }

  .mah100vh-xs {
    max-height: 100vh;
  }

  .or0-xs {
    order: 0;
  }

  .br0px-xs {
    border-radius: 0;
  }

  .btlr0px-xs {
    border-top-left-radius: 0;
  }

  .btrr0px-xs {
    border-top-right-radius: 0;
  }

  .bblr0px-xs {
    border-bottom-left-radius: 0;
  }

  .bbrr0px-xs {
    border-bottom-right-radius: 0;
  }

  .or1-xs {
    order: 1;
  }

  .br5px-xs {
    border-radius: 6px;
  }

  .br8px-xs {
    border-radius: 8px;
  }

  .br19px-xs {
    border-radius: 19px;
  }

  .btlr5px-xs {
    border-top-left-radius: 5px;
  }

  .btrr5px-xs {
    border-top-right-radius: 5px;
  }

  .bblr5px-xs {
    border-bottom-left-radius: 5px;
  }

  .bbrr5px-xs {
    border-bottom-right-radius: 5px;
  }

  .or2-xs {
    order: 2;
  }

  .br10px-xs {
    border-radius: 12px;
  }

  .br16px-xs {
    border-radius: 16px;
  }

  .br38px-xs {
    border-radius: 38px;
  }

  .btlr10px-xs {
    border-top-left-radius: 10px;
  }

  .btrr10px-xs {
    border-top-right-radius: 10px;
  }

  .bblr10px-xs {
    border-bottom-left-radius: 10px;
  }

  .bbrr10px-xs {
    border-bottom-right-radius: 10px;
  }

  .or3-xs {
    order: 3;
  }

  .br15px-xs {
    border-radius: 18px;
  }

  .br24px-xs {
    border-radius: 24px;
  }

  .br57px-xs {
    border-radius: 57px;
  }

  .btlr15px-xs {
    border-top-left-radius: 15px;
  }

  .btrr15px-xs {
    border-top-right-radius: 15px;
  }

  .bblr15px-xs {
    border-bottom-left-radius: 15px;
  }

  .bbrr15px-xs {
    border-bottom-right-radius: 15px;
  }

  .or4-xs {
    order: 4;
  }

  .br20px-xs {
    border-radius: 24px;
  }

  .br32px-xs {
    border-radius: 32px;
  }

  .br76px-xs {
    border-radius: 76px;
  }

  .btlr20px-xs {
    border-top-left-radius: 20px;
  }

  .btrr20px-xs {
    border-top-right-radius: 20px;
  }

  .bblr20px-xs {
    border-bottom-left-radius: 20px;
  }

  .bbrr20px-xs {
    border-bottom-right-radius: 20px;
  }

  .or5-xs {
    order: 5;
  }

  .br25px-xs {
    border-radius: 30px;
  }

  .br95px-xs {
    border-radius: 95px;
  }

  .btlr25px-xs {
    border-top-left-radius: 25px;
  }

  .btrr25px-xs {
    border-top-right-radius: 25px;
  }

  .bblr25px-xs {
    border-bottom-left-radius: 25px;
  }

  .bbrr25px-xs {
    border-bottom-right-radius: 25px;
  }

  .or6-xs {
    order: 6;
  }

  .br30px-xs {
    border-radius: 36px;
  }

  .br48px-xs {
    border-radius: 48px;
  }

  .br114px-xs {
    border-radius: 114px;
  }

  .btlr30px-xs {
    border-top-left-radius: 30px;
  }

  .btrr30px-xs {
    border-top-right-radius: 30px;
  }

  .bblr30px-xs {
    border-bottom-left-radius: 30px;
  }

  .bbrr30px-xs {
    border-bottom-right-radius: 30px;
  }

  .or7-xs {
    order: 7;
  }

  .br35px-xs {
    border-radius: 42px;
  }

  .br56px-xs {
    border-radius: 56px;
  }

  .br133px-xs {
    border-radius: 133px;
  }

  .btlr35px-xs {
    border-top-left-radius: 35px;
  }

  .btrr35px-xs {
    border-top-right-radius: 35px;
  }

  .bblr35px-xs {
    border-bottom-left-radius: 35px;
  }

  .bbrr35px-xs {
    border-bottom-right-radius: 35px;
  }

  .or8-xs {
    order: 8;
  }

  .br40px-xs {
    border-radius: 48px;
  }

  .br64px-xs {
    border-radius: 64px;
  }

  .br152px-xs {
    border-radius: 152px;
  }

  .btlr40px-xs {
    border-top-left-radius: 40px;
  }

  .btrr40px-xs {
    border-top-right-radius: 40px;
  }

  .bblr40px-xs {
    border-bottom-left-radius: 40px;
  }

  .bbrr40px-xs {
    border-bottom-right-radius: 40px;
  }

  .or9-xs {
    order: 9;
  }

  .br45px-xs {
    border-radius: 54px;
  }

  .br72px-xs {
    border-radius: 72px;
  }

  .br171px-xs {
    border-radius: 171px;
  }

  .btlr45px-xs {
    border-top-left-radius: 45px;
  }

  .btrr45px-xs {
    border-top-right-radius: 45px;
  }

  .bblr45px-xs {
    border-bottom-left-radius: 45px;
  }

  .bbrr45px-xs {
    border-bottom-right-radius: 45px;
  }

  .or10-xs {
    order: 10;
  }

  .br50px-xs {
    border-radius: 60px;
  }

  .br80px-xs {
    border-radius: 80px;
  }

  .br190px-xs {
    border-radius: 190px;
  }

  .btlr50px-xs {
    border-top-left-radius: 50px;
  }

  .btrr50px-xs {
    border-top-right-radius: 50px;
  }

  .bblr50px-xs {
    border-bottom-left-radius: 50px;
  }

  .bbrr50px-xs {
    border-bottom-right-radius: 50px;
  }

  .br100 {
    border-radius: 100%;
  }

  .btlr100 {
    border-top-left-radius: 100%;
  }

  .btrr100 {
    border-top-right-radius: 100%;
  }

  .bblr100 {
    border-bottom-left-radius: 100%;
  }

  .bbrr100 {
    border-bottom-right-radius: 100%;
  }
}

@media (width >= 36rem) {
  .c1-sm, .c1-hover-sm:hover {
    color: #fff;
  }

  .bg1-sm, .bg1-hover-sm:hover {
    background-color: #fff;
  }

  .bo1-sm, .bo1-hover-sm:hover {
    border-color: #fff;
  }

  .f1-sm, .f1-hover-sm:hover {
    fill: #fff;
  }

  .c2-sm, .c2-hover-sm:hover {
    color: #000;
  }

  .bg2-sm, .bg2-hover-sm:hover {
    background-color: #000;
  }

  .bo2-sm, .bo2-hover-sm:hover {
    border-color: #000;
  }

  .f2-sm, .f2-hover-sm:hover {
    fill: #000;
  }

  .c3-sm, .c3-hover-sm:hover {
    color: #4a67ff;
  }

  .bg3-sm, .bg3-hover-sm:hover {
    background-color: #4a67ff;
  }

  .bo3-sm, .bo3-hover-sm:hover {
    border-color: #4a67ff;
  }

  .f3-sm, .f3-hover-sm:hover {
    fill: #4a67ff;
  }

  .c4-sm, .c4-hover-sm:hover {
    color: #fc7785;
  }

  .bg4-sm, .bg4-hover-sm:hover {
    background-color: #fc7785;
  }

  .bo4-sm, .bo4-hover-sm:hover {
    border-color: #fc7785;
  }

  .f4-sm, .f4-hover-sm:hover {
    fill: #fc7785;
  }

  .c5-sm, .c5-hover-sm:hover {
    color: #f0f0f8;
  }

  .bg5-sm, .bg5-hover-sm:hover {
    background-color: #f0f0f8;
  }

  .bo5-sm, .bo5-hover-sm:hover {
    border-color: #f0f0f8;
  }

  .f5-sm, .f5-hover-sm:hover {
    fill: #f0f0f8;
  }

  .c6-sm, .c6-hover-sm:hover {
    color: #002d63;
  }

  .bg6-sm, .bg6-hover-sm:hover {
    background-color: #002d63;
  }

  .bo6-sm, .bo6-hover-sm:hover {
    border-color: #002d63;
  }

  .f6-sm, .f6-hover-sm:hover {
    fill: #002d63;
  }

  .c7-sm, .c7-hover-sm:hover {
    color: #40dda1;
  }

  .bg7-sm, .bg7-hover-sm:hover {
    background-color: #40dda1;
  }

  .bo7-sm, .bo7-hover-sm:hover {
    border-color: #40dda1;
  }

  .f7-sm, .f7-hover-sm:hover {
    fill: #40dda1;
  }

  .c8-sm, .c8-hover-sm:hover {
    color: #ddb640;
  }

  .bg8-sm, .bg8-hover-sm:hover {
    background-color: #ddb640;
  }

  .bo8-sm, .bo8-hover-sm:hover {
    border-color: #ddb640;
  }

  .f8-sm, .f8-hover-sm:hover {
    fill: #ddb640;
  }

  .c9-sm, .c9-hover-sm:hover {
    color: #dd4040;
  }

  .bg9-sm, .bg9-hover-sm:hover {
    background-color: #dd4040;
  }

  .bo9-sm, .bo9-hover-sm:hover {
    border-color: #dd4040;
  }

  .f9-sm, .f9-hover-sm:hover {
    fill: #dd4040;
  }

  .c10-sm, .c10-hover-sm:hover {
    color: #038589;
  }

  .bg10-sm, .bg10-hover-sm:hover {
    background-color: #038589;
  }

  .bo10-sm, .bo10-hover-sm:hover {
    border-color: #038589;
  }

  .f10-sm, .f10-hover-sm:hover {
    fill: #038589;
  }

  .c11-sm, .c11-hover-sm:hover {
    color: #007ee5;
  }

  .bg11-sm, .bg11-hover-sm:hover {
    background-color: #007ee5;
  }

  .bo11-sm, .bo11-hover-sm:hover {
    border-color: #007ee5;
  }

  .f11-sm, .f11-hover-sm:hover {
    fill: #007ee5;
  }

  .c12-sm, .c12-hover-sm:hover {
    color: #969696;
  }

  .bg12-sm, .bg12-hover-sm:hover {
    background-color: #969696;
  }

  .bo12-sm, .bo12-hover-sm:hover {
    border-color: #969696;
  }

  .f12-sm, .f12-hover-sm:hover {
    fill: #969696;
  }

  .c13-sm, .c13-hover-sm:hover {
    color: #fb7a27;
  }

  .bg13-sm, .bg13-hover-sm:hover {
    background-color: #fb7a27;
  }

  .bo13-sm, .bo13-hover-sm:hover {
    border-color: #fb7a27;
  }

  .f13-sm, .f13-hover-sm:hover {
    fill: #fb7a27;
  }

  .c14-sm, .c14-hover-sm:hover {
    color: #505050;
  }

  .bg14-sm, .bg14-hover-sm:hover {
    background-color: #505050;
  }

  .bo14-sm, .bo14-hover-sm:hover {
    border-color: #505050;
  }

  .f14-sm, .f14-hover-sm:hover {
    fill: #505050;
  }

  .c15-sm, .c15-hover-sm:hover {
    color: #151313;
  }

  .bg15-sm, .bg15-hover-sm:hover {
    background-color: #151313;
  }

  .bo15-sm, .bo15-hover-sm:hover {
    border-color: #151313;
  }

  .f15-sm, .f15-hover-sm:hover {
    fill: #151313;
  }

  .c16-sm, .c16-hover-sm:hover {
    color: #d9d9d9;
  }

  .bg16-sm, .bg16-hover-sm:hover {
    background-color: #d9d9d9;
  }

  .bo16-sm, .bo16-hover-sm:hover {
    border-color: #d9d9d9;
  }

  .f16-sm, .f16-hover-sm:hover {
    fill: #d9d9d9;
  }

  .c17-sm, .c17-hover-sm:hover {
    color: #d3d9fc;
  }

  .bg17-sm, .bg17-hover-sm:hover {
    background-color: #d3d9fc;
  }

  .bo17-sm, .bo17-hover-sm:hover {
    border-color: #d3d9fc;
  }

  .f17-sm, .f17-hover-sm:hover {
    fill: #d3d9fc;
  }

  .c18-sm, .c18-hover-sm:hover {
    color: #6775b9;
  }

  .bg18-sm, .bg18-hover-sm:hover {
    background-color: #6775b9;
  }

  .bo18-sm, .bo18-hover-sm:hover {
    border-color: #6775b9;
  }

  .f18-sm, .f18-hover-sm:hover {
    fill: #6775b9;
  }

  .c19-sm, .c19-hover-sm:hover {
    color: #f5f6f9;
  }

  .bg19-sm, .bg19-hover-sm:hover {
    background-color: #f5f6f9;
  }

  .bo19-sm, .bo19-hover-sm:hover {
    border-color: #f5f6f9;
  }

  .f19-sm, .f19-hover-sm:hover {
    fill: #f5f6f9;
  }

  .c20-sm, .c20-hover-sm:hover {
    color: #f0f2fd;
  }

  .bg20-sm, .bg20-hover-sm:hover {
    background-color: #f0f2fd;
  }

  .bo20-sm, .bo20-hover-sm:hover {
    border-color: #f0f2fd;
  }

  .f20-sm, .f20-hover-sm:hover {
    fill: #f0f2fd;
  }

  .c21-sm, .c21-hover-sm:hover {
    color: #f9f9f9;
  }

  .bg21-sm, .bg21-hover-sm:hover {
    background-color: #f9f9f9;
  }

  .bo21-sm, .bo21-hover-sm:hover {
    border-color: #f9f9f9;
  }

  .f21-sm, .f21-hover-sm:hover {
    fill: #f9f9f9;
  }

  .c22-sm, .c22-hover-sm:hover {
    color: #f6f6f6;
  }

  .bg22-sm, .bg22-hover-sm:hover {
    background-color: #f6f6f6;
  }

  .bo22-sm, .bo22-hover-sm:hover {
    border-color: #f6f6f6;
  }

  .f22-sm, .f22-hover-sm:hover {
    fill: #f6f6f6;
  }

  .c23-sm, .c23-hover-sm:hover {
    color: #fd6474;
  }

  .bg23-sm, .bg23-hover-sm:hover {
    background-color: #fd6474;
  }

  .bo23-sm, .bo23-hover-sm:hover {
    border-color: #fd6474;
  }

  .f23-sm, .f23-hover-sm:hover {
    fill: #fd6474;
  }

  .c24-sm, .c24-hover-sm:hover {
    color: #efefef;
  }

  .bg24-sm, .bg24-hover-sm:hover {
    background-color: #efefef;
  }

  .bo24-sm, .bo24-hover-sm:hover {
    border-color: #efefef;
  }

  .f24-sm, .f24-hover-sm:hover {
    fill: #efefef;
  }

  .c25-sm, .c25-hover-sm:hover {
    color: #f8f8f8;
  }

  .bg25-sm, .bg25-hover-sm:hover {
    background-color: #f8f8f8;
  }

  .bo25-sm, .bo25-hover-sm:hover {
    border-color: #f8f8f8;
  }

  .f25-sm, .f25-hover-sm:hover {
    fill: #f8f8f8;
  }

  .c26-sm, .c26-hover-sm:hover {
    color: #0126ed;
  }

  .bg26-sm, .bg26-hover-sm:hover {
    background-color: #0126ed;
  }

  .bo26-sm, .bo26-hover-sm:hover {
    border-color: #0126ed;
  }

  .f26-sm, .f26-hover-sm:hover {
    fill: #0126ed;
  }

  .c27-sm, .c27-hover-sm:hover {
    color: #5e5f66;
  }

  .bg27-sm, .bg27-hover-sm:hover {
    background-color: #5e5f66;
  }

  .bo27-sm, .bo27-hover-sm:hover {
    border-color: #5e5f66;
  }

  .f27-sm, .f27-hover-sm:hover {
    fill: #5e5f66;
  }

  .c28-sm, .c28-hover-sm:hover {
    color: #aaacb5;
  }

  .bg28-sm, .bg28-hover-sm:hover {
    background-color: #aaacb5;
  }

  .bo28-sm, .bo28-hover-sm:hover {
    border-color: #aaacb5;
  }

  .f28-sm, .f28-hover-sm:hover {
    fill: #aaacb5;
  }

  .c29-sm, .c29-hover-sm:hover {
    color: #dde2f2;
  }

  .bg29-sm, .bg29-hover-sm:hover {
    background-color: #dde2f2;
  }

  .bo29-sm, .bo29-hover-sm:hover {
    border-color: #dde2f2;
  }

  .f29-sm, .f29-hover-sm:hover {
    fill: #dde2f2;
  }

  .c30-sm, .c30-hover-sm:hover {
    color: #09b255;
  }

  .bg30-sm, .bg30-hover-sm:hover {
    background-color: #09b255;
  }

  .bo30-sm, .bo30-hover-sm:hover {
    border-color: #09b255;
  }

  .f30-sm, .f30-hover-sm:hover {
    fill: #09b255;
  }

  .c31-sm, .c31-hover-sm:hover {
    color: #dbdfee;
  }

  .bg31-sm, .bg31-hover-sm:hover {
    background-color: #dbdfee;
  }

  .bo31-sm, .bo31-hover-sm:hover {
    border-color: #dbdfee;
  }

  .f31-sm, .f31-hover-sm:hover {
    fill: #dbdfee;
  }

  .c32-sm, .c32-hover-sm:hover {
    color: #737171;
  }

  .bg32-sm, .bg32-hover-sm:hover {
    background-color: #737171;
  }

  .bo32-sm, .bo32-hover-sm:hover {
    border-color: #737171;
  }

  .f32-sm, .f32-hover-sm:hover {
    fill: #737171;
  }

  .c33-sm, .c33-hover-sm:hover {
    color: #767b90;
  }

  .bg33-sm, .bg33-hover-sm:hover {
    background-color: #767b90;
  }

  .bo33-sm, .bo33-hover-sm:hover {
    border-color: #767b90;
  }

  .f33-sm, .f33-hover-sm:hover {
    fill: #767b90;
  }

  .c34-sm, .c34-hover-sm:hover {
    color: #15d757;
  }

  .bg34-sm, .bg34-hover-sm:hover {
    background-color: #15d757;
  }

  .bo34-sm, .bo34-hover-sm:hover {
    border-color: #15d757;
  }

  .f34-sm, .f34-hover-sm:hover {
    fill: #15d757;
  }

  .c35-sm, .c35-hover-sm:hover {
    color: #e8ebf5;
  }

  .bg35-sm, .bg35-hover-sm:hover {
    background-color: #e8ebf5;
  }

  .bo35-sm, .bo35-hover-sm:hover {
    border-color: #e8ebf5;
  }

  .f35-sm, .f35-hover-sm:hover {
    fill: #e8ebf5;
  }

  .c36-sm, .c36-hover-sm:hover {
    color: #4c6b92;
  }

  .bg36-sm, .bg36-hover-sm:hover {
    background-color: #4c6b92;
  }

  .bo36-sm, .bo36-hover-sm:hover {
    border-color: #4c6b92;
  }

  .f36-sm, .f36-hover-sm:hover {
    fill: #4c6b92;
  }

  .c37-sm, .c37-hover-sm:hover {
    color: #818181;
  }

  .bg37-sm, .bg37-hover-sm:hover {
    background-color: #818181;
  }

  .bo37-sm, .bo37-hover-sm:hover {
    border-color: #818181;
  }

  .f37-sm, .f37-hover-sm:hover {
    fill: #818181;
  }

  .c38-sm, .c38-hover-sm:hover {
    color: #f0f2fd;
  }

  .bg38-sm, .bg38-hover-sm:hover {
    background-color: #f0f2fd;
  }

  .bo38-sm, .bo38-hover-sm:hover {
    border-color: #f0f2fd;
  }

  .f38-sm, .f38-hover-sm:hover {
    fill: #f0f2fd;
  }

  .c39-sm, .c39-hover-sm:hover {
    color: #f99229;
  }

  .bg39-sm, .bg39-hover-sm:hover {
    background-color: #f99229;
  }

  .bo39-sm, .bo39-hover-sm:hover {
    border-color: #f99229;
  }

  .f39-sm, .f39-hover-sm:hover {
    fill: #f99229;
  }

  .c40-sm, .c40-hover-sm:hover {
    color: #2947ef;
  }

  .bg40-sm, .bg40-hover-sm:hover {
    background-color: #2947ef;
  }

  .bo40-sm, .bo40-hover-sm:hover {
    border-color: #2947ef;
  }

  .f40-sm, .f40-hover-sm:hover {
    fill: #2947ef;
  }

  .c41-sm, .c41-hover-sm:hover {
    color: #f5f6ff;
  }

  .bg41-sm, .bg41-hover-sm:hover {
    background-color: #f5f6ff;
  }

  .bo41-sm, .bo41-hover-sm:hover {
    border-color: #f5f6ff;
  }

  .f41-sm, .f41-hover-sm:hover {
    fill: #f5f6ff;
  }

  .container {
    max-width: calc(100vw - 4rem);
  }

  .tal-sm {
    text-align: left;
  }

  .tac-sm {
    text-align: center;
  }

  .tar-sm {
    text-align: right;
  }

  .taj-sm {
    text-align: justify;
  }

  .tas-sm {
    text-align: start;
  }

  .tae-sm {
    text-align: end;
  }

  .fs0-sm {
    font-size: 0;
  }

  .fs0-25rem-sm {
    font-size: .25rem;
  }

  .fs0-125rem-sm {
    font-size: .125rem;
  }

  .fs0-5rem-sm {
    font-size: .5rem;
  }

  .fs0-75rem-sm {
    font-size: .75rem;
  }

  .fs0-875rem-sm {
    font-size: .875rem;
  }

  .fs1rem-sm {
    font-size: 1rem;
  }

  .fs1-25rem-sm {
    font-size: 1.25rem;
  }

  .fs1-125rem-sm {
    font-size: 1.125rem;
  }

  .fs1-5rem-sm {
    font-size: 1.5rem;
  }

  .fs1-75rem-sm {
    font-size: 1.75rem;
  }

  .fs1-875rem-sm {
    font-size: 1.875rem;
  }

  .fs2rem-sm {
    font-size: 2rem;
  }

  .fs2-25rem-sm {
    font-size: 2.25rem;
  }

  .fs2-125rem-sm {
    font-size: 2.125rem;
  }

  .fs2-5rem-sm {
    font-size: 2.5rem;
  }

  .fs2-75rem-sm {
    font-size: 2.75rem;
  }

  .fs2-875rem-sm {
    font-size: 2.875rem;
  }

  .fs3rem-sm {
    font-size: 3rem;
  }

  .fs3-25rem-sm {
    font-size: 3.25rem;
  }

  .fs3-125rem-sm {
    font-size: 3.125rem;
  }

  .fs3-5rem-sm {
    font-size: 3.5rem;
  }

  .fs3-75rem-sm {
    font-size: 3.75rem;
  }

  .fs3-875rem-sm {
    font-size: 3.875rem;
  }

  .fs4rem-sm {
    font-size: 4rem;
  }

  .fs4-25rem-sm {
    font-size: 4.25rem;
  }

  .fs4-125rem-sm {
    font-size: 4.125rem;
  }

  .fs4-5rem-sm {
    font-size: 4.5rem;
  }

  .fs4-75rem-sm {
    font-size: 4.75rem;
  }

  .fs4-875rem-sm {
    font-size: 4.875rem;
  }

  .fs5rem-sm {
    font-size: 5rem;
  }

  .fs5-25rem-sm {
    font-size: 5.25rem;
  }

  .fs5-125rem-sm {
    font-size: 5.125rem;
  }

  .fs5-5rem-sm {
    font-size: 5.5rem;
  }

  .fs5-75rem-sm {
    font-size: 5.75rem;
  }

  .fs5-875rem-sm {
    font-size: 5.875rem;
  }

  .fs6rem-sm {
    font-size: 6rem;
  }

  .fs6-25rem-sm {
    font-size: 6.25rem;
  }

  .fs6-125rem-sm {
    font-size: 6.125rem;
  }

  .fs6-5rem-sm {
    font-size: 6.5rem;
  }

  .fs6-75rem-sm {
    font-size: 6.75rem;
  }

  .fs6-875rem-sm {
    font-size: 6.875rem;
  }

  .fs7rem-sm {
    font-size: 7rem;
  }

  .db-sm {
    display: block;
  }

  .dib-sm {
    display: inline-block;
  }

  .di-sm {
    display: inline;
  }

  .df-sm {
    display: flex;
  }

  .fdc-sm {
    flex-direction: column;
  }

  .fdr-sm {
    flex-direction: row;
  }

  .fdcr-sm {
    flex-direction: column-reverse;
  }

  .fdrr-sm {
    flex-direction: row-reverse;
  }

  .fww-sm {
    flex-wrap: wrap;
  }

  .jcfs-sm {
    justify-content: flex-start;
  }

  .jcc-sm {
    justify-content: center;
  }

  .jcfe-sm {
    justify-content: flex-end;
  }

  .jcsb-sm {
    justify-content: space-between;
  }

  .jcsa-sm {
    justify-content: space-around;
  }

  .jcse-sm {
    justify-content: space-evenly;
  }

  .jce-sm {
    justify-content: end;
  }

  .aifs-sm {
    align-items: flex-start;
  }

  .aic-sm {
    align-items: center;
  }

  .aife-sm {
    align-items: flex-end;
  }

  .jss-sm {
    justify-self: start;
  }

  .jsfs-sm {
    justify-self: flex-start;
  }

  .jsc-sm {
    justify-self: center;
  }

  .jsfe-sm {
    justify-self: flex-end;
  }

  .jse-sm {
    justify-self: end;
  }

  .ass-sm {
    align-self: start;
  }

  .asfs-sm {
    align-self: flex-start;
  }

  .asc-sm {
    align-self: center;
  }

  .asfe-sm {
    align-self: flex-end;
  }

  .ase-sm {
    align-self: end;
  }

  .dif-sm {
    display: inline-flex;
  }

  .dg-sm {
    display: grid;
  }

  .brss-sm {
    border-right-style: solid;
  }

  .brw1px-sm {
    border-right-width: 1px;
  }

  .brw2px-sm {
    border-right-width: 2px;
  }

  .gtcr1-sm {
    grid-template-columns: repeat(1, 1fr);
  }

  .ftcr1-sm {
    grid-column: 1 / 1fr;
  }

  .gtrr1-sm {
    grid-template-rows: repeat(1, 1fr);
  }

  .gtcr1a-sm {
    grid-template-columns: repeat(1, auto);
  }

  .gtrr1a-sm {
    grid-template-rows: repeat(1, auto);
  }

  .gcs1-sm {
    grid-column-start: 1;
  }

  .gce2-sm {
    grid-column-end: 2;
  }

  .grs1-sm {
    grid-row-start: 1;
  }

  .gre2-sm {
    grid-row-end: 2;
  }

  .gtcr2-sm {
    grid-template-columns: repeat(2, 1fr);
  }

  .ftcr2-sm {
    grid-column: 2 / 1fr;
  }

  .gtrr2-sm {
    grid-template-rows: repeat(2, 1fr);
  }

  .gtcr2a-sm {
    grid-template-columns: repeat(2, auto);
  }

  .gtrr2a-sm {
    grid-template-rows: repeat(2, auto);
  }

  .gcs2-sm {
    grid-column-start: 2;
  }

  .gce3-sm {
    grid-column-end: 3;
  }

  .grs2-sm {
    grid-row-start: 2;
  }

  .gre3-sm {
    grid-row-end: 3;
  }

  .gtcr3-sm {
    grid-template-columns: repeat(3, 1fr);
  }

  .ftcr3-sm {
    grid-column: 3 / 1fr;
  }

  .gtrr3-sm {
    grid-template-rows: repeat(3, 1fr);
  }

  .gtcr3a-sm {
    grid-template-columns: repeat(3, auto);
  }

  .gtrr3a-sm {
    grid-template-rows: repeat(3, auto);
  }

  .gcs3-sm {
    grid-column-start: 3;
  }

  .gce4-sm {
    grid-column-end: 4;
  }

  .grs3-sm {
    grid-row-start: 3;
  }

  .gre4-sm {
    grid-row-end: 4;
  }

  .gtcr4-sm {
    grid-template-columns: repeat(4, 1fr);
  }

  .ftcr4-sm {
    grid-column: 4 / 1fr;
  }

  .gtrr4-sm {
    grid-template-rows: repeat(4, 1fr);
  }

  .gtcr4a-sm {
    grid-template-columns: repeat(4, auto);
  }

  .gtrr4a-sm {
    grid-template-rows: repeat(4, auto);
  }

  .gcs4-sm {
    grid-column-start: 4;
  }

  .gce5-sm {
    grid-column-end: 5;
  }

  .grs4-sm {
    grid-row-start: 4;
  }

  .gre5-sm {
    grid-row-end: 5;
  }

  .gtcr5-sm {
    grid-template-columns: repeat(5, 1fr);
  }

  .ftcr5-sm {
    grid-column: 5 / 1fr;
  }

  .gtrr5-sm {
    grid-template-rows: repeat(5, 1fr);
  }

  .gtcr5a-sm {
    grid-template-columns: repeat(5, auto);
  }

  .gtrr5a-sm {
    grid-template-rows: repeat(5, auto);
  }

  .gcs5-sm {
    grid-column-start: 5;
  }

  .gce6-sm {
    grid-column-end: 6;
  }

  .grs5-sm {
    grid-row-start: 5;
  }

  .gre6-sm {
    grid-row-end: 6;
  }

  .gtcr6-sm {
    grid-template-columns: repeat(6, 1fr);
  }

  .ftcr6-sm {
    grid-column: 6 / 1fr;
  }

  .gtrr6-sm {
    grid-template-rows: repeat(6, 1fr);
  }

  .gtcr6a-sm {
    grid-template-columns: repeat(6, auto);
  }

  .gtrr6a-sm {
    grid-template-rows: repeat(6, auto);
  }

  .gcs6-sm {
    grid-column-start: 6;
  }

  .gce7-sm {
    grid-column-end: 7;
  }

  .grs6-sm {
    grid-row-start: 6;
  }

  .gre7-sm {
    grid-row-end: 7;
  }

  .gtcr7-sm {
    grid-template-columns: repeat(7, 1fr);
  }

  .ftcr7-sm {
    grid-column: 7 / 1fr;
  }

  .gtrr7-sm {
    grid-template-rows: repeat(7, 1fr);
  }

  .gtcr7a-sm {
    grid-template-columns: repeat(7, auto);
  }

  .gtrr7a-sm {
    grid-template-rows: repeat(7, auto);
  }

  .gcs7-sm {
    grid-column-start: 7;
  }

  .gce8-sm {
    grid-column-end: 8;
  }

  .grs7-sm {
    grid-row-start: 7;
  }

  .gre8-sm {
    grid-row-end: 8;
  }

  .gtcr8-sm {
    grid-template-columns: repeat(8, 1fr);
  }

  .ftcr8-sm {
    grid-column: 8 / 1fr;
  }

  .gtrr8-sm {
    grid-template-rows: repeat(8, 1fr);
  }

  .gtcr8a-sm {
    grid-template-columns: repeat(8, auto);
  }

  .gtrr8a-sm {
    grid-template-rows: repeat(8, auto);
  }

  .gcs8-sm {
    grid-column-start: 8;
  }

  .gce9-sm {
    grid-column-end: 9;
  }

  .grs8-sm {
    grid-row-start: 8;
  }

  .gre9-sm {
    grid-row-end: 9;
  }

  .gtcr9-sm {
    grid-template-columns: repeat(9, 1fr);
  }

  .ftcr9-sm {
    grid-column: 9 / 1fr;
  }

  .gtrr9-sm {
    grid-template-rows: repeat(9, 1fr);
  }

  .gtcr9a-sm {
    grid-template-columns: repeat(9, auto);
  }

  .gtrr9a-sm {
    grid-template-rows: repeat(9, auto);
  }

  .gcs9-sm {
    grid-column-start: 9;
  }

  .gce10-sm {
    grid-column-end: 10;
  }

  .grs9-sm {
    grid-row-start: 9;
  }

  .gre10-sm {
    grid-row-end: 10;
  }

  .gtccr-sm {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }

  .gtcr10-sm {
    grid-template-columns: repeat(10, 1fr);
  }

  .ftcr10-sm {
    grid-column: 10 / 1fr;
  }

  .gtrr10-sm {
    grid-template-rows: repeat(10, 1fr);
  }

  .gtcr10a-sm {
    grid-template-columns: repeat(10, auto);
  }

  .gtrr10a-sm {
    grid-template-rows: repeat(10, auto);
  }

  .gcs10-sm {
    grid-column-start: 10;
  }

  .gce11-sm {
    grid-column-end: 11;
  }

  .grs10-sm {
    grid-row-start: 10;
  }

  .gre11-sm {
    grid-row-end: 11;
  }

  .gg0-5rem-sm {
    grid-gap: .5rem;
  }

  .gg1rem-sm {
    grid-gap: 1rem;
  }

  .rg0-5rem-sm {
    row-gap: .5rem;
  }

  .rg1rem-sm {
    row-gap: 1rem;
  }

  .cg0-5rem-sm {
    column-gap: .5rem;
  }

  .cg1rem-sm {
    column-gap: 1rem;
  }

  .gg1-5rem-sm {
    grid-gap: 1.5rem;
  }

  .gg2rem-sm {
    grid-gap: 2rem;
  }

  .rg1-5rem-sm {
    row-gap: 1.5rem;
  }

  .rg2rem-sm {
    row-gap: 2rem;
  }

  .cg1-5rem-sm {
    column-gap: 1.5rem;
  }

  .cg2rem-sm {
    column-gap: 2rem;
  }

  .gg2-5rem-sm {
    grid-gap: 2.5rem;
  }

  .gg3rem-sm {
    grid-gap: 3rem;
  }

  .rg2-5rem-sm {
    row-gap: 2.5rem;
  }

  .rg3rem-sm {
    row-gap: 3rem;
  }

  .cg2-5rem-sm {
    column-gap: 2.5rem;
  }

  .cg3rem-sm {
    column-gap: 3rem;
  }

  .gg3-5rem-sm {
    grid-gap: 3.5rem;
  }

  .gg4rem-sm {
    grid-gap: 4rem;
  }

  .rg3-5rem-sm {
    row-gap: 3.5rem;
  }

  .rg4rem-sm {
    row-gap: 4rem;
  }

  .cg3-5rem-sm {
    column-gap: 3.5rem;
  }

  .cg4rem-sm {
    column-gap: 4rem;
  }

  .gg4-5rem-sm {
    grid-gap: 4.5rem;
  }

  .gg5rem-sm {
    grid-gap: 5rem;
  }

  .rg4-5rem-sm {
    row-gap: 4.5rem;
  }

  .rg5rem-sm {
    row-gap: 5rem;
  }

  .cg4-5rem-sm {
    column-gap: 4.5rem;
  }

  .cg5rem-sm {
    column-gap: 5rem;
  }

  .dn-sm {
    display: none;
  }

  .pa-sm {
    position: absolute;
  }

  .pr-sm {
    position: relative;
  }

  .pf-sm {
    position: fixed;
  }

  .ps-sm {
    -webkit-position: sticky;
    position: sticky;
  }

  .pst-sm {
    position: static;
  }

  .t0-sm {
    top: 0;
  }

  .r0-sm {
    right: 0;
  }

  .b0-sm {
    bottom: 0;
  }

  .l0-sm {
    left: 0;
  }

  .t100-sm {
    top: 100%;
  }

  .r100-sm {
    right: 100%;
  }

  .b100-sm {
    bottom: 100%;
  }

  .l100-sm {
    left: 100%;
  }

  .fl-sm {
    float: left;
  }

  .fr-sm {
    float: right;
  }

  .fn-sm {
    float: none;
  }

  .ma-sm {
    margin: auto;
  }

  .mta-sm, .mya-sm {
    margin-top: auto;
  }

  .mra-sm, .mxa-sm {
    margin-right: auto;
  }

  .mba-sm, .mya-sm {
    margin-bottom: auto;
  }

  .mla-sm, .mxa-sm {
    margin-left: auto;
  }

  .m0-sm {
    margin: 0;
  }

  .mt0-sm, .my0-sm {
    margin-top: 0;
  }

  .mr0-sm, .mx0-sm {
    margin-right: 0;
  }

  .mb0-sm, .my0-sm {
    margin-bottom: 0;
  }

  .ml0-sm, .mx0-sm {
    margin-left: 0;
  }

  .p0-sm {
    padding: 0;
  }

  .pt0-sm, .py0-sm {
    padding-top: 0;
  }

  .pr0-sm, .px0-sm {
    padding-right: 0;
  }

  .pb0-sm, .py0-sm {
    padding-bottom: 0;
  }

  .pl0-sm, .px0-sm {
    padding-left: 0;
  }

  .m10-sm {
    margin: 10%;
  }

  .mt10-sm, .my10-sm {
    margin-top: 10%;
  }

  .mr10-sm, .mx10-sm {
    margin-right: 10%;
  }

  .mb10-sm, .my10-sm {
    margin-bottom: 10%;
  }

  .ml10-sm, .mx10-sm {
    margin-left: 10%;
  }

  .p10-sm {
    padding: 10%;
  }

  .pt10-sm, .py10-sm {
    padding-top: 10%;
  }

  .pr10-sm, .px10-sm {
    padding-right: 10%;
  }

  .pb10-sm, .py10-sm {
    padding-bottom: 10%;
  }

  .pl10-sm, .px10-sm {
    padding-left: 10%;
  }

  .m0-5rem-sm {
    margin: .5rem;
  }

  .m1rem-sm {
    margin: 1rem;
  }

  .mt0-5rem-sm, .my0-5rem-sm {
    margin-top: .5rem;
  }

  .mt1rem-sm, .my1rem-sm {
    margin-top: 1rem;
  }

  .mr0-5rem-sm, .mx0-5rem-sm {
    margin-right: .5rem;
  }

  .mr1rem-sm, .mx1rem-sm {
    margin-right: 1rem;
  }

  .mb0-5rem-sm, .my0-5rem-sm {
    margin-bottom: .5rem;
  }

  .mb1rem-sm, .my1rem-sm {
    margin-bottom: 1rem;
  }

  .ml0-5rem-sm, .mx0-5rem-sm {
    margin-left: .5rem;
  }

  .ml1rem-sm, .mx1rem-sm {
    margin-left: 1rem;
  }

  .p0-5rem-sm {
    padding: .5rem;
  }

  .p1rem-sm {
    padding: 1rem;
  }

  .pt0-5rem-sm, .py0-5rem-sm {
    padding-top: .5rem;
  }

  .pt1rem-sm, .py1rem-sm {
    padding-top: 1rem;
  }

  .pr0-5rem-sm, .px0-5rem-sm {
    padding-right: .5rem;
  }

  .pr1rem-sm, .px1rem-sm {
    padding-right: 1rem;
  }

  .pb0-5rem-sm, .py0-5rem-sm {
    padding-bottom: .5rem;
  }

  .pb1rem-sm, .py1rem-sm {
    padding-bottom: 1rem;
  }

  .pl0-5rem-sm, .px0-5rem-sm {
    padding-left: .5rem;
  }

  .pl1rem-sm, .px1rem-sm {
    padding-left: 1rem;
  }

  .m20-sm {
    margin: 20%;
  }

  .mt20-sm, .my20-sm {
    margin-top: 20%;
  }

  .mr20-sm, .mx20-sm {
    margin-right: 20%;
  }

  .mb20-sm, .my20-sm {
    margin-bottom: 20%;
  }

  .ml20-sm, .mx20-sm {
    margin-left: 20%;
  }

  .p20-sm {
    padding: 20%;
  }

  .pt20-sm, .py20-sm {
    padding-top: 20%;
  }

  .pr20-sm, .px20-sm {
    padding-right: 20%;
  }

  .pb20-sm, .py20-sm {
    padding-bottom: 20%;
  }

  .pl20-sm, .px20-sm {
    padding-left: 20%;
  }

  .m1-5rem-sm {
    margin: 1.5rem;
  }

  .m2rem-sm {
    margin: 2rem;
  }

  .mt1-5rem-sm, .my1-5rem-sm {
    margin-top: 1.5rem;
  }

  .mt2rem-sm, .my2rem-sm {
    margin-top: 2rem;
  }

  .mr1-5rem-sm, .mx1-5rem-sm {
    margin-right: 1.5rem;
  }

  .mr2rem-sm, .mx2rem-sm {
    margin-right: 2rem;
  }

  .mb1-5rem-sm, .my1-5rem-sm {
    margin-bottom: 1.5rem;
  }

  .mb2rem-sm, .my2rem-sm {
    margin-bottom: 2rem;
  }

  .ml1-5rem-sm, .mx1-5rem-sm {
    margin-left: 1.5rem;
  }

  .ml2rem-sm, .mx2rem-sm {
    margin-left: 2rem;
  }

  .p1-5rem-sm {
    padding: 1.5rem;
  }

  .p2rem-sm {
    padding: 2rem;
  }

  .pt1-5rem-sm, .py1-5rem-sm {
    padding-top: 1.5rem;
  }

  .pt2rem-sm, .py2rem-sm {
    padding-top: 2rem;
  }

  .pr1-5rem-sm, .px1-5rem-sm {
    padding-right: 1.5rem;
  }

  .pr2rem-sm, .px2rem-sm {
    padding-right: 2rem;
  }

  .pb1-5rem-sm, .py1-5rem-sm {
    padding-bottom: 1.5rem;
  }

  .pb2rem-sm, .py2rem-sm {
    padding-bottom: 2rem;
  }

  .pl1-5rem-sm, .px1-5rem-sm {
    padding-left: 1.5rem;
  }

  .pl2rem-sm, .px2rem-sm {
    padding-left: 2rem;
  }

  .m30-sm {
    margin: 30%;
  }

  .mt30-sm, .my30-sm {
    margin-top: 30%;
  }

  .mr30-sm, .mx30-sm {
    margin-right: 30%;
  }

  .mb30-sm, .my30-sm {
    margin-bottom: 30%;
  }

  .ml30-sm, .mx30-sm {
    margin-left: 30%;
  }

  .p30-sm {
    padding: 30%;
  }

  .pt30-sm, .py30-sm {
    padding-top: 30%;
  }

  .pr30-sm, .px30-sm {
    padding-right: 30%;
  }

  .pb30-sm, .py30-sm {
    padding-bottom: 30%;
  }

  .pl30-sm, .px30-sm {
    padding-left: 30%;
  }

  .m2-5rem-sm {
    margin: 2.5rem;
  }

  .m3rem-sm {
    margin: 3rem;
  }

  .mt2-5rem-sm, .my2-5rem-sm {
    margin-top: 2.5rem;
  }

  .mt3rem-sm, .my3rem-sm {
    margin-top: 3rem;
  }

  .mr2-5rem-sm, .mx2-5rem-sm {
    margin-right: 2.5rem;
  }

  .mr3rem-sm, .mx3rem-sm {
    margin-right: 3rem;
  }

  .mb2-5rem-sm, .my2-5rem-sm {
    margin-bottom: 2.5rem;
  }

  .mb3rem-sm, .my3rem-sm {
    margin-bottom: 3rem;
  }

  .ml2-5rem-sm, .mx2-5rem-sm {
    margin-left: 2.5rem;
  }

  .ml3rem-sm, .mx3rem-sm {
    margin-left: 3rem;
  }

  .p2-5rem-sm {
    padding: 2.5rem;
  }

  .p3rem-sm {
    padding: 3rem;
  }

  .pt2-5rem-sm, .py2-5rem-sm {
    padding-top: 2.5rem;
  }

  .pt3rem-sm, .py3rem-sm {
    padding-top: 3rem;
  }

  .pr2-5rem-sm, .px2-5rem-sm {
    padding-right: 2.5rem;
  }

  .pr3rem-sm, .px3rem-sm {
    padding-right: 3rem;
  }

  .pb2-5rem-sm, .py2-5rem-sm {
    padding-bottom: 2.5rem;
  }

  .pb3rem-sm, .py3rem-sm {
    padding-bottom: 3rem;
  }

  .pl2-5rem-sm, .px2-5rem-sm {
    padding-left: 2.5rem;
  }

  .pl3rem-sm, .px3rem-sm {
    padding-left: 3rem;
  }

  .m40-sm {
    margin: 40%;
  }

  .mt40-sm, .my40-sm {
    margin-top: 40%;
  }

  .mr40-sm, .mx40-sm {
    margin-right: 40%;
  }

  .mb40-sm, .my40-sm {
    margin-bottom: 40%;
  }

  .ml40-sm, .mx40-sm {
    margin-left: 40%;
  }

  .p40-sm {
    padding: 40%;
  }

  .pt40-sm, .py40-sm {
    padding-top: 40%;
  }

  .pr40-sm, .px40-sm {
    padding-right: 40%;
  }

  .pb40-sm, .py40-sm {
    padding-bottom: 40%;
  }

  .pl40-sm, .px40-sm {
    padding-left: 40%;
  }

  .m3-5rem-sm {
    margin: 3.5rem;
  }

  .m4rem-sm {
    margin: 4rem;
  }

  .mt3-5rem-sm, .my3-5rem-sm {
    margin-top: 3.5rem;
  }

  .mt4rem-sm, .my4rem-sm {
    margin-top: 4rem;
  }

  .mr3-5rem-sm, .mx3-5rem-sm {
    margin-right: 3.5rem;
  }

  .mr4rem-sm, .mx4rem-sm {
    margin-right: 4rem;
  }

  .mb3-5rem-sm, .my3-5rem-sm {
    margin-bottom: 3.5rem;
  }

  .mb4rem-sm, .my4rem-sm {
    margin-bottom: 4rem;
  }

  .ml3-5rem-sm, .mx3-5rem-sm {
    margin-left: 3.5rem;
  }

  .ml4rem-sm, .mx4rem-sm {
    margin-left: 4rem;
  }

  .p3-5rem-sm {
    padding: 3.5rem;
  }

  .p4rem-sm {
    padding: 4rem;
  }

  .pt3-5rem-sm, .py3-5rem-sm {
    padding-top: 3.5rem;
  }

  .pt4rem-sm, .py4rem-sm {
    padding-top: 4rem;
  }

  .pr3-5rem-sm, .px3-5rem-sm {
    padding-right: 3.5rem;
  }

  .pr4rem-sm, .px4rem-sm {
    padding-right: 4rem;
  }

  .pb3-5rem-sm, .py3-5rem-sm {
    padding-bottom: 3.5rem;
  }

  .pb4rem-sm, .py4rem-sm {
    padding-bottom: 4rem;
  }

  .pl3-5rem-sm, .px3-5rem-sm {
    padding-left: 3.5rem;
  }

  .pl4rem-sm, .px4rem-sm {
    padding-left: 4rem;
  }

  .m50-sm {
    margin: 50%;
  }

  .mt50-sm, .my50-sm {
    margin-top: 50%;
  }

  .mr50-sm, .mx50-sm {
    margin-right: 50%;
  }

  .mb50-sm, .my50-sm {
    margin-bottom: 50%;
  }

  .ml50-sm, .mx50-sm {
    margin-left: 50%;
  }

  .p50-sm {
    padding: 50%;
  }

  .pt50-sm, .py50-sm {
    padding-top: 50%;
  }

  .pr50-sm, .px50-sm {
    padding-right: 50%;
  }

  .pb50-sm, .py50-sm {
    padding-bottom: 50%;
  }

  .pl50-sm, .px50-sm {
    padding-left: 50%;
  }

  .m4-5rem-sm {
    margin: 4.5rem;
  }

  .m5rem-sm {
    margin: 5rem;
  }

  .mt4-5rem-sm, .my4-5rem-sm {
    margin-top: 4.5rem;
  }

  .mt5rem-sm, .my5rem-sm {
    margin-top: 5rem;
  }

  .mr4-5rem-sm, .mx4-5rem-sm {
    margin-right: 4.5rem;
  }

  .mr5rem-sm, .mx5rem-sm {
    margin-right: 5rem;
  }

  .mb4-5rem-sm, .my4-5rem-sm {
    margin-bottom: 4.5rem;
  }

  .mb5rem-sm, .my5rem-sm {
    margin-bottom: 5rem;
  }

  .ml4-5rem-sm, .mx4-5rem-sm {
    margin-left: 4.5rem;
  }

  .ml5rem-sm, .mx5rem-sm {
    margin-left: 5rem;
  }

  .p4-5rem-sm {
    padding: 4.5rem;
  }

  .p5rem-sm {
    padding: 5rem;
  }

  .pt4-5rem-sm, .py4-5rem-sm {
    padding-top: 4.5rem;
  }

  .pt5rem-sm, .py5rem-sm {
    padding-top: 5rem;
  }

  .pr4-5rem-sm, .px4-5rem-sm {
    padding-right: 4.5rem;
  }

  .pr5rem-sm, .px5rem-sm {
    padding-right: 5rem;
  }

  .pb4-5rem-sm, .py4-5rem-sm {
    padding-bottom: 4.5rem;
  }

  .pb5rem-sm, .py5rem-sm {
    padding-bottom: 5rem;
  }

  .pl4-5rem-sm, .px4-5rem-sm {
    padding-left: 4.5rem;
  }

  .pl5rem-sm, .px5rem-sm {
    padding-left: 5rem;
  }

  .m60-sm {
    margin: 60%;
  }

  .mt60-sm, .my60-sm {
    margin-top: 60%;
  }

  .mr60-sm, .mx60-sm {
    margin-right: 60%;
  }

  .mb60-sm, .my60-sm {
    margin-bottom: 60%;
  }

  .ml60-sm, .mx60-sm {
    margin-left: 60%;
  }

  .p60-sm {
    padding: 60%;
  }

  .pt60-sm, .py60-sm {
    padding-top: 60%;
  }

  .pr60-sm, .px60-sm {
    padding-right: 60%;
  }

  .pb60-sm, .py60-sm {
    padding-bottom: 60%;
  }

  .pl60-sm, .px60-sm {
    padding-left: 60%;
  }

  .m5-5rem-sm {
    margin: 5.5rem;
  }

  .m6rem-sm {
    margin: 6rem;
  }

  .mt5-5rem-sm, .my5-5rem-sm {
    margin-top: 5.5rem;
  }

  .mt6rem-sm, .my6rem-sm {
    margin-top: 6rem;
  }

  .mr5-5rem-sm, .mx5-5rem-sm {
    margin-right: 5.5rem;
  }

  .mr6rem-sm, .mx6rem-sm {
    margin-right: 6rem;
  }

  .mb5-5rem-sm, .my5-5rem-sm {
    margin-bottom: 5.5rem;
  }

  .mb6rem-sm, .my6rem-sm {
    margin-bottom: 6rem;
  }

  .ml5-5rem-sm, .mx5-5rem-sm {
    margin-left: 5.5rem;
  }

  .ml6rem-sm, .mx6rem-sm {
    margin-left: 6rem;
  }

  .p5-5rem-sm {
    padding: 5.5rem;
  }

  .p6rem-sm {
    padding: 6rem;
  }

  .pt5-5rem-sm, .py5-5rem-sm {
    padding-top: 5.5rem;
  }

  .pt6rem-sm, .py6rem-sm {
    padding-top: 6rem;
  }

  .pr5-5rem-sm, .px5-5rem-sm {
    padding-right: 5.5rem;
  }

  .pr6rem-sm, .px6rem-sm {
    padding-right: 6rem;
  }

  .pb5-5rem-sm, .py5-5rem-sm {
    padding-bottom: 5.5rem;
  }

  .pb6rem-sm, .py6rem-sm {
    padding-bottom: 6rem;
  }

  .pl5-5rem-sm, .px5-5rem-sm {
    padding-left: 5.5rem;
  }

  .pl6rem-sm, .px6rem-sm {
    padding-left: 6rem;
  }

  .m70-sm {
    margin: 70%;
  }

  .mt70-sm, .my70-sm {
    margin-top: 70%;
  }

  .mr70-sm, .mx70-sm {
    margin-right: 70%;
  }

  .mb70-sm, .my70-sm {
    margin-bottom: 70%;
  }

  .ml70-sm, .mx70-sm {
    margin-left: 70%;
  }

  .p70-sm {
    padding: 70%;
  }

  .pt70-sm, .py70-sm {
    padding-top: 70%;
  }

  .pr70-sm, .px70-sm {
    padding-right: 70%;
  }

  .pb70-sm, .py70-sm {
    padding-bottom: 70%;
  }

  .pl70-sm, .px70-sm {
    padding-left: 70%;
  }

  .m6-5rem-sm {
    margin: 6.5rem;
  }

  .m7rem-sm {
    margin: 7rem;
  }

  .mt6-5rem-sm, .my6-5rem-sm {
    margin-top: 6.5rem;
  }

  .mt7rem-sm, .my7rem-sm {
    margin-top: 7rem;
  }

  .mr6-5rem-sm, .mx6-5rem-sm {
    margin-right: 6.5rem;
  }

  .mr7rem-sm, .mx7rem-sm {
    margin-right: 7rem;
  }

  .mb6-5rem-sm, .my6-5rem-sm {
    margin-bottom: 6.5rem;
  }

  .mb7rem-sm, .my7rem-sm {
    margin-bottom: 7rem;
  }

  .ml6-5rem-sm, .mx6-5rem-sm {
    margin-left: 6.5rem;
  }

  .ml7rem-sm, .mx7rem-sm {
    margin-left: 7rem;
  }

  .p6-5rem-sm {
    padding: 6.5rem;
  }

  .p7rem-sm {
    padding: 7rem;
  }

  .pt6-5rem-sm, .py6-5rem-sm {
    padding-top: 6.5rem;
  }

  .pt7rem-sm, .py7rem-sm {
    padding-top: 7rem;
  }

  .pr6-5rem-sm, .px6-5rem-sm {
    padding-right: 6.5rem;
  }

  .pr7rem-sm, .px7rem-sm {
    padding-right: 7rem;
  }

  .pb6-5rem-sm, .py6-5rem-sm {
    padding-bottom: 6.5rem;
  }

  .pb7rem-sm, .py7rem-sm {
    padding-bottom: 7rem;
  }

  .pl6-5rem-sm, .px6-5rem-sm {
    padding-left: 6.5rem;
  }

  .pl7rem-sm, .px7rem-sm {
    padding-left: 7rem;
  }

  .m80-sm {
    margin: 80%;
  }

  .mt80-sm, .my80-sm {
    margin-top: 80%;
  }

  .mr80-sm, .mx80-sm {
    margin-right: 80%;
  }

  .mb80-sm, .my80-sm {
    margin-bottom: 80%;
  }

  .ml80-sm, .mx80-sm {
    margin-left: 80%;
  }

  .p80-sm {
    padding: 80%;
  }

  .pt80-sm, .py80-sm {
    padding-top: 80%;
  }

  .pr80-sm, .px80-sm {
    padding-right: 80%;
  }

  .pb80-sm, .py80-sm {
    padding-bottom: 80%;
  }

  .pl80-sm, .px80-sm {
    padding-left: 80%;
  }

  .m7-5rem-sm {
    margin: 7.5rem;
  }

  .m8rem-sm {
    margin: 8rem;
  }

  .mt7-5rem-sm, .my7-5rem-sm {
    margin-top: 7.5rem;
  }

  .mt8rem-sm, .my8rem-sm {
    margin-top: 8rem;
  }

  .mr7-5rem-sm, .mx7-5rem-sm {
    margin-right: 7.5rem;
  }

  .mr8rem-sm, .mx8rem-sm {
    margin-right: 8rem;
  }

  .mb7-5rem-sm, .my7-5rem-sm {
    margin-bottom: 7.5rem;
  }

  .mb8rem-sm, .my8rem-sm {
    margin-bottom: 8rem;
  }

  .ml7-5rem-sm, .mx7-5rem-sm {
    margin-left: 7.5rem;
  }

  .ml8rem-sm, .mx8rem-sm {
    margin-left: 8rem;
  }

  .p7-5rem-sm {
    padding: 7.5rem;
  }

  .p8rem-sm {
    padding: 8rem;
  }

  .pt7-5rem-sm, .py7-5rem-sm {
    padding-top: 7.5rem;
  }

  .pt8rem-sm, .py8rem-sm {
    padding-top: 8rem;
  }

  .pr7-5rem-sm, .px7-5rem-sm {
    padding-right: 7.5rem;
  }

  .pr8rem-sm, .px8rem-sm {
    padding-right: 8rem;
  }

  .pb7-5rem-sm, .py7-5rem-sm {
    padding-bottom: 7.5rem;
  }

  .pb8rem-sm, .py8rem-sm {
    padding-bottom: 8rem;
  }

  .pl7-5rem-sm, .px7-5rem-sm {
    padding-left: 7.5rem;
  }

  .pl8rem-sm, .px8rem-sm {
    padding-left: 8rem;
  }

  .m90-sm {
    margin: 90%;
  }

  .mt90-sm, .my90-sm {
    margin-top: 90%;
  }

  .mr90-sm, .mx90-sm {
    margin-right: 90%;
  }

  .mb90-sm, .my90-sm {
    margin-bottom: 90%;
  }

  .ml90-sm, .mx90-sm {
    margin-left: 90%;
  }

  .p90-sm {
    padding: 90%;
  }

  .pt90-sm, .py90-sm {
    padding-top: 90%;
  }

  .pr90-sm, .px90-sm {
    padding-right: 90%;
  }

  .pb90-sm, .py90-sm {
    padding-bottom: 90%;
  }

  .pl90-sm, .px90-sm {
    padding-left: 90%;
  }

  .m8-5rem-sm {
    margin: 8.5rem;
  }

  .m9rem-sm {
    margin: 9rem;
  }

  .mt8-5rem-sm, .my8-5rem-sm {
    margin-top: 8.5rem;
  }

  .mt9rem-sm, .my9rem-sm {
    margin-top: 9rem;
  }

  .mr8-5rem-sm, .mx8-5rem-sm {
    margin-right: 8.5rem;
  }

  .mr9rem-sm, .mx9rem-sm {
    margin-right: 9rem;
  }

  .mb8-5rem-sm, .my8-5rem-sm {
    margin-bottom: 8.5rem;
  }

  .mb9rem-sm, .my9rem-sm {
    margin-bottom: 9rem;
  }

  .ml8-5rem-sm, .mx8-5rem-sm {
    margin-left: 8.5rem;
  }

  .ml9rem-sm, .mx9rem-sm {
    margin-left: 9rem;
  }

  .p8-5rem-sm {
    padding: 8.5rem;
  }

  .p9rem-sm {
    padding: 9rem;
  }

  .pt8-5rem-sm, .py8-5rem-sm {
    padding-top: 8.5rem;
  }

  .pt9rem-sm, .py9rem-sm {
    padding-top: 9rem;
  }

  .pr8-5rem-sm, .px8-5rem-sm {
    padding-right: 8.5rem;
  }

  .pr9rem-sm, .px9rem-sm {
    padding-right: 9rem;
  }

  .pb8-5rem-sm, .py8-5rem-sm {
    padding-bottom: 8.5rem;
  }

  .pb9rem-sm, .py9rem-sm {
    padding-bottom: 9rem;
  }

  .pl8-5rem-sm, .px8-5rem-sm {
    padding-left: 8.5rem;
  }

  .pl9rem-sm, .px9rem-sm {
    padding-left: 9rem;
  }

  .m100-sm {
    margin: 100%;
  }

  .mt100-sm, .my100-sm {
    margin-top: 100%;
  }

  .mr100-sm, .mx100-sm {
    margin-right: 100%;
  }

  .mb100-sm, .my100-sm {
    margin-bottom: 100%;
  }

  .ml100-sm, .mx100-sm {
    margin-left: 100%;
  }

  .p100-sm {
    padding: 100%;
  }

  .pt100-sm, .py100-sm {
    padding-top: 100%;
  }

  .pr100-sm, .px100-sm {
    padding-right: 100%;
  }

  .pb100-sm, .py100-sm {
    padding-bottom: 100%;
  }

  .pl100-sm, .px100-sm {
    padding-left: 100%;
  }

  .m9-5rem-sm {
    margin: 9.5rem;
  }

  .m10rem-sm {
    margin: 10rem;
  }

  .mt9-5rem-sm, .my9-5rem-sm {
    margin-top: 9.5rem;
  }

  .mt10rem-sm, .my10rem-sm {
    margin-top: 10rem;
  }

  .mr9-5rem-sm, .mx9-5rem-sm {
    margin-right: 9.5rem;
  }

  .mr10rem-sm, .mx10rem-sm {
    margin-right: 10rem;
  }

  .mb9-5rem-sm, .my9-5rem-sm {
    margin-bottom: 9.5rem;
  }

  .mb10rem-sm, .my10rem-sm {
    margin-bottom: 10rem;
  }

  .ml9-5rem-sm, .mx9-5rem-sm {
    margin-left: 9.5rem;
  }

  .ml10rem-sm, .mx10rem-sm {
    margin-left: 10rem;
  }

  .p9-5rem-sm {
    padding: 9.5rem;
  }

  .p10rem-sm {
    padding: 10rem;
  }

  .pt9-5rem-sm, .py9-5rem-sm {
    padding-top: 9.5rem;
  }

  .pt10rem-sm, .py10rem-sm {
    padding-top: 10rem;
  }

  .pr9-5rem-sm, .px9-5rem-sm {
    padding-right: 9.5rem;
  }

  .pr10rem-sm, .px10rem-sm {
    padding-right: 10rem;
  }

  .pb9-5rem-sm, .py9-5rem-sm {
    padding-bottom: 9.5rem;
  }

  .pb10rem-sm, .py10rem-sm {
    padding-bottom: 10rem;
  }

  .pl9-5rem-sm, .px9-5rem-sm {
    padding-left: 9.5rem;
  }

  .pl10rem-sm, .px10rem-sm {
    padding-left: 10rem;
  }

  .wa-sm {
    width: auto;
  }

  .ha-sm {
    height: auto;
  }

  .w0-sm {
    width: 0;
  }

  .h0-sm {
    height: 0;
  }

  .maw0-sm {
    max-width: 0;
  }

  .mah0-sm {
    max-height: 0;
  }

  .mih0-sm {
    min-height: 0;
  }

  .w1rem-sm {
    width: 1rem;
  }

  .w10px-sm {
    width: 10px;
  }

  .w10-sm {
    width: 10%;
  }

  .w10vw-sm {
    width: 10vw;
  }

  .h1rem-sm {
    height: 1rem;
  }

  .h10px-sm {
    height: 10px;
  }

  .h10-sm {
    height: 10%;
  }

  .h10vh-sm {
    height: 10vh;
  }

  .miw1rem-sm {
    min-width: 1rem;
  }

  .miw100px-sm {
    min-width: 100px;
  }

  .miw10-sm {
    min-width: 10%;
  }

  .miw10vw-sm {
    min-width: 10vw;
  }

  .mih1rem-sm {
    min-height: 1rem;
  }

  .mih100px-sm {
    min-height: 100px;
  }

  .mih10-sm {
    min-height: 10%;
  }

  .mih10vh-sm {
    min-height: 10vh;
  }

  .maw1rem-sm {
    max-width: 1rem;
  }

  .maw100px-sm {
    max-width: 100px;
  }

  .maw10-sm {
    max-width: 10%;
  }

  .maw10vw-sm {
    max-width: 10vw;
  }

  .mah1rem-sm {
    max-height: 1rem;
  }

  .mah100px-sm {
    max-height: 100px;
  }

  .mah10-sm {
    max-height: 10%;
  }

  .mah10vh-sm {
    max-height: 10vh;
  }

  .w2rem-sm {
    width: 2rem;
  }

  .w20px-sm {
    width: 20px;
  }

  .w200px-sm {
    width: 200px;
  }

  .w20-sm {
    width: 20%;
  }

  .w20vw-sm {
    width: 20vw;
  }

  .h2rem-sm {
    height: 2rem;
  }

  .h20px-sm {
    height: 20px;
  }

  .h200px-sm {
    height: 200px;
  }

  .h20-sm {
    height: 20%;
  }

  .h20vh-sm {
    height: 20vh;
  }

  .miw2rem-sm {
    min-width: 2rem;
  }

  .miw200px-sm {
    min-width: 200px;
  }

  .miw20-sm {
    min-width: 20%;
  }

  .miw20vw-sm {
    min-width: 20vw;
  }

  .mih2rem-sm {
    min-height: 2rem;
  }

  .mih200px-sm {
    min-height: 200px;
  }

  .mih20-sm {
    min-height: 20%;
  }

  .mih20vh-sm {
    min-height: 20vh;
  }

  .maw2rem-sm {
    max-width: 2rem;
  }

  .maw200px-sm {
    max-width: 200px;
  }

  .maw20-sm {
    max-width: 20%;
  }

  .maw20vw-sm {
    max-width: 20vw;
  }

  .mah2rem-sm {
    max-height: 2rem;
  }

  .mah200px-sm {
    max-height: 200px;
  }

  .mah20-sm {
    max-height: 20%;
  }

  .mah20vh-sm {
    max-height: 20vh;
  }

  .w3rem-sm {
    width: 3rem;
  }

  .w30px-sm {
    width: 30px;
  }

  .w300px-sm {
    width: 300px;
  }

  .w30-sm {
    width: 30%;
  }

  .w30vw-sm {
    width: 30vw;
  }

  .h3rem-sm {
    height: 3rem;
  }

  .h30px-sm {
    height: 30px;
  }

  .h300px-sm {
    height: 300px;
  }

  .h30-sm {
    height: 30%;
  }

  .h30vh-sm {
    height: 30vh;
  }

  .miw3rem-sm {
    min-width: 3rem;
  }

  .miw300px-sm {
    min-width: 300px;
  }

  .miw30-sm {
    min-width: 30%;
  }

  .miw30vw-sm {
    min-width: 30vw;
  }

  .mih3rem-sm {
    min-height: 3rem;
  }

  .mih300px-sm {
    min-height: 300px;
  }

  .mih30-sm {
    min-height: 30%;
  }

  .mih30vh-sm {
    min-height: 30vh;
  }

  .maw3rem-sm {
    max-width: 3rem;
  }

  .maw300px-sm {
    max-width: 300px;
  }

  .maw30-sm {
    max-width: 30%;
  }

  .maw30vw-sm {
    max-width: 30vw;
  }

  .mah3rem-sm {
    max-height: 3rem;
  }

  .mah300px-sm {
    max-height: 300px;
  }

  .mah30-sm {
    max-height: 30%;
  }

  .mah30vh-sm {
    max-height: 30vh;
  }

  .w4rem-sm {
    width: 4rem;
  }

  .w40px-sm {
    width: 40px;
  }

  .w400px-sm {
    width: 400px;
  }

  .w40-sm {
    width: 40%;
  }

  .w40vw-sm {
    width: 40vw;
  }

  .h4rem-sm {
    height: 4rem;
  }

  .h40px-sm {
    height: 40px;
  }

  .h400px-sm {
    height: 400px;
  }

  .h40-sm {
    height: 40%;
  }

  .h40vh-sm {
    height: 40vh;
  }

  .miw4rem-sm {
    min-width: 4rem;
  }

  .miw400px-sm {
    min-width: 400px;
  }

  .miw40-sm {
    min-width: 40%;
  }

  .miw40vw-sm {
    min-width: 40vw;
  }

  .mih4rem-sm {
    min-height: 4rem;
  }

  .mih400px-sm {
    min-height: 400px;
  }

  .mih40-sm {
    min-height: 40%;
  }

  .mih40vh-sm {
    min-height: 40vh;
  }

  .maw4rem-sm {
    max-width: 4rem;
  }

  .maw400px-sm {
    max-width: 400px;
  }

  .maw40-sm {
    max-width: 40%;
  }

  .maw40vw-sm {
    max-width: 40vw;
  }

  .mah4rem-sm {
    max-height: 4rem;
  }

  .mah400px-sm {
    max-height: 400px;
  }

  .mah40-sm {
    max-height: 40%;
  }

  .mah40vh-sm {
    max-height: 40vh;
  }

  .w5rem-sm {
    width: 5rem;
  }

  .w50px-sm {
    width: 50px;
  }

  .w500px-sm {
    width: 500px;
  }

  .w50-sm {
    width: 50%;
  }

  .w50vw-sm {
    width: 50vw;
  }

  .h5rem-sm {
    height: 5rem;
  }

  .h50px-sm {
    height: 50px;
  }

  .h500px-sm {
    height: 500px;
  }

  .h50-sm {
    height: 50%;
  }

  .h50vh-sm {
    height: 50vh;
  }

  .miw5rem-sm {
    min-width: 5rem;
  }

  .miw500px-sm {
    min-width: 500px;
  }

  .miw50-sm {
    min-width: 50%;
  }

  .miw50vw-sm {
    min-width: 50vw;
  }

  .mih5rem-sm {
    min-height: 5rem;
  }

  .mih500px-sm {
    min-height: 500px;
  }

  .mih50-sm {
    min-height: 50%;
  }

  .mih50vh-sm {
    min-height: 50vh;
  }

  .maw5rem-sm {
    max-width: 5rem;
  }

  .maw500px-sm {
    max-width: 500px;
  }

  .maw50-sm {
    max-width: 50%;
  }

  .maw50vw-sm {
    max-width: 50vw;
  }

  .mah5rem-sm {
    max-height: 5rem;
  }

  .mah500px-sm {
    max-height: 500px;
  }

  .mah50-sm {
    max-height: 50%;
  }

  .mah50vh-sm {
    max-height: 50vh;
  }

  .w6rem-sm {
    width: 6rem;
  }

  .w60px-sm {
    width: 60px;
  }

  .w600px-sm {
    width: 600px;
  }

  .w60-sm {
    width: 60%;
  }

  .w60vw-sm {
    width: 60vw;
  }

  .h6rem-sm {
    height: 6rem;
  }

  .h60px-sm {
    height: 60px;
  }

  .h600px-sm {
    height: 600px;
  }

  .h60-sm {
    height: 60%;
  }

  .h60vh-sm {
    height: 60vh;
  }

  .miw6rem-sm {
    min-width: 6rem;
  }

  .miw600px-sm {
    min-width: 600px;
  }

  .miw60-sm {
    min-width: 60%;
  }

  .miw60vw-sm {
    min-width: 60vw;
  }

  .mih6rem-sm {
    min-height: 6rem;
  }

  .mih600px-sm {
    min-height: 600px;
  }

  .mih60-sm {
    min-height: 60%;
  }

  .mih60vh-sm {
    min-height: 60vh;
  }

  .maw6rem-sm {
    max-width: 6rem;
  }

  .maw600px-sm {
    max-width: 600px;
  }

  .maw60-sm {
    max-width: 60%;
  }

  .maw60vw-sm {
    max-width: 60vw;
  }

  .mah6rem-sm {
    max-height: 6rem;
  }

  .mah600px-sm {
    max-height: 600px;
  }

  .mah60-sm {
    max-height: 60%;
  }

  .mah60vh-sm {
    max-height: 60vh;
  }

  .w7rem-sm {
    width: 7rem;
  }

  .w70px-sm {
    width: 70px;
  }

  .w700px-sm {
    width: 700px;
  }

  .w70-sm {
    width: 70%;
  }

  .w70vw-sm {
    width: 70vw;
  }

  .h7rem-sm {
    height: 7rem;
  }

  .h70px-sm {
    height: 70px;
  }

  .h700px-sm {
    height: 700px;
  }

  .h70-sm {
    height: 70%;
  }

  .h70vh-sm {
    height: 70vh;
  }

  .miw7rem-sm {
    min-width: 7rem;
  }

  .miw700px-sm {
    min-width: 700px;
  }

  .miw70-sm {
    min-width: 70%;
  }

  .miw70vw-sm {
    min-width: 70vw;
  }

  .mih7rem-sm {
    min-height: 7rem;
  }

  .mih700px-sm {
    min-height: 700px;
  }

  .mih70-sm {
    min-height: 70%;
  }

  .mih70vh-sm {
    min-height: 70vh;
  }

  .maw7rem-sm {
    max-width: 7rem;
  }

  .maw700px-sm {
    max-width: 700px;
  }

  .maw70-sm {
    max-width: 70%;
  }

  .maw70vw-sm {
    max-width: 70vw;
  }

  .mah7rem-sm {
    max-height: 7rem;
  }

  .mah700px-sm {
    max-height: 700px;
  }

  .mah70-sm {
    max-height: 70%;
  }

  .mah70vh-sm {
    max-height: 70vh;
  }

  .w8rem-sm {
    width: 8rem;
  }

  .w80px-sm {
    width: 80px;
  }

  .w800px-sm {
    width: 800px;
  }

  .w80-sm {
    width: 80%;
  }

  .w80vw-sm {
    width: 80vw;
  }

  .h8rem-sm {
    height: 8rem;
  }

  .h80px-sm {
    height: 80px;
  }

  .h800px-sm {
    height: 800px;
  }

  .h80-sm {
    height: 80%;
  }

  .h80vh-sm {
    height: 80vh;
  }

  .miw8rem-sm {
    min-width: 8rem;
  }

  .miw800px-sm {
    min-width: 800px;
  }

  .miw80-sm {
    min-width: 80%;
  }

  .miw80vw-sm {
    min-width: 80vw;
  }

  .mih8rem-sm {
    min-height: 8rem;
  }

  .mih800px-sm {
    min-height: 800px;
  }

  .mih80-sm {
    min-height: 80%;
  }

  .mih80vh-sm {
    min-height: 80vh;
  }

  .maw8rem-sm {
    max-width: 8rem;
  }

  .maw800px-sm {
    max-width: 800px;
  }

  .maw80-sm {
    max-width: 80%;
  }

  .maw80vw-sm {
    max-width: 80vw;
  }

  .mah8rem-sm {
    max-height: 8rem;
  }

  .mah800px-sm {
    max-height: 800px;
  }

  .mah80-sm {
    max-height: 80%;
  }

  .mah80vh-sm {
    max-height: 80vh;
  }

  .w9rem-sm {
    width: 9rem;
  }

  .w90px-sm {
    width: 90px;
  }

  .w900px-sm {
    width: 900px;
  }

  .w90-sm {
    width: 90%;
  }

  .w90vw-sm {
    width: 90vw;
  }

  .h9rem-sm {
    height: 9rem;
  }

  .h90px-sm {
    height: 90px;
  }

  .h900px-sm {
    height: 900px;
  }

  .h90-sm {
    height: 90%;
  }

  .h90vh-sm {
    height: 90vh;
  }

  .miw9rem-sm {
    min-width: 9rem;
  }

  .miw900px-sm {
    min-width: 900px;
  }

  .miw90-sm {
    min-width: 90%;
  }

  .miw90vw-sm {
    min-width: 90vw;
  }

  .mih9rem-sm {
    min-height: 9rem;
  }

  .mih900px-sm {
    min-height: 900px;
  }

  .mih90-sm {
    min-height: 90%;
  }

  .mih90vh-sm {
    min-height: 90vh;
  }

  .maw9rem-sm {
    max-width: 9rem;
  }

  .maw900px-sm {
    max-width: 900px;
  }

  .maw90-sm {
    max-width: 90%;
  }

  .maw90vw-sm {
    max-width: 90vw;
  }

  .mah9rem-sm {
    max-height: 9rem;
  }

  .mah900px-sm {
    max-height: 900px;
  }

  .mah90-sm {
    max-height: 90%;
  }

  .mah90vh-sm {
    max-height: 90vh;
  }

  .w10rem-sm {
    width: 10rem;
  }

  .w100px-sm {
    width: 100px;
  }

  .w1000px-sm {
    width: 1000px;
  }

  .w100-sm {
    width: 100%;
  }

  .w100vw-sm {
    width: 100vw;
  }

  .h10rem-sm {
    height: 10rem;
  }

  .h100px-sm {
    height: 100px;
  }

  .h1000px-sm {
    height: 1000px;
  }

  .h100-sm {
    height: 100%;
  }

  .h100vh-sm {
    height: 100vh;
  }

  .miw10rem-sm {
    min-width: 10rem;
  }

  .miw1000px-sm {
    min-width: 1000px;
  }

  .miw100-sm {
    min-width: 100%;
  }

  .miw100vw-sm {
    min-width: 100vw;
  }

  .mih10rem-sm {
    min-height: 10rem;
  }

  .mih1000px-sm {
    min-height: 1000px;
  }

  .mih100-sm {
    min-height: 100%;
  }

  .mih100vh-sm {
    min-height: 100vh;
  }

  .maw10rem-sm {
    max-width: 10rem;
  }

  .maw1000px-sm {
    max-width: 1000px;
  }

  .maw100-sm {
    max-width: 100%;
  }

  .maw100vw-sm {
    max-width: 100vw;
  }

  .mah10rem-sm {
    max-height: 10rem;
  }

  .mah1000px-sm {
    max-height: 1000px;
  }

  .mah100-sm {
    max-height: 100%;
  }

  .mah100vh-sm {
    max-height: 100vh;
  }

  .or0-sm {
    order: 0;
  }

  .br0px-sm {
    border-radius: 0;
  }

  .btlr0px-sm {
    border-top-left-radius: 0;
  }

  .btrr0px-sm {
    border-top-right-radius: 0;
  }

  .bblr0px-sm {
    border-bottom-left-radius: 0;
  }

  .bbrr0px-sm {
    border-bottom-right-radius: 0;
  }

  .or1-sm {
    order: 1;
  }

  .br5px-sm {
    border-radius: 6px;
  }

  .br8px-sm {
    border-radius: 8px;
  }

  .br19px-sm {
    border-radius: 19px;
  }

  .btlr5px-sm {
    border-top-left-radius: 5px;
  }

  .btrr5px-sm {
    border-top-right-radius: 5px;
  }

  .bblr5px-sm {
    border-bottom-left-radius: 5px;
  }

  .bbrr5px-sm {
    border-bottom-right-radius: 5px;
  }

  .or2-sm {
    order: 2;
  }

  .br10px-sm {
    border-radius: 12px;
  }

  .br16px-sm {
    border-radius: 16px;
  }

  .br38px-sm {
    border-radius: 38px;
  }

  .btlr10px-sm {
    border-top-left-radius: 10px;
  }

  .btrr10px-sm {
    border-top-right-radius: 10px;
  }

  .bblr10px-sm {
    border-bottom-left-radius: 10px;
  }

  .bbrr10px-sm {
    border-bottom-right-radius: 10px;
  }

  .or3-sm {
    order: 3;
  }

  .br15px-sm {
    border-radius: 18px;
  }

  .br24px-sm {
    border-radius: 24px;
  }

  .br57px-sm {
    border-radius: 57px;
  }

  .btlr15px-sm {
    border-top-left-radius: 15px;
  }

  .btrr15px-sm {
    border-top-right-radius: 15px;
  }

  .bblr15px-sm {
    border-bottom-left-radius: 15px;
  }

  .bbrr15px-sm {
    border-bottom-right-radius: 15px;
  }

  .or4-sm {
    order: 4;
  }

  .br20px-sm {
    border-radius: 24px;
  }

  .br32px-sm {
    border-radius: 32px;
  }

  .br76px-sm {
    border-radius: 76px;
  }

  .btlr20px-sm {
    border-top-left-radius: 20px;
  }

  .btrr20px-sm {
    border-top-right-radius: 20px;
  }

  .bblr20px-sm {
    border-bottom-left-radius: 20px;
  }

  .bbrr20px-sm {
    border-bottom-right-radius: 20px;
  }

  .or5-sm {
    order: 5;
  }

  .br25px-sm {
    border-radius: 30px;
  }

  .br95px-sm {
    border-radius: 95px;
  }

  .btlr25px-sm {
    border-top-left-radius: 25px;
  }

  .btrr25px-sm {
    border-top-right-radius: 25px;
  }

  .bblr25px-sm {
    border-bottom-left-radius: 25px;
  }

  .bbrr25px-sm {
    border-bottom-right-radius: 25px;
  }

  .or6-sm {
    order: 6;
  }

  .br30px-sm {
    border-radius: 36px;
  }

  .br48px-sm {
    border-radius: 48px;
  }

  .br114px-sm {
    border-radius: 114px;
  }

  .btlr30px-sm {
    border-top-left-radius: 30px;
  }

  .btrr30px-sm {
    border-top-right-radius: 30px;
  }

  .bblr30px-sm {
    border-bottom-left-radius: 30px;
  }

  .bbrr30px-sm {
    border-bottom-right-radius: 30px;
  }

  .or7-sm {
    order: 7;
  }

  .br35px-sm {
    border-radius: 42px;
  }

  .br56px-sm {
    border-radius: 56px;
  }

  .br133px-sm {
    border-radius: 133px;
  }

  .btlr35px-sm {
    border-top-left-radius: 35px;
  }

  .btrr35px-sm {
    border-top-right-radius: 35px;
  }

  .bblr35px-sm {
    border-bottom-left-radius: 35px;
  }

  .bbrr35px-sm {
    border-bottom-right-radius: 35px;
  }

  .or8-sm {
    order: 8;
  }

  .br40px-sm {
    border-radius: 48px;
  }

  .br64px-sm {
    border-radius: 64px;
  }

  .br152px-sm {
    border-radius: 152px;
  }

  .btlr40px-sm {
    border-top-left-radius: 40px;
  }

  .btrr40px-sm {
    border-top-right-radius: 40px;
  }

  .bblr40px-sm {
    border-bottom-left-radius: 40px;
  }

  .bbrr40px-sm {
    border-bottom-right-radius: 40px;
  }

  .or9-sm {
    order: 9;
  }

  .br45px-sm {
    border-radius: 54px;
  }

  .br72px-sm {
    border-radius: 72px;
  }

  .br171px-sm {
    border-radius: 171px;
  }

  .btlr45px-sm {
    border-top-left-radius: 45px;
  }

  .btrr45px-sm {
    border-top-right-radius: 45px;
  }

  .bblr45px-sm {
    border-bottom-left-radius: 45px;
  }

  .bbrr45px-sm {
    border-bottom-right-radius: 45px;
  }

  .or10-sm {
    order: 10;
  }

  .br50px-sm {
    border-radius: 60px;
  }

  .br80px-sm {
    border-radius: 80px;
  }

  .br190px-sm {
    border-radius: 190px;
  }

  .btlr50px-sm {
    border-top-left-radius: 50px;
  }

  .btrr50px-sm {
    border-top-right-radius: 50px;
  }

  .bblr50px-sm {
    border-bottom-left-radius: 50px;
  }

  .bbrr50px-sm {
    border-bottom-right-radius: 50px;
  }

  .br100 {
    border-radius: 100%;
  }

  .btlr100 {
    border-top-left-radius: 100%;
  }

  .btrr100 {
    border-top-right-radius: 100%;
  }

  .bblr100 {
    border-bottom-left-radius: 100%;
  }

  .bbrr100 {
    border-bottom-right-radius: 100%;
  }
}

@media (width >= 48rem) {
  .c1-md, .c1-hover-md:hover {
    color: #fff;
  }

  .bg1-md, .bg1-hover-md:hover {
    background-color: #fff;
  }

  .bo1-md, .bo1-hover-md:hover {
    border-color: #fff;
  }

  .f1-md, .f1-hover-md:hover {
    fill: #fff;
  }

  .c2-md, .c2-hover-md:hover {
    color: #000;
  }

  .bg2-md, .bg2-hover-md:hover {
    background-color: #000;
  }

  .bo2-md, .bo2-hover-md:hover {
    border-color: #000;
  }

  .f2-md, .f2-hover-md:hover {
    fill: #000;
  }

  .c3-md, .c3-hover-md:hover {
    color: #4a67ff;
  }

  .bg3-md, .bg3-hover-md:hover {
    background-color: #4a67ff;
  }

  .bo3-md, .bo3-hover-md:hover {
    border-color: #4a67ff;
  }

  .f3-md, .f3-hover-md:hover {
    fill: #4a67ff;
  }

  .c4-md, .c4-hover-md:hover {
    color: #fc7785;
  }

  .bg4-md, .bg4-hover-md:hover {
    background-color: #fc7785;
  }

  .bo4-md, .bo4-hover-md:hover {
    border-color: #fc7785;
  }

  .f4-md, .f4-hover-md:hover {
    fill: #fc7785;
  }

  .c5-md, .c5-hover-md:hover {
    color: #f0f0f8;
  }

  .bg5-md, .bg5-hover-md:hover {
    background-color: #f0f0f8;
  }

  .bo5-md, .bo5-hover-md:hover {
    border-color: #f0f0f8;
  }

  .f5-md, .f5-hover-md:hover {
    fill: #f0f0f8;
  }

  .c6-md, .c6-hover-md:hover {
    color: #002d63;
  }

  .bg6-md, .bg6-hover-md:hover {
    background-color: #002d63;
  }

  .bo6-md, .bo6-hover-md:hover {
    border-color: #002d63;
  }

  .f6-md, .f6-hover-md:hover {
    fill: #002d63;
  }

  .c7-md, .c7-hover-md:hover {
    color: #40dda1;
  }

  .bg7-md, .bg7-hover-md:hover {
    background-color: #40dda1;
  }

  .bo7-md, .bo7-hover-md:hover {
    border-color: #40dda1;
  }

  .f7-md, .f7-hover-md:hover {
    fill: #40dda1;
  }

  .c8-md, .c8-hover-md:hover {
    color: #ddb640;
  }

  .bg8-md, .bg8-hover-md:hover {
    background-color: #ddb640;
  }

  .bo8-md, .bo8-hover-md:hover {
    border-color: #ddb640;
  }

  .f8-md, .f8-hover-md:hover {
    fill: #ddb640;
  }

  .c9-md, .c9-hover-md:hover {
    color: #dd4040;
  }

  .bg9-md, .bg9-hover-md:hover {
    background-color: #dd4040;
  }

  .bo9-md, .bo9-hover-md:hover {
    border-color: #dd4040;
  }

  .f9-md, .f9-hover-md:hover {
    fill: #dd4040;
  }

  .c10-md, .c10-hover-md:hover {
    color: #038589;
  }

  .bg10-md, .bg10-hover-md:hover {
    background-color: #038589;
  }

  .bo10-md, .bo10-hover-md:hover {
    border-color: #038589;
  }

  .f10-md, .f10-hover-md:hover {
    fill: #038589;
  }

  .c11-md, .c11-hover-md:hover {
    color: #007ee5;
  }

  .bg11-md, .bg11-hover-md:hover {
    background-color: #007ee5;
  }

  .bo11-md, .bo11-hover-md:hover {
    border-color: #007ee5;
  }

  .f11-md, .f11-hover-md:hover {
    fill: #007ee5;
  }

  .c12-md, .c12-hover-md:hover {
    color: #969696;
  }

  .bg12-md, .bg12-hover-md:hover {
    background-color: #969696;
  }

  .bo12-md, .bo12-hover-md:hover {
    border-color: #969696;
  }

  .f12-md, .f12-hover-md:hover {
    fill: #969696;
  }

  .c13-md, .c13-hover-md:hover {
    color: #fb7a27;
  }

  .bg13-md, .bg13-hover-md:hover {
    background-color: #fb7a27;
  }

  .bo13-md, .bo13-hover-md:hover {
    border-color: #fb7a27;
  }

  .f13-md, .f13-hover-md:hover {
    fill: #fb7a27;
  }

  .c14-md, .c14-hover-md:hover {
    color: #505050;
  }

  .bg14-md, .bg14-hover-md:hover {
    background-color: #505050;
  }

  .bo14-md, .bo14-hover-md:hover {
    border-color: #505050;
  }

  .f14-md, .f14-hover-md:hover {
    fill: #505050;
  }

  .c15-md, .c15-hover-md:hover {
    color: #151313;
  }

  .bg15-md, .bg15-hover-md:hover {
    background-color: #151313;
  }

  .bo15-md, .bo15-hover-md:hover {
    border-color: #151313;
  }

  .f15-md, .f15-hover-md:hover {
    fill: #151313;
  }

  .c16-md, .c16-hover-md:hover {
    color: #d9d9d9;
  }

  .bg16-md, .bg16-hover-md:hover {
    background-color: #d9d9d9;
  }

  .bo16-md, .bo16-hover-md:hover {
    border-color: #d9d9d9;
  }

  .f16-md, .f16-hover-md:hover {
    fill: #d9d9d9;
  }

  .c17-md, .c17-hover-md:hover {
    color: #d3d9fc;
  }

  .bg17-md, .bg17-hover-md:hover {
    background-color: #d3d9fc;
  }

  .bo17-md, .bo17-hover-md:hover {
    border-color: #d3d9fc;
  }

  .f17-md, .f17-hover-md:hover {
    fill: #d3d9fc;
  }

  .c18-md, .c18-hover-md:hover {
    color: #6775b9;
  }

  .bg18-md, .bg18-hover-md:hover {
    background-color: #6775b9;
  }

  .bo18-md, .bo18-hover-md:hover {
    border-color: #6775b9;
  }

  .f18-md, .f18-hover-md:hover {
    fill: #6775b9;
  }

  .c19-md, .c19-hover-md:hover {
    color: #f5f6f9;
  }

  .bg19-md, .bg19-hover-md:hover {
    background-color: #f5f6f9;
  }

  .bo19-md, .bo19-hover-md:hover {
    border-color: #f5f6f9;
  }

  .f19-md, .f19-hover-md:hover {
    fill: #f5f6f9;
  }

  .c20-md, .c20-hover-md:hover {
    color: #f0f2fd;
  }

  .bg20-md, .bg20-hover-md:hover {
    background-color: #f0f2fd;
  }

  .bo20-md, .bo20-hover-md:hover {
    border-color: #f0f2fd;
  }

  .f20-md, .f20-hover-md:hover {
    fill: #f0f2fd;
  }

  .c21-md, .c21-hover-md:hover {
    color: #f9f9f9;
  }

  .bg21-md, .bg21-hover-md:hover {
    background-color: #f9f9f9;
  }

  .bo21-md, .bo21-hover-md:hover {
    border-color: #f9f9f9;
  }

  .f21-md, .f21-hover-md:hover {
    fill: #f9f9f9;
  }

  .c22-md, .c22-hover-md:hover {
    color: #f6f6f6;
  }

  .bg22-md, .bg22-hover-md:hover {
    background-color: #f6f6f6;
  }

  .bo22-md, .bo22-hover-md:hover {
    border-color: #f6f6f6;
  }

  .f22-md, .f22-hover-md:hover {
    fill: #f6f6f6;
  }

  .c23-md, .c23-hover-md:hover {
    color: #fd6474;
  }

  .bg23-md, .bg23-hover-md:hover {
    background-color: #fd6474;
  }

  .bo23-md, .bo23-hover-md:hover {
    border-color: #fd6474;
  }

  .f23-md, .f23-hover-md:hover {
    fill: #fd6474;
  }

  .c24-md, .c24-hover-md:hover {
    color: #efefef;
  }

  .bg24-md, .bg24-hover-md:hover {
    background-color: #efefef;
  }

  .bo24-md, .bo24-hover-md:hover {
    border-color: #efefef;
  }

  .f24-md, .f24-hover-md:hover {
    fill: #efefef;
  }

  .c25-md, .c25-hover-md:hover {
    color: #f8f8f8;
  }

  .bg25-md, .bg25-hover-md:hover {
    background-color: #f8f8f8;
  }

  .bo25-md, .bo25-hover-md:hover {
    border-color: #f8f8f8;
  }

  .f25-md, .f25-hover-md:hover {
    fill: #f8f8f8;
  }

  .c26-md, .c26-hover-md:hover {
    color: #0126ed;
  }

  .bg26-md, .bg26-hover-md:hover {
    background-color: #0126ed;
  }

  .bo26-md, .bo26-hover-md:hover {
    border-color: #0126ed;
  }

  .f26-md, .f26-hover-md:hover {
    fill: #0126ed;
  }

  .c27-md, .c27-hover-md:hover {
    color: #5e5f66;
  }

  .bg27-md, .bg27-hover-md:hover {
    background-color: #5e5f66;
  }

  .bo27-md, .bo27-hover-md:hover {
    border-color: #5e5f66;
  }

  .f27-md, .f27-hover-md:hover {
    fill: #5e5f66;
  }

  .c28-md, .c28-hover-md:hover {
    color: #aaacb5;
  }

  .bg28-md, .bg28-hover-md:hover {
    background-color: #aaacb5;
  }

  .bo28-md, .bo28-hover-md:hover {
    border-color: #aaacb5;
  }

  .f28-md, .f28-hover-md:hover {
    fill: #aaacb5;
  }

  .c29-md, .c29-hover-md:hover {
    color: #dde2f2;
  }

  .bg29-md, .bg29-hover-md:hover {
    background-color: #dde2f2;
  }

  .bo29-md, .bo29-hover-md:hover {
    border-color: #dde2f2;
  }

  .f29-md, .f29-hover-md:hover {
    fill: #dde2f2;
  }

  .c30-md, .c30-hover-md:hover {
    color: #09b255;
  }

  .bg30-md, .bg30-hover-md:hover {
    background-color: #09b255;
  }

  .bo30-md, .bo30-hover-md:hover {
    border-color: #09b255;
  }

  .f30-md, .f30-hover-md:hover {
    fill: #09b255;
  }

  .c31-md, .c31-hover-md:hover {
    color: #dbdfee;
  }

  .bg31-md, .bg31-hover-md:hover {
    background-color: #dbdfee;
  }

  .bo31-md, .bo31-hover-md:hover {
    border-color: #dbdfee;
  }

  .f31-md, .f31-hover-md:hover {
    fill: #dbdfee;
  }

  .c32-md, .c32-hover-md:hover {
    color: #737171;
  }

  .bg32-md, .bg32-hover-md:hover {
    background-color: #737171;
  }

  .bo32-md, .bo32-hover-md:hover {
    border-color: #737171;
  }

  .f32-md, .f32-hover-md:hover {
    fill: #737171;
  }

  .c33-md, .c33-hover-md:hover {
    color: #767b90;
  }

  .bg33-md, .bg33-hover-md:hover {
    background-color: #767b90;
  }

  .bo33-md, .bo33-hover-md:hover {
    border-color: #767b90;
  }

  .f33-md, .f33-hover-md:hover {
    fill: #767b90;
  }

  .c34-md, .c34-hover-md:hover {
    color: #15d757;
  }

  .bg34-md, .bg34-hover-md:hover {
    background-color: #15d757;
  }

  .bo34-md, .bo34-hover-md:hover {
    border-color: #15d757;
  }

  .f34-md, .f34-hover-md:hover {
    fill: #15d757;
  }

  .c35-md, .c35-hover-md:hover {
    color: #e8ebf5;
  }

  .bg35-md, .bg35-hover-md:hover {
    background-color: #e8ebf5;
  }

  .bo35-md, .bo35-hover-md:hover {
    border-color: #e8ebf5;
  }

  .f35-md, .f35-hover-md:hover {
    fill: #e8ebf5;
  }

  .c36-md, .c36-hover-md:hover {
    color: #4c6b92;
  }

  .bg36-md, .bg36-hover-md:hover {
    background-color: #4c6b92;
  }

  .bo36-md, .bo36-hover-md:hover {
    border-color: #4c6b92;
  }

  .f36-md, .f36-hover-md:hover {
    fill: #4c6b92;
  }

  .c37-md, .c37-hover-md:hover {
    color: #818181;
  }

  .bg37-md, .bg37-hover-md:hover {
    background-color: #818181;
  }

  .bo37-md, .bo37-hover-md:hover {
    border-color: #818181;
  }

  .f37-md, .f37-hover-md:hover {
    fill: #818181;
  }

  .c38-md, .c38-hover-md:hover {
    color: #f0f2fd;
  }

  .bg38-md, .bg38-hover-md:hover {
    background-color: #f0f2fd;
  }

  .bo38-md, .bo38-hover-md:hover {
    border-color: #f0f2fd;
  }

  .f38-md, .f38-hover-md:hover {
    fill: #f0f2fd;
  }

  .c39-md, .c39-hover-md:hover {
    color: #f99229;
  }

  .bg39-md, .bg39-hover-md:hover {
    background-color: #f99229;
  }

  .bo39-md, .bo39-hover-md:hover {
    border-color: #f99229;
  }

  .f39-md, .f39-hover-md:hover {
    fill: #f99229;
  }

  .c40-md, .c40-hover-md:hover {
    color: #2947ef;
  }

  .bg40-md, .bg40-hover-md:hover {
    background-color: #2947ef;
  }

  .bo40-md, .bo40-hover-md:hover {
    border-color: #2947ef;
  }

  .f40-md, .f40-hover-md:hover {
    fill: #2947ef;
  }

  .c41-md, .c41-hover-md:hover {
    color: #f5f6ff;
  }

  .bg41-md, .bg41-hover-md:hover {
    background-color: #f5f6ff;
  }

  .bo41-md, .bo41-hover-md:hover {
    border-color: #f5f6ff;
  }

  .f41-md, .f41-hover-md:hover {
    fill: #f5f6ff;
  }

  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .tal-md {
    text-align: left;
  }

  .tac-md {
    text-align: center;
  }

  .tar-md {
    text-align: right;
  }

  .taj-md {
    text-align: justify;
  }

  .tas-md {
    text-align: start;
  }

  .tae-md {
    text-align: end;
  }

  .fs0-md {
    font-size: 0;
  }

  .fs0-25rem-md {
    font-size: .25rem;
  }

  .fs0-125rem-md {
    font-size: .125rem;
  }

  .fs0-5rem-md {
    font-size: .5rem;
  }

  .fs0-75rem-md {
    font-size: .75rem;
  }

  .fs0-875rem-md {
    font-size: .875rem;
  }

  .fs1rem-md {
    font-size: 1rem;
  }

  .fs1-25rem-md {
    font-size: 1.25rem;
  }

  .fs1-125rem-md {
    font-size: 1.125rem;
  }

  .fs1-5rem-md {
    font-size: 1.5rem;
  }

  .fs1-75rem-md {
    font-size: 1.75rem;
  }

  .fs1-875rem-md {
    font-size: 1.875rem;
  }

  .fs2rem-md {
    font-size: 2rem;
  }

  .fs2-25rem-md {
    font-size: 2.25rem;
  }

  .fs2-125rem-md {
    font-size: 2.125rem;
  }

  .fs2-5rem-md {
    font-size: 2.5rem;
  }

  .fs2-75rem-md {
    font-size: 2.75rem;
  }

  .fs2-875rem-md {
    font-size: 2.875rem;
  }

  .fs3rem-md {
    font-size: 3rem;
  }

  .fs3-25rem-md {
    font-size: 3.25rem;
  }

  .fs3-125rem-md {
    font-size: 3.125rem;
  }

  .fs3-5rem-md {
    font-size: 3.5rem;
  }

  .fs3-75rem-md {
    font-size: 3.75rem;
  }

  .fs3-875rem-md {
    font-size: 3.875rem;
  }

  .fs4rem-md {
    font-size: 4rem;
  }

  .fs4-25rem-md {
    font-size: 4.25rem;
  }

  .fs4-125rem-md {
    font-size: 4.125rem;
  }

  .fs4-5rem-md {
    font-size: 4.5rem;
  }

  .fs4-75rem-md {
    font-size: 4.75rem;
  }

  .fs4-875rem-md {
    font-size: 4.875rem;
  }

  .fs5rem-md {
    font-size: 5rem;
  }

  .fs5-25rem-md {
    font-size: 5.25rem;
  }

  .fs5-125rem-md {
    font-size: 5.125rem;
  }

  .fs5-5rem-md {
    font-size: 5.5rem;
  }

  .fs5-75rem-md {
    font-size: 5.75rem;
  }

  .fs5-875rem-md {
    font-size: 5.875rem;
  }

  .fs6rem-md {
    font-size: 6rem;
  }

  .fs6-25rem-md {
    font-size: 6.25rem;
  }

  .fs6-125rem-md {
    font-size: 6.125rem;
  }

  .fs6-5rem-md {
    font-size: 6.5rem;
  }

  .fs6-75rem-md {
    font-size: 6.75rem;
  }

  .fs6-875rem-md {
    font-size: 6.875rem;
  }

  .fs7rem-md {
    font-size: 7rem;
  }

  .db-md {
    display: block;
  }

  .dib-md {
    display: inline-block;
  }

  .di-md {
    display: inline;
  }

  .df-md {
    display: flex;
  }

  .fdc-md {
    flex-direction: column;
  }

  .fdr-md {
    flex-direction: row;
  }

  .fdcr-md {
    flex-direction: column-reverse;
  }

  .fdrr-md {
    flex-direction: row-reverse;
  }

  .fww-md {
    flex-wrap: wrap;
  }

  .jcfs-md {
    justify-content: flex-start;
  }

  .jcc-md {
    justify-content: center;
  }

  .jcfe-md {
    justify-content: flex-end;
  }

  .jcsb-md {
    justify-content: space-between;
  }

  .jcsa-md {
    justify-content: space-around;
  }

  .jcse-md {
    justify-content: space-evenly;
  }

  .jce-md {
    justify-content: end;
  }

  .aifs-md {
    align-items: flex-start;
  }

  .aic-md {
    align-items: center;
  }

  .aife-md {
    align-items: flex-end;
  }

  .jss-md {
    justify-self: start;
  }

  .jsfs-md {
    justify-self: flex-start;
  }

  .jsc-md {
    justify-self: center;
  }

  .jsfe-md {
    justify-self: flex-end;
  }

  .jse-md {
    justify-self: end;
  }

  .ass-md {
    align-self: start;
  }

  .asfs-md {
    align-self: flex-start;
  }

  .asc-md {
    align-self: center;
  }

  .asfe-md {
    align-self: flex-end;
  }

  .ase-md {
    align-self: end;
  }

  .dif-md {
    display: inline-flex;
  }

  .dg-md {
    display: grid;
  }

  .brss-md {
    border-right-style: solid;
  }

  .brw1px-md {
    border-right-width: 1px;
  }

  .brw2px-md {
    border-right-width: 2px;
  }

  .gtcr1-md {
    grid-template-columns: repeat(1, 1fr);
  }

  .ftcr1-md {
    grid-column: 1 / 1fr;
  }

  .gtrr1-md {
    grid-template-rows: repeat(1, 1fr);
  }

  .gtcr1a-md {
    grid-template-columns: repeat(1, auto);
  }

  .gtrr1a-md {
    grid-template-rows: repeat(1, auto);
  }

  .gcs1-md {
    grid-column-start: 1;
  }

  .gce2-md {
    grid-column-end: 2;
  }

  .grs1-md {
    grid-row-start: 1;
  }

  .gre2-md {
    grid-row-end: 2;
  }

  .gtcr2-md {
    grid-template-columns: repeat(2, 1fr);
  }

  .ftcr2-md {
    grid-column: 2 / 1fr;
  }

  .gtrr2-md {
    grid-template-rows: repeat(2, 1fr);
  }

  .gtcr2a-md {
    grid-template-columns: repeat(2, auto);
  }

  .gtrr2a-md {
    grid-template-rows: repeat(2, auto);
  }

  .gcs2-md {
    grid-column-start: 2;
  }

  .gce3-md {
    grid-column-end: 3;
  }

  .grs2-md {
    grid-row-start: 2;
  }

  .gre3-md {
    grid-row-end: 3;
  }

  .gtcr3-md {
    grid-template-columns: repeat(3, 1fr);
  }

  .ftcr3-md {
    grid-column: 3 / 1fr;
  }

  .gtrr3-md {
    grid-template-rows: repeat(3, 1fr);
  }

  .gtcr3a-md {
    grid-template-columns: repeat(3, auto);
  }

  .gtrr3a-md {
    grid-template-rows: repeat(3, auto);
  }

  .gcs3-md {
    grid-column-start: 3;
  }

  .gce4-md {
    grid-column-end: 4;
  }

  .grs3-md {
    grid-row-start: 3;
  }

  .gre4-md {
    grid-row-end: 4;
  }

  .gtcr4-md {
    grid-template-columns: repeat(4, 1fr);
  }

  .ftcr4-md {
    grid-column: 4 / 1fr;
  }

  .gtrr4-md {
    grid-template-rows: repeat(4, 1fr);
  }

  .gtcr4a-md {
    grid-template-columns: repeat(4, auto);
  }

  .gtrr4a-md {
    grid-template-rows: repeat(4, auto);
  }

  .gcs4-md {
    grid-column-start: 4;
  }

  .gce5-md {
    grid-column-end: 5;
  }

  .grs4-md {
    grid-row-start: 4;
  }

  .gre5-md {
    grid-row-end: 5;
  }

  .gtcr5-md {
    grid-template-columns: repeat(5, 1fr);
  }

  .ftcr5-md {
    grid-column: 5 / 1fr;
  }

  .gtrr5-md {
    grid-template-rows: repeat(5, 1fr);
  }

  .gtcr5a-md {
    grid-template-columns: repeat(5, auto);
  }

  .gtrr5a-md {
    grid-template-rows: repeat(5, auto);
  }

  .gcs5-md {
    grid-column-start: 5;
  }

  .gce6-md {
    grid-column-end: 6;
  }

  .grs5-md {
    grid-row-start: 5;
  }

  .gre6-md {
    grid-row-end: 6;
  }

  .gtcr6-md {
    grid-template-columns: repeat(6, 1fr);
  }

  .ftcr6-md {
    grid-column: 6 / 1fr;
  }

  .gtrr6-md {
    grid-template-rows: repeat(6, 1fr);
  }

  .gtcr6a-md {
    grid-template-columns: repeat(6, auto);
  }

  .gtrr6a-md {
    grid-template-rows: repeat(6, auto);
  }

  .gcs6-md {
    grid-column-start: 6;
  }

  .gce7-md {
    grid-column-end: 7;
  }

  .grs6-md {
    grid-row-start: 6;
  }

  .gre7-md {
    grid-row-end: 7;
  }

  .gtcr7-md {
    grid-template-columns: repeat(7, 1fr);
  }

  .ftcr7-md {
    grid-column: 7 / 1fr;
  }

  .gtrr7-md {
    grid-template-rows: repeat(7, 1fr);
  }

  .gtcr7a-md {
    grid-template-columns: repeat(7, auto);
  }

  .gtrr7a-md {
    grid-template-rows: repeat(7, auto);
  }

  .gcs7-md {
    grid-column-start: 7;
  }

  .gce8-md {
    grid-column-end: 8;
  }

  .grs7-md {
    grid-row-start: 7;
  }

  .gre8-md {
    grid-row-end: 8;
  }

  .gtcr8-md {
    grid-template-columns: repeat(8, 1fr);
  }

  .ftcr8-md {
    grid-column: 8 / 1fr;
  }

  .gtrr8-md {
    grid-template-rows: repeat(8, 1fr);
  }

  .gtcr8a-md {
    grid-template-columns: repeat(8, auto);
  }

  .gtrr8a-md {
    grid-template-rows: repeat(8, auto);
  }

  .gcs8-md {
    grid-column-start: 8;
  }

  .gce9-md {
    grid-column-end: 9;
  }

  .grs8-md {
    grid-row-start: 8;
  }

  .gre9-md {
    grid-row-end: 9;
  }

  .gtcr9-md {
    grid-template-columns: repeat(9, 1fr);
  }

  .ftcr9-md {
    grid-column: 9 / 1fr;
  }

  .gtrr9-md {
    grid-template-rows: repeat(9, 1fr);
  }

  .gtcr9a-md {
    grid-template-columns: repeat(9, auto);
  }

  .gtrr9a-md {
    grid-template-rows: repeat(9, auto);
  }

  .gcs9-md {
    grid-column-start: 9;
  }

  .gce10-md {
    grid-column-end: 10;
  }

  .grs9-md {
    grid-row-start: 9;
  }

  .gre10-md {
    grid-row-end: 10;
  }

  .gtccr-md {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }

  .gtcr10-md {
    grid-template-columns: repeat(10, 1fr);
  }

  .ftcr10-md {
    grid-column: 10 / 1fr;
  }

  .gtrr10-md {
    grid-template-rows: repeat(10, 1fr);
  }

  .gtcr10a-md {
    grid-template-columns: repeat(10, auto);
  }

  .gtrr10a-md {
    grid-template-rows: repeat(10, auto);
  }

  .gcs10-md {
    grid-column-start: 10;
  }

  .gce11-md {
    grid-column-end: 11;
  }

  .grs10-md {
    grid-row-start: 10;
  }

  .gre11-md {
    grid-row-end: 11;
  }

  .gg0-5rem-md {
    grid-gap: .5rem;
  }

  .gg1rem-md {
    grid-gap: 1rem;
  }

  .rg0-5rem-md {
    row-gap: .5rem;
  }

  .rg1rem-md {
    row-gap: 1rem;
  }

  .cg0-5rem-md {
    column-gap: .5rem;
  }

  .cg1rem-md {
    column-gap: 1rem;
  }

  .gg1-5rem-md {
    grid-gap: 1.5rem;
  }

  .gg2rem-md {
    grid-gap: 2rem;
  }

  .rg1-5rem-md {
    row-gap: 1.5rem;
  }

  .rg2rem-md {
    row-gap: 2rem;
  }

  .cg1-5rem-md {
    column-gap: 1.5rem;
  }

  .cg2rem-md {
    column-gap: 2rem;
  }

  .gg2-5rem-md {
    grid-gap: 2.5rem;
  }

  .gg3rem-md {
    grid-gap: 3rem;
  }

  .rg2-5rem-md {
    row-gap: 2.5rem;
  }

  .rg3rem-md {
    row-gap: 3rem;
  }

  .cg2-5rem-md {
    column-gap: 2.5rem;
  }

  .cg3rem-md {
    column-gap: 3rem;
  }

  .gg3-5rem-md {
    grid-gap: 3.5rem;
  }

  .gg4rem-md {
    grid-gap: 4rem;
  }

  .rg3-5rem-md {
    row-gap: 3.5rem;
  }

  .rg4rem-md {
    row-gap: 4rem;
  }

  .cg3-5rem-md {
    column-gap: 3.5rem;
  }

  .cg4rem-md {
    column-gap: 4rem;
  }

  .gg4-5rem-md {
    grid-gap: 4.5rem;
  }

  .gg5rem-md {
    grid-gap: 5rem;
  }

  .rg4-5rem-md {
    row-gap: 4.5rem;
  }

  .rg5rem-md {
    row-gap: 5rem;
  }

  .cg4-5rem-md {
    column-gap: 4.5rem;
  }

  .cg5rem-md {
    column-gap: 5rem;
  }

  .dn-md {
    display: none;
  }

  .pa-md {
    position: absolute;
  }

  .pr-md {
    position: relative;
  }

  .pf-md {
    position: fixed;
  }

  .ps-md {
    -webkit-position: sticky;
    position: sticky;
  }

  .pst-md {
    position: static;
  }

  .t0-md {
    top: 0;
  }

  .r0-md {
    right: 0;
  }

  .b0-md {
    bottom: 0;
  }

  .l0-md {
    left: 0;
  }

  .t100-md {
    top: 100%;
  }

  .r100-md {
    right: 100%;
  }

  .b100-md {
    bottom: 100%;
  }

  .l100-md {
    left: 100%;
  }

  .fl-md {
    float: left;
  }

  .fr-md {
    float: right;
  }

  .fn-md {
    float: none;
  }

  .ma-md {
    margin: auto;
  }

  .mta-md, .mya-md {
    margin-top: auto;
  }

  .mra-md, .mxa-md {
    margin-right: auto;
  }

  .mba-md, .mya-md {
    margin-bottom: auto;
  }

  .mla-md, .mxa-md {
    margin-left: auto;
  }

  .m0-md {
    margin: 0;
  }

  .mt0-md, .my0-md {
    margin-top: 0;
  }

  .mr0-md, .mx0-md {
    margin-right: 0;
  }

  .mb0-md, .my0-md {
    margin-bottom: 0;
  }

  .ml0-md, .mx0-md {
    margin-left: 0;
  }

  .p0-md {
    padding: 0;
  }

  .pt0-md, .py0-md {
    padding-top: 0;
  }

  .pr0-md, .px0-md {
    padding-right: 0;
  }

  .pb0-md, .py0-md {
    padding-bottom: 0;
  }

  .pl0-md, .px0-md {
    padding-left: 0;
  }

  .m10-md {
    margin: 10%;
  }

  .mt10-md, .my10-md {
    margin-top: 10%;
  }

  .mr10-md, .mx10-md {
    margin-right: 10%;
  }

  .mb10-md, .my10-md {
    margin-bottom: 10%;
  }

  .ml10-md, .mx10-md {
    margin-left: 10%;
  }

  .p10-md {
    padding: 10%;
  }

  .pt10-md, .py10-md {
    padding-top: 10%;
  }

  .pr10-md, .px10-md {
    padding-right: 10%;
  }

  .pb10-md, .py10-md {
    padding-bottom: 10%;
  }

  .pl10-md, .px10-md {
    padding-left: 10%;
  }

  .m0-5rem-md {
    margin: .5rem;
  }

  .m1rem-md {
    margin: 1rem;
  }

  .mt0-5rem-md, .my0-5rem-md {
    margin-top: .5rem;
  }

  .mt1rem-md, .my1rem-md {
    margin-top: 1rem;
  }

  .mr0-5rem-md, .mx0-5rem-md {
    margin-right: .5rem;
  }

  .mr1rem-md, .mx1rem-md {
    margin-right: 1rem;
  }

  .mb0-5rem-md, .my0-5rem-md {
    margin-bottom: .5rem;
  }

  .mb1rem-md, .my1rem-md {
    margin-bottom: 1rem;
  }

  .ml0-5rem-md, .mx0-5rem-md {
    margin-left: .5rem;
  }

  .ml1rem-md, .mx1rem-md {
    margin-left: 1rem;
  }

  .p0-5rem-md {
    padding: .5rem;
  }

  .p1rem-md {
    padding: 1rem;
  }

  .pt0-5rem-md, .py0-5rem-md {
    padding-top: .5rem;
  }

  .pt1rem-md, .py1rem-md {
    padding-top: 1rem;
  }

  .pr0-5rem-md, .px0-5rem-md {
    padding-right: .5rem;
  }

  .pr1rem-md, .px1rem-md {
    padding-right: 1rem;
  }

  .pb0-5rem-md, .py0-5rem-md {
    padding-bottom: .5rem;
  }

  .pb1rem-md, .py1rem-md {
    padding-bottom: 1rem;
  }

  .pl0-5rem-md, .px0-5rem-md {
    padding-left: .5rem;
  }

  .pl1rem-md, .px1rem-md {
    padding-left: 1rem;
  }

  .m20-md {
    margin: 20%;
  }

  .mt20-md, .my20-md {
    margin-top: 20%;
  }

  .mr20-md, .mx20-md {
    margin-right: 20%;
  }

  .mb20-md, .my20-md {
    margin-bottom: 20%;
  }

  .ml20-md, .mx20-md {
    margin-left: 20%;
  }

  .p20-md {
    padding: 20%;
  }

  .pt20-md, .py20-md {
    padding-top: 20%;
  }

  .pr20-md, .px20-md {
    padding-right: 20%;
  }

  .pb20-md, .py20-md {
    padding-bottom: 20%;
  }

  .pl20-md, .px20-md {
    padding-left: 20%;
  }

  .m1-5rem-md {
    margin: 1.5rem;
  }

  .m2rem-md {
    margin: 2rem;
  }

  .mt1-5rem-md, .my1-5rem-md {
    margin-top: 1.5rem;
  }

  .mt2rem-md, .my2rem-md {
    margin-top: 2rem;
  }

  .mr1-5rem-md, .mx1-5rem-md {
    margin-right: 1.5rem;
  }

  .mr2rem-md, .mx2rem-md {
    margin-right: 2rem;
  }

  .mb1-5rem-md, .my1-5rem-md {
    margin-bottom: 1.5rem;
  }

  .mb2rem-md, .my2rem-md {
    margin-bottom: 2rem;
  }

  .ml1-5rem-md, .mx1-5rem-md {
    margin-left: 1.5rem;
  }

  .ml2rem-md, .mx2rem-md {
    margin-left: 2rem;
  }

  .p1-5rem-md {
    padding: 1.5rem;
  }

  .p2rem-md {
    padding: 2rem;
  }

  .pt1-5rem-md, .py1-5rem-md {
    padding-top: 1.5rem;
  }

  .pt2rem-md, .py2rem-md {
    padding-top: 2rem;
  }

  .pr1-5rem-md, .px1-5rem-md {
    padding-right: 1.5rem;
  }

  .pr2rem-md, .px2rem-md {
    padding-right: 2rem;
  }

  .pb1-5rem-md, .py1-5rem-md {
    padding-bottom: 1.5rem;
  }

  .pb2rem-md, .py2rem-md {
    padding-bottom: 2rem;
  }

  .pl1-5rem-md, .px1-5rem-md {
    padding-left: 1.5rem;
  }

  .pl2rem-md, .px2rem-md {
    padding-left: 2rem;
  }

  .m30-md {
    margin: 30%;
  }

  .mt30-md, .my30-md {
    margin-top: 30%;
  }

  .mr30-md, .mx30-md {
    margin-right: 30%;
  }

  .mb30-md, .my30-md {
    margin-bottom: 30%;
  }

  .ml30-md, .mx30-md {
    margin-left: 30%;
  }

  .p30-md {
    padding: 30%;
  }

  .pt30-md, .py30-md {
    padding-top: 30%;
  }

  .pr30-md, .px30-md {
    padding-right: 30%;
  }

  .pb30-md, .py30-md {
    padding-bottom: 30%;
  }

  .pl30-md, .px30-md {
    padding-left: 30%;
  }

  .m2-5rem-md {
    margin: 2.5rem;
  }

  .m3rem-md {
    margin: 3rem;
  }

  .mt2-5rem-md, .my2-5rem-md {
    margin-top: 2.5rem;
  }

  .mt3rem-md, .my3rem-md {
    margin-top: 3rem;
  }

  .mr2-5rem-md, .mx2-5rem-md {
    margin-right: 2.5rem;
  }

  .mr3rem-md, .mx3rem-md {
    margin-right: 3rem;
  }

  .mb2-5rem-md, .my2-5rem-md {
    margin-bottom: 2.5rem;
  }

  .mb3rem-md, .my3rem-md {
    margin-bottom: 3rem;
  }

  .ml2-5rem-md, .mx2-5rem-md {
    margin-left: 2.5rem;
  }

  .ml3rem-md, .mx3rem-md {
    margin-left: 3rem;
  }

  .p2-5rem-md {
    padding: 2.5rem;
  }

  .p3rem-md {
    padding: 3rem;
  }

  .pt2-5rem-md, .py2-5rem-md {
    padding-top: 2.5rem;
  }

  .pt3rem-md, .py3rem-md {
    padding-top: 3rem;
  }

  .pr2-5rem-md, .px2-5rem-md {
    padding-right: 2.5rem;
  }

  .pr3rem-md, .px3rem-md {
    padding-right: 3rem;
  }

  .pb2-5rem-md, .py2-5rem-md {
    padding-bottom: 2.5rem;
  }

  .pb3rem-md, .py3rem-md {
    padding-bottom: 3rem;
  }

  .pl2-5rem-md, .px2-5rem-md {
    padding-left: 2.5rem;
  }

  .pl3rem-md, .px3rem-md {
    padding-left: 3rem;
  }

  .m40-md {
    margin: 40%;
  }

  .mt40-md, .my40-md {
    margin-top: 40%;
  }

  .mr40-md, .mx40-md {
    margin-right: 40%;
  }

  .mb40-md, .my40-md {
    margin-bottom: 40%;
  }

  .ml40-md, .mx40-md {
    margin-left: 40%;
  }

  .p40-md {
    padding: 40%;
  }

  .pt40-md, .py40-md {
    padding-top: 40%;
  }

  .pr40-md, .px40-md {
    padding-right: 40%;
  }

  .pb40-md, .py40-md {
    padding-bottom: 40%;
  }

  .pl40-md, .px40-md {
    padding-left: 40%;
  }

  .m3-5rem-md {
    margin: 3.5rem;
  }

  .m4rem-md {
    margin: 4rem;
  }

  .mt3-5rem-md, .my3-5rem-md {
    margin-top: 3.5rem;
  }

  .mt4rem-md, .my4rem-md {
    margin-top: 4rem;
  }

  .mr3-5rem-md, .mx3-5rem-md {
    margin-right: 3.5rem;
  }

  .mr4rem-md, .mx4rem-md {
    margin-right: 4rem;
  }

  .mb3-5rem-md, .my3-5rem-md {
    margin-bottom: 3.5rem;
  }

  .mb4rem-md, .my4rem-md {
    margin-bottom: 4rem;
  }

  .ml3-5rem-md, .mx3-5rem-md {
    margin-left: 3.5rem;
  }

  .ml4rem-md, .mx4rem-md {
    margin-left: 4rem;
  }

  .p3-5rem-md {
    padding: 3.5rem;
  }

  .p4rem-md {
    padding: 4rem;
  }

  .pt3-5rem-md, .py3-5rem-md {
    padding-top: 3.5rem;
  }

  .pt4rem-md, .py4rem-md {
    padding-top: 4rem;
  }

  .pr3-5rem-md, .px3-5rem-md {
    padding-right: 3.5rem;
  }

  .pr4rem-md, .px4rem-md {
    padding-right: 4rem;
  }

  .pb3-5rem-md, .py3-5rem-md {
    padding-bottom: 3.5rem;
  }

  .pb4rem-md, .py4rem-md {
    padding-bottom: 4rem;
  }

  .pl3-5rem-md, .px3-5rem-md {
    padding-left: 3.5rem;
  }

  .pl4rem-md, .px4rem-md {
    padding-left: 4rem;
  }

  .m50-md {
    margin: 50%;
  }

  .mt50-md, .my50-md {
    margin-top: 50%;
  }

  .mr50-md, .mx50-md {
    margin-right: 50%;
  }

  .mb50-md, .my50-md {
    margin-bottom: 50%;
  }

  .ml50-md, .mx50-md {
    margin-left: 50%;
  }

  .p50-md {
    padding: 50%;
  }

  .pt50-md, .py50-md {
    padding-top: 50%;
  }

  .pr50-md, .px50-md {
    padding-right: 50%;
  }

  .pb50-md, .py50-md {
    padding-bottom: 50%;
  }

  .pl50-md, .px50-md {
    padding-left: 50%;
  }

  .m4-5rem-md {
    margin: 4.5rem;
  }

  .m5rem-md {
    margin: 5rem;
  }

  .mt4-5rem-md, .my4-5rem-md {
    margin-top: 4.5rem;
  }

  .mt5rem-md, .my5rem-md {
    margin-top: 5rem;
  }

  .mr4-5rem-md, .mx4-5rem-md {
    margin-right: 4.5rem;
  }

  .mr5rem-md, .mx5rem-md {
    margin-right: 5rem;
  }

  .mb4-5rem-md, .my4-5rem-md {
    margin-bottom: 4.5rem;
  }

  .mb5rem-md, .my5rem-md {
    margin-bottom: 5rem;
  }

  .ml4-5rem-md, .mx4-5rem-md {
    margin-left: 4.5rem;
  }

  .ml5rem-md, .mx5rem-md {
    margin-left: 5rem;
  }

  .p4-5rem-md {
    padding: 4.5rem;
  }

  .p5rem-md {
    padding: 5rem;
  }

  .pt4-5rem-md, .py4-5rem-md {
    padding-top: 4.5rem;
  }

  .pt5rem-md, .py5rem-md {
    padding-top: 5rem;
  }

  .pr4-5rem-md, .px4-5rem-md {
    padding-right: 4.5rem;
  }

  .pr5rem-md, .px5rem-md {
    padding-right: 5rem;
  }

  .pb4-5rem-md, .py4-5rem-md {
    padding-bottom: 4.5rem;
  }

  .pb5rem-md, .py5rem-md {
    padding-bottom: 5rem;
  }

  .pl4-5rem-md, .px4-5rem-md {
    padding-left: 4.5rem;
  }

  .pl5rem-md, .px5rem-md {
    padding-left: 5rem;
  }

  .m60-md {
    margin: 60%;
  }

  .mt60-md, .my60-md {
    margin-top: 60%;
  }

  .mr60-md, .mx60-md {
    margin-right: 60%;
  }

  .mb60-md, .my60-md {
    margin-bottom: 60%;
  }

  .ml60-md, .mx60-md {
    margin-left: 60%;
  }

  .p60-md {
    padding: 60%;
  }

  .pt60-md, .py60-md {
    padding-top: 60%;
  }

  .pr60-md, .px60-md {
    padding-right: 60%;
  }

  .pb60-md, .py60-md {
    padding-bottom: 60%;
  }

  .pl60-md, .px60-md {
    padding-left: 60%;
  }

  .m5-5rem-md {
    margin: 5.5rem;
  }

  .m6rem-md {
    margin: 6rem;
  }

  .mt5-5rem-md, .my5-5rem-md {
    margin-top: 5.5rem;
  }

  .mt6rem-md, .my6rem-md {
    margin-top: 6rem;
  }

  .mr5-5rem-md, .mx5-5rem-md {
    margin-right: 5.5rem;
  }

  .mr6rem-md, .mx6rem-md {
    margin-right: 6rem;
  }

  .mb5-5rem-md, .my5-5rem-md {
    margin-bottom: 5.5rem;
  }

  .mb6rem-md, .my6rem-md {
    margin-bottom: 6rem;
  }

  .ml5-5rem-md, .mx5-5rem-md {
    margin-left: 5.5rem;
  }

  .ml6rem-md, .mx6rem-md {
    margin-left: 6rem;
  }

  .p5-5rem-md {
    padding: 5.5rem;
  }

  .p6rem-md {
    padding: 6rem;
  }

  .pt5-5rem-md, .py5-5rem-md {
    padding-top: 5.5rem;
  }

  .pt6rem-md, .py6rem-md {
    padding-top: 6rem;
  }

  .pr5-5rem-md, .px5-5rem-md {
    padding-right: 5.5rem;
  }

  .pr6rem-md, .px6rem-md {
    padding-right: 6rem;
  }

  .pb5-5rem-md, .py5-5rem-md {
    padding-bottom: 5.5rem;
  }

  .pb6rem-md, .py6rem-md {
    padding-bottom: 6rem;
  }

  .pl5-5rem-md, .px5-5rem-md {
    padding-left: 5.5rem;
  }

  .pl6rem-md, .px6rem-md {
    padding-left: 6rem;
  }

  .m70-md {
    margin: 70%;
  }

  .mt70-md, .my70-md {
    margin-top: 70%;
  }

  .mr70-md, .mx70-md {
    margin-right: 70%;
  }

  .mb70-md, .my70-md {
    margin-bottom: 70%;
  }

  .ml70-md, .mx70-md {
    margin-left: 70%;
  }

  .p70-md {
    padding: 70%;
  }

  .pt70-md, .py70-md {
    padding-top: 70%;
  }

  .pr70-md, .px70-md {
    padding-right: 70%;
  }

  .pb70-md, .py70-md {
    padding-bottom: 70%;
  }

  .pl70-md, .px70-md {
    padding-left: 70%;
  }

  .m6-5rem-md {
    margin: 6.5rem;
  }

  .m7rem-md {
    margin: 7rem;
  }

  .mt6-5rem-md, .my6-5rem-md {
    margin-top: 6.5rem;
  }

  .mt7rem-md, .my7rem-md {
    margin-top: 7rem;
  }

  .mr6-5rem-md, .mx6-5rem-md {
    margin-right: 6.5rem;
  }

  .mr7rem-md, .mx7rem-md {
    margin-right: 7rem;
  }

  .mb6-5rem-md, .my6-5rem-md {
    margin-bottom: 6.5rem;
  }

  .mb7rem-md, .my7rem-md {
    margin-bottom: 7rem;
  }

  .ml6-5rem-md, .mx6-5rem-md {
    margin-left: 6.5rem;
  }

  .ml7rem-md, .mx7rem-md {
    margin-left: 7rem;
  }

  .p6-5rem-md {
    padding: 6.5rem;
  }

  .p7rem-md {
    padding: 7rem;
  }

  .pt6-5rem-md, .py6-5rem-md {
    padding-top: 6.5rem;
  }

  .pt7rem-md, .py7rem-md {
    padding-top: 7rem;
  }

  .pr6-5rem-md, .px6-5rem-md {
    padding-right: 6.5rem;
  }

  .pr7rem-md, .px7rem-md {
    padding-right: 7rem;
  }

  .pb6-5rem-md, .py6-5rem-md {
    padding-bottom: 6.5rem;
  }

  .pb7rem-md, .py7rem-md {
    padding-bottom: 7rem;
  }

  .pl6-5rem-md, .px6-5rem-md {
    padding-left: 6.5rem;
  }

  .pl7rem-md, .px7rem-md {
    padding-left: 7rem;
  }

  .m80-md {
    margin: 80%;
  }

  .mt80-md, .my80-md {
    margin-top: 80%;
  }

  .mr80-md, .mx80-md {
    margin-right: 80%;
  }

  .mb80-md, .my80-md {
    margin-bottom: 80%;
  }

  .ml80-md, .mx80-md {
    margin-left: 80%;
  }

  .p80-md {
    padding: 80%;
  }

  .pt80-md, .py80-md {
    padding-top: 80%;
  }

  .pr80-md, .px80-md {
    padding-right: 80%;
  }

  .pb80-md, .py80-md {
    padding-bottom: 80%;
  }

  .pl80-md, .px80-md {
    padding-left: 80%;
  }

  .m7-5rem-md {
    margin: 7.5rem;
  }

  .m8rem-md {
    margin: 8rem;
  }

  .mt7-5rem-md, .my7-5rem-md {
    margin-top: 7.5rem;
  }

  .mt8rem-md, .my8rem-md {
    margin-top: 8rem;
  }

  .mr7-5rem-md, .mx7-5rem-md {
    margin-right: 7.5rem;
  }

  .mr8rem-md, .mx8rem-md {
    margin-right: 8rem;
  }

  .mb7-5rem-md, .my7-5rem-md {
    margin-bottom: 7.5rem;
  }

  .mb8rem-md, .my8rem-md {
    margin-bottom: 8rem;
  }

  .ml7-5rem-md, .mx7-5rem-md {
    margin-left: 7.5rem;
  }

  .ml8rem-md, .mx8rem-md {
    margin-left: 8rem;
  }

  .p7-5rem-md {
    padding: 7.5rem;
  }

  .p8rem-md {
    padding: 8rem;
  }

  .pt7-5rem-md, .py7-5rem-md {
    padding-top: 7.5rem;
  }

  .pt8rem-md, .py8rem-md {
    padding-top: 8rem;
  }

  .pr7-5rem-md, .px7-5rem-md {
    padding-right: 7.5rem;
  }

  .pr8rem-md, .px8rem-md {
    padding-right: 8rem;
  }

  .pb7-5rem-md, .py7-5rem-md {
    padding-bottom: 7.5rem;
  }

  .pb8rem-md, .py8rem-md {
    padding-bottom: 8rem;
  }

  .pl7-5rem-md, .px7-5rem-md {
    padding-left: 7.5rem;
  }

  .pl8rem-md, .px8rem-md {
    padding-left: 8rem;
  }

  .m90-md {
    margin: 90%;
  }

  .mt90-md, .my90-md {
    margin-top: 90%;
  }

  .mr90-md, .mx90-md {
    margin-right: 90%;
  }

  .mb90-md, .my90-md {
    margin-bottom: 90%;
  }

  .ml90-md, .mx90-md {
    margin-left: 90%;
  }

  .p90-md {
    padding: 90%;
  }

  .pt90-md, .py90-md {
    padding-top: 90%;
  }

  .pr90-md, .px90-md {
    padding-right: 90%;
  }

  .pb90-md, .py90-md {
    padding-bottom: 90%;
  }

  .pl90-md, .px90-md {
    padding-left: 90%;
  }

  .m8-5rem-md {
    margin: 8.5rem;
  }

  .m9rem-md {
    margin: 9rem;
  }

  .mt8-5rem-md, .my8-5rem-md {
    margin-top: 8.5rem;
  }

  .mt9rem-md, .my9rem-md {
    margin-top: 9rem;
  }

  .mr8-5rem-md, .mx8-5rem-md {
    margin-right: 8.5rem;
  }

  .mr9rem-md, .mx9rem-md {
    margin-right: 9rem;
  }

  .mb8-5rem-md, .my8-5rem-md {
    margin-bottom: 8.5rem;
  }

  .mb9rem-md, .my9rem-md {
    margin-bottom: 9rem;
  }

  .ml8-5rem-md, .mx8-5rem-md {
    margin-left: 8.5rem;
  }

  .ml9rem-md, .mx9rem-md {
    margin-left: 9rem;
  }

  .p8-5rem-md {
    padding: 8.5rem;
  }

  .p9rem-md {
    padding: 9rem;
  }

  .pt8-5rem-md, .py8-5rem-md {
    padding-top: 8.5rem;
  }

  .pt9rem-md, .py9rem-md {
    padding-top: 9rem;
  }

  .pr8-5rem-md, .px8-5rem-md {
    padding-right: 8.5rem;
  }

  .pr9rem-md, .px9rem-md {
    padding-right: 9rem;
  }

  .pb8-5rem-md, .py8-5rem-md {
    padding-bottom: 8.5rem;
  }

  .pb9rem-md, .py9rem-md {
    padding-bottom: 9rem;
  }

  .pl8-5rem-md, .px8-5rem-md {
    padding-left: 8.5rem;
  }

  .pl9rem-md, .px9rem-md {
    padding-left: 9rem;
  }

  .m100-md {
    margin: 100%;
  }

  .mt100-md, .my100-md {
    margin-top: 100%;
  }

  .mr100-md, .mx100-md {
    margin-right: 100%;
  }

  .mb100-md, .my100-md {
    margin-bottom: 100%;
  }

  .ml100-md, .mx100-md {
    margin-left: 100%;
  }

  .p100-md {
    padding: 100%;
  }

  .pt100-md, .py100-md {
    padding-top: 100%;
  }

  .pr100-md, .px100-md {
    padding-right: 100%;
  }

  .pb100-md, .py100-md {
    padding-bottom: 100%;
  }

  .pl100-md, .px100-md {
    padding-left: 100%;
  }

  .m9-5rem-md {
    margin: 9.5rem;
  }

  .m10rem-md {
    margin: 10rem;
  }

  .mt9-5rem-md, .my9-5rem-md {
    margin-top: 9.5rem;
  }

  .mt10rem-md, .my10rem-md {
    margin-top: 10rem;
  }

  .mr9-5rem-md, .mx9-5rem-md {
    margin-right: 9.5rem;
  }

  .mr10rem-md, .mx10rem-md {
    margin-right: 10rem;
  }

  .mb9-5rem-md, .my9-5rem-md {
    margin-bottom: 9.5rem;
  }

  .mb10rem-md, .my10rem-md {
    margin-bottom: 10rem;
  }

  .ml9-5rem-md, .mx9-5rem-md {
    margin-left: 9.5rem;
  }

  .ml10rem-md, .mx10rem-md {
    margin-left: 10rem;
  }

  .p9-5rem-md {
    padding: 9.5rem;
  }

  .p10rem-md {
    padding: 10rem;
  }

  .pt9-5rem-md, .py9-5rem-md {
    padding-top: 9.5rem;
  }

  .pt10rem-md, .py10rem-md {
    padding-top: 10rem;
  }

  .pr9-5rem-md, .px9-5rem-md {
    padding-right: 9.5rem;
  }

  .pr10rem-md, .px10rem-md {
    padding-right: 10rem;
  }

  .pb9-5rem-md, .py9-5rem-md {
    padding-bottom: 9.5rem;
  }

  .pb10rem-md, .py10rem-md {
    padding-bottom: 10rem;
  }

  .pl9-5rem-md, .px9-5rem-md {
    padding-left: 9.5rem;
  }

  .pl10rem-md, .px10rem-md {
    padding-left: 10rem;
  }

  .wa-md {
    width: auto;
  }

  .ha-md {
    height: auto;
  }

  .w0-md {
    width: 0;
  }

  .h0-md {
    height: 0;
  }

  .maw0-md {
    max-width: 0;
  }

  .mah0-md {
    max-height: 0;
  }

  .mih0-md {
    min-height: 0;
  }

  .w1rem-md {
    width: 1rem;
  }

  .w10px-md {
    width: 10px;
  }

  .w10-md {
    width: 10%;
  }

  .w10vw-md {
    width: 10vw;
  }

  .h1rem-md {
    height: 1rem;
  }

  .h10px-md {
    height: 10px;
  }

  .h10-md {
    height: 10%;
  }

  .h10vh-md {
    height: 10vh;
  }

  .miw1rem-md {
    min-width: 1rem;
  }

  .miw100px-md {
    min-width: 100px;
  }

  .miw10-md {
    min-width: 10%;
  }

  .miw10vw-md {
    min-width: 10vw;
  }

  .mih1rem-md {
    min-height: 1rem;
  }

  .mih100px-md {
    min-height: 100px;
  }

  .mih10-md {
    min-height: 10%;
  }

  .mih10vh-md {
    min-height: 10vh;
  }

  .maw1rem-md {
    max-width: 1rem;
  }

  .maw100px-md {
    max-width: 100px;
  }

  .maw10-md {
    max-width: 10%;
  }

  .maw10vw-md {
    max-width: 10vw;
  }

  .mah1rem-md {
    max-height: 1rem;
  }

  .mah100px-md {
    max-height: 100px;
  }

  .mah10-md {
    max-height: 10%;
  }

  .mah10vh-md {
    max-height: 10vh;
  }

  .w2rem-md {
    width: 2rem;
  }

  .w20px-md {
    width: 20px;
  }

  .w200px-md {
    width: 200px;
  }

  .w20-md {
    width: 20%;
  }

  .w20vw-md {
    width: 20vw;
  }

  .h2rem-md {
    height: 2rem;
  }

  .h20px-md {
    height: 20px;
  }

  .h200px-md {
    height: 200px;
  }

  .h20-md {
    height: 20%;
  }

  .h20vh-md {
    height: 20vh;
  }

  .miw2rem-md {
    min-width: 2rem;
  }

  .miw200px-md {
    min-width: 200px;
  }

  .miw20-md {
    min-width: 20%;
  }

  .miw20vw-md {
    min-width: 20vw;
  }

  .mih2rem-md {
    min-height: 2rem;
  }

  .mih200px-md {
    min-height: 200px;
  }

  .mih20-md {
    min-height: 20%;
  }

  .mih20vh-md {
    min-height: 20vh;
  }

  .maw2rem-md {
    max-width: 2rem;
  }

  .maw200px-md {
    max-width: 200px;
  }

  .maw20-md {
    max-width: 20%;
  }

  .maw20vw-md {
    max-width: 20vw;
  }

  .mah2rem-md {
    max-height: 2rem;
  }

  .mah200px-md {
    max-height: 200px;
  }

  .mah20-md {
    max-height: 20%;
  }

  .mah20vh-md {
    max-height: 20vh;
  }

  .w3rem-md {
    width: 3rem;
  }

  .w30px-md {
    width: 30px;
  }

  .w300px-md {
    width: 300px;
  }

  .w30-md {
    width: 30%;
  }

  .w30vw-md {
    width: 30vw;
  }

  .h3rem-md {
    height: 3rem;
  }

  .h30px-md {
    height: 30px;
  }

  .h300px-md {
    height: 300px;
  }

  .h30-md {
    height: 30%;
  }

  .h30vh-md {
    height: 30vh;
  }

  .miw3rem-md {
    min-width: 3rem;
  }

  .miw300px-md {
    min-width: 300px;
  }

  .miw30-md {
    min-width: 30%;
  }

  .miw30vw-md {
    min-width: 30vw;
  }

  .mih3rem-md {
    min-height: 3rem;
  }

  .mih300px-md {
    min-height: 300px;
  }

  .mih30-md {
    min-height: 30%;
  }

  .mih30vh-md {
    min-height: 30vh;
  }

  .maw3rem-md {
    max-width: 3rem;
  }

  .maw300px-md {
    max-width: 300px;
  }

  .maw30-md {
    max-width: 30%;
  }

  .maw30vw-md {
    max-width: 30vw;
  }

  .mah3rem-md {
    max-height: 3rem;
  }

  .mah300px-md {
    max-height: 300px;
  }

  .mah30-md {
    max-height: 30%;
  }

  .mah30vh-md {
    max-height: 30vh;
  }

  .w4rem-md {
    width: 4rem;
  }

  .w40px-md {
    width: 40px;
  }

  .w400px-md {
    width: 400px;
  }

  .w40-md {
    width: 40%;
  }

  .w40vw-md {
    width: 40vw;
  }

  .h4rem-md {
    height: 4rem;
  }

  .h40px-md {
    height: 40px;
  }

  .h400px-md {
    height: 400px;
  }

  .h40-md {
    height: 40%;
  }

  .h40vh-md {
    height: 40vh;
  }

  .miw4rem-md {
    min-width: 4rem;
  }

  .miw400px-md {
    min-width: 400px;
  }

  .miw40-md {
    min-width: 40%;
  }

  .miw40vw-md {
    min-width: 40vw;
  }

  .mih4rem-md {
    min-height: 4rem;
  }

  .mih400px-md {
    min-height: 400px;
  }

  .mih40-md {
    min-height: 40%;
  }

  .mih40vh-md {
    min-height: 40vh;
  }

  .maw4rem-md {
    max-width: 4rem;
  }

  .maw400px-md {
    max-width: 400px;
  }

  .maw40-md {
    max-width: 40%;
  }

  .maw40vw-md {
    max-width: 40vw;
  }

  .mah4rem-md {
    max-height: 4rem;
  }

  .mah400px-md {
    max-height: 400px;
  }

  .mah40-md {
    max-height: 40%;
  }

  .mah40vh-md {
    max-height: 40vh;
  }

  .w5rem-md {
    width: 5rem;
  }

  .w50px-md {
    width: 50px;
  }

  .w500px-md {
    width: 500px;
  }

  .w50-md {
    width: 50%;
  }

  .w50vw-md {
    width: 50vw;
  }

  .h5rem-md {
    height: 5rem;
  }

  .h50px-md {
    height: 50px;
  }

  .h500px-md {
    height: 500px;
  }

  .h50-md {
    height: 50%;
  }

  .h50vh-md {
    height: 50vh;
  }

  .miw5rem-md {
    min-width: 5rem;
  }

  .miw500px-md {
    min-width: 500px;
  }

  .miw50-md {
    min-width: 50%;
  }

  .miw50vw-md {
    min-width: 50vw;
  }

  .mih5rem-md {
    min-height: 5rem;
  }

  .mih500px-md {
    min-height: 500px;
  }

  .mih50-md {
    min-height: 50%;
  }

  .mih50vh-md {
    min-height: 50vh;
  }

  .maw5rem-md {
    max-width: 5rem;
  }

  .maw500px-md {
    max-width: 500px;
  }

  .maw50-md {
    max-width: 50%;
  }

  .maw50vw-md {
    max-width: 50vw;
  }

  .mah5rem-md {
    max-height: 5rem;
  }

  .mah500px-md {
    max-height: 500px;
  }

  .mah50-md {
    max-height: 50%;
  }

  .mah50vh-md {
    max-height: 50vh;
  }

  .w6rem-md {
    width: 6rem;
  }

  .w60px-md {
    width: 60px;
  }

  .w600px-md {
    width: 600px;
  }

  .w60-md {
    width: 60%;
  }

  .w60vw-md {
    width: 60vw;
  }

  .h6rem-md {
    height: 6rem;
  }

  .h60px-md {
    height: 60px;
  }

  .h600px-md {
    height: 600px;
  }

  .h60-md {
    height: 60%;
  }

  .h60vh-md {
    height: 60vh;
  }

  .miw6rem-md {
    min-width: 6rem;
  }

  .miw600px-md {
    min-width: 600px;
  }

  .miw60-md {
    min-width: 60%;
  }

  .miw60vw-md {
    min-width: 60vw;
  }

  .mih6rem-md {
    min-height: 6rem;
  }

  .mih600px-md {
    min-height: 600px;
  }

  .mih60-md {
    min-height: 60%;
  }

  .mih60vh-md {
    min-height: 60vh;
  }

  .maw6rem-md {
    max-width: 6rem;
  }

  .maw600px-md {
    max-width: 600px;
  }

  .maw60-md {
    max-width: 60%;
  }

  .maw60vw-md {
    max-width: 60vw;
  }

  .mah6rem-md {
    max-height: 6rem;
  }

  .mah600px-md {
    max-height: 600px;
  }

  .mah60-md {
    max-height: 60%;
  }

  .mah60vh-md {
    max-height: 60vh;
  }

  .w7rem-md {
    width: 7rem;
  }

  .w70px-md {
    width: 70px;
  }

  .w700px-md {
    width: 700px;
  }

  .w70-md {
    width: 70%;
  }

  .w70vw-md {
    width: 70vw;
  }

  .h7rem-md {
    height: 7rem;
  }

  .h70px-md {
    height: 70px;
  }

  .h700px-md {
    height: 700px;
  }

  .h70-md {
    height: 70%;
  }

  .h70vh-md {
    height: 70vh;
  }

  .miw7rem-md {
    min-width: 7rem;
  }

  .miw700px-md {
    min-width: 700px;
  }

  .miw70-md {
    min-width: 70%;
  }

  .miw70vw-md {
    min-width: 70vw;
  }

  .mih7rem-md {
    min-height: 7rem;
  }

  .mih700px-md {
    min-height: 700px;
  }

  .mih70-md {
    min-height: 70%;
  }

  .mih70vh-md {
    min-height: 70vh;
  }

  .maw7rem-md {
    max-width: 7rem;
  }

  .maw700px-md {
    max-width: 700px;
  }

  .maw70-md {
    max-width: 70%;
  }

  .maw70vw-md {
    max-width: 70vw;
  }

  .mah7rem-md {
    max-height: 7rem;
  }

  .mah700px-md {
    max-height: 700px;
  }

  .mah70-md {
    max-height: 70%;
  }

  .mah70vh-md {
    max-height: 70vh;
  }

  .w8rem-md {
    width: 8rem;
  }

  .w80px-md {
    width: 80px;
  }

  .w800px-md {
    width: 800px;
  }

  .w80-md {
    width: 80%;
  }

  .w80vw-md {
    width: 80vw;
  }

  .h8rem-md {
    height: 8rem;
  }

  .h80px-md {
    height: 80px;
  }

  .h800px-md {
    height: 800px;
  }

  .h80-md {
    height: 80%;
  }

  .h80vh-md {
    height: 80vh;
  }

  .miw8rem-md {
    min-width: 8rem;
  }

  .miw800px-md {
    min-width: 800px;
  }

  .miw80-md {
    min-width: 80%;
  }

  .miw80vw-md {
    min-width: 80vw;
  }

  .mih8rem-md {
    min-height: 8rem;
  }

  .mih800px-md {
    min-height: 800px;
  }

  .mih80-md {
    min-height: 80%;
  }

  .mih80vh-md {
    min-height: 80vh;
  }

  .maw8rem-md {
    max-width: 8rem;
  }

  .maw800px-md {
    max-width: 800px;
  }

  .maw80-md {
    max-width: 80%;
  }

  .maw80vw-md {
    max-width: 80vw;
  }

  .mah8rem-md {
    max-height: 8rem;
  }

  .mah800px-md {
    max-height: 800px;
  }

  .mah80-md {
    max-height: 80%;
  }

  .mah80vh-md {
    max-height: 80vh;
  }

  .w9rem-md {
    width: 9rem;
  }

  .w90px-md {
    width: 90px;
  }

  .w900px-md {
    width: 900px;
  }

  .w90-md {
    width: 90%;
  }

  .w90vw-md {
    width: 90vw;
  }

  .h9rem-md {
    height: 9rem;
  }

  .h90px-md {
    height: 90px;
  }

  .h900px-md {
    height: 900px;
  }

  .h90-md {
    height: 90%;
  }

  .h90vh-md {
    height: 90vh;
  }

  .miw9rem-md {
    min-width: 9rem;
  }

  .miw900px-md {
    min-width: 900px;
  }

  .miw90-md {
    min-width: 90%;
  }

  .miw90vw-md {
    min-width: 90vw;
  }

  .mih9rem-md {
    min-height: 9rem;
  }

  .mih900px-md {
    min-height: 900px;
  }

  .mih90-md {
    min-height: 90%;
  }

  .mih90vh-md {
    min-height: 90vh;
  }

  .maw9rem-md {
    max-width: 9rem;
  }

  .maw900px-md {
    max-width: 900px;
  }

  .maw90-md {
    max-width: 90%;
  }

  .maw90vw-md {
    max-width: 90vw;
  }

  .mah9rem-md {
    max-height: 9rem;
  }

  .mah900px-md {
    max-height: 900px;
  }

  .mah90-md {
    max-height: 90%;
  }

  .mah90vh-md {
    max-height: 90vh;
  }

  .w10rem-md {
    width: 10rem;
  }

  .w100px-md {
    width: 100px;
  }

  .w1000px-md {
    width: 1000px;
  }

  .w100-md {
    width: 100%;
  }

  .w100vw-md {
    width: 100vw;
  }

  .h10rem-md {
    height: 10rem;
  }

  .h100px-md {
    height: 100px;
  }

  .h1000px-md {
    height: 1000px;
  }

  .h100-md {
    height: 100%;
  }

  .h100vh-md {
    height: 100vh;
  }

  .miw10rem-md {
    min-width: 10rem;
  }

  .miw1000px-md {
    min-width: 1000px;
  }

  .miw100-md {
    min-width: 100%;
  }

  .miw100vw-md {
    min-width: 100vw;
  }

  .mih10rem-md {
    min-height: 10rem;
  }

  .mih1000px-md {
    min-height: 1000px;
  }

  .mih100-md {
    min-height: 100%;
  }

  .mih100vh-md {
    min-height: 100vh;
  }

  .maw10rem-md {
    max-width: 10rem;
  }

  .maw1000px-md {
    max-width: 1000px;
  }

  .maw100-md {
    max-width: 100%;
  }

  .maw100vw-md {
    max-width: 100vw;
  }

  .mah10rem-md {
    max-height: 10rem;
  }

  .mah1000px-md {
    max-height: 1000px;
  }

  .mah100-md {
    max-height: 100%;
  }

  .mah100vh-md {
    max-height: 100vh;
  }

  .or0-md {
    order: 0;
  }

  .br0px-md {
    border-radius: 0;
  }

  .btlr0px-md {
    border-top-left-radius: 0;
  }

  .btrr0px-md {
    border-top-right-radius: 0;
  }

  .bblr0px-md {
    border-bottom-left-radius: 0;
  }

  .bbrr0px-md {
    border-bottom-right-radius: 0;
  }

  .or1-md {
    order: 1;
  }

  .br5px-md {
    border-radius: 6px;
  }

  .br8px-md {
    border-radius: 8px;
  }

  .br19px-md {
    border-radius: 19px;
  }

  .btlr5px-md {
    border-top-left-radius: 5px;
  }

  .btrr5px-md {
    border-top-right-radius: 5px;
  }

  .bblr5px-md {
    border-bottom-left-radius: 5px;
  }

  .bbrr5px-md {
    border-bottom-right-radius: 5px;
  }

  .or2-md {
    order: 2;
  }

  .br10px-md {
    border-radius: 12px;
  }

  .br16px-md {
    border-radius: 16px;
  }

  .br38px-md {
    border-radius: 38px;
  }

  .btlr10px-md {
    border-top-left-radius: 10px;
  }

  .btrr10px-md {
    border-top-right-radius: 10px;
  }

  .bblr10px-md {
    border-bottom-left-radius: 10px;
  }

  .bbrr10px-md {
    border-bottom-right-radius: 10px;
  }

  .or3-md {
    order: 3;
  }

  .br15px-md {
    border-radius: 18px;
  }

  .br24px-md {
    border-radius: 24px;
  }

  .br57px-md {
    border-radius: 57px;
  }

  .btlr15px-md {
    border-top-left-radius: 15px;
  }

  .btrr15px-md {
    border-top-right-radius: 15px;
  }

  .bblr15px-md {
    border-bottom-left-radius: 15px;
  }

  .bbrr15px-md {
    border-bottom-right-radius: 15px;
  }

  .or4-md {
    order: 4;
  }

  .br20px-md {
    border-radius: 24px;
  }

  .br32px-md {
    border-radius: 32px;
  }

  .br76px-md {
    border-radius: 76px;
  }

  .btlr20px-md {
    border-top-left-radius: 20px;
  }

  .btrr20px-md {
    border-top-right-radius: 20px;
  }

  .bblr20px-md {
    border-bottom-left-radius: 20px;
  }

  .bbrr20px-md {
    border-bottom-right-radius: 20px;
  }

  .or5-md {
    order: 5;
  }

  .br25px-md {
    border-radius: 30px;
  }

  .br95px-md {
    border-radius: 95px;
  }

  .btlr25px-md {
    border-top-left-radius: 25px;
  }

  .btrr25px-md {
    border-top-right-radius: 25px;
  }

  .bblr25px-md {
    border-bottom-left-radius: 25px;
  }

  .bbrr25px-md {
    border-bottom-right-radius: 25px;
  }

  .or6-md {
    order: 6;
  }

  .br30px-md {
    border-radius: 36px;
  }

  .br48px-md {
    border-radius: 48px;
  }

  .br114px-md {
    border-radius: 114px;
  }

  .btlr30px-md {
    border-top-left-radius: 30px;
  }

  .btrr30px-md {
    border-top-right-radius: 30px;
  }

  .bblr30px-md {
    border-bottom-left-radius: 30px;
  }

  .bbrr30px-md {
    border-bottom-right-radius: 30px;
  }

  .or7-md {
    order: 7;
  }

  .br35px-md {
    border-radius: 42px;
  }

  .br56px-md {
    border-radius: 56px;
  }

  .br133px-md {
    border-radius: 133px;
  }

  .btlr35px-md {
    border-top-left-radius: 35px;
  }

  .btrr35px-md {
    border-top-right-radius: 35px;
  }

  .bblr35px-md {
    border-bottom-left-radius: 35px;
  }

  .bbrr35px-md {
    border-bottom-right-radius: 35px;
  }

  .or8-md {
    order: 8;
  }

  .br40px-md {
    border-radius: 48px;
  }

  .br64px-md {
    border-radius: 64px;
  }

  .br152px-md {
    border-radius: 152px;
  }

  .btlr40px-md {
    border-top-left-radius: 40px;
  }

  .btrr40px-md {
    border-top-right-radius: 40px;
  }

  .bblr40px-md {
    border-bottom-left-radius: 40px;
  }

  .bbrr40px-md {
    border-bottom-right-radius: 40px;
  }

  .or9-md {
    order: 9;
  }

  .br45px-md {
    border-radius: 54px;
  }

  .br72px-md {
    border-radius: 72px;
  }

  .br171px-md {
    border-radius: 171px;
  }

  .btlr45px-md {
    border-top-left-radius: 45px;
  }

  .btrr45px-md {
    border-top-right-radius: 45px;
  }

  .bblr45px-md {
    border-bottom-left-radius: 45px;
  }

  .bbrr45px-md {
    border-bottom-right-radius: 45px;
  }

  .or10-md {
    order: 10;
  }

  .br50px-md {
    border-radius: 60px;
  }

  .br80px-md {
    border-radius: 80px;
  }

  .br190px-md {
    border-radius: 190px;
  }

  .btlr50px-md {
    border-top-left-radius: 50px;
  }

  .btrr50px-md {
    border-top-right-radius: 50px;
  }

  .bblr50px-md {
    border-bottom-left-radius: 50px;
  }

  .bbrr50px-md {
    border-bottom-right-radius: 50px;
  }

  .br100 {
    border-radius: 100%;
  }

  .btlr100 {
    border-top-left-radius: 100%;
  }

  .btrr100 {
    border-top-right-radius: 100%;
  }

  .bblr100 {
    border-bottom-left-radius: 100%;
  }

  .bbrr100 {
    border-bottom-right-radius: 100%;
  }
}

@media (width >= 62rem) {
  .c1-lg, .c1-hover-lg:hover {
    color: #fff;
  }

  .bg1-lg, .bg1-hover-lg:hover {
    background-color: #fff;
  }

  .bo1-lg, .bo1-hover-lg:hover {
    border-color: #fff;
  }

  .f1-lg, .f1-hover-lg:hover {
    fill: #fff;
  }

  .c2-lg, .c2-hover-lg:hover {
    color: #000;
  }

  .bg2-lg, .bg2-hover-lg:hover {
    background-color: #000;
  }

  .bo2-lg, .bo2-hover-lg:hover {
    border-color: #000;
  }

  .f2-lg, .f2-hover-lg:hover {
    fill: #000;
  }

  .c3-lg, .c3-hover-lg:hover {
    color: #4a67ff;
  }

  .bg3-lg, .bg3-hover-lg:hover {
    background-color: #4a67ff;
  }

  .bo3-lg, .bo3-hover-lg:hover {
    border-color: #4a67ff;
  }

  .f3-lg, .f3-hover-lg:hover {
    fill: #4a67ff;
  }

  .c4-lg, .c4-hover-lg:hover {
    color: #fc7785;
  }

  .bg4-lg, .bg4-hover-lg:hover {
    background-color: #fc7785;
  }

  .bo4-lg, .bo4-hover-lg:hover {
    border-color: #fc7785;
  }

  .f4-lg, .f4-hover-lg:hover {
    fill: #fc7785;
  }

  .c5-lg, .c5-hover-lg:hover {
    color: #f0f0f8;
  }

  .bg5-lg, .bg5-hover-lg:hover {
    background-color: #f0f0f8;
  }

  .bo5-lg, .bo5-hover-lg:hover {
    border-color: #f0f0f8;
  }

  .f5-lg, .f5-hover-lg:hover {
    fill: #f0f0f8;
  }

  .c6-lg, .c6-hover-lg:hover {
    color: #002d63;
  }

  .bg6-lg, .bg6-hover-lg:hover {
    background-color: #002d63;
  }

  .bo6-lg, .bo6-hover-lg:hover {
    border-color: #002d63;
  }

  .f6-lg, .f6-hover-lg:hover {
    fill: #002d63;
  }

  .c7-lg, .c7-hover-lg:hover {
    color: #40dda1;
  }

  .bg7-lg, .bg7-hover-lg:hover {
    background-color: #40dda1;
  }

  .bo7-lg, .bo7-hover-lg:hover {
    border-color: #40dda1;
  }

  .f7-lg, .f7-hover-lg:hover {
    fill: #40dda1;
  }

  .c8-lg, .c8-hover-lg:hover {
    color: #ddb640;
  }

  .bg8-lg, .bg8-hover-lg:hover {
    background-color: #ddb640;
  }

  .bo8-lg, .bo8-hover-lg:hover {
    border-color: #ddb640;
  }

  .f8-lg, .f8-hover-lg:hover {
    fill: #ddb640;
  }

  .c9-lg, .c9-hover-lg:hover {
    color: #dd4040;
  }

  .bg9-lg, .bg9-hover-lg:hover {
    background-color: #dd4040;
  }

  .bo9-lg, .bo9-hover-lg:hover {
    border-color: #dd4040;
  }

  .f9-lg, .f9-hover-lg:hover {
    fill: #dd4040;
  }

  .c10-lg, .c10-hover-lg:hover {
    color: #038589;
  }

  .bg10-lg, .bg10-hover-lg:hover {
    background-color: #038589;
  }

  .bo10-lg, .bo10-hover-lg:hover {
    border-color: #038589;
  }

  .f10-lg, .f10-hover-lg:hover {
    fill: #038589;
  }

  .c11-lg, .c11-hover-lg:hover {
    color: #007ee5;
  }

  .bg11-lg, .bg11-hover-lg:hover {
    background-color: #007ee5;
  }

  .bo11-lg, .bo11-hover-lg:hover {
    border-color: #007ee5;
  }

  .f11-lg, .f11-hover-lg:hover {
    fill: #007ee5;
  }

  .c12-lg, .c12-hover-lg:hover {
    color: #969696;
  }

  .bg12-lg, .bg12-hover-lg:hover {
    background-color: #969696;
  }

  .bo12-lg, .bo12-hover-lg:hover {
    border-color: #969696;
  }

  .f12-lg, .f12-hover-lg:hover {
    fill: #969696;
  }

  .c13-lg, .c13-hover-lg:hover {
    color: #fb7a27;
  }

  .bg13-lg, .bg13-hover-lg:hover {
    background-color: #fb7a27;
  }

  .bo13-lg, .bo13-hover-lg:hover {
    border-color: #fb7a27;
  }

  .f13-lg, .f13-hover-lg:hover {
    fill: #fb7a27;
  }

  .c14-lg, .c14-hover-lg:hover {
    color: #505050;
  }

  .bg14-lg, .bg14-hover-lg:hover {
    background-color: #505050;
  }

  .bo14-lg, .bo14-hover-lg:hover {
    border-color: #505050;
  }

  .f14-lg, .f14-hover-lg:hover {
    fill: #505050;
  }

  .c15-lg, .c15-hover-lg:hover {
    color: #151313;
  }

  .bg15-lg, .bg15-hover-lg:hover {
    background-color: #151313;
  }

  .bo15-lg, .bo15-hover-lg:hover {
    border-color: #151313;
  }

  .f15-lg, .f15-hover-lg:hover {
    fill: #151313;
  }

  .c16-lg, .c16-hover-lg:hover {
    color: #d9d9d9;
  }

  .bg16-lg, .bg16-hover-lg:hover {
    background-color: #d9d9d9;
  }

  .bo16-lg, .bo16-hover-lg:hover {
    border-color: #d9d9d9;
  }

  .f16-lg, .f16-hover-lg:hover {
    fill: #d9d9d9;
  }

  .c17-lg, .c17-hover-lg:hover {
    color: #d3d9fc;
  }

  .bg17-lg, .bg17-hover-lg:hover {
    background-color: #d3d9fc;
  }

  .bo17-lg, .bo17-hover-lg:hover {
    border-color: #d3d9fc;
  }

  .f17-lg, .f17-hover-lg:hover {
    fill: #d3d9fc;
  }

  .c18-lg, .c18-hover-lg:hover {
    color: #6775b9;
  }

  .bg18-lg, .bg18-hover-lg:hover {
    background-color: #6775b9;
  }

  .bo18-lg, .bo18-hover-lg:hover {
    border-color: #6775b9;
  }

  .f18-lg, .f18-hover-lg:hover {
    fill: #6775b9;
  }

  .c19-lg, .c19-hover-lg:hover {
    color: #f5f6f9;
  }

  .bg19-lg, .bg19-hover-lg:hover {
    background-color: #f5f6f9;
  }

  .bo19-lg, .bo19-hover-lg:hover {
    border-color: #f5f6f9;
  }

  .f19-lg, .f19-hover-lg:hover {
    fill: #f5f6f9;
  }

  .c20-lg, .c20-hover-lg:hover {
    color: #f0f2fd;
  }

  .bg20-lg, .bg20-hover-lg:hover {
    background-color: #f0f2fd;
  }

  .bo20-lg, .bo20-hover-lg:hover {
    border-color: #f0f2fd;
  }

  .f20-lg, .f20-hover-lg:hover {
    fill: #f0f2fd;
  }

  .c21-lg, .c21-hover-lg:hover {
    color: #f9f9f9;
  }

  .bg21-lg, .bg21-hover-lg:hover {
    background-color: #f9f9f9;
  }

  .bo21-lg, .bo21-hover-lg:hover {
    border-color: #f9f9f9;
  }

  .f21-lg, .f21-hover-lg:hover {
    fill: #f9f9f9;
  }

  .c22-lg, .c22-hover-lg:hover {
    color: #f6f6f6;
  }

  .bg22-lg, .bg22-hover-lg:hover {
    background-color: #f6f6f6;
  }

  .bo22-lg, .bo22-hover-lg:hover {
    border-color: #f6f6f6;
  }

  .f22-lg, .f22-hover-lg:hover {
    fill: #f6f6f6;
  }

  .c23-lg, .c23-hover-lg:hover {
    color: #fd6474;
  }

  .bg23-lg, .bg23-hover-lg:hover {
    background-color: #fd6474;
  }

  .bo23-lg, .bo23-hover-lg:hover {
    border-color: #fd6474;
  }

  .f23-lg, .f23-hover-lg:hover {
    fill: #fd6474;
  }

  .c24-lg, .c24-hover-lg:hover {
    color: #efefef;
  }

  .bg24-lg, .bg24-hover-lg:hover {
    background-color: #efefef;
  }

  .bo24-lg, .bo24-hover-lg:hover {
    border-color: #efefef;
  }

  .f24-lg, .f24-hover-lg:hover {
    fill: #efefef;
  }

  .c25-lg, .c25-hover-lg:hover {
    color: #f8f8f8;
  }

  .bg25-lg, .bg25-hover-lg:hover {
    background-color: #f8f8f8;
  }

  .bo25-lg, .bo25-hover-lg:hover {
    border-color: #f8f8f8;
  }

  .f25-lg, .f25-hover-lg:hover {
    fill: #f8f8f8;
  }

  .c26-lg, .c26-hover-lg:hover {
    color: #0126ed;
  }

  .bg26-lg, .bg26-hover-lg:hover {
    background-color: #0126ed;
  }

  .bo26-lg, .bo26-hover-lg:hover {
    border-color: #0126ed;
  }

  .f26-lg, .f26-hover-lg:hover {
    fill: #0126ed;
  }

  .c27-lg, .c27-hover-lg:hover {
    color: #5e5f66;
  }

  .bg27-lg, .bg27-hover-lg:hover {
    background-color: #5e5f66;
  }

  .bo27-lg, .bo27-hover-lg:hover {
    border-color: #5e5f66;
  }

  .f27-lg, .f27-hover-lg:hover {
    fill: #5e5f66;
  }

  .c28-lg, .c28-hover-lg:hover {
    color: #aaacb5;
  }

  .bg28-lg, .bg28-hover-lg:hover {
    background-color: #aaacb5;
  }

  .bo28-lg, .bo28-hover-lg:hover {
    border-color: #aaacb5;
  }

  .f28-lg, .f28-hover-lg:hover {
    fill: #aaacb5;
  }

  .c29-lg, .c29-hover-lg:hover {
    color: #dde2f2;
  }

  .bg29-lg, .bg29-hover-lg:hover {
    background-color: #dde2f2;
  }

  .bo29-lg, .bo29-hover-lg:hover {
    border-color: #dde2f2;
  }

  .f29-lg, .f29-hover-lg:hover {
    fill: #dde2f2;
  }

  .c30-lg, .c30-hover-lg:hover {
    color: #09b255;
  }

  .bg30-lg, .bg30-hover-lg:hover {
    background-color: #09b255;
  }

  .bo30-lg, .bo30-hover-lg:hover {
    border-color: #09b255;
  }

  .f30-lg, .f30-hover-lg:hover {
    fill: #09b255;
  }

  .c31-lg, .c31-hover-lg:hover {
    color: #dbdfee;
  }

  .bg31-lg, .bg31-hover-lg:hover {
    background-color: #dbdfee;
  }

  .bo31-lg, .bo31-hover-lg:hover {
    border-color: #dbdfee;
  }

  .f31-lg, .f31-hover-lg:hover {
    fill: #dbdfee;
  }

  .c32-lg, .c32-hover-lg:hover {
    color: #737171;
  }

  .bg32-lg, .bg32-hover-lg:hover {
    background-color: #737171;
  }

  .bo32-lg, .bo32-hover-lg:hover {
    border-color: #737171;
  }

  .f32-lg, .f32-hover-lg:hover {
    fill: #737171;
  }

  .c33-lg, .c33-hover-lg:hover {
    color: #767b90;
  }

  .bg33-lg, .bg33-hover-lg:hover {
    background-color: #767b90;
  }

  .bo33-lg, .bo33-hover-lg:hover {
    border-color: #767b90;
  }

  .f33-lg, .f33-hover-lg:hover {
    fill: #767b90;
  }

  .c34-lg, .c34-hover-lg:hover {
    color: #15d757;
  }

  .bg34-lg, .bg34-hover-lg:hover {
    background-color: #15d757;
  }

  .bo34-lg, .bo34-hover-lg:hover {
    border-color: #15d757;
  }

  .f34-lg, .f34-hover-lg:hover {
    fill: #15d757;
  }

  .c35-lg, .c35-hover-lg:hover {
    color: #e8ebf5;
  }

  .bg35-lg, .bg35-hover-lg:hover {
    background-color: #e8ebf5;
  }

  .bo35-lg, .bo35-hover-lg:hover {
    border-color: #e8ebf5;
  }

  .f35-lg, .f35-hover-lg:hover {
    fill: #e8ebf5;
  }

  .c36-lg, .c36-hover-lg:hover {
    color: #4c6b92;
  }

  .bg36-lg, .bg36-hover-lg:hover {
    background-color: #4c6b92;
  }

  .bo36-lg, .bo36-hover-lg:hover {
    border-color: #4c6b92;
  }

  .f36-lg, .f36-hover-lg:hover {
    fill: #4c6b92;
  }

  .c37-lg, .c37-hover-lg:hover {
    color: #818181;
  }

  .bg37-lg, .bg37-hover-lg:hover {
    background-color: #818181;
  }

  .bo37-lg, .bo37-hover-lg:hover {
    border-color: #818181;
  }

  .f37-lg, .f37-hover-lg:hover {
    fill: #818181;
  }

  .c38-lg, .c38-hover-lg:hover {
    color: #f0f2fd;
  }

  .bg38-lg, .bg38-hover-lg:hover {
    background-color: #f0f2fd;
  }

  .bo38-lg, .bo38-hover-lg:hover {
    border-color: #f0f2fd;
  }

  .f38-lg, .f38-hover-lg:hover {
    fill: #f0f2fd;
  }

  .c39-lg, .c39-hover-lg:hover {
    color: #f99229;
  }

  .bg39-lg, .bg39-hover-lg:hover {
    background-color: #f99229;
  }

  .bo39-lg, .bo39-hover-lg:hover {
    border-color: #f99229;
  }

  .f39-lg, .f39-hover-lg:hover {
    fill: #f99229;
  }

  .c40-lg, .c40-hover-lg:hover {
    color: #2947ef;
  }

  .bg40-lg, .bg40-hover-lg:hover {
    background-color: #2947ef;
  }

  .bo40-lg, .bo40-hover-lg:hover {
    border-color: #2947ef;
  }

  .f40-lg, .f40-hover-lg:hover {
    fill: #2947ef;
  }

  .c41-lg, .c41-hover-lg:hover {
    color: #f5f6ff;
  }

  .bg41-lg, .bg41-hover-lg:hover {
    background-color: #f5f6ff;
  }

  .bo41-lg, .bo41-hover-lg:hover {
    border-color: #f5f6ff;
  }

  .f41-lg, .f41-hover-lg:hover {
    fill: #f5f6ff;
  }

  .tal-lg {
    text-align: left;
  }

  .tac-lg {
    text-align: center;
  }

  .tar-lg {
    text-align: right;
  }

  .taj-lg {
    text-align: justify;
  }

  .tas-lg {
    text-align: start;
  }

  .tae-lg {
    text-align: end;
  }

  .fs0-lg {
    font-size: 0;
  }

  .fs0-25rem-lg {
    font-size: .25rem;
  }

  .fs0-125rem-lg {
    font-size: .125rem;
  }

  .fs0-5rem-lg {
    font-size: .5rem;
  }

  .fs0-75rem-lg {
    font-size: .75rem;
  }

  .fs0-875rem-lg {
    font-size: .875rem;
  }

  .fs1rem-lg {
    font-size: 1rem;
  }

  .fs1-25rem-lg {
    font-size: 1.25rem;
  }

  .fs1-125rem-lg {
    font-size: 1.125rem;
  }

  .fs1-5rem-lg {
    font-size: 1.5rem;
  }

  .fs1-75rem-lg {
    font-size: 1.75rem;
  }

  .fs1-875rem-lg {
    font-size: 1.875rem;
  }

  .fs2rem-lg {
    font-size: 2rem;
  }

  .fs2-25rem-lg {
    font-size: 2.25rem;
  }

  .fs2-125rem-lg {
    font-size: 2.125rem;
  }

  .fs2-5rem-lg {
    font-size: 2.5rem;
  }

  .fs2-75rem-lg {
    font-size: 2.75rem;
  }

  .fs2-875rem-lg {
    font-size: 2.875rem;
  }

  .fs3rem-lg {
    font-size: 3rem;
  }

  .fs3-25rem-lg {
    font-size: 3.25rem;
  }

  .fs3-125rem-lg {
    font-size: 3.125rem;
  }

  .fs3-5rem-lg {
    font-size: 3.5rem;
  }

  .fs3-75rem-lg {
    font-size: 3.75rem;
  }

  .fs3-875rem-lg {
    font-size: 3.875rem;
  }

  .fs4rem-lg {
    font-size: 4rem;
  }

  .fs4-25rem-lg {
    font-size: 4.25rem;
  }

  .fs4-125rem-lg {
    font-size: 4.125rem;
  }

  .fs4-5rem-lg {
    font-size: 4.5rem;
  }

  .fs4-75rem-lg {
    font-size: 4.75rem;
  }

  .fs4-875rem-lg {
    font-size: 4.875rem;
  }

  .fs5rem-lg {
    font-size: 5rem;
  }

  .fs5-25rem-lg {
    font-size: 5.25rem;
  }

  .fs5-125rem-lg {
    font-size: 5.125rem;
  }

  .fs5-5rem-lg {
    font-size: 5.5rem;
  }

  .fs5-75rem-lg {
    font-size: 5.75rem;
  }

  .fs5-875rem-lg {
    font-size: 5.875rem;
  }

  .fs6rem-lg {
    font-size: 6rem;
  }

  .fs6-25rem-lg {
    font-size: 6.25rem;
  }

  .fs6-125rem-lg {
    font-size: 6.125rem;
  }

  .fs6-5rem-lg {
    font-size: 6.5rem;
  }

  .fs6-75rem-lg {
    font-size: 6.75rem;
  }

  .fs6-875rem-lg {
    font-size: 6.875rem;
  }

  .fs7rem-lg {
    font-size: 7rem;
  }

  .db-lg {
    display: block;
  }

  .dib-lg {
    display: inline-block;
  }

  .di-lg {
    display: inline;
  }

  .df-lg {
    display: flex;
  }

  .fdc-lg {
    flex-direction: column;
  }

  .fdr-lg {
    flex-direction: row;
  }

  .fdcr-lg {
    flex-direction: column-reverse;
  }

  .fdrr-lg {
    flex-direction: row-reverse;
  }

  .fww-lg {
    flex-wrap: wrap;
  }

  .jcfs-lg {
    justify-content: flex-start;
  }

  .jcc-lg {
    justify-content: center;
  }

  .jcfe-lg {
    justify-content: flex-end;
  }

  .jcsb-lg {
    justify-content: space-between;
  }

  .jcsa-lg {
    justify-content: space-around;
  }

  .jcse-lg {
    justify-content: space-evenly;
  }

  .jce-lg {
    justify-content: end;
  }

  .aifs-lg {
    align-items: flex-start;
  }

  .aic-lg {
    align-items: center;
  }

  .aife-lg {
    align-items: flex-end;
  }

  .jss-lg {
    justify-self: start;
  }

  .jsfs-lg {
    justify-self: flex-start;
  }

  .jsc-lg {
    justify-self: center;
  }

  .jsfe-lg {
    justify-self: flex-end;
  }

  .jse-lg {
    justify-self: end;
  }

  .ass-lg {
    align-self: start;
  }

  .asfs-lg {
    align-self: flex-start;
  }

  .asc-lg {
    align-self: center;
  }

  .asfe-lg {
    align-self: flex-end;
  }

  .ase-lg {
    align-self: end;
  }

  .dif-lg {
    display: inline-flex;
  }

  .dg-lg {
    display: grid;
  }

  .brss-lg {
    border-right-style: solid;
  }

  .brw1px-lg {
    border-right-width: 1px;
  }

  .brw2px-lg {
    border-right-width: 2px;
  }

  .gtcr1-lg {
    grid-template-columns: repeat(1, 1fr);
  }

  .ftcr1-lg {
    grid-column: 1 / 1fr;
  }

  .gtrr1-lg {
    grid-template-rows: repeat(1, 1fr);
  }

  .gtcr1a-lg {
    grid-template-columns: repeat(1, auto);
  }

  .gtrr1a-lg {
    grid-template-rows: repeat(1, auto);
  }

  .gcs1-lg {
    grid-column-start: 1;
  }

  .gce2-lg {
    grid-column-end: 2;
  }

  .grs1-lg {
    grid-row-start: 1;
  }

  .gre2-lg {
    grid-row-end: 2;
  }

  .gtcr2-lg {
    grid-template-columns: repeat(2, 1fr);
  }

  .ftcr2-lg {
    grid-column: 2 / 1fr;
  }

  .gtrr2-lg {
    grid-template-rows: repeat(2, 1fr);
  }

  .gtcr2a-lg {
    grid-template-columns: repeat(2, auto);
  }

  .gtrr2a-lg {
    grid-template-rows: repeat(2, auto);
  }

  .gcs2-lg {
    grid-column-start: 2;
  }

  .gce3-lg {
    grid-column-end: 3;
  }

  .grs2-lg {
    grid-row-start: 2;
  }

  .gre3-lg {
    grid-row-end: 3;
  }

  .gtcr3-lg {
    grid-template-columns: repeat(3, 1fr);
  }

  .ftcr3-lg {
    grid-column: 3 / 1fr;
  }

  .gtrr3-lg {
    grid-template-rows: repeat(3, 1fr);
  }

  .gtcr3a-lg {
    grid-template-columns: repeat(3, auto);
  }

  .gtrr3a-lg {
    grid-template-rows: repeat(3, auto);
  }

  .gcs3-lg {
    grid-column-start: 3;
  }

  .gce4-lg {
    grid-column-end: 4;
  }

  .grs3-lg {
    grid-row-start: 3;
  }

  .gre4-lg {
    grid-row-end: 4;
  }

  .gtcr4-lg {
    grid-template-columns: repeat(4, 1fr);
  }

  .ftcr4-lg {
    grid-column: 4 / 1fr;
  }

  .gtrr4-lg {
    grid-template-rows: repeat(4, 1fr);
  }

  .gtcr4a-lg {
    grid-template-columns: repeat(4, auto);
  }

  .gtrr4a-lg {
    grid-template-rows: repeat(4, auto);
  }

  .gcs4-lg {
    grid-column-start: 4;
  }

  .gce5-lg {
    grid-column-end: 5;
  }

  .grs4-lg {
    grid-row-start: 4;
  }

  .gre5-lg {
    grid-row-end: 5;
  }

  .gtcr5-lg {
    grid-template-columns: repeat(5, 1fr);
  }

  .ftcr5-lg {
    grid-column: 5 / 1fr;
  }

  .gtrr5-lg {
    grid-template-rows: repeat(5, 1fr);
  }

  .gtcr5a-lg {
    grid-template-columns: repeat(5, auto);
  }

  .gtrr5a-lg {
    grid-template-rows: repeat(5, auto);
  }

  .gcs5-lg {
    grid-column-start: 5;
  }

  .gce6-lg {
    grid-column-end: 6;
  }

  .grs5-lg {
    grid-row-start: 5;
  }

  .gre6-lg {
    grid-row-end: 6;
  }

  .gtcr6-lg {
    grid-template-columns: repeat(6, 1fr);
  }

  .ftcr6-lg {
    grid-column: 6 / 1fr;
  }

  .gtrr6-lg {
    grid-template-rows: repeat(6, 1fr);
  }

  .gtcr6a-lg {
    grid-template-columns: repeat(6, auto);
  }

  .gtrr6a-lg {
    grid-template-rows: repeat(6, auto);
  }

  .gcs6-lg {
    grid-column-start: 6;
  }

  .gce7-lg {
    grid-column-end: 7;
  }

  .grs6-lg {
    grid-row-start: 6;
  }

  .gre7-lg {
    grid-row-end: 7;
  }

  .gtcr7-lg {
    grid-template-columns: repeat(7, 1fr);
  }

  .ftcr7-lg {
    grid-column: 7 / 1fr;
  }

  .gtrr7-lg {
    grid-template-rows: repeat(7, 1fr);
  }

  .gtcr7a-lg {
    grid-template-columns: repeat(7, auto);
  }

  .gtrr7a-lg {
    grid-template-rows: repeat(7, auto);
  }

  .gcs7-lg {
    grid-column-start: 7;
  }

  .gce8-lg {
    grid-column-end: 8;
  }

  .grs7-lg {
    grid-row-start: 7;
  }

  .gre8-lg {
    grid-row-end: 8;
  }

  .gtcr8-lg {
    grid-template-columns: repeat(8, 1fr);
  }

  .ftcr8-lg {
    grid-column: 8 / 1fr;
  }

  .gtrr8-lg {
    grid-template-rows: repeat(8, 1fr);
  }

  .gtcr8a-lg {
    grid-template-columns: repeat(8, auto);
  }

  .gtrr8a-lg {
    grid-template-rows: repeat(8, auto);
  }

  .gcs8-lg {
    grid-column-start: 8;
  }

  .gce9-lg {
    grid-column-end: 9;
  }

  .grs8-lg {
    grid-row-start: 8;
  }

  .gre9-lg {
    grid-row-end: 9;
  }

  .gtcr9-lg {
    grid-template-columns: repeat(9, 1fr);
  }

  .ftcr9-lg {
    grid-column: 9 / 1fr;
  }

  .gtrr9-lg {
    grid-template-rows: repeat(9, 1fr);
  }

  .gtcr9a-lg {
    grid-template-columns: repeat(9, auto);
  }

  .gtrr9a-lg {
    grid-template-rows: repeat(9, auto);
  }

  .gcs9-lg {
    grid-column-start: 9;
  }

  .gce10-lg {
    grid-column-end: 10;
  }

  .grs9-lg {
    grid-row-start: 9;
  }

  .gre10-lg {
    grid-row-end: 10;
  }

  .gtccr-lg {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }

  .gtcr10-lg {
    grid-template-columns: repeat(10, 1fr);
  }

  .ftcr10-lg {
    grid-column: 10 / 1fr;
  }

  .gtrr10-lg {
    grid-template-rows: repeat(10, 1fr);
  }

  .gtcr10a-lg {
    grid-template-columns: repeat(10, auto);
  }

  .gtrr10a-lg {
    grid-template-rows: repeat(10, auto);
  }

  .gcs10-lg {
    grid-column-start: 10;
  }

  .gce11-lg {
    grid-column-end: 11;
  }

  .grs10-lg {
    grid-row-start: 10;
  }

  .gre11-lg {
    grid-row-end: 11;
  }

  .gg0-5rem-lg {
    grid-gap: .5rem;
  }

  .gg1rem-lg {
    grid-gap: 1rem;
  }

  .rg0-5rem-lg {
    row-gap: .5rem;
  }

  .rg1rem-lg {
    row-gap: 1rem;
  }

  .cg0-5rem-lg {
    column-gap: .5rem;
  }

  .cg1rem-lg {
    column-gap: 1rem;
  }

  .gg1-5rem-lg {
    grid-gap: 1.5rem;
  }

  .gg2rem-lg {
    grid-gap: 2rem;
  }

  .rg1-5rem-lg {
    row-gap: 1.5rem;
  }

  .rg2rem-lg {
    row-gap: 2rem;
  }

  .cg1-5rem-lg {
    column-gap: 1.5rem;
  }

  .cg2rem-lg {
    column-gap: 2rem;
  }

  .gg2-5rem-lg {
    grid-gap: 2.5rem;
  }

  .gg3rem-lg {
    grid-gap: 3rem;
  }

  .rg2-5rem-lg {
    row-gap: 2.5rem;
  }

  .rg3rem-lg {
    row-gap: 3rem;
  }

  .cg2-5rem-lg {
    column-gap: 2.5rem;
  }

  .cg3rem-lg {
    column-gap: 3rem;
  }

  .gg3-5rem-lg {
    grid-gap: 3.5rem;
  }

  .gg4rem-lg {
    grid-gap: 4rem;
  }

  .rg3-5rem-lg {
    row-gap: 3.5rem;
  }

  .rg4rem-lg {
    row-gap: 4rem;
  }

  .cg3-5rem-lg {
    column-gap: 3.5rem;
  }

  .cg4rem-lg {
    column-gap: 4rem;
  }

  .gg4-5rem-lg {
    grid-gap: 4.5rem;
  }

  .gg5rem-lg {
    grid-gap: 5rem;
  }

  .rg4-5rem-lg {
    row-gap: 4.5rem;
  }

  .rg5rem-lg {
    row-gap: 5rem;
  }

  .cg4-5rem-lg {
    column-gap: 4.5rem;
  }

  .cg5rem-lg {
    column-gap: 5rem;
  }

  .dn-lg {
    display: none;
  }

  .pa-lg {
    position: absolute;
  }

  .pr-lg {
    position: relative;
  }

  .pf-lg {
    position: fixed;
  }

  .ps-lg {
    -webkit-position: sticky;
    position: sticky;
  }

  .pst-lg {
    position: static;
  }

  .t0-lg {
    top: 0;
  }

  .r0-lg {
    right: 0;
  }

  .b0-lg {
    bottom: 0;
  }

  .l0-lg {
    left: 0;
  }

  .t100-lg {
    top: 100%;
  }

  .r100-lg {
    right: 100%;
  }

  .b100-lg {
    bottom: 100%;
  }

  .l100-lg {
    left: 100%;
  }

  .fl-lg {
    float: left;
  }

  .fr-lg {
    float: right;
  }

  .fn-lg {
    float: none;
  }

  .ma-lg {
    margin: auto;
  }

  .mta-lg, .mya-lg {
    margin-top: auto;
  }

  .mra-lg, .mxa-lg {
    margin-right: auto;
  }

  .mba-lg, .mya-lg {
    margin-bottom: auto;
  }

  .mla-lg, .mxa-lg {
    margin-left: auto;
  }

  .m0-lg {
    margin: 0;
  }

  .mt0-lg, .my0-lg {
    margin-top: 0;
  }

  .mr0-lg, .mx0-lg {
    margin-right: 0;
  }

  .mb0-lg, .my0-lg {
    margin-bottom: 0;
  }

  .ml0-lg, .mx0-lg {
    margin-left: 0;
  }

  .p0-lg {
    padding: 0;
  }

  .pt0-lg, .py0-lg {
    padding-top: 0;
  }

  .pr0-lg, .px0-lg {
    padding-right: 0;
  }

  .pb0-lg, .py0-lg {
    padding-bottom: 0;
  }

  .pl0-lg, .px0-lg {
    padding-left: 0;
  }

  .m10-lg {
    margin: 10%;
  }

  .mt10-lg, .my10-lg {
    margin-top: 10%;
  }

  .mr10-lg, .mx10-lg {
    margin-right: 10%;
  }

  .mb10-lg, .my10-lg {
    margin-bottom: 10%;
  }

  .ml10-lg, .mx10-lg {
    margin-left: 10%;
  }

  .p10-lg {
    padding: 10%;
  }

  .pt10-lg, .py10-lg {
    padding-top: 10%;
  }

  .pr10-lg, .px10-lg {
    padding-right: 10%;
  }

  .pb10-lg, .py10-lg {
    padding-bottom: 10%;
  }

  .pl10-lg, .px10-lg {
    padding-left: 10%;
  }

  .m0-5rem-lg {
    margin: .5rem;
  }

  .m1rem-lg {
    margin: 1rem;
  }

  .mt0-5rem-lg, .my0-5rem-lg {
    margin-top: .5rem;
  }

  .mt1rem-lg, .my1rem-lg {
    margin-top: 1rem;
  }

  .mr0-5rem-lg, .mx0-5rem-lg {
    margin-right: .5rem;
  }

  .mr1rem-lg, .mx1rem-lg {
    margin-right: 1rem;
  }

  .mb0-5rem-lg, .my0-5rem-lg {
    margin-bottom: .5rem;
  }

  .mb1rem-lg, .my1rem-lg {
    margin-bottom: 1rem;
  }

  .ml0-5rem-lg, .mx0-5rem-lg {
    margin-left: .5rem;
  }

  .ml1rem-lg, .mx1rem-lg {
    margin-left: 1rem;
  }

  .p0-5rem-lg {
    padding: .5rem;
  }

  .p1rem-lg {
    padding: 1rem;
  }

  .pt0-5rem-lg, .py0-5rem-lg {
    padding-top: .5rem;
  }

  .pt1rem-lg, .py1rem-lg {
    padding-top: 1rem;
  }

  .pr0-5rem-lg, .px0-5rem-lg {
    padding-right: .5rem;
  }

  .pr1rem-lg, .px1rem-lg {
    padding-right: 1rem;
  }

  .pb0-5rem-lg, .py0-5rem-lg {
    padding-bottom: .5rem;
  }

  .pb1rem-lg, .py1rem-lg {
    padding-bottom: 1rem;
  }

  .pl0-5rem-lg, .px0-5rem-lg {
    padding-left: .5rem;
  }

  .pl1rem-lg, .px1rem-lg {
    padding-left: 1rem;
  }

  .m20-lg {
    margin: 20%;
  }

  .mt20-lg, .my20-lg {
    margin-top: 20%;
  }

  .mr20-lg, .mx20-lg {
    margin-right: 20%;
  }

  .mb20-lg, .my20-lg {
    margin-bottom: 20%;
  }

  .ml20-lg, .mx20-lg {
    margin-left: 20%;
  }

  .p20-lg {
    padding: 20%;
  }

  .pt20-lg, .py20-lg {
    padding-top: 20%;
  }

  .pr20-lg, .px20-lg {
    padding-right: 20%;
  }

  .pb20-lg, .py20-lg {
    padding-bottom: 20%;
  }

  .pl20-lg, .px20-lg {
    padding-left: 20%;
  }

  .m1-5rem-lg {
    margin: 1.5rem;
  }

  .m2rem-lg {
    margin: 2rem;
  }

  .mt1-5rem-lg, .my1-5rem-lg {
    margin-top: 1.5rem;
  }

  .mt2rem-lg, .my2rem-lg {
    margin-top: 2rem;
  }

  .mr1-5rem-lg, .mx1-5rem-lg {
    margin-right: 1.5rem;
  }

  .mr2rem-lg, .mx2rem-lg {
    margin-right: 2rem;
  }

  .mb1-5rem-lg, .my1-5rem-lg {
    margin-bottom: 1.5rem;
  }

  .mb2rem-lg, .my2rem-lg {
    margin-bottom: 2rem;
  }

  .ml1-5rem-lg, .mx1-5rem-lg {
    margin-left: 1.5rem;
  }

  .ml2rem-lg, .mx2rem-lg {
    margin-left: 2rem;
  }

  .p1-5rem-lg {
    padding: 1.5rem;
  }

  .p2rem-lg {
    padding: 2rem;
  }

  .pt1-5rem-lg, .py1-5rem-lg {
    padding-top: 1.5rem;
  }

  .pt2rem-lg, .py2rem-lg {
    padding-top: 2rem;
  }

  .pr1-5rem-lg, .px1-5rem-lg {
    padding-right: 1.5rem;
  }

  .pr2rem-lg, .px2rem-lg {
    padding-right: 2rem;
  }

  .pb1-5rem-lg, .py1-5rem-lg {
    padding-bottom: 1.5rem;
  }

  .pb2rem-lg, .py2rem-lg {
    padding-bottom: 2rem;
  }

  .pl1-5rem-lg, .px1-5rem-lg {
    padding-left: 1.5rem;
  }

  .pl2rem-lg, .px2rem-lg {
    padding-left: 2rem;
  }

  .m30-lg {
    margin: 30%;
  }

  .mt30-lg, .my30-lg {
    margin-top: 30%;
  }

  .mr30-lg, .mx30-lg {
    margin-right: 30%;
  }

  .mb30-lg, .my30-lg {
    margin-bottom: 30%;
  }

  .ml30-lg, .mx30-lg {
    margin-left: 30%;
  }

  .p30-lg {
    padding: 30%;
  }

  .pt30-lg, .py30-lg {
    padding-top: 30%;
  }

  .pr30-lg, .px30-lg {
    padding-right: 30%;
  }

  .pb30-lg, .py30-lg {
    padding-bottom: 30%;
  }

  .pl30-lg, .px30-lg {
    padding-left: 30%;
  }

  .m2-5rem-lg {
    margin: 2.5rem;
  }

  .m3rem-lg {
    margin: 3rem;
  }

  .mt2-5rem-lg, .my2-5rem-lg {
    margin-top: 2.5rem;
  }

  .mt3rem-lg, .my3rem-lg {
    margin-top: 3rem;
  }

  .mr2-5rem-lg, .mx2-5rem-lg {
    margin-right: 2.5rem;
  }

  .mr3rem-lg, .mx3rem-lg {
    margin-right: 3rem;
  }

  .mb2-5rem-lg, .my2-5rem-lg {
    margin-bottom: 2.5rem;
  }

  .mb3rem-lg, .my3rem-lg {
    margin-bottom: 3rem;
  }

  .ml2-5rem-lg, .mx2-5rem-lg {
    margin-left: 2.5rem;
  }

  .ml3rem-lg, .mx3rem-lg {
    margin-left: 3rem;
  }

  .p2-5rem-lg {
    padding: 2.5rem;
  }

  .p3rem-lg {
    padding: 3rem;
  }

  .pt2-5rem-lg, .py2-5rem-lg {
    padding-top: 2.5rem;
  }

  .pt3rem-lg, .py3rem-lg {
    padding-top: 3rem;
  }

  .pr2-5rem-lg, .px2-5rem-lg {
    padding-right: 2.5rem;
  }

  .pr3rem-lg, .px3rem-lg {
    padding-right: 3rem;
  }

  .pb2-5rem-lg, .py2-5rem-lg {
    padding-bottom: 2.5rem;
  }

  .pb3rem-lg, .py3rem-lg {
    padding-bottom: 3rem;
  }

  .pl2-5rem-lg, .px2-5rem-lg {
    padding-left: 2.5rem;
  }

  .pl3rem-lg, .px3rem-lg {
    padding-left: 3rem;
  }

  .m40-lg {
    margin: 40%;
  }

  .mt40-lg, .my40-lg {
    margin-top: 40%;
  }

  .mr40-lg, .mx40-lg {
    margin-right: 40%;
  }

  .mb40-lg, .my40-lg {
    margin-bottom: 40%;
  }

  .ml40-lg, .mx40-lg {
    margin-left: 40%;
  }

  .p40-lg {
    padding: 40%;
  }

  .pt40-lg, .py40-lg {
    padding-top: 40%;
  }

  .pr40-lg, .px40-lg {
    padding-right: 40%;
  }

  .pb40-lg, .py40-lg {
    padding-bottom: 40%;
  }

  .pl40-lg, .px40-lg {
    padding-left: 40%;
  }

  .m3-5rem-lg {
    margin: 3.5rem;
  }

  .m4rem-lg {
    margin: 4rem;
  }

  .mt3-5rem-lg, .my3-5rem-lg {
    margin-top: 3.5rem;
  }

  .mt4rem-lg, .my4rem-lg {
    margin-top: 4rem;
  }

  .mr3-5rem-lg, .mx3-5rem-lg {
    margin-right: 3.5rem;
  }

  .mr4rem-lg, .mx4rem-lg {
    margin-right: 4rem;
  }

  .mb3-5rem-lg, .my3-5rem-lg {
    margin-bottom: 3.5rem;
  }

  .mb4rem-lg, .my4rem-lg {
    margin-bottom: 4rem;
  }

  .ml3-5rem-lg, .mx3-5rem-lg {
    margin-left: 3.5rem;
  }

  .ml4rem-lg, .mx4rem-lg {
    margin-left: 4rem;
  }

  .p3-5rem-lg {
    padding: 3.5rem;
  }

  .p4rem-lg {
    padding: 4rem;
  }

  .pt3-5rem-lg, .py3-5rem-lg {
    padding-top: 3.5rem;
  }

  .pt4rem-lg, .py4rem-lg {
    padding-top: 4rem;
  }

  .pr3-5rem-lg, .px3-5rem-lg {
    padding-right: 3.5rem;
  }

  .pr4rem-lg, .px4rem-lg {
    padding-right: 4rem;
  }

  .pb3-5rem-lg, .py3-5rem-lg {
    padding-bottom: 3.5rem;
  }

  .pb4rem-lg, .py4rem-lg {
    padding-bottom: 4rem;
  }

  .pl3-5rem-lg, .px3-5rem-lg {
    padding-left: 3.5rem;
  }

  .pl4rem-lg, .px4rem-lg {
    padding-left: 4rem;
  }

  .m50-lg {
    margin: 50%;
  }

  .mt50-lg, .my50-lg {
    margin-top: 50%;
  }

  .mr50-lg, .mx50-lg {
    margin-right: 50%;
  }

  .mb50-lg, .my50-lg {
    margin-bottom: 50%;
  }

  .ml50-lg, .mx50-lg {
    margin-left: 50%;
  }

  .p50-lg {
    padding: 50%;
  }

  .pt50-lg, .py50-lg {
    padding-top: 50%;
  }

  .pr50-lg, .px50-lg {
    padding-right: 50%;
  }

  .pb50-lg, .py50-lg {
    padding-bottom: 50%;
  }

  .pl50-lg, .px50-lg {
    padding-left: 50%;
  }

  .m4-5rem-lg {
    margin: 4.5rem;
  }

  .m5rem-lg {
    margin: 5rem;
  }

  .mt4-5rem-lg, .my4-5rem-lg {
    margin-top: 4.5rem;
  }

  .mt5rem-lg, .my5rem-lg {
    margin-top: 5rem;
  }

  .mr4-5rem-lg, .mx4-5rem-lg {
    margin-right: 4.5rem;
  }

  .mr5rem-lg, .mx5rem-lg {
    margin-right: 5rem;
  }

  .mb4-5rem-lg, .my4-5rem-lg {
    margin-bottom: 4.5rem;
  }

  .mb5rem-lg, .my5rem-lg {
    margin-bottom: 5rem;
  }

  .ml4-5rem-lg, .mx4-5rem-lg {
    margin-left: 4.5rem;
  }

  .ml5rem-lg, .mx5rem-lg {
    margin-left: 5rem;
  }

  .p4-5rem-lg {
    padding: 4.5rem;
  }

  .p5rem-lg {
    padding: 5rem;
  }

  .pt4-5rem-lg, .py4-5rem-lg {
    padding-top: 4.5rem;
  }

  .pt5rem-lg, .py5rem-lg {
    padding-top: 5rem;
  }

  .pr4-5rem-lg, .px4-5rem-lg {
    padding-right: 4.5rem;
  }

  .pr5rem-lg, .px5rem-lg {
    padding-right: 5rem;
  }

  .pb4-5rem-lg, .py4-5rem-lg {
    padding-bottom: 4.5rem;
  }

  .pb5rem-lg, .py5rem-lg {
    padding-bottom: 5rem;
  }

  .pl4-5rem-lg, .px4-5rem-lg {
    padding-left: 4.5rem;
  }

  .pl5rem-lg, .px5rem-lg {
    padding-left: 5rem;
  }

  .m60-lg {
    margin: 60%;
  }

  .mt60-lg, .my60-lg {
    margin-top: 60%;
  }

  .mr60-lg, .mx60-lg {
    margin-right: 60%;
  }

  .mb60-lg, .my60-lg {
    margin-bottom: 60%;
  }

  .ml60-lg, .mx60-lg {
    margin-left: 60%;
  }

  .p60-lg {
    padding: 60%;
  }

  .pt60-lg, .py60-lg {
    padding-top: 60%;
  }

  .pr60-lg, .px60-lg {
    padding-right: 60%;
  }

  .pb60-lg, .py60-lg {
    padding-bottom: 60%;
  }

  .pl60-lg, .px60-lg {
    padding-left: 60%;
  }

  .m5-5rem-lg {
    margin: 5.5rem;
  }

  .m6rem-lg {
    margin: 6rem;
  }

  .mt5-5rem-lg, .my5-5rem-lg {
    margin-top: 5.5rem;
  }

  .mt6rem-lg, .my6rem-lg {
    margin-top: 6rem;
  }

  .mr5-5rem-lg, .mx5-5rem-lg {
    margin-right: 5.5rem;
  }

  .mr6rem-lg, .mx6rem-lg {
    margin-right: 6rem;
  }

  .mb5-5rem-lg, .my5-5rem-lg {
    margin-bottom: 5.5rem;
  }

  .mb6rem-lg, .my6rem-lg {
    margin-bottom: 6rem;
  }

  .ml5-5rem-lg, .mx5-5rem-lg {
    margin-left: 5.5rem;
  }

  .ml6rem-lg, .mx6rem-lg {
    margin-left: 6rem;
  }

  .p5-5rem-lg {
    padding: 5.5rem;
  }

  .p6rem-lg {
    padding: 6rem;
  }

  .pt5-5rem-lg, .py5-5rem-lg {
    padding-top: 5.5rem;
  }

  .pt6rem-lg, .py6rem-lg {
    padding-top: 6rem;
  }

  .pr5-5rem-lg, .px5-5rem-lg {
    padding-right: 5.5rem;
  }

  .pr6rem-lg, .px6rem-lg {
    padding-right: 6rem;
  }

  .pb5-5rem-lg, .py5-5rem-lg {
    padding-bottom: 5.5rem;
  }

  .pb6rem-lg, .py6rem-lg {
    padding-bottom: 6rem;
  }

  .pl5-5rem-lg, .px5-5rem-lg {
    padding-left: 5.5rem;
  }

  .pl6rem-lg, .px6rem-lg {
    padding-left: 6rem;
  }

  .m70-lg {
    margin: 70%;
  }

  .mt70-lg, .my70-lg {
    margin-top: 70%;
  }

  .mr70-lg, .mx70-lg {
    margin-right: 70%;
  }

  .mb70-lg, .my70-lg {
    margin-bottom: 70%;
  }

  .ml70-lg, .mx70-lg {
    margin-left: 70%;
  }

  .p70-lg {
    padding: 70%;
  }

  .pt70-lg, .py70-lg {
    padding-top: 70%;
  }

  .pr70-lg, .px70-lg {
    padding-right: 70%;
  }

  .pb70-lg, .py70-lg {
    padding-bottom: 70%;
  }

  .pl70-lg, .px70-lg {
    padding-left: 70%;
  }

  .m6-5rem-lg {
    margin: 6.5rem;
  }

  .m7rem-lg {
    margin: 7rem;
  }

  .mt6-5rem-lg, .my6-5rem-lg {
    margin-top: 6.5rem;
  }

  .mt7rem-lg, .my7rem-lg {
    margin-top: 7rem;
  }

  .mr6-5rem-lg, .mx6-5rem-lg {
    margin-right: 6.5rem;
  }

  .mr7rem-lg, .mx7rem-lg {
    margin-right: 7rem;
  }

  .mb6-5rem-lg, .my6-5rem-lg {
    margin-bottom: 6.5rem;
  }

  .mb7rem-lg, .my7rem-lg {
    margin-bottom: 7rem;
  }

  .ml6-5rem-lg, .mx6-5rem-lg {
    margin-left: 6.5rem;
  }

  .ml7rem-lg, .mx7rem-lg {
    margin-left: 7rem;
  }

  .p6-5rem-lg {
    padding: 6.5rem;
  }

  .p7rem-lg {
    padding: 7rem;
  }

  .pt6-5rem-lg, .py6-5rem-lg {
    padding-top: 6.5rem;
  }

  .pt7rem-lg, .py7rem-lg {
    padding-top: 7rem;
  }

  .pr6-5rem-lg, .px6-5rem-lg {
    padding-right: 6.5rem;
  }

  .pr7rem-lg, .px7rem-lg {
    padding-right: 7rem;
  }

  .pb6-5rem-lg, .py6-5rem-lg {
    padding-bottom: 6.5rem;
  }

  .pb7rem-lg, .py7rem-lg {
    padding-bottom: 7rem;
  }

  .pl6-5rem-lg, .px6-5rem-lg {
    padding-left: 6.5rem;
  }

  .pl7rem-lg, .px7rem-lg {
    padding-left: 7rem;
  }

  .m80-lg {
    margin: 80%;
  }

  .mt80-lg, .my80-lg {
    margin-top: 80%;
  }

  .mr80-lg, .mx80-lg {
    margin-right: 80%;
  }

  .mb80-lg, .my80-lg {
    margin-bottom: 80%;
  }

  .ml80-lg, .mx80-lg {
    margin-left: 80%;
  }

  .p80-lg {
    padding: 80%;
  }

  .pt80-lg, .py80-lg {
    padding-top: 80%;
  }

  .pr80-lg, .px80-lg {
    padding-right: 80%;
  }

  .pb80-lg, .py80-lg {
    padding-bottom: 80%;
  }

  .pl80-lg, .px80-lg {
    padding-left: 80%;
  }

  .m7-5rem-lg {
    margin: 7.5rem;
  }

  .m8rem-lg {
    margin: 8rem;
  }

  .mt7-5rem-lg, .my7-5rem-lg {
    margin-top: 7.5rem;
  }

  .mt8rem-lg, .my8rem-lg {
    margin-top: 8rem;
  }

  .mr7-5rem-lg, .mx7-5rem-lg {
    margin-right: 7.5rem;
  }

  .mr8rem-lg, .mx8rem-lg {
    margin-right: 8rem;
  }

  .mb7-5rem-lg, .my7-5rem-lg {
    margin-bottom: 7.5rem;
  }

  .mb8rem-lg, .my8rem-lg {
    margin-bottom: 8rem;
  }

  .ml7-5rem-lg, .mx7-5rem-lg {
    margin-left: 7.5rem;
  }

  .ml8rem-lg, .mx8rem-lg {
    margin-left: 8rem;
  }

  .p7-5rem-lg {
    padding: 7.5rem;
  }

  .p8rem-lg {
    padding: 8rem;
  }

  .pt7-5rem-lg, .py7-5rem-lg {
    padding-top: 7.5rem;
  }

  .pt8rem-lg, .py8rem-lg {
    padding-top: 8rem;
  }

  .pr7-5rem-lg, .px7-5rem-lg {
    padding-right: 7.5rem;
  }

  .pr8rem-lg, .px8rem-lg {
    padding-right: 8rem;
  }

  .pb7-5rem-lg, .py7-5rem-lg {
    padding-bottom: 7.5rem;
  }

  .pb8rem-lg, .py8rem-lg {
    padding-bottom: 8rem;
  }

  .pl7-5rem-lg, .px7-5rem-lg {
    padding-left: 7.5rem;
  }

  .pl8rem-lg, .px8rem-lg {
    padding-left: 8rem;
  }

  .m90-lg {
    margin: 90%;
  }

  .mt90-lg, .my90-lg {
    margin-top: 90%;
  }

  .mr90-lg, .mx90-lg {
    margin-right: 90%;
  }

  .mb90-lg, .my90-lg {
    margin-bottom: 90%;
  }

  .ml90-lg, .mx90-lg {
    margin-left: 90%;
  }

  .p90-lg {
    padding: 90%;
  }

  .pt90-lg, .py90-lg {
    padding-top: 90%;
  }

  .pr90-lg, .px90-lg {
    padding-right: 90%;
  }

  .pb90-lg, .py90-lg {
    padding-bottom: 90%;
  }

  .pl90-lg, .px90-lg {
    padding-left: 90%;
  }

  .m8-5rem-lg {
    margin: 8.5rem;
  }

  .m9rem-lg {
    margin: 9rem;
  }

  .mt8-5rem-lg, .my8-5rem-lg {
    margin-top: 8.5rem;
  }

  .mt9rem-lg, .my9rem-lg {
    margin-top: 9rem;
  }

  .mr8-5rem-lg, .mx8-5rem-lg {
    margin-right: 8.5rem;
  }

  .mr9rem-lg, .mx9rem-lg {
    margin-right: 9rem;
  }

  .mb8-5rem-lg, .my8-5rem-lg {
    margin-bottom: 8.5rem;
  }

  .mb9rem-lg, .my9rem-lg {
    margin-bottom: 9rem;
  }

  .ml8-5rem-lg, .mx8-5rem-lg {
    margin-left: 8.5rem;
  }

  .ml9rem-lg, .mx9rem-lg {
    margin-left: 9rem;
  }

  .p8-5rem-lg {
    padding: 8.5rem;
  }

  .p9rem-lg {
    padding: 9rem;
  }

  .pt8-5rem-lg, .py8-5rem-lg {
    padding-top: 8.5rem;
  }

  .pt9rem-lg, .py9rem-lg {
    padding-top: 9rem;
  }

  .pr8-5rem-lg, .px8-5rem-lg {
    padding-right: 8.5rem;
  }

  .pr9rem-lg, .px9rem-lg {
    padding-right: 9rem;
  }

  .pb8-5rem-lg, .py8-5rem-lg {
    padding-bottom: 8.5rem;
  }

  .pb9rem-lg, .py9rem-lg {
    padding-bottom: 9rem;
  }

  .pl8-5rem-lg, .px8-5rem-lg {
    padding-left: 8.5rem;
  }

  .pl9rem-lg, .px9rem-lg {
    padding-left: 9rem;
  }

  .m100-lg {
    margin: 100%;
  }

  .mt100-lg, .my100-lg {
    margin-top: 100%;
  }

  .mr100-lg, .mx100-lg {
    margin-right: 100%;
  }

  .mb100-lg, .my100-lg {
    margin-bottom: 100%;
  }

  .ml100-lg, .mx100-lg {
    margin-left: 100%;
  }

  .p100-lg {
    padding: 100%;
  }

  .pt100-lg, .py100-lg {
    padding-top: 100%;
  }

  .pr100-lg, .px100-lg {
    padding-right: 100%;
  }

  .pb100-lg, .py100-lg {
    padding-bottom: 100%;
  }

  .pl100-lg, .px100-lg {
    padding-left: 100%;
  }

  .m9-5rem-lg {
    margin: 9.5rem;
  }

  .m10rem-lg {
    margin: 10rem;
  }

  .mt9-5rem-lg, .my9-5rem-lg {
    margin-top: 9.5rem;
  }

  .mt10rem-lg, .my10rem-lg {
    margin-top: 10rem;
  }

  .mr9-5rem-lg, .mx9-5rem-lg {
    margin-right: 9.5rem;
  }

  .mr10rem-lg, .mx10rem-lg {
    margin-right: 10rem;
  }

  .mb9-5rem-lg, .my9-5rem-lg {
    margin-bottom: 9.5rem;
  }

  .mb10rem-lg, .my10rem-lg {
    margin-bottom: 10rem;
  }

  .ml9-5rem-lg, .mx9-5rem-lg {
    margin-left: 9.5rem;
  }

  .ml10rem-lg, .mx10rem-lg {
    margin-left: 10rem;
  }

  .p9-5rem-lg {
    padding: 9.5rem;
  }

  .p10rem-lg {
    padding: 10rem;
  }

  .pt9-5rem-lg, .py9-5rem-lg {
    padding-top: 9.5rem;
  }

  .pt10rem-lg, .py10rem-lg {
    padding-top: 10rem;
  }

  .pr9-5rem-lg, .px9-5rem-lg {
    padding-right: 9.5rem;
  }

  .pr10rem-lg, .px10rem-lg {
    padding-right: 10rem;
  }

  .pb9-5rem-lg, .py9-5rem-lg {
    padding-bottom: 9.5rem;
  }

  .pb10rem-lg, .py10rem-lg {
    padding-bottom: 10rem;
  }

  .pl9-5rem-lg, .px9-5rem-lg {
    padding-left: 9.5rem;
  }

  .pl10rem-lg, .px10rem-lg {
    padding-left: 10rem;
  }

  .wa-lg {
    width: auto;
  }

  .ha-lg {
    height: auto;
  }

  .w0-lg {
    width: 0;
  }

  .h0-lg {
    height: 0;
  }

  .maw0-lg {
    max-width: 0;
  }

  .mah0-lg {
    max-height: 0;
  }

  .mih0-lg {
    min-height: 0;
  }

  .w1rem-lg {
    width: 1rem;
  }

  .w10px-lg {
    width: 10px;
  }

  .w10-lg {
    width: 10%;
  }

  .w10vw-lg {
    width: 10vw;
  }

  .h1rem-lg {
    height: 1rem;
  }

  .h10px-lg {
    height: 10px;
  }

  .h10-lg {
    height: 10%;
  }

  .h10vh-lg {
    height: 10vh;
  }

  .miw1rem-lg {
    min-width: 1rem;
  }

  .miw100px-lg {
    min-width: 100px;
  }

  .miw10-lg {
    min-width: 10%;
  }

  .miw10vw-lg {
    min-width: 10vw;
  }

  .mih1rem-lg {
    min-height: 1rem;
  }

  .mih100px-lg {
    min-height: 100px;
  }

  .mih10-lg {
    min-height: 10%;
  }

  .mih10vh-lg {
    min-height: 10vh;
  }

  .maw1rem-lg {
    max-width: 1rem;
  }

  .maw100px-lg {
    max-width: 100px;
  }

  .maw10-lg {
    max-width: 10%;
  }

  .maw10vw-lg {
    max-width: 10vw;
  }

  .mah1rem-lg {
    max-height: 1rem;
  }

  .mah100px-lg {
    max-height: 100px;
  }

  .mah10-lg {
    max-height: 10%;
  }

  .mah10vh-lg {
    max-height: 10vh;
  }

  .w2rem-lg {
    width: 2rem;
  }

  .w20px-lg {
    width: 20px;
  }

  .w200px-lg {
    width: 200px;
  }

  .w20-lg {
    width: 20%;
  }

  .w20vw-lg {
    width: 20vw;
  }

  .h2rem-lg {
    height: 2rem;
  }

  .h20px-lg {
    height: 20px;
  }

  .h200px-lg {
    height: 200px;
  }

  .h20-lg {
    height: 20%;
  }

  .h20vh-lg {
    height: 20vh;
  }

  .miw2rem-lg {
    min-width: 2rem;
  }

  .miw200px-lg {
    min-width: 200px;
  }

  .miw20-lg {
    min-width: 20%;
  }

  .miw20vw-lg {
    min-width: 20vw;
  }

  .mih2rem-lg {
    min-height: 2rem;
  }

  .mih200px-lg {
    min-height: 200px;
  }

  .mih20-lg {
    min-height: 20%;
  }

  .mih20vh-lg {
    min-height: 20vh;
  }

  .maw2rem-lg {
    max-width: 2rem;
  }

  .maw200px-lg {
    max-width: 200px;
  }

  .maw20-lg {
    max-width: 20%;
  }

  .maw20vw-lg {
    max-width: 20vw;
  }

  .mah2rem-lg {
    max-height: 2rem;
  }

  .mah200px-lg {
    max-height: 200px;
  }

  .mah20-lg {
    max-height: 20%;
  }

  .mah20vh-lg {
    max-height: 20vh;
  }

  .w3rem-lg {
    width: 3rem;
  }

  .w30px-lg {
    width: 30px;
  }

  .w300px-lg {
    width: 300px;
  }

  .w30-lg {
    width: 30%;
  }

  .w30vw-lg {
    width: 30vw;
  }

  .h3rem-lg {
    height: 3rem;
  }

  .h30px-lg {
    height: 30px;
  }

  .h300px-lg {
    height: 300px;
  }

  .h30-lg {
    height: 30%;
  }

  .h30vh-lg {
    height: 30vh;
  }

  .miw3rem-lg {
    min-width: 3rem;
  }

  .miw300px-lg {
    min-width: 300px;
  }

  .miw30-lg {
    min-width: 30%;
  }

  .miw30vw-lg {
    min-width: 30vw;
  }

  .mih3rem-lg {
    min-height: 3rem;
  }

  .mih300px-lg {
    min-height: 300px;
  }

  .mih30-lg {
    min-height: 30%;
  }

  .mih30vh-lg {
    min-height: 30vh;
  }

  .maw3rem-lg {
    max-width: 3rem;
  }

  .maw300px-lg {
    max-width: 300px;
  }

  .maw30-lg {
    max-width: 30%;
  }

  .maw30vw-lg {
    max-width: 30vw;
  }

  .mah3rem-lg {
    max-height: 3rem;
  }

  .mah300px-lg {
    max-height: 300px;
  }

  .mah30-lg {
    max-height: 30%;
  }

  .mah30vh-lg {
    max-height: 30vh;
  }

  .w4rem-lg {
    width: 4rem;
  }

  .w40px-lg {
    width: 40px;
  }

  .w400px-lg {
    width: 400px;
  }

  .w40-lg {
    width: 40%;
  }

  .w40vw-lg {
    width: 40vw;
  }

  .h4rem-lg {
    height: 4rem;
  }

  .h40px-lg {
    height: 40px;
  }

  .h400px-lg {
    height: 400px;
  }

  .h40-lg {
    height: 40%;
  }

  .h40vh-lg {
    height: 40vh;
  }

  .miw4rem-lg {
    min-width: 4rem;
  }

  .miw400px-lg {
    min-width: 400px;
  }

  .miw40-lg {
    min-width: 40%;
  }

  .miw40vw-lg {
    min-width: 40vw;
  }

  .mih4rem-lg {
    min-height: 4rem;
  }

  .mih400px-lg {
    min-height: 400px;
  }

  .mih40-lg {
    min-height: 40%;
  }

  .mih40vh-lg {
    min-height: 40vh;
  }

  .maw4rem-lg {
    max-width: 4rem;
  }

  .maw400px-lg {
    max-width: 400px;
  }

  .maw40-lg {
    max-width: 40%;
  }

  .maw40vw-lg {
    max-width: 40vw;
  }

  .mah4rem-lg {
    max-height: 4rem;
  }

  .mah400px-lg {
    max-height: 400px;
  }

  .mah40-lg {
    max-height: 40%;
  }

  .mah40vh-lg {
    max-height: 40vh;
  }

  .w5rem-lg {
    width: 5rem;
  }

  .w50px-lg {
    width: 50px;
  }

  .w500px-lg {
    width: 500px;
  }

  .w50-lg {
    width: 50%;
  }

  .w50vw-lg {
    width: 50vw;
  }

  .h5rem-lg {
    height: 5rem;
  }

  .h50px-lg {
    height: 50px;
  }

  .h500px-lg {
    height: 500px;
  }

  .h50-lg {
    height: 50%;
  }

  .h50vh-lg {
    height: 50vh;
  }

  .miw5rem-lg {
    min-width: 5rem;
  }

  .miw500px-lg {
    min-width: 500px;
  }

  .miw50-lg {
    min-width: 50%;
  }

  .miw50vw-lg {
    min-width: 50vw;
  }

  .mih5rem-lg {
    min-height: 5rem;
  }

  .mih500px-lg {
    min-height: 500px;
  }

  .mih50-lg {
    min-height: 50%;
  }

  .mih50vh-lg {
    min-height: 50vh;
  }

  .maw5rem-lg {
    max-width: 5rem;
  }

  .maw500px-lg {
    max-width: 500px;
  }

  .maw50-lg {
    max-width: 50%;
  }

  .maw50vw-lg {
    max-width: 50vw;
  }

  .mah5rem-lg {
    max-height: 5rem;
  }

  .mah500px-lg {
    max-height: 500px;
  }

  .mah50-lg {
    max-height: 50%;
  }

  .mah50vh-lg {
    max-height: 50vh;
  }

  .w6rem-lg {
    width: 6rem;
  }

  .w60px-lg {
    width: 60px;
  }

  .w600px-lg {
    width: 600px;
  }

  .w60-lg {
    width: 60%;
  }

  .w60vw-lg {
    width: 60vw;
  }

  .h6rem-lg {
    height: 6rem;
  }

  .h60px-lg {
    height: 60px;
  }

  .h600px-lg {
    height: 600px;
  }

  .h60-lg {
    height: 60%;
  }

  .h60vh-lg {
    height: 60vh;
  }

  .miw6rem-lg {
    min-width: 6rem;
  }

  .miw600px-lg {
    min-width: 600px;
  }

  .miw60-lg {
    min-width: 60%;
  }

  .miw60vw-lg {
    min-width: 60vw;
  }

  .mih6rem-lg {
    min-height: 6rem;
  }

  .mih600px-lg {
    min-height: 600px;
  }

  .mih60-lg {
    min-height: 60%;
  }

  .mih60vh-lg {
    min-height: 60vh;
  }

  .maw6rem-lg {
    max-width: 6rem;
  }

  .maw600px-lg {
    max-width: 600px;
  }

  .maw60-lg {
    max-width: 60%;
  }

  .maw60vw-lg {
    max-width: 60vw;
  }

  .mah6rem-lg {
    max-height: 6rem;
  }

  .mah600px-lg {
    max-height: 600px;
  }

  .mah60-lg {
    max-height: 60%;
  }

  .mah60vh-lg {
    max-height: 60vh;
  }

  .w7rem-lg {
    width: 7rem;
  }

  .w70px-lg {
    width: 70px;
  }

  .w700px-lg {
    width: 700px;
  }

  .w70-lg {
    width: 70%;
  }

  .w70vw-lg {
    width: 70vw;
  }

  .h7rem-lg {
    height: 7rem;
  }

  .h70px-lg {
    height: 70px;
  }

  .h700px-lg {
    height: 700px;
  }

  .h70-lg {
    height: 70%;
  }

  .h70vh-lg {
    height: 70vh;
  }

  .miw7rem-lg {
    min-width: 7rem;
  }

  .miw700px-lg {
    min-width: 700px;
  }

  .miw70-lg {
    min-width: 70%;
  }

  .miw70vw-lg {
    min-width: 70vw;
  }

  .mih7rem-lg {
    min-height: 7rem;
  }

  .mih700px-lg {
    min-height: 700px;
  }

  .mih70-lg {
    min-height: 70%;
  }

  .mih70vh-lg {
    min-height: 70vh;
  }

  .maw7rem-lg {
    max-width: 7rem;
  }

  .maw700px-lg {
    max-width: 700px;
  }

  .maw70-lg {
    max-width: 70%;
  }

  .maw70vw-lg {
    max-width: 70vw;
  }

  .mah7rem-lg {
    max-height: 7rem;
  }

  .mah700px-lg {
    max-height: 700px;
  }

  .mah70-lg {
    max-height: 70%;
  }

  .mah70vh-lg {
    max-height: 70vh;
  }

  .w8rem-lg {
    width: 8rem;
  }

  .w80px-lg {
    width: 80px;
  }

  .w800px-lg {
    width: 800px;
  }

  .w80-lg {
    width: 80%;
  }

  .w80vw-lg {
    width: 80vw;
  }

  .h8rem-lg {
    height: 8rem;
  }

  .h80px-lg {
    height: 80px;
  }

  .h800px-lg {
    height: 800px;
  }

  .h80-lg {
    height: 80%;
  }

  .h80vh-lg {
    height: 80vh;
  }

  .miw8rem-lg {
    min-width: 8rem;
  }

  .miw800px-lg {
    min-width: 800px;
  }

  .miw80-lg {
    min-width: 80%;
  }

  .miw80vw-lg {
    min-width: 80vw;
  }

  .mih8rem-lg {
    min-height: 8rem;
  }

  .mih800px-lg {
    min-height: 800px;
  }

  .mih80-lg {
    min-height: 80%;
  }

  .mih80vh-lg {
    min-height: 80vh;
  }

  .maw8rem-lg {
    max-width: 8rem;
  }

  .maw800px-lg {
    max-width: 800px;
  }

  .maw80-lg {
    max-width: 80%;
  }

  .maw80vw-lg {
    max-width: 80vw;
  }

  .mah8rem-lg {
    max-height: 8rem;
  }

  .mah800px-lg {
    max-height: 800px;
  }

  .mah80-lg {
    max-height: 80%;
  }

  .mah80vh-lg {
    max-height: 80vh;
  }

  .w9rem-lg {
    width: 9rem;
  }

  .w90px-lg {
    width: 90px;
  }

  .w900px-lg {
    width: 900px;
  }

  .w90-lg {
    width: 90%;
  }

  .w90vw-lg {
    width: 90vw;
  }

  .h9rem-lg {
    height: 9rem;
  }

  .h90px-lg {
    height: 90px;
  }

  .h900px-lg {
    height: 900px;
  }

  .h90-lg {
    height: 90%;
  }

  .h90vh-lg {
    height: 90vh;
  }

  .miw9rem-lg {
    min-width: 9rem;
  }

  .miw900px-lg {
    min-width: 900px;
  }

  .miw90-lg {
    min-width: 90%;
  }

  .miw90vw-lg {
    min-width: 90vw;
  }

  .mih9rem-lg {
    min-height: 9rem;
  }

  .mih900px-lg {
    min-height: 900px;
  }

  .mih90-lg {
    min-height: 90%;
  }

  .mih90vh-lg {
    min-height: 90vh;
  }

  .maw9rem-lg {
    max-width: 9rem;
  }

  .maw900px-lg {
    max-width: 900px;
  }

  .maw90-lg {
    max-width: 90%;
  }

  .maw90vw-lg {
    max-width: 90vw;
  }

  .mah9rem-lg {
    max-height: 9rem;
  }

  .mah900px-lg {
    max-height: 900px;
  }

  .mah90-lg {
    max-height: 90%;
  }

  .mah90vh-lg {
    max-height: 90vh;
  }

  .w10rem-lg {
    width: 10rem;
  }

  .w100px-lg {
    width: 100px;
  }

  .w1000px-lg {
    width: 1000px;
  }

  .w100-lg {
    width: 100%;
  }

  .w100vw-lg {
    width: 100vw;
  }

  .h10rem-lg {
    height: 10rem;
  }

  .h100px-lg {
    height: 100px;
  }

  .h1000px-lg {
    height: 1000px;
  }

  .h100-lg {
    height: 100%;
  }

  .h100vh-lg {
    height: 100vh;
  }

  .miw10rem-lg {
    min-width: 10rem;
  }

  .miw1000px-lg {
    min-width: 1000px;
  }

  .miw100-lg {
    min-width: 100%;
  }

  .miw100vw-lg {
    min-width: 100vw;
  }

  .mih10rem-lg {
    min-height: 10rem;
  }

  .mih1000px-lg {
    min-height: 1000px;
  }

  .mih100-lg {
    min-height: 100%;
  }

  .mih100vh-lg {
    min-height: 100vh;
  }

  .maw10rem-lg {
    max-width: 10rem;
  }

  .maw1000px-lg {
    max-width: 1000px;
  }

  .maw100-lg {
    max-width: 100%;
  }

  .maw100vw-lg {
    max-width: 100vw;
  }

  .mah10rem-lg {
    max-height: 10rem;
  }

  .mah1000px-lg {
    max-height: 1000px;
  }

  .mah100-lg {
    max-height: 100%;
  }

  .mah100vh-lg {
    max-height: 100vh;
  }

  .or0-lg {
    order: 0;
  }

  .br0px-lg {
    border-radius: 0;
  }

  .btlr0px-lg {
    border-top-left-radius: 0;
  }

  .btrr0px-lg {
    border-top-right-radius: 0;
  }

  .bblr0px-lg {
    border-bottom-left-radius: 0;
  }

  .bbrr0px-lg {
    border-bottom-right-radius: 0;
  }

  .or1-lg {
    order: 1;
  }

  .br5px-lg {
    border-radius: 6px;
  }

  .br8px-lg {
    border-radius: 8px;
  }

  .br19px-lg {
    border-radius: 19px;
  }

  .btlr5px-lg {
    border-top-left-radius: 5px;
  }

  .btrr5px-lg {
    border-top-right-radius: 5px;
  }

  .bblr5px-lg {
    border-bottom-left-radius: 5px;
  }

  .bbrr5px-lg {
    border-bottom-right-radius: 5px;
  }

  .or2-lg {
    order: 2;
  }

  .br10px-lg {
    border-radius: 12px;
  }

  .br16px-lg {
    border-radius: 16px;
  }

  .br38px-lg {
    border-radius: 38px;
  }

  .btlr10px-lg {
    border-top-left-radius: 10px;
  }

  .btrr10px-lg {
    border-top-right-radius: 10px;
  }

  .bblr10px-lg {
    border-bottom-left-radius: 10px;
  }

  .bbrr10px-lg {
    border-bottom-right-radius: 10px;
  }

  .or3-lg {
    order: 3;
  }

  .br15px-lg {
    border-radius: 18px;
  }

  .br24px-lg {
    border-radius: 24px;
  }

  .br57px-lg {
    border-radius: 57px;
  }

  .btlr15px-lg {
    border-top-left-radius: 15px;
  }

  .btrr15px-lg {
    border-top-right-radius: 15px;
  }

  .bblr15px-lg {
    border-bottom-left-radius: 15px;
  }

  .bbrr15px-lg {
    border-bottom-right-radius: 15px;
  }

  .or4-lg {
    order: 4;
  }

  .br20px-lg {
    border-radius: 24px;
  }

  .br32px-lg {
    border-radius: 32px;
  }

  .br76px-lg {
    border-radius: 76px;
  }

  .btlr20px-lg {
    border-top-left-radius: 20px;
  }

  .btrr20px-lg {
    border-top-right-radius: 20px;
  }

  .bblr20px-lg {
    border-bottom-left-radius: 20px;
  }

  .bbrr20px-lg {
    border-bottom-right-radius: 20px;
  }

  .or5-lg {
    order: 5;
  }

  .br25px-lg {
    border-radius: 30px;
  }

  .br95px-lg {
    border-radius: 95px;
  }

  .btlr25px-lg {
    border-top-left-radius: 25px;
  }

  .btrr25px-lg {
    border-top-right-radius: 25px;
  }

  .bblr25px-lg {
    border-bottom-left-radius: 25px;
  }

  .bbrr25px-lg {
    border-bottom-right-radius: 25px;
  }

  .or6-lg {
    order: 6;
  }

  .br30px-lg {
    border-radius: 36px;
  }

  .br48px-lg {
    border-radius: 48px;
  }

  .br114px-lg {
    border-radius: 114px;
  }

  .btlr30px-lg {
    border-top-left-radius: 30px;
  }

  .btrr30px-lg {
    border-top-right-radius: 30px;
  }

  .bblr30px-lg {
    border-bottom-left-radius: 30px;
  }

  .bbrr30px-lg {
    border-bottom-right-radius: 30px;
  }

  .or7-lg {
    order: 7;
  }

  .br35px-lg {
    border-radius: 42px;
  }

  .br56px-lg {
    border-radius: 56px;
  }

  .br133px-lg {
    border-radius: 133px;
  }

  .btlr35px-lg {
    border-top-left-radius: 35px;
  }

  .btrr35px-lg {
    border-top-right-radius: 35px;
  }

  .bblr35px-lg {
    border-bottom-left-radius: 35px;
  }

  .bbrr35px-lg {
    border-bottom-right-radius: 35px;
  }

  .or8-lg {
    order: 8;
  }

  .br40px-lg {
    border-radius: 48px;
  }

  .br64px-lg {
    border-radius: 64px;
  }

  .br152px-lg {
    border-radius: 152px;
  }

  .btlr40px-lg {
    border-top-left-radius: 40px;
  }

  .btrr40px-lg {
    border-top-right-radius: 40px;
  }

  .bblr40px-lg {
    border-bottom-left-radius: 40px;
  }

  .bbrr40px-lg {
    border-bottom-right-radius: 40px;
  }

  .or9-lg {
    order: 9;
  }

  .br45px-lg {
    border-radius: 54px;
  }

  .br72px-lg {
    border-radius: 72px;
  }

  .br171px-lg {
    border-radius: 171px;
  }

  .btlr45px-lg {
    border-top-left-radius: 45px;
  }

  .btrr45px-lg {
    border-top-right-radius: 45px;
  }

  .bblr45px-lg {
    border-bottom-left-radius: 45px;
  }

  .bbrr45px-lg {
    border-bottom-right-radius: 45px;
  }

  .or10-lg {
    order: 10;
  }

  .br50px-lg {
    border-radius: 60px;
  }

  .br80px-lg {
    border-radius: 80px;
  }

  .br190px-lg {
    border-radius: 190px;
  }

  .btlr50px-lg {
    border-top-left-radius: 50px;
  }

  .btrr50px-lg {
    border-top-right-radius: 50px;
  }

  .bblr50px-lg {
    border-bottom-left-radius: 50px;
  }

  .bbrr50px-lg {
    border-bottom-right-radius: 50px;
  }

  .br100 {
    border-radius: 100%;
  }

  .btlr100 {
    border-top-left-radius: 100%;
  }

  .btrr100 {
    border-top-right-radius: 100%;
  }

  .bblr100 {
    border-bottom-left-radius: 100%;
  }

  .bbrr100 {
    border-bottom-right-radius: 100%;
  }
}

@media (width >= 75rem) {
  .c1-xl, .c1-hover-xl:hover {
    color: #fff;
  }

  .bg1-xl, .bg1-hover-xl:hover {
    background-color: #fff;
  }

  .bo1-xl, .bo1-hover-xl:hover {
    border-color: #fff;
  }

  .f1-xl, .f1-hover-xl:hover {
    fill: #fff;
  }

  .c2-xl, .c2-hover-xl:hover {
    color: #000;
  }

  .bg2-xl, .bg2-hover-xl:hover {
    background-color: #000;
  }

  .bo2-xl, .bo2-hover-xl:hover {
    border-color: #000;
  }

  .f2-xl, .f2-hover-xl:hover {
    fill: #000;
  }

  .c3-xl, .c3-hover-xl:hover {
    color: #4a67ff;
  }

  .bg3-xl, .bg3-hover-xl:hover {
    background-color: #4a67ff;
  }

  .bo3-xl, .bo3-hover-xl:hover {
    border-color: #4a67ff;
  }

  .f3-xl, .f3-hover-xl:hover {
    fill: #4a67ff;
  }

  .c4-xl, .c4-hover-xl:hover {
    color: #fc7785;
  }

  .bg4-xl, .bg4-hover-xl:hover {
    background-color: #fc7785;
  }

  .bo4-xl, .bo4-hover-xl:hover {
    border-color: #fc7785;
  }

  .f4-xl, .f4-hover-xl:hover {
    fill: #fc7785;
  }

  .c5-xl, .c5-hover-xl:hover {
    color: #f0f0f8;
  }

  .bg5-xl, .bg5-hover-xl:hover {
    background-color: #f0f0f8;
  }

  .bo5-xl, .bo5-hover-xl:hover {
    border-color: #f0f0f8;
  }

  .f5-xl, .f5-hover-xl:hover {
    fill: #f0f0f8;
  }

  .c6-xl, .c6-hover-xl:hover {
    color: #002d63;
  }

  .bg6-xl, .bg6-hover-xl:hover {
    background-color: #002d63;
  }

  .bo6-xl, .bo6-hover-xl:hover {
    border-color: #002d63;
  }

  .f6-xl, .f6-hover-xl:hover {
    fill: #002d63;
  }

  .c7-xl, .c7-hover-xl:hover {
    color: #40dda1;
  }

  .bg7-xl, .bg7-hover-xl:hover {
    background-color: #40dda1;
  }

  .bo7-xl, .bo7-hover-xl:hover {
    border-color: #40dda1;
  }

  .f7-xl, .f7-hover-xl:hover {
    fill: #40dda1;
  }

  .c8-xl, .c8-hover-xl:hover {
    color: #ddb640;
  }

  .bg8-xl, .bg8-hover-xl:hover {
    background-color: #ddb640;
  }

  .bo8-xl, .bo8-hover-xl:hover {
    border-color: #ddb640;
  }

  .f8-xl, .f8-hover-xl:hover {
    fill: #ddb640;
  }

  .c9-xl, .c9-hover-xl:hover {
    color: #dd4040;
  }

  .bg9-xl, .bg9-hover-xl:hover {
    background-color: #dd4040;
  }

  .bo9-xl, .bo9-hover-xl:hover {
    border-color: #dd4040;
  }

  .f9-xl, .f9-hover-xl:hover {
    fill: #dd4040;
  }

  .c10-xl, .c10-hover-xl:hover {
    color: #038589;
  }

  .bg10-xl, .bg10-hover-xl:hover {
    background-color: #038589;
  }

  .bo10-xl, .bo10-hover-xl:hover {
    border-color: #038589;
  }

  .f10-xl, .f10-hover-xl:hover {
    fill: #038589;
  }

  .c11-xl, .c11-hover-xl:hover {
    color: #007ee5;
  }

  .bg11-xl, .bg11-hover-xl:hover {
    background-color: #007ee5;
  }

  .bo11-xl, .bo11-hover-xl:hover {
    border-color: #007ee5;
  }

  .f11-xl, .f11-hover-xl:hover {
    fill: #007ee5;
  }

  .c12-xl, .c12-hover-xl:hover {
    color: #969696;
  }

  .bg12-xl, .bg12-hover-xl:hover {
    background-color: #969696;
  }

  .bo12-xl, .bo12-hover-xl:hover {
    border-color: #969696;
  }

  .f12-xl, .f12-hover-xl:hover {
    fill: #969696;
  }

  .c13-xl, .c13-hover-xl:hover {
    color: #fb7a27;
  }

  .bg13-xl, .bg13-hover-xl:hover {
    background-color: #fb7a27;
  }

  .bo13-xl, .bo13-hover-xl:hover {
    border-color: #fb7a27;
  }

  .f13-xl, .f13-hover-xl:hover {
    fill: #fb7a27;
  }

  .c14-xl, .c14-hover-xl:hover {
    color: #505050;
  }

  .bg14-xl, .bg14-hover-xl:hover {
    background-color: #505050;
  }

  .bo14-xl, .bo14-hover-xl:hover {
    border-color: #505050;
  }

  .f14-xl, .f14-hover-xl:hover {
    fill: #505050;
  }

  .c15-xl, .c15-hover-xl:hover {
    color: #151313;
  }

  .bg15-xl, .bg15-hover-xl:hover {
    background-color: #151313;
  }

  .bo15-xl, .bo15-hover-xl:hover {
    border-color: #151313;
  }

  .f15-xl, .f15-hover-xl:hover {
    fill: #151313;
  }

  .c16-xl, .c16-hover-xl:hover {
    color: #d9d9d9;
  }

  .bg16-xl, .bg16-hover-xl:hover {
    background-color: #d9d9d9;
  }

  .bo16-xl, .bo16-hover-xl:hover {
    border-color: #d9d9d9;
  }

  .f16-xl, .f16-hover-xl:hover {
    fill: #d9d9d9;
  }

  .c17-xl, .c17-hover-xl:hover {
    color: #d3d9fc;
  }

  .bg17-xl, .bg17-hover-xl:hover {
    background-color: #d3d9fc;
  }

  .bo17-xl, .bo17-hover-xl:hover {
    border-color: #d3d9fc;
  }

  .f17-xl, .f17-hover-xl:hover {
    fill: #d3d9fc;
  }

  .c18-xl, .c18-hover-xl:hover {
    color: #6775b9;
  }

  .bg18-xl, .bg18-hover-xl:hover {
    background-color: #6775b9;
  }

  .bo18-xl, .bo18-hover-xl:hover {
    border-color: #6775b9;
  }

  .f18-xl, .f18-hover-xl:hover {
    fill: #6775b9;
  }

  .c19-xl, .c19-hover-xl:hover {
    color: #f5f6f9;
  }

  .bg19-xl, .bg19-hover-xl:hover {
    background-color: #f5f6f9;
  }

  .bo19-xl, .bo19-hover-xl:hover {
    border-color: #f5f6f9;
  }

  .f19-xl, .f19-hover-xl:hover {
    fill: #f5f6f9;
  }

  .c20-xl, .c20-hover-xl:hover {
    color: #f0f2fd;
  }

  .bg20-xl, .bg20-hover-xl:hover {
    background-color: #f0f2fd;
  }

  .bo20-xl, .bo20-hover-xl:hover {
    border-color: #f0f2fd;
  }

  .f20-xl, .f20-hover-xl:hover {
    fill: #f0f2fd;
  }

  .c21-xl, .c21-hover-xl:hover {
    color: #f9f9f9;
  }

  .bg21-xl, .bg21-hover-xl:hover {
    background-color: #f9f9f9;
  }

  .bo21-xl, .bo21-hover-xl:hover {
    border-color: #f9f9f9;
  }

  .f21-xl, .f21-hover-xl:hover {
    fill: #f9f9f9;
  }

  .c22-xl, .c22-hover-xl:hover {
    color: #f6f6f6;
  }

  .bg22-xl, .bg22-hover-xl:hover {
    background-color: #f6f6f6;
  }

  .bo22-xl, .bo22-hover-xl:hover {
    border-color: #f6f6f6;
  }

  .f22-xl, .f22-hover-xl:hover {
    fill: #f6f6f6;
  }

  .c23-xl, .c23-hover-xl:hover {
    color: #fd6474;
  }

  .bg23-xl, .bg23-hover-xl:hover {
    background-color: #fd6474;
  }

  .bo23-xl, .bo23-hover-xl:hover {
    border-color: #fd6474;
  }

  .f23-xl, .f23-hover-xl:hover {
    fill: #fd6474;
  }

  .c24-xl, .c24-hover-xl:hover {
    color: #efefef;
  }

  .bg24-xl, .bg24-hover-xl:hover {
    background-color: #efefef;
  }

  .bo24-xl, .bo24-hover-xl:hover {
    border-color: #efefef;
  }

  .f24-xl, .f24-hover-xl:hover {
    fill: #efefef;
  }

  .c25-xl, .c25-hover-xl:hover {
    color: #f8f8f8;
  }

  .bg25-xl, .bg25-hover-xl:hover {
    background-color: #f8f8f8;
  }

  .bo25-xl, .bo25-hover-xl:hover {
    border-color: #f8f8f8;
  }

  .f25-xl, .f25-hover-xl:hover {
    fill: #f8f8f8;
  }

  .c26-xl, .c26-hover-xl:hover {
    color: #0126ed;
  }

  .bg26-xl, .bg26-hover-xl:hover {
    background-color: #0126ed;
  }

  .bo26-xl, .bo26-hover-xl:hover {
    border-color: #0126ed;
  }

  .f26-xl, .f26-hover-xl:hover {
    fill: #0126ed;
  }

  .c27-xl, .c27-hover-xl:hover {
    color: #5e5f66;
  }

  .bg27-xl, .bg27-hover-xl:hover {
    background-color: #5e5f66;
  }

  .bo27-xl, .bo27-hover-xl:hover {
    border-color: #5e5f66;
  }

  .f27-xl, .f27-hover-xl:hover {
    fill: #5e5f66;
  }

  .c28-xl, .c28-hover-xl:hover {
    color: #aaacb5;
  }

  .bg28-xl, .bg28-hover-xl:hover {
    background-color: #aaacb5;
  }

  .bo28-xl, .bo28-hover-xl:hover {
    border-color: #aaacb5;
  }

  .f28-xl, .f28-hover-xl:hover {
    fill: #aaacb5;
  }

  .c29-xl, .c29-hover-xl:hover {
    color: #dde2f2;
  }

  .bg29-xl, .bg29-hover-xl:hover {
    background-color: #dde2f2;
  }

  .bo29-xl, .bo29-hover-xl:hover {
    border-color: #dde2f2;
  }

  .f29-xl, .f29-hover-xl:hover {
    fill: #dde2f2;
  }

  .c30-xl, .c30-hover-xl:hover {
    color: #09b255;
  }

  .bg30-xl, .bg30-hover-xl:hover {
    background-color: #09b255;
  }

  .bo30-xl, .bo30-hover-xl:hover {
    border-color: #09b255;
  }

  .f30-xl, .f30-hover-xl:hover {
    fill: #09b255;
  }

  .c31-xl, .c31-hover-xl:hover {
    color: #dbdfee;
  }

  .bg31-xl, .bg31-hover-xl:hover {
    background-color: #dbdfee;
  }

  .bo31-xl, .bo31-hover-xl:hover {
    border-color: #dbdfee;
  }

  .f31-xl, .f31-hover-xl:hover {
    fill: #dbdfee;
  }

  .c32-xl, .c32-hover-xl:hover {
    color: #737171;
  }

  .bg32-xl, .bg32-hover-xl:hover {
    background-color: #737171;
  }

  .bo32-xl, .bo32-hover-xl:hover {
    border-color: #737171;
  }

  .f32-xl, .f32-hover-xl:hover {
    fill: #737171;
  }

  .c33-xl, .c33-hover-xl:hover {
    color: #767b90;
  }

  .bg33-xl, .bg33-hover-xl:hover {
    background-color: #767b90;
  }

  .bo33-xl, .bo33-hover-xl:hover {
    border-color: #767b90;
  }

  .f33-xl, .f33-hover-xl:hover {
    fill: #767b90;
  }

  .c34-xl, .c34-hover-xl:hover {
    color: #15d757;
  }

  .bg34-xl, .bg34-hover-xl:hover {
    background-color: #15d757;
  }

  .bo34-xl, .bo34-hover-xl:hover {
    border-color: #15d757;
  }

  .f34-xl, .f34-hover-xl:hover {
    fill: #15d757;
  }

  .c35-xl, .c35-hover-xl:hover {
    color: #e8ebf5;
  }

  .bg35-xl, .bg35-hover-xl:hover {
    background-color: #e8ebf5;
  }

  .bo35-xl, .bo35-hover-xl:hover {
    border-color: #e8ebf5;
  }

  .f35-xl, .f35-hover-xl:hover {
    fill: #e8ebf5;
  }

  .c36-xl, .c36-hover-xl:hover {
    color: #4c6b92;
  }

  .bg36-xl, .bg36-hover-xl:hover {
    background-color: #4c6b92;
  }

  .bo36-xl, .bo36-hover-xl:hover {
    border-color: #4c6b92;
  }

  .f36-xl, .f36-hover-xl:hover {
    fill: #4c6b92;
  }

  .c37-xl, .c37-hover-xl:hover {
    color: #818181;
  }

  .bg37-xl, .bg37-hover-xl:hover {
    background-color: #818181;
  }

  .bo37-xl, .bo37-hover-xl:hover {
    border-color: #818181;
  }

  .f37-xl, .f37-hover-xl:hover {
    fill: #818181;
  }

  .c38-xl, .c38-hover-xl:hover {
    color: #f0f2fd;
  }

  .bg38-xl, .bg38-hover-xl:hover {
    background-color: #f0f2fd;
  }

  .bo38-xl, .bo38-hover-xl:hover {
    border-color: #f0f2fd;
  }

  .f38-xl, .f38-hover-xl:hover {
    fill: #f0f2fd;
  }

  .c39-xl, .c39-hover-xl:hover {
    color: #f99229;
  }

  .bg39-xl, .bg39-hover-xl:hover {
    background-color: #f99229;
  }

  .bo39-xl, .bo39-hover-xl:hover {
    border-color: #f99229;
  }

  .f39-xl, .f39-hover-xl:hover {
    fill: #f99229;
  }

  .c40-xl, .c40-hover-xl:hover {
    color: #2947ef;
  }

  .bg40-xl, .bg40-hover-xl:hover {
    background-color: #2947ef;
  }

  .bo40-xl, .bo40-hover-xl:hover {
    border-color: #2947ef;
  }

  .f40-xl, .f40-hover-xl:hover {
    fill: #2947ef;
  }

  .c41-xl, .c41-hover-xl:hover {
    color: #f5f6ff;
  }

  .bg41-xl, .bg41-hover-xl:hover {
    background-color: #f5f6ff;
  }

  .bo41-xl, .bo41-hover-xl:hover {
    border-color: #f5f6ff;
  }

  .f41-xl, .f41-hover-xl:hover {
    fill: #f5f6ff;
  }

  .container {
    max-width: 1500px;
  }

  .tal-xl {
    text-align: left;
  }

  .tac-xl {
    text-align: center;
  }

  .tar-xl {
    text-align: right;
  }

  .taj-xl {
    text-align: justify;
  }

  .tas-xl {
    text-align: start;
  }

  .tae-xl {
    text-align: end;
  }

  .fs0-xl {
    font-size: 0;
  }

  .fs0-25rem-xl {
    font-size: .25rem;
  }

  .fs0-125rem-xl {
    font-size: .125rem;
  }

  .fs0-5rem-xl {
    font-size: .5rem;
  }

  .fs0-75rem-xl {
    font-size: .75rem;
  }

  .fs0-875rem-xl {
    font-size: .875rem;
  }

  .fs1rem-xl {
    font-size: 1rem;
  }

  .fs1-25rem-xl {
    font-size: 1.25rem;
  }

  .fs1-125rem-xl {
    font-size: 1.125rem;
  }

  .fs1-5rem-xl {
    font-size: 1.5rem;
  }

  .fs1-75rem-xl {
    font-size: 1.75rem;
  }

  .fs1-875rem-xl {
    font-size: 1.875rem;
  }

  .fs2rem-xl {
    font-size: 2rem;
  }

  .fs2-25rem-xl {
    font-size: 2.25rem;
  }

  .fs2-125rem-xl {
    font-size: 2.125rem;
  }

  .fs2-5rem-xl {
    font-size: 2.5rem;
  }

  .fs2-75rem-xl {
    font-size: 2.75rem;
  }

  .fs2-875rem-xl {
    font-size: 2.875rem;
  }

  .fs3rem-xl {
    font-size: 3rem;
  }

  .fs3-25rem-xl {
    font-size: 3.25rem;
  }

  .fs3-125rem-xl {
    font-size: 3.125rem;
  }

  .fs3-5rem-xl {
    font-size: 3.5rem;
  }

  .fs3-75rem-xl {
    font-size: 3.75rem;
  }

  .fs3-875rem-xl {
    font-size: 3.875rem;
  }

  .fs4rem-xl {
    font-size: 4rem;
  }

  .fs4-25rem-xl {
    font-size: 4.25rem;
  }

  .fs4-125rem-xl {
    font-size: 4.125rem;
  }

  .fs4-5rem-xl {
    font-size: 4.5rem;
  }

  .fs4-75rem-xl {
    font-size: 4.75rem;
  }

  .fs4-875rem-xl {
    font-size: 4.875rem;
  }

  .fs5rem-xl {
    font-size: 5rem;
  }

  .fs5-25rem-xl {
    font-size: 5.25rem;
  }

  .fs5-125rem-xl {
    font-size: 5.125rem;
  }

  .fs5-5rem-xl {
    font-size: 5.5rem;
  }

  .fs5-75rem-xl {
    font-size: 5.75rem;
  }

  .fs5-875rem-xl {
    font-size: 5.875rem;
  }

  .fs6rem-xl {
    font-size: 6rem;
  }

  .fs6-25rem-xl {
    font-size: 6.25rem;
  }

  .fs6-125rem-xl {
    font-size: 6.125rem;
  }

  .fs6-5rem-xl {
    font-size: 6.5rem;
  }

  .fs6-75rem-xl {
    font-size: 6.75rem;
  }

  .fs6-875rem-xl {
    font-size: 6.875rem;
  }

  .fs7rem-xl {
    font-size: 7rem;
  }

  .db-xl {
    display: block;
  }

  .dib-xl {
    display: inline-block;
  }

  .di-xl {
    display: inline;
  }

  .df-xl {
    display: flex;
  }

  .fdc-xl {
    flex-direction: column;
  }

  .fdr-xl {
    flex-direction: row;
  }

  .fdcr-xl {
    flex-direction: column-reverse;
  }

  .fdrr-xl {
    flex-direction: row-reverse;
  }

  .fww-xl {
    flex-wrap: wrap;
  }

  .jcfs-xl {
    justify-content: flex-start;
  }

  .jcc-xl {
    justify-content: center;
  }

  .jcfe-xl {
    justify-content: flex-end;
  }

  .jcsb-xl {
    justify-content: space-between;
  }

  .jcsa-xl {
    justify-content: space-around;
  }

  .jcse-xl {
    justify-content: space-evenly;
  }

  .jce-xl {
    justify-content: end;
  }

  .aifs-xl {
    align-items: flex-start;
  }

  .aic-xl {
    align-items: center;
  }

  .aife-xl {
    align-items: flex-end;
  }

  .jss-xl {
    justify-self: start;
  }

  .jsfs-xl {
    justify-self: flex-start;
  }

  .jsc-xl {
    justify-self: center;
  }

  .jsfe-xl {
    justify-self: flex-end;
  }

  .jse-xl {
    justify-self: end;
  }

  .ass-xl {
    align-self: start;
  }

  .asfs-xl {
    align-self: flex-start;
  }

  .asc-xl {
    align-self: center;
  }

  .asfe-xl {
    align-self: flex-end;
  }

  .ase-xl {
    align-self: end;
  }

  .dif-xl {
    display: inline-flex;
  }

  .dg-xl {
    display: grid;
  }

  .brss-xl {
    border-right-style: solid;
  }

  .brw1px-xl {
    border-right-width: 1px;
  }

  .brw2px-xl {
    border-right-width: 2px;
  }

  .gtcr1-xl {
    grid-template-columns: repeat(1, 1fr);
  }

  .ftcr1-xl {
    grid-column: 1 / 1fr;
  }

  .gtrr1-xl {
    grid-template-rows: repeat(1, 1fr);
  }

  .gtcr1a-xl {
    grid-template-columns: repeat(1, auto);
  }

  .gtrr1a-xl {
    grid-template-rows: repeat(1, auto);
  }

  .gcs1-xl {
    grid-column-start: 1;
  }

  .gce2-xl {
    grid-column-end: 2;
  }

  .grs1-xl {
    grid-row-start: 1;
  }

  .gre2-xl {
    grid-row-end: 2;
  }

  .gtcr2-xl {
    grid-template-columns: repeat(2, 1fr);
  }

  .ftcr2-xl {
    grid-column: 2 / 1fr;
  }

  .gtrr2-xl {
    grid-template-rows: repeat(2, 1fr);
  }

  .gtcr2a-xl {
    grid-template-columns: repeat(2, auto);
  }

  .gtrr2a-xl {
    grid-template-rows: repeat(2, auto);
  }

  .gcs2-xl {
    grid-column-start: 2;
  }

  .gce3-xl {
    grid-column-end: 3;
  }

  .grs2-xl {
    grid-row-start: 2;
  }

  .gre3-xl {
    grid-row-end: 3;
  }

  .gtcr3-xl {
    grid-template-columns: repeat(3, 1fr);
  }

  .ftcr3-xl {
    grid-column: 3 / 1fr;
  }

  .gtrr3-xl {
    grid-template-rows: repeat(3, 1fr);
  }

  .gtcr3a-xl {
    grid-template-columns: repeat(3, auto);
  }

  .gtrr3a-xl {
    grid-template-rows: repeat(3, auto);
  }

  .gcs3-xl {
    grid-column-start: 3;
  }

  .gce4-xl {
    grid-column-end: 4;
  }

  .grs3-xl {
    grid-row-start: 3;
  }

  .gre4-xl {
    grid-row-end: 4;
  }

  .gtcr4-xl {
    grid-template-columns: repeat(4, 1fr);
  }

  .ftcr4-xl {
    grid-column: 4 / 1fr;
  }

  .gtrr4-xl {
    grid-template-rows: repeat(4, 1fr);
  }

  .gtcr4a-xl {
    grid-template-columns: repeat(4, auto);
  }

  .gtrr4a-xl {
    grid-template-rows: repeat(4, auto);
  }

  .gcs4-xl {
    grid-column-start: 4;
  }

  .gce5-xl {
    grid-column-end: 5;
  }

  .grs4-xl {
    grid-row-start: 4;
  }

  .gre5-xl {
    grid-row-end: 5;
  }

  .gtcr5-xl {
    grid-template-columns: repeat(5, 1fr);
  }

  .ftcr5-xl {
    grid-column: 5 / 1fr;
  }

  .gtrr5-xl {
    grid-template-rows: repeat(5, 1fr);
  }

  .gtcr5a-xl {
    grid-template-columns: repeat(5, auto);
  }

  .gtrr5a-xl {
    grid-template-rows: repeat(5, auto);
  }

  .gcs5-xl {
    grid-column-start: 5;
  }

  .gce6-xl {
    grid-column-end: 6;
  }

  .grs5-xl {
    grid-row-start: 5;
  }

  .gre6-xl {
    grid-row-end: 6;
  }

  .gtcr6-xl {
    grid-template-columns: repeat(6, 1fr);
  }

  .ftcr6-xl {
    grid-column: 6 / 1fr;
  }

  .gtrr6-xl {
    grid-template-rows: repeat(6, 1fr);
  }

  .gtcr6a-xl {
    grid-template-columns: repeat(6, auto);
  }

  .gtrr6a-xl {
    grid-template-rows: repeat(6, auto);
  }

  .gcs6-xl {
    grid-column-start: 6;
  }

  .gce7-xl {
    grid-column-end: 7;
  }

  .grs6-xl {
    grid-row-start: 6;
  }

  .gre7-xl {
    grid-row-end: 7;
  }

  .gtcr7-xl {
    grid-template-columns: repeat(7, 1fr);
  }

  .ftcr7-xl {
    grid-column: 7 / 1fr;
  }

  .gtrr7-xl {
    grid-template-rows: repeat(7, 1fr);
  }

  .gtcr7a-xl {
    grid-template-columns: repeat(7, auto);
  }

  .gtrr7a-xl {
    grid-template-rows: repeat(7, auto);
  }

  .gcs7-xl {
    grid-column-start: 7;
  }

  .gce8-xl {
    grid-column-end: 8;
  }

  .grs7-xl {
    grid-row-start: 7;
  }

  .gre8-xl {
    grid-row-end: 8;
  }

  .gtcr8-xl {
    grid-template-columns: repeat(8, 1fr);
  }

  .ftcr8-xl {
    grid-column: 8 / 1fr;
  }

  .gtrr8-xl {
    grid-template-rows: repeat(8, 1fr);
  }

  .gtcr8a-xl {
    grid-template-columns: repeat(8, auto);
  }

  .gtrr8a-xl {
    grid-template-rows: repeat(8, auto);
  }

  .gcs8-xl {
    grid-column-start: 8;
  }

  .gce9-xl {
    grid-column-end: 9;
  }

  .grs8-xl {
    grid-row-start: 8;
  }

  .gre9-xl {
    grid-row-end: 9;
  }

  .gtcr9-xl {
    grid-template-columns: repeat(9, 1fr);
  }

  .ftcr9-xl {
    grid-column: 9 / 1fr;
  }

  .gtrr9-xl {
    grid-template-rows: repeat(9, 1fr);
  }

  .gtcr9a-xl {
    grid-template-columns: repeat(9, auto);
  }

  .gtrr9a-xl {
    grid-template-rows: repeat(9, auto);
  }

  .gcs9-xl {
    grid-column-start: 9;
  }

  .gce10-xl {
    grid-column-end: 10;
  }

  .grs9-xl {
    grid-row-start: 9;
  }

  .gre10-xl {
    grid-row-end: 10;
  }

  .gtccr-xl {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }

  .gtcr10-xl {
    grid-template-columns: repeat(10, 1fr);
  }

  .ftcr10-xl {
    grid-column: 10 / 1fr;
  }

  .gtrr10-xl {
    grid-template-rows: repeat(10, 1fr);
  }

  .gtcr10a-xl {
    grid-template-columns: repeat(10, auto);
  }

  .gtrr10a-xl {
    grid-template-rows: repeat(10, auto);
  }

  .gcs10-xl {
    grid-column-start: 10;
  }

  .gce11-xl {
    grid-column-end: 11;
  }

  .grs10-xl {
    grid-row-start: 10;
  }

  .gre11-xl {
    grid-row-end: 11;
  }

  .gg0-5rem-xl {
    grid-gap: .5rem;
  }

  .gg1rem-xl {
    grid-gap: 1rem;
  }

  .rg0-5rem-xl {
    row-gap: .5rem;
  }

  .rg1rem-xl {
    row-gap: 1rem;
  }

  .cg0-5rem-xl {
    column-gap: .5rem;
  }

  .cg1rem-xl {
    column-gap: 1rem;
  }

  .gg1-5rem-xl {
    grid-gap: 1.5rem;
  }

  .gg2rem-xl {
    grid-gap: 2rem;
  }

  .rg1-5rem-xl {
    row-gap: 1.5rem;
  }

  .rg2rem-xl {
    row-gap: 2rem;
  }

  .cg1-5rem-xl {
    column-gap: 1.5rem;
  }

  .cg2rem-xl {
    column-gap: 2rem;
  }

  .gg2-5rem-xl {
    grid-gap: 2.5rem;
  }

  .gg3rem-xl {
    grid-gap: 3rem;
  }

  .rg2-5rem-xl {
    row-gap: 2.5rem;
  }

  .rg3rem-xl {
    row-gap: 3rem;
  }

  .cg2-5rem-xl {
    column-gap: 2.5rem;
  }

  .cg3rem-xl {
    column-gap: 3rem;
  }

  .gg3-5rem-xl {
    grid-gap: 3.5rem;
  }

  .gg4rem-xl {
    grid-gap: 4rem;
  }

  .rg3-5rem-xl {
    row-gap: 3.5rem;
  }

  .rg4rem-xl {
    row-gap: 4rem;
  }

  .cg3-5rem-xl {
    column-gap: 3.5rem;
  }

  .cg4rem-xl {
    column-gap: 4rem;
  }

  .gg4-5rem-xl {
    grid-gap: 4.5rem;
  }

  .gg5rem-xl {
    grid-gap: 5rem;
  }

  .rg4-5rem-xl {
    row-gap: 4.5rem;
  }

  .rg5rem-xl {
    row-gap: 5rem;
  }

  .cg4-5rem-xl {
    column-gap: 4.5rem;
  }

  .cg5rem-xl {
    column-gap: 5rem;
  }

  .dn-xl {
    display: none;
  }

  .pa-xl {
    position: absolute;
  }

  .pr-xl {
    position: relative;
  }

  .pf-xl {
    position: fixed;
  }

  .ps-xl {
    -webkit-position: sticky;
    position: sticky;
  }

  .pst-xl {
    position: static;
  }

  .t0-xl {
    top: 0;
  }

  .r0-xl {
    right: 0;
  }

  .b0-xl {
    bottom: 0;
  }

  .l0-xl {
    left: 0;
  }

  .t100-xl {
    top: 100%;
  }

  .r100-xl {
    right: 100%;
  }

  .b100-xl {
    bottom: 100%;
  }

  .l100-xl {
    left: 100%;
  }

  .fl-xl {
    float: left;
  }

  .fr-xl {
    float: right;
  }

  .fn-xl {
    float: none;
  }

  .ma-xl {
    margin: auto;
  }

  .mta-xl, .mya-xl {
    margin-top: auto;
  }

  .mra-xl, .mxa-xl {
    margin-right: auto;
  }

  .mba-xl, .mya-xl {
    margin-bottom: auto;
  }

  .mla-xl, .mxa-xl {
    margin-left: auto;
  }

  .m0-xl {
    margin: 0;
  }

  .mt0-xl, .my0-xl {
    margin-top: 0;
  }

  .mr0-xl, .mx0-xl {
    margin-right: 0;
  }

  .mb0-xl, .my0-xl {
    margin-bottom: 0;
  }

  .ml0-xl, .mx0-xl {
    margin-left: 0;
  }

  .p0-xl {
    padding: 0;
  }

  .pt0-xl, .py0-xl {
    padding-top: 0;
  }

  .pr0-xl, .px0-xl {
    padding-right: 0;
  }

  .pb0-xl, .py0-xl {
    padding-bottom: 0;
  }

  .pl0-xl, .px0-xl {
    padding-left: 0;
  }

  .m10-xl {
    margin: 10%;
  }

  .mt10-xl, .my10-xl {
    margin-top: 10%;
  }

  .mr10-xl, .mx10-xl {
    margin-right: 10%;
  }

  .mb10-xl, .my10-xl {
    margin-bottom: 10%;
  }

  .ml10-xl, .mx10-xl {
    margin-left: 10%;
  }

  .p10-xl {
    padding: 10%;
  }

  .pt10-xl, .py10-xl {
    padding-top: 10%;
  }

  .pr10-xl, .px10-xl {
    padding-right: 10%;
  }

  .pb10-xl, .py10-xl {
    padding-bottom: 10%;
  }

  .pl10-xl, .px10-xl {
    padding-left: 10%;
  }

  .m0-5rem-xl {
    margin: .5rem;
  }

  .m1rem-xl {
    margin: 1rem;
  }

  .mt0-5rem-xl, .my0-5rem-xl {
    margin-top: .5rem;
  }

  .mt1rem-xl, .my1rem-xl {
    margin-top: 1rem;
  }

  .mr0-5rem-xl, .mx0-5rem-xl {
    margin-right: .5rem;
  }

  .mr1rem-xl, .mx1rem-xl {
    margin-right: 1rem;
  }

  .mb0-5rem-xl, .my0-5rem-xl {
    margin-bottom: .5rem;
  }

  .mb1rem-xl, .my1rem-xl {
    margin-bottom: 1rem;
  }

  .ml0-5rem-xl, .mx0-5rem-xl {
    margin-left: .5rem;
  }

  .ml1rem-xl, .mx1rem-xl {
    margin-left: 1rem;
  }

  .p0-5rem-xl {
    padding: .5rem;
  }

  .p1rem-xl {
    padding: 1rem;
  }

  .pt0-5rem-xl, .py0-5rem-xl {
    padding-top: .5rem;
  }

  .pt1rem-xl, .py1rem-xl {
    padding-top: 1rem;
  }

  .pr0-5rem-xl, .px0-5rem-xl {
    padding-right: .5rem;
  }

  .pr1rem-xl, .px1rem-xl {
    padding-right: 1rem;
  }

  .pb0-5rem-xl, .py0-5rem-xl {
    padding-bottom: .5rem;
  }

  .pb1rem-xl, .py1rem-xl {
    padding-bottom: 1rem;
  }

  .pl0-5rem-xl, .px0-5rem-xl {
    padding-left: .5rem;
  }

  .pl1rem-xl, .px1rem-xl {
    padding-left: 1rem;
  }

  .m20-xl {
    margin: 20%;
  }

  .mt20-xl, .my20-xl {
    margin-top: 20%;
  }

  .mr20-xl, .mx20-xl {
    margin-right: 20%;
  }

  .mb20-xl, .my20-xl {
    margin-bottom: 20%;
  }

  .ml20-xl, .mx20-xl {
    margin-left: 20%;
  }

  .p20-xl {
    padding: 20%;
  }

  .pt20-xl, .py20-xl {
    padding-top: 20%;
  }

  .pr20-xl, .px20-xl {
    padding-right: 20%;
  }

  .pb20-xl, .py20-xl {
    padding-bottom: 20%;
  }

  .pl20-xl, .px20-xl {
    padding-left: 20%;
  }

  .m1-5rem-xl {
    margin: 1.5rem;
  }

  .m2rem-xl {
    margin: 2rem;
  }

  .mt1-5rem-xl, .my1-5rem-xl {
    margin-top: 1.5rem;
  }

  .mt2rem-xl, .my2rem-xl {
    margin-top: 2rem;
  }

  .mr1-5rem-xl, .mx1-5rem-xl {
    margin-right: 1.5rem;
  }

  .mr2rem-xl, .mx2rem-xl {
    margin-right: 2rem;
  }

  .mb1-5rem-xl, .my1-5rem-xl {
    margin-bottom: 1.5rem;
  }

  .mb2rem-xl, .my2rem-xl {
    margin-bottom: 2rem;
  }

  .ml1-5rem-xl, .mx1-5rem-xl {
    margin-left: 1.5rem;
  }

  .ml2rem-xl, .mx2rem-xl {
    margin-left: 2rem;
  }

  .p1-5rem-xl {
    padding: 1.5rem;
  }

  .p2rem-xl {
    padding: 2rem;
  }

  .pt1-5rem-xl, .py1-5rem-xl {
    padding-top: 1.5rem;
  }

  .pt2rem-xl, .py2rem-xl {
    padding-top: 2rem;
  }

  .pr1-5rem-xl, .px1-5rem-xl {
    padding-right: 1.5rem;
  }

  .pr2rem-xl, .px2rem-xl {
    padding-right: 2rem;
  }

  .pb1-5rem-xl, .py1-5rem-xl {
    padding-bottom: 1.5rem;
  }

  .pb2rem-xl, .py2rem-xl {
    padding-bottom: 2rem;
  }

  .pl1-5rem-xl, .px1-5rem-xl {
    padding-left: 1.5rem;
  }

  .pl2rem-xl, .px2rem-xl {
    padding-left: 2rem;
  }

  .m30-xl {
    margin: 30%;
  }

  .mt30-xl, .my30-xl {
    margin-top: 30%;
  }

  .mr30-xl, .mx30-xl {
    margin-right: 30%;
  }

  .mb30-xl, .my30-xl {
    margin-bottom: 30%;
  }

  .ml30-xl, .mx30-xl {
    margin-left: 30%;
  }

  .p30-xl {
    padding: 30%;
  }

  .pt30-xl, .py30-xl {
    padding-top: 30%;
  }

  .pr30-xl, .px30-xl {
    padding-right: 30%;
  }

  .pb30-xl, .py30-xl {
    padding-bottom: 30%;
  }

  .pl30-xl, .px30-xl {
    padding-left: 30%;
  }

  .m2-5rem-xl {
    margin: 2.5rem;
  }

  .m3rem-xl {
    margin: 3rem;
  }

  .mt2-5rem-xl, .my2-5rem-xl {
    margin-top: 2.5rem;
  }

  .mt3rem-xl, .my3rem-xl {
    margin-top: 3rem;
  }

  .mr2-5rem-xl, .mx2-5rem-xl {
    margin-right: 2.5rem;
  }

  .mr3rem-xl, .mx3rem-xl {
    margin-right: 3rem;
  }

  .mb2-5rem-xl, .my2-5rem-xl {
    margin-bottom: 2.5rem;
  }

  .mb3rem-xl, .my3rem-xl {
    margin-bottom: 3rem;
  }

  .ml2-5rem-xl, .mx2-5rem-xl {
    margin-left: 2.5rem;
  }

  .ml3rem-xl, .mx3rem-xl {
    margin-left: 3rem;
  }

  .p2-5rem-xl {
    padding: 2.5rem;
  }

  .p3rem-xl {
    padding: 3rem;
  }

  .pt2-5rem-xl, .py2-5rem-xl {
    padding-top: 2.5rem;
  }

  .pt3rem-xl, .py3rem-xl {
    padding-top: 3rem;
  }

  .pr2-5rem-xl, .px2-5rem-xl {
    padding-right: 2.5rem;
  }

  .pr3rem-xl, .px3rem-xl {
    padding-right: 3rem;
  }

  .pb2-5rem-xl, .py2-5rem-xl {
    padding-bottom: 2.5rem;
  }

  .pb3rem-xl, .py3rem-xl {
    padding-bottom: 3rem;
  }

  .pl2-5rem-xl, .px2-5rem-xl {
    padding-left: 2.5rem;
  }

  .pl3rem-xl, .px3rem-xl {
    padding-left: 3rem;
  }

  .m40-xl {
    margin: 40%;
  }

  .mt40-xl, .my40-xl {
    margin-top: 40%;
  }

  .mr40-xl, .mx40-xl {
    margin-right: 40%;
  }

  .mb40-xl, .my40-xl {
    margin-bottom: 40%;
  }

  .ml40-xl, .mx40-xl {
    margin-left: 40%;
  }

  .p40-xl {
    padding: 40%;
  }

  .pt40-xl, .py40-xl {
    padding-top: 40%;
  }

  .pr40-xl, .px40-xl {
    padding-right: 40%;
  }

  .pb40-xl, .py40-xl {
    padding-bottom: 40%;
  }

  .pl40-xl, .px40-xl {
    padding-left: 40%;
  }

  .m3-5rem-xl {
    margin: 3.5rem;
  }

  .m4rem-xl {
    margin: 4rem;
  }

  .mt3-5rem-xl, .my3-5rem-xl {
    margin-top: 3.5rem;
  }

  .mt4rem-xl, .my4rem-xl {
    margin-top: 4rem;
  }

  .mr3-5rem-xl, .mx3-5rem-xl {
    margin-right: 3.5rem;
  }

  .mr4rem-xl, .mx4rem-xl {
    margin-right: 4rem;
  }

  .mb3-5rem-xl, .my3-5rem-xl {
    margin-bottom: 3.5rem;
  }

  .mb4rem-xl, .my4rem-xl {
    margin-bottom: 4rem;
  }

  .ml3-5rem-xl, .mx3-5rem-xl {
    margin-left: 3.5rem;
  }

  .ml4rem-xl, .mx4rem-xl {
    margin-left: 4rem;
  }

  .p3-5rem-xl {
    padding: 3.5rem;
  }

  .p4rem-xl {
    padding: 4rem;
  }

  .pt3-5rem-xl, .py3-5rem-xl {
    padding-top: 3.5rem;
  }

  .pt4rem-xl, .py4rem-xl {
    padding-top: 4rem;
  }

  .pr3-5rem-xl, .px3-5rem-xl {
    padding-right: 3.5rem;
  }

  .pr4rem-xl, .px4rem-xl {
    padding-right: 4rem;
  }

  .pb3-5rem-xl, .py3-5rem-xl {
    padding-bottom: 3.5rem;
  }

  .pb4rem-xl, .py4rem-xl {
    padding-bottom: 4rem;
  }

  .pl3-5rem-xl, .px3-5rem-xl {
    padding-left: 3.5rem;
  }

  .pl4rem-xl, .px4rem-xl {
    padding-left: 4rem;
  }

  .m50-xl {
    margin: 50%;
  }

  .mt50-xl, .my50-xl {
    margin-top: 50%;
  }

  .mr50-xl, .mx50-xl {
    margin-right: 50%;
  }

  .mb50-xl, .my50-xl {
    margin-bottom: 50%;
  }

  .ml50-xl, .mx50-xl {
    margin-left: 50%;
  }

  .p50-xl {
    padding: 50%;
  }

  .pt50-xl, .py50-xl {
    padding-top: 50%;
  }

  .pr50-xl, .px50-xl {
    padding-right: 50%;
  }

  .pb50-xl, .py50-xl {
    padding-bottom: 50%;
  }

  .pl50-xl, .px50-xl {
    padding-left: 50%;
  }

  .m4-5rem-xl {
    margin: 4.5rem;
  }

  .m5rem-xl {
    margin: 5rem;
  }

  .mt4-5rem-xl, .my4-5rem-xl {
    margin-top: 4.5rem;
  }

  .mt5rem-xl, .my5rem-xl {
    margin-top: 5rem;
  }

  .mr4-5rem-xl, .mx4-5rem-xl {
    margin-right: 4.5rem;
  }

  .mr5rem-xl, .mx5rem-xl {
    margin-right: 5rem;
  }

  .mb4-5rem-xl, .my4-5rem-xl {
    margin-bottom: 4.5rem;
  }

  .mb5rem-xl, .my5rem-xl {
    margin-bottom: 5rem;
  }

  .ml4-5rem-xl, .mx4-5rem-xl {
    margin-left: 4.5rem;
  }

  .ml5rem-xl, .mx5rem-xl {
    margin-left: 5rem;
  }

  .p4-5rem-xl {
    padding: 4.5rem;
  }

  .p5rem-xl {
    padding: 5rem;
  }

  .pt4-5rem-xl, .py4-5rem-xl {
    padding-top: 4.5rem;
  }

  .pt5rem-xl, .py5rem-xl {
    padding-top: 5rem;
  }

  .pr4-5rem-xl, .px4-5rem-xl {
    padding-right: 4.5rem;
  }

  .pr5rem-xl, .px5rem-xl {
    padding-right: 5rem;
  }

  .pb4-5rem-xl, .py4-5rem-xl {
    padding-bottom: 4.5rem;
  }

  .pb5rem-xl, .py5rem-xl {
    padding-bottom: 5rem;
  }

  .pl4-5rem-xl, .px4-5rem-xl {
    padding-left: 4.5rem;
  }

  .pl5rem-xl, .px5rem-xl {
    padding-left: 5rem;
  }

  .m60-xl {
    margin: 60%;
  }

  .mt60-xl, .my60-xl {
    margin-top: 60%;
  }

  .mr60-xl, .mx60-xl {
    margin-right: 60%;
  }

  .mb60-xl, .my60-xl {
    margin-bottom: 60%;
  }

  .ml60-xl, .mx60-xl {
    margin-left: 60%;
  }

  .p60-xl {
    padding: 60%;
  }

  .pt60-xl, .py60-xl {
    padding-top: 60%;
  }

  .pr60-xl, .px60-xl {
    padding-right: 60%;
  }

  .pb60-xl, .py60-xl {
    padding-bottom: 60%;
  }

  .pl60-xl, .px60-xl {
    padding-left: 60%;
  }

  .m5-5rem-xl {
    margin: 5.5rem;
  }

  .m6rem-xl {
    margin: 6rem;
  }

  .mt5-5rem-xl, .my5-5rem-xl {
    margin-top: 5.5rem;
  }

  .mt6rem-xl, .my6rem-xl {
    margin-top: 6rem;
  }

  .mr5-5rem-xl, .mx5-5rem-xl {
    margin-right: 5.5rem;
  }

  .mr6rem-xl, .mx6rem-xl {
    margin-right: 6rem;
  }

  .mb5-5rem-xl, .my5-5rem-xl {
    margin-bottom: 5.5rem;
  }

  .mb6rem-xl, .my6rem-xl {
    margin-bottom: 6rem;
  }

  .ml5-5rem-xl, .mx5-5rem-xl {
    margin-left: 5.5rem;
  }

  .ml6rem-xl, .mx6rem-xl {
    margin-left: 6rem;
  }

  .p5-5rem-xl {
    padding: 5.5rem;
  }

  .p6rem-xl {
    padding: 6rem;
  }

  .pt5-5rem-xl, .py5-5rem-xl {
    padding-top: 5.5rem;
  }

  .pt6rem-xl, .py6rem-xl {
    padding-top: 6rem;
  }

  .pr5-5rem-xl, .px5-5rem-xl {
    padding-right: 5.5rem;
  }

  .pr6rem-xl, .px6rem-xl {
    padding-right: 6rem;
  }

  .pb5-5rem-xl, .py5-5rem-xl {
    padding-bottom: 5.5rem;
  }

  .pb6rem-xl, .py6rem-xl {
    padding-bottom: 6rem;
  }

  .pl5-5rem-xl, .px5-5rem-xl {
    padding-left: 5.5rem;
  }

  .pl6rem-xl, .px6rem-xl {
    padding-left: 6rem;
  }

  .m70-xl {
    margin: 70%;
  }

  .mt70-xl, .my70-xl {
    margin-top: 70%;
  }

  .mr70-xl, .mx70-xl {
    margin-right: 70%;
  }

  .mb70-xl, .my70-xl {
    margin-bottom: 70%;
  }

  .ml70-xl, .mx70-xl {
    margin-left: 70%;
  }

  .p70-xl {
    padding: 70%;
  }

  .pt70-xl, .py70-xl {
    padding-top: 70%;
  }

  .pr70-xl, .px70-xl {
    padding-right: 70%;
  }

  .pb70-xl, .py70-xl {
    padding-bottom: 70%;
  }

  .pl70-xl, .px70-xl {
    padding-left: 70%;
  }

  .m6-5rem-xl {
    margin: 6.5rem;
  }

  .m7rem-xl {
    margin: 7rem;
  }

  .mt6-5rem-xl, .my6-5rem-xl {
    margin-top: 6.5rem;
  }

  .mt7rem-xl, .my7rem-xl {
    margin-top: 7rem;
  }

  .mr6-5rem-xl, .mx6-5rem-xl {
    margin-right: 6.5rem;
  }

  .mr7rem-xl, .mx7rem-xl {
    margin-right: 7rem;
  }

  .mb6-5rem-xl, .my6-5rem-xl {
    margin-bottom: 6.5rem;
  }

  .mb7rem-xl, .my7rem-xl {
    margin-bottom: 7rem;
  }

  .ml6-5rem-xl, .mx6-5rem-xl {
    margin-left: 6.5rem;
  }

  .ml7rem-xl, .mx7rem-xl {
    margin-left: 7rem;
  }

  .p6-5rem-xl {
    padding: 6.5rem;
  }

  .p7rem-xl {
    padding: 7rem;
  }

  .pt6-5rem-xl, .py6-5rem-xl {
    padding-top: 6.5rem;
  }

  .pt7rem-xl, .py7rem-xl {
    padding-top: 7rem;
  }

  .pr6-5rem-xl, .px6-5rem-xl {
    padding-right: 6.5rem;
  }

  .pr7rem-xl, .px7rem-xl {
    padding-right: 7rem;
  }

  .pb6-5rem-xl, .py6-5rem-xl {
    padding-bottom: 6.5rem;
  }

  .pb7rem-xl, .py7rem-xl {
    padding-bottom: 7rem;
  }

  .pl6-5rem-xl, .px6-5rem-xl {
    padding-left: 6.5rem;
  }

  .pl7rem-xl, .px7rem-xl {
    padding-left: 7rem;
  }

  .m80-xl {
    margin: 80%;
  }

  .mt80-xl, .my80-xl {
    margin-top: 80%;
  }

  .mr80-xl, .mx80-xl {
    margin-right: 80%;
  }

  .mb80-xl, .my80-xl {
    margin-bottom: 80%;
  }

  .ml80-xl, .mx80-xl {
    margin-left: 80%;
  }

  .p80-xl {
    padding: 80%;
  }

  .pt80-xl, .py80-xl {
    padding-top: 80%;
  }

  .pr80-xl, .px80-xl {
    padding-right: 80%;
  }

  .pb80-xl, .py80-xl {
    padding-bottom: 80%;
  }

  .pl80-xl, .px80-xl {
    padding-left: 80%;
  }

  .m7-5rem-xl {
    margin: 7.5rem;
  }

  .m8rem-xl {
    margin: 8rem;
  }

  .mt7-5rem-xl, .my7-5rem-xl {
    margin-top: 7.5rem;
  }

  .mt8rem-xl, .my8rem-xl {
    margin-top: 8rem;
  }

  .mr7-5rem-xl, .mx7-5rem-xl {
    margin-right: 7.5rem;
  }

  .mr8rem-xl, .mx8rem-xl {
    margin-right: 8rem;
  }

  .mb7-5rem-xl, .my7-5rem-xl {
    margin-bottom: 7.5rem;
  }

  .mb8rem-xl, .my8rem-xl {
    margin-bottom: 8rem;
  }

  .ml7-5rem-xl, .mx7-5rem-xl {
    margin-left: 7.5rem;
  }

  .ml8rem-xl, .mx8rem-xl {
    margin-left: 8rem;
  }

  .p7-5rem-xl {
    padding: 7.5rem;
  }

  .p8rem-xl {
    padding: 8rem;
  }

  .pt7-5rem-xl, .py7-5rem-xl {
    padding-top: 7.5rem;
  }

  .pt8rem-xl, .py8rem-xl {
    padding-top: 8rem;
  }

  .pr7-5rem-xl, .px7-5rem-xl {
    padding-right: 7.5rem;
  }

  .pr8rem-xl, .px8rem-xl {
    padding-right: 8rem;
  }

  .pb7-5rem-xl, .py7-5rem-xl {
    padding-bottom: 7.5rem;
  }

  .pb8rem-xl, .py8rem-xl {
    padding-bottom: 8rem;
  }

  .pl7-5rem-xl, .px7-5rem-xl {
    padding-left: 7.5rem;
  }

  .pl8rem-xl, .px8rem-xl {
    padding-left: 8rem;
  }

  .m90-xl {
    margin: 90%;
  }

  .mt90-xl, .my90-xl {
    margin-top: 90%;
  }

  .mr90-xl, .mx90-xl {
    margin-right: 90%;
  }

  .mb90-xl, .my90-xl {
    margin-bottom: 90%;
  }

  .ml90-xl, .mx90-xl {
    margin-left: 90%;
  }

  .p90-xl {
    padding: 90%;
  }

  .pt90-xl, .py90-xl {
    padding-top: 90%;
  }

  .pr90-xl, .px90-xl {
    padding-right: 90%;
  }

  .pb90-xl, .py90-xl {
    padding-bottom: 90%;
  }

  .pl90-xl, .px90-xl {
    padding-left: 90%;
  }

  .m8-5rem-xl {
    margin: 8.5rem;
  }

  .m9rem-xl {
    margin: 9rem;
  }

  .mt8-5rem-xl, .my8-5rem-xl {
    margin-top: 8.5rem;
  }

  .mt9rem-xl, .my9rem-xl {
    margin-top: 9rem;
  }

  .mr8-5rem-xl, .mx8-5rem-xl {
    margin-right: 8.5rem;
  }

  .mr9rem-xl, .mx9rem-xl {
    margin-right: 9rem;
  }

  .mb8-5rem-xl, .my8-5rem-xl {
    margin-bottom: 8.5rem;
  }

  .mb9rem-xl, .my9rem-xl {
    margin-bottom: 9rem;
  }

  .ml8-5rem-xl, .mx8-5rem-xl {
    margin-left: 8.5rem;
  }

  .ml9rem-xl, .mx9rem-xl {
    margin-left: 9rem;
  }

  .p8-5rem-xl {
    padding: 8.5rem;
  }

  .p9rem-xl {
    padding: 9rem;
  }

  .pt8-5rem-xl, .py8-5rem-xl {
    padding-top: 8.5rem;
  }

  .pt9rem-xl, .py9rem-xl {
    padding-top: 9rem;
  }

  .pr8-5rem-xl, .px8-5rem-xl {
    padding-right: 8.5rem;
  }

  .pr9rem-xl, .px9rem-xl {
    padding-right: 9rem;
  }

  .pb8-5rem-xl, .py8-5rem-xl {
    padding-bottom: 8.5rem;
  }

  .pb9rem-xl, .py9rem-xl {
    padding-bottom: 9rem;
  }

  .pl8-5rem-xl, .px8-5rem-xl {
    padding-left: 8.5rem;
  }

  .pl9rem-xl, .px9rem-xl {
    padding-left: 9rem;
  }

  .m100-xl {
    margin: 100%;
  }

  .mt100-xl, .my100-xl {
    margin-top: 100%;
  }

  .mr100-xl, .mx100-xl {
    margin-right: 100%;
  }

  .mb100-xl, .my100-xl {
    margin-bottom: 100%;
  }

  .ml100-xl, .mx100-xl {
    margin-left: 100%;
  }

  .p100-xl {
    padding: 100%;
  }

  .pt100-xl, .py100-xl {
    padding-top: 100%;
  }

  .pr100-xl, .px100-xl {
    padding-right: 100%;
  }

  .pb100-xl, .py100-xl {
    padding-bottom: 100%;
  }

  .pl100-xl, .px100-xl {
    padding-left: 100%;
  }

  .m9-5rem-xl {
    margin: 9.5rem;
  }

  .m10rem-xl {
    margin: 10rem;
  }

  .mt9-5rem-xl, .my9-5rem-xl {
    margin-top: 9.5rem;
  }

  .mt10rem-xl, .my10rem-xl {
    margin-top: 10rem;
  }

  .mr9-5rem-xl, .mx9-5rem-xl {
    margin-right: 9.5rem;
  }

  .mr10rem-xl, .mx10rem-xl {
    margin-right: 10rem;
  }

  .mb9-5rem-xl, .my9-5rem-xl {
    margin-bottom: 9.5rem;
  }

  .mb10rem-xl, .my10rem-xl {
    margin-bottom: 10rem;
  }

  .ml9-5rem-xl, .mx9-5rem-xl {
    margin-left: 9.5rem;
  }

  .ml10rem-xl, .mx10rem-xl {
    margin-left: 10rem;
  }

  .p9-5rem-xl {
    padding: 9.5rem;
  }

  .p10rem-xl {
    padding: 10rem;
  }

  .pt9-5rem-xl, .py9-5rem-xl {
    padding-top: 9.5rem;
  }

  .pt10rem-xl, .py10rem-xl {
    padding-top: 10rem;
  }

  .pr9-5rem-xl, .px9-5rem-xl {
    padding-right: 9.5rem;
  }

  .pr10rem-xl, .px10rem-xl {
    padding-right: 10rem;
  }

  .pb9-5rem-xl, .py9-5rem-xl {
    padding-bottom: 9.5rem;
  }

  .pb10rem-xl, .py10rem-xl {
    padding-bottom: 10rem;
  }

  .pl9-5rem-xl, .px9-5rem-xl {
    padding-left: 9.5rem;
  }

  .pl10rem-xl, .px10rem-xl {
    padding-left: 10rem;
  }

  .wa-xl {
    width: auto;
  }

  .ha-xl {
    height: auto;
  }

  .w0-xl {
    width: 0;
  }

  .h0-xl {
    height: 0;
  }

  .maw0-xl {
    max-width: 0;
  }

  .mah0-xl {
    max-height: 0;
  }

  .mih0-xl {
    min-height: 0;
  }

  .w1rem-xl {
    width: 1rem;
  }

  .w10px-xl {
    width: 10px;
  }

  .w10-xl {
    width: 10%;
  }

  .w10vw-xl {
    width: 10vw;
  }

  .h1rem-xl {
    height: 1rem;
  }

  .h10px-xl {
    height: 10px;
  }

  .h10-xl {
    height: 10%;
  }

  .h10vh-xl {
    height: 10vh;
  }

  .miw1rem-xl {
    min-width: 1rem;
  }

  .miw100px-xl {
    min-width: 100px;
  }

  .miw10-xl {
    min-width: 10%;
  }

  .miw10vw-xl {
    min-width: 10vw;
  }

  .mih1rem-xl {
    min-height: 1rem;
  }

  .mih100px-xl {
    min-height: 100px;
  }

  .mih10-xl {
    min-height: 10%;
  }

  .mih10vh-xl {
    min-height: 10vh;
  }

  .maw1rem-xl {
    max-width: 1rem;
  }

  .maw100px-xl {
    max-width: 100px;
  }

  .maw10-xl {
    max-width: 10%;
  }

  .maw10vw-xl {
    max-width: 10vw;
  }

  .mah1rem-xl {
    max-height: 1rem;
  }

  .mah100px-xl {
    max-height: 100px;
  }

  .mah10-xl {
    max-height: 10%;
  }

  .mah10vh-xl {
    max-height: 10vh;
  }

  .w2rem-xl {
    width: 2rem;
  }

  .w20px-xl {
    width: 20px;
  }

  .w200px-xl {
    width: 200px;
  }

  .w20-xl {
    width: 20%;
  }

  .w20vw-xl {
    width: 20vw;
  }

  .h2rem-xl {
    height: 2rem;
  }

  .h20px-xl {
    height: 20px;
  }

  .h200px-xl {
    height: 200px;
  }

  .h20-xl {
    height: 20%;
  }

  .h20vh-xl {
    height: 20vh;
  }

  .miw2rem-xl {
    min-width: 2rem;
  }

  .miw200px-xl {
    min-width: 200px;
  }

  .miw20-xl {
    min-width: 20%;
  }

  .miw20vw-xl {
    min-width: 20vw;
  }

  .mih2rem-xl {
    min-height: 2rem;
  }

  .mih200px-xl {
    min-height: 200px;
  }

  .mih20-xl {
    min-height: 20%;
  }

  .mih20vh-xl {
    min-height: 20vh;
  }

  .maw2rem-xl {
    max-width: 2rem;
  }

  .maw200px-xl {
    max-width: 200px;
  }

  .maw20-xl {
    max-width: 20%;
  }

  .maw20vw-xl {
    max-width: 20vw;
  }

  .mah2rem-xl {
    max-height: 2rem;
  }

  .mah200px-xl {
    max-height: 200px;
  }

  .mah20-xl {
    max-height: 20%;
  }

  .mah20vh-xl {
    max-height: 20vh;
  }

  .w3rem-xl {
    width: 3rem;
  }

  .w30px-xl {
    width: 30px;
  }

  .w300px-xl {
    width: 300px;
  }

  .w30-xl {
    width: 30%;
  }

  .w30vw-xl {
    width: 30vw;
  }

  .h3rem-xl {
    height: 3rem;
  }

  .h30px-xl {
    height: 30px;
  }

  .h300px-xl {
    height: 300px;
  }

  .h30-xl {
    height: 30%;
  }

  .h30vh-xl {
    height: 30vh;
  }

  .miw3rem-xl {
    min-width: 3rem;
  }

  .miw300px-xl {
    min-width: 300px;
  }

  .miw30-xl {
    min-width: 30%;
  }

  .miw30vw-xl {
    min-width: 30vw;
  }

  .mih3rem-xl {
    min-height: 3rem;
  }

  .mih300px-xl {
    min-height: 300px;
  }

  .mih30-xl {
    min-height: 30%;
  }

  .mih30vh-xl {
    min-height: 30vh;
  }

  .maw3rem-xl {
    max-width: 3rem;
  }

  .maw300px-xl {
    max-width: 300px;
  }

  .maw30-xl {
    max-width: 30%;
  }

  .maw30vw-xl {
    max-width: 30vw;
  }

  .mah3rem-xl {
    max-height: 3rem;
  }

  .mah300px-xl {
    max-height: 300px;
  }

  .mah30-xl {
    max-height: 30%;
  }

  .mah30vh-xl {
    max-height: 30vh;
  }

  .w4rem-xl {
    width: 4rem;
  }

  .w40px-xl {
    width: 40px;
  }

  .w400px-xl {
    width: 400px;
  }

  .w40-xl {
    width: 40%;
  }

  .w40vw-xl {
    width: 40vw;
  }

  .h4rem-xl {
    height: 4rem;
  }

  .h40px-xl {
    height: 40px;
  }

  .h400px-xl {
    height: 400px;
  }

  .h40-xl {
    height: 40%;
  }

  .h40vh-xl {
    height: 40vh;
  }

  .miw4rem-xl {
    min-width: 4rem;
  }

  .miw400px-xl {
    min-width: 400px;
  }

  .miw40-xl {
    min-width: 40%;
  }

  .miw40vw-xl {
    min-width: 40vw;
  }

  .mih4rem-xl {
    min-height: 4rem;
  }

  .mih400px-xl {
    min-height: 400px;
  }

  .mih40-xl {
    min-height: 40%;
  }

  .mih40vh-xl {
    min-height: 40vh;
  }

  .maw4rem-xl {
    max-width: 4rem;
  }

  .maw400px-xl {
    max-width: 400px;
  }

  .maw40-xl {
    max-width: 40%;
  }

  .maw40vw-xl {
    max-width: 40vw;
  }

  .mah4rem-xl {
    max-height: 4rem;
  }

  .mah400px-xl {
    max-height: 400px;
  }

  .mah40-xl {
    max-height: 40%;
  }

  .mah40vh-xl {
    max-height: 40vh;
  }

  .w5rem-xl {
    width: 5rem;
  }

  .w50px-xl {
    width: 50px;
  }

  .w500px-xl {
    width: 500px;
  }

  .w50-xl {
    width: 50%;
  }

  .w50vw-xl {
    width: 50vw;
  }

  .h5rem-xl {
    height: 5rem;
  }

  .h50px-xl {
    height: 50px;
  }

  .h500px-xl {
    height: 500px;
  }

  .h50-xl {
    height: 50%;
  }

  .h50vh-xl {
    height: 50vh;
  }

  .miw5rem-xl {
    min-width: 5rem;
  }

  .miw500px-xl {
    min-width: 500px;
  }

  .miw50-xl {
    min-width: 50%;
  }

  .miw50vw-xl {
    min-width: 50vw;
  }

  .mih5rem-xl {
    min-height: 5rem;
  }

  .mih500px-xl {
    min-height: 500px;
  }

  .mih50-xl {
    min-height: 50%;
  }

  .mih50vh-xl {
    min-height: 50vh;
  }

  .maw5rem-xl {
    max-width: 5rem;
  }

  .maw500px-xl {
    max-width: 500px;
  }

  .maw50-xl {
    max-width: 50%;
  }

  .maw50vw-xl {
    max-width: 50vw;
  }

  .mah5rem-xl {
    max-height: 5rem;
  }

  .mah500px-xl {
    max-height: 500px;
  }

  .mah50-xl {
    max-height: 50%;
  }

  .mah50vh-xl {
    max-height: 50vh;
  }

  .w6rem-xl {
    width: 6rem;
  }

  .w60px-xl {
    width: 60px;
  }

  .w600px-xl {
    width: 600px;
  }

  .w60-xl {
    width: 60%;
  }

  .w60vw-xl {
    width: 60vw;
  }

  .h6rem-xl {
    height: 6rem;
  }

  .h60px-xl {
    height: 60px;
  }

  .h600px-xl {
    height: 600px;
  }

  .h60-xl {
    height: 60%;
  }

  .h60vh-xl {
    height: 60vh;
  }

  .miw6rem-xl {
    min-width: 6rem;
  }

  .miw600px-xl {
    min-width: 600px;
  }

  .miw60-xl {
    min-width: 60%;
  }

  .miw60vw-xl {
    min-width: 60vw;
  }

  .mih6rem-xl {
    min-height: 6rem;
  }

  .mih600px-xl {
    min-height: 600px;
  }

  .mih60-xl {
    min-height: 60%;
  }

  .mih60vh-xl {
    min-height: 60vh;
  }

  .maw6rem-xl {
    max-width: 6rem;
  }

  .maw600px-xl {
    max-width: 600px;
  }

  .maw60-xl {
    max-width: 60%;
  }

  .maw60vw-xl {
    max-width: 60vw;
  }

  .mah6rem-xl {
    max-height: 6rem;
  }

  .mah600px-xl {
    max-height: 600px;
  }

  .mah60-xl {
    max-height: 60%;
  }

  .mah60vh-xl {
    max-height: 60vh;
  }

  .w7rem-xl {
    width: 7rem;
  }

  .w70px-xl {
    width: 70px;
  }

  .w700px-xl {
    width: 700px;
  }

  .w70-xl {
    width: 70%;
  }

  .w70vw-xl {
    width: 70vw;
  }

  .h7rem-xl {
    height: 7rem;
  }

  .h70px-xl {
    height: 70px;
  }

  .h700px-xl {
    height: 700px;
  }

  .h70-xl {
    height: 70%;
  }

  .h70vh-xl {
    height: 70vh;
  }

  .miw7rem-xl {
    min-width: 7rem;
  }

  .miw700px-xl {
    min-width: 700px;
  }

  .miw70-xl {
    min-width: 70%;
  }

  .miw70vw-xl {
    min-width: 70vw;
  }

  .mih7rem-xl {
    min-height: 7rem;
  }

  .mih700px-xl {
    min-height: 700px;
  }

  .mih70-xl {
    min-height: 70%;
  }

  .mih70vh-xl {
    min-height: 70vh;
  }

  .maw7rem-xl {
    max-width: 7rem;
  }

  .maw700px-xl {
    max-width: 700px;
  }

  .maw70-xl {
    max-width: 70%;
  }

  .maw70vw-xl {
    max-width: 70vw;
  }

  .mah7rem-xl {
    max-height: 7rem;
  }

  .mah700px-xl {
    max-height: 700px;
  }

  .mah70-xl {
    max-height: 70%;
  }

  .mah70vh-xl {
    max-height: 70vh;
  }

  .w8rem-xl {
    width: 8rem;
  }

  .w80px-xl {
    width: 80px;
  }

  .w800px-xl {
    width: 800px;
  }

  .w80-xl {
    width: 80%;
  }

  .w80vw-xl {
    width: 80vw;
  }

  .h8rem-xl {
    height: 8rem;
  }

  .h80px-xl {
    height: 80px;
  }

  .h800px-xl {
    height: 800px;
  }

  .h80-xl {
    height: 80%;
  }

  .h80vh-xl {
    height: 80vh;
  }

  .miw8rem-xl {
    min-width: 8rem;
  }

  .miw800px-xl {
    min-width: 800px;
  }

  .miw80-xl {
    min-width: 80%;
  }

  .miw80vw-xl {
    min-width: 80vw;
  }

  .mih8rem-xl {
    min-height: 8rem;
  }

  .mih800px-xl {
    min-height: 800px;
  }

  .mih80-xl {
    min-height: 80%;
  }

  .mih80vh-xl {
    min-height: 80vh;
  }

  .maw8rem-xl {
    max-width: 8rem;
  }

  .maw800px-xl {
    max-width: 800px;
  }

  .maw80-xl {
    max-width: 80%;
  }

  .maw80vw-xl {
    max-width: 80vw;
  }

  .mah8rem-xl {
    max-height: 8rem;
  }

  .mah800px-xl {
    max-height: 800px;
  }

  .mah80-xl {
    max-height: 80%;
  }

  .mah80vh-xl {
    max-height: 80vh;
  }

  .w9rem-xl {
    width: 9rem;
  }

  .w90px-xl {
    width: 90px;
  }

  .w900px-xl {
    width: 900px;
  }

  .w90-xl {
    width: 90%;
  }

  .w90vw-xl {
    width: 90vw;
  }

  .h9rem-xl {
    height: 9rem;
  }

  .h90px-xl {
    height: 90px;
  }

  .h900px-xl {
    height: 900px;
  }

  .h90-xl {
    height: 90%;
  }

  .h90vh-xl {
    height: 90vh;
  }

  .miw9rem-xl {
    min-width: 9rem;
  }

  .miw900px-xl {
    min-width: 900px;
  }

  .miw90-xl {
    min-width: 90%;
  }

  .miw90vw-xl {
    min-width: 90vw;
  }

  .mih9rem-xl {
    min-height: 9rem;
  }

  .mih900px-xl {
    min-height: 900px;
  }

  .mih90-xl {
    min-height: 90%;
  }

  .mih90vh-xl {
    min-height: 90vh;
  }

  .maw9rem-xl {
    max-width: 9rem;
  }

  .maw900px-xl {
    max-width: 900px;
  }

  .maw90-xl {
    max-width: 90%;
  }

  .maw90vw-xl {
    max-width: 90vw;
  }

  .mah9rem-xl {
    max-height: 9rem;
  }

  .mah900px-xl {
    max-height: 900px;
  }

  .mah90-xl {
    max-height: 90%;
  }

  .mah90vh-xl {
    max-height: 90vh;
  }

  .w10rem-xl {
    width: 10rem;
  }

  .w100px-xl {
    width: 100px;
  }

  .w1000px-xl {
    width: 1000px;
  }

  .w100-xl {
    width: 100%;
  }

  .w100vw-xl {
    width: 100vw;
  }

  .h10rem-xl {
    height: 10rem;
  }

  .h100px-xl {
    height: 100px;
  }

  .h1000px-xl {
    height: 1000px;
  }

  .h100-xl {
    height: 100%;
  }

  .h100vh-xl {
    height: 100vh;
  }

  .miw10rem-xl {
    min-width: 10rem;
  }

  .miw1000px-xl {
    min-width: 1000px;
  }

  .miw100-xl {
    min-width: 100%;
  }

  .miw100vw-xl {
    min-width: 100vw;
  }

  .mih10rem-xl {
    min-height: 10rem;
  }

  .mih1000px-xl {
    min-height: 1000px;
  }

  .mih100-xl {
    min-height: 100%;
  }

  .mih100vh-xl {
    min-height: 100vh;
  }

  .maw10rem-xl {
    max-width: 10rem;
  }

  .maw1000px-xl {
    max-width: 1000px;
  }

  .maw100-xl {
    max-width: 100%;
  }

  .maw100vw-xl {
    max-width: 100vw;
  }

  .mah10rem-xl {
    max-height: 10rem;
  }

  .mah1000px-xl {
    max-height: 1000px;
  }

  .mah100-xl {
    max-height: 100%;
  }

  .mah100vh-xl {
    max-height: 100vh;
  }

  .or0-xl {
    order: 0;
  }

  .br0px-xl {
    border-radius: 0;
  }

  .btlr0px-xl {
    border-top-left-radius: 0;
  }

  .btrr0px-xl {
    border-top-right-radius: 0;
  }

  .bblr0px-xl {
    border-bottom-left-radius: 0;
  }

  .bbrr0px-xl {
    border-bottom-right-radius: 0;
  }

  .or1-xl {
    order: 1;
  }

  .br5px-xl {
    border-radius: 6px;
  }

  .br8px-xl {
    border-radius: 8px;
  }

  .br19px-xl {
    border-radius: 19px;
  }

  .btlr5px-xl {
    border-top-left-radius: 5px;
  }

  .btrr5px-xl {
    border-top-right-radius: 5px;
  }

  .bblr5px-xl {
    border-bottom-left-radius: 5px;
  }

  .bbrr5px-xl {
    border-bottom-right-radius: 5px;
  }

  .or2-xl {
    order: 2;
  }

  .br10px-xl {
    border-radius: 12px;
  }

  .br16px-xl {
    border-radius: 16px;
  }

  .br38px-xl {
    border-radius: 38px;
  }

  .btlr10px-xl {
    border-top-left-radius: 10px;
  }

  .btrr10px-xl {
    border-top-right-radius: 10px;
  }

  .bblr10px-xl {
    border-bottom-left-radius: 10px;
  }

  .bbrr10px-xl {
    border-bottom-right-radius: 10px;
  }

  .or3-xl {
    order: 3;
  }

  .br15px-xl {
    border-radius: 18px;
  }

  .br24px-xl {
    border-radius: 24px;
  }

  .br57px-xl {
    border-radius: 57px;
  }

  .btlr15px-xl {
    border-top-left-radius: 15px;
  }

  .btrr15px-xl {
    border-top-right-radius: 15px;
  }

  .bblr15px-xl {
    border-bottom-left-radius: 15px;
  }

  .bbrr15px-xl {
    border-bottom-right-radius: 15px;
  }

  .or4-xl {
    order: 4;
  }

  .br20px-xl {
    border-radius: 24px;
  }

  .br32px-xl {
    border-radius: 32px;
  }

  .br76px-xl {
    border-radius: 76px;
  }

  .btlr20px-xl {
    border-top-left-radius: 20px;
  }

  .btrr20px-xl {
    border-top-right-radius: 20px;
  }

  .bblr20px-xl {
    border-bottom-left-radius: 20px;
  }

  .bbrr20px-xl {
    border-bottom-right-radius: 20px;
  }

  .or5-xl {
    order: 5;
  }

  .br25px-xl {
    border-radius: 30px;
  }

  .br95px-xl {
    border-radius: 95px;
  }

  .btlr25px-xl {
    border-top-left-radius: 25px;
  }

  .btrr25px-xl {
    border-top-right-radius: 25px;
  }

  .bblr25px-xl {
    border-bottom-left-radius: 25px;
  }

  .bbrr25px-xl {
    border-bottom-right-radius: 25px;
  }

  .or6-xl {
    order: 6;
  }

  .br30px-xl {
    border-radius: 36px;
  }

  .br48px-xl {
    border-radius: 48px;
  }

  .br114px-xl {
    border-radius: 114px;
  }

  .btlr30px-xl {
    border-top-left-radius: 30px;
  }

  .btrr30px-xl {
    border-top-right-radius: 30px;
  }

  .bblr30px-xl {
    border-bottom-left-radius: 30px;
  }

  .bbrr30px-xl {
    border-bottom-right-radius: 30px;
  }

  .or7-xl {
    order: 7;
  }

  .br35px-xl {
    border-radius: 42px;
  }

  .br56px-xl {
    border-radius: 56px;
  }

  .br133px-xl {
    border-radius: 133px;
  }

  .btlr35px-xl {
    border-top-left-radius: 35px;
  }

  .btrr35px-xl {
    border-top-right-radius: 35px;
  }

  .bblr35px-xl {
    border-bottom-left-radius: 35px;
  }

  .bbrr35px-xl {
    border-bottom-right-radius: 35px;
  }

  .or8-xl {
    order: 8;
  }

  .br40px-xl {
    border-radius: 48px;
  }

  .br64px-xl {
    border-radius: 64px;
  }

  .br152px-xl {
    border-radius: 152px;
  }

  .btlr40px-xl {
    border-top-left-radius: 40px;
  }

  .btrr40px-xl {
    border-top-right-radius: 40px;
  }

  .bblr40px-xl {
    border-bottom-left-radius: 40px;
  }

  .bbrr40px-xl {
    border-bottom-right-radius: 40px;
  }

  .or9-xl {
    order: 9;
  }

  .br45px-xl {
    border-radius: 54px;
  }

  .br72px-xl {
    border-radius: 72px;
  }

  .br171px-xl {
    border-radius: 171px;
  }

  .btlr45px-xl {
    border-top-left-radius: 45px;
  }

  .btrr45px-xl {
    border-top-right-radius: 45px;
  }

  .bblr45px-xl {
    border-bottom-left-radius: 45px;
  }

  .bbrr45px-xl {
    border-bottom-right-radius: 45px;
  }

  .or10-xl {
    order: 10;
  }

  .br50px-xl {
    border-radius: 60px;
  }

  .br80px-xl {
    border-radius: 80px;
  }

  .br190px-xl {
    border-radius: 190px;
  }

  .btlr50px-xl {
    border-top-left-radius: 50px;
  }

  .btrr50px-xl {
    border-top-right-radius: 50px;
  }

  .bblr50px-xl {
    border-bottom-left-radius: 50px;
  }

  .bbrr50px-xl {
    border-bottom-right-radius: 50px;
  }

  .br100 {
    border-radius: 100%;
  }

  .btlr100 {
    border-top-left-radius: 100%;
  }

  .btrr100 {
    border-top-right-radius: 100%;
  }

  .bblr100 {
    border-bottom-left-radius: 100%;
  }

  .bbrr100 {
    border-bottom-right-radius: 100%;
  }
}

@media (width >= 1276.8px) {
  .c1-screen1, .c1-hover-screen1:hover {
    color: #fff;
  }

  .bg1-screen1, .bg1-hover-screen1:hover {
    background-color: #fff;
  }

  .bo1-screen1, .bo1-hover-screen1:hover {
    border-color: #fff;
  }

  .f1-screen1, .f1-hover-screen1:hover {
    fill: #fff;
  }

  .c2-screen1, .c2-hover-screen1:hover {
    color: #000;
  }

  .bg2-screen1, .bg2-hover-screen1:hover {
    background-color: #000;
  }

  .bo2-screen1, .bo2-hover-screen1:hover {
    border-color: #000;
  }

  .f2-screen1, .f2-hover-screen1:hover {
    fill: #000;
  }

  .c3-screen1, .c3-hover-screen1:hover {
    color: #4a67ff;
  }

  .bg3-screen1, .bg3-hover-screen1:hover {
    background-color: #4a67ff;
  }

  .bo3-screen1, .bo3-hover-screen1:hover {
    border-color: #4a67ff;
  }

  .f3-screen1, .f3-hover-screen1:hover {
    fill: #4a67ff;
  }

  .c4-screen1, .c4-hover-screen1:hover {
    color: #fc7785;
  }

  .bg4-screen1, .bg4-hover-screen1:hover {
    background-color: #fc7785;
  }

  .bo4-screen1, .bo4-hover-screen1:hover {
    border-color: #fc7785;
  }

  .f4-screen1, .f4-hover-screen1:hover {
    fill: #fc7785;
  }

  .c5-screen1, .c5-hover-screen1:hover {
    color: #f0f0f8;
  }

  .bg5-screen1, .bg5-hover-screen1:hover {
    background-color: #f0f0f8;
  }

  .bo5-screen1, .bo5-hover-screen1:hover {
    border-color: #f0f0f8;
  }

  .f5-screen1, .f5-hover-screen1:hover {
    fill: #f0f0f8;
  }

  .c6-screen1, .c6-hover-screen1:hover {
    color: #002d63;
  }

  .bg6-screen1, .bg6-hover-screen1:hover {
    background-color: #002d63;
  }

  .bo6-screen1, .bo6-hover-screen1:hover {
    border-color: #002d63;
  }

  .f6-screen1, .f6-hover-screen1:hover {
    fill: #002d63;
  }

  .c7-screen1, .c7-hover-screen1:hover {
    color: #40dda1;
  }

  .bg7-screen1, .bg7-hover-screen1:hover {
    background-color: #40dda1;
  }

  .bo7-screen1, .bo7-hover-screen1:hover {
    border-color: #40dda1;
  }

  .f7-screen1, .f7-hover-screen1:hover {
    fill: #40dda1;
  }

  .c8-screen1, .c8-hover-screen1:hover {
    color: #ddb640;
  }

  .bg8-screen1, .bg8-hover-screen1:hover {
    background-color: #ddb640;
  }

  .bo8-screen1, .bo8-hover-screen1:hover {
    border-color: #ddb640;
  }

  .f8-screen1, .f8-hover-screen1:hover {
    fill: #ddb640;
  }

  .c9-screen1, .c9-hover-screen1:hover {
    color: #dd4040;
  }

  .bg9-screen1, .bg9-hover-screen1:hover {
    background-color: #dd4040;
  }

  .bo9-screen1, .bo9-hover-screen1:hover {
    border-color: #dd4040;
  }

  .f9-screen1, .f9-hover-screen1:hover {
    fill: #dd4040;
  }

  .c10-screen1, .c10-hover-screen1:hover {
    color: #038589;
  }

  .bg10-screen1, .bg10-hover-screen1:hover {
    background-color: #038589;
  }

  .bo10-screen1, .bo10-hover-screen1:hover {
    border-color: #038589;
  }

  .f10-screen1, .f10-hover-screen1:hover {
    fill: #038589;
  }

  .c11-screen1, .c11-hover-screen1:hover {
    color: #007ee5;
  }

  .bg11-screen1, .bg11-hover-screen1:hover {
    background-color: #007ee5;
  }

  .bo11-screen1, .bo11-hover-screen1:hover {
    border-color: #007ee5;
  }

  .f11-screen1, .f11-hover-screen1:hover {
    fill: #007ee5;
  }

  .c12-screen1, .c12-hover-screen1:hover {
    color: #969696;
  }

  .bg12-screen1, .bg12-hover-screen1:hover {
    background-color: #969696;
  }

  .bo12-screen1, .bo12-hover-screen1:hover {
    border-color: #969696;
  }

  .f12-screen1, .f12-hover-screen1:hover {
    fill: #969696;
  }

  .c13-screen1, .c13-hover-screen1:hover {
    color: #fb7a27;
  }

  .bg13-screen1, .bg13-hover-screen1:hover {
    background-color: #fb7a27;
  }

  .bo13-screen1, .bo13-hover-screen1:hover {
    border-color: #fb7a27;
  }

  .f13-screen1, .f13-hover-screen1:hover {
    fill: #fb7a27;
  }

  .c14-screen1, .c14-hover-screen1:hover {
    color: #505050;
  }

  .bg14-screen1, .bg14-hover-screen1:hover {
    background-color: #505050;
  }

  .bo14-screen1, .bo14-hover-screen1:hover {
    border-color: #505050;
  }

  .f14-screen1, .f14-hover-screen1:hover {
    fill: #505050;
  }

  .c15-screen1, .c15-hover-screen1:hover {
    color: #151313;
  }

  .bg15-screen1, .bg15-hover-screen1:hover {
    background-color: #151313;
  }

  .bo15-screen1, .bo15-hover-screen1:hover {
    border-color: #151313;
  }

  .f15-screen1, .f15-hover-screen1:hover {
    fill: #151313;
  }

  .c16-screen1, .c16-hover-screen1:hover {
    color: #d9d9d9;
  }

  .bg16-screen1, .bg16-hover-screen1:hover {
    background-color: #d9d9d9;
  }

  .bo16-screen1, .bo16-hover-screen1:hover {
    border-color: #d9d9d9;
  }

  .f16-screen1, .f16-hover-screen1:hover {
    fill: #d9d9d9;
  }

  .c17-screen1, .c17-hover-screen1:hover {
    color: #d3d9fc;
  }

  .bg17-screen1, .bg17-hover-screen1:hover {
    background-color: #d3d9fc;
  }

  .bo17-screen1, .bo17-hover-screen1:hover {
    border-color: #d3d9fc;
  }

  .f17-screen1, .f17-hover-screen1:hover {
    fill: #d3d9fc;
  }

  .c18-screen1, .c18-hover-screen1:hover {
    color: #6775b9;
  }

  .bg18-screen1, .bg18-hover-screen1:hover {
    background-color: #6775b9;
  }

  .bo18-screen1, .bo18-hover-screen1:hover {
    border-color: #6775b9;
  }

  .f18-screen1, .f18-hover-screen1:hover {
    fill: #6775b9;
  }

  .c19-screen1, .c19-hover-screen1:hover {
    color: #f5f6f9;
  }

  .bg19-screen1, .bg19-hover-screen1:hover {
    background-color: #f5f6f9;
  }

  .bo19-screen1, .bo19-hover-screen1:hover {
    border-color: #f5f6f9;
  }

  .f19-screen1, .f19-hover-screen1:hover {
    fill: #f5f6f9;
  }

  .c20-screen1, .c20-hover-screen1:hover {
    color: #f0f2fd;
  }

  .bg20-screen1, .bg20-hover-screen1:hover {
    background-color: #f0f2fd;
  }

  .bo20-screen1, .bo20-hover-screen1:hover {
    border-color: #f0f2fd;
  }

  .f20-screen1, .f20-hover-screen1:hover {
    fill: #f0f2fd;
  }

  .c21-screen1, .c21-hover-screen1:hover {
    color: #f9f9f9;
  }

  .bg21-screen1, .bg21-hover-screen1:hover {
    background-color: #f9f9f9;
  }

  .bo21-screen1, .bo21-hover-screen1:hover {
    border-color: #f9f9f9;
  }

  .f21-screen1, .f21-hover-screen1:hover {
    fill: #f9f9f9;
  }

  .c22-screen1, .c22-hover-screen1:hover {
    color: #f6f6f6;
  }

  .bg22-screen1, .bg22-hover-screen1:hover {
    background-color: #f6f6f6;
  }

  .bo22-screen1, .bo22-hover-screen1:hover {
    border-color: #f6f6f6;
  }

  .f22-screen1, .f22-hover-screen1:hover {
    fill: #f6f6f6;
  }

  .c23-screen1, .c23-hover-screen1:hover {
    color: #fd6474;
  }

  .bg23-screen1, .bg23-hover-screen1:hover {
    background-color: #fd6474;
  }

  .bo23-screen1, .bo23-hover-screen1:hover {
    border-color: #fd6474;
  }

  .f23-screen1, .f23-hover-screen1:hover {
    fill: #fd6474;
  }

  .c24-screen1, .c24-hover-screen1:hover {
    color: #efefef;
  }

  .bg24-screen1, .bg24-hover-screen1:hover {
    background-color: #efefef;
  }

  .bo24-screen1, .bo24-hover-screen1:hover {
    border-color: #efefef;
  }

  .f24-screen1, .f24-hover-screen1:hover {
    fill: #efefef;
  }

  .c25-screen1, .c25-hover-screen1:hover {
    color: #f8f8f8;
  }

  .bg25-screen1, .bg25-hover-screen1:hover {
    background-color: #f8f8f8;
  }

  .bo25-screen1, .bo25-hover-screen1:hover {
    border-color: #f8f8f8;
  }

  .f25-screen1, .f25-hover-screen1:hover {
    fill: #f8f8f8;
  }

  .c26-screen1, .c26-hover-screen1:hover {
    color: #0126ed;
  }

  .bg26-screen1, .bg26-hover-screen1:hover {
    background-color: #0126ed;
  }

  .bo26-screen1, .bo26-hover-screen1:hover {
    border-color: #0126ed;
  }

  .f26-screen1, .f26-hover-screen1:hover {
    fill: #0126ed;
  }

  .c27-screen1, .c27-hover-screen1:hover {
    color: #5e5f66;
  }

  .bg27-screen1, .bg27-hover-screen1:hover {
    background-color: #5e5f66;
  }

  .bo27-screen1, .bo27-hover-screen1:hover {
    border-color: #5e5f66;
  }

  .f27-screen1, .f27-hover-screen1:hover {
    fill: #5e5f66;
  }

  .c28-screen1, .c28-hover-screen1:hover {
    color: #aaacb5;
  }

  .bg28-screen1, .bg28-hover-screen1:hover {
    background-color: #aaacb5;
  }

  .bo28-screen1, .bo28-hover-screen1:hover {
    border-color: #aaacb5;
  }

  .f28-screen1, .f28-hover-screen1:hover {
    fill: #aaacb5;
  }

  .c29-screen1, .c29-hover-screen1:hover {
    color: #dde2f2;
  }

  .bg29-screen1, .bg29-hover-screen1:hover {
    background-color: #dde2f2;
  }

  .bo29-screen1, .bo29-hover-screen1:hover {
    border-color: #dde2f2;
  }

  .f29-screen1, .f29-hover-screen1:hover {
    fill: #dde2f2;
  }

  .c30-screen1, .c30-hover-screen1:hover {
    color: #09b255;
  }

  .bg30-screen1, .bg30-hover-screen1:hover {
    background-color: #09b255;
  }

  .bo30-screen1, .bo30-hover-screen1:hover {
    border-color: #09b255;
  }

  .f30-screen1, .f30-hover-screen1:hover {
    fill: #09b255;
  }

  .c31-screen1, .c31-hover-screen1:hover {
    color: #dbdfee;
  }

  .bg31-screen1, .bg31-hover-screen1:hover {
    background-color: #dbdfee;
  }

  .bo31-screen1, .bo31-hover-screen1:hover {
    border-color: #dbdfee;
  }

  .f31-screen1, .f31-hover-screen1:hover {
    fill: #dbdfee;
  }

  .c32-screen1, .c32-hover-screen1:hover {
    color: #737171;
  }

  .bg32-screen1, .bg32-hover-screen1:hover {
    background-color: #737171;
  }

  .bo32-screen1, .bo32-hover-screen1:hover {
    border-color: #737171;
  }

  .f32-screen1, .f32-hover-screen1:hover {
    fill: #737171;
  }

  .c33-screen1, .c33-hover-screen1:hover {
    color: #767b90;
  }

  .bg33-screen1, .bg33-hover-screen1:hover {
    background-color: #767b90;
  }

  .bo33-screen1, .bo33-hover-screen1:hover {
    border-color: #767b90;
  }

  .f33-screen1, .f33-hover-screen1:hover {
    fill: #767b90;
  }

  .c34-screen1, .c34-hover-screen1:hover {
    color: #15d757;
  }

  .bg34-screen1, .bg34-hover-screen1:hover {
    background-color: #15d757;
  }

  .bo34-screen1, .bo34-hover-screen1:hover {
    border-color: #15d757;
  }

  .f34-screen1, .f34-hover-screen1:hover {
    fill: #15d757;
  }

  .c35-screen1, .c35-hover-screen1:hover {
    color: #e8ebf5;
  }

  .bg35-screen1, .bg35-hover-screen1:hover {
    background-color: #e8ebf5;
  }

  .bo35-screen1, .bo35-hover-screen1:hover {
    border-color: #e8ebf5;
  }

  .f35-screen1, .f35-hover-screen1:hover {
    fill: #e8ebf5;
  }

  .c36-screen1, .c36-hover-screen1:hover {
    color: #4c6b92;
  }

  .bg36-screen1, .bg36-hover-screen1:hover {
    background-color: #4c6b92;
  }

  .bo36-screen1, .bo36-hover-screen1:hover {
    border-color: #4c6b92;
  }

  .f36-screen1, .f36-hover-screen1:hover {
    fill: #4c6b92;
  }

  .c37-screen1, .c37-hover-screen1:hover {
    color: #818181;
  }

  .bg37-screen1, .bg37-hover-screen1:hover {
    background-color: #818181;
  }

  .bo37-screen1, .bo37-hover-screen1:hover {
    border-color: #818181;
  }

  .f37-screen1, .f37-hover-screen1:hover {
    fill: #818181;
  }

  .c38-screen1, .c38-hover-screen1:hover {
    color: #f0f2fd;
  }

  .bg38-screen1, .bg38-hover-screen1:hover {
    background-color: #f0f2fd;
  }

  .bo38-screen1, .bo38-hover-screen1:hover {
    border-color: #f0f2fd;
  }

  .f38-screen1, .f38-hover-screen1:hover {
    fill: #f0f2fd;
  }

  .c39-screen1, .c39-hover-screen1:hover {
    color: #f99229;
  }

  .bg39-screen1, .bg39-hover-screen1:hover {
    background-color: #f99229;
  }

  .bo39-screen1, .bo39-hover-screen1:hover {
    border-color: #f99229;
  }

  .f39-screen1, .f39-hover-screen1:hover {
    fill: #f99229;
  }

  .c40-screen1, .c40-hover-screen1:hover {
    color: #2947ef;
  }

  .bg40-screen1, .bg40-hover-screen1:hover {
    background-color: #2947ef;
  }

  .bo40-screen1, .bo40-hover-screen1:hover {
    border-color: #2947ef;
  }

  .f40-screen1, .f40-hover-screen1:hover {
    fill: #2947ef;
  }

  .c41-screen1, .c41-hover-screen1:hover {
    color: #f5f6ff;
  }

  .bg41-screen1, .bg41-hover-screen1:hover {
    background-color: #f5f6ff;
  }

  .bo41-screen1, .bo41-hover-screen1:hover {
    border-color: #f5f6ff;
  }

  .f41-screen1, .f41-hover-screen1:hover {
    fill: #f5f6ff;
  }

  .tal-screen1 {
    text-align: left;
  }

  .tac-screen1 {
    text-align: center;
  }

  .tar-screen1 {
    text-align: right;
  }

  .taj-screen1 {
    text-align: justify;
  }

  .tas-screen1 {
    text-align: start;
  }

  .tae-screen1 {
    text-align: end;
  }

  .fs0-screen1 {
    font-size: 0;
  }

  .fs0-25rem-screen1 {
    font-size: .25rem;
  }

  .fs0-125rem-screen1 {
    font-size: .125rem;
  }

  .fs0-5rem-screen1 {
    font-size: .5rem;
  }

  .fs0-75rem-screen1 {
    font-size: .75rem;
  }

  .fs0-875rem-screen1 {
    font-size: .875rem;
  }

  .fs1rem-screen1 {
    font-size: 1rem;
  }

  .fs1-25rem-screen1 {
    font-size: 1.25rem;
  }

  .fs1-125rem-screen1 {
    font-size: 1.125rem;
  }

  .fs1-5rem-screen1 {
    font-size: 1.5rem;
  }

  .fs1-75rem-screen1 {
    font-size: 1.75rem;
  }

  .fs1-875rem-screen1 {
    font-size: 1.875rem;
  }

  .fs2rem-screen1 {
    font-size: 2rem;
  }

  .fs2-25rem-screen1 {
    font-size: 2.25rem;
  }

  .fs2-125rem-screen1 {
    font-size: 2.125rem;
  }

  .fs2-5rem-screen1 {
    font-size: 2.5rem;
  }

  .fs2-75rem-screen1 {
    font-size: 2.75rem;
  }

  .fs2-875rem-screen1 {
    font-size: 2.875rem;
  }

  .fs3rem-screen1 {
    font-size: 3rem;
  }

  .fs3-25rem-screen1 {
    font-size: 3.25rem;
  }

  .fs3-125rem-screen1 {
    font-size: 3.125rem;
  }

  .fs3-5rem-screen1 {
    font-size: 3.5rem;
  }

  .fs3-75rem-screen1 {
    font-size: 3.75rem;
  }

  .fs3-875rem-screen1 {
    font-size: 3.875rem;
  }

  .fs4rem-screen1 {
    font-size: 4rem;
  }

  .fs4-25rem-screen1 {
    font-size: 4.25rem;
  }

  .fs4-125rem-screen1 {
    font-size: 4.125rem;
  }

  .fs4-5rem-screen1 {
    font-size: 4.5rem;
  }

  .fs4-75rem-screen1 {
    font-size: 4.75rem;
  }

  .fs4-875rem-screen1 {
    font-size: 4.875rem;
  }

  .fs5rem-screen1 {
    font-size: 5rem;
  }

  .fs5-25rem-screen1 {
    font-size: 5.25rem;
  }

  .fs5-125rem-screen1 {
    font-size: 5.125rem;
  }

  .fs5-5rem-screen1 {
    font-size: 5.5rem;
  }

  .fs5-75rem-screen1 {
    font-size: 5.75rem;
  }

  .fs5-875rem-screen1 {
    font-size: 5.875rem;
  }

  .fs6rem-screen1 {
    font-size: 6rem;
  }

  .fs6-25rem-screen1 {
    font-size: 6.25rem;
  }

  .fs6-125rem-screen1 {
    font-size: 6.125rem;
  }

  .fs6-5rem-screen1 {
    font-size: 6.5rem;
  }

  .fs6-75rem-screen1 {
    font-size: 6.75rem;
  }

  .fs6-875rem-screen1 {
    font-size: 6.875rem;
  }

  .fs7rem-screen1 {
    font-size: 7rem;
  }

  .db-screen1 {
    display: block;
  }

  .dib-screen1 {
    display: inline-block;
  }

  .di-screen1 {
    display: inline;
  }

  .df-screen1 {
    display: flex;
  }

  .fdc-screen1 {
    flex-direction: column;
  }

  .fdr-screen1 {
    flex-direction: row;
  }

  .fdcr-screen1 {
    flex-direction: column-reverse;
  }

  .fdrr-screen1 {
    flex-direction: row-reverse;
  }

  .fww-screen1 {
    flex-wrap: wrap;
  }

  .jcfs-screen1 {
    justify-content: flex-start;
  }

  .jcc-screen1 {
    justify-content: center;
  }

  .jcfe-screen1 {
    justify-content: flex-end;
  }

  .jcsb-screen1 {
    justify-content: space-between;
  }

  .jcsa-screen1 {
    justify-content: space-around;
  }

  .jcse-screen1 {
    justify-content: space-evenly;
  }

  .jce-screen1 {
    justify-content: end;
  }

  .aifs-screen1 {
    align-items: flex-start;
  }

  .aic-screen1 {
    align-items: center;
  }

  .aife-screen1 {
    align-items: flex-end;
  }

  .jss-screen1 {
    justify-self: start;
  }

  .jsfs-screen1 {
    justify-self: flex-start;
  }

  .jsc-screen1 {
    justify-self: center;
  }

  .jsfe-screen1 {
    justify-self: flex-end;
  }

  .jse-screen1 {
    justify-self: end;
  }

  .ass-screen1 {
    align-self: start;
  }

  .asfs-screen1 {
    align-self: flex-start;
  }

  .asc-screen1 {
    align-self: center;
  }

  .asfe-screen1 {
    align-self: flex-end;
  }

  .ase-screen1 {
    align-self: end;
  }

  .dif-screen1 {
    display: inline-flex;
  }

  .dg-screen1 {
    display: grid;
  }

  .brss-screen1 {
    border-right-style: solid;
  }

  .brw1px-screen1 {
    border-right-width: 1px;
  }

  .brw2px-screen1 {
    border-right-width: 2px;
  }

  .gtcr1-screen1 {
    grid-template-columns: repeat(1, 1fr);
  }

  .ftcr1-screen1 {
    grid-column: 1 / 1fr;
  }

  .gtrr1-screen1 {
    grid-template-rows: repeat(1, 1fr);
  }

  .gtcr1a-screen1 {
    grid-template-columns: repeat(1, auto);
  }

  .gtrr1a-screen1 {
    grid-template-rows: repeat(1, auto);
  }

  .gcs1-screen1 {
    grid-column-start: 1;
  }

  .gce2-screen1 {
    grid-column-end: 2;
  }

  .grs1-screen1 {
    grid-row-start: 1;
  }

  .gre2-screen1 {
    grid-row-end: 2;
  }

  .gtcr2-screen1 {
    grid-template-columns: repeat(2, 1fr);
  }

  .ftcr2-screen1 {
    grid-column: 2 / 1fr;
  }

  .gtrr2-screen1 {
    grid-template-rows: repeat(2, 1fr);
  }

  .gtcr2a-screen1 {
    grid-template-columns: repeat(2, auto);
  }

  .gtrr2a-screen1 {
    grid-template-rows: repeat(2, auto);
  }

  .gcs2-screen1 {
    grid-column-start: 2;
  }

  .gce3-screen1 {
    grid-column-end: 3;
  }

  .grs2-screen1 {
    grid-row-start: 2;
  }

  .gre3-screen1 {
    grid-row-end: 3;
  }

  .gtcr3-screen1 {
    grid-template-columns: repeat(3, 1fr);
  }

  .ftcr3-screen1 {
    grid-column: 3 / 1fr;
  }

  .gtrr3-screen1 {
    grid-template-rows: repeat(3, 1fr);
  }

  .gtcr3a-screen1 {
    grid-template-columns: repeat(3, auto);
  }

  .gtrr3a-screen1 {
    grid-template-rows: repeat(3, auto);
  }

  .gcs3-screen1 {
    grid-column-start: 3;
  }

  .gce4-screen1 {
    grid-column-end: 4;
  }

  .grs3-screen1 {
    grid-row-start: 3;
  }

  .gre4-screen1 {
    grid-row-end: 4;
  }

  .gtcr4-screen1 {
    grid-template-columns: repeat(4, 1fr);
  }

  .ftcr4-screen1 {
    grid-column: 4 / 1fr;
  }

  .gtrr4-screen1 {
    grid-template-rows: repeat(4, 1fr);
  }

  .gtcr4a-screen1 {
    grid-template-columns: repeat(4, auto);
  }

  .gtrr4a-screen1 {
    grid-template-rows: repeat(4, auto);
  }

  .gcs4-screen1 {
    grid-column-start: 4;
  }

  .gce5-screen1 {
    grid-column-end: 5;
  }

  .grs4-screen1 {
    grid-row-start: 4;
  }

  .gre5-screen1 {
    grid-row-end: 5;
  }

  .gtcr5-screen1 {
    grid-template-columns: repeat(5, 1fr);
  }

  .ftcr5-screen1 {
    grid-column: 5 / 1fr;
  }

  .gtrr5-screen1 {
    grid-template-rows: repeat(5, 1fr);
  }

  .gtcr5a-screen1 {
    grid-template-columns: repeat(5, auto);
  }

  .gtrr5a-screen1 {
    grid-template-rows: repeat(5, auto);
  }

  .gcs5-screen1 {
    grid-column-start: 5;
  }

  .gce6-screen1 {
    grid-column-end: 6;
  }

  .grs5-screen1 {
    grid-row-start: 5;
  }

  .gre6-screen1 {
    grid-row-end: 6;
  }

  .gtcr6-screen1 {
    grid-template-columns: repeat(6, 1fr);
  }

  .ftcr6-screen1 {
    grid-column: 6 / 1fr;
  }

  .gtrr6-screen1 {
    grid-template-rows: repeat(6, 1fr);
  }

  .gtcr6a-screen1 {
    grid-template-columns: repeat(6, auto);
  }

  .gtrr6a-screen1 {
    grid-template-rows: repeat(6, auto);
  }

  .gcs6-screen1 {
    grid-column-start: 6;
  }

  .gce7-screen1 {
    grid-column-end: 7;
  }

  .grs6-screen1 {
    grid-row-start: 6;
  }

  .gre7-screen1 {
    grid-row-end: 7;
  }

  .gtcr7-screen1 {
    grid-template-columns: repeat(7, 1fr);
  }

  .ftcr7-screen1 {
    grid-column: 7 / 1fr;
  }

  .gtrr7-screen1 {
    grid-template-rows: repeat(7, 1fr);
  }

  .gtcr7a-screen1 {
    grid-template-columns: repeat(7, auto);
  }

  .gtrr7a-screen1 {
    grid-template-rows: repeat(7, auto);
  }

  .gcs7-screen1 {
    grid-column-start: 7;
  }

  .gce8-screen1 {
    grid-column-end: 8;
  }

  .grs7-screen1 {
    grid-row-start: 7;
  }

  .gre8-screen1 {
    grid-row-end: 8;
  }

  .gtcr8-screen1 {
    grid-template-columns: repeat(8, 1fr);
  }

  .ftcr8-screen1 {
    grid-column: 8 / 1fr;
  }

  .gtrr8-screen1 {
    grid-template-rows: repeat(8, 1fr);
  }

  .gtcr8a-screen1 {
    grid-template-columns: repeat(8, auto);
  }

  .gtrr8a-screen1 {
    grid-template-rows: repeat(8, auto);
  }

  .gcs8-screen1 {
    grid-column-start: 8;
  }

  .gce9-screen1 {
    grid-column-end: 9;
  }

  .grs8-screen1 {
    grid-row-start: 8;
  }

  .gre9-screen1 {
    grid-row-end: 9;
  }

  .gtcr9-screen1 {
    grid-template-columns: repeat(9, 1fr);
  }

  .ftcr9-screen1 {
    grid-column: 9 / 1fr;
  }

  .gtrr9-screen1 {
    grid-template-rows: repeat(9, 1fr);
  }

  .gtcr9a-screen1 {
    grid-template-columns: repeat(9, auto);
  }

  .gtrr9a-screen1 {
    grid-template-rows: repeat(9, auto);
  }

  .gcs9-screen1 {
    grid-column-start: 9;
  }

  .gce10-screen1 {
    grid-column-end: 10;
  }

  .grs9-screen1 {
    grid-row-start: 9;
  }

  .gre10-screen1 {
    grid-row-end: 10;
  }

  .gtccr-screen1 {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }

  .gtcr10-screen1 {
    grid-template-columns: repeat(10, 1fr);
  }

  .ftcr10-screen1 {
    grid-column: 10 / 1fr;
  }

  .gtrr10-screen1 {
    grid-template-rows: repeat(10, 1fr);
  }

  .gtcr10a-screen1 {
    grid-template-columns: repeat(10, auto);
  }

  .gtrr10a-screen1 {
    grid-template-rows: repeat(10, auto);
  }

  .gcs10-screen1 {
    grid-column-start: 10;
  }

  .gce11-screen1 {
    grid-column-end: 11;
  }

  .grs10-screen1 {
    grid-row-start: 10;
  }

  .gre11-screen1 {
    grid-row-end: 11;
  }

  .gg0-5rem-screen1 {
    grid-gap: .5rem;
  }

  .gg1rem-screen1 {
    grid-gap: 1rem;
  }

  .rg0-5rem-screen1 {
    row-gap: .5rem;
  }

  .rg1rem-screen1 {
    row-gap: 1rem;
  }

  .cg0-5rem-screen1 {
    column-gap: .5rem;
  }

  .cg1rem-screen1 {
    column-gap: 1rem;
  }

  .gg1-5rem-screen1 {
    grid-gap: 1.5rem;
  }

  .gg2rem-screen1 {
    grid-gap: 2rem;
  }

  .rg1-5rem-screen1 {
    row-gap: 1.5rem;
  }

  .rg2rem-screen1 {
    row-gap: 2rem;
  }

  .cg1-5rem-screen1 {
    column-gap: 1.5rem;
  }

  .cg2rem-screen1 {
    column-gap: 2rem;
  }

  .gg2-5rem-screen1 {
    grid-gap: 2.5rem;
  }

  .gg3rem-screen1 {
    grid-gap: 3rem;
  }

  .rg2-5rem-screen1 {
    row-gap: 2.5rem;
  }

  .rg3rem-screen1 {
    row-gap: 3rem;
  }

  .cg2-5rem-screen1 {
    column-gap: 2.5rem;
  }

  .cg3rem-screen1 {
    column-gap: 3rem;
  }

  .gg3-5rem-screen1 {
    grid-gap: 3.5rem;
  }

  .gg4rem-screen1 {
    grid-gap: 4rem;
  }

  .rg3-5rem-screen1 {
    row-gap: 3.5rem;
  }

  .rg4rem-screen1 {
    row-gap: 4rem;
  }

  .cg3-5rem-screen1 {
    column-gap: 3.5rem;
  }

  .cg4rem-screen1 {
    column-gap: 4rem;
  }

  .gg4-5rem-screen1 {
    grid-gap: 4.5rem;
  }

  .gg5rem-screen1 {
    grid-gap: 5rem;
  }

  .rg4-5rem-screen1 {
    row-gap: 4.5rem;
  }

  .rg5rem-screen1 {
    row-gap: 5rem;
  }

  .cg4-5rem-screen1 {
    column-gap: 4.5rem;
  }

  .cg5rem-screen1 {
    column-gap: 5rem;
  }

  .dn-screen1 {
    display: none;
  }

  .pa-screen1 {
    position: absolute;
  }

  .pr-screen1 {
    position: relative;
  }

  .pf-screen1 {
    position: fixed;
  }

  .ps-screen1 {
    -webkit-position: sticky;
    position: sticky;
  }

  .pst-screen1 {
    position: static;
  }

  .t0-screen1 {
    top: 0;
  }

  .r0-screen1 {
    right: 0;
  }

  .b0-screen1 {
    bottom: 0;
  }

  .l0-screen1 {
    left: 0;
  }

  .t100-screen1 {
    top: 100%;
  }

  .r100-screen1 {
    right: 100%;
  }

  .b100-screen1 {
    bottom: 100%;
  }

  .l100-screen1 {
    left: 100%;
  }

  .fl-screen1 {
    float: left;
  }

  .fr-screen1 {
    float: right;
  }

  .fn-screen1 {
    float: none;
  }

  .ma-screen1 {
    margin: auto;
  }

  .mta-screen1, .mya-screen1 {
    margin-top: auto;
  }

  .mra-screen1, .mxa-screen1 {
    margin-right: auto;
  }

  .mba-screen1, .mya-screen1 {
    margin-bottom: auto;
  }

  .mla-screen1, .mxa-screen1 {
    margin-left: auto;
  }

  .m0-screen1 {
    margin: 0;
  }

  .mt0-screen1, .my0-screen1 {
    margin-top: 0;
  }

  .mr0-screen1, .mx0-screen1 {
    margin-right: 0;
  }

  .mb0-screen1, .my0-screen1 {
    margin-bottom: 0;
  }

  .ml0-screen1, .mx0-screen1 {
    margin-left: 0;
  }

  .p0-screen1 {
    padding: 0;
  }

  .pt0-screen1, .py0-screen1 {
    padding-top: 0;
  }

  .pr0-screen1, .px0-screen1 {
    padding-right: 0;
  }

  .pb0-screen1, .py0-screen1 {
    padding-bottom: 0;
  }

  .pl0-screen1, .px0-screen1 {
    padding-left: 0;
  }

  .m10-screen1 {
    margin: 10%;
  }

  .mt10-screen1, .my10-screen1 {
    margin-top: 10%;
  }

  .mr10-screen1, .mx10-screen1 {
    margin-right: 10%;
  }

  .mb10-screen1, .my10-screen1 {
    margin-bottom: 10%;
  }

  .ml10-screen1, .mx10-screen1 {
    margin-left: 10%;
  }

  .p10-screen1 {
    padding: 10%;
  }

  .pt10-screen1, .py10-screen1 {
    padding-top: 10%;
  }

  .pr10-screen1, .px10-screen1 {
    padding-right: 10%;
  }

  .pb10-screen1, .py10-screen1 {
    padding-bottom: 10%;
  }

  .pl10-screen1, .px10-screen1 {
    padding-left: 10%;
  }

  .m0-5rem-screen1 {
    margin: .5rem;
  }

  .m1rem-screen1 {
    margin: 1rem;
  }

  .mt0-5rem-screen1, .my0-5rem-screen1 {
    margin-top: .5rem;
  }

  .mt1rem-screen1, .my1rem-screen1 {
    margin-top: 1rem;
  }

  .mr0-5rem-screen1, .mx0-5rem-screen1 {
    margin-right: .5rem;
  }

  .mr1rem-screen1, .mx1rem-screen1 {
    margin-right: 1rem;
  }

  .mb0-5rem-screen1, .my0-5rem-screen1 {
    margin-bottom: .5rem;
  }

  .mb1rem-screen1, .my1rem-screen1 {
    margin-bottom: 1rem;
  }

  .ml0-5rem-screen1, .mx0-5rem-screen1 {
    margin-left: .5rem;
  }

  .ml1rem-screen1, .mx1rem-screen1 {
    margin-left: 1rem;
  }

  .p0-5rem-screen1 {
    padding: .5rem;
  }

  .p1rem-screen1 {
    padding: 1rem;
  }

  .pt0-5rem-screen1, .py0-5rem-screen1 {
    padding-top: .5rem;
  }

  .pt1rem-screen1, .py1rem-screen1 {
    padding-top: 1rem;
  }

  .pr0-5rem-screen1, .px0-5rem-screen1 {
    padding-right: .5rem;
  }

  .pr1rem-screen1, .px1rem-screen1 {
    padding-right: 1rem;
  }

  .pb0-5rem-screen1, .py0-5rem-screen1 {
    padding-bottom: .5rem;
  }

  .pb1rem-screen1, .py1rem-screen1 {
    padding-bottom: 1rem;
  }

  .pl0-5rem-screen1, .px0-5rem-screen1 {
    padding-left: .5rem;
  }

  .pl1rem-screen1, .px1rem-screen1 {
    padding-left: 1rem;
  }

  .m20-screen1 {
    margin: 20%;
  }

  .mt20-screen1, .my20-screen1 {
    margin-top: 20%;
  }

  .mr20-screen1, .mx20-screen1 {
    margin-right: 20%;
  }

  .mb20-screen1, .my20-screen1 {
    margin-bottom: 20%;
  }

  .ml20-screen1, .mx20-screen1 {
    margin-left: 20%;
  }

  .p20-screen1 {
    padding: 20%;
  }

  .pt20-screen1, .py20-screen1 {
    padding-top: 20%;
  }

  .pr20-screen1, .px20-screen1 {
    padding-right: 20%;
  }

  .pb20-screen1, .py20-screen1 {
    padding-bottom: 20%;
  }

  .pl20-screen1, .px20-screen1 {
    padding-left: 20%;
  }

  .m1-5rem-screen1 {
    margin: 1.5rem;
  }

  .m2rem-screen1 {
    margin: 2rem;
  }

  .mt1-5rem-screen1, .my1-5rem-screen1 {
    margin-top: 1.5rem;
  }

  .mt2rem-screen1, .my2rem-screen1 {
    margin-top: 2rem;
  }

  .mr1-5rem-screen1, .mx1-5rem-screen1 {
    margin-right: 1.5rem;
  }

  .mr2rem-screen1, .mx2rem-screen1 {
    margin-right: 2rem;
  }

  .mb1-5rem-screen1, .my1-5rem-screen1 {
    margin-bottom: 1.5rem;
  }

  .mb2rem-screen1, .my2rem-screen1 {
    margin-bottom: 2rem;
  }

  .ml1-5rem-screen1, .mx1-5rem-screen1 {
    margin-left: 1.5rem;
  }

  .ml2rem-screen1, .mx2rem-screen1 {
    margin-left: 2rem;
  }

  .p1-5rem-screen1 {
    padding: 1.5rem;
  }

  .p2rem-screen1 {
    padding: 2rem;
  }

  .pt1-5rem-screen1, .py1-5rem-screen1 {
    padding-top: 1.5rem;
  }

  .pt2rem-screen1, .py2rem-screen1 {
    padding-top: 2rem;
  }

  .pr1-5rem-screen1, .px1-5rem-screen1 {
    padding-right: 1.5rem;
  }

  .pr2rem-screen1, .px2rem-screen1 {
    padding-right: 2rem;
  }

  .pb1-5rem-screen1, .py1-5rem-screen1 {
    padding-bottom: 1.5rem;
  }

  .pb2rem-screen1, .py2rem-screen1 {
    padding-bottom: 2rem;
  }

  .pl1-5rem-screen1, .px1-5rem-screen1 {
    padding-left: 1.5rem;
  }

  .pl2rem-screen1, .px2rem-screen1 {
    padding-left: 2rem;
  }

  .m30-screen1 {
    margin: 30%;
  }

  .mt30-screen1, .my30-screen1 {
    margin-top: 30%;
  }

  .mr30-screen1, .mx30-screen1 {
    margin-right: 30%;
  }

  .mb30-screen1, .my30-screen1 {
    margin-bottom: 30%;
  }

  .ml30-screen1, .mx30-screen1 {
    margin-left: 30%;
  }

  .p30-screen1 {
    padding: 30%;
  }

  .pt30-screen1, .py30-screen1 {
    padding-top: 30%;
  }

  .pr30-screen1, .px30-screen1 {
    padding-right: 30%;
  }

  .pb30-screen1, .py30-screen1 {
    padding-bottom: 30%;
  }

  .pl30-screen1, .px30-screen1 {
    padding-left: 30%;
  }

  .m2-5rem-screen1 {
    margin: 2.5rem;
  }

  .m3rem-screen1 {
    margin: 3rem;
  }

  .mt2-5rem-screen1, .my2-5rem-screen1 {
    margin-top: 2.5rem;
  }

  .mt3rem-screen1, .my3rem-screen1 {
    margin-top: 3rem;
  }

  .mr2-5rem-screen1, .mx2-5rem-screen1 {
    margin-right: 2.5rem;
  }

  .mr3rem-screen1, .mx3rem-screen1 {
    margin-right: 3rem;
  }

  .mb2-5rem-screen1, .my2-5rem-screen1 {
    margin-bottom: 2.5rem;
  }

  .mb3rem-screen1, .my3rem-screen1 {
    margin-bottom: 3rem;
  }

  .ml2-5rem-screen1, .mx2-5rem-screen1 {
    margin-left: 2.5rem;
  }

  .ml3rem-screen1, .mx3rem-screen1 {
    margin-left: 3rem;
  }

  .p2-5rem-screen1 {
    padding: 2.5rem;
  }

  .p3rem-screen1 {
    padding: 3rem;
  }

  .pt2-5rem-screen1, .py2-5rem-screen1 {
    padding-top: 2.5rem;
  }

  .pt3rem-screen1, .py3rem-screen1 {
    padding-top: 3rem;
  }

  .pr2-5rem-screen1, .px2-5rem-screen1 {
    padding-right: 2.5rem;
  }

  .pr3rem-screen1, .px3rem-screen1 {
    padding-right: 3rem;
  }

  .pb2-5rem-screen1, .py2-5rem-screen1 {
    padding-bottom: 2.5rem;
  }

  .pb3rem-screen1, .py3rem-screen1 {
    padding-bottom: 3rem;
  }

  .pl2-5rem-screen1, .px2-5rem-screen1 {
    padding-left: 2.5rem;
  }

  .pl3rem-screen1, .px3rem-screen1 {
    padding-left: 3rem;
  }

  .m40-screen1 {
    margin: 40%;
  }

  .mt40-screen1, .my40-screen1 {
    margin-top: 40%;
  }

  .mr40-screen1, .mx40-screen1 {
    margin-right: 40%;
  }

  .mb40-screen1, .my40-screen1 {
    margin-bottom: 40%;
  }

  .ml40-screen1, .mx40-screen1 {
    margin-left: 40%;
  }

  .p40-screen1 {
    padding: 40%;
  }

  .pt40-screen1, .py40-screen1 {
    padding-top: 40%;
  }

  .pr40-screen1, .px40-screen1 {
    padding-right: 40%;
  }

  .pb40-screen1, .py40-screen1 {
    padding-bottom: 40%;
  }

  .pl40-screen1, .px40-screen1 {
    padding-left: 40%;
  }

  .m3-5rem-screen1 {
    margin: 3.5rem;
  }

  .m4rem-screen1 {
    margin: 4rem;
  }

  .mt3-5rem-screen1, .my3-5rem-screen1 {
    margin-top: 3.5rem;
  }

  .mt4rem-screen1, .my4rem-screen1 {
    margin-top: 4rem;
  }

  .mr3-5rem-screen1, .mx3-5rem-screen1 {
    margin-right: 3.5rem;
  }

  .mr4rem-screen1, .mx4rem-screen1 {
    margin-right: 4rem;
  }

  .mb3-5rem-screen1, .my3-5rem-screen1 {
    margin-bottom: 3.5rem;
  }

  .mb4rem-screen1, .my4rem-screen1 {
    margin-bottom: 4rem;
  }

  .ml3-5rem-screen1, .mx3-5rem-screen1 {
    margin-left: 3.5rem;
  }

  .ml4rem-screen1, .mx4rem-screen1 {
    margin-left: 4rem;
  }

  .p3-5rem-screen1 {
    padding: 3.5rem;
  }

  .p4rem-screen1 {
    padding: 4rem;
  }

  .pt3-5rem-screen1, .py3-5rem-screen1 {
    padding-top: 3.5rem;
  }

  .pt4rem-screen1, .py4rem-screen1 {
    padding-top: 4rem;
  }

  .pr3-5rem-screen1, .px3-5rem-screen1 {
    padding-right: 3.5rem;
  }

  .pr4rem-screen1, .px4rem-screen1 {
    padding-right: 4rem;
  }

  .pb3-5rem-screen1, .py3-5rem-screen1 {
    padding-bottom: 3.5rem;
  }

  .pb4rem-screen1, .py4rem-screen1 {
    padding-bottom: 4rem;
  }

  .pl3-5rem-screen1, .px3-5rem-screen1 {
    padding-left: 3.5rem;
  }

  .pl4rem-screen1, .px4rem-screen1 {
    padding-left: 4rem;
  }

  .m50-screen1 {
    margin: 50%;
  }

  .mt50-screen1, .my50-screen1 {
    margin-top: 50%;
  }

  .mr50-screen1, .mx50-screen1 {
    margin-right: 50%;
  }

  .mb50-screen1, .my50-screen1 {
    margin-bottom: 50%;
  }

  .ml50-screen1, .mx50-screen1 {
    margin-left: 50%;
  }

  .p50-screen1 {
    padding: 50%;
  }

  .pt50-screen1, .py50-screen1 {
    padding-top: 50%;
  }

  .pr50-screen1, .px50-screen1 {
    padding-right: 50%;
  }

  .pb50-screen1, .py50-screen1 {
    padding-bottom: 50%;
  }

  .pl50-screen1, .px50-screen1 {
    padding-left: 50%;
  }

  .m4-5rem-screen1 {
    margin: 4.5rem;
  }

  .m5rem-screen1 {
    margin: 5rem;
  }

  .mt4-5rem-screen1, .my4-5rem-screen1 {
    margin-top: 4.5rem;
  }

  .mt5rem-screen1, .my5rem-screen1 {
    margin-top: 5rem;
  }

  .mr4-5rem-screen1, .mx4-5rem-screen1 {
    margin-right: 4.5rem;
  }

  .mr5rem-screen1, .mx5rem-screen1 {
    margin-right: 5rem;
  }

  .mb4-5rem-screen1, .my4-5rem-screen1 {
    margin-bottom: 4.5rem;
  }

  .mb5rem-screen1, .my5rem-screen1 {
    margin-bottom: 5rem;
  }

  .ml4-5rem-screen1, .mx4-5rem-screen1 {
    margin-left: 4.5rem;
  }

  .ml5rem-screen1, .mx5rem-screen1 {
    margin-left: 5rem;
  }

  .p4-5rem-screen1 {
    padding: 4.5rem;
  }

  .p5rem-screen1 {
    padding: 5rem;
  }

  .pt4-5rem-screen1, .py4-5rem-screen1 {
    padding-top: 4.5rem;
  }

  .pt5rem-screen1, .py5rem-screen1 {
    padding-top: 5rem;
  }

  .pr4-5rem-screen1, .px4-5rem-screen1 {
    padding-right: 4.5rem;
  }

  .pr5rem-screen1, .px5rem-screen1 {
    padding-right: 5rem;
  }

  .pb4-5rem-screen1, .py4-5rem-screen1 {
    padding-bottom: 4.5rem;
  }

  .pb5rem-screen1, .py5rem-screen1 {
    padding-bottom: 5rem;
  }

  .pl4-5rem-screen1, .px4-5rem-screen1 {
    padding-left: 4.5rem;
  }

  .pl5rem-screen1, .px5rem-screen1 {
    padding-left: 5rem;
  }

  .m60-screen1 {
    margin: 60%;
  }

  .mt60-screen1, .my60-screen1 {
    margin-top: 60%;
  }

  .mr60-screen1, .mx60-screen1 {
    margin-right: 60%;
  }

  .mb60-screen1, .my60-screen1 {
    margin-bottom: 60%;
  }

  .ml60-screen1, .mx60-screen1 {
    margin-left: 60%;
  }

  .p60-screen1 {
    padding: 60%;
  }

  .pt60-screen1, .py60-screen1 {
    padding-top: 60%;
  }

  .pr60-screen1, .px60-screen1 {
    padding-right: 60%;
  }

  .pb60-screen1, .py60-screen1 {
    padding-bottom: 60%;
  }

  .pl60-screen1, .px60-screen1 {
    padding-left: 60%;
  }

  .m5-5rem-screen1 {
    margin: 5.5rem;
  }

  .m6rem-screen1 {
    margin: 6rem;
  }

  .mt5-5rem-screen1, .my5-5rem-screen1 {
    margin-top: 5.5rem;
  }

  .mt6rem-screen1, .my6rem-screen1 {
    margin-top: 6rem;
  }

  .mr5-5rem-screen1, .mx5-5rem-screen1 {
    margin-right: 5.5rem;
  }

  .mr6rem-screen1, .mx6rem-screen1 {
    margin-right: 6rem;
  }

  .mb5-5rem-screen1, .my5-5rem-screen1 {
    margin-bottom: 5.5rem;
  }

  .mb6rem-screen1, .my6rem-screen1 {
    margin-bottom: 6rem;
  }

  .ml5-5rem-screen1, .mx5-5rem-screen1 {
    margin-left: 5.5rem;
  }

  .ml6rem-screen1, .mx6rem-screen1 {
    margin-left: 6rem;
  }

  .p5-5rem-screen1 {
    padding: 5.5rem;
  }

  .p6rem-screen1 {
    padding: 6rem;
  }

  .pt5-5rem-screen1, .py5-5rem-screen1 {
    padding-top: 5.5rem;
  }

  .pt6rem-screen1, .py6rem-screen1 {
    padding-top: 6rem;
  }

  .pr5-5rem-screen1, .px5-5rem-screen1 {
    padding-right: 5.5rem;
  }

  .pr6rem-screen1, .px6rem-screen1 {
    padding-right: 6rem;
  }

  .pb5-5rem-screen1, .py5-5rem-screen1 {
    padding-bottom: 5.5rem;
  }

  .pb6rem-screen1, .py6rem-screen1 {
    padding-bottom: 6rem;
  }

  .pl5-5rem-screen1, .px5-5rem-screen1 {
    padding-left: 5.5rem;
  }

  .pl6rem-screen1, .px6rem-screen1 {
    padding-left: 6rem;
  }

  .m70-screen1 {
    margin: 70%;
  }

  .mt70-screen1, .my70-screen1 {
    margin-top: 70%;
  }

  .mr70-screen1, .mx70-screen1 {
    margin-right: 70%;
  }

  .mb70-screen1, .my70-screen1 {
    margin-bottom: 70%;
  }

  .ml70-screen1, .mx70-screen1 {
    margin-left: 70%;
  }

  .p70-screen1 {
    padding: 70%;
  }

  .pt70-screen1, .py70-screen1 {
    padding-top: 70%;
  }

  .pr70-screen1, .px70-screen1 {
    padding-right: 70%;
  }

  .pb70-screen1, .py70-screen1 {
    padding-bottom: 70%;
  }

  .pl70-screen1, .px70-screen1 {
    padding-left: 70%;
  }

  .m6-5rem-screen1 {
    margin: 6.5rem;
  }

  .m7rem-screen1 {
    margin: 7rem;
  }

  .mt6-5rem-screen1, .my6-5rem-screen1 {
    margin-top: 6.5rem;
  }

  .mt7rem-screen1, .my7rem-screen1 {
    margin-top: 7rem;
  }

  .mr6-5rem-screen1, .mx6-5rem-screen1 {
    margin-right: 6.5rem;
  }

  .mr7rem-screen1, .mx7rem-screen1 {
    margin-right: 7rem;
  }

  .mb6-5rem-screen1, .my6-5rem-screen1 {
    margin-bottom: 6.5rem;
  }

  .mb7rem-screen1, .my7rem-screen1 {
    margin-bottom: 7rem;
  }

  .ml6-5rem-screen1, .mx6-5rem-screen1 {
    margin-left: 6.5rem;
  }

  .ml7rem-screen1, .mx7rem-screen1 {
    margin-left: 7rem;
  }

  .p6-5rem-screen1 {
    padding: 6.5rem;
  }

  .p7rem-screen1 {
    padding: 7rem;
  }

  .pt6-5rem-screen1, .py6-5rem-screen1 {
    padding-top: 6.5rem;
  }

  .pt7rem-screen1, .py7rem-screen1 {
    padding-top: 7rem;
  }

  .pr6-5rem-screen1, .px6-5rem-screen1 {
    padding-right: 6.5rem;
  }

  .pr7rem-screen1, .px7rem-screen1 {
    padding-right: 7rem;
  }

  .pb6-5rem-screen1, .py6-5rem-screen1 {
    padding-bottom: 6.5rem;
  }

  .pb7rem-screen1, .py7rem-screen1 {
    padding-bottom: 7rem;
  }

  .pl6-5rem-screen1, .px6-5rem-screen1 {
    padding-left: 6.5rem;
  }

  .pl7rem-screen1, .px7rem-screen1 {
    padding-left: 7rem;
  }

  .m80-screen1 {
    margin: 80%;
  }

  .mt80-screen1, .my80-screen1 {
    margin-top: 80%;
  }

  .mr80-screen1, .mx80-screen1 {
    margin-right: 80%;
  }

  .mb80-screen1, .my80-screen1 {
    margin-bottom: 80%;
  }

  .ml80-screen1, .mx80-screen1 {
    margin-left: 80%;
  }

  .p80-screen1 {
    padding: 80%;
  }

  .pt80-screen1, .py80-screen1 {
    padding-top: 80%;
  }

  .pr80-screen1, .px80-screen1 {
    padding-right: 80%;
  }

  .pb80-screen1, .py80-screen1 {
    padding-bottom: 80%;
  }

  .pl80-screen1, .px80-screen1 {
    padding-left: 80%;
  }

  .m7-5rem-screen1 {
    margin: 7.5rem;
  }

  .m8rem-screen1 {
    margin: 8rem;
  }

  .mt7-5rem-screen1, .my7-5rem-screen1 {
    margin-top: 7.5rem;
  }

  .mt8rem-screen1, .my8rem-screen1 {
    margin-top: 8rem;
  }

  .mr7-5rem-screen1, .mx7-5rem-screen1 {
    margin-right: 7.5rem;
  }

  .mr8rem-screen1, .mx8rem-screen1 {
    margin-right: 8rem;
  }

  .mb7-5rem-screen1, .my7-5rem-screen1 {
    margin-bottom: 7.5rem;
  }

  .mb8rem-screen1, .my8rem-screen1 {
    margin-bottom: 8rem;
  }

  .ml7-5rem-screen1, .mx7-5rem-screen1 {
    margin-left: 7.5rem;
  }

  .ml8rem-screen1, .mx8rem-screen1 {
    margin-left: 8rem;
  }

  .p7-5rem-screen1 {
    padding: 7.5rem;
  }

  .p8rem-screen1 {
    padding: 8rem;
  }

  .pt7-5rem-screen1, .py7-5rem-screen1 {
    padding-top: 7.5rem;
  }

  .pt8rem-screen1, .py8rem-screen1 {
    padding-top: 8rem;
  }

  .pr7-5rem-screen1, .px7-5rem-screen1 {
    padding-right: 7.5rem;
  }

  .pr8rem-screen1, .px8rem-screen1 {
    padding-right: 8rem;
  }

  .pb7-5rem-screen1, .py7-5rem-screen1 {
    padding-bottom: 7.5rem;
  }

  .pb8rem-screen1, .py8rem-screen1 {
    padding-bottom: 8rem;
  }

  .pl7-5rem-screen1, .px7-5rem-screen1 {
    padding-left: 7.5rem;
  }

  .pl8rem-screen1, .px8rem-screen1 {
    padding-left: 8rem;
  }

  .m90-screen1 {
    margin: 90%;
  }

  .mt90-screen1, .my90-screen1 {
    margin-top: 90%;
  }

  .mr90-screen1, .mx90-screen1 {
    margin-right: 90%;
  }

  .mb90-screen1, .my90-screen1 {
    margin-bottom: 90%;
  }

  .ml90-screen1, .mx90-screen1 {
    margin-left: 90%;
  }

  .p90-screen1 {
    padding: 90%;
  }

  .pt90-screen1, .py90-screen1 {
    padding-top: 90%;
  }

  .pr90-screen1, .px90-screen1 {
    padding-right: 90%;
  }

  .pb90-screen1, .py90-screen1 {
    padding-bottom: 90%;
  }

  .pl90-screen1, .px90-screen1 {
    padding-left: 90%;
  }

  .m8-5rem-screen1 {
    margin: 8.5rem;
  }

  .m9rem-screen1 {
    margin: 9rem;
  }

  .mt8-5rem-screen1, .my8-5rem-screen1 {
    margin-top: 8.5rem;
  }

  .mt9rem-screen1, .my9rem-screen1 {
    margin-top: 9rem;
  }

  .mr8-5rem-screen1, .mx8-5rem-screen1 {
    margin-right: 8.5rem;
  }

  .mr9rem-screen1, .mx9rem-screen1 {
    margin-right: 9rem;
  }

  .mb8-5rem-screen1, .my8-5rem-screen1 {
    margin-bottom: 8.5rem;
  }

  .mb9rem-screen1, .my9rem-screen1 {
    margin-bottom: 9rem;
  }

  .ml8-5rem-screen1, .mx8-5rem-screen1 {
    margin-left: 8.5rem;
  }

  .ml9rem-screen1, .mx9rem-screen1 {
    margin-left: 9rem;
  }

  .p8-5rem-screen1 {
    padding: 8.5rem;
  }

  .p9rem-screen1 {
    padding: 9rem;
  }

  .pt8-5rem-screen1, .py8-5rem-screen1 {
    padding-top: 8.5rem;
  }

  .pt9rem-screen1, .py9rem-screen1 {
    padding-top: 9rem;
  }

  .pr8-5rem-screen1, .px8-5rem-screen1 {
    padding-right: 8.5rem;
  }

  .pr9rem-screen1, .px9rem-screen1 {
    padding-right: 9rem;
  }

  .pb8-5rem-screen1, .py8-5rem-screen1 {
    padding-bottom: 8.5rem;
  }

  .pb9rem-screen1, .py9rem-screen1 {
    padding-bottom: 9rem;
  }

  .pl8-5rem-screen1, .px8-5rem-screen1 {
    padding-left: 8.5rem;
  }

  .pl9rem-screen1, .px9rem-screen1 {
    padding-left: 9rem;
  }

  .m100-screen1 {
    margin: 100%;
  }

  .mt100-screen1, .my100-screen1 {
    margin-top: 100%;
  }

  .mr100-screen1, .mx100-screen1 {
    margin-right: 100%;
  }

  .mb100-screen1, .my100-screen1 {
    margin-bottom: 100%;
  }

  .ml100-screen1, .mx100-screen1 {
    margin-left: 100%;
  }

  .p100-screen1 {
    padding: 100%;
  }

  .pt100-screen1, .py100-screen1 {
    padding-top: 100%;
  }

  .pr100-screen1, .px100-screen1 {
    padding-right: 100%;
  }

  .pb100-screen1, .py100-screen1 {
    padding-bottom: 100%;
  }

  .pl100-screen1, .px100-screen1 {
    padding-left: 100%;
  }

  .m9-5rem-screen1 {
    margin: 9.5rem;
  }

  .m10rem-screen1 {
    margin: 10rem;
  }

  .mt9-5rem-screen1, .my9-5rem-screen1 {
    margin-top: 9.5rem;
  }

  .mt10rem-screen1, .my10rem-screen1 {
    margin-top: 10rem;
  }

  .mr9-5rem-screen1, .mx9-5rem-screen1 {
    margin-right: 9.5rem;
  }

  .mr10rem-screen1, .mx10rem-screen1 {
    margin-right: 10rem;
  }

  .mb9-5rem-screen1, .my9-5rem-screen1 {
    margin-bottom: 9.5rem;
  }

  .mb10rem-screen1, .my10rem-screen1 {
    margin-bottom: 10rem;
  }

  .ml9-5rem-screen1, .mx9-5rem-screen1 {
    margin-left: 9.5rem;
  }

  .ml10rem-screen1, .mx10rem-screen1 {
    margin-left: 10rem;
  }

  .p9-5rem-screen1 {
    padding: 9.5rem;
  }

  .p10rem-screen1 {
    padding: 10rem;
  }

  .pt9-5rem-screen1, .py9-5rem-screen1 {
    padding-top: 9.5rem;
  }

  .pt10rem-screen1, .py10rem-screen1 {
    padding-top: 10rem;
  }

  .pr9-5rem-screen1, .px9-5rem-screen1 {
    padding-right: 9.5rem;
  }

  .pr10rem-screen1, .px10rem-screen1 {
    padding-right: 10rem;
  }

  .pb9-5rem-screen1, .py9-5rem-screen1 {
    padding-bottom: 9.5rem;
  }

  .pb10rem-screen1, .py10rem-screen1 {
    padding-bottom: 10rem;
  }

  .pl9-5rem-screen1, .px9-5rem-screen1 {
    padding-left: 9.5rem;
  }

  .pl10rem-screen1, .px10rem-screen1 {
    padding-left: 10rem;
  }

  .wa-screen1 {
    width: auto;
  }

  .ha-screen1 {
    height: auto;
  }

  .w0-screen1 {
    width: 0;
  }

  .h0-screen1 {
    height: 0;
  }

  .maw0-screen1 {
    max-width: 0;
  }

  .mah0-screen1 {
    max-height: 0;
  }

  .mih0-screen1 {
    min-height: 0;
  }

  .w1rem-screen1 {
    width: 1rem;
  }

  .w10px-screen1 {
    width: 10px;
  }

  .w10-screen1 {
    width: 10%;
  }

  .w10vw-screen1 {
    width: 10vw;
  }

  .h1rem-screen1 {
    height: 1rem;
  }

  .h10px-screen1 {
    height: 10px;
  }

  .h10-screen1 {
    height: 10%;
  }

  .h10vh-screen1 {
    height: 10vh;
  }

  .miw1rem-screen1 {
    min-width: 1rem;
  }

  .miw100px-screen1 {
    min-width: 100px;
  }

  .miw10-screen1 {
    min-width: 10%;
  }

  .miw10vw-screen1 {
    min-width: 10vw;
  }

  .mih1rem-screen1 {
    min-height: 1rem;
  }

  .mih100px-screen1 {
    min-height: 100px;
  }

  .mih10-screen1 {
    min-height: 10%;
  }

  .mih10vh-screen1 {
    min-height: 10vh;
  }

  .maw1rem-screen1 {
    max-width: 1rem;
  }

  .maw100px-screen1 {
    max-width: 100px;
  }

  .maw10-screen1 {
    max-width: 10%;
  }

  .maw10vw-screen1 {
    max-width: 10vw;
  }

  .mah1rem-screen1 {
    max-height: 1rem;
  }

  .mah100px-screen1 {
    max-height: 100px;
  }

  .mah10-screen1 {
    max-height: 10%;
  }

  .mah10vh-screen1 {
    max-height: 10vh;
  }

  .w2rem-screen1 {
    width: 2rem;
  }

  .w20px-screen1 {
    width: 20px;
  }

  .w200px-screen1 {
    width: 200px;
  }

  .w20-screen1 {
    width: 20%;
  }

  .w20vw-screen1 {
    width: 20vw;
  }

  .h2rem-screen1 {
    height: 2rem;
  }

  .h20px-screen1 {
    height: 20px;
  }

  .h200px-screen1 {
    height: 200px;
  }

  .h20-screen1 {
    height: 20%;
  }

  .h20vh-screen1 {
    height: 20vh;
  }

  .miw2rem-screen1 {
    min-width: 2rem;
  }

  .miw200px-screen1 {
    min-width: 200px;
  }

  .miw20-screen1 {
    min-width: 20%;
  }

  .miw20vw-screen1 {
    min-width: 20vw;
  }

  .mih2rem-screen1 {
    min-height: 2rem;
  }

  .mih200px-screen1 {
    min-height: 200px;
  }

  .mih20-screen1 {
    min-height: 20%;
  }

  .mih20vh-screen1 {
    min-height: 20vh;
  }

  .maw2rem-screen1 {
    max-width: 2rem;
  }

  .maw200px-screen1 {
    max-width: 200px;
  }

  .maw20-screen1 {
    max-width: 20%;
  }

  .maw20vw-screen1 {
    max-width: 20vw;
  }

  .mah2rem-screen1 {
    max-height: 2rem;
  }

  .mah200px-screen1 {
    max-height: 200px;
  }

  .mah20-screen1 {
    max-height: 20%;
  }

  .mah20vh-screen1 {
    max-height: 20vh;
  }

  .w3rem-screen1 {
    width: 3rem;
  }

  .w30px-screen1 {
    width: 30px;
  }

  .w300px-screen1 {
    width: 300px;
  }

  .w30-screen1 {
    width: 30%;
  }

  .w30vw-screen1 {
    width: 30vw;
  }

  .h3rem-screen1 {
    height: 3rem;
  }

  .h30px-screen1 {
    height: 30px;
  }

  .h300px-screen1 {
    height: 300px;
  }

  .h30-screen1 {
    height: 30%;
  }

  .h30vh-screen1 {
    height: 30vh;
  }

  .miw3rem-screen1 {
    min-width: 3rem;
  }

  .miw300px-screen1 {
    min-width: 300px;
  }

  .miw30-screen1 {
    min-width: 30%;
  }

  .miw30vw-screen1 {
    min-width: 30vw;
  }

  .mih3rem-screen1 {
    min-height: 3rem;
  }

  .mih300px-screen1 {
    min-height: 300px;
  }

  .mih30-screen1 {
    min-height: 30%;
  }

  .mih30vh-screen1 {
    min-height: 30vh;
  }

  .maw3rem-screen1 {
    max-width: 3rem;
  }

  .maw300px-screen1 {
    max-width: 300px;
  }

  .maw30-screen1 {
    max-width: 30%;
  }

  .maw30vw-screen1 {
    max-width: 30vw;
  }

  .mah3rem-screen1 {
    max-height: 3rem;
  }

  .mah300px-screen1 {
    max-height: 300px;
  }

  .mah30-screen1 {
    max-height: 30%;
  }

  .mah30vh-screen1 {
    max-height: 30vh;
  }

  .w4rem-screen1 {
    width: 4rem;
  }

  .w40px-screen1 {
    width: 40px;
  }

  .w400px-screen1 {
    width: 400px;
  }

  .w40-screen1 {
    width: 40%;
  }

  .w40vw-screen1 {
    width: 40vw;
  }

  .h4rem-screen1 {
    height: 4rem;
  }

  .h40px-screen1 {
    height: 40px;
  }

  .h400px-screen1 {
    height: 400px;
  }

  .h40-screen1 {
    height: 40%;
  }

  .h40vh-screen1 {
    height: 40vh;
  }

  .miw4rem-screen1 {
    min-width: 4rem;
  }

  .miw400px-screen1 {
    min-width: 400px;
  }

  .miw40-screen1 {
    min-width: 40%;
  }

  .miw40vw-screen1 {
    min-width: 40vw;
  }

  .mih4rem-screen1 {
    min-height: 4rem;
  }

  .mih400px-screen1 {
    min-height: 400px;
  }

  .mih40-screen1 {
    min-height: 40%;
  }

  .mih40vh-screen1 {
    min-height: 40vh;
  }

  .maw4rem-screen1 {
    max-width: 4rem;
  }

  .maw400px-screen1 {
    max-width: 400px;
  }

  .maw40-screen1 {
    max-width: 40%;
  }

  .maw40vw-screen1 {
    max-width: 40vw;
  }

  .mah4rem-screen1 {
    max-height: 4rem;
  }

  .mah400px-screen1 {
    max-height: 400px;
  }

  .mah40-screen1 {
    max-height: 40%;
  }

  .mah40vh-screen1 {
    max-height: 40vh;
  }

  .w5rem-screen1 {
    width: 5rem;
  }

  .w50px-screen1 {
    width: 50px;
  }

  .w500px-screen1 {
    width: 500px;
  }

  .w50-screen1 {
    width: 50%;
  }

  .w50vw-screen1 {
    width: 50vw;
  }

  .h5rem-screen1 {
    height: 5rem;
  }

  .h50px-screen1 {
    height: 50px;
  }

  .h500px-screen1 {
    height: 500px;
  }

  .h50-screen1 {
    height: 50%;
  }

  .h50vh-screen1 {
    height: 50vh;
  }

  .miw5rem-screen1 {
    min-width: 5rem;
  }

  .miw500px-screen1 {
    min-width: 500px;
  }

  .miw50-screen1 {
    min-width: 50%;
  }

  .miw50vw-screen1 {
    min-width: 50vw;
  }

  .mih5rem-screen1 {
    min-height: 5rem;
  }

  .mih500px-screen1 {
    min-height: 500px;
  }

  .mih50-screen1 {
    min-height: 50%;
  }

  .mih50vh-screen1 {
    min-height: 50vh;
  }

  .maw5rem-screen1 {
    max-width: 5rem;
  }

  .maw500px-screen1 {
    max-width: 500px;
  }

  .maw50-screen1 {
    max-width: 50%;
  }

  .maw50vw-screen1 {
    max-width: 50vw;
  }

  .mah5rem-screen1 {
    max-height: 5rem;
  }

  .mah500px-screen1 {
    max-height: 500px;
  }

  .mah50-screen1 {
    max-height: 50%;
  }

  .mah50vh-screen1 {
    max-height: 50vh;
  }

  .w6rem-screen1 {
    width: 6rem;
  }

  .w60px-screen1 {
    width: 60px;
  }

  .w600px-screen1 {
    width: 600px;
  }

  .w60-screen1 {
    width: 60%;
  }

  .w60vw-screen1 {
    width: 60vw;
  }

  .h6rem-screen1 {
    height: 6rem;
  }

  .h60px-screen1 {
    height: 60px;
  }

  .h600px-screen1 {
    height: 600px;
  }

  .h60-screen1 {
    height: 60%;
  }

  .h60vh-screen1 {
    height: 60vh;
  }

  .miw6rem-screen1 {
    min-width: 6rem;
  }

  .miw600px-screen1 {
    min-width: 600px;
  }

  .miw60-screen1 {
    min-width: 60%;
  }

  .miw60vw-screen1 {
    min-width: 60vw;
  }

  .mih6rem-screen1 {
    min-height: 6rem;
  }

  .mih600px-screen1 {
    min-height: 600px;
  }

  .mih60-screen1 {
    min-height: 60%;
  }

  .mih60vh-screen1 {
    min-height: 60vh;
  }

  .maw6rem-screen1 {
    max-width: 6rem;
  }

  .maw600px-screen1 {
    max-width: 600px;
  }

  .maw60-screen1 {
    max-width: 60%;
  }

  .maw60vw-screen1 {
    max-width: 60vw;
  }

  .mah6rem-screen1 {
    max-height: 6rem;
  }

  .mah600px-screen1 {
    max-height: 600px;
  }

  .mah60-screen1 {
    max-height: 60%;
  }

  .mah60vh-screen1 {
    max-height: 60vh;
  }

  .w7rem-screen1 {
    width: 7rem;
  }

  .w70px-screen1 {
    width: 70px;
  }

  .w700px-screen1 {
    width: 700px;
  }

  .w70-screen1 {
    width: 70%;
  }

  .w70vw-screen1 {
    width: 70vw;
  }

  .h7rem-screen1 {
    height: 7rem;
  }

  .h70px-screen1 {
    height: 70px;
  }

  .h700px-screen1 {
    height: 700px;
  }

  .h70-screen1 {
    height: 70%;
  }

  .h70vh-screen1 {
    height: 70vh;
  }

  .miw7rem-screen1 {
    min-width: 7rem;
  }

  .miw700px-screen1 {
    min-width: 700px;
  }

  .miw70-screen1 {
    min-width: 70%;
  }

  .miw70vw-screen1 {
    min-width: 70vw;
  }

  .mih7rem-screen1 {
    min-height: 7rem;
  }

  .mih700px-screen1 {
    min-height: 700px;
  }

  .mih70-screen1 {
    min-height: 70%;
  }

  .mih70vh-screen1 {
    min-height: 70vh;
  }

  .maw7rem-screen1 {
    max-width: 7rem;
  }

  .maw700px-screen1 {
    max-width: 700px;
  }

  .maw70-screen1 {
    max-width: 70%;
  }

  .maw70vw-screen1 {
    max-width: 70vw;
  }

  .mah7rem-screen1 {
    max-height: 7rem;
  }

  .mah700px-screen1 {
    max-height: 700px;
  }

  .mah70-screen1 {
    max-height: 70%;
  }

  .mah70vh-screen1 {
    max-height: 70vh;
  }

  .w8rem-screen1 {
    width: 8rem;
  }

  .w80px-screen1 {
    width: 80px;
  }

  .w800px-screen1 {
    width: 800px;
  }

  .w80-screen1 {
    width: 80%;
  }

  .w80vw-screen1 {
    width: 80vw;
  }

  .h8rem-screen1 {
    height: 8rem;
  }

  .h80px-screen1 {
    height: 80px;
  }

  .h800px-screen1 {
    height: 800px;
  }

  .h80-screen1 {
    height: 80%;
  }

  .h80vh-screen1 {
    height: 80vh;
  }

  .miw8rem-screen1 {
    min-width: 8rem;
  }

  .miw800px-screen1 {
    min-width: 800px;
  }

  .miw80-screen1 {
    min-width: 80%;
  }

  .miw80vw-screen1 {
    min-width: 80vw;
  }

  .mih8rem-screen1 {
    min-height: 8rem;
  }

  .mih800px-screen1 {
    min-height: 800px;
  }

  .mih80-screen1 {
    min-height: 80%;
  }

  .mih80vh-screen1 {
    min-height: 80vh;
  }

  .maw8rem-screen1 {
    max-width: 8rem;
  }

  .maw800px-screen1 {
    max-width: 800px;
  }

  .maw80-screen1 {
    max-width: 80%;
  }

  .maw80vw-screen1 {
    max-width: 80vw;
  }

  .mah8rem-screen1 {
    max-height: 8rem;
  }

  .mah800px-screen1 {
    max-height: 800px;
  }

  .mah80-screen1 {
    max-height: 80%;
  }

  .mah80vh-screen1 {
    max-height: 80vh;
  }

  .w9rem-screen1 {
    width: 9rem;
  }

  .w90px-screen1 {
    width: 90px;
  }

  .w900px-screen1 {
    width: 900px;
  }

  .w90-screen1 {
    width: 90%;
  }

  .w90vw-screen1 {
    width: 90vw;
  }

  .h9rem-screen1 {
    height: 9rem;
  }

  .h90px-screen1 {
    height: 90px;
  }

  .h900px-screen1 {
    height: 900px;
  }

  .h90-screen1 {
    height: 90%;
  }

  .h90vh-screen1 {
    height: 90vh;
  }

  .miw9rem-screen1 {
    min-width: 9rem;
  }

  .miw900px-screen1 {
    min-width: 900px;
  }

  .miw90-screen1 {
    min-width: 90%;
  }

  .miw90vw-screen1 {
    min-width: 90vw;
  }

  .mih9rem-screen1 {
    min-height: 9rem;
  }

  .mih900px-screen1 {
    min-height: 900px;
  }

  .mih90-screen1 {
    min-height: 90%;
  }

  .mih90vh-screen1 {
    min-height: 90vh;
  }

  .maw9rem-screen1 {
    max-width: 9rem;
  }

  .maw900px-screen1 {
    max-width: 900px;
  }

  .maw90-screen1 {
    max-width: 90%;
  }

  .maw90vw-screen1 {
    max-width: 90vw;
  }

  .mah9rem-screen1 {
    max-height: 9rem;
  }

  .mah900px-screen1 {
    max-height: 900px;
  }

  .mah90-screen1 {
    max-height: 90%;
  }

  .mah90vh-screen1 {
    max-height: 90vh;
  }

  .w10rem-screen1 {
    width: 10rem;
  }

  .w100px-screen1 {
    width: 100px;
  }

  .w1000px-screen1 {
    width: 1000px;
  }

  .w100-screen1 {
    width: 100%;
  }

  .w100vw-screen1 {
    width: 100vw;
  }

  .h10rem-screen1 {
    height: 10rem;
  }

  .h100px-screen1 {
    height: 100px;
  }

  .h1000px-screen1 {
    height: 1000px;
  }

  .h100-screen1 {
    height: 100%;
  }

  .h100vh-screen1 {
    height: 100vh;
  }

  .miw10rem-screen1 {
    min-width: 10rem;
  }

  .miw1000px-screen1 {
    min-width: 1000px;
  }

  .miw100-screen1 {
    min-width: 100%;
  }

  .miw100vw-screen1 {
    min-width: 100vw;
  }

  .mih10rem-screen1 {
    min-height: 10rem;
  }

  .mih1000px-screen1 {
    min-height: 1000px;
  }

  .mih100-screen1 {
    min-height: 100%;
  }

  .mih100vh-screen1 {
    min-height: 100vh;
  }

  .maw10rem-screen1 {
    max-width: 10rem;
  }

  .maw1000px-screen1 {
    max-width: 1000px;
  }

  .maw100-screen1 {
    max-width: 100%;
  }

  .maw100vw-screen1 {
    max-width: 100vw;
  }

  .mah10rem-screen1 {
    max-height: 10rem;
  }

  .mah1000px-screen1 {
    max-height: 1000px;
  }

  .mah100-screen1 {
    max-height: 100%;
  }

  .mah100vh-screen1 {
    max-height: 100vh;
  }

  .or0-screen1 {
    order: 0;
  }

  .br0px-screen1 {
    border-radius: 0;
  }

  .btlr0px-screen1 {
    border-top-left-radius: 0;
  }

  .btrr0px-screen1 {
    border-top-right-radius: 0;
  }

  .bblr0px-screen1 {
    border-bottom-left-radius: 0;
  }

  .bbrr0px-screen1 {
    border-bottom-right-radius: 0;
  }

  .or1-screen1 {
    order: 1;
  }

  .br5px-screen1 {
    border-radius: 6px;
  }

  .br8px-screen1 {
    border-radius: 8px;
  }

  .br19px-screen1 {
    border-radius: 19px;
  }

  .btlr5px-screen1 {
    border-top-left-radius: 5px;
  }

  .btrr5px-screen1 {
    border-top-right-radius: 5px;
  }

  .bblr5px-screen1 {
    border-bottom-left-radius: 5px;
  }

  .bbrr5px-screen1 {
    border-bottom-right-radius: 5px;
  }

  .or2-screen1 {
    order: 2;
  }

  .br10px-screen1 {
    border-radius: 12px;
  }

  .br16px-screen1 {
    border-radius: 16px;
  }

  .br38px-screen1 {
    border-radius: 38px;
  }

  .btlr10px-screen1 {
    border-top-left-radius: 10px;
  }

  .btrr10px-screen1 {
    border-top-right-radius: 10px;
  }

  .bblr10px-screen1 {
    border-bottom-left-radius: 10px;
  }

  .bbrr10px-screen1 {
    border-bottom-right-radius: 10px;
  }

  .or3-screen1 {
    order: 3;
  }

  .br15px-screen1 {
    border-radius: 18px;
  }

  .br24px-screen1 {
    border-radius: 24px;
  }

  .br57px-screen1 {
    border-radius: 57px;
  }

  .btlr15px-screen1 {
    border-top-left-radius: 15px;
  }

  .btrr15px-screen1 {
    border-top-right-radius: 15px;
  }

  .bblr15px-screen1 {
    border-bottom-left-radius: 15px;
  }

  .bbrr15px-screen1 {
    border-bottom-right-radius: 15px;
  }

  .or4-screen1 {
    order: 4;
  }

  .br20px-screen1 {
    border-radius: 24px;
  }

  .br32px-screen1 {
    border-radius: 32px;
  }

  .br76px-screen1 {
    border-radius: 76px;
  }

  .btlr20px-screen1 {
    border-top-left-radius: 20px;
  }

  .btrr20px-screen1 {
    border-top-right-radius: 20px;
  }

  .bblr20px-screen1 {
    border-bottom-left-radius: 20px;
  }

  .bbrr20px-screen1 {
    border-bottom-right-radius: 20px;
  }

  .or5-screen1 {
    order: 5;
  }

  .br25px-screen1 {
    border-radius: 30px;
  }

  .br95px-screen1 {
    border-radius: 95px;
  }

  .btlr25px-screen1 {
    border-top-left-radius: 25px;
  }

  .btrr25px-screen1 {
    border-top-right-radius: 25px;
  }

  .bblr25px-screen1 {
    border-bottom-left-radius: 25px;
  }

  .bbrr25px-screen1 {
    border-bottom-right-radius: 25px;
  }

  .or6-screen1 {
    order: 6;
  }

  .br30px-screen1 {
    border-radius: 36px;
  }

  .br48px-screen1 {
    border-radius: 48px;
  }

  .br114px-screen1 {
    border-radius: 114px;
  }

  .btlr30px-screen1 {
    border-top-left-radius: 30px;
  }

  .btrr30px-screen1 {
    border-top-right-radius: 30px;
  }

  .bblr30px-screen1 {
    border-bottom-left-radius: 30px;
  }

  .bbrr30px-screen1 {
    border-bottom-right-radius: 30px;
  }

  .or7-screen1 {
    order: 7;
  }

  .br35px-screen1 {
    border-radius: 42px;
  }

  .br56px-screen1 {
    border-radius: 56px;
  }

  .br133px-screen1 {
    border-radius: 133px;
  }

  .btlr35px-screen1 {
    border-top-left-radius: 35px;
  }

  .btrr35px-screen1 {
    border-top-right-radius: 35px;
  }

  .bblr35px-screen1 {
    border-bottom-left-radius: 35px;
  }

  .bbrr35px-screen1 {
    border-bottom-right-radius: 35px;
  }

  .or8-screen1 {
    order: 8;
  }

  .br40px-screen1 {
    border-radius: 48px;
  }

  .br64px-screen1 {
    border-radius: 64px;
  }

  .br152px-screen1 {
    border-radius: 152px;
  }

  .btlr40px-screen1 {
    border-top-left-radius: 40px;
  }

  .btrr40px-screen1 {
    border-top-right-radius: 40px;
  }

  .bblr40px-screen1 {
    border-bottom-left-radius: 40px;
  }

  .bbrr40px-screen1 {
    border-bottom-right-radius: 40px;
  }

  .or9-screen1 {
    order: 9;
  }

  .br45px-screen1 {
    border-radius: 54px;
  }

  .br72px-screen1 {
    border-radius: 72px;
  }

  .br171px-screen1 {
    border-radius: 171px;
  }

  .btlr45px-screen1 {
    border-top-left-radius: 45px;
  }

  .btrr45px-screen1 {
    border-top-right-radius: 45px;
  }

  .bblr45px-screen1 {
    border-bottom-left-radius: 45px;
  }

  .bbrr45px-screen1 {
    border-bottom-right-radius: 45px;
  }

  .or10-screen1 {
    order: 10;
  }

  .br50px-screen1 {
    border-radius: 60px;
  }

  .br80px-screen1 {
    border-radius: 80px;
  }

  .br190px-screen1 {
    border-radius: 190px;
  }

  .btlr50px-screen1 {
    border-top-left-radius: 50px;
  }

  .btrr50px-screen1 {
    border-top-right-radius: 50px;
  }

  .bblr50px-screen1 {
    border-bottom-left-radius: 50px;
  }

  .bbrr50px-screen1 {
    border-bottom-right-radius: 50px;
  }

  .br100 {
    border-radius: 100%;
  }

  .btlr100 {
    border-top-left-radius: 100%;
  }

  .btrr100 {
    border-top-right-radius: 100%;
  }

  .bblr100 {
    border-bottom-left-radius: 100%;
  }

  .bbrr100 {
    border-bottom-right-radius: 100%;
  }
}

@media (width >= 1536px) {
  .c1-screen4, .c1-hover-screen4:hover {
    color: #fff;
  }

  .bg1-screen4, .bg1-hover-screen4:hover {
    background-color: #fff;
  }

  .bo1-screen4, .bo1-hover-screen4:hover {
    border-color: #fff;
  }

  .f1-screen4, .f1-hover-screen4:hover {
    fill: #fff;
  }

  .c2-screen4, .c2-hover-screen4:hover {
    color: #000;
  }

  .bg2-screen4, .bg2-hover-screen4:hover {
    background-color: #000;
  }

  .bo2-screen4, .bo2-hover-screen4:hover {
    border-color: #000;
  }

  .f2-screen4, .f2-hover-screen4:hover {
    fill: #000;
  }

  .c3-screen4, .c3-hover-screen4:hover {
    color: #4a67ff;
  }

  .bg3-screen4, .bg3-hover-screen4:hover {
    background-color: #4a67ff;
  }

  .bo3-screen4, .bo3-hover-screen4:hover {
    border-color: #4a67ff;
  }

  .f3-screen4, .f3-hover-screen4:hover {
    fill: #4a67ff;
  }

  .c4-screen4, .c4-hover-screen4:hover {
    color: #fc7785;
  }

  .bg4-screen4, .bg4-hover-screen4:hover {
    background-color: #fc7785;
  }

  .bo4-screen4, .bo4-hover-screen4:hover {
    border-color: #fc7785;
  }

  .f4-screen4, .f4-hover-screen4:hover {
    fill: #fc7785;
  }

  .c5-screen4, .c5-hover-screen4:hover {
    color: #f0f0f8;
  }

  .bg5-screen4, .bg5-hover-screen4:hover {
    background-color: #f0f0f8;
  }

  .bo5-screen4, .bo5-hover-screen4:hover {
    border-color: #f0f0f8;
  }

  .f5-screen4, .f5-hover-screen4:hover {
    fill: #f0f0f8;
  }

  .c6-screen4, .c6-hover-screen4:hover {
    color: #002d63;
  }

  .bg6-screen4, .bg6-hover-screen4:hover {
    background-color: #002d63;
  }

  .bo6-screen4, .bo6-hover-screen4:hover {
    border-color: #002d63;
  }

  .f6-screen4, .f6-hover-screen4:hover {
    fill: #002d63;
  }

  .c7-screen4, .c7-hover-screen4:hover {
    color: #40dda1;
  }

  .bg7-screen4, .bg7-hover-screen4:hover {
    background-color: #40dda1;
  }

  .bo7-screen4, .bo7-hover-screen4:hover {
    border-color: #40dda1;
  }

  .f7-screen4, .f7-hover-screen4:hover {
    fill: #40dda1;
  }

  .c8-screen4, .c8-hover-screen4:hover {
    color: #ddb640;
  }

  .bg8-screen4, .bg8-hover-screen4:hover {
    background-color: #ddb640;
  }

  .bo8-screen4, .bo8-hover-screen4:hover {
    border-color: #ddb640;
  }

  .f8-screen4, .f8-hover-screen4:hover {
    fill: #ddb640;
  }

  .c9-screen4, .c9-hover-screen4:hover {
    color: #dd4040;
  }

  .bg9-screen4, .bg9-hover-screen4:hover {
    background-color: #dd4040;
  }

  .bo9-screen4, .bo9-hover-screen4:hover {
    border-color: #dd4040;
  }

  .f9-screen4, .f9-hover-screen4:hover {
    fill: #dd4040;
  }

  .c10-screen4, .c10-hover-screen4:hover {
    color: #038589;
  }

  .bg10-screen4, .bg10-hover-screen4:hover {
    background-color: #038589;
  }

  .bo10-screen4, .bo10-hover-screen4:hover {
    border-color: #038589;
  }

  .f10-screen4, .f10-hover-screen4:hover {
    fill: #038589;
  }

  .c11-screen4, .c11-hover-screen4:hover {
    color: #007ee5;
  }

  .bg11-screen4, .bg11-hover-screen4:hover {
    background-color: #007ee5;
  }

  .bo11-screen4, .bo11-hover-screen4:hover {
    border-color: #007ee5;
  }

  .f11-screen4, .f11-hover-screen4:hover {
    fill: #007ee5;
  }

  .c12-screen4, .c12-hover-screen4:hover {
    color: #969696;
  }

  .bg12-screen4, .bg12-hover-screen4:hover {
    background-color: #969696;
  }

  .bo12-screen4, .bo12-hover-screen4:hover {
    border-color: #969696;
  }

  .f12-screen4, .f12-hover-screen4:hover {
    fill: #969696;
  }

  .c13-screen4, .c13-hover-screen4:hover {
    color: #fb7a27;
  }

  .bg13-screen4, .bg13-hover-screen4:hover {
    background-color: #fb7a27;
  }

  .bo13-screen4, .bo13-hover-screen4:hover {
    border-color: #fb7a27;
  }

  .f13-screen4, .f13-hover-screen4:hover {
    fill: #fb7a27;
  }

  .c14-screen4, .c14-hover-screen4:hover {
    color: #505050;
  }

  .bg14-screen4, .bg14-hover-screen4:hover {
    background-color: #505050;
  }

  .bo14-screen4, .bo14-hover-screen4:hover {
    border-color: #505050;
  }

  .f14-screen4, .f14-hover-screen4:hover {
    fill: #505050;
  }

  .c15-screen4, .c15-hover-screen4:hover {
    color: #151313;
  }

  .bg15-screen4, .bg15-hover-screen4:hover {
    background-color: #151313;
  }

  .bo15-screen4, .bo15-hover-screen4:hover {
    border-color: #151313;
  }

  .f15-screen4, .f15-hover-screen4:hover {
    fill: #151313;
  }

  .c16-screen4, .c16-hover-screen4:hover {
    color: #d9d9d9;
  }

  .bg16-screen4, .bg16-hover-screen4:hover {
    background-color: #d9d9d9;
  }

  .bo16-screen4, .bo16-hover-screen4:hover {
    border-color: #d9d9d9;
  }

  .f16-screen4, .f16-hover-screen4:hover {
    fill: #d9d9d9;
  }

  .c17-screen4, .c17-hover-screen4:hover {
    color: #d3d9fc;
  }

  .bg17-screen4, .bg17-hover-screen4:hover {
    background-color: #d3d9fc;
  }

  .bo17-screen4, .bo17-hover-screen4:hover {
    border-color: #d3d9fc;
  }

  .f17-screen4, .f17-hover-screen4:hover {
    fill: #d3d9fc;
  }

  .c18-screen4, .c18-hover-screen4:hover {
    color: #6775b9;
  }

  .bg18-screen4, .bg18-hover-screen4:hover {
    background-color: #6775b9;
  }

  .bo18-screen4, .bo18-hover-screen4:hover {
    border-color: #6775b9;
  }

  .f18-screen4, .f18-hover-screen4:hover {
    fill: #6775b9;
  }

  .c19-screen4, .c19-hover-screen4:hover {
    color: #f5f6f9;
  }

  .bg19-screen4, .bg19-hover-screen4:hover {
    background-color: #f5f6f9;
  }

  .bo19-screen4, .bo19-hover-screen4:hover {
    border-color: #f5f6f9;
  }

  .f19-screen4, .f19-hover-screen4:hover {
    fill: #f5f6f9;
  }

  .c20-screen4, .c20-hover-screen4:hover {
    color: #f0f2fd;
  }

  .bg20-screen4, .bg20-hover-screen4:hover {
    background-color: #f0f2fd;
  }

  .bo20-screen4, .bo20-hover-screen4:hover {
    border-color: #f0f2fd;
  }

  .f20-screen4, .f20-hover-screen4:hover {
    fill: #f0f2fd;
  }

  .c21-screen4, .c21-hover-screen4:hover {
    color: #f9f9f9;
  }

  .bg21-screen4, .bg21-hover-screen4:hover {
    background-color: #f9f9f9;
  }

  .bo21-screen4, .bo21-hover-screen4:hover {
    border-color: #f9f9f9;
  }

  .f21-screen4, .f21-hover-screen4:hover {
    fill: #f9f9f9;
  }

  .c22-screen4, .c22-hover-screen4:hover {
    color: #f6f6f6;
  }

  .bg22-screen4, .bg22-hover-screen4:hover {
    background-color: #f6f6f6;
  }

  .bo22-screen4, .bo22-hover-screen4:hover {
    border-color: #f6f6f6;
  }

  .f22-screen4, .f22-hover-screen4:hover {
    fill: #f6f6f6;
  }

  .c23-screen4, .c23-hover-screen4:hover {
    color: #fd6474;
  }

  .bg23-screen4, .bg23-hover-screen4:hover {
    background-color: #fd6474;
  }

  .bo23-screen4, .bo23-hover-screen4:hover {
    border-color: #fd6474;
  }

  .f23-screen4, .f23-hover-screen4:hover {
    fill: #fd6474;
  }

  .c24-screen4, .c24-hover-screen4:hover {
    color: #efefef;
  }

  .bg24-screen4, .bg24-hover-screen4:hover {
    background-color: #efefef;
  }

  .bo24-screen4, .bo24-hover-screen4:hover {
    border-color: #efefef;
  }

  .f24-screen4, .f24-hover-screen4:hover {
    fill: #efefef;
  }

  .c25-screen4, .c25-hover-screen4:hover {
    color: #f8f8f8;
  }

  .bg25-screen4, .bg25-hover-screen4:hover {
    background-color: #f8f8f8;
  }

  .bo25-screen4, .bo25-hover-screen4:hover {
    border-color: #f8f8f8;
  }

  .f25-screen4, .f25-hover-screen4:hover {
    fill: #f8f8f8;
  }

  .c26-screen4, .c26-hover-screen4:hover {
    color: #0126ed;
  }

  .bg26-screen4, .bg26-hover-screen4:hover {
    background-color: #0126ed;
  }

  .bo26-screen4, .bo26-hover-screen4:hover {
    border-color: #0126ed;
  }

  .f26-screen4, .f26-hover-screen4:hover {
    fill: #0126ed;
  }

  .c27-screen4, .c27-hover-screen4:hover {
    color: #5e5f66;
  }

  .bg27-screen4, .bg27-hover-screen4:hover {
    background-color: #5e5f66;
  }

  .bo27-screen4, .bo27-hover-screen4:hover {
    border-color: #5e5f66;
  }

  .f27-screen4, .f27-hover-screen4:hover {
    fill: #5e5f66;
  }

  .c28-screen4, .c28-hover-screen4:hover {
    color: #aaacb5;
  }

  .bg28-screen4, .bg28-hover-screen4:hover {
    background-color: #aaacb5;
  }

  .bo28-screen4, .bo28-hover-screen4:hover {
    border-color: #aaacb5;
  }

  .f28-screen4, .f28-hover-screen4:hover {
    fill: #aaacb5;
  }

  .c29-screen4, .c29-hover-screen4:hover {
    color: #dde2f2;
  }

  .bg29-screen4, .bg29-hover-screen4:hover {
    background-color: #dde2f2;
  }

  .bo29-screen4, .bo29-hover-screen4:hover {
    border-color: #dde2f2;
  }

  .f29-screen4, .f29-hover-screen4:hover {
    fill: #dde2f2;
  }

  .c30-screen4, .c30-hover-screen4:hover {
    color: #09b255;
  }

  .bg30-screen4, .bg30-hover-screen4:hover {
    background-color: #09b255;
  }

  .bo30-screen4, .bo30-hover-screen4:hover {
    border-color: #09b255;
  }

  .f30-screen4, .f30-hover-screen4:hover {
    fill: #09b255;
  }

  .c31-screen4, .c31-hover-screen4:hover {
    color: #dbdfee;
  }

  .bg31-screen4, .bg31-hover-screen4:hover {
    background-color: #dbdfee;
  }

  .bo31-screen4, .bo31-hover-screen4:hover {
    border-color: #dbdfee;
  }

  .f31-screen4, .f31-hover-screen4:hover {
    fill: #dbdfee;
  }

  .c32-screen4, .c32-hover-screen4:hover {
    color: #737171;
  }

  .bg32-screen4, .bg32-hover-screen4:hover {
    background-color: #737171;
  }

  .bo32-screen4, .bo32-hover-screen4:hover {
    border-color: #737171;
  }

  .f32-screen4, .f32-hover-screen4:hover {
    fill: #737171;
  }

  .c33-screen4, .c33-hover-screen4:hover {
    color: #767b90;
  }

  .bg33-screen4, .bg33-hover-screen4:hover {
    background-color: #767b90;
  }

  .bo33-screen4, .bo33-hover-screen4:hover {
    border-color: #767b90;
  }

  .f33-screen4, .f33-hover-screen4:hover {
    fill: #767b90;
  }

  .c34-screen4, .c34-hover-screen4:hover {
    color: #15d757;
  }

  .bg34-screen4, .bg34-hover-screen4:hover {
    background-color: #15d757;
  }

  .bo34-screen4, .bo34-hover-screen4:hover {
    border-color: #15d757;
  }

  .f34-screen4, .f34-hover-screen4:hover {
    fill: #15d757;
  }

  .c35-screen4, .c35-hover-screen4:hover {
    color: #e8ebf5;
  }

  .bg35-screen4, .bg35-hover-screen4:hover {
    background-color: #e8ebf5;
  }

  .bo35-screen4, .bo35-hover-screen4:hover {
    border-color: #e8ebf5;
  }

  .f35-screen4, .f35-hover-screen4:hover {
    fill: #e8ebf5;
  }

  .c36-screen4, .c36-hover-screen4:hover {
    color: #4c6b92;
  }

  .bg36-screen4, .bg36-hover-screen4:hover {
    background-color: #4c6b92;
  }

  .bo36-screen4, .bo36-hover-screen4:hover {
    border-color: #4c6b92;
  }

  .f36-screen4, .f36-hover-screen4:hover {
    fill: #4c6b92;
  }

  .c37-screen4, .c37-hover-screen4:hover {
    color: #818181;
  }

  .bg37-screen4, .bg37-hover-screen4:hover {
    background-color: #818181;
  }

  .bo37-screen4, .bo37-hover-screen4:hover {
    border-color: #818181;
  }

  .f37-screen4, .f37-hover-screen4:hover {
    fill: #818181;
  }

  .c38-screen4, .c38-hover-screen4:hover {
    color: #f0f2fd;
  }

  .bg38-screen4, .bg38-hover-screen4:hover {
    background-color: #f0f2fd;
  }

  .bo38-screen4, .bo38-hover-screen4:hover {
    border-color: #f0f2fd;
  }

  .f38-screen4, .f38-hover-screen4:hover {
    fill: #f0f2fd;
  }

  .c39-screen4, .c39-hover-screen4:hover {
    color: #f99229;
  }

  .bg39-screen4, .bg39-hover-screen4:hover {
    background-color: #f99229;
  }

  .bo39-screen4, .bo39-hover-screen4:hover {
    border-color: #f99229;
  }

  .f39-screen4, .f39-hover-screen4:hover {
    fill: #f99229;
  }

  .c40-screen4, .c40-hover-screen4:hover {
    color: #2947ef;
  }

  .bg40-screen4, .bg40-hover-screen4:hover {
    background-color: #2947ef;
  }

  .bo40-screen4, .bo40-hover-screen4:hover {
    border-color: #2947ef;
  }

  .f40-screen4, .f40-hover-screen4:hover {
    fill: #2947ef;
  }

  .c41-screen4, .c41-hover-screen4:hover {
    color: #f5f6ff;
  }

  .bg41-screen4, .bg41-hover-screen4:hover {
    background-color: #f5f6ff;
  }

  .bo41-screen4, .bo41-hover-screen4:hover {
    border-color: #f5f6ff;
  }

  .f41-screen4, .f41-hover-screen4:hover {
    fill: #f5f6ff;
  }

  .tal-screen4 {
    text-align: left;
  }

  .tac-screen4 {
    text-align: center;
  }

  .tar-screen4 {
    text-align: right;
  }

  .taj-screen4 {
    text-align: justify;
  }

  .tas-screen4 {
    text-align: start;
  }

  .tae-screen4 {
    text-align: end;
  }

  .fs0-screen4 {
    font-size: 0;
  }

  .fs0-25rem-screen4 {
    font-size: .25rem;
  }

  .fs0-125rem-screen4 {
    font-size: .125rem;
  }

  .fs0-5rem-screen4 {
    font-size: .5rem;
  }

  .fs0-75rem-screen4 {
    font-size: .75rem;
  }

  .fs0-875rem-screen4 {
    font-size: .875rem;
  }

  .fs1rem-screen4 {
    font-size: 1rem;
  }

  .fs1-25rem-screen4 {
    font-size: 1.25rem;
  }

  .fs1-125rem-screen4 {
    font-size: 1.125rem;
  }

  .fs1-5rem-screen4 {
    font-size: 1.5rem;
  }

  .fs1-75rem-screen4 {
    font-size: 1.75rem;
  }

  .fs1-875rem-screen4 {
    font-size: 1.875rem;
  }

  .fs2rem-screen4 {
    font-size: 2rem;
  }

  .fs2-25rem-screen4 {
    font-size: 2.25rem;
  }

  .fs2-125rem-screen4 {
    font-size: 2.125rem;
  }

  .fs2-5rem-screen4 {
    font-size: 2.5rem;
  }

  .fs2-75rem-screen4 {
    font-size: 2.75rem;
  }

  .fs2-875rem-screen4 {
    font-size: 2.875rem;
  }

  .fs3rem-screen4 {
    font-size: 3rem;
  }

  .fs3-25rem-screen4 {
    font-size: 3.25rem;
  }

  .fs3-125rem-screen4 {
    font-size: 3.125rem;
  }

  .fs3-5rem-screen4 {
    font-size: 3.5rem;
  }

  .fs3-75rem-screen4 {
    font-size: 3.75rem;
  }

  .fs3-875rem-screen4 {
    font-size: 3.875rem;
  }

  .fs4rem-screen4 {
    font-size: 4rem;
  }

  .fs4-25rem-screen4 {
    font-size: 4.25rem;
  }

  .fs4-125rem-screen4 {
    font-size: 4.125rem;
  }

  .fs4-5rem-screen4 {
    font-size: 4.5rem;
  }

  .fs4-75rem-screen4 {
    font-size: 4.75rem;
  }

  .fs4-875rem-screen4 {
    font-size: 4.875rem;
  }

  .fs5rem-screen4 {
    font-size: 5rem;
  }

  .fs5-25rem-screen4 {
    font-size: 5.25rem;
  }

  .fs5-125rem-screen4 {
    font-size: 5.125rem;
  }

  .fs5-5rem-screen4 {
    font-size: 5.5rem;
  }

  .fs5-75rem-screen4 {
    font-size: 5.75rem;
  }

  .fs5-875rem-screen4 {
    font-size: 5.875rem;
  }

  .fs6rem-screen4 {
    font-size: 6rem;
  }

  .fs6-25rem-screen4 {
    font-size: 6.25rem;
  }

  .fs6-125rem-screen4 {
    font-size: 6.125rem;
  }

  .fs6-5rem-screen4 {
    font-size: 6.5rem;
  }

  .fs6-75rem-screen4 {
    font-size: 6.75rem;
  }

  .fs6-875rem-screen4 {
    font-size: 6.875rem;
  }

  .fs7rem-screen4 {
    font-size: 7rem;
  }

  .db-screen4 {
    display: block;
  }

  .dib-screen4 {
    display: inline-block;
  }

  .di-screen4 {
    display: inline;
  }

  .df-screen4 {
    display: flex;
  }

  .fdc-screen4 {
    flex-direction: column;
  }

  .fdr-screen4 {
    flex-direction: row;
  }

  .fdcr-screen4 {
    flex-direction: column-reverse;
  }

  .fdrr-screen4 {
    flex-direction: row-reverse;
  }

  .fww-screen4 {
    flex-wrap: wrap;
  }

  .jcfs-screen4 {
    justify-content: flex-start;
  }

  .jcc-screen4 {
    justify-content: center;
  }

  .jcfe-screen4 {
    justify-content: flex-end;
  }

  .jcsb-screen4 {
    justify-content: space-between;
  }

  .jcsa-screen4 {
    justify-content: space-around;
  }

  .jcse-screen4 {
    justify-content: space-evenly;
  }

  .jce-screen4 {
    justify-content: end;
  }

  .aifs-screen4 {
    align-items: flex-start;
  }

  .aic-screen4 {
    align-items: center;
  }

  .aife-screen4 {
    align-items: flex-end;
  }

  .jss-screen4 {
    justify-self: start;
  }

  .jsfs-screen4 {
    justify-self: flex-start;
  }

  .jsc-screen4 {
    justify-self: center;
  }

  .jsfe-screen4 {
    justify-self: flex-end;
  }

  .jse-screen4 {
    justify-self: end;
  }

  .ass-screen4 {
    align-self: start;
  }

  .asfs-screen4 {
    align-self: flex-start;
  }

  .asc-screen4 {
    align-self: center;
  }

  .asfe-screen4 {
    align-self: flex-end;
  }

  .ase-screen4 {
    align-self: end;
  }

  .dif-screen4 {
    display: inline-flex;
  }

  .dg-screen4 {
    display: grid;
  }

  .brss-screen4 {
    border-right-style: solid;
  }

  .brw1px-screen4 {
    border-right-width: 1px;
  }

  .brw2px-screen4 {
    border-right-width: 2px;
  }

  .gtcr1-screen4 {
    grid-template-columns: repeat(1, 1fr);
  }

  .ftcr1-screen4 {
    grid-column: 1 / 1fr;
  }

  .gtrr1-screen4 {
    grid-template-rows: repeat(1, 1fr);
  }

  .gtcr1a-screen4 {
    grid-template-columns: repeat(1, auto);
  }

  .gtrr1a-screen4 {
    grid-template-rows: repeat(1, auto);
  }

  .gcs1-screen4 {
    grid-column-start: 1;
  }

  .gce2-screen4 {
    grid-column-end: 2;
  }

  .grs1-screen4 {
    grid-row-start: 1;
  }

  .gre2-screen4 {
    grid-row-end: 2;
  }

  .gtcr2-screen4 {
    grid-template-columns: repeat(2, 1fr);
  }

  .ftcr2-screen4 {
    grid-column: 2 / 1fr;
  }

  .gtrr2-screen4 {
    grid-template-rows: repeat(2, 1fr);
  }

  .gtcr2a-screen4 {
    grid-template-columns: repeat(2, auto);
  }

  .gtrr2a-screen4 {
    grid-template-rows: repeat(2, auto);
  }

  .gcs2-screen4 {
    grid-column-start: 2;
  }

  .gce3-screen4 {
    grid-column-end: 3;
  }

  .grs2-screen4 {
    grid-row-start: 2;
  }

  .gre3-screen4 {
    grid-row-end: 3;
  }

  .gtcr3-screen4 {
    grid-template-columns: repeat(3, 1fr);
  }

  .ftcr3-screen4 {
    grid-column: 3 / 1fr;
  }

  .gtrr3-screen4 {
    grid-template-rows: repeat(3, 1fr);
  }

  .gtcr3a-screen4 {
    grid-template-columns: repeat(3, auto);
  }

  .gtrr3a-screen4 {
    grid-template-rows: repeat(3, auto);
  }

  .gcs3-screen4 {
    grid-column-start: 3;
  }

  .gce4-screen4 {
    grid-column-end: 4;
  }

  .grs3-screen4 {
    grid-row-start: 3;
  }

  .gre4-screen4 {
    grid-row-end: 4;
  }

  .gtcr4-screen4 {
    grid-template-columns: repeat(4, 1fr);
  }

  .ftcr4-screen4 {
    grid-column: 4 / 1fr;
  }

  .gtrr4-screen4 {
    grid-template-rows: repeat(4, 1fr);
  }

  .gtcr4a-screen4 {
    grid-template-columns: repeat(4, auto);
  }

  .gtrr4a-screen4 {
    grid-template-rows: repeat(4, auto);
  }

  .gcs4-screen4 {
    grid-column-start: 4;
  }

  .gce5-screen4 {
    grid-column-end: 5;
  }

  .grs4-screen4 {
    grid-row-start: 4;
  }

  .gre5-screen4 {
    grid-row-end: 5;
  }

  .gtcr5-screen4 {
    grid-template-columns: repeat(5, 1fr);
  }

  .ftcr5-screen4 {
    grid-column: 5 / 1fr;
  }

  .gtrr5-screen4 {
    grid-template-rows: repeat(5, 1fr);
  }

  .gtcr5a-screen4 {
    grid-template-columns: repeat(5, auto);
  }

  .gtrr5a-screen4 {
    grid-template-rows: repeat(5, auto);
  }

  .gcs5-screen4 {
    grid-column-start: 5;
  }

  .gce6-screen4 {
    grid-column-end: 6;
  }

  .grs5-screen4 {
    grid-row-start: 5;
  }

  .gre6-screen4 {
    grid-row-end: 6;
  }

  .gtcr6-screen4 {
    grid-template-columns: repeat(6, 1fr);
  }

  .ftcr6-screen4 {
    grid-column: 6 / 1fr;
  }

  .gtrr6-screen4 {
    grid-template-rows: repeat(6, 1fr);
  }

  .gtcr6a-screen4 {
    grid-template-columns: repeat(6, auto);
  }

  .gtrr6a-screen4 {
    grid-template-rows: repeat(6, auto);
  }

  .gcs6-screen4 {
    grid-column-start: 6;
  }

  .gce7-screen4 {
    grid-column-end: 7;
  }

  .grs6-screen4 {
    grid-row-start: 6;
  }

  .gre7-screen4 {
    grid-row-end: 7;
  }

  .gtcr7-screen4 {
    grid-template-columns: repeat(7, 1fr);
  }

  .ftcr7-screen4 {
    grid-column: 7 / 1fr;
  }

  .gtrr7-screen4 {
    grid-template-rows: repeat(7, 1fr);
  }

  .gtcr7a-screen4 {
    grid-template-columns: repeat(7, auto);
  }

  .gtrr7a-screen4 {
    grid-template-rows: repeat(7, auto);
  }

  .gcs7-screen4 {
    grid-column-start: 7;
  }

  .gce8-screen4 {
    grid-column-end: 8;
  }

  .grs7-screen4 {
    grid-row-start: 7;
  }

  .gre8-screen4 {
    grid-row-end: 8;
  }

  .gtcr8-screen4 {
    grid-template-columns: repeat(8, 1fr);
  }

  .ftcr8-screen4 {
    grid-column: 8 / 1fr;
  }

  .gtrr8-screen4 {
    grid-template-rows: repeat(8, 1fr);
  }

  .gtcr8a-screen4 {
    grid-template-columns: repeat(8, auto);
  }

  .gtrr8a-screen4 {
    grid-template-rows: repeat(8, auto);
  }

  .gcs8-screen4 {
    grid-column-start: 8;
  }

  .gce9-screen4 {
    grid-column-end: 9;
  }

  .grs8-screen4 {
    grid-row-start: 8;
  }

  .gre9-screen4 {
    grid-row-end: 9;
  }

  .gtcr9-screen4 {
    grid-template-columns: repeat(9, 1fr);
  }

  .ftcr9-screen4 {
    grid-column: 9 / 1fr;
  }

  .gtrr9-screen4 {
    grid-template-rows: repeat(9, 1fr);
  }

  .gtcr9a-screen4 {
    grid-template-columns: repeat(9, auto);
  }

  .gtrr9a-screen4 {
    grid-template-rows: repeat(9, auto);
  }

  .gcs9-screen4 {
    grid-column-start: 9;
  }

  .gce10-screen4 {
    grid-column-end: 10;
  }

  .grs9-screen4 {
    grid-row-start: 9;
  }

  .gre10-screen4 {
    grid-row-end: 10;
  }

  .gtccr-screen4 {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }

  .gtcr10-screen4 {
    grid-template-columns: repeat(10, 1fr);
  }

  .ftcr10-screen4 {
    grid-column: 10 / 1fr;
  }

  .gtrr10-screen4 {
    grid-template-rows: repeat(10, 1fr);
  }

  .gtcr10a-screen4 {
    grid-template-columns: repeat(10, auto);
  }

  .gtrr10a-screen4 {
    grid-template-rows: repeat(10, auto);
  }

  .gcs10-screen4 {
    grid-column-start: 10;
  }

  .gce11-screen4 {
    grid-column-end: 11;
  }

  .grs10-screen4 {
    grid-row-start: 10;
  }

  .gre11-screen4 {
    grid-row-end: 11;
  }

  .gg0-5rem-screen4 {
    grid-gap: .5rem;
  }

  .gg1rem-screen4 {
    grid-gap: 1rem;
  }

  .rg0-5rem-screen4 {
    row-gap: .5rem;
  }

  .rg1rem-screen4 {
    row-gap: 1rem;
  }

  .cg0-5rem-screen4 {
    column-gap: .5rem;
  }

  .cg1rem-screen4 {
    column-gap: 1rem;
  }

  .gg1-5rem-screen4 {
    grid-gap: 1.5rem;
  }

  .gg2rem-screen4 {
    grid-gap: 2rem;
  }

  .rg1-5rem-screen4 {
    row-gap: 1.5rem;
  }

  .rg2rem-screen4 {
    row-gap: 2rem;
  }

  .cg1-5rem-screen4 {
    column-gap: 1.5rem;
  }

  .cg2rem-screen4 {
    column-gap: 2rem;
  }

  .gg2-5rem-screen4 {
    grid-gap: 2.5rem;
  }

  .gg3rem-screen4 {
    grid-gap: 3rem;
  }

  .rg2-5rem-screen4 {
    row-gap: 2.5rem;
  }

  .rg3rem-screen4 {
    row-gap: 3rem;
  }

  .cg2-5rem-screen4 {
    column-gap: 2.5rem;
  }

  .cg3rem-screen4 {
    column-gap: 3rem;
  }

  .gg3-5rem-screen4 {
    grid-gap: 3.5rem;
  }

  .gg4rem-screen4 {
    grid-gap: 4rem;
  }

  .rg3-5rem-screen4 {
    row-gap: 3.5rem;
  }

  .rg4rem-screen4 {
    row-gap: 4rem;
  }

  .cg3-5rem-screen4 {
    column-gap: 3.5rem;
  }

  .cg4rem-screen4 {
    column-gap: 4rem;
  }

  .gg4-5rem-screen4 {
    grid-gap: 4.5rem;
  }

  .gg5rem-screen4 {
    grid-gap: 5rem;
  }

  .rg4-5rem-screen4 {
    row-gap: 4.5rem;
  }

  .rg5rem-screen4 {
    row-gap: 5rem;
  }

  .cg4-5rem-screen4 {
    column-gap: 4.5rem;
  }

  .cg5rem-screen4 {
    column-gap: 5rem;
  }

  .dn-screen4 {
    display: none;
  }

  .pa-screen4 {
    position: absolute;
  }

  .pr-screen4 {
    position: relative;
  }

  .pf-screen4 {
    position: fixed;
  }

  .ps-screen4 {
    -webkit-position: sticky;
    position: sticky;
  }

  .pst-screen4 {
    position: static;
  }

  .t0-screen4 {
    top: 0;
  }

  .r0-screen4 {
    right: 0;
  }

  .b0-screen4 {
    bottom: 0;
  }

  .l0-screen4 {
    left: 0;
  }

  .t100-screen4 {
    top: 100%;
  }

  .r100-screen4 {
    right: 100%;
  }

  .b100-screen4 {
    bottom: 100%;
  }

  .l100-screen4 {
    left: 100%;
  }

  .fl-screen4 {
    float: left;
  }

  .fr-screen4 {
    float: right;
  }

  .fn-screen4 {
    float: none;
  }

  .ma-screen4 {
    margin: auto;
  }

  .mta-screen4, .mya-screen4 {
    margin-top: auto;
  }

  .mra-screen4, .mxa-screen4 {
    margin-right: auto;
  }

  .mba-screen4, .mya-screen4 {
    margin-bottom: auto;
  }

  .mla-screen4, .mxa-screen4 {
    margin-left: auto;
  }

  .m0-screen4 {
    margin: 0;
  }

  .mt0-screen4, .my0-screen4 {
    margin-top: 0;
  }

  .mr0-screen4, .mx0-screen4 {
    margin-right: 0;
  }

  .mb0-screen4, .my0-screen4 {
    margin-bottom: 0;
  }

  .ml0-screen4, .mx0-screen4 {
    margin-left: 0;
  }

  .p0-screen4 {
    padding: 0;
  }

  .pt0-screen4, .py0-screen4 {
    padding-top: 0;
  }

  .pr0-screen4, .px0-screen4 {
    padding-right: 0;
  }

  .pb0-screen4, .py0-screen4 {
    padding-bottom: 0;
  }

  .pl0-screen4, .px0-screen4 {
    padding-left: 0;
  }

  .m10-screen4 {
    margin: 10%;
  }

  .mt10-screen4, .my10-screen4 {
    margin-top: 10%;
  }

  .mr10-screen4, .mx10-screen4 {
    margin-right: 10%;
  }

  .mb10-screen4, .my10-screen4 {
    margin-bottom: 10%;
  }

  .ml10-screen4, .mx10-screen4 {
    margin-left: 10%;
  }

  .p10-screen4 {
    padding: 10%;
  }

  .pt10-screen4, .py10-screen4 {
    padding-top: 10%;
  }

  .pr10-screen4, .px10-screen4 {
    padding-right: 10%;
  }

  .pb10-screen4, .py10-screen4 {
    padding-bottom: 10%;
  }

  .pl10-screen4, .px10-screen4 {
    padding-left: 10%;
  }

  .m0-5rem-screen4 {
    margin: .5rem;
  }

  .m1rem-screen4 {
    margin: 1rem;
  }

  .mt0-5rem-screen4, .my0-5rem-screen4 {
    margin-top: .5rem;
  }

  .mt1rem-screen4, .my1rem-screen4 {
    margin-top: 1rem;
  }

  .mr0-5rem-screen4, .mx0-5rem-screen4 {
    margin-right: .5rem;
  }

  .mr1rem-screen4, .mx1rem-screen4 {
    margin-right: 1rem;
  }

  .mb0-5rem-screen4, .my0-5rem-screen4 {
    margin-bottom: .5rem;
  }

  .mb1rem-screen4, .my1rem-screen4 {
    margin-bottom: 1rem;
  }

  .ml0-5rem-screen4, .mx0-5rem-screen4 {
    margin-left: .5rem;
  }

  .ml1rem-screen4, .mx1rem-screen4 {
    margin-left: 1rem;
  }

  .p0-5rem-screen4 {
    padding: .5rem;
  }

  .p1rem-screen4 {
    padding: 1rem;
  }

  .pt0-5rem-screen4, .py0-5rem-screen4 {
    padding-top: .5rem;
  }

  .pt1rem-screen4, .py1rem-screen4 {
    padding-top: 1rem;
  }

  .pr0-5rem-screen4, .px0-5rem-screen4 {
    padding-right: .5rem;
  }

  .pr1rem-screen4, .px1rem-screen4 {
    padding-right: 1rem;
  }

  .pb0-5rem-screen4, .py0-5rem-screen4 {
    padding-bottom: .5rem;
  }

  .pb1rem-screen4, .py1rem-screen4 {
    padding-bottom: 1rem;
  }

  .pl0-5rem-screen4, .px0-5rem-screen4 {
    padding-left: .5rem;
  }

  .pl1rem-screen4, .px1rem-screen4 {
    padding-left: 1rem;
  }

  .m20-screen4 {
    margin: 20%;
  }

  .mt20-screen4, .my20-screen4 {
    margin-top: 20%;
  }

  .mr20-screen4, .mx20-screen4 {
    margin-right: 20%;
  }

  .mb20-screen4, .my20-screen4 {
    margin-bottom: 20%;
  }

  .ml20-screen4, .mx20-screen4 {
    margin-left: 20%;
  }

  .p20-screen4 {
    padding: 20%;
  }

  .pt20-screen4, .py20-screen4 {
    padding-top: 20%;
  }

  .pr20-screen4, .px20-screen4 {
    padding-right: 20%;
  }

  .pb20-screen4, .py20-screen4 {
    padding-bottom: 20%;
  }

  .pl20-screen4, .px20-screen4 {
    padding-left: 20%;
  }

  .m1-5rem-screen4 {
    margin: 1.5rem;
  }

  .m2rem-screen4 {
    margin: 2rem;
  }

  .mt1-5rem-screen4, .my1-5rem-screen4 {
    margin-top: 1.5rem;
  }

  .mt2rem-screen4, .my2rem-screen4 {
    margin-top: 2rem;
  }

  .mr1-5rem-screen4, .mx1-5rem-screen4 {
    margin-right: 1.5rem;
  }

  .mr2rem-screen4, .mx2rem-screen4 {
    margin-right: 2rem;
  }

  .mb1-5rem-screen4, .my1-5rem-screen4 {
    margin-bottom: 1.5rem;
  }

  .mb2rem-screen4, .my2rem-screen4 {
    margin-bottom: 2rem;
  }

  .ml1-5rem-screen4, .mx1-5rem-screen4 {
    margin-left: 1.5rem;
  }

  .ml2rem-screen4, .mx2rem-screen4 {
    margin-left: 2rem;
  }

  .p1-5rem-screen4 {
    padding: 1.5rem;
  }

  .p2rem-screen4 {
    padding: 2rem;
  }

  .pt1-5rem-screen4, .py1-5rem-screen4 {
    padding-top: 1.5rem;
  }

  .pt2rem-screen4, .py2rem-screen4 {
    padding-top: 2rem;
  }

  .pr1-5rem-screen4, .px1-5rem-screen4 {
    padding-right: 1.5rem;
  }

  .pr2rem-screen4, .px2rem-screen4 {
    padding-right: 2rem;
  }

  .pb1-5rem-screen4, .py1-5rem-screen4 {
    padding-bottom: 1.5rem;
  }

  .pb2rem-screen4, .py2rem-screen4 {
    padding-bottom: 2rem;
  }

  .pl1-5rem-screen4, .px1-5rem-screen4 {
    padding-left: 1.5rem;
  }

  .pl2rem-screen4, .px2rem-screen4 {
    padding-left: 2rem;
  }

  .m30-screen4 {
    margin: 30%;
  }

  .mt30-screen4, .my30-screen4 {
    margin-top: 30%;
  }

  .mr30-screen4, .mx30-screen4 {
    margin-right: 30%;
  }

  .mb30-screen4, .my30-screen4 {
    margin-bottom: 30%;
  }

  .ml30-screen4, .mx30-screen4 {
    margin-left: 30%;
  }

  .p30-screen4 {
    padding: 30%;
  }

  .pt30-screen4, .py30-screen4 {
    padding-top: 30%;
  }

  .pr30-screen4, .px30-screen4 {
    padding-right: 30%;
  }

  .pb30-screen4, .py30-screen4 {
    padding-bottom: 30%;
  }

  .pl30-screen4, .px30-screen4 {
    padding-left: 30%;
  }

  .m2-5rem-screen4 {
    margin: 2.5rem;
  }

  .m3rem-screen4 {
    margin: 3rem;
  }

  .mt2-5rem-screen4, .my2-5rem-screen4 {
    margin-top: 2.5rem;
  }

  .mt3rem-screen4, .my3rem-screen4 {
    margin-top: 3rem;
  }

  .mr2-5rem-screen4, .mx2-5rem-screen4 {
    margin-right: 2.5rem;
  }

  .mr3rem-screen4, .mx3rem-screen4 {
    margin-right: 3rem;
  }

  .mb2-5rem-screen4, .my2-5rem-screen4 {
    margin-bottom: 2.5rem;
  }

  .mb3rem-screen4, .my3rem-screen4 {
    margin-bottom: 3rem;
  }

  .ml2-5rem-screen4, .mx2-5rem-screen4 {
    margin-left: 2.5rem;
  }

  .ml3rem-screen4, .mx3rem-screen4 {
    margin-left: 3rem;
  }

  .p2-5rem-screen4 {
    padding: 2.5rem;
  }

  .p3rem-screen4 {
    padding: 3rem;
  }

  .pt2-5rem-screen4, .py2-5rem-screen4 {
    padding-top: 2.5rem;
  }

  .pt3rem-screen4, .py3rem-screen4 {
    padding-top: 3rem;
  }

  .pr2-5rem-screen4, .px2-5rem-screen4 {
    padding-right: 2.5rem;
  }

  .pr3rem-screen4, .px3rem-screen4 {
    padding-right: 3rem;
  }

  .pb2-5rem-screen4, .py2-5rem-screen4 {
    padding-bottom: 2.5rem;
  }

  .pb3rem-screen4, .py3rem-screen4 {
    padding-bottom: 3rem;
  }

  .pl2-5rem-screen4, .px2-5rem-screen4 {
    padding-left: 2.5rem;
  }

  .pl3rem-screen4, .px3rem-screen4 {
    padding-left: 3rem;
  }

  .m40-screen4 {
    margin: 40%;
  }

  .mt40-screen4, .my40-screen4 {
    margin-top: 40%;
  }

  .mr40-screen4, .mx40-screen4 {
    margin-right: 40%;
  }

  .mb40-screen4, .my40-screen4 {
    margin-bottom: 40%;
  }

  .ml40-screen4, .mx40-screen4 {
    margin-left: 40%;
  }

  .p40-screen4 {
    padding: 40%;
  }

  .pt40-screen4, .py40-screen4 {
    padding-top: 40%;
  }

  .pr40-screen4, .px40-screen4 {
    padding-right: 40%;
  }

  .pb40-screen4, .py40-screen4 {
    padding-bottom: 40%;
  }

  .pl40-screen4, .px40-screen4 {
    padding-left: 40%;
  }

  .m3-5rem-screen4 {
    margin: 3.5rem;
  }

  .m4rem-screen4 {
    margin: 4rem;
  }

  .mt3-5rem-screen4, .my3-5rem-screen4 {
    margin-top: 3.5rem;
  }

  .mt4rem-screen4, .my4rem-screen4 {
    margin-top: 4rem;
  }

  .mr3-5rem-screen4, .mx3-5rem-screen4 {
    margin-right: 3.5rem;
  }

  .mr4rem-screen4, .mx4rem-screen4 {
    margin-right: 4rem;
  }

  .mb3-5rem-screen4, .my3-5rem-screen4 {
    margin-bottom: 3.5rem;
  }

  .mb4rem-screen4, .my4rem-screen4 {
    margin-bottom: 4rem;
  }

  .ml3-5rem-screen4, .mx3-5rem-screen4 {
    margin-left: 3.5rem;
  }

  .ml4rem-screen4, .mx4rem-screen4 {
    margin-left: 4rem;
  }

  .p3-5rem-screen4 {
    padding: 3.5rem;
  }

  .p4rem-screen4 {
    padding: 4rem;
  }

  .pt3-5rem-screen4, .py3-5rem-screen4 {
    padding-top: 3.5rem;
  }

  .pt4rem-screen4, .py4rem-screen4 {
    padding-top: 4rem;
  }

  .pr3-5rem-screen4, .px3-5rem-screen4 {
    padding-right: 3.5rem;
  }

  .pr4rem-screen4, .px4rem-screen4 {
    padding-right: 4rem;
  }

  .pb3-5rem-screen4, .py3-5rem-screen4 {
    padding-bottom: 3.5rem;
  }

  .pb4rem-screen4, .py4rem-screen4 {
    padding-bottom: 4rem;
  }

  .pl3-5rem-screen4, .px3-5rem-screen4 {
    padding-left: 3.5rem;
  }

  .pl4rem-screen4, .px4rem-screen4 {
    padding-left: 4rem;
  }

  .m50-screen4 {
    margin: 50%;
  }

  .mt50-screen4, .my50-screen4 {
    margin-top: 50%;
  }

  .mr50-screen4, .mx50-screen4 {
    margin-right: 50%;
  }

  .mb50-screen4, .my50-screen4 {
    margin-bottom: 50%;
  }

  .ml50-screen4, .mx50-screen4 {
    margin-left: 50%;
  }

  .p50-screen4 {
    padding: 50%;
  }

  .pt50-screen4, .py50-screen4 {
    padding-top: 50%;
  }

  .pr50-screen4, .px50-screen4 {
    padding-right: 50%;
  }

  .pb50-screen4, .py50-screen4 {
    padding-bottom: 50%;
  }

  .pl50-screen4, .px50-screen4 {
    padding-left: 50%;
  }

  .m4-5rem-screen4 {
    margin: 4.5rem;
  }

  .m5rem-screen4 {
    margin: 5rem;
  }

  .mt4-5rem-screen4, .my4-5rem-screen4 {
    margin-top: 4.5rem;
  }

  .mt5rem-screen4, .my5rem-screen4 {
    margin-top: 5rem;
  }

  .mr4-5rem-screen4, .mx4-5rem-screen4 {
    margin-right: 4.5rem;
  }

  .mr5rem-screen4, .mx5rem-screen4 {
    margin-right: 5rem;
  }

  .mb4-5rem-screen4, .my4-5rem-screen4 {
    margin-bottom: 4.5rem;
  }

  .mb5rem-screen4, .my5rem-screen4 {
    margin-bottom: 5rem;
  }

  .ml4-5rem-screen4, .mx4-5rem-screen4 {
    margin-left: 4.5rem;
  }

  .ml5rem-screen4, .mx5rem-screen4 {
    margin-left: 5rem;
  }

  .p4-5rem-screen4 {
    padding: 4.5rem;
  }

  .p5rem-screen4 {
    padding: 5rem;
  }

  .pt4-5rem-screen4, .py4-5rem-screen4 {
    padding-top: 4.5rem;
  }

  .pt5rem-screen4, .py5rem-screen4 {
    padding-top: 5rem;
  }

  .pr4-5rem-screen4, .px4-5rem-screen4 {
    padding-right: 4.5rem;
  }

  .pr5rem-screen4, .px5rem-screen4 {
    padding-right: 5rem;
  }

  .pb4-5rem-screen4, .py4-5rem-screen4 {
    padding-bottom: 4.5rem;
  }

  .pb5rem-screen4, .py5rem-screen4 {
    padding-bottom: 5rem;
  }

  .pl4-5rem-screen4, .px4-5rem-screen4 {
    padding-left: 4.5rem;
  }

  .pl5rem-screen4, .px5rem-screen4 {
    padding-left: 5rem;
  }

  .m60-screen4 {
    margin: 60%;
  }

  .mt60-screen4, .my60-screen4 {
    margin-top: 60%;
  }

  .mr60-screen4, .mx60-screen4 {
    margin-right: 60%;
  }

  .mb60-screen4, .my60-screen4 {
    margin-bottom: 60%;
  }

  .ml60-screen4, .mx60-screen4 {
    margin-left: 60%;
  }

  .p60-screen4 {
    padding: 60%;
  }

  .pt60-screen4, .py60-screen4 {
    padding-top: 60%;
  }

  .pr60-screen4, .px60-screen4 {
    padding-right: 60%;
  }

  .pb60-screen4, .py60-screen4 {
    padding-bottom: 60%;
  }

  .pl60-screen4, .px60-screen4 {
    padding-left: 60%;
  }

  .m5-5rem-screen4 {
    margin: 5.5rem;
  }

  .m6rem-screen4 {
    margin: 6rem;
  }

  .mt5-5rem-screen4, .my5-5rem-screen4 {
    margin-top: 5.5rem;
  }

  .mt6rem-screen4, .my6rem-screen4 {
    margin-top: 6rem;
  }

  .mr5-5rem-screen4, .mx5-5rem-screen4 {
    margin-right: 5.5rem;
  }

  .mr6rem-screen4, .mx6rem-screen4 {
    margin-right: 6rem;
  }

  .mb5-5rem-screen4, .my5-5rem-screen4 {
    margin-bottom: 5.5rem;
  }

  .mb6rem-screen4, .my6rem-screen4 {
    margin-bottom: 6rem;
  }

  .ml5-5rem-screen4, .mx5-5rem-screen4 {
    margin-left: 5.5rem;
  }

  .ml6rem-screen4, .mx6rem-screen4 {
    margin-left: 6rem;
  }

  .p5-5rem-screen4 {
    padding: 5.5rem;
  }

  .p6rem-screen4 {
    padding: 6rem;
  }

  .pt5-5rem-screen4, .py5-5rem-screen4 {
    padding-top: 5.5rem;
  }

  .pt6rem-screen4, .py6rem-screen4 {
    padding-top: 6rem;
  }

  .pr5-5rem-screen4, .px5-5rem-screen4 {
    padding-right: 5.5rem;
  }

  .pr6rem-screen4, .px6rem-screen4 {
    padding-right: 6rem;
  }

  .pb5-5rem-screen4, .py5-5rem-screen4 {
    padding-bottom: 5.5rem;
  }

  .pb6rem-screen4, .py6rem-screen4 {
    padding-bottom: 6rem;
  }

  .pl5-5rem-screen4, .px5-5rem-screen4 {
    padding-left: 5.5rem;
  }

  .pl6rem-screen4, .px6rem-screen4 {
    padding-left: 6rem;
  }

  .m70-screen4 {
    margin: 70%;
  }

  .mt70-screen4, .my70-screen4 {
    margin-top: 70%;
  }

  .mr70-screen4, .mx70-screen4 {
    margin-right: 70%;
  }

  .mb70-screen4, .my70-screen4 {
    margin-bottom: 70%;
  }

  .ml70-screen4, .mx70-screen4 {
    margin-left: 70%;
  }

  .p70-screen4 {
    padding: 70%;
  }

  .pt70-screen4, .py70-screen4 {
    padding-top: 70%;
  }

  .pr70-screen4, .px70-screen4 {
    padding-right: 70%;
  }

  .pb70-screen4, .py70-screen4 {
    padding-bottom: 70%;
  }

  .pl70-screen4, .px70-screen4 {
    padding-left: 70%;
  }

  .m6-5rem-screen4 {
    margin: 6.5rem;
  }

  .m7rem-screen4 {
    margin: 7rem;
  }

  .mt6-5rem-screen4, .my6-5rem-screen4 {
    margin-top: 6.5rem;
  }

  .mt7rem-screen4, .my7rem-screen4 {
    margin-top: 7rem;
  }

  .mr6-5rem-screen4, .mx6-5rem-screen4 {
    margin-right: 6.5rem;
  }

  .mr7rem-screen4, .mx7rem-screen4 {
    margin-right: 7rem;
  }

  .mb6-5rem-screen4, .my6-5rem-screen4 {
    margin-bottom: 6.5rem;
  }

  .mb7rem-screen4, .my7rem-screen4 {
    margin-bottom: 7rem;
  }

  .ml6-5rem-screen4, .mx6-5rem-screen4 {
    margin-left: 6.5rem;
  }

  .ml7rem-screen4, .mx7rem-screen4 {
    margin-left: 7rem;
  }

  .p6-5rem-screen4 {
    padding: 6.5rem;
  }

  .p7rem-screen4 {
    padding: 7rem;
  }

  .pt6-5rem-screen4, .py6-5rem-screen4 {
    padding-top: 6.5rem;
  }

  .pt7rem-screen4, .py7rem-screen4 {
    padding-top: 7rem;
  }

  .pr6-5rem-screen4, .px6-5rem-screen4 {
    padding-right: 6.5rem;
  }

  .pr7rem-screen4, .px7rem-screen4 {
    padding-right: 7rem;
  }

  .pb6-5rem-screen4, .py6-5rem-screen4 {
    padding-bottom: 6.5rem;
  }

  .pb7rem-screen4, .py7rem-screen4 {
    padding-bottom: 7rem;
  }

  .pl6-5rem-screen4, .px6-5rem-screen4 {
    padding-left: 6.5rem;
  }

  .pl7rem-screen4, .px7rem-screen4 {
    padding-left: 7rem;
  }

  .m80-screen4 {
    margin: 80%;
  }

  .mt80-screen4, .my80-screen4 {
    margin-top: 80%;
  }

  .mr80-screen4, .mx80-screen4 {
    margin-right: 80%;
  }

  .mb80-screen4, .my80-screen4 {
    margin-bottom: 80%;
  }

  .ml80-screen4, .mx80-screen4 {
    margin-left: 80%;
  }

  .p80-screen4 {
    padding: 80%;
  }

  .pt80-screen4, .py80-screen4 {
    padding-top: 80%;
  }

  .pr80-screen4, .px80-screen4 {
    padding-right: 80%;
  }

  .pb80-screen4, .py80-screen4 {
    padding-bottom: 80%;
  }

  .pl80-screen4, .px80-screen4 {
    padding-left: 80%;
  }

  .m7-5rem-screen4 {
    margin: 7.5rem;
  }

  .m8rem-screen4 {
    margin: 8rem;
  }

  .mt7-5rem-screen4, .my7-5rem-screen4 {
    margin-top: 7.5rem;
  }

  .mt8rem-screen4, .my8rem-screen4 {
    margin-top: 8rem;
  }

  .mr7-5rem-screen4, .mx7-5rem-screen4 {
    margin-right: 7.5rem;
  }

  .mr8rem-screen4, .mx8rem-screen4 {
    margin-right: 8rem;
  }

  .mb7-5rem-screen4, .my7-5rem-screen4 {
    margin-bottom: 7.5rem;
  }

  .mb8rem-screen4, .my8rem-screen4 {
    margin-bottom: 8rem;
  }

  .ml7-5rem-screen4, .mx7-5rem-screen4 {
    margin-left: 7.5rem;
  }

  .ml8rem-screen4, .mx8rem-screen4 {
    margin-left: 8rem;
  }

  .p7-5rem-screen4 {
    padding: 7.5rem;
  }

  .p8rem-screen4 {
    padding: 8rem;
  }

  .pt7-5rem-screen4, .py7-5rem-screen4 {
    padding-top: 7.5rem;
  }

  .pt8rem-screen4, .py8rem-screen4 {
    padding-top: 8rem;
  }

  .pr7-5rem-screen4, .px7-5rem-screen4 {
    padding-right: 7.5rem;
  }

  .pr8rem-screen4, .px8rem-screen4 {
    padding-right: 8rem;
  }

  .pb7-5rem-screen4, .py7-5rem-screen4 {
    padding-bottom: 7.5rem;
  }

  .pb8rem-screen4, .py8rem-screen4 {
    padding-bottom: 8rem;
  }

  .pl7-5rem-screen4, .px7-5rem-screen4 {
    padding-left: 7.5rem;
  }

  .pl8rem-screen4, .px8rem-screen4 {
    padding-left: 8rem;
  }

  .m90-screen4 {
    margin: 90%;
  }

  .mt90-screen4, .my90-screen4 {
    margin-top: 90%;
  }

  .mr90-screen4, .mx90-screen4 {
    margin-right: 90%;
  }

  .mb90-screen4, .my90-screen4 {
    margin-bottom: 90%;
  }

  .ml90-screen4, .mx90-screen4 {
    margin-left: 90%;
  }

  .p90-screen4 {
    padding: 90%;
  }

  .pt90-screen4, .py90-screen4 {
    padding-top: 90%;
  }

  .pr90-screen4, .px90-screen4 {
    padding-right: 90%;
  }

  .pb90-screen4, .py90-screen4 {
    padding-bottom: 90%;
  }

  .pl90-screen4, .px90-screen4 {
    padding-left: 90%;
  }

  .m8-5rem-screen4 {
    margin: 8.5rem;
  }

  .m9rem-screen4 {
    margin: 9rem;
  }

  .mt8-5rem-screen4, .my8-5rem-screen4 {
    margin-top: 8.5rem;
  }

  .mt9rem-screen4, .my9rem-screen4 {
    margin-top: 9rem;
  }

  .mr8-5rem-screen4, .mx8-5rem-screen4 {
    margin-right: 8.5rem;
  }

  .mr9rem-screen4, .mx9rem-screen4 {
    margin-right: 9rem;
  }

  .mb8-5rem-screen4, .my8-5rem-screen4 {
    margin-bottom: 8.5rem;
  }

  .mb9rem-screen4, .my9rem-screen4 {
    margin-bottom: 9rem;
  }

  .ml8-5rem-screen4, .mx8-5rem-screen4 {
    margin-left: 8.5rem;
  }

  .ml9rem-screen4, .mx9rem-screen4 {
    margin-left: 9rem;
  }

  .p8-5rem-screen4 {
    padding: 8.5rem;
  }

  .p9rem-screen4 {
    padding: 9rem;
  }

  .pt8-5rem-screen4, .py8-5rem-screen4 {
    padding-top: 8.5rem;
  }

  .pt9rem-screen4, .py9rem-screen4 {
    padding-top: 9rem;
  }

  .pr8-5rem-screen4, .px8-5rem-screen4 {
    padding-right: 8.5rem;
  }

  .pr9rem-screen4, .px9rem-screen4 {
    padding-right: 9rem;
  }

  .pb8-5rem-screen4, .py8-5rem-screen4 {
    padding-bottom: 8.5rem;
  }

  .pb9rem-screen4, .py9rem-screen4 {
    padding-bottom: 9rem;
  }

  .pl8-5rem-screen4, .px8-5rem-screen4 {
    padding-left: 8.5rem;
  }

  .pl9rem-screen4, .px9rem-screen4 {
    padding-left: 9rem;
  }

  .m100-screen4 {
    margin: 100%;
  }

  .mt100-screen4, .my100-screen4 {
    margin-top: 100%;
  }

  .mr100-screen4, .mx100-screen4 {
    margin-right: 100%;
  }

  .mb100-screen4, .my100-screen4 {
    margin-bottom: 100%;
  }

  .ml100-screen4, .mx100-screen4 {
    margin-left: 100%;
  }

  .p100-screen4 {
    padding: 100%;
  }

  .pt100-screen4, .py100-screen4 {
    padding-top: 100%;
  }

  .pr100-screen4, .px100-screen4 {
    padding-right: 100%;
  }

  .pb100-screen4, .py100-screen4 {
    padding-bottom: 100%;
  }

  .pl100-screen4, .px100-screen4 {
    padding-left: 100%;
  }

  .m9-5rem-screen4 {
    margin: 9.5rem;
  }

  .m10rem-screen4 {
    margin: 10rem;
  }

  .mt9-5rem-screen4, .my9-5rem-screen4 {
    margin-top: 9.5rem;
  }

  .mt10rem-screen4, .my10rem-screen4 {
    margin-top: 10rem;
  }

  .mr9-5rem-screen4, .mx9-5rem-screen4 {
    margin-right: 9.5rem;
  }

  .mr10rem-screen4, .mx10rem-screen4 {
    margin-right: 10rem;
  }

  .mb9-5rem-screen4, .my9-5rem-screen4 {
    margin-bottom: 9.5rem;
  }

  .mb10rem-screen4, .my10rem-screen4 {
    margin-bottom: 10rem;
  }

  .ml9-5rem-screen4, .mx9-5rem-screen4 {
    margin-left: 9.5rem;
  }

  .ml10rem-screen4, .mx10rem-screen4 {
    margin-left: 10rem;
  }

  .p9-5rem-screen4 {
    padding: 9.5rem;
  }

  .p10rem-screen4 {
    padding: 10rem;
  }

  .pt9-5rem-screen4, .py9-5rem-screen4 {
    padding-top: 9.5rem;
  }

  .pt10rem-screen4, .py10rem-screen4 {
    padding-top: 10rem;
  }

  .pr9-5rem-screen4, .px9-5rem-screen4 {
    padding-right: 9.5rem;
  }

  .pr10rem-screen4, .px10rem-screen4 {
    padding-right: 10rem;
  }

  .pb9-5rem-screen4, .py9-5rem-screen4 {
    padding-bottom: 9.5rem;
  }

  .pb10rem-screen4, .py10rem-screen4 {
    padding-bottom: 10rem;
  }

  .pl9-5rem-screen4, .px9-5rem-screen4 {
    padding-left: 9.5rem;
  }

  .pl10rem-screen4, .px10rem-screen4 {
    padding-left: 10rem;
  }

  .wa-screen4 {
    width: auto;
  }

  .ha-screen4 {
    height: auto;
  }

  .w0-screen4 {
    width: 0;
  }

  .h0-screen4 {
    height: 0;
  }

  .maw0-screen4 {
    max-width: 0;
  }

  .mah0-screen4 {
    max-height: 0;
  }

  .mih0-screen4 {
    min-height: 0;
  }

  .w1rem-screen4 {
    width: 1rem;
  }

  .w10px-screen4 {
    width: 10px;
  }

  .w10-screen4 {
    width: 10%;
  }

  .w10vw-screen4 {
    width: 10vw;
  }

  .h1rem-screen4 {
    height: 1rem;
  }

  .h10px-screen4 {
    height: 10px;
  }

  .h10-screen4 {
    height: 10%;
  }

  .h10vh-screen4 {
    height: 10vh;
  }

  .miw1rem-screen4 {
    min-width: 1rem;
  }

  .miw100px-screen4 {
    min-width: 100px;
  }

  .miw10-screen4 {
    min-width: 10%;
  }

  .miw10vw-screen4 {
    min-width: 10vw;
  }

  .mih1rem-screen4 {
    min-height: 1rem;
  }

  .mih100px-screen4 {
    min-height: 100px;
  }

  .mih10-screen4 {
    min-height: 10%;
  }

  .mih10vh-screen4 {
    min-height: 10vh;
  }

  .maw1rem-screen4 {
    max-width: 1rem;
  }

  .maw100px-screen4 {
    max-width: 100px;
  }

  .maw10-screen4 {
    max-width: 10%;
  }

  .maw10vw-screen4 {
    max-width: 10vw;
  }

  .mah1rem-screen4 {
    max-height: 1rem;
  }

  .mah100px-screen4 {
    max-height: 100px;
  }

  .mah10-screen4 {
    max-height: 10%;
  }

  .mah10vh-screen4 {
    max-height: 10vh;
  }

  .w2rem-screen4 {
    width: 2rem;
  }

  .w20px-screen4 {
    width: 20px;
  }

  .w200px-screen4 {
    width: 200px;
  }

  .w20-screen4 {
    width: 20%;
  }

  .w20vw-screen4 {
    width: 20vw;
  }

  .h2rem-screen4 {
    height: 2rem;
  }

  .h20px-screen4 {
    height: 20px;
  }

  .h200px-screen4 {
    height: 200px;
  }

  .h20-screen4 {
    height: 20%;
  }

  .h20vh-screen4 {
    height: 20vh;
  }

  .miw2rem-screen4 {
    min-width: 2rem;
  }

  .miw200px-screen4 {
    min-width: 200px;
  }

  .miw20-screen4 {
    min-width: 20%;
  }

  .miw20vw-screen4 {
    min-width: 20vw;
  }

  .mih2rem-screen4 {
    min-height: 2rem;
  }

  .mih200px-screen4 {
    min-height: 200px;
  }

  .mih20-screen4 {
    min-height: 20%;
  }

  .mih20vh-screen4 {
    min-height: 20vh;
  }

  .maw2rem-screen4 {
    max-width: 2rem;
  }

  .maw200px-screen4 {
    max-width: 200px;
  }

  .maw20-screen4 {
    max-width: 20%;
  }

  .maw20vw-screen4 {
    max-width: 20vw;
  }

  .mah2rem-screen4 {
    max-height: 2rem;
  }

  .mah200px-screen4 {
    max-height: 200px;
  }

  .mah20-screen4 {
    max-height: 20%;
  }

  .mah20vh-screen4 {
    max-height: 20vh;
  }

  .w3rem-screen4 {
    width: 3rem;
  }

  .w30px-screen4 {
    width: 30px;
  }

  .w300px-screen4 {
    width: 300px;
  }

  .w30-screen4 {
    width: 30%;
  }

  .w30vw-screen4 {
    width: 30vw;
  }

  .h3rem-screen4 {
    height: 3rem;
  }

  .h30px-screen4 {
    height: 30px;
  }

  .h300px-screen4 {
    height: 300px;
  }

  .h30-screen4 {
    height: 30%;
  }

  .h30vh-screen4 {
    height: 30vh;
  }

  .miw3rem-screen4 {
    min-width: 3rem;
  }

  .miw300px-screen4 {
    min-width: 300px;
  }

  .miw30-screen4 {
    min-width: 30%;
  }

  .miw30vw-screen4 {
    min-width: 30vw;
  }

  .mih3rem-screen4 {
    min-height: 3rem;
  }

  .mih300px-screen4 {
    min-height: 300px;
  }

  .mih30-screen4 {
    min-height: 30%;
  }

  .mih30vh-screen4 {
    min-height: 30vh;
  }

  .maw3rem-screen4 {
    max-width: 3rem;
  }

  .maw300px-screen4 {
    max-width: 300px;
  }

  .maw30-screen4 {
    max-width: 30%;
  }

  .maw30vw-screen4 {
    max-width: 30vw;
  }

  .mah3rem-screen4 {
    max-height: 3rem;
  }

  .mah300px-screen4 {
    max-height: 300px;
  }

  .mah30-screen4 {
    max-height: 30%;
  }

  .mah30vh-screen4 {
    max-height: 30vh;
  }

  .w4rem-screen4 {
    width: 4rem;
  }

  .w40px-screen4 {
    width: 40px;
  }

  .w400px-screen4 {
    width: 400px;
  }

  .w40-screen4 {
    width: 40%;
  }

  .w40vw-screen4 {
    width: 40vw;
  }

  .h4rem-screen4 {
    height: 4rem;
  }

  .h40px-screen4 {
    height: 40px;
  }

  .h400px-screen4 {
    height: 400px;
  }

  .h40-screen4 {
    height: 40%;
  }

  .h40vh-screen4 {
    height: 40vh;
  }

  .miw4rem-screen4 {
    min-width: 4rem;
  }

  .miw400px-screen4 {
    min-width: 400px;
  }

  .miw40-screen4 {
    min-width: 40%;
  }

  .miw40vw-screen4 {
    min-width: 40vw;
  }

  .mih4rem-screen4 {
    min-height: 4rem;
  }

  .mih400px-screen4 {
    min-height: 400px;
  }

  .mih40-screen4 {
    min-height: 40%;
  }

  .mih40vh-screen4 {
    min-height: 40vh;
  }

  .maw4rem-screen4 {
    max-width: 4rem;
  }

  .maw400px-screen4 {
    max-width: 400px;
  }

  .maw40-screen4 {
    max-width: 40%;
  }

  .maw40vw-screen4 {
    max-width: 40vw;
  }

  .mah4rem-screen4 {
    max-height: 4rem;
  }

  .mah400px-screen4 {
    max-height: 400px;
  }

  .mah40-screen4 {
    max-height: 40%;
  }

  .mah40vh-screen4 {
    max-height: 40vh;
  }

  .w5rem-screen4 {
    width: 5rem;
  }

  .w50px-screen4 {
    width: 50px;
  }

  .w500px-screen4 {
    width: 500px;
  }

  .w50-screen4 {
    width: 50%;
  }

  .w50vw-screen4 {
    width: 50vw;
  }

  .h5rem-screen4 {
    height: 5rem;
  }

  .h50px-screen4 {
    height: 50px;
  }

  .h500px-screen4 {
    height: 500px;
  }

  .h50-screen4 {
    height: 50%;
  }

  .h50vh-screen4 {
    height: 50vh;
  }

  .miw5rem-screen4 {
    min-width: 5rem;
  }

  .miw500px-screen4 {
    min-width: 500px;
  }

  .miw50-screen4 {
    min-width: 50%;
  }

  .miw50vw-screen4 {
    min-width: 50vw;
  }

  .mih5rem-screen4 {
    min-height: 5rem;
  }

  .mih500px-screen4 {
    min-height: 500px;
  }

  .mih50-screen4 {
    min-height: 50%;
  }

  .mih50vh-screen4 {
    min-height: 50vh;
  }

  .maw5rem-screen4 {
    max-width: 5rem;
  }

  .maw500px-screen4 {
    max-width: 500px;
  }

  .maw50-screen4 {
    max-width: 50%;
  }

  .maw50vw-screen4 {
    max-width: 50vw;
  }

  .mah5rem-screen4 {
    max-height: 5rem;
  }

  .mah500px-screen4 {
    max-height: 500px;
  }

  .mah50-screen4 {
    max-height: 50%;
  }

  .mah50vh-screen4 {
    max-height: 50vh;
  }

  .w6rem-screen4 {
    width: 6rem;
  }

  .w60px-screen4 {
    width: 60px;
  }

  .w600px-screen4 {
    width: 600px;
  }

  .w60-screen4 {
    width: 60%;
  }

  .w60vw-screen4 {
    width: 60vw;
  }

  .h6rem-screen4 {
    height: 6rem;
  }

  .h60px-screen4 {
    height: 60px;
  }

  .h600px-screen4 {
    height: 600px;
  }

  .h60-screen4 {
    height: 60%;
  }

  .h60vh-screen4 {
    height: 60vh;
  }

  .miw6rem-screen4 {
    min-width: 6rem;
  }

  .miw600px-screen4 {
    min-width: 600px;
  }

  .miw60-screen4 {
    min-width: 60%;
  }

  .miw60vw-screen4 {
    min-width: 60vw;
  }

  .mih6rem-screen4 {
    min-height: 6rem;
  }

  .mih600px-screen4 {
    min-height: 600px;
  }

  .mih60-screen4 {
    min-height: 60%;
  }

  .mih60vh-screen4 {
    min-height: 60vh;
  }

  .maw6rem-screen4 {
    max-width: 6rem;
  }

  .maw600px-screen4 {
    max-width: 600px;
  }

  .maw60-screen4 {
    max-width: 60%;
  }

  .maw60vw-screen4 {
    max-width: 60vw;
  }

  .mah6rem-screen4 {
    max-height: 6rem;
  }

  .mah600px-screen4 {
    max-height: 600px;
  }

  .mah60-screen4 {
    max-height: 60%;
  }

  .mah60vh-screen4 {
    max-height: 60vh;
  }

  .w7rem-screen4 {
    width: 7rem;
  }

  .w70px-screen4 {
    width: 70px;
  }

  .w700px-screen4 {
    width: 700px;
  }

  .w70-screen4 {
    width: 70%;
  }

  .w70vw-screen4 {
    width: 70vw;
  }

  .h7rem-screen4 {
    height: 7rem;
  }

  .h70px-screen4 {
    height: 70px;
  }

  .h700px-screen4 {
    height: 700px;
  }

  .h70-screen4 {
    height: 70%;
  }

  .h70vh-screen4 {
    height: 70vh;
  }

  .miw7rem-screen4 {
    min-width: 7rem;
  }

  .miw700px-screen4 {
    min-width: 700px;
  }

  .miw70-screen4 {
    min-width: 70%;
  }

  .miw70vw-screen4 {
    min-width: 70vw;
  }

  .mih7rem-screen4 {
    min-height: 7rem;
  }

  .mih700px-screen4 {
    min-height: 700px;
  }

  .mih70-screen4 {
    min-height: 70%;
  }

  .mih70vh-screen4 {
    min-height: 70vh;
  }

  .maw7rem-screen4 {
    max-width: 7rem;
  }

  .maw700px-screen4 {
    max-width: 700px;
  }

  .maw70-screen4 {
    max-width: 70%;
  }

  .maw70vw-screen4 {
    max-width: 70vw;
  }

  .mah7rem-screen4 {
    max-height: 7rem;
  }

  .mah700px-screen4 {
    max-height: 700px;
  }

  .mah70-screen4 {
    max-height: 70%;
  }

  .mah70vh-screen4 {
    max-height: 70vh;
  }

  .w8rem-screen4 {
    width: 8rem;
  }

  .w80px-screen4 {
    width: 80px;
  }

  .w800px-screen4 {
    width: 800px;
  }

  .w80-screen4 {
    width: 80%;
  }

  .w80vw-screen4 {
    width: 80vw;
  }

  .h8rem-screen4 {
    height: 8rem;
  }

  .h80px-screen4 {
    height: 80px;
  }

  .h800px-screen4 {
    height: 800px;
  }

  .h80-screen4 {
    height: 80%;
  }

  .h80vh-screen4 {
    height: 80vh;
  }

  .miw8rem-screen4 {
    min-width: 8rem;
  }

  .miw800px-screen4 {
    min-width: 800px;
  }

  .miw80-screen4 {
    min-width: 80%;
  }

  .miw80vw-screen4 {
    min-width: 80vw;
  }

  .mih8rem-screen4 {
    min-height: 8rem;
  }

  .mih800px-screen4 {
    min-height: 800px;
  }

  .mih80-screen4 {
    min-height: 80%;
  }

  .mih80vh-screen4 {
    min-height: 80vh;
  }

  .maw8rem-screen4 {
    max-width: 8rem;
  }

  .maw800px-screen4 {
    max-width: 800px;
  }

  .maw80-screen4 {
    max-width: 80%;
  }

  .maw80vw-screen4 {
    max-width: 80vw;
  }

  .mah8rem-screen4 {
    max-height: 8rem;
  }

  .mah800px-screen4 {
    max-height: 800px;
  }

  .mah80-screen4 {
    max-height: 80%;
  }

  .mah80vh-screen4 {
    max-height: 80vh;
  }

  .w9rem-screen4 {
    width: 9rem;
  }

  .w90px-screen4 {
    width: 90px;
  }

  .w900px-screen4 {
    width: 900px;
  }

  .w90-screen4 {
    width: 90%;
  }

  .w90vw-screen4 {
    width: 90vw;
  }

  .h9rem-screen4 {
    height: 9rem;
  }

  .h90px-screen4 {
    height: 90px;
  }

  .h900px-screen4 {
    height: 900px;
  }

  .h90-screen4 {
    height: 90%;
  }

  .h90vh-screen4 {
    height: 90vh;
  }

  .miw9rem-screen4 {
    min-width: 9rem;
  }

  .miw900px-screen4 {
    min-width: 900px;
  }

  .miw90-screen4 {
    min-width: 90%;
  }

  .miw90vw-screen4 {
    min-width: 90vw;
  }

  .mih9rem-screen4 {
    min-height: 9rem;
  }

  .mih900px-screen4 {
    min-height: 900px;
  }

  .mih90-screen4 {
    min-height: 90%;
  }

  .mih90vh-screen4 {
    min-height: 90vh;
  }

  .maw9rem-screen4 {
    max-width: 9rem;
  }

  .maw900px-screen4 {
    max-width: 900px;
  }

  .maw90-screen4 {
    max-width: 90%;
  }

  .maw90vw-screen4 {
    max-width: 90vw;
  }

  .mah9rem-screen4 {
    max-height: 9rem;
  }

  .mah900px-screen4 {
    max-height: 900px;
  }

  .mah90-screen4 {
    max-height: 90%;
  }

  .mah90vh-screen4 {
    max-height: 90vh;
  }

  .w10rem-screen4 {
    width: 10rem;
  }

  .w100px-screen4 {
    width: 100px;
  }

  .w1000px-screen4 {
    width: 1000px;
  }

  .w100-screen4 {
    width: 100%;
  }

  .w100vw-screen4 {
    width: 100vw;
  }

  .h10rem-screen4 {
    height: 10rem;
  }

  .h100px-screen4 {
    height: 100px;
  }

  .h1000px-screen4 {
    height: 1000px;
  }

  .h100-screen4 {
    height: 100%;
  }

  .h100vh-screen4 {
    height: 100vh;
  }

  .miw10rem-screen4 {
    min-width: 10rem;
  }

  .miw1000px-screen4 {
    min-width: 1000px;
  }

  .miw100-screen4 {
    min-width: 100%;
  }

  .miw100vw-screen4 {
    min-width: 100vw;
  }

  .mih10rem-screen4 {
    min-height: 10rem;
  }

  .mih1000px-screen4 {
    min-height: 1000px;
  }

  .mih100-screen4 {
    min-height: 100%;
  }

  .mih100vh-screen4 {
    min-height: 100vh;
  }

  .maw10rem-screen4 {
    max-width: 10rem;
  }

  .maw1000px-screen4 {
    max-width: 1000px;
  }

  .maw100-screen4 {
    max-width: 100%;
  }

  .maw100vw-screen4 {
    max-width: 100vw;
  }

  .mah10rem-screen4 {
    max-height: 10rem;
  }

  .mah1000px-screen4 {
    max-height: 1000px;
  }

  .mah100-screen4 {
    max-height: 100%;
  }

  .mah100vh-screen4 {
    max-height: 100vh;
  }

  .or0-screen4 {
    order: 0;
  }

  .br0px-screen4 {
    border-radius: 0;
  }

  .btlr0px-screen4 {
    border-top-left-radius: 0;
  }

  .btrr0px-screen4 {
    border-top-right-radius: 0;
  }

  .bblr0px-screen4 {
    border-bottom-left-radius: 0;
  }

  .bbrr0px-screen4 {
    border-bottom-right-radius: 0;
  }

  .or1-screen4 {
    order: 1;
  }

  .br5px-screen4 {
    border-radius: 6px;
  }

  .br8px-screen4 {
    border-radius: 8px;
  }

  .br19px-screen4 {
    border-radius: 19px;
  }

  .btlr5px-screen4 {
    border-top-left-radius: 5px;
  }

  .btrr5px-screen4 {
    border-top-right-radius: 5px;
  }

  .bblr5px-screen4 {
    border-bottom-left-radius: 5px;
  }

  .bbrr5px-screen4 {
    border-bottom-right-radius: 5px;
  }

  .or2-screen4 {
    order: 2;
  }

  .br10px-screen4 {
    border-radius: 12px;
  }

  .br16px-screen4 {
    border-radius: 16px;
  }

  .br38px-screen4 {
    border-radius: 38px;
  }

  .btlr10px-screen4 {
    border-top-left-radius: 10px;
  }

  .btrr10px-screen4 {
    border-top-right-radius: 10px;
  }

  .bblr10px-screen4 {
    border-bottom-left-radius: 10px;
  }

  .bbrr10px-screen4 {
    border-bottom-right-radius: 10px;
  }

  .or3-screen4 {
    order: 3;
  }

  .br15px-screen4 {
    border-radius: 18px;
  }

  .br24px-screen4 {
    border-radius: 24px;
  }

  .br57px-screen4 {
    border-radius: 57px;
  }

  .btlr15px-screen4 {
    border-top-left-radius: 15px;
  }

  .btrr15px-screen4 {
    border-top-right-radius: 15px;
  }

  .bblr15px-screen4 {
    border-bottom-left-radius: 15px;
  }

  .bbrr15px-screen4 {
    border-bottom-right-radius: 15px;
  }

  .or4-screen4 {
    order: 4;
  }

  .br20px-screen4 {
    border-radius: 24px;
  }

  .br32px-screen4 {
    border-radius: 32px;
  }

  .br76px-screen4 {
    border-radius: 76px;
  }

  .btlr20px-screen4 {
    border-top-left-radius: 20px;
  }

  .btrr20px-screen4 {
    border-top-right-radius: 20px;
  }

  .bblr20px-screen4 {
    border-bottom-left-radius: 20px;
  }

  .bbrr20px-screen4 {
    border-bottom-right-radius: 20px;
  }

  .or5-screen4 {
    order: 5;
  }

  .br25px-screen4 {
    border-radius: 30px;
  }

  .br95px-screen4 {
    border-radius: 95px;
  }

  .btlr25px-screen4 {
    border-top-left-radius: 25px;
  }

  .btrr25px-screen4 {
    border-top-right-radius: 25px;
  }

  .bblr25px-screen4 {
    border-bottom-left-radius: 25px;
  }

  .bbrr25px-screen4 {
    border-bottom-right-radius: 25px;
  }

  .or6-screen4 {
    order: 6;
  }

  .br30px-screen4 {
    border-radius: 36px;
  }

  .br48px-screen4 {
    border-radius: 48px;
  }

  .br114px-screen4 {
    border-radius: 114px;
  }

  .btlr30px-screen4 {
    border-top-left-radius: 30px;
  }

  .btrr30px-screen4 {
    border-top-right-radius: 30px;
  }

  .bblr30px-screen4 {
    border-bottom-left-radius: 30px;
  }

  .bbrr30px-screen4 {
    border-bottom-right-radius: 30px;
  }

  .or7-screen4 {
    order: 7;
  }

  .br35px-screen4 {
    border-radius: 42px;
  }

  .br56px-screen4 {
    border-radius: 56px;
  }

  .br133px-screen4 {
    border-radius: 133px;
  }

  .btlr35px-screen4 {
    border-top-left-radius: 35px;
  }

  .btrr35px-screen4 {
    border-top-right-radius: 35px;
  }

  .bblr35px-screen4 {
    border-bottom-left-radius: 35px;
  }

  .bbrr35px-screen4 {
    border-bottom-right-radius: 35px;
  }

  .or8-screen4 {
    order: 8;
  }

  .br40px-screen4 {
    border-radius: 48px;
  }

  .br64px-screen4 {
    border-radius: 64px;
  }

  .br152px-screen4 {
    border-radius: 152px;
  }

  .btlr40px-screen4 {
    border-top-left-radius: 40px;
  }

  .btrr40px-screen4 {
    border-top-right-radius: 40px;
  }

  .bblr40px-screen4 {
    border-bottom-left-radius: 40px;
  }

  .bbrr40px-screen4 {
    border-bottom-right-radius: 40px;
  }

  .or9-screen4 {
    order: 9;
  }

  .br45px-screen4 {
    border-radius: 54px;
  }

  .br72px-screen4 {
    border-radius: 72px;
  }

  .br171px-screen4 {
    border-radius: 171px;
  }

  .btlr45px-screen4 {
    border-top-left-radius: 45px;
  }

  .btrr45px-screen4 {
    border-top-right-radius: 45px;
  }

  .bblr45px-screen4 {
    border-bottom-left-radius: 45px;
  }

  .bbrr45px-screen4 {
    border-bottom-right-radius: 45px;
  }

  .or10-screen4 {
    order: 10;
  }

  .br50px-screen4 {
    border-radius: 60px;
  }

  .br80px-screen4 {
    border-radius: 80px;
  }

  .br190px-screen4 {
    border-radius: 190px;
  }

  .btlr50px-screen4 {
    border-top-left-radius: 50px;
  }

  .btrr50px-screen4 {
    border-top-right-radius: 50px;
  }

  .bblr50px-screen4 {
    border-bottom-left-radius: 50px;
  }

  .bbrr50px-screen4 {
    border-bottom-right-radius: 50px;
  }

  .br100 {
    border-radius: 100%;
  }

  .btlr100 {
    border-top-left-radius: 100%;
  }

  .btrr100 {
    border-top-right-radius: 100%;
  }

  .bblr100 {
    border-bottom-left-radius: 100%;
  }

  .bbrr100 {
    border-bottom-right-radius: 100%;
  }
}

@media (width >= 1920px) {
  .c1-screen5, .c1-hover-screen5:hover {
    color: #fff;
  }

  .bg1-screen5, .bg1-hover-screen5:hover {
    background-color: #fff;
  }

  .bo1-screen5, .bo1-hover-screen5:hover {
    border-color: #fff;
  }

  .f1-screen5, .f1-hover-screen5:hover {
    fill: #fff;
  }

  .c2-screen5, .c2-hover-screen5:hover {
    color: #000;
  }

  .bg2-screen5, .bg2-hover-screen5:hover {
    background-color: #000;
  }

  .bo2-screen5, .bo2-hover-screen5:hover {
    border-color: #000;
  }

  .f2-screen5, .f2-hover-screen5:hover {
    fill: #000;
  }

  .c3-screen5, .c3-hover-screen5:hover {
    color: #4a67ff;
  }

  .bg3-screen5, .bg3-hover-screen5:hover {
    background-color: #4a67ff;
  }

  .bo3-screen5, .bo3-hover-screen5:hover {
    border-color: #4a67ff;
  }

  .f3-screen5, .f3-hover-screen5:hover {
    fill: #4a67ff;
  }

  .c4-screen5, .c4-hover-screen5:hover {
    color: #fc7785;
  }

  .bg4-screen5, .bg4-hover-screen5:hover {
    background-color: #fc7785;
  }

  .bo4-screen5, .bo4-hover-screen5:hover {
    border-color: #fc7785;
  }

  .f4-screen5, .f4-hover-screen5:hover {
    fill: #fc7785;
  }

  .c5-screen5, .c5-hover-screen5:hover {
    color: #f0f0f8;
  }

  .bg5-screen5, .bg5-hover-screen5:hover {
    background-color: #f0f0f8;
  }

  .bo5-screen5, .bo5-hover-screen5:hover {
    border-color: #f0f0f8;
  }

  .f5-screen5, .f5-hover-screen5:hover {
    fill: #f0f0f8;
  }

  .c6-screen5, .c6-hover-screen5:hover {
    color: #002d63;
  }

  .bg6-screen5, .bg6-hover-screen5:hover {
    background-color: #002d63;
  }

  .bo6-screen5, .bo6-hover-screen5:hover {
    border-color: #002d63;
  }

  .f6-screen5, .f6-hover-screen5:hover {
    fill: #002d63;
  }

  .c7-screen5, .c7-hover-screen5:hover {
    color: #40dda1;
  }

  .bg7-screen5, .bg7-hover-screen5:hover {
    background-color: #40dda1;
  }

  .bo7-screen5, .bo7-hover-screen5:hover {
    border-color: #40dda1;
  }

  .f7-screen5, .f7-hover-screen5:hover {
    fill: #40dda1;
  }

  .c8-screen5, .c8-hover-screen5:hover {
    color: #ddb640;
  }

  .bg8-screen5, .bg8-hover-screen5:hover {
    background-color: #ddb640;
  }

  .bo8-screen5, .bo8-hover-screen5:hover {
    border-color: #ddb640;
  }

  .f8-screen5, .f8-hover-screen5:hover {
    fill: #ddb640;
  }

  .c9-screen5, .c9-hover-screen5:hover {
    color: #dd4040;
  }

  .bg9-screen5, .bg9-hover-screen5:hover {
    background-color: #dd4040;
  }

  .bo9-screen5, .bo9-hover-screen5:hover {
    border-color: #dd4040;
  }

  .f9-screen5, .f9-hover-screen5:hover {
    fill: #dd4040;
  }

  .c10-screen5, .c10-hover-screen5:hover {
    color: #038589;
  }

  .bg10-screen5, .bg10-hover-screen5:hover {
    background-color: #038589;
  }

  .bo10-screen5, .bo10-hover-screen5:hover {
    border-color: #038589;
  }

  .f10-screen5, .f10-hover-screen5:hover {
    fill: #038589;
  }

  .c11-screen5, .c11-hover-screen5:hover {
    color: #007ee5;
  }

  .bg11-screen5, .bg11-hover-screen5:hover {
    background-color: #007ee5;
  }

  .bo11-screen5, .bo11-hover-screen5:hover {
    border-color: #007ee5;
  }

  .f11-screen5, .f11-hover-screen5:hover {
    fill: #007ee5;
  }

  .c12-screen5, .c12-hover-screen5:hover {
    color: #969696;
  }

  .bg12-screen5, .bg12-hover-screen5:hover {
    background-color: #969696;
  }

  .bo12-screen5, .bo12-hover-screen5:hover {
    border-color: #969696;
  }

  .f12-screen5, .f12-hover-screen5:hover {
    fill: #969696;
  }

  .c13-screen5, .c13-hover-screen5:hover {
    color: #fb7a27;
  }

  .bg13-screen5, .bg13-hover-screen5:hover {
    background-color: #fb7a27;
  }

  .bo13-screen5, .bo13-hover-screen5:hover {
    border-color: #fb7a27;
  }

  .f13-screen5, .f13-hover-screen5:hover {
    fill: #fb7a27;
  }

  .c14-screen5, .c14-hover-screen5:hover {
    color: #505050;
  }

  .bg14-screen5, .bg14-hover-screen5:hover {
    background-color: #505050;
  }

  .bo14-screen5, .bo14-hover-screen5:hover {
    border-color: #505050;
  }

  .f14-screen5, .f14-hover-screen5:hover {
    fill: #505050;
  }

  .c15-screen5, .c15-hover-screen5:hover {
    color: #151313;
  }

  .bg15-screen5, .bg15-hover-screen5:hover {
    background-color: #151313;
  }

  .bo15-screen5, .bo15-hover-screen5:hover {
    border-color: #151313;
  }

  .f15-screen5, .f15-hover-screen5:hover {
    fill: #151313;
  }

  .c16-screen5, .c16-hover-screen5:hover {
    color: #d9d9d9;
  }

  .bg16-screen5, .bg16-hover-screen5:hover {
    background-color: #d9d9d9;
  }

  .bo16-screen5, .bo16-hover-screen5:hover {
    border-color: #d9d9d9;
  }

  .f16-screen5, .f16-hover-screen5:hover {
    fill: #d9d9d9;
  }

  .c17-screen5, .c17-hover-screen5:hover {
    color: #d3d9fc;
  }

  .bg17-screen5, .bg17-hover-screen5:hover {
    background-color: #d3d9fc;
  }

  .bo17-screen5, .bo17-hover-screen5:hover {
    border-color: #d3d9fc;
  }

  .f17-screen5, .f17-hover-screen5:hover {
    fill: #d3d9fc;
  }

  .c18-screen5, .c18-hover-screen5:hover {
    color: #6775b9;
  }

  .bg18-screen5, .bg18-hover-screen5:hover {
    background-color: #6775b9;
  }

  .bo18-screen5, .bo18-hover-screen5:hover {
    border-color: #6775b9;
  }

  .f18-screen5, .f18-hover-screen5:hover {
    fill: #6775b9;
  }

  .c19-screen5, .c19-hover-screen5:hover {
    color: #f5f6f9;
  }

  .bg19-screen5, .bg19-hover-screen5:hover {
    background-color: #f5f6f9;
  }

  .bo19-screen5, .bo19-hover-screen5:hover {
    border-color: #f5f6f9;
  }

  .f19-screen5, .f19-hover-screen5:hover {
    fill: #f5f6f9;
  }

  .c20-screen5, .c20-hover-screen5:hover {
    color: #f0f2fd;
  }

  .bg20-screen5, .bg20-hover-screen5:hover {
    background-color: #f0f2fd;
  }

  .bo20-screen5, .bo20-hover-screen5:hover {
    border-color: #f0f2fd;
  }

  .f20-screen5, .f20-hover-screen5:hover {
    fill: #f0f2fd;
  }

  .c21-screen5, .c21-hover-screen5:hover {
    color: #f9f9f9;
  }

  .bg21-screen5, .bg21-hover-screen5:hover {
    background-color: #f9f9f9;
  }

  .bo21-screen5, .bo21-hover-screen5:hover {
    border-color: #f9f9f9;
  }

  .f21-screen5, .f21-hover-screen5:hover {
    fill: #f9f9f9;
  }

  .c22-screen5, .c22-hover-screen5:hover {
    color: #f6f6f6;
  }

  .bg22-screen5, .bg22-hover-screen5:hover {
    background-color: #f6f6f6;
  }

  .bo22-screen5, .bo22-hover-screen5:hover {
    border-color: #f6f6f6;
  }

  .f22-screen5, .f22-hover-screen5:hover {
    fill: #f6f6f6;
  }

  .c23-screen5, .c23-hover-screen5:hover {
    color: #fd6474;
  }

  .bg23-screen5, .bg23-hover-screen5:hover {
    background-color: #fd6474;
  }

  .bo23-screen5, .bo23-hover-screen5:hover {
    border-color: #fd6474;
  }

  .f23-screen5, .f23-hover-screen5:hover {
    fill: #fd6474;
  }

  .c24-screen5, .c24-hover-screen5:hover {
    color: #efefef;
  }

  .bg24-screen5, .bg24-hover-screen5:hover {
    background-color: #efefef;
  }

  .bo24-screen5, .bo24-hover-screen5:hover {
    border-color: #efefef;
  }

  .f24-screen5, .f24-hover-screen5:hover {
    fill: #efefef;
  }

  .c25-screen5, .c25-hover-screen5:hover {
    color: #f8f8f8;
  }

  .bg25-screen5, .bg25-hover-screen5:hover {
    background-color: #f8f8f8;
  }

  .bo25-screen5, .bo25-hover-screen5:hover {
    border-color: #f8f8f8;
  }

  .f25-screen5, .f25-hover-screen5:hover {
    fill: #f8f8f8;
  }

  .c26-screen5, .c26-hover-screen5:hover {
    color: #0126ed;
  }

  .bg26-screen5, .bg26-hover-screen5:hover {
    background-color: #0126ed;
  }

  .bo26-screen5, .bo26-hover-screen5:hover {
    border-color: #0126ed;
  }

  .f26-screen5, .f26-hover-screen5:hover {
    fill: #0126ed;
  }

  .c27-screen5, .c27-hover-screen5:hover {
    color: #5e5f66;
  }

  .bg27-screen5, .bg27-hover-screen5:hover {
    background-color: #5e5f66;
  }

  .bo27-screen5, .bo27-hover-screen5:hover {
    border-color: #5e5f66;
  }

  .f27-screen5, .f27-hover-screen5:hover {
    fill: #5e5f66;
  }

  .c28-screen5, .c28-hover-screen5:hover {
    color: #aaacb5;
  }

  .bg28-screen5, .bg28-hover-screen5:hover {
    background-color: #aaacb5;
  }

  .bo28-screen5, .bo28-hover-screen5:hover {
    border-color: #aaacb5;
  }

  .f28-screen5, .f28-hover-screen5:hover {
    fill: #aaacb5;
  }

  .c29-screen5, .c29-hover-screen5:hover {
    color: #dde2f2;
  }

  .bg29-screen5, .bg29-hover-screen5:hover {
    background-color: #dde2f2;
  }

  .bo29-screen5, .bo29-hover-screen5:hover {
    border-color: #dde2f2;
  }

  .f29-screen5, .f29-hover-screen5:hover {
    fill: #dde2f2;
  }

  .c30-screen5, .c30-hover-screen5:hover {
    color: #09b255;
  }

  .bg30-screen5, .bg30-hover-screen5:hover {
    background-color: #09b255;
  }

  .bo30-screen5, .bo30-hover-screen5:hover {
    border-color: #09b255;
  }

  .f30-screen5, .f30-hover-screen5:hover {
    fill: #09b255;
  }

  .c31-screen5, .c31-hover-screen5:hover {
    color: #dbdfee;
  }

  .bg31-screen5, .bg31-hover-screen5:hover {
    background-color: #dbdfee;
  }

  .bo31-screen5, .bo31-hover-screen5:hover {
    border-color: #dbdfee;
  }

  .f31-screen5, .f31-hover-screen5:hover {
    fill: #dbdfee;
  }

  .c32-screen5, .c32-hover-screen5:hover {
    color: #737171;
  }

  .bg32-screen5, .bg32-hover-screen5:hover {
    background-color: #737171;
  }

  .bo32-screen5, .bo32-hover-screen5:hover {
    border-color: #737171;
  }

  .f32-screen5, .f32-hover-screen5:hover {
    fill: #737171;
  }

  .c33-screen5, .c33-hover-screen5:hover {
    color: #767b90;
  }

  .bg33-screen5, .bg33-hover-screen5:hover {
    background-color: #767b90;
  }

  .bo33-screen5, .bo33-hover-screen5:hover {
    border-color: #767b90;
  }

  .f33-screen5, .f33-hover-screen5:hover {
    fill: #767b90;
  }

  .c34-screen5, .c34-hover-screen5:hover {
    color: #15d757;
  }

  .bg34-screen5, .bg34-hover-screen5:hover {
    background-color: #15d757;
  }

  .bo34-screen5, .bo34-hover-screen5:hover {
    border-color: #15d757;
  }

  .f34-screen5, .f34-hover-screen5:hover {
    fill: #15d757;
  }

  .c35-screen5, .c35-hover-screen5:hover {
    color: #e8ebf5;
  }

  .bg35-screen5, .bg35-hover-screen5:hover {
    background-color: #e8ebf5;
  }

  .bo35-screen5, .bo35-hover-screen5:hover {
    border-color: #e8ebf5;
  }

  .f35-screen5, .f35-hover-screen5:hover {
    fill: #e8ebf5;
  }

  .c36-screen5, .c36-hover-screen5:hover {
    color: #4c6b92;
  }

  .bg36-screen5, .bg36-hover-screen5:hover {
    background-color: #4c6b92;
  }

  .bo36-screen5, .bo36-hover-screen5:hover {
    border-color: #4c6b92;
  }

  .f36-screen5, .f36-hover-screen5:hover {
    fill: #4c6b92;
  }

  .c37-screen5, .c37-hover-screen5:hover {
    color: #818181;
  }

  .bg37-screen5, .bg37-hover-screen5:hover {
    background-color: #818181;
  }

  .bo37-screen5, .bo37-hover-screen5:hover {
    border-color: #818181;
  }

  .f37-screen5, .f37-hover-screen5:hover {
    fill: #818181;
  }

  .c38-screen5, .c38-hover-screen5:hover {
    color: #f0f2fd;
  }

  .bg38-screen5, .bg38-hover-screen5:hover {
    background-color: #f0f2fd;
  }

  .bo38-screen5, .bo38-hover-screen5:hover {
    border-color: #f0f2fd;
  }

  .f38-screen5, .f38-hover-screen5:hover {
    fill: #f0f2fd;
  }

  .c39-screen5, .c39-hover-screen5:hover {
    color: #f99229;
  }

  .bg39-screen5, .bg39-hover-screen5:hover {
    background-color: #f99229;
  }

  .bo39-screen5, .bo39-hover-screen5:hover {
    border-color: #f99229;
  }

  .f39-screen5, .f39-hover-screen5:hover {
    fill: #f99229;
  }

  .c40-screen5, .c40-hover-screen5:hover {
    color: #2947ef;
  }

  .bg40-screen5, .bg40-hover-screen5:hover {
    background-color: #2947ef;
  }

  .bo40-screen5, .bo40-hover-screen5:hover {
    border-color: #2947ef;
  }

  .f40-screen5, .f40-hover-screen5:hover {
    fill: #2947ef;
  }

  .c41-screen5, .c41-hover-screen5:hover {
    color: #f5f6ff;
  }

  .bg41-screen5, .bg41-hover-screen5:hover {
    background-color: #f5f6ff;
  }

  .bo41-screen5, .bo41-hover-screen5:hover {
    border-color: #f5f6ff;
  }

  .f41-screen5, .f41-hover-screen5:hover {
    fill: #f5f6ff;
  }

  .tal-screen5 {
    text-align: left;
  }

  .tac-screen5 {
    text-align: center;
  }

  .tar-screen5 {
    text-align: right;
  }

  .taj-screen5 {
    text-align: justify;
  }

  .tas-screen5 {
    text-align: start;
  }

  .tae-screen5 {
    text-align: end;
  }

  .fs0-screen5 {
    font-size: 0;
  }

  .fs0-25rem-screen5 {
    font-size: .25rem;
  }

  .fs0-125rem-screen5 {
    font-size: .125rem;
  }

  .fs0-5rem-screen5 {
    font-size: .5rem;
  }

  .fs0-75rem-screen5 {
    font-size: .75rem;
  }

  .fs0-875rem-screen5 {
    font-size: .875rem;
  }

  .fs1rem-screen5 {
    font-size: 1rem;
  }

  .fs1-25rem-screen5 {
    font-size: 1.25rem;
  }

  .fs1-125rem-screen5 {
    font-size: 1.125rem;
  }

  .fs1-5rem-screen5 {
    font-size: 1.5rem;
  }

  .fs1-75rem-screen5 {
    font-size: 1.75rem;
  }

  .fs1-875rem-screen5 {
    font-size: 1.875rem;
  }

  .fs2rem-screen5 {
    font-size: 2rem;
  }

  .fs2-25rem-screen5 {
    font-size: 2.25rem;
  }

  .fs2-125rem-screen5 {
    font-size: 2.125rem;
  }

  .fs2-5rem-screen5 {
    font-size: 2.5rem;
  }

  .fs2-75rem-screen5 {
    font-size: 2.75rem;
  }

  .fs2-875rem-screen5 {
    font-size: 2.875rem;
  }

  .fs3rem-screen5 {
    font-size: 3rem;
  }

  .fs3-25rem-screen5 {
    font-size: 3.25rem;
  }

  .fs3-125rem-screen5 {
    font-size: 3.125rem;
  }

  .fs3-5rem-screen5 {
    font-size: 3.5rem;
  }

  .fs3-75rem-screen5 {
    font-size: 3.75rem;
  }

  .fs3-875rem-screen5 {
    font-size: 3.875rem;
  }

  .fs4rem-screen5 {
    font-size: 4rem;
  }

  .fs4-25rem-screen5 {
    font-size: 4.25rem;
  }

  .fs4-125rem-screen5 {
    font-size: 4.125rem;
  }

  .fs4-5rem-screen5 {
    font-size: 4.5rem;
  }

  .fs4-75rem-screen5 {
    font-size: 4.75rem;
  }

  .fs4-875rem-screen5 {
    font-size: 4.875rem;
  }

  .fs5rem-screen5 {
    font-size: 5rem;
  }

  .fs5-25rem-screen5 {
    font-size: 5.25rem;
  }

  .fs5-125rem-screen5 {
    font-size: 5.125rem;
  }

  .fs5-5rem-screen5 {
    font-size: 5.5rem;
  }

  .fs5-75rem-screen5 {
    font-size: 5.75rem;
  }

  .fs5-875rem-screen5 {
    font-size: 5.875rem;
  }

  .fs6rem-screen5 {
    font-size: 6rem;
  }

  .fs6-25rem-screen5 {
    font-size: 6.25rem;
  }

  .fs6-125rem-screen5 {
    font-size: 6.125rem;
  }

  .fs6-5rem-screen5 {
    font-size: 6.5rem;
  }

  .fs6-75rem-screen5 {
    font-size: 6.75rem;
  }

  .fs6-875rem-screen5 {
    font-size: 6.875rem;
  }

  .fs7rem-screen5 {
    font-size: 7rem;
  }

  .db-screen5 {
    display: block;
  }

  .dib-screen5 {
    display: inline-block;
  }

  .di-screen5 {
    display: inline;
  }

  .df-screen5 {
    display: flex;
  }

  .fdc-screen5 {
    flex-direction: column;
  }

  .fdr-screen5 {
    flex-direction: row;
  }

  .fdcr-screen5 {
    flex-direction: column-reverse;
  }

  .fdrr-screen5 {
    flex-direction: row-reverse;
  }

  .fww-screen5 {
    flex-wrap: wrap;
  }

  .jcfs-screen5 {
    justify-content: flex-start;
  }

  .jcc-screen5 {
    justify-content: center;
  }

  .jcfe-screen5 {
    justify-content: flex-end;
  }

  .jcsb-screen5 {
    justify-content: space-between;
  }

  .jcsa-screen5 {
    justify-content: space-around;
  }

  .jcse-screen5 {
    justify-content: space-evenly;
  }

  .jce-screen5 {
    justify-content: end;
  }

  .aifs-screen5 {
    align-items: flex-start;
  }

  .aic-screen5 {
    align-items: center;
  }

  .aife-screen5 {
    align-items: flex-end;
  }

  .jss-screen5 {
    justify-self: start;
  }

  .jsfs-screen5 {
    justify-self: flex-start;
  }

  .jsc-screen5 {
    justify-self: center;
  }

  .jsfe-screen5 {
    justify-self: flex-end;
  }

  .jse-screen5 {
    justify-self: end;
  }

  .ass-screen5 {
    align-self: start;
  }

  .asfs-screen5 {
    align-self: flex-start;
  }

  .asc-screen5 {
    align-self: center;
  }

  .asfe-screen5 {
    align-self: flex-end;
  }

  .ase-screen5 {
    align-self: end;
  }

  .dif-screen5 {
    display: inline-flex;
  }

  .dg-screen5 {
    display: grid;
  }

  .brss-screen5 {
    border-right-style: solid;
  }

  .brw1px-screen5 {
    border-right-width: 1px;
  }

  .brw2px-screen5 {
    border-right-width: 2px;
  }

  .gtcr1-screen5 {
    grid-template-columns: repeat(1, 1fr);
  }

  .ftcr1-screen5 {
    grid-column: 1 / 1fr;
  }

  .gtrr1-screen5 {
    grid-template-rows: repeat(1, 1fr);
  }

  .gtcr1a-screen5 {
    grid-template-columns: repeat(1, auto);
  }

  .gtrr1a-screen5 {
    grid-template-rows: repeat(1, auto);
  }

  .gcs1-screen5 {
    grid-column-start: 1;
  }

  .gce2-screen5 {
    grid-column-end: 2;
  }

  .grs1-screen5 {
    grid-row-start: 1;
  }

  .gre2-screen5 {
    grid-row-end: 2;
  }

  .gtcr2-screen5 {
    grid-template-columns: repeat(2, 1fr);
  }

  .ftcr2-screen5 {
    grid-column: 2 / 1fr;
  }

  .gtrr2-screen5 {
    grid-template-rows: repeat(2, 1fr);
  }

  .gtcr2a-screen5 {
    grid-template-columns: repeat(2, auto);
  }

  .gtrr2a-screen5 {
    grid-template-rows: repeat(2, auto);
  }

  .gcs2-screen5 {
    grid-column-start: 2;
  }

  .gce3-screen5 {
    grid-column-end: 3;
  }

  .grs2-screen5 {
    grid-row-start: 2;
  }

  .gre3-screen5 {
    grid-row-end: 3;
  }

  .gtcr3-screen5 {
    grid-template-columns: repeat(3, 1fr);
  }

  .ftcr3-screen5 {
    grid-column: 3 / 1fr;
  }

  .gtrr3-screen5 {
    grid-template-rows: repeat(3, 1fr);
  }

  .gtcr3a-screen5 {
    grid-template-columns: repeat(3, auto);
  }

  .gtrr3a-screen5 {
    grid-template-rows: repeat(3, auto);
  }

  .gcs3-screen5 {
    grid-column-start: 3;
  }

  .gce4-screen5 {
    grid-column-end: 4;
  }

  .grs3-screen5 {
    grid-row-start: 3;
  }

  .gre4-screen5 {
    grid-row-end: 4;
  }

  .gtcr4-screen5 {
    grid-template-columns: repeat(4, 1fr);
  }

  .ftcr4-screen5 {
    grid-column: 4 / 1fr;
  }

  .gtrr4-screen5 {
    grid-template-rows: repeat(4, 1fr);
  }

  .gtcr4a-screen5 {
    grid-template-columns: repeat(4, auto);
  }

  .gtrr4a-screen5 {
    grid-template-rows: repeat(4, auto);
  }

  .gcs4-screen5 {
    grid-column-start: 4;
  }

  .gce5-screen5 {
    grid-column-end: 5;
  }

  .grs4-screen5 {
    grid-row-start: 4;
  }

  .gre5-screen5 {
    grid-row-end: 5;
  }

  .gtcr5-screen5 {
    grid-template-columns: repeat(5, 1fr);
  }

  .ftcr5-screen5 {
    grid-column: 5 / 1fr;
  }

  .gtrr5-screen5 {
    grid-template-rows: repeat(5, 1fr);
  }

  .gtcr5a-screen5 {
    grid-template-columns: repeat(5, auto);
  }

  .gtrr5a-screen5 {
    grid-template-rows: repeat(5, auto);
  }

  .gcs5-screen5 {
    grid-column-start: 5;
  }

  .gce6-screen5 {
    grid-column-end: 6;
  }

  .grs5-screen5 {
    grid-row-start: 5;
  }

  .gre6-screen5 {
    grid-row-end: 6;
  }

  .gtcr6-screen5 {
    grid-template-columns: repeat(6, 1fr);
  }

  .ftcr6-screen5 {
    grid-column: 6 / 1fr;
  }

  .gtrr6-screen5 {
    grid-template-rows: repeat(6, 1fr);
  }

  .gtcr6a-screen5 {
    grid-template-columns: repeat(6, auto);
  }

  .gtrr6a-screen5 {
    grid-template-rows: repeat(6, auto);
  }

  .gcs6-screen5 {
    grid-column-start: 6;
  }

  .gce7-screen5 {
    grid-column-end: 7;
  }

  .grs6-screen5 {
    grid-row-start: 6;
  }

  .gre7-screen5 {
    grid-row-end: 7;
  }

  .gtcr7-screen5 {
    grid-template-columns: repeat(7, 1fr);
  }

  .ftcr7-screen5 {
    grid-column: 7 / 1fr;
  }

  .gtrr7-screen5 {
    grid-template-rows: repeat(7, 1fr);
  }

  .gtcr7a-screen5 {
    grid-template-columns: repeat(7, auto);
  }

  .gtrr7a-screen5 {
    grid-template-rows: repeat(7, auto);
  }

  .gcs7-screen5 {
    grid-column-start: 7;
  }

  .gce8-screen5 {
    grid-column-end: 8;
  }

  .grs7-screen5 {
    grid-row-start: 7;
  }

  .gre8-screen5 {
    grid-row-end: 8;
  }

  .gtcr8-screen5 {
    grid-template-columns: repeat(8, 1fr);
  }

  .ftcr8-screen5 {
    grid-column: 8 / 1fr;
  }

  .gtrr8-screen5 {
    grid-template-rows: repeat(8, 1fr);
  }

  .gtcr8a-screen5 {
    grid-template-columns: repeat(8, auto);
  }

  .gtrr8a-screen5 {
    grid-template-rows: repeat(8, auto);
  }

  .gcs8-screen5 {
    grid-column-start: 8;
  }

  .gce9-screen5 {
    grid-column-end: 9;
  }

  .grs8-screen5 {
    grid-row-start: 8;
  }

  .gre9-screen5 {
    grid-row-end: 9;
  }

  .gtcr9-screen5 {
    grid-template-columns: repeat(9, 1fr);
  }

  .ftcr9-screen5 {
    grid-column: 9 / 1fr;
  }

  .gtrr9-screen5 {
    grid-template-rows: repeat(9, 1fr);
  }

  .gtcr9a-screen5 {
    grid-template-columns: repeat(9, auto);
  }

  .gtrr9a-screen5 {
    grid-template-rows: repeat(9, auto);
  }

  .gcs9-screen5 {
    grid-column-start: 9;
  }

  .gce10-screen5 {
    grid-column-end: 10;
  }

  .grs9-screen5 {
    grid-row-start: 9;
  }

  .gre10-screen5 {
    grid-row-end: 10;
  }

  .gtccr-screen5 {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }

  .gtcr10-screen5 {
    grid-template-columns: repeat(10, 1fr);
  }

  .ftcr10-screen5 {
    grid-column: 10 / 1fr;
  }

  .gtrr10-screen5 {
    grid-template-rows: repeat(10, 1fr);
  }

  .gtcr10a-screen5 {
    grid-template-columns: repeat(10, auto);
  }

  .gtrr10a-screen5 {
    grid-template-rows: repeat(10, auto);
  }

  .gcs10-screen5 {
    grid-column-start: 10;
  }

  .gce11-screen5 {
    grid-column-end: 11;
  }

  .grs10-screen5 {
    grid-row-start: 10;
  }

  .gre11-screen5 {
    grid-row-end: 11;
  }

  .gg0-5rem-screen5 {
    grid-gap: .5rem;
  }

  .gg1rem-screen5 {
    grid-gap: 1rem;
  }

  .rg0-5rem-screen5 {
    row-gap: .5rem;
  }

  .rg1rem-screen5 {
    row-gap: 1rem;
  }

  .cg0-5rem-screen5 {
    column-gap: .5rem;
  }

  .cg1rem-screen5 {
    column-gap: 1rem;
  }

  .gg1-5rem-screen5 {
    grid-gap: 1.5rem;
  }

  .gg2rem-screen5 {
    grid-gap: 2rem;
  }

  .rg1-5rem-screen5 {
    row-gap: 1.5rem;
  }

  .rg2rem-screen5 {
    row-gap: 2rem;
  }

  .cg1-5rem-screen5 {
    column-gap: 1.5rem;
  }

  .cg2rem-screen5 {
    column-gap: 2rem;
  }

  .gg2-5rem-screen5 {
    grid-gap: 2.5rem;
  }

  .gg3rem-screen5 {
    grid-gap: 3rem;
  }

  .rg2-5rem-screen5 {
    row-gap: 2.5rem;
  }

  .rg3rem-screen5 {
    row-gap: 3rem;
  }

  .cg2-5rem-screen5 {
    column-gap: 2.5rem;
  }

  .cg3rem-screen5 {
    column-gap: 3rem;
  }

  .gg3-5rem-screen5 {
    grid-gap: 3.5rem;
  }

  .gg4rem-screen5 {
    grid-gap: 4rem;
  }

  .rg3-5rem-screen5 {
    row-gap: 3.5rem;
  }

  .rg4rem-screen5 {
    row-gap: 4rem;
  }

  .cg3-5rem-screen5 {
    column-gap: 3.5rem;
  }

  .cg4rem-screen5 {
    column-gap: 4rem;
  }

  .gg4-5rem-screen5 {
    grid-gap: 4.5rem;
  }

  .gg5rem-screen5 {
    grid-gap: 5rem;
  }

  .rg4-5rem-screen5 {
    row-gap: 4.5rem;
  }

  .rg5rem-screen5 {
    row-gap: 5rem;
  }

  .cg4-5rem-screen5 {
    column-gap: 4.5rem;
  }

  .cg5rem-screen5 {
    column-gap: 5rem;
  }

  .dn-screen5 {
    display: none;
  }

  .pa-screen5 {
    position: absolute;
  }

  .pr-screen5 {
    position: relative;
  }

  .pf-screen5 {
    position: fixed;
  }

  .ps-screen5 {
    -webkit-position: sticky;
    position: sticky;
  }

  .pst-screen5 {
    position: static;
  }

  .t0-screen5 {
    top: 0;
  }

  .r0-screen5 {
    right: 0;
  }

  .b0-screen5 {
    bottom: 0;
  }

  .l0-screen5 {
    left: 0;
  }

  .t100-screen5 {
    top: 100%;
  }

  .r100-screen5 {
    right: 100%;
  }

  .b100-screen5 {
    bottom: 100%;
  }

  .l100-screen5 {
    left: 100%;
  }

  .fl-screen5 {
    float: left;
  }

  .fr-screen5 {
    float: right;
  }

  .fn-screen5 {
    float: none;
  }

  .ma-screen5 {
    margin: auto;
  }

  .mta-screen5, .mya-screen5 {
    margin-top: auto;
  }

  .mra-screen5, .mxa-screen5 {
    margin-right: auto;
  }

  .mba-screen5, .mya-screen5 {
    margin-bottom: auto;
  }

  .mla-screen5, .mxa-screen5 {
    margin-left: auto;
  }

  .m0-screen5 {
    margin: 0;
  }

  .mt0-screen5, .my0-screen5 {
    margin-top: 0;
  }

  .mr0-screen5, .mx0-screen5 {
    margin-right: 0;
  }

  .mb0-screen5, .my0-screen5 {
    margin-bottom: 0;
  }

  .ml0-screen5, .mx0-screen5 {
    margin-left: 0;
  }

  .p0-screen5 {
    padding: 0;
  }

  .pt0-screen5, .py0-screen5 {
    padding-top: 0;
  }

  .pr0-screen5, .px0-screen5 {
    padding-right: 0;
  }

  .pb0-screen5, .py0-screen5 {
    padding-bottom: 0;
  }

  .pl0-screen5, .px0-screen5 {
    padding-left: 0;
  }

  .m10-screen5 {
    margin: 10%;
  }

  .mt10-screen5, .my10-screen5 {
    margin-top: 10%;
  }

  .mr10-screen5, .mx10-screen5 {
    margin-right: 10%;
  }

  .mb10-screen5, .my10-screen5 {
    margin-bottom: 10%;
  }

  .ml10-screen5, .mx10-screen5 {
    margin-left: 10%;
  }

  .p10-screen5 {
    padding: 10%;
  }

  .pt10-screen5, .py10-screen5 {
    padding-top: 10%;
  }

  .pr10-screen5, .px10-screen5 {
    padding-right: 10%;
  }

  .pb10-screen5, .py10-screen5 {
    padding-bottom: 10%;
  }

  .pl10-screen5, .px10-screen5 {
    padding-left: 10%;
  }

  .m0-5rem-screen5 {
    margin: .5rem;
  }

  .m1rem-screen5 {
    margin: 1rem;
  }

  .mt0-5rem-screen5, .my0-5rem-screen5 {
    margin-top: .5rem;
  }

  .mt1rem-screen5, .my1rem-screen5 {
    margin-top: 1rem;
  }

  .mr0-5rem-screen5, .mx0-5rem-screen5 {
    margin-right: .5rem;
  }

  .mr1rem-screen5, .mx1rem-screen5 {
    margin-right: 1rem;
  }

  .mb0-5rem-screen5, .my0-5rem-screen5 {
    margin-bottom: .5rem;
  }

  .mb1rem-screen5, .my1rem-screen5 {
    margin-bottom: 1rem;
  }

  .ml0-5rem-screen5, .mx0-5rem-screen5 {
    margin-left: .5rem;
  }

  .ml1rem-screen5, .mx1rem-screen5 {
    margin-left: 1rem;
  }

  .p0-5rem-screen5 {
    padding: .5rem;
  }

  .p1rem-screen5 {
    padding: 1rem;
  }

  .pt0-5rem-screen5, .py0-5rem-screen5 {
    padding-top: .5rem;
  }

  .pt1rem-screen5, .py1rem-screen5 {
    padding-top: 1rem;
  }

  .pr0-5rem-screen5, .px0-5rem-screen5 {
    padding-right: .5rem;
  }

  .pr1rem-screen5, .px1rem-screen5 {
    padding-right: 1rem;
  }

  .pb0-5rem-screen5, .py0-5rem-screen5 {
    padding-bottom: .5rem;
  }

  .pb1rem-screen5, .py1rem-screen5 {
    padding-bottom: 1rem;
  }

  .pl0-5rem-screen5, .px0-5rem-screen5 {
    padding-left: .5rem;
  }

  .pl1rem-screen5, .px1rem-screen5 {
    padding-left: 1rem;
  }

  .m20-screen5 {
    margin: 20%;
  }

  .mt20-screen5, .my20-screen5 {
    margin-top: 20%;
  }

  .mr20-screen5, .mx20-screen5 {
    margin-right: 20%;
  }

  .mb20-screen5, .my20-screen5 {
    margin-bottom: 20%;
  }

  .ml20-screen5, .mx20-screen5 {
    margin-left: 20%;
  }

  .p20-screen5 {
    padding: 20%;
  }

  .pt20-screen5, .py20-screen5 {
    padding-top: 20%;
  }

  .pr20-screen5, .px20-screen5 {
    padding-right: 20%;
  }

  .pb20-screen5, .py20-screen5 {
    padding-bottom: 20%;
  }

  .pl20-screen5, .px20-screen5 {
    padding-left: 20%;
  }

  .m1-5rem-screen5 {
    margin: 1.5rem;
  }

  .m2rem-screen5 {
    margin: 2rem;
  }

  .mt1-5rem-screen5, .my1-5rem-screen5 {
    margin-top: 1.5rem;
  }

  .mt2rem-screen5, .my2rem-screen5 {
    margin-top: 2rem;
  }

  .mr1-5rem-screen5, .mx1-5rem-screen5 {
    margin-right: 1.5rem;
  }

  .mr2rem-screen5, .mx2rem-screen5 {
    margin-right: 2rem;
  }

  .mb1-5rem-screen5, .my1-5rem-screen5 {
    margin-bottom: 1.5rem;
  }

  .mb2rem-screen5, .my2rem-screen5 {
    margin-bottom: 2rem;
  }

  .ml1-5rem-screen5, .mx1-5rem-screen5 {
    margin-left: 1.5rem;
  }

  .ml2rem-screen5, .mx2rem-screen5 {
    margin-left: 2rem;
  }

  .p1-5rem-screen5 {
    padding: 1.5rem;
  }

  .p2rem-screen5 {
    padding: 2rem;
  }

  .pt1-5rem-screen5, .py1-5rem-screen5 {
    padding-top: 1.5rem;
  }

  .pt2rem-screen5, .py2rem-screen5 {
    padding-top: 2rem;
  }

  .pr1-5rem-screen5, .px1-5rem-screen5 {
    padding-right: 1.5rem;
  }

  .pr2rem-screen5, .px2rem-screen5 {
    padding-right: 2rem;
  }

  .pb1-5rem-screen5, .py1-5rem-screen5 {
    padding-bottom: 1.5rem;
  }

  .pb2rem-screen5, .py2rem-screen5 {
    padding-bottom: 2rem;
  }

  .pl1-5rem-screen5, .px1-5rem-screen5 {
    padding-left: 1.5rem;
  }

  .pl2rem-screen5, .px2rem-screen5 {
    padding-left: 2rem;
  }

  .m30-screen5 {
    margin: 30%;
  }

  .mt30-screen5, .my30-screen5 {
    margin-top: 30%;
  }

  .mr30-screen5, .mx30-screen5 {
    margin-right: 30%;
  }

  .mb30-screen5, .my30-screen5 {
    margin-bottom: 30%;
  }

  .ml30-screen5, .mx30-screen5 {
    margin-left: 30%;
  }

  .p30-screen5 {
    padding: 30%;
  }

  .pt30-screen5, .py30-screen5 {
    padding-top: 30%;
  }

  .pr30-screen5, .px30-screen5 {
    padding-right: 30%;
  }

  .pb30-screen5, .py30-screen5 {
    padding-bottom: 30%;
  }

  .pl30-screen5, .px30-screen5 {
    padding-left: 30%;
  }

  .m2-5rem-screen5 {
    margin: 2.5rem;
  }

  .m3rem-screen5 {
    margin: 3rem;
  }

  .mt2-5rem-screen5, .my2-5rem-screen5 {
    margin-top: 2.5rem;
  }

  .mt3rem-screen5, .my3rem-screen5 {
    margin-top: 3rem;
  }

  .mr2-5rem-screen5, .mx2-5rem-screen5 {
    margin-right: 2.5rem;
  }

  .mr3rem-screen5, .mx3rem-screen5 {
    margin-right: 3rem;
  }

  .mb2-5rem-screen5, .my2-5rem-screen5 {
    margin-bottom: 2.5rem;
  }

  .mb3rem-screen5, .my3rem-screen5 {
    margin-bottom: 3rem;
  }

  .ml2-5rem-screen5, .mx2-5rem-screen5 {
    margin-left: 2.5rem;
  }

  .ml3rem-screen5, .mx3rem-screen5 {
    margin-left: 3rem;
  }

  .p2-5rem-screen5 {
    padding: 2.5rem;
  }

  .p3rem-screen5 {
    padding: 3rem;
  }

  .pt2-5rem-screen5, .py2-5rem-screen5 {
    padding-top: 2.5rem;
  }

  .pt3rem-screen5, .py3rem-screen5 {
    padding-top: 3rem;
  }

  .pr2-5rem-screen5, .px2-5rem-screen5 {
    padding-right: 2.5rem;
  }

  .pr3rem-screen5, .px3rem-screen5 {
    padding-right: 3rem;
  }

  .pb2-5rem-screen5, .py2-5rem-screen5 {
    padding-bottom: 2.5rem;
  }

  .pb3rem-screen5, .py3rem-screen5 {
    padding-bottom: 3rem;
  }

  .pl2-5rem-screen5, .px2-5rem-screen5 {
    padding-left: 2.5rem;
  }

  .pl3rem-screen5, .px3rem-screen5 {
    padding-left: 3rem;
  }

  .m40-screen5 {
    margin: 40%;
  }

  .mt40-screen5, .my40-screen5 {
    margin-top: 40%;
  }

  .mr40-screen5, .mx40-screen5 {
    margin-right: 40%;
  }

  .mb40-screen5, .my40-screen5 {
    margin-bottom: 40%;
  }

  .ml40-screen5, .mx40-screen5 {
    margin-left: 40%;
  }

  .p40-screen5 {
    padding: 40%;
  }

  .pt40-screen5, .py40-screen5 {
    padding-top: 40%;
  }

  .pr40-screen5, .px40-screen5 {
    padding-right: 40%;
  }

  .pb40-screen5, .py40-screen5 {
    padding-bottom: 40%;
  }

  .pl40-screen5, .px40-screen5 {
    padding-left: 40%;
  }

  .m3-5rem-screen5 {
    margin: 3.5rem;
  }

  .m4rem-screen5 {
    margin: 4rem;
  }

  .mt3-5rem-screen5, .my3-5rem-screen5 {
    margin-top: 3.5rem;
  }

  .mt4rem-screen5, .my4rem-screen5 {
    margin-top: 4rem;
  }

  .mr3-5rem-screen5, .mx3-5rem-screen5 {
    margin-right: 3.5rem;
  }

  .mr4rem-screen5, .mx4rem-screen5 {
    margin-right: 4rem;
  }

  .mb3-5rem-screen5, .my3-5rem-screen5 {
    margin-bottom: 3.5rem;
  }

  .mb4rem-screen5, .my4rem-screen5 {
    margin-bottom: 4rem;
  }

  .ml3-5rem-screen5, .mx3-5rem-screen5 {
    margin-left: 3.5rem;
  }

  .ml4rem-screen5, .mx4rem-screen5 {
    margin-left: 4rem;
  }

  .p3-5rem-screen5 {
    padding: 3.5rem;
  }

  .p4rem-screen5 {
    padding: 4rem;
  }

  .pt3-5rem-screen5, .py3-5rem-screen5 {
    padding-top: 3.5rem;
  }

  .pt4rem-screen5, .py4rem-screen5 {
    padding-top: 4rem;
  }

  .pr3-5rem-screen5, .px3-5rem-screen5 {
    padding-right: 3.5rem;
  }

  .pr4rem-screen5, .px4rem-screen5 {
    padding-right: 4rem;
  }

  .pb3-5rem-screen5, .py3-5rem-screen5 {
    padding-bottom: 3.5rem;
  }

  .pb4rem-screen5, .py4rem-screen5 {
    padding-bottom: 4rem;
  }

  .pl3-5rem-screen5, .px3-5rem-screen5 {
    padding-left: 3.5rem;
  }

  .pl4rem-screen5, .px4rem-screen5 {
    padding-left: 4rem;
  }

  .m50-screen5 {
    margin: 50%;
  }

  .mt50-screen5, .my50-screen5 {
    margin-top: 50%;
  }

  .mr50-screen5, .mx50-screen5 {
    margin-right: 50%;
  }

  .mb50-screen5, .my50-screen5 {
    margin-bottom: 50%;
  }

  .ml50-screen5, .mx50-screen5 {
    margin-left: 50%;
  }

  .p50-screen5 {
    padding: 50%;
  }

  .pt50-screen5, .py50-screen5 {
    padding-top: 50%;
  }

  .pr50-screen5, .px50-screen5 {
    padding-right: 50%;
  }

  .pb50-screen5, .py50-screen5 {
    padding-bottom: 50%;
  }

  .pl50-screen5, .px50-screen5 {
    padding-left: 50%;
  }

  .m4-5rem-screen5 {
    margin: 4.5rem;
  }

  .m5rem-screen5 {
    margin: 5rem;
  }

  .mt4-5rem-screen5, .my4-5rem-screen5 {
    margin-top: 4.5rem;
  }

  .mt5rem-screen5, .my5rem-screen5 {
    margin-top: 5rem;
  }

  .mr4-5rem-screen5, .mx4-5rem-screen5 {
    margin-right: 4.5rem;
  }

  .mr5rem-screen5, .mx5rem-screen5 {
    margin-right: 5rem;
  }

  .mb4-5rem-screen5, .my4-5rem-screen5 {
    margin-bottom: 4.5rem;
  }

  .mb5rem-screen5, .my5rem-screen5 {
    margin-bottom: 5rem;
  }

  .ml4-5rem-screen5, .mx4-5rem-screen5 {
    margin-left: 4.5rem;
  }

  .ml5rem-screen5, .mx5rem-screen5 {
    margin-left: 5rem;
  }

  .p4-5rem-screen5 {
    padding: 4.5rem;
  }

  .p5rem-screen5 {
    padding: 5rem;
  }

  .pt4-5rem-screen5, .py4-5rem-screen5 {
    padding-top: 4.5rem;
  }

  .pt5rem-screen5, .py5rem-screen5 {
    padding-top: 5rem;
  }

  .pr4-5rem-screen5, .px4-5rem-screen5 {
    padding-right: 4.5rem;
  }

  .pr5rem-screen5, .px5rem-screen5 {
    padding-right: 5rem;
  }

  .pb4-5rem-screen5, .py4-5rem-screen5 {
    padding-bottom: 4.5rem;
  }

  .pb5rem-screen5, .py5rem-screen5 {
    padding-bottom: 5rem;
  }

  .pl4-5rem-screen5, .px4-5rem-screen5 {
    padding-left: 4.5rem;
  }

  .pl5rem-screen5, .px5rem-screen5 {
    padding-left: 5rem;
  }

  .m60-screen5 {
    margin: 60%;
  }

  .mt60-screen5, .my60-screen5 {
    margin-top: 60%;
  }

  .mr60-screen5, .mx60-screen5 {
    margin-right: 60%;
  }

  .mb60-screen5, .my60-screen5 {
    margin-bottom: 60%;
  }

  .ml60-screen5, .mx60-screen5 {
    margin-left: 60%;
  }

  .p60-screen5 {
    padding: 60%;
  }

  .pt60-screen5, .py60-screen5 {
    padding-top: 60%;
  }

  .pr60-screen5, .px60-screen5 {
    padding-right: 60%;
  }

  .pb60-screen5, .py60-screen5 {
    padding-bottom: 60%;
  }

  .pl60-screen5, .px60-screen5 {
    padding-left: 60%;
  }

  .m5-5rem-screen5 {
    margin: 5.5rem;
  }

  .m6rem-screen5 {
    margin: 6rem;
  }

  .mt5-5rem-screen5, .my5-5rem-screen5 {
    margin-top: 5.5rem;
  }

  .mt6rem-screen5, .my6rem-screen5 {
    margin-top: 6rem;
  }

  .mr5-5rem-screen5, .mx5-5rem-screen5 {
    margin-right: 5.5rem;
  }

  .mr6rem-screen5, .mx6rem-screen5 {
    margin-right: 6rem;
  }

  .mb5-5rem-screen5, .my5-5rem-screen5 {
    margin-bottom: 5.5rem;
  }

  .mb6rem-screen5, .my6rem-screen5 {
    margin-bottom: 6rem;
  }

  .ml5-5rem-screen5, .mx5-5rem-screen5 {
    margin-left: 5.5rem;
  }

  .ml6rem-screen5, .mx6rem-screen5 {
    margin-left: 6rem;
  }

  .p5-5rem-screen5 {
    padding: 5.5rem;
  }

  .p6rem-screen5 {
    padding: 6rem;
  }

  .pt5-5rem-screen5, .py5-5rem-screen5 {
    padding-top: 5.5rem;
  }

  .pt6rem-screen5, .py6rem-screen5 {
    padding-top: 6rem;
  }

  .pr5-5rem-screen5, .px5-5rem-screen5 {
    padding-right: 5.5rem;
  }

  .pr6rem-screen5, .px6rem-screen5 {
    padding-right: 6rem;
  }

  .pb5-5rem-screen5, .py5-5rem-screen5 {
    padding-bottom: 5.5rem;
  }

  .pb6rem-screen5, .py6rem-screen5 {
    padding-bottom: 6rem;
  }

  .pl5-5rem-screen5, .px5-5rem-screen5 {
    padding-left: 5.5rem;
  }

  .pl6rem-screen5, .px6rem-screen5 {
    padding-left: 6rem;
  }

  .m70-screen5 {
    margin: 70%;
  }

  .mt70-screen5, .my70-screen5 {
    margin-top: 70%;
  }

  .mr70-screen5, .mx70-screen5 {
    margin-right: 70%;
  }

  .mb70-screen5, .my70-screen5 {
    margin-bottom: 70%;
  }

  .ml70-screen5, .mx70-screen5 {
    margin-left: 70%;
  }

  .p70-screen5 {
    padding: 70%;
  }

  .pt70-screen5, .py70-screen5 {
    padding-top: 70%;
  }

  .pr70-screen5, .px70-screen5 {
    padding-right: 70%;
  }

  .pb70-screen5, .py70-screen5 {
    padding-bottom: 70%;
  }

  .pl70-screen5, .px70-screen5 {
    padding-left: 70%;
  }

  .m6-5rem-screen5 {
    margin: 6.5rem;
  }

  .m7rem-screen5 {
    margin: 7rem;
  }

  .mt6-5rem-screen5, .my6-5rem-screen5 {
    margin-top: 6.5rem;
  }

  .mt7rem-screen5, .my7rem-screen5 {
    margin-top: 7rem;
  }

  .mr6-5rem-screen5, .mx6-5rem-screen5 {
    margin-right: 6.5rem;
  }

  .mr7rem-screen5, .mx7rem-screen5 {
    margin-right: 7rem;
  }

  .mb6-5rem-screen5, .my6-5rem-screen5 {
    margin-bottom: 6.5rem;
  }

  .mb7rem-screen5, .my7rem-screen5 {
    margin-bottom: 7rem;
  }

  .ml6-5rem-screen5, .mx6-5rem-screen5 {
    margin-left: 6.5rem;
  }

  .ml7rem-screen5, .mx7rem-screen5 {
    margin-left: 7rem;
  }

  .p6-5rem-screen5 {
    padding: 6.5rem;
  }

  .p7rem-screen5 {
    padding: 7rem;
  }

  .pt6-5rem-screen5, .py6-5rem-screen5 {
    padding-top: 6.5rem;
  }

  .pt7rem-screen5, .py7rem-screen5 {
    padding-top: 7rem;
  }

  .pr6-5rem-screen5, .px6-5rem-screen5 {
    padding-right: 6.5rem;
  }

  .pr7rem-screen5, .px7rem-screen5 {
    padding-right: 7rem;
  }

  .pb6-5rem-screen5, .py6-5rem-screen5 {
    padding-bottom: 6.5rem;
  }

  .pb7rem-screen5, .py7rem-screen5 {
    padding-bottom: 7rem;
  }

  .pl6-5rem-screen5, .px6-5rem-screen5 {
    padding-left: 6.5rem;
  }

  .pl7rem-screen5, .px7rem-screen5 {
    padding-left: 7rem;
  }

  .m80-screen5 {
    margin: 80%;
  }

  .mt80-screen5, .my80-screen5 {
    margin-top: 80%;
  }

  .mr80-screen5, .mx80-screen5 {
    margin-right: 80%;
  }

  .mb80-screen5, .my80-screen5 {
    margin-bottom: 80%;
  }

  .ml80-screen5, .mx80-screen5 {
    margin-left: 80%;
  }

  .p80-screen5 {
    padding: 80%;
  }

  .pt80-screen5, .py80-screen5 {
    padding-top: 80%;
  }

  .pr80-screen5, .px80-screen5 {
    padding-right: 80%;
  }

  .pb80-screen5, .py80-screen5 {
    padding-bottom: 80%;
  }

  .pl80-screen5, .px80-screen5 {
    padding-left: 80%;
  }

  .m7-5rem-screen5 {
    margin: 7.5rem;
  }

  .m8rem-screen5 {
    margin: 8rem;
  }

  .mt7-5rem-screen5, .my7-5rem-screen5 {
    margin-top: 7.5rem;
  }

  .mt8rem-screen5, .my8rem-screen5 {
    margin-top: 8rem;
  }

  .mr7-5rem-screen5, .mx7-5rem-screen5 {
    margin-right: 7.5rem;
  }

  .mr8rem-screen5, .mx8rem-screen5 {
    margin-right: 8rem;
  }

  .mb7-5rem-screen5, .my7-5rem-screen5 {
    margin-bottom: 7.5rem;
  }

  .mb8rem-screen5, .my8rem-screen5 {
    margin-bottom: 8rem;
  }

  .ml7-5rem-screen5, .mx7-5rem-screen5 {
    margin-left: 7.5rem;
  }

  .ml8rem-screen5, .mx8rem-screen5 {
    margin-left: 8rem;
  }

  .p7-5rem-screen5 {
    padding: 7.5rem;
  }

  .p8rem-screen5 {
    padding: 8rem;
  }

  .pt7-5rem-screen5, .py7-5rem-screen5 {
    padding-top: 7.5rem;
  }

  .pt8rem-screen5, .py8rem-screen5 {
    padding-top: 8rem;
  }

  .pr7-5rem-screen5, .px7-5rem-screen5 {
    padding-right: 7.5rem;
  }

  .pr8rem-screen5, .px8rem-screen5 {
    padding-right: 8rem;
  }

  .pb7-5rem-screen5, .py7-5rem-screen5 {
    padding-bottom: 7.5rem;
  }

  .pb8rem-screen5, .py8rem-screen5 {
    padding-bottom: 8rem;
  }

  .pl7-5rem-screen5, .px7-5rem-screen5 {
    padding-left: 7.5rem;
  }

  .pl8rem-screen5, .px8rem-screen5 {
    padding-left: 8rem;
  }

  .m90-screen5 {
    margin: 90%;
  }

  .mt90-screen5, .my90-screen5 {
    margin-top: 90%;
  }

  .mr90-screen5, .mx90-screen5 {
    margin-right: 90%;
  }

  .mb90-screen5, .my90-screen5 {
    margin-bottom: 90%;
  }

  .ml90-screen5, .mx90-screen5 {
    margin-left: 90%;
  }

  .p90-screen5 {
    padding: 90%;
  }

  .pt90-screen5, .py90-screen5 {
    padding-top: 90%;
  }

  .pr90-screen5, .px90-screen5 {
    padding-right: 90%;
  }

  .pb90-screen5, .py90-screen5 {
    padding-bottom: 90%;
  }

  .pl90-screen5, .px90-screen5 {
    padding-left: 90%;
  }

  .m8-5rem-screen5 {
    margin: 8.5rem;
  }

  .m9rem-screen5 {
    margin: 9rem;
  }

  .mt8-5rem-screen5, .my8-5rem-screen5 {
    margin-top: 8.5rem;
  }

  .mt9rem-screen5, .my9rem-screen5 {
    margin-top: 9rem;
  }

  .mr8-5rem-screen5, .mx8-5rem-screen5 {
    margin-right: 8.5rem;
  }

  .mr9rem-screen5, .mx9rem-screen5 {
    margin-right: 9rem;
  }

  .mb8-5rem-screen5, .my8-5rem-screen5 {
    margin-bottom: 8.5rem;
  }

  .mb9rem-screen5, .my9rem-screen5 {
    margin-bottom: 9rem;
  }

  .ml8-5rem-screen5, .mx8-5rem-screen5 {
    margin-left: 8.5rem;
  }

  .ml9rem-screen5, .mx9rem-screen5 {
    margin-left: 9rem;
  }

  .p8-5rem-screen5 {
    padding: 8.5rem;
  }

  .p9rem-screen5 {
    padding: 9rem;
  }

  .pt8-5rem-screen5, .py8-5rem-screen5 {
    padding-top: 8.5rem;
  }

  .pt9rem-screen5, .py9rem-screen5 {
    padding-top: 9rem;
  }

  .pr8-5rem-screen5, .px8-5rem-screen5 {
    padding-right: 8.5rem;
  }

  .pr9rem-screen5, .px9rem-screen5 {
    padding-right: 9rem;
  }

  .pb8-5rem-screen5, .py8-5rem-screen5 {
    padding-bottom: 8.5rem;
  }

  .pb9rem-screen5, .py9rem-screen5 {
    padding-bottom: 9rem;
  }

  .pl8-5rem-screen5, .px8-5rem-screen5 {
    padding-left: 8.5rem;
  }

  .pl9rem-screen5, .px9rem-screen5 {
    padding-left: 9rem;
  }

  .m100-screen5 {
    margin: 100%;
  }

  .mt100-screen5, .my100-screen5 {
    margin-top: 100%;
  }

  .mr100-screen5, .mx100-screen5 {
    margin-right: 100%;
  }

  .mb100-screen5, .my100-screen5 {
    margin-bottom: 100%;
  }

  .ml100-screen5, .mx100-screen5 {
    margin-left: 100%;
  }

  .p100-screen5 {
    padding: 100%;
  }

  .pt100-screen5, .py100-screen5 {
    padding-top: 100%;
  }

  .pr100-screen5, .px100-screen5 {
    padding-right: 100%;
  }

  .pb100-screen5, .py100-screen5 {
    padding-bottom: 100%;
  }

  .pl100-screen5, .px100-screen5 {
    padding-left: 100%;
  }

  .m9-5rem-screen5 {
    margin: 9.5rem;
  }

  .m10rem-screen5 {
    margin: 10rem;
  }

  .mt9-5rem-screen5, .my9-5rem-screen5 {
    margin-top: 9.5rem;
  }

  .mt10rem-screen5, .my10rem-screen5 {
    margin-top: 10rem;
  }

  .mr9-5rem-screen5, .mx9-5rem-screen5 {
    margin-right: 9.5rem;
  }

  .mr10rem-screen5, .mx10rem-screen5 {
    margin-right: 10rem;
  }

  .mb9-5rem-screen5, .my9-5rem-screen5 {
    margin-bottom: 9.5rem;
  }

  .mb10rem-screen5, .my10rem-screen5 {
    margin-bottom: 10rem;
  }

  .ml9-5rem-screen5, .mx9-5rem-screen5 {
    margin-left: 9.5rem;
  }

  .ml10rem-screen5, .mx10rem-screen5 {
    margin-left: 10rem;
  }

  .p9-5rem-screen5 {
    padding: 9.5rem;
  }

  .p10rem-screen5 {
    padding: 10rem;
  }

  .pt9-5rem-screen5, .py9-5rem-screen5 {
    padding-top: 9.5rem;
  }

  .pt10rem-screen5, .py10rem-screen5 {
    padding-top: 10rem;
  }

  .pr9-5rem-screen5, .px9-5rem-screen5 {
    padding-right: 9.5rem;
  }

  .pr10rem-screen5, .px10rem-screen5 {
    padding-right: 10rem;
  }

  .pb9-5rem-screen5, .py9-5rem-screen5 {
    padding-bottom: 9.5rem;
  }

  .pb10rem-screen5, .py10rem-screen5 {
    padding-bottom: 10rem;
  }

  .pl9-5rem-screen5, .px9-5rem-screen5 {
    padding-left: 9.5rem;
  }

  .pl10rem-screen5, .px10rem-screen5 {
    padding-left: 10rem;
  }

  .wa-screen5 {
    width: auto;
  }

  .ha-screen5 {
    height: auto;
  }

  .w0-screen5 {
    width: 0;
  }

  .h0-screen5 {
    height: 0;
  }

  .maw0-screen5 {
    max-width: 0;
  }

  .mah0-screen5 {
    max-height: 0;
  }

  .mih0-screen5 {
    min-height: 0;
  }

  .w1rem-screen5 {
    width: 1rem;
  }

  .w10px-screen5 {
    width: 10px;
  }

  .w10-screen5 {
    width: 10%;
  }

  .w10vw-screen5 {
    width: 10vw;
  }

  .h1rem-screen5 {
    height: 1rem;
  }

  .h10px-screen5 {
    height: 10px;
  }

  .h10-screen5 {
    height: 10%;
  }

  .h10vh-screen5 {
    height: 10vh;
  }

  .miw1rem-screen5 {
    min-width: 1rem;
  }

  .miw100px-screen5 {
    min-width: 100px;
  }

  .miw10-screen5 {
    min-width: 10%;
  }

  .miw10vw-screen5 {
    min-width: 10vw;
  }

  .mih1rem-screen5 {
    min-height: 1rem;
  }

  .mih100px-screen5 {
    min-height: 100px;
  }

  .mih10-screen5 {
    min-height: 10%;
  }

  .mih10vh-screen5 {
    min-height: 10vh;
  }

  .maw1rem-screen5 {
    max-width: 1rem;
  }

  .maw100px-screen5 {
    max-width: 100px;
  }

  .maw10-screen5 {
    max-width: 10%;
  }

  .maw10vw-screen5 {
    max-width: 10vw;
  }

  .mah1rem-screen5 {
    max-height: 1rem;
  }

  .mah100px-screen5 {
    max-height: 100px;
  }

  .mah10-screen5 {
    max-height: 10%;
  }

  .mah10vh-screen5 {
    max-height: 10vh;
  }

  .w2rem-screen5 {
    width: 2rem;
  }

  .w20px-screen5 {
    width: 20px;
  }

  .w200px-screen5 {
    width: 200px;
  }

  .w20-screen5 {
    width: 20%;
  }

  .w20vw-screen5 {
    width: 20vw;
  }

  .h2rem-screen5 {
    height: 2rem;
  }

  .h20px-screen5 {
    height: 20px;
  }

  .h200px-screen5 {
    height: 200px;
  }

  .h20-screen5 {
    height: 20%;
  }

  .h20vh-screen5 {
    height: 20vh;
  }

  .miw2rem-screen5 {
    min-width: 2rem;
  }

  .miw200px-screen5 {
    min-width: 200px;
  }

  .miw20-screen5 {
    min-width: 20%;
  }

  .miw20vw-screen5 {
    min-width: 20vw;
  }

  .mih2rem-screen5 {
    min-height: 2rem;
  }

  .mih200px-screen5 {
    min-height: 200px;
  }

  .mih20-screen5 {
    min-height: 20%;
  }

  .mih20vh-screen5 {
    min-height: 20vh;
  }

  .maw2rem-screen5 {
    max-width: 2rem;
  }

  .maw200px-screen5 {
    max-width: 200px;
  }

  .maw20-screen5 {
    max-width: 20%;
  }

  .maw20vw-screen5 {
    max-width: 20vw;
  }

  .mah2rem-screen5 {
    max-height: 2rem;
  }

  .mah200px-screen5 {
    max-height: 200px;
  }

  .mah20-screen5 {
    max-height: 20%;
  }

  .mah20vh-screen5 {
    max-height: 20vh;
  }

  .w3rem-screen5 {
    width: 3rem;
  }

  .w30px-screen5 {
    width: 30px;
  }

  .w300px-screen5 {
    width: 300px;
  }

  .w30-screen5 {
    width: 30%;
  }

  .w30vw-screen5 {
    width: 30vw;
  }

  .h3rem-screen5 {
    height: 3rem;
  }

  .h30px-screen5 {
    height: 30px;
  }

  .h300px-screen5 {
    height: 300px;
  }

  .h30-screen5 {
    height: 30%;
  }

  .h30vh-screen5 {
    height: 30vh;
  }

  .miw3rem-screen5 {
    min-width: 3rem;
  }

  .miw300px-screen5 {
    min-width: 300px;
  }

  .miw30-screen5 {
    min-width: 30%;
  }

  .miw30vw-screen5 {
    min-width: 30vw;
  }

  .mih3rem-screen5 {
    min-height: 3rem;
  }

  .mih300px-screen5 {
    min-height: 300px;
  }

  .mih30-screen5 {
    min-height: 30%;
  }

  .mih30vh-screen5 {
    min-height: 30vh;
  }

  .maw3rem-screen5 {
    max-width: 3rem;
  }

  .maw300px-screen5 {
    max-width: 300px;
  }

  .maw30-screen5 {
    max-width: 30%;
  }

  .maw30vw-screen5 {
    max-width: 30vw;
  }

  .mah3rem-screen5 {
    max-height: 3rem;
  }

  .mah300px-screen5 {
    max-height: 300px;
  }

  .mah30-screen5 {
    max-height: 30%;
  }

  .mah30vh-screen5 {
    max-height: 30vh;
  }

  .w4rem-screen5 {
    width: 4rem;
  }

  .w40px-screen5 {
    width: 40px;
  }

  .w400px-screen5 {
    width: 400px;
  }

  .w40-screen5 {
    width: 40%;
  }

  .w40vw-screen5 {
    width: 40vw;
  }

  .h4rem-screen5 {
    height: 4rem;
  }

  .h40px-screen5 {
    height: 40px;
  }

  .h400px-screen5 {
    height: 400px;
  }

  .h40-screen5 {
    height: 40%;
  }

  .h40vh-screen5 {
    height: 40vh;
  }

  .miw4rem-screen5 {
    min-width: 4rem;
  }

  .miw400px-screen5 {
    min-width: 400px;
  }

  .miw40-screen5 {
    min-width: 40%;
  }

  .miw40vw-screen5 {
    min-width: 40vw;
  }

  .mih4rem-screen5 {
    min-height: 4rem;
  }

  .mih400px-screen5 {
    min-height: 400px;
  }

  .mih40-screen5 {
    min-height: 40%;
  }

  .mih40vh-screen5 {
    min-height: 40vh;
  }

  .maw4rem-screen5 {
    max-width: 4rem;
  }

  .maw400px-screen5 {
    max-width: 400px;
  }

  .maw40-screen5 {
    max-width: 40%;
  }

  .maw40vw-screen5 {
    max-width: 40vw;
  }

  .mah4rem-screen5 {
    max-height: 4rem;
  }

  .mah400px-screen5 {
    max-height: 400px;
  }

  .mah40-screen5 {
    max-height: 40%;
  }

  .mah40vh-screen5 {
    max-height: 40vh;
  }

  .w5rem-screen5 {
    width: 5rem;
  }

  .w50px-screen5 {
    width: 50px;
  }

  .w500px-screen5 {
    width: 500px;
  }

  .w50-screen5 {
    width: 50%;
  }

  .w50vw-screen5 {
    width: 50vw;
  }

  .h5rem-screen5 {
    height: 5rem;
  }

  .h50px-screen5 {
    height: 50px;
  }

  .h500px-screen5 {
    height: 500px;
  }

  .h50-screen5 {
    height: 50%;
  }

  .h50vh-screen5 {
    height: 50vh;
  }

  .miw5rem-screen5 {
    min-width: 5rem;
  }

  .miw500px-screen5 {
    min-width: 500px;
  }

  .miw50-screen5 {
    min-width: 50%;
  }

  .miw50vw-screen5 {
    min-width: 50vw;
  }

  .mih5rem-screen5 {
    min-height: 5rem;
  }

  .mih500px-screen5 {
    min-height: 500px;
  }

  .mih50-screen5 {
    min-height: 50%;
  }

  .mih50vh-screen5 {
    min-height: 50vh;
  }

  .maw5rem-screen5 {
    max-width: 5rem;
  }

  .maw500px-screen5 {
    max-width: 500px;
  }

  .maw50-screen5 {
    max-width: 50%;
  }

  .maw50vw-screen5 {
    max-width: 50vw;
  }

  .mah5rem-screen5 {
    max-height: 5rem;
  }

  .mah500px-screen5 {
    max-height: 500px;
  }

  .mah50-screen5 {
    max-height: 50%;
  }

  .mah50vh-screen5 {
    max-height: 50vh;
  }

  .w6rem-screen5 {
    width: 6rem;
  }

  .w60px-screen5 {
    width: 60px;
  }

  .w600px-screen5 {
    width: 600px;
  }

  .w60-screen5 {
    width: 60%;
  }

  .w60vw-screen5 {
    width: 60vw;
  }

  .h6rem-screen5 {
    height: 6rem;
  }

  .h60px-screen5 {
    height: 60px;
  }

  .h600px-screen5 {
    height: 600px;
  }

  .h60-screen5 {
    height: 60%;
  }

  .h60vh-screen5 {
    height: 60vh;
  }

  .miw6rem-screen5 {
    min-width: 6rem;
  }

  .miw600px-screen5 {
    min-width: 600px;
  }

  .miw60-screen5 {
    min-width: 60%;
  }

  .miw60vw-screen5 {
    min-width: 60vw;
  }

  .mih6rem-screen5 {
    min-height: 6rem;
  }

  .mih600px-screen5 {
    min-height: 600px;
  }

  .mih60-screen5 {
    min-height: 60%;
  }

  .mih60vh-screen5 {
    min-height: 60vh;
  }

  .maw6rem-screen5 {
    max-width: 6rem;
  }

  .maw600px-screen5 {
    max-width: 600px;
  }

  .maw60-screen5 {
    max-width: 60%;
  }

  .maw60vw-screen5 {
    max-width: 60vw;
  }

  .mah6rem-screen5 {
    max-height: 6rem;
  }

  .mah600px-screen5 {
    max-height: 600px;
  }

  .mah60-screen5 {
    max-height: 60%;
  }

  .mah60vh-screen5 {
    max-height: 60vh;
  }

  .w7rem-screen5 {
    width: 7rem;
  }

  .w70px-screen5 {
    width: 70px;
  }

  .w700px-screen5 {
    width: 700px;
  }

  .w70-screen5 {
    width: 70%;
  }

  .w70vw-screen5 {
    width: 70vw;
  }

  .h7rem-screen5 {
    height: 7rem;
  }

  .h70px-screen5 {
    height: 70px;
  }

  .h700px-screen5 {
    height: 700px;
  }

  .h70-screen5 {
    height: 70%;
  }

  .h70vh-screen5 {
    height: 70vh;
  }

  .miw7rem-screen5 {
    min-width: 7rem;
  }

  .miw700px-screen5 {
    min-width: 700px;
  }

  .miw70-screen5 {
    min-width: 70%;
  }

  .miw70vw-screen5 {
    min-width: 70vw;
  }

  .mih7rem-screen5 {
    min-height: 7rem;
  }

  .mih700px-screen5 {
    min-height: 700px;
  }

  .mih70-screen5 {
    min-height: 70%;
  }

  .mih70vh-screen5 {
    min-height: 70vh;
  }

  .maw7rem-screen5 {
    max-width: 7rem;
  }

  .maw700px-screen5 {
    max-width: 700px;
  }

  .maw70-screen5 {
    max-width: 70%;
  }

  .maw70vw-screen5 {
    max-width: 70vw;
  }

  .mah7rem-screen5 {
    max-height: 7rem;
  }

  .mah700px-screen5 {
    max-height: 700px;
  }

  .mah70-screen5 {
    max-height: 70%;
  }

  .mah70vh-screen5 {
    max-height: 70vh;
  }

  .w8rem-screen5 {
    width: 8rem;
  }

  .w80px-screen5 {
    width: 80px;
  }

  .w800px-screen5 {
    width: 800px;
  }

  .w80-screen5 {
    width: 80%;
  }

  .w80vw-screen5 {
    width: 80vw;
  }

  .h8rem-screen5 {
    height: 8rem;
  }

  .h80px-screen5 {
    height: 80px;
  }

  .h800px-screen5 {
    height: 800px;
  }

  .h80-screen5 {
    height: 80%;
  }

  .h80vh-screen5 {
    height: 80vh;
  }

  .miw8rem-screen5 {
    min-width: 8rem;
  }

  .miw800px-screen5 {
    min-width: 800px;
  }

  .miw80-screen5 {
    min-width: 80%;
  }

  .miw80vw-screen5 {
    min-width: 80vw;
  }

  .mih8rem-screen5 {
    min-height: 8rem;
  }

  .mih800px-screen5 {
    min-height: 800px;
  }

  .mih80-screen5 {
    min-height: 80%;
  }

  .mih80vh-screen5 {
    min-height: 80vh;
  }

  .maw8rem-screen5 {
    max-width: 8rem;
  }

  .maw800px-screen5 {
    max-width: 800px;
  }

  .maw80-screen5 {
    max-width: 80%;
  }

  .maw80vw-screen5 {
    max-width: 80vw;
  }

  .mah8rem-screen5 {
    max-height: 8rem;
  }

  .mah800px-screen5 {
    max-height: 800px;
  }

  .mah80-screen5 {
    max-height: 80%;
  }

  .mah80vh-screen5 {
    max-height: 80vh;
  }

  .w9rem-screen5 {
    width: 9rem;
  }

  .w90px-screen5 {
    width: 90px;
  }

  .w900px-screen5 {
    width: 900px;
  }

  .w90-screen5 {
    width: 90%;
  }

  .w90vw-screen5 {
    width: 90vw;
  }

  .h9rem-screen5 {
    height: 9rem;
  }

  .h90px-screen5 {
    height: 90px;
  }

  .h900px-screen5 {
    height: 900px;
  }

  .h90-screen5 {
    height: 90%;
  }

  .h90vh-screen5 {
    height: 90vh;
  }

  .miw9rem-screen5 {
    min-width: 9rem;
  }

  .miw900px-screen5 {
    min-width: 900px;
  }

  .miw90-screen5 {
    min-width: 90%;
  }

  .miw90vw-screen5 {
    min-width: 90vw;
  }

  .mih9rem-screen5 {
    min-height: 9rem;
  }

  .mih900px-screen5 {
    min-height: 900px;
  }

  .mih90-screen5 {
    min-height: 90%;
  }

  .mih90vh-screen5 {
    min-height: 90vh;
  }

  .maw9rem-screen5 {
    max-width: 9rem;
  }

  .maw900px-screen5 {
    max-width: 900px;
  }

  .maw90-screen5 {
    max-width: 90%;
  }

  .maw90vw-screen5 {
    max-width: 90vw;
  }

  .mah9rem-screen5 {
    max-height: 9rem;
  }

  .mah900px-screen5 {
    max-height: 900px;
  }

  .mah90-screen5 {
    max-height: 90%;
  }

  .mah90vh-screen5 {
    max-height: 90vh;
  }

  .w10rem-screen5 {
    width: 10rem;
  }

  .w100px-screen5 {
    width: 100px;
  }

  .w1000px-screen5 {
    width: 1000px;
  }

  .w100-screen5 {
    width: 100%;
  }

  .w100vw-screen5 {
    width: 100vw;
  }

  .h10rem-screen5 {
    height: 10rem;
  }

  .h100px-screen5 {
    height: 100px;
  }

  .h1000px-screen5 {
    height: 1000px;
  }

  .h100-screen5 {
    height: 100%;
  }

  .h100vh-screen5 {
    height: 100vh;
  }

  .miw10rem-screen5 {
    min-width: 10rem;
  }

  .miw1000px-screen5 {
    min-width: 1000px;
  }

  .miw100-screen5 {
    min-width: 100%;
  }

  .miw100vw-screen5 {
    min-width: 100vw;
  }

  .mih10rem-screen5 {
    min-height: 10rem;
  }

  .mih1000px-screen5 {
    min-height: 1000px;
  }

  .mih100-screen5 {
    min-height: 100%;
  }

  .mih100vh-screen5 {
    min-height: 100vh;
  }

  .maw10rem-screen5 {
    max-width: 10rem;
  }

  .maw1000px-screen5 {
    max-width: 1000px;
  }

  .maw100-screen5 {
    max-width: 100%;
  }

  .maw100vw-screen5 {
    max-width: 100vw;
  }

  .mah10rem-screen5 {
    max-height: 10rem;
  }

  .mah1000px-screen5 {
    max-height: 1000px;
  }

  .mah100-screen5 {
    max-height: 100%;
  }

  .mah100vh-screen5 {
    max-height: 100vh;
  }

  .or0-screen5 {
    order: 0;
  }

  .br0px-screen5 {
    border-radius: 0;
  }

  .btlr0px-screen5 {
    border-top-left-radius: 0;
  }

  .btrr0px-screen5 {
    border-top-right-radius: 0;
  }

  .bblr0px-screen5 {
    border-bottom-left-radius: 0;
  }

  .bbrr0px-screen5 {
    border-bottom-right-radius: 0;
  }

  .or1-screen5 {
    order: 1;
  }

  .br5px-screen5 {
    border-radius: 6px;
  }

  .br8px-screen5 {
    border-radius: 8px;
  }

  .br19px-screen5 {
    border-radius: 19px;
  }

  .btlr5px-screen5 {
    border-top-left-radius: 5px;
  }

  .btrr5px-screen5 {
    border-top-right-radius: 5px;
  }

  .bblr5px-screen5 {
    border-bottom-left-radius: 5px;
  }

  .bbrr5px-screen5 {
    border-bottom-right-radius: 5px;
  }

  .or2-screen5 {
    order: 2;
  }

  .br10px-screen5 {
    border-radius: 12px;
  }

  .br16px-screen5 {
    border-radius: 16px;
  }

  .br38px-screen5 {
    border-radius: 38px;
  }

  .btlr10px-screen5 {
    border-top-left-radius: 10px;
  }

  .btrr10px-screen5 {
    border-top-right-radius: 10px;
  }

  .bblr10px-screen5 {
    border-bottom-left-radius: 10px;
  }

  .bbrr10px-screen5 {
    border-bottom-right-radius: 10px;
  }

  .or3-screen5 {
    order: 3;
  }

  .br15px-screen5 {
    border-radius: 18px;
  }

  .br24px-screen5 {
    border-radius: 24px;
  }

  .br57px-screen5 {
    border-radius: 57px;
  }

  .btlr15px-screen5 {
    border-top-left-radius: 15px;
  }

  .btrr15px-screen5 {
    border-top-right-radius: 15px;
  }

  .bblr15px-screen5 {
    border-bottom-left-radius: 15px;
  }

  .bbrr15px-screen5 {
    border-bottom-right-radius: 15px;
  }

  .or4-screen5 {
    order: 4;
  }

  .br20px-screen5 {
    border-radius: 24px;
  }

  .br32px-screen5 {
    border-radius: 32px;
  }

  .br76px-screen5 {
    border-radius: 76px;
  }

  .btlr20px-screen5 {
    border-top-left-radius: 20px;
  }

  .btrr20px-screen5 {
    border-top-right-radius: 20px;
  }

  .bblr20px-screen5 {
    border-bottom-left-radius: 20px;
  }

  .bbrr20px-screen5 {
    border-bottom-right-radius: 20px;
  }

  .or5-screen5 {
    order: 5;
  }

  .br25px-screen5 {
    border-radius: 30px;
  }

  .br95px-screen5 {
    border-radius: 95px;
  }

  .btlr25px-screen5 {
    border-top-left-radius: 25px;
  }

  .btrr25px-screen5 {
    border-top-right-radius: 25px;
  }

  .bblr25px-screen5 {
    border-bottom-left-radius: 25px;
  }

  .bbrr25px-screen5 {
    border-bottom-right-radius: 25px;
  }

  .or6-screen5 {
    order: 6;
  }

  .br30px-screen5 {
    border-radius: 36px;
  }

  .br48px-screen5 {
    border-radius: 48px;
  }

  .br114px-screen5 {
    border-radius: 114px;
  }

  .btlr30px-screen5 {
    border-top-left-radius: 30px;
  }

  .btrr30px-screen5 {
    border-top-right-radius: 30px;
  }

  .bblr30px-screen5 {
    border-bottom-left-radius: 30px;
  }

  .bbrr30px-screen5 {
    border-bottom-right-radius: 30px;
  }

  .or7-screen5 {
    order: 7;
  }

  .br35px-screen5 {
    border-radius: 42px;
  }

  .br56px-screen5 {
    border-radius: 56px;
  }

  .br133px-screen5 {
    border-radius: 133px;
  }

  .btlr35px-screen5 {
    border-top-left-radius: 35px;
  }

  .btrr35px-screen5 {
    border-top-right-radius: 35px;
  }

  .bblr35px-screen5 {
    border-bottom-left-radius: 35px;
  }

  .bbrr35px-screen5 {
    border-bottom-right-radius: 35px;
  }

  .or8-screen5 {
    order: 8;
  }

  .br40px-screen5 {
    border-radius: 48px;
  }

  .br64px-screen5 {
    border-radius: 64px;
  }

  .br152px-screen5 {
    border-radius: 152px;
  }

  .btlr40px-screen5 {
    border-top-left-radius: 40px;
  }

  .btrr40px-screen5 {
    border-top-right-radius: 40px;
  }

  .bblr40px-screen5 {
    border-bottom-left-radius: 40px;
  }

  .bbrr40px-screen5 {
    border-bottom-right-radius: 40px;
  }

  .or9-screen5 {
    order: 9;
  }

  .br45px-screen5 {
    border-radius: 54px;
  }

  .br72px-screen5 {
    border-radius: 72px;
  }

  .br171px-screen5 {
    border-radius: 171px;
  }

  .btlr45px-screen5 {
    border-top-left-radius: 45px;
  }

  .btrr45px-screen5 {
    border-top-right-radius: 45px;
  }

  .bblr45px-screen5 {
    border-bottom-left-radius: 45px;
  }

  .bbrr45px-screen5 {
    border-bottom-right-radius: 45px;
  }

  .or10-screen5 {
    order: 10;
  }

  .br50px-screen5 {
    border-radius: 60px;
  }

  .br80px-screen5 {
    border-radius: 80px;
  }

  .br190px-screen5 {
    border-radius: 190px;
  }

  .btlr50px-screen5 {
    border-top-left-radius: 50px;
  }

  .btrr50px-screen5 {
    border-top-right-radius: 50px;
  }

  .bblr50px-screen5 {
    border-bottom-left-radius: 50px;
  }

  .bbrr50px-screen5 {
    border-bottom-right-radius: 50px;
  }

  .br100 {
    border-radius: 100%;
  }

  .btlr100 {
    border-top-left-radius: 100%;
  }

  .btrr100 {
    border-top-right-radius: 100%;
  }

  .bblr100 {
    border-bottom-left-radius: 100%;
  }

  .bbrr100 {
    border-bottom-right-radius: 100%;
  }
}

.all-sidebar-report .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.all-sidebar-report .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 7px 0;
}

.all-sidebar-report .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px;
}

.all-sidebar-report .css-ahj2mt-MuiTypography-root {
  font-size: 12px;
  font-weight: 500;
}

.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon {
  text-transform: capitalize;
  color: #151313;
  box-sizing: border-box;
  margin: 0 31px 0 19px;
  padding: 0;
  font-size: 21px;
  font-weight: 300;
}

.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root:before, .advisor-index button.MuiTab-labelIcon:before {
  content: "";
  background-color: #0000;
  width: 90%;
  height: 2px;
  position: absolute;
  bottom: 9px;
  left: 54%;
  transform: translateX(-50%);
}

.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected:before, .advisor-index button.Mui-selected:before {
  background-color: #2947ef;
}

.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected, .advisor-index button.Mui-selected {
  color: #2947ef;
  background: none;
  font-weight: 500;
  font-size: 22px !important;
}

.advisor-index button.css-1q2h7u5, .advisor-index button.Mui-selected {
  text-transform: capitalize !important;
  font-size: 18px !important;
}

.dahboard-tabs .MuiTabPanel-root .container {
  max-width: 100%;
}

.advisor-index .MuiTabs-root .MuiTabs-indicator {
  display: none;
}

.advisor-index .css-67l5gl.Mui-expanded {
  margin: 0;
}

.advisor-index .css-1iji0d4.Mui-expanded {
  min-height: 50px;
}

.advisor-index .css-9l3uo3 {
  font-size: 11px;
}

.menuHover:hover .menu, .menu:hover {
  pointer-events: auto;
  opacity: 1;
}

.shadow {
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.new-shadow {
  box-shadow: 0 0 13px #32325d20;
}

.cpflp {
  padding: .3rem .5rem;
}

#hero {
  background-image: url("hero.2699d40d.svg");
  background-position: center;
  background-size: 10rem;
  animation: 1000s linear infinite heroMoveBackground;
}

.checkbox {
  pointer-events: none;
}

.checkbox > input {
  appearance: none;
  -o-appearance: none;
  pointer-events: auto;
}

.checkbox > input:checked, .checkbox.active > input:checked {
  background-color: #fc7785;
  border-color: #fc7785;
}

.checkbox:before {
  content: "";
  background-color: #f0f0f8;
  border-radius: 2px 0 2px 2px;
  width: 30%;
  height: 15%;
  position: absolute;
  top: 52.5%;
  left: 20%;
  transform: rotate(45deg);
}

.activeTabBlog:after {
  content: "";
  background-color: #2947ef;
  border-radius: 20px;
  width: 53px;
  height: 2px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.checkbox:after {
  content: "";
  opacity: .4;
  background-color: #f0f0f8;
  border-radius: 0 2px 2px 0;
  width: 50%;
  height: 15%;
  position: absolute;
  top: 42.5%;
  left: 32.5%;
  transform: rotate(-45deg);
}

.checkobox-report .checkbox {
  appearance: none;
  -o-appearance: none;
  pointer-events: auto;
  background-color: #f0f0f8;
}

.checkobox-report .checkbox:before {
  content: "";
  opacity: 0;
  background-color: #f0f0f8;
  border-radius: 2px 0 2px 2px;
  width: 30%;
  height: 15%;
  position: absolute;
  top: 52.5%;
  left: 20%;
  transform: rotate(45deg);
}

.checkobox-report .checkbox:after {
  content: "";
  opacity: 0;
  background-color: #f0f0f8;
  border-radius: 0 2px 2px 0;
  width: 50%;
  height: 15%;
  position: absolute;
  top: 42.5%;
  left: 32.5%;
  transform: rotate(-45deg);
}

.checkobox-report .checkbox:checked {
  background-color: #fc7785;
  border-color: #fc7785;
}

.checkobox-report .checkbox:checked:before {
  opacity: 1;
}

.checkobox-report .checkbox:checked:after {
  opacity: .4;
}

.checkbox.checkobox-round:after {
  display: none;
}

.checkbox.checkobox-round:before {
  background-color: #f0f0f890;
  border-radius: 50%;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#popup-message.hidden {
  transform: translateX(-125%);
}

@keyframes heroMoveBackground {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 1000rem -1000rem;
  }
}

@keyframes slideRight {
  from {
    right: 0;
  }

  to {
    right: -267px;
  }
}

.girlImage {
  width: 267px;
  height: 381px;
  animation: 1s forwards slideRight;
  position: absolute;
  top: -190px;
}

#testimonialWrapper:before {
  content: "";
  z-index: 1;
  background: linear-gradient(90deg, #f0f0f8, #0000);
  width: 2.5rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#testimonialWrapper:after {
  content: "";
  z-index: 1;
  background: linear-gradient(90deg, #0000, #f0f0f8);
  width: 2.5rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

table tr:nth-child(2n), .tableRow:nth-child(2n), table tr > :nth-child(2n), .tableRow > :nth-child(2n) {
  background: #e1e1f155;
}

.table-permission .table-userPermissions tr:nth-child(2n), .table-permission .table-userPermissions .tableRow:nth-child(2n), .table-permission .table-userPermissions tr > :nth-child(2n), .table-permission .table-userPermissions .tableRow > :nth-child(2n) {
  background: none;
}

.table-permission .table-userPermissions .advisor-profile {
  background-color: #7385be70;
  border: 2px solid #7385be50;
  width: 2.5rem;
  height: 2.5rem;
}

table {
  border-spacing: 0;
}

table th {
  font-weight: 600;
}

table th, table td {
  outline: 0;
  width: 20%;
  min-width: 200px;
  padding: .5rem 1rem;
}

table thead th {
  text-align: center;
  border-bottom: 2px solid #002d63;
  font-size: 1.5rem;
  background: #fff !important;
}

table td {
  text-align: center;
}

table tbody th {
  background: #fff;
  border-right: 2px solid #002d63;
}

.table-advisor th, .table-advisor td {
  width: 150px;
  min-width: auto;
}

.table-advisor th svg, .table-advisor td svg {
  width: 21px;
  height: 21px;
  margin-top: 8px;
  display: inline-block;
}

.table-advisor td:nth-child(4) {
  text-align: center;
}

.table-advisor td:nth-child(4) svg {
  width: 38px;
  height: 38px;
}

.audit-log-btn {
  top: -125px;
  right: 21px;
}

.chat-gpt-drawer .css-4t3x6l-MuiPaper-root-MuiDrawer-paper, .chat-gpt-drawer .css-wf16b5 {
  width: 60%;
}

.speech-bubble:before {
  content: "";
  background: linear-gradient(45deg, #0000 49%, #4a67ff 50%);
  width: 1rem;
  height: 1rem;
  position: absolute;
  bottom: -1rem;
  right: 0;
}

.speech-bubble.bg5:before {
  background: linear-gradient(-45deg, #0000 49%, #f0f0f8 50%);
  left: 0;
}

.fds {
  filter: drop-shadow(0 4px 8px #0008);
}

#expandHeader {
  width: 4rem;
}

#expandHeader:hover {
  width: 230px;
}

.a4 {
  width: 220mm;
  height: 340mm;
}

.a4-pdf {
  width: 210mm;
  height: 297.7mm;
}

.all-a4-pdf {
  width: 210mm;
  height: 350mm;
}

.workflow-pdf {
  width: 210mm;
  height: 297.7mm;
}

.lst {
  list-style-type: none;
}

@media (width <= 74.99rem) {
  #menu {
    z-index: 5;
    background: linear-gradient(45deg, #002d63, #4a67ff);
    width: 230px;
    height: 100vh;
    right: 0;
  }

  #menu:not(.showing) {
    right: -100%;
  }

  #menu:not(.showing).showing {
    right: 0;
  }
}

.riskSlider input {
  -webkit-appearance: none;
  background: linear-gradient(90deg, #40dda1, #dd4040);
  height: 5px;
}

.riskSlider input::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  z-index: 1;
  background: #fff center / cover no-repeat;
  border-radius: 100%;
  outline: 0;
  width: 2rem;
  height: 2rem;
  position: relative;
}

.riskSlider input::-moz-range-thumb {
  pointer-events: all;
  z-index: 10;
  -moz-appearance: none;
  background: #fff center / cover no-repeat;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  position: relative;
}

.riskSlider input.riskIfYes::-webkit-slider-thumb {
  background-image: url("Yes.28cd902b.png");
}

.riskSlider input.riskIfYes::-moz-range-thumb {
  background-image: url("Yes.28cd902b.png");
}

.riskSlider input.riskIfNo::-webkit-slider-thumb {
  background-image: url("No.f9cbc566.png");
}

.riskSlider input.riskIfNo::-moz-range-thumb {
  background-image: url("No.f9cbc566.png");
}

.riskSlider input.riskIf1::-webkit-slider-thumb {
  background-image: url("1.fd7d012a.png");
}

.riskSlider input.riskIf1::-moz-range-thumb {
  background-image: url("1.fd7d012a.png");
}

.riskSlider input.riskIf2::-webkit-slider-thumb {
  background-image: url("2.0944c2bc.png");
}

.riskSlider input.riskIf2::-moz-range-thumb {
  background-image: url("2.0944c2bc.png");
}

.riskSlider input.riskIf3::-webkit-slider-thumb {
  background-image: url("3.40e22d99.png");
}

.riskSlider input.riskIf3::-moz-range-thumb {
  background-image: url("3.40e22d99.png");
}

.riskSlider input.riskIf4::-webkit-slider-thumb {
  background-image: url("4.af5e55b2.png");
}

.riskSlider input.riskIf4::-moz-range-thumb {
  background-image: url("4.af5e55b2.png");
}

.riskSlider input.riskIf5::-webkit-slider-thumb {
  background-image: url("5.56dc92df.png");
}

.riskSlider input.riskIf5::-moz-range-thumb {
  background-image: url("5.56dc92df.png");
}

.riskSlider input.riskIfD::-webkit-slider-thumb {
  background-image: url("1.fd7d012a.png");
}

.riskSlider input.riskIfD::-moz-range-thumb {
  background-image: url("1.fd7d012a.png");
}

.riskSlider input.riskIfNS::-webkit-slider-thumb {
  background-image: url("2.0944c2bc.png");
}

.riskSlider input.riskIfNS::-moz-range-thumb {
  background-image: url("2.0944c2bc.png");
}

.riskSlider input.riskIfND::-webkit-slider-thumb {
  background-image: url("3.40e22d99.png");
}

.riskSlider input.riskIfND::-moz-range-thumb {
  background-image: url("3.40e22d99.png");
}

.riskSlider input::-moz-range-track {
  z-index: -1;
  background-color: #000;
  border: 0;
  position: relative;
}

.riskSlider input:last-of-type::-moz-range-track {
  -moz-appearance: none;
  background: none;
  border: 0;
}

.riskSlider input[type="range"]::-moz-focus-outer {
  border: 0;
}

.gtcr-audit {
  grid-gap: 10px;
  grid-template-columns: 175px 75px 203px 203px;
  padding: 7px 10px;
  display: grid;
}

.gtcr-audit a {
  overflow-wrap: anywhere;
}

.gtcr-workflow-head {
  grid-template-columns: 175px 75px 203px 203px;
}

.gtcr-audit-two {
  grid-gap: 10px;
  grid-template-columns: 175px 75px 413px;
  padding: 10px;
  display: grid;
}

.gtcr-audit-workflow {
  grid-template-columns: 210px 75px 435px;
  padding: 10px;
  display: grid;
}

.gtcr-audit-fit-report {
  grid-gap: 10px;
  grid-template-columns: 335px 340px;
  padding: 10px;
  display: grid;
}

.gtcr-audit-fit-report-about {
  grid-gap: 10px;
  grid-template-columns: 130px 165px 165px 165px;
  padding: 10px;
  display: grid;
}

.logo-img {
  width: 100px;
}

.button-groups-new .close-popup {
  background-color: #fff0;
  position: absolute;
  right: 0;
}

.button-groups-new .close-popup svg {
  box-shadow: none;
  background-color: #fff0;
}

.button-groups-new button {
  position: relative;
}

.button-groups-new button svg {
  background-color: #fff;
  padding: 5px;
  top: auto;
  bottom: -10px;
  left: -8px;
}

.button-groups-new button .director-tooltip {
  bottom: -25px;
  left: -70px;
}

.packge-select {
  justify-content: center;
  align-items: center;
  display: flex;
}

.packge-select.bottom-packge .audit-pakcges.bronze-pakcge-brown {
  background-image: url("bronze.38ee917d.png");
}

.packge-select.bottom-packge .audit-pakcges.silver-pakcge {
  background-image: url("silver.d336da1b.png");
}

.packge-select.bottom-packge .audit-pakcges.gold-pakcge {
  background-image: url("gold.4e728253.png");
}

.packge-select.bottom-packge .audit-pakcges .left-area {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 6px;
}

.packge-select.bottom-packge .audit-pakcges .left-area p, .packge-select.bottom-packge .audit-pakcges .left-area span {
  margin-left: 3px;
  font-size: .7rem;
}

.packge-select.bottom-packge .audit-pakcges .right-area {
  width: 100%;
  position: absolute;
  bottom: 33px;
}

.packge-select.bottom-packge .audit-pakcges .right-area p {
  color: #000;
  font-size: .4rem;
}

.packge-select .audit-pakcges {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-wrap: wrap;
  align-items: center;
  width: 122px;
  height: 119px;
  margin: 0 8px;
  display: flex;
  position: relative;
}

.packge-select .currentz {
  border-radius: 97px;
  box-shadow: 0 40px #41dc9f;
}

.op70 {
  opacity: .8;
}

.z12 {
  zoom: 1.2;
}

.extenstion-pack-btn-groups .btn-packge {
  background-color: #f0f0f8;
  border-radius: 12px;
  flex-wrap: wrap;
  align-items: center;
  min-height: 115px;
  font-size: 16px;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
}

.extenstion-pack-btn-groups .btn-packge .ribbon {
  position: absolute;
  bottom: 0;
  right: -37px;
  transform: rotate(-45deg);
}

.extenstion-pack-btn-groups .btn-packge .ribbon img {
  width: 99px;
  height: auto !important;
}

.extenstion-pack-btn-groups .btn-packge .ribbon.bronze, .extenstion-pack-btn-groups .btn-packge .ribbon.silver, .extenstion-pack-btn-groups .btn-packge .ribbon.gold {
  bottom: -14px;
  right: -20px;
  transform: rotate(0);
}

.extenstion-pack-btn-groups .btn-packge .ribbon.bronze img, .extenstion-pack-btn-groups .btn-packge .ribbon.silver img, .extenstion-pack-btn-groups .btn-packge .ribbon.gold img {
  width: 53px;
}

.extenstion-pack-btn-groups .btn-packge .trophy-cup {
  position: absolute;
  top: 65px;
  right: -7px !important;
  transform: rotate(0) !important;
}

.extenstion-pack-btn-groups .btn-packge .trophy-cup img {
  width: 53px !important;
}

.extenstion-pack-btn-groups .btn-packge.op20 {
  background-color: #f0f0f880g;
  color: #002d6340;
  opacity: 1;
  border-color: #ccc;
  transition: all .3s ease-in-out;
}

.extenstion-pack-btn-groups .btn-packge button {
  width: 70%;
  transition: all .3s ease-in-out;
  font-size: 12px !important;
}

.extenstion-pack-btn-groups .btn-packge:hover {
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.extenstion-pack-btn-groups .btn-packge .tooltip-area {
  position: absolute;
  top: -6px;
  right: -8px;
}

.extenstion-pack-btn-groups .btn-packge .tooltip-area .icon {
  transition: all .3s ease-in-out;
  display: inline-block;
  position: relative;
}

.extenstion-pack-btn-groups .btn-packge .tooltip-area .icon img {
  width: 21px;
  height: auto;
}

.extenstion-pack-btn-groups .btn-packge .tooltip-area .icon .overlay-box {
  z-index: 999;
  text-align: left;
  background-color: #fff;
  border-radius: 20px;
  min-width: 350px;
  padding: 20px 16px 25px;
  font-style: normal;
  transition: all .3s ease-in-out;
  display: none;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.extenstion-pack-btn-groups .btn-packge .tooltip-area .icon .overlay-box:before {
  content: "";
  border: 15px solid #0000;
  border-left: 15.9px solid #fff;
  border-right-width: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 49%;
  right: -14px;
  transform: translateY(-50%);
}

.extenstion-pack-btn-groups .btn-packge .tooltip-area .icon .overlay-box p {
  text-transform: none;
  margin-bottom: 5px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.extenstion-pack-btn-groups .btn-packge .tooltip-area .icon .overlay-box .bottom-text p {
  font-size: 11px;
}

.extenstion-pack-btn-groups .btn-packge .tooltip-area .icon .overlay-box .price {
  text-align: center;
  margin-top: 13px;
  font-size: 19px;
  font-style: normal;
}

.extenstion-pack-btn-groups .btn-packge .tooltip-area .icon .overlay-box button {
  z-index: 999;
}

.extenstion-pack-btn-groups .btn-packge .tooltip-area:hover .overlay-box {
  transition: all .3s ease-in-out;
  display: block;
}

.extenstion-pack-btn-groups .btn-packge .tooltip-area.style-two {
  top: -25px;
  right: -15px;
}

.extenstion-pack-btn-groups .btn-packge .percentage-area {
  background-color: #ffffff90;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  padding: 5px;
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.extenstion-pack-btn-groups .btn-packge .percentage-area h4 span {
  font-weight: 400;
}

.extenstion-pack-btn-groups .btn-packge .percentage-area h4.red-text {
  color: #fc7785;
  font-size: 12px;
  line-height: 1.1;
}

.extenstion-pack-btn-groups .btn-packge .percentage-area h4.red-text span {
  color: #002d63;
  font-size: 9px;
}

.extenstion-pack-btn-groups .btn-packge .percentage-area .progress-bar svg {
  box-shadow: none;
  background-color: #0000;
  padding: 0;
}

.extenstion-pack-btn-groups .btn-packge .percentage-area .text-center-area {
  display: block;
  font-size: 6px !important;
  line-height: 6px !important;
}

.white {
  color: #fff;
}

.blue {
  color: #4a67ff;
}

.red {
  color: #ff3d3d;
}

.yellow {
  color: #fcb22b;
}

.green {
  color: #5ee15e;
}

.fw500 {
  font-weight: 500;
}

.w24 {
  width: 18%;
}

.w250px {
  width: 270px;
}

.w45 {
  width: 45%;
}

.w50 {
  width: 50%;
}

.w43 {
  width: 43%;
}

.w33 {
  width: 33.33%;
}

.CircularProgressbar.red .CircularProgressbar-path {
  stroke: #ff3d3d;
}

.CircularProgressbar.red .CircularProgressbar-text {
  fill: #151313;
}

.CircularProgressbar.yellow .CircularProgressbar-path {
  stroke: #ff0;
}

.CircularProgressbar.yellow .CircularProgressbar-text {
  fill: #151313;
}

.CircularProgressbar.orange .CircularProgressbar-path {
  stroke: #fcb22b;
}

.CircularProgressbar.orange .CircularProgressbar-text {
  fill: #151313;
}

.CircularProgressbar.green .CircularProgressbar-path {
  stroke: #5ee15e;
}

.CircularProgressbar.green .CircularProgressbar-text {
  fill: #151313;
}

.CircularProgressbar.red .CircularProgressbar-trail {
  stroke: #ff3d3d40 !important;
}

.CircularProgressbar.blue .CircularProgressbar-trail {
  stroke: #4a67ff40 !important;
}

.CircularProgressbar.yellow .CircularProgressbar-trail {
  stroke: #ffff0040 !important;
}

.CircularProgressbar.green .CircularProgressbar-trail {
  stroke: #5ee15e40 !important;
}

.CircularProgressbar.orange .CircularProgressbar-trail {
  stroke: #fcb22b40 !important;
}

#expandHeader .pr.df a, #expandHeader .pr.df span.usn {
  box-sizing: border-box;
  border-radius: 7px;
  margin: 0 0 7px;
  padding: 13px 15px 13px 40px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  position: relative;
  color: #151313 !important;
}

#expandHeader .pr.df a .dropdown, #expandHeader .pr.df span.usn .dropdown {
  z-index: 99;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  display: none;
  position: absolute;
  top: 32px;
  left: 0;
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

#expandHeader .pr.df a .dropdown a, #expandHeader .pr.df span.usn .dropdown a {
  color: #002d63;
  width: 100%;
  margin-bottom: 5px;
  padding: 0;
  font-size: 15px;
  display: block;
}

#expandHeader .pr.df a .dropdown a:hover, #expandHeader .pr.df span.usn .dropdown a:hover {
  color: #fc7785;
}

#expandHeader .pr.df a .icon, #expandHeader .pr.df span.usn .icon {
  position: absolute;
  top: 1px;
  right: -4px;
}

#expandHeader .pr.df a .icon svg, #expandHeader .pr.df span.usn .icon svg {
  zoom: .6;
  opacity: .5;
  width: 46px;
}

#expandHeader .pr.df a:hover .dropdown, #expandHeader .pr.df span.usn:hover .dropdown {
  display: block;
}

#expandHeader .pr.df a .dropdown, #expandHeader .pr.df span.usn .dropdown {
  position: relative;
}

#expandHeader .pr.df a .dropdown .dropdown-menu, #expandHeader .pr.df span.usn .dropdown .dropdown-menu {
  z-index: 99;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  transition: all .3s ease-in-out;
  display: none;
  left: 0;
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

#expandHeader .pr.df a .dropdown .dropdown-menu .dropdown-item, #expandHeader .pr.df span.usn .dropdown .dropdown-menu .dropdown-item {
  width: 100%;
  font-size: 16px;
  display: block;
}

#expandHeader .pr.df a .dropdown button, #expandHeader .pr.df span.usn .dropdown button {
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding-left: 1rem;
  position: relative;
}

#expandHeader .pr.df a .dropdown button svg, #expandHeader .pr.df span.usn .dropdown button svg {
  top: 15px;
}

#expandHeader .pr.df a .dropdown button .icon, #expandHeader .pr.df span.usn .dropdown button .icon {
  transition: all .3s ease-in-out;
  position: absolute;
  top: -7px;
  right: -3px;
  transform: rotate(0);
}

#expandHeader .pr.df a .dropdown button .icon svg, #expandHeader .pr.df span.usn .dropdown button .icon svg {
  opacity: .5;
  width: 29px;
}

#expandHeader .pr.df a .dropdown-show, #expandHeader .pr.df span.usn .dropdown-show {
  position: relative;
}

#expandHeader .pr.df a .dropdown-show .icon, #expandHeader .pr.df span.usn .dropdown-show .icon {
  transition: all .3s ease-in-out;
  top: -3px;
  transform: rotate(90deg);
}

#expandHeader .pr.df a .dropdown-show .dropdown-menu, #expandHeader .pr.df span.usn .dropdown-show .dropdown-menu {
  z-index: 99;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  transition: all .3s ease-in-out;
  display: block;
  left: 0;
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

#expandHeader .pr.df a .dropdown-show .dropdown-menu .dropdown-item, #expandHeader .pr.df span.usn .dropdown-show .dropdown-menu .dropdown-item {
  width: 100%;
  padding-left: 0;
  font-size: 16px;
  display: block;
}

#expandHeader .pr.df a:hover, #expandHeader .pr.df span.usn:hover {
  background-color: #f5f6f9;
  border-radius: 10px;
}

.progress {
  position: relative;
}

.progress:before {
  content: attr(data-name);
  width: 100%;
  font-weight: 400;
  position: absolute;
  bottom: -2rem;
}

.progress:after {
  content: attr(data-percent);
  text-align: center;
  width: 100%;
  font-size: 2rem;
  position: absolute;
  top: 3.7rem;
  left: 0;
}

.progress svg {
  width: 10rem;
  height: 10rem;
}

.progress svg:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
}

.progress svg:nth-child(2) path {
  fill: none;
  stroke-width: 25px;
  stroke-dasharray: 629;
  stroke: #fc7785;
  animation: 10s load;
}

.progress-bar {
  margin: 0 auto;
  position: relative;
}

.progress-bar .text-center-area {
  text-align: center;
  font-size: 11px;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-input, .progress-area {
  position: relative;
}

.tooltip-input .tooltip-area, .progress-area .tooltip-area {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.tooltip-input .tooltip-area .icon, .progress-area .tooltip-area .icon {
  position: relative;
}

.tooltip-input .tooltip-area .icon img, .progress-area .tooltip-area .icon img {
  width: 24px;
  height: auto;
}

.tooltip-input .tooltip-area .icon .overlay-box, .progress-area .tooltip-area .icon .overlay-box {
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  width: 250px;
  padding: 16px;
  transition: all .3s ease-in-out;
  display: none;
  position: absolute;
  top: 50%;
  left: -300px;
  transform: translateY(-50%);
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.tooltip-input .tooltip-area .icon .overlay-box:before, .progress-area .tooltip-area .icon .overlay-box:before {
  content: "";
  border: 15px solid #0000;
  border-left: 15.9px solid #fff;
  border-right-width: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 46%;
  right: -14px;
  transform: translateY(-50%);
}

.tooltip-input .tooltip-area .icon .overlay-box p, .progress-area .tooltip-area .icon .overlay-box p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.tooltip-input .tooltip-area:hover .overlay-box, .progress-area .tooltip-area:hover .overlay-box {
  display: block;
}

.button-text {
  width: 83%;
  display: block;
  position: relative;
}

.button-text svg {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%);
}

.button-text p {
  border: none;
  margin: 0;
  padding: 0;
}

.tooltip-box {
  position: relative;
}

.tooltip-box .tooltip-area {
  z-index: 999;
  width: 248px;
  display: none;
  position: absolute;
  top: 50%;
  right: -262px;
  transform: translateY(-50%);
}

.tooltip-box .tooltip-area .overlay-box {
  text-align: center;
  background-color: #f0f0f8;
  border-radius: 8px;
  padding: 16px;
  transition: all .3s ease-in-out;
  position: relative;
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.tooltip-box .tooltip-area .overlay-box img {
  text-align: center;
  height: 350px;
  margin: 0 auto;
}

.tooltip-box .tooltip-area:before {
  content: "";
  border-top: 15px solid #0000;
  border-bottom: 15.9px solid #0000;
  border-left: 0 solid #0000;
  border-right: 15px solid #f0f0f8;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: -14px;
  transform: translateY(-50%);
}

.tooltip-box:hover .tooltip-area {
  display: block;
}

.tooltip-box-modal {
  position: relative;
}

.tooltip-box-modal .tooltip-area {
  cursor: pointer;
  margin-left: 12px;
  position: relative;
}

.tooltip-box-modal .tooltip-area .icon {
  transition: all .3s ease-in-out;
  display: inline-block;
  position: relative;
}

.tooltip-box-modal .tooltip-area .icon img {
  width: 21px;
  height: auto;
}

.tooltip-box-modal .tooltip-area .icon .overlay-box {
  z-index: 9;
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  min-width: 350px;
  padding: 16px;
  transition: all .3s ease-in-out;
  display: none;
  position: absolute;
  top: 50%;
  right: 34px;
  transform: translateY(-50%);
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.tooltip-box-modal .tooltip-area .icon .overlay-box:before {
  content: "";
  border: 15px solid #0000;
  border-left: 15.9px solid #fff;
  border-right-width: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 49%;
  right: -14px;
  transform: translateY(-50%);
}

.tooltip-box-modal .tooltip-area .icon .overlay-box p {
  margin-bottom: 5px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.tooltip-box-modal .tooltip-area .icon .overlay-box .bottom-text p {
  font-size: 11px;
}

.tooltip-box-modal .tooltip-area .icon .overlay-box .price {
  text-align: center;
  margin-top: 13px;
  font-size: 19px;
  font-style: normal;
}

.tooltip-box-modal .tooltip-area:hover .overlay-box {
  transition: all .3s ease-in-out;
  display: block;
}

.tooltip-box-modal .tooltip-area.style-two {
  z-index: 2;
  top: -29px;
  right: -25px;
}

.module-tooltip-box {
  position: static;
}

.module-tooltip-box .tooltip-area {
  z-index: 999;
  background-color: #fff;
  border-radius: 8px;
  width: 220px;
  padding: 8px;
  transition: all .3s ease-in-out;
  display: none;
  position: absolute;
  transform: translateY(-50%);
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
  top: 50% !important;
  left: 6% !important;
}

.module-tooltip-box .tooltip-area .overlay-box {
  color: #000;
  box-sizing: border-box;
  background-color: #f0f0f8;
  padding: 10px;
  font-size: 8px;
  transition: all .3s ease-in-out;
}

.module-tooltip-box .tooltip-area .dashboard-permission-overlaybox {
  padding: 4px 0;
}

.module-tooltip-box .tooltip-area-permissions {
  top: 90% !important;
  left: -75% !important;
}

.module-tooltip-box:hover .tooltip-area {
  display: block;
}

.module-tooltip-box svg {
  z-index: 1;
  background-color: #fff;
  width: 8%;
  padding: 5px;
  top: auto;
  bottom: -12px;
  left: -12px;
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.certificate-tooltip-box {
  position: relative;
}

.certificate-tooltip-box .certificate-tooltip-area {
  text-align: center;
  z-index: 9;
  background-color: #f0f0f8;
  border-radius: 8px;
  width: 195px;
  height: 7vh;
  padding: 20px;
  transition: all .3s ease-in-out;
  display: none;
  position: absolute;
  top: -100px;
  left: 70px;
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.certificate-tooltip-box:hover .certificate-tooltip-area {
  display: block;
}

.progress-box {
  text-align: center;
}

.progress-box .text-center-area {
  text-align: center !important;
}

.expansionpack-page {
  position: relative;
}

.expansionpack-page .container {
  flex-wrap: wrap;
}

.expansionpack-page .container .lef-img-area {
  width: 25%;
}

.expansionpack-page .container .lef-img-area .img-box {
  position: relative;
}

.expansionpack-page .container .lef-img-area .img-box img {
  width: 100%;
  height: auto;
}

.expansionpack-page .container .right-text-area {
  width: 43%;
  margin-left: -126px;
  position: relative;
}

.expansionpack-page .container .right-text-area:before {
  content: "";
  background-image: url("text-bg.081158e9.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 551px;
  height: 348px;
  position: absolute;
  top: -54px;
  left: -287px;
}

.expansionpack-page .container .right-text-area p {
  text-align: center;
  position: relative;
}

.expansionpack-page .container .right-text-area p:before {
  content: "";
  background-image: url("left-angle.fa97e5ec.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 57px;
  height: 57px;
  position: absolute;
  bottom: -26px;
  left: -27px;
}

.expansionpack-page .container .right-text-area p:after {
  content: "";
  background-image: url("right-angle.43344453.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 57px;
  height: 57px;
  position: absolute;
  top: -46px;
  right: -27px;
}

.expansionpack-page .container .right-text-area .number-sec {
  margin-top: 64px;
  position: relative;
}

.expansionpack-page .container .right-text-area .number-sec:before {
  content: "";
  background-color: #002d63;
  border-radius: 25px;
  width: 105%;
  height: 77%;
  position: absolute;
  bottom: 26px;
  left: -19px;
}

.expansionpack-page .container .right-text-area .number-sec ul {
  padding: 15px;
  list-style: none;
  position: relative;
  margin-left: 0 !important;
}

.expansionpack-page .container .right-text-area .number-sec ul li {
  padding-bottom: 13px;
  font-weight: 600;
  position: relative;
}

.expansionpack-page .container .right-text-area .number-sec ul li .icon {
  margin-right: 15px;
}

.expansionpack-page .container .right-text-area .number-sec ul li .icon img {
  width: 11px;
  height: auto;
  position: relative;
  top: 3px;
}

.expansionpack-page .container .right-text-area .number-sec ul li span {
  color: #4a67ff;
  margin-right: 1px;
}

.expansionpack-page .container .right-text-area .about-img {
  flex-wrap: wrap;
  margin-top: 43px;
  display: flex;
  position: relative;
}

.expansionpack-page .container .right-text-area .about-img .img-box {
  width: 28%;
  position: relative;
}

.expansionpack-page .container .right-text-area .about-img .img-box img {
  width: 172px;
}

.expansionpack-page .container .right-text-area .about-img .text-box {
  width: 66%;
  height: 138px;
  margin-top: 6px;
  margin-left: -2px;
  padding: 11px 12px;
}

.expansionpack-page .container .right-text-area .about-img .text-box p:before, .expansionpack-page .container .right-text-area .about-img .text-box p:after {
  display: none;
}

.p0-5rem {
  padding: .5rem;
}

.h75vh {
  height: 75vh;
}

.mb0-7rem {
  margin-bottom: .7rem !important;
}

.mb1-7rem {
  margin-bottom: 1.7rem !important;
}

.minh100vh button {
  font-size: 14px;
}

#e1jygW4piZH1 {
  z-index: 1500;
}

#fileUploadRepository text, #fileUploadFilingAssistant text, #repositoryToDataRoom text, #syncRepositoryDataRoom text {
  font-size: 50px !important;
}

#cloud {
  min-height: 600px;
}

.tooltip-btn {
  background-color: #22369b;
  border-radius: 43px 6px 6px 3px;
  padding: 6px 22px;
  position: absolute;
  top: -51px;
  right: 0;
}

.tooltip-btn:before {
  content: "";
  border: 11px solid #0000;
  border-top-color: #22369b;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -10px;
  right: 6px;
}

.benfits-sec {
  background-image: url("benifts-bg.98e7e4c6.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.modal-sec {
  background-image: url("modal-bg.5623364b.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.modal-sec .heading {
  text-align: center;
  margin-top: -68px;
  margin-bottom: 28px;
}

.new-report {
  background-image: url("psd-bg.ea37248c.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 2rem 3rem;
}

.new-report.report-ai {
  background-image: url("bg-ai.dca51315.jpg");
}

.new-report .logo-report {
  border-bottom: 3px solid #002d63;
  position: relative;
}

.new-report .right-list {
  text-align: right;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.new-report .right-list li {
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 700;
}

.new-report .right-list li span {
  width: 250px;
  font-weight: 400;
  display: inline-block;
}

.workflow-report {
  padding: 2rem;
}

.new-report-btn {
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 4px;
  padding: 0;
  display: flex;
}

.new-report-btn:hover {
  box-shadow: 0 0 8px #0000001a;
}

.new-report-btn span {
  color: #002d63;
  margin-left: 25px;
  padding: 2px 11px;
  font-weight: 600;
  position: relative;
}

.new-report-btn .left-heading {
  text-align: center;
  border-right: 3px solid #fff;
  width: 150px;
  margin-left: 0;
  padding: 2px 11px;
  display: inline-block;
}

.new-report-btn .left-heading:before {
  content: "";
  background-color: #002d63;
  border-radius: 0 10px 10px 0;
  width: 6px;
  height: 74%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.progress-area-report {
  position: relative;
}

.progress-area-report .all-report-progress-box {
  margin: 0 27px 22px 33px;
}

.progress-area-report .progress-box {
  margin: 0 27px 32px 33px;
  position: relative;
}

.progress-area-report .progress-box:before {
  content: "";
  background-image: url("triangle-progress.98d375be.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 27px;
  height: 27px;
  position: absolute;
  bottom: -10px;
  left: -31px;
}

.progress-area-report .progress-box:after {
  content: "";
  background-image: url("triangle-progress.98d375be.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 27px;
  height: 27px;
  position: absolute;
  top: -10px;
  right: -31px;
  transform: rotate(180deg);
}

.progress-area-report .progress-box .text-center-area {
  bottom: 42px;
}

.progress-area-report .progress-box-hidden {
  width: 25%;
}

.tables-area {
  background-color: #f5f6ff;
  padding: 15px;
  position: relative;
  box-shadow: 0 0 10px #0003;
}

.tables-area .new-report-btn .left-heading {
  border-right: 3px solid #f5f6ff;
}

.register-btn-area {
  text-align: center;
  margin: 85px 0 28px;
  position: relative;
}

.register-btn-area p {
  margin-bottom: 36px;
}

.register-btn-area .register-btn {
  padding: 11px 75px;
  position: relative;
}

.kpis-text {
  text-align: center;
  margin: 74px 0 250px;
  position: relative;
}

.kpis-text h3 {
  color: #002d6350;
  font-size: 75px;
}

.mb1-3rem {
  margin-bottom: 1.2rem;
}

.certificates-page, .company-audit-page, .checklist-page {
  background-image: url("dashboard-certificates-bg.85130630.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.certificates-page .requirement-area, .company-audit-page .requirement-area, .checklist-page .requirement-area {
  text-align: center;
  margin-top: 15px;
  margin-left: 362px;
  font-size: 14px;
  font-weight: 600;
}

.certificates-page .left-upgrade-button, .company-audit-page .left-upgrade-button, .checklist-page .left-upgrade-button {
  text-align: center;
  width: 100%;
  padding: 35px 0 20px;
  transition: all .3s ease-in-out;
  position: relative;
}

.certificates-page .left-upgrade-button .badge-btn, .company-audit-page .left-upgrade-button .badge-btn, .checklist-page .left-upgrade-button .badge-btn {
  color: #fff;
  background-color: #0000;
  background-image: url("upgrage-button.6f0422cf.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
  padding: 16px 75px 17px 29px;
  font-size: 14px;
  transition: all .3s ease-in-out;
  transform: scale(1);
}

.certificates-page .left-upgrade-button .badge-btn:hover, .company-audit-page .left-upgrade-button .badge-btn:hover, .checklist-page .left-upgrade-button .badge-btn:hover {
  transform: scale(1.1);
}

.certificates-page.disabled-certificates-page, .company-audit-page.disabled-certificates-page, .checklist-page.disabled-certificates-page {
  width: 100%;
}

.certificates-page.disabled-certificates-page .badge-btn, .company-audit-page.disabled-certificates-page .badge-btn, .checklist-page.disabled-certificates-page .badge-btn, .certificates-page.disabled-certificates-page .left-upgrade-button a, .company-audit-page.disabled-certificates-page .left-upgrade-button a, .checklist-page.disabled-certificates-page .left-upgrade-button a, .certificates-page.disabled-certificates-page .upgrade-btns, .certificates-page.disabled-certificates-page butoon, .company-audit-page.disabled-certificates-page .upgrade-btns, .company-audit-page.disabled-certificates-page butoon, .checklist-page.disabled-certificates-page .upgrade-btns, .checklist-page.disabled-certificates-page butoon, .certificates-page.disabled-certificates-page .certification-pricing-column a, .company-audit-page.disabled-certificates-page .certification-pricing-column a, .checklist-page.disabled-certificates-page .certification-pricing-column a {
  pointer-events: none;
}

.certificates-page .heading-area, .company-audit-page .heading-area, .checklist-page .heading-area {
  text-align: center;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 574px 63px;
  position: relative;
}

.certificates-page .heading-area.bronze, .company-audit-page .heading-area.bronze, .checklist-page .heading-area.bronze {
  background-image: url("bronze-level.2424dece.png");
}

.certificates-page .heading-area.silver, .company-audit-page .heading-area.silver, .checklist-page .heading-area.silver {
  background-image: url("sliver-level.2b7e9a18.png");
}

.certificates-page .heading-area.gold, .company-audit-page .heading-area.gold, .checklist-page .heading-area.gold {
  background-image: url("gold-level.04c2cea3.png");
}

.certificates-page .badges-sec, .company-audit-page .badges-sec, .checklist-page .badges-sec {
  padding: 10px 0;
  position: relative;
}

.certificates-page .badges-sec .badges-area, .company-audit-page .badges-sec .badges-area, .checklist-page .badges-sec .badges-area {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.certificates-page .certificates-current .top-heading, .company-audit-page .certificates-current .top-heading, .checklist-page .certificates-current .top-heading {
  padding: 0 10px 35px;
}

.certificates-page .certificates-current .packge-select, .company-audit-page .certificates-current .packge-select, .checklist-page .certificates-current .packge-select {
  zoom: .9;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.certificates-page .certificates-current .packge-select .current .w250px, .company-audit-page .certificates-current .packge-select .current .w250px, .checklist-page .certificates-current .packge-select .current .w250px {
  border-radius: 97px;
  box-shadow: 0 0 26px #41dc9f60;
}

.certificates-page .progress-sec, .company-audit-page .progress-sec, .checklist-page .progress-sec {
  position: relation;
}

.certificates-page .progress-sec .progress-list, .company-audit-page .progress-sec .progress-list, .checklist-page .progress-sec .progress-list {
  background-image: url("certificat-bg-list.4ee850f3.png");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: space-between;
  margin: 15px 0;
  padding: 9px 8px 4px;
  list-style: none;
  display: flex;
  position: relative;
}

.certificates-page .progress-sec .progress-list li, .company-audit-page .progress-sec .progress-list li, .checklist-page .progress-sec .progress-list li {
  opacity: .5;
}

.certificates-page .progress-sec .progress-list li .certificate-btn, .company-audit-page .progress-sec .progress-list li .certificate-btn, .checklist-page .progress-sec .progress-list li .certificate-btn {
  background-color: #0000;
  border: none;
}

.certificates-page .progress-sec .progress-list li .certificate-btn img, .company-audit-page .progress-sec .progress-list li .certificate-btn img, .checklist-page .progress-sec .progress-list li .certificate-btn img {
  width: 100%;
}

.certificates-page .progress-sec .progress-list li.current, .company-audit-page .progress-sec .progress-list li.current, .checklist-page .progress-sec .progress-list li.current {
  opacity: 1;
}

.certificates-page .certificate-progress-bar .progress, .company-audit-page .certificate-progress-bar .progress, .checklist-page .certificate-progress-bar .progress {
  border-radius: 15px;
  width: 100%;
  height: 18px;
}

.certificates-page .certificate-progress-bar .progress .progress-bar, .company-audit-page .certificate-progress-bar .progress .progress-bar, .checklist-page .certificate-progress-bar .progress .progress-bar {
  border-radius: 15px;
  background: #16b216 !important;
}

.certificates-page .data-check-box, .company-audit-page .data-check-box, .checklist-page .data-check-box {
  border-radius: 21px;
  margin: 20px 0 0;
  padding: 20px;
  position: relative;
  box-shadow: 0 0 10px #0000004d;
}

.certificates-page .data-check-box.data-check-box-empty, .company-audit-page .data-check-box.data-check-box-empty, .checklist-page .data-check-box.data-check-box-empty {
  box-shadow: 0 0 #0000004d;
}

.certificates-page .data-check-box .packge-select, .company-audit-page .data-check-box .packge-select, .checklist-page .data-check-box .packge-select {
  text-align: center;
  position: absolute;
  bottom: 31px;
}

.certificates-page .data-check-box .packge-select img, .company-audit-page .data-check-box .packge-select img, .checklist-page .data-check-box .packge-select img {
  margin: 0 auto;
}

.certificates-page .data-check-box .stuts-btn, .company-audit-page .data-check-box .stuts-btn, .checklist-page .data-check-box .stuts-btn {
  background-color: #0000;
  border: none;
  position: absolute;
  bottom: 11px;
  right: 11px;
}

.certificates-page .data-check-box .stuts-btn img, .company-audit-page .data-check-box .stuts-btn img, .checklist-page .data-check-box .stuts-btn img {
  width: 109px;
}

.certificates-page .data-check-box .data-list, .company-audit-page .data-check-box .data-list, .checklist-page .data-check-box .data-list {
  margin: 0 0 30px;
  padding: 0;
  list-style: none;
  position: relative;
}

.certificates-page .data-check-box .data-list:before, .company-audit-page .data-check-box .data-list:before, .checklist-page .data-check-box .data-list:before {
  content: "";
  background-color: #e7e7e7;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 22px;
}

.certificates-page .data-check-box .data-list li, .company-audit-page .data-check-box .data-list li, .checklist-page .data-check-box .data-list li {
  opacity: .5;
  padding-bottom: 15px;
  padding-left: 44px;
  position: relative;
}

.certificates-page .data-check-box .data-list li:before, .company-audit-page .data-check-box .data-list li:before, .checklist-page .data-check-box .data-list li:before {
  content: "";
  background-color: #e7e7e7;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 9px;
  left: 19px;
}

.certificates-page .data-check-box .data-list li h4, .company-audit-page .data-check-box .data-list li h4, .checklist-page .data-check-box .data-list li h4 {
  font-size: 15px;
  font-weight: 500;
}

.certificates-page .data-check-box .data-list li p, .company-audit-page .data-check-box .data-list li p, .checklist-page .data-check-box .data-list li p {
  font-size: 12px;
}

.certificates-page .data-check-box .data-list li .icon, .company-audit-page .data-check-box .data-list li .icon, .checklist-page .data-check-box .data-list li .icon {
  display: none;
  position: absolute;
  top: 4px;
  left: -7px;
}

.certificates-page .data-check-box .data-list li .icon.round, .company-audit-page .data-check-box .data-list li .icon.round, .checklist-page .data-check-box .data-list li .icon.round {
  background-color: #0000004d;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: block;
}

.certificates-page .data-check-box .data-list li .icon img, .company-audit-page .data-check-box .data-list li .icon img, .checklist-page .data-check-box .data-list li .icon img {
  width: 17px;
}

.certificates-page .data-check-box .data-list li.check, .company-audit-page .data-check-box .data-list li.check, .checklist-page .data-check-box .data-list li.check {
  opacity: 1;
}

.certificates-page .data-check-box .data-list li.check .icon.round, .company-audit-page .data-check-box .data-list li.check .icon.round, .checklist-page .data-check-box .data-list li.check .icon.round {
  display: none;
}

.certificates-page .data-check-box .data-list li.check .icon.check, .company-audit-page .data-check-box .data-list li.check .icon.check, .checklist-page .data-check-box .data-list li.check .icon.check {
  display: block;
}

.certificates-page .btn-back, .company-audit-page .btn-back, .checklist-page .btn-back {
  text-align: center;
  position: relative;
}

.certificates-page .btn-back .upgrade-btns, .company-audit-page .btn-back .upgrade-btns, .checklist-page .btn-back .upgrade-btns {
  position: relative;
}

.certificates-page .btn-back .upgrade-btns:before, .company-audit-page .btn-back .upgrade-btns:before, .checklist-page .btn-back .upgrade-btns:before {
  content: "";
  border-top: 18px solid #0000;
  border-bottom: 18px solid #0000;
  border-left: 21px solid #4a67fe;
  transition-duration: .25s;
  position: absolute;
  top: 2px;
  right: -20px;
}

.certificates-page .btn-back .upgrade-btns:hover:before, .company-audit-page .btn-back .upgrade-btns:hover:before, .checklist-page .btn-back .upgrade-btns:hover:before {
  border-left: 21px solid #fc7785;
}

.footer-sec {
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 0;
  position: relative;
}

.footer-sec .check-box {
  border-radius: 21px;
  padding: 40px 0 141px;
  position: relative;
  box-shadow: 0 0 10px #0000004d;
}

.footer-sec .check-box .certification-pricing-column {
  zoom: .7;
  width: 315px;
  height: 0;
  position: absolute;
  top: 40px;
  right: 650px;
}

.footer-sec .check-box .certification-pricing-column span.h100px {
  width: 50px;
  height: 50px;
}

.footer-sec h2 {
  text-align: center;
  width: 100%;
}

.footer-sec h2 img {
  width: 550px;
}

.footer-sec .certificates-current {
  width: 68%;
  margin: 30px auto;
  position: relative;
}

.footer-sec .certificates-current .audit-pakcges {
  zoom: .6;
  opacity: 1 !important;
  box-shadow: none !important;
}

.footer-sec .upgrade-btn {
  text-align: center;
  zoom: .5;
  background-color: #0000;
  border: none;
  width: 100%;
  transition: all .3s ease-in-out;
  position: relative;
  transform: scale(1);
}

.footer-sec .upgrade-btn:hover {
  transform: scale(1.1);
}

.question-box {
  color: #002d63;
  border: 2px solid #281ac9;
  border-radius: 8px;
  margin-top: 10px;
  padding: 15px;
  position: relative;
}

.question-box h3 {
  padding-bottom: 13px;
  padding-left: 25px;
  position: relative;
}

.question-box h3:before {
  content: "";
  background-color: #fc7785;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 11px;
  left: 0;
}

.recomended-box {
  margin-bottom: 20px;
  padding: 5px 15px;
}

.recomended-box:before {
  content: "";
  background-color: #9f9f9f;
  width: 30%;
  height: 4px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

#sidebar button .close-svg {
  display: none;
}

#sidebar button.close-report .close-svg {
  display: inline-block;
}

.investmentcertificate-page {
  background-color: #fff;
  background-image: url("investmen-certificate-bg.767bb611.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}

.investmentcertificate-page .header-area {
  text-align: center;
  background-image: url("investmen-certificate-header-bg.b1f4bf2d.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 407px;
  padding-top: 10px;
  position: relative;
}

.investmentcertificate-page .certificate-area {
  margin-top: -196px;
  position: relative;
}

.investmentcertificate-page .certificate-area .left-area {
  box-sizing: border-box;
  width: 100%;
  padding: 0 27px;
  display: block;
  position: relative;
}

.investmentcertificate-page .certificate-area .left-area h5 {
  font-style: italic;
}

.investmentcertificate-page .certificate-area .left-area .status-area {
  margin: 20px 0;
  padding-left: 33px;
  font-size: 18px;
  font-weight: 500;
  display: block;
  position: relative;
}

.investmentcertificate-page .certificate-area .left-area .status-area:before {
  content: "";
  background-color: #002d63;
  width: 24px;
  height: 6px;
  position: absolute;
  top: 12px;
  left: 0;
}

.investmentcertificate-page .certificate-area .left-area .status-area .PassFail {
  color: #999;
  text-transform: uppercase;
  padding: 2px 10px;
  font-style: normal;
  display: inline-block;
  position: relative;
}

.investmentcertificate-page .certificate-area .left-area .status-area .PassFail.Pass {
  background-color: #40dda1;
}

.investmentcertificate-page .certificate-area .left-area .status-area .PassFail.Fail {
  background-color: #dd4040;
}

.investmentcertificate-page .certificate-area .left-area .status-area .PassFail.Uncertified {
  background-color: #e5e3e3;
}

.investmentcertificate-page .certificate-area .left-area .signure-area {
  position: relative;
}

.investmentcertificate-page .certificate-area .left-area .signure-area .date-area {
  text-align: center;
  display: inline-block;
}

.investmentcertificate-page .certificate-area .left-area .signure-area .date-area .logo-certified {
  width: 180px;
}

.investmentcertificate-page .certificate-area .left-area .signure-area .date-area.singature-area {
  margin-top: -45px;
}

.investmentcertificate-page .certificate-area .left-area .signure-area .date-area p, .investmentcertificate-page .certificate-area .left-area .signure-area .date-area figure {
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: block;
  position: relative;
}

.investmentcertificate-page .certificate-area .left-area .signure-area .date-area p:before, .investmentcertificate-page .certificate-area .left-area .signure-area .date-area figure:before {
  content: "";
  background-color: #416ffd;
  width: 200px;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.investmentcertificate-page .certificate-area .right-area {
  width: 81%;
  margin: 0 auto;
  position: relative;
}

.investmentcertificate-page .certificate-area .right-area .op70 {
  opacity: 1;
}

.investmentcertificate-page .certificate-area .right-area .op70 img {
  width: 150px;
}

.investmentcertificate-page .certificate-area .right-area .op70 img.w7rem {
  width: 127px;
}

.investmentcertificate-page .certificate-area .right-area .op70 .fs1-5rem {
  font-size: .7rem;
}

.investmentcertificate-page .certificate-area .right-area .w7rem {
  width: 13rem;
}

.investmentcertificate-page .logo-area {
  text-align: center;
}

.step-box {
  text-align: center;
  color: #a5a4a7;
  background-color: #0000;
  border: 1px solid #a5a4a780;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  padding: 4px 16px 2px;
  font-size: 9px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.button-left-area {
  align-items: center;
  display: flex;
}

.contact-btn {
  text-align: center;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 21px;
  display: block;
  position: relative;
}

.contact-btn .contact-us-btn {
  border-bottom: 1px solid #fc7785;
}

.questionnaire-sidebar, .esg-sidebar {
  height: 100vh;
  display: block;
}

.questionnaire-sidebar .mah100, .esg-sidebar .mah100 {
  margin: 15px 0;
}

.questionnaire-sidebar .mta, .esg-sidebar .mta {
  width: 93%;
  position: absolute;
  bottom: 0;
}

.verify-btn {
  border-radius: 0 5px 5px 0;
  position: absolute;
  right: 0;
}

.report-page .sidebar-report {
  height: 100vh;
  display: block;
}

.report-page .sidebar-report .mah100.mya.oya.df.fdc.jcsb {
  max-height: 55vh;
  margin: 5px 0 0;
}

.report-page .sidebar-report .mta {
  width: 94%;
  position: absolute;
  bottom: 0;
}

.report-page .sidebar-report .siedbar-dropdwon {
  max-height: 71vh;
}

.report-page .sidebar-report .siedbar-dropdwon .oya.df.fdc.jcsb {
  border-left: 2px solid #fff;
  max-height: 100% !important;
}

.report-page .sidebar-report .siedbar-dropdwon .MuiPaper-root {
  background-color: #0000 !important;
}

.report-page .sidebar-report.repository-sidebar .mah100.mya.oya.df.fdc.jcsb {
  max-height: 53vh;
}

.showDirectors .inner-showDirectors {
  background-image: url("popup-modal-bg.ea997a30.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
  width: 900px;
  padding: 60px;
  position: relative;
}

.showDirectors .inner-showDirectors .top-header {
  width: 100%;
  margin-bottom: 40px;
  position: relative;
}

.showDirectors .inner-showDirectors .top-header button {
  margin: 0;
  padding: 7px 20px;
  font-size: 14px;
  position: absolute;
  top: 41px;
  right: -20px;
}

.showDirectors .inner-showDirectors .table-area-showDirectors {
  border-radius: 0 0 10px 10px;
  max-width: 100%;
  min-height: auto;
  max-height: 60vh;
  position: relative;
  overflow: scroll;
  box-shadow: 0 0 5px #0003;
}

.showDirectors .inner-showDirectors .table-area-showDirectors .table-showDirectors {
  width: 100%;
  position: relative;
}

.showDirectors .inner-showDirectors .table-area-showDirectors .table-showDirectors thead, .showDirectors .inner-showDirectors .table-area-showDirectors .table-showDirectors thead tr {
  position: relative;
}

.showDirectors .inner-showDirectors .table-area-showDirectors .table-showDirectors thead tr th {
  text-align: left;
  color: #002d63;
  background-color: #0000;
  border-bottom: 5px solid #e3e8ee;
  padding: 9px 23px;
  font-size: 15px;
  font-weight: 400;
  position: relative;
  box-shadow: 0 0 5px #0003;
  width: auto !important;
  min-width: auto !important;
}

.showDirectors .inner-showDirectors .table-area-showDirectors .table-showDirectors thead tr th:before {
  content: "";
  background-color: #fff;
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 1px;
  box-shadow: 0 0 5px #0003;
}

.showDirectors .inner-showDirectors .table-area-showDirectors .table-showDirectors tbody {
  position: relative;
}

.showDirectors .inner-showDirectors .table-area-showDirectors .table-showDirectors tbody tr {
  position: relative;
  background: none !important;
}

.showDirectors .inner-showDirectors .table-area-showDirectors .table-showDirectors tbody tr td {
  text-align: left;
  border-bottom: 1px solid #e3e8ee;
  padding: 9px 23px;
  font-size: 12px;
  position: relative;
  background-color: #0000 !important;
  width: auto !important;
  min-width: auto !important;
}

.showDirectors .inner-showDirectors .table-area-showDirectors .table-showDirectors tbody tr td:before {
  content: "";
  background-color: #fff;
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 1px;
  box-shadow: 0 0 5px #0003;
}

.showDirectors .inner-showDirectors .table-area-showDirectors .table-showDirectors tbody tr td button {
  padding: 4px 20px;
}

.showDirectors .inner-showDirectors .table-area-showDirectors .logo-modal {
  text-align: center;
  width: 100%;
  padding: 10px 0;
  position: relative;
}

.close-popup {
  box-shadow: none;
  cursor: pointer;
  background-color: #0000;
  position: absolute;
  top: 15px;
  right: 15px;
}

.close-popup svg {
  fill: #4a67ff;
}

.close-popup.style-two svg {
  fill: #fc7785;
}

.sectionWrapper .group-inputs {
  margin-bottom: 5px;
  position: relative;
}

.sectionWrapper .group-inputs input, .sectionWrapper .group-inputs textarea {
  color: #002d63;
  background-color: #f0f0f800;
  border: none;
  border-radius: 5px;
  width: 100%;
  padding: 0;
  font-size: .75rem;
  position: relative;
  top: 0;
  left: 8px;
}

.sectionWrapper .group-inputs .show-input {
  opacity: 1;
  background-color: #f0f0f8;
  position: relative;
  top: 0;
  left: 0;
}

.sectionWrapper .group-inputs textarea {
  height: auto;
}

.sectionWrapper .group-inputs p {
  z-index: 1;
  width: 100%;
  transition: all .3s ease-in-out;
  display: inline-block;
  position: relative;
}

.sectionWrapper .group-inputs p:hover .edit-btn {
  visibility: visible;
  opacity: 1;
}

.sectionWrapper .group-inputs .edit-btn {
  transition: all .3s ease-in-out;
  display: inline-block;
}

.sectionWrapper .group-inputs .edit-btn .edit-pencil {
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin-left: 11px;
}

.sectionWrapper .group-inputs .edit-btn .edit-pencil svg {
  fill: #fc7785;
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
}

.sectionWrapper .group-inputs .check-btn {
  opacity: 1;
  visibility: visible;
  transition: all .3s ease-in-out;
  display: inline-block;
}

.sectionWrapper .group-inputs .check-btn .ok-btn {
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin-left: 11px;
}

.sectionWrapper .group-inputs .check-btn .ok-btn img {
  width: 15px;
}

.sectionWrapper .group-inputs.link-group p {
  width: auto;
  display: inline-block;
}

.sectionWrapper .group-inputs.link-group p a {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
  display: inline-block;
  position: relative;
  top: 5px;
  overflow: hidden !important;
}

.sectionWrapper .group-inputs .edit-text {
  display: flex;
}

.sectionWrapper .group-inputs .edit-text:hover .edit-btn {
  opacity: 1;
  visibility: visible;
}

.sectionWrapper .company-name {
  margin-bottom: 1rem;
  display: inline-block;
  position: relative;
}

.sectionWrapper .company-name:before {
  content: "";
  background-color: #002d63;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -8px;
  left: 0;
}

.edit-button-top {
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.edit-button-top svg {
  fill: #fc7785;
  width: 21px;
  position: relative;
  top: 7px;
}

.company-settings {
  position: absolute;
  top: -22px;
  right: 33px;
}

.company-settings svg {
  width: 49px;
}

.avtar-area img {
  width: 39px;
  height: auto;
  margin-bottom: 10px;
}

.slider-items {
  position: relative;
  overflow: hidden;
}

.slider-container {
  scroll-behavior: smooth;
  padding: 0 10px;
  display: flex;
  overflow-x: hidden;
}

.slider-container .item {
  min-width: 19.2%;
  max-width: 19.2%;
  padding-bottom: 58px;
  font-size: 14px;
  position: relative;
}

.slider-container .item .readmore-btn {
  padding: 7px 15px;
  font-size: 14px;
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
}

.btn-area-slider button .icon img {
  filter: brightness(100);
  width: 7px;
  height: auto;
  position: relative;
  top: 2px;
}

.btn-area-slider button.pre-btn .icon {
  transform: rotate(180deg);
}

.directors-info-box button .icon img {
  filter: brightness(100);
  width: 18px;
  height: auto;
  margin-right: 11px;
  position: relative;
  top: 1px;
}

.multi-dropdown .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, ul.MuiList-root li.multi-dropdown-list .MuiCheckbox-root.Mui-checked, ul.MuiList-root li.multi-dropdown-list .MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #fc7785;
}

.multi-dropdown .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline, .multi-dropdown fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #f0f0f8 !important;
}

ul.MuiList-root li.multi-dropdown-list.Mui-selected {
  background-color: #fc778510;
}

#sidebar-company-settings button {
  transition: all .3s ease-in-out;
}

#sidebar-company-settings button.active, #sidebar-company-settings button:hover {
  background-color: #002d63;
  color: #fff !important;
}

#sidebar-company-settings .delete-active {
  color: #f50100;
}

#sidebar-company-settings .delete-active:hover {
  color: #fff !important;
  background-color: #f50100 !important;
}

.company-snapshot-btn {
  background-color: #0000;
  border-bottom: 1px solid #4a67ff00;
}

.company-snapshot-btn.active, .company-snapshot-btn:hover {
  color: #4a67ff;
  border-bottom: 1px solid #4a67ff;
}

.company-snapshot-btn.active span, .company-snapshot-btn:hover span {
  color: #4a67ff;
}

.user-tabs .user-btn {
  position: relative;
}

.user-tabs .user-btn.active button, .user-tabs .user-btn:hover button {
  background-color: #002d63;
}

.table-of-content {
  margin: 0 auto;
}

.table-of-content .list-table {
  padding: 0;
}

.table-of-content .list-table li {
  margin-bottom: 10px;
  position: relative;
}

.table-of-content .list-table li:before {
  content: "";
  border-bottom: 2px dashed;
  height: 5px;
  position: absolute;
  top: 50%;
  right: 57px;
  transform: translateY(-50%);
}

.table-of-content .list-table li:first-child:before, .table-of-content .list-table li:nth-child(2):before, .table-of-content .list-table li:nth-child(3):before {
  width: 76%;
}

.table-of-content .list-table li:nth-child(4):before {
  width: 62%;
}

.table-of-content .list-table li:nth-child(5):before {
  width: 76%;
}

.table-of-content .list-table li i {
  font-style: normal;
}

.table-of-content.style-two .list-table li:first-child:before, .table-of-content.style-two .list-table li:nth-child(2):before, .table-of-content.style-two .list-table li:nth-child(3):before {
  width: 72%;
}

.policy-page-data p {
  margin-bottom: 20px;
}

.type-customer .checkbox.active input:checked, .type-customer .checkbox input:checked {
  background-color: #bbb;
  border-color: #bbb;
}

.poupup-img {
  background-color: #f0f0f8;
  border: 2px dashed;
  border-radius: 18px;
  width: 120px;
  height: 120px;
  padding: 10px;
}

.tooltip-area-company-settings {
  top: -11px;
  right: -11px;
}

.tooltip-area-company-settings .icon img {
  width: 18px;
}

.button-groups-new .company-settings-btn {
  background-color: #0000;
  width: 2.6rem;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: -16px;
  left: 6px;
}

.button-groups-new .company-settings-btn svg {
  box-shadow: none;
  background-color: #0000;
  height: 3rem;
  bottom: auto;
  left: auto;
  width: 3rem !important;
}

.certificate-area-dashboard .fs0-75rem {
  font-size: .6rem;
}

.w20rem {
  width: 20rem;
}

.valid-date {
  bottom: 6px;
}

.valid-date p {
  font-size: 7px;
}

.dashbaord-certficate-page .w70px {
  width: 85px;
}

.certificate-area-dashboard .fs0-5rem {
  font-size: .37rem;
}

.dropdown-add-group {
  height: 80px;
  position: relative;
}

.dropdown-add-group .dropdown-content {
  z-index: 1;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 2px solid #4a67ff;
  width: 100%;
  max-height: 255px;
  padding: 4px 8px;
  position: absolute;
  top: 58px;
  overflow: hidden scroll;
}

.dropdown-add-group .dropdown-content .item {
  border-bottom: 1px solid #00000020;
  border-radius: 5px;
  width: 100%;
  margin: 3px 0 10px;
  padding: 10px 15px;
  transition: all .3s ease-in-out;
}

.dropdown-add-group .dropdown-content .item:hover {
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.dropdown-add-group .dropdown-content .item h4 {
  font-size: 10px;
}

.dropdown-add-group .dropdown-content .item .progress-box-horizantal {
  width: 110px;
}

.dropdown-add-group .dropdown-content .item .progress-box-horizantal label {
  display: block;
}

.dropdown-add-group .dropdown-content .item .progress-box-horizantal .progress {
  height: 1rem;
  box-shadow: #000;
  color: #fff;
  background-color: #d6d6d6;
  border-radius: 25px;
  height: 13px;
  font-size: .55rem;
  transition: width .6s;
  display: flex;
  overflow: hidden;
}

.dropdown-add-group .dropdown-content .item .progress-box-horizantal .progress .progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  margin: 0;
  display: flex;
  overflow: hidden;
}

.dropdown-add-group .dropdown-content .item .progress-box-horizantal .progress.red .progress-bar {
  background-color: #ff3d3d;
}

.dropdown-add-group .dropdown-content .item .progress-box-horizantal .progress.yellow .progress-bar {
  background-color: #ff0;
}

.dropdown-add-group .dropdown-content .item .progress-box-horizantal .progress.orange .progress-bar {
  background-color: #fcb22b;
}

.dropdown-add-group .dropdown-content .item .progress-box-horizantal .progress.green .progress-bar {
  background-color: #5ee15e;
}

.dropdown-add-group.dropdown {
  position: relative;
}

.dropdown-add-group.dropdown #dropdown-basic {
  text-align: left;
  text-transform: capitalize;
  width: 100%;
}

.dropdown-add-group.dropdown .dropdown-content.dropdown-menu {
  display: none;
}

.dropdown-add-group.dropdown .dropdown-content.dropdown-menu .dropdown-item {
  width: 96%;
  display: block;
}

.dropdown-add-group.dropdown.show .dropdown-content.dropdown-menu {
  z-index: 3;
  display: block;
}

.mt0-3rem {
  margin-top: .3rem;
}

.back-btn {
  top: 28px;
  left: -500px;
}

.back-btn .icon {
  margin-right: .3rem;
  top: 3px;
}

.back-btn .icon svg {
  fill: #4a67ff;
  width: 15px;
  transition: all .3s ease-in-out;
}

.back-btn:hover .icon svg {
  fill: #fc7785;
}

.searchWrapper {
  align-items: center;
  width: 300px;
  display: flex;
  position: relative;
  border: none !important;
}

.advisor-select {
  position: relative;
}

.advisor-select:before {
  contact: "";
  background-image: url("arrow.7cb05709.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.company-detail-box .company-detail .percentage-area .input-area {
  width: 100%;
  display: inline-block;
}

.company-detail-box .company-detail .percentage-area .input-area input {
  -webkit-appearance: none;
  width: 100%;
  font-size: 10px;
}

.company-detail-box .company-detail .percentage-area .input-area input:before {
  content: "€";
  position: absolute;
  top: 0;
}

.orange {
  color: #fcb22b;
}

.fs0-50rem {
  font-size: .5rem;
}

.bottom-company-area {
  max-height: 100vh;
  padding: 0 5px;
  overflow-y: scroll;
}

.mr0-2rem {
  margin-right: .2rem;
}

.dropdown-company-dahboard {
  transition: all .5s ease-in-out;
  position: relative;
}

.dropdown-company-dahboard .dropdown-content {
  width: 90%;
  margin: 0 auto;
  transition: all .5s ease-in-out;
  position: relative !important;
}

.dropdown-company-dahboard .dropdown-content .certificate-area-dashboard {
  zoom: .7;
  margin-top: 10px;
}

.dropdown-company-dahboard .MuiFormControl-root {
  width: 105px;
  margin-left: 19px;
}

.advisor-modal .user-advisor-modal-icon {
  width: 54px;
  height: 50px;
}

.advisor-modal-form {
  zoom: .7;
}

.advisor-modal-form .pr {
  margin-bottom: 20px;
}

.advisor-modal-form .pr.checkbox {
  margin-bottom: 0;
}

.form-group .img-upload-area {
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  display: flex;
  position: relative;
}

.form-group .img-upload-area .img-box {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.form-group .img-upload-area .img-box img {
  object-fit: revert;
  border: 2px solid #15d757;
  border-radius: 50%;
  width: 98px;
  height: 98px;
  padding: 0;
}

.form-group .img-upload-area .img-box .input-file {
  cursor: pointer;
  z-index: 2;
  background: none;
  border: none;
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 1px;
  left: 85px;
}

.form-group .img-upload-area .img-box .input-file input {
  z-index: 99;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: block;
  position: relative;
  top: -4px;
  left: -2px;
  opacity: 0 !important;
}

.form-group .img-upload-area .img-box .input-file .add-btn {
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  padding: 5px;
  line-height: 1.8rem;
  position: absolute;
  top: 7px;
  left: -12px;
  box-shadow: 0 0 5px #0003;
}

.form-group .img-upload-area .img-box .input-file .add-btn svg {
  opacity: .9;
  fill: #748aff;
  width: 17px;
}

.upload-pic-area #profile {
  opacity: 0;
  cursor: pointer;
  z-index: 99;
  width: 100%;
  height: 82px;
  position: absolute;
  top: -2px;
  left: 0;
}

.upload-pic-area label {
  width: 100%;
  display: block;
}

.report-btn-r {
  z-index: 1;
  position: absolute;
  bottom: -9px;
  right: -6px;
}

.label-checkbox {
  position: relative;
}

.label-checkbox .tooltiparea {
  z-index: 2;
  opacity: 0;
  visible: hidden;
  background-color: #fff;
  border-radius: 7px;
  width: 300px;
  padding: 10px;
  position: absolute;
  top: 39px;
  box-shadow: 0 0 5px #0003;
}

.label-checkbox:hover .tooltiparea {
  opacity: 1;
  visible: visible;
}

.list-file-manger {
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
}

.list-file-manger li {
  padding-left: 9px;
  font-size: .75rem;
  position: relative;
}

.list-file-manger li:before {
  content: "";
  border-radius: 50%;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 6px;
  left: -9px;
}

.list-file-manger li.blue-list:before {
  background-color: #05a;
}

.list-file-manger li.lightblue-list:before {
  background-color: #5d9afc;
}

.list-file-manger li.green-list:before {
  background-color: #40dda1;
}

.list-file-manger li.dropbox-list:before {
  background: #0160ff;
}

.list-file-manger li.gdrive-list:before {
  background: #ffbd19;
}

.table-permission {
  width: 100%;
  overflow-x: auto;
}

.table-permission .table-userPermissions {
  background-color: #f0f2fd50;
  border: 1px solid #f0f2fd;
  border-radius: 30px;
  width: 100%;
  margin-top: 1rem;
  padding: 2rem;
}

.table-permission .table-userPermissions thead tr th {
  color: #151313;
  text-align: left;
  z-index: 1;
  border-bottom: 1px solid #b5bfde;
  font-size: 18px;
  position: sticky;
  top: 0;
  background-color: #0000 !important;
}

.table-permission .table-userPermissions thead tr th:first-child {
  position: sticky;
  left: 0;
}

.table-permission .table-userPermissions thead tr th.user-area {
  text-align: left;
}

.table-permission .table-userPermissions tbody tr td:first-child {
  text-align: left;
  z-index: 2;
  position: sticky;
  left: 0;
}

.table-permission .table-userPermissions tbody tr td .checkbox:before, .table-permission .table-userPermissions tbody tr td .checkbox:after {
  background-color: #e9ebf4;
}

.table-permission .table-userPermissions tbody tr td .checkbox input {
  opacity: 0;
}

.table-permission .table-userPermissions tbody tr td .checkbox input:checked, .table-permission .table-userPermissions tbody tr td .checkbox.active input:checked {
  opacity: 1;
}

.table-permission .table-userPermissions.table-clientPermissions thead tbody tr td input {
  opacity: 1;
  background-color: #fff;
}

.table-permission .table-userPermissions.table-clientPermissions thead tbody tr td .dropdowns-advisor-permission .dropdown-add-group.dropdown .dropdown-item .deta-dropdown-company .checkbox:before, .table-permission .table-userPermissions.table-clientPermissions thead tbody tr td .dropdowns-advisor-permission .dropdown-add-group.dropdown .dropdown-item .deta-dropdown-company .checkbox:after {
  background: #fff !important;
}

.table-permission .table-userPermissions thead tr th.user-area span {
  font-size: 12px;
}

.table-clientPermissions {
  margin: 0 auto;
}

.table-clientPermissions td, .table-clientPermissions th {
  width: auto !important;
  min-width: 200px !important;
}

.table-clientPermissions .select-advisor {
  width: auto;
  margin-left: 10px;
  font-size: 3px;
}

.table-clientPermissions .select-advisor .MuiSelect-select {
  padding: 14px 24px;
}

.table-clientPermissions .select-advisor fieldset {
  border: none !important;
}

.MuiList-root .MuiButtonBase-root {
  font-size: 13px;
}

.disable-advisor-checkbox span svg {
  opacity: .4 !important;
  color: #fc7785 !important;
  margin-right: 10px !important;
}

.select-advisor-list {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 8px !important;
  padding-bottom: 8px !important;
}

.select-advisor-list:last-child {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.select-advisor-list span {
  padding: 0;
  font-size: 14px;
}

.select-advisor-list span svg {
  color: #fc7785;
  margin-right: 10px;
}

.select-advisor-list span.Mui-checked svg {
  color: #fc7785;
}

.select-advisor-list .MuiListItemText-root {
  font-size: 13px;
}

.close-table-prem {
  background-color: #fc7785;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  padding: 1px;
  top: -3px;
  right: -11px;
}

.expand-button {
  background-color: #0000;
  width: 28px;
  margin-left: -20px;
  padding: 0;
  font-size: 1rem;
}

.sub-folders {
  text-align: left;
  border-radius: 5px;
  margin: 5px 7px;
  position: relative;
  box-shadow: 0 0 5px #0003;
}

.sub-folders span {
  width: 80%;
  margin: 5px 0;
  font-size: .75rem;
  display: block;
}

.sub-folders .date {
  font-size: .5rem;
  color: #000 !important;
}

.help-icon {
  background-color: #0000;
  margin: 0;
  padding: 0;
  top: -3px;
  right: 0;
}

.help-icon svg {
  width: 1.5rem;
}

.progress-box-vertical {
  margin-left: 0;
  position: relative;
}

.progress {
  background-color: #e9ecef;
  border-radius: .25rem;
  width: 70px;
  height: .8rem;
  font-size: .75rem;
  display: flex;
  overflow: visible;
}

.progress .progress-bar {
  color: #fff;
  white-space: nowrap;
  background-image: linear-gradient(to right, red, red, #f26807, #f26807, #29fc00, #29fc00);
  background-repeat: no-repeat;
  background-size: 6em 1.95em;
  justify-content: end;
  margin: 0;
  animation-name: width;
  animation-duration: 3s;
  display: flex;
  overflow: visible;
}

.progress .progress-bar span.precentage-block {
  color: #000;
  margin-right: -8px;
  font-size: 9px;
}

.progress .progress-bar span.precentage-block i {
  color: #fff;
  background-color: #4a67ff;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 7px;
  font-style: normal;
}

@keyframes width {
  0%, 100% {
    transition-timing-function: cubic-bezier(1, 0, .45, .85);
  }

  0% {
    width: 0;
  }

  33% {
    width: 23%;
  }

  66% {
    width: 66%;
  }

  100% {
    width: 100%;
  }
}

.arrow-right {
  color: #222;
  white-space: initial;
  zoom: .6;
  background-color: #dde2f2;
  border-radius: 36px;
  justify-content: center;
  align-items: center;
  width: 115px;
  margin-top: -18px;
  margin-left: -68px;
  padding: 11px 5px;
  font-size: 11px;
  display: flex;
  position: absolute;
  top: -53px;
  left: auto;
}

.arrow-right:before {
  content: "";
  border-top: 8px solid #dde2f200;
  border-bottom: 8px solid #dde2f200;
  border-left: 15px solid #dde2f2;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -13px;
  right: 35px;
  transform: rotate(90deg);
}

.dropdowns-advisor-permission {
  width: 100%;
  position: relative;
}

.dropdowns-advisor-permission .dropdown-add-group.dropdown {
  height: auto;
}

.dropdowns-advisor-permission .dropdown-add-group.dropdown #dropdown-basic {
  background-color: #0000;
  padding-left: 42px;
  font-size: 11px;
}

.dropdowns-advisor-permission .dropdown-add-group.dropdown #dropdown-basic svg {
  width: 15px;
}

.all-reprots-area .reports-logs {
  z-index: 2;
  border-radius: 5px;
  padding: 3px 5px;
  font-size: 12px;
  left: 50%;
  transform: translateX(-50%);
}

.company-audit-page .table-area-company-log {
  width: 100%;
  margin: 0 auto;
}

.company-audit-page .table-area-company-log .tabs-btn {
  justify-content: center;
  margin: 25px 0;
  display: flex;
}

.company-audit-page .table-area-company-log .tabs-btn button {
  border-radius: 8px;
  margin: 0 10px;
  padding: 10px 35px;
}

.company-audit-page .table-area-company-log .table-company-log {
  margin: 0 auto;
}

.company-audit-page .table-area-company-log .table-company-log thead tr th {
  font-size: 17px;
}

.company-audit-page .table-area-company-log .table-company-log tbody tr td {
  max-width: 300px;
  font-size: 14px;
}

.company-audit-page .table-company-log tbody tr td .progress-box-vertical {
  height: 80px;
  margin-left: 43px;
}

.company-audit-page .table-company-log tbody tr td .progress-box-vertical.style-two .text-center-area {
  left: 1%;
}

.company-audit-page .table-company-log thead tr th .label-checkbox .checkbox:before {
  top: 19px;
}

.company-audit-page .table-company-log thead tr th .label-checkbox .checkbox:after {
  top: 17px;
}

.portfolio-overall .chart {
  perspective: 1000px;
  perspective-origin: 50%;
  backface-visibility: visible;
  width: 176px;
  margin: 0 37px;
}

.portfolio-overall .chart .bar {
  height: 10em;
  transform-style: preserve-3d;
  position: relative;
  transform: rotateX(60deg)rotateY(0);
}

.portfolio-overall .chart .bar .face {
  background-color: #cccccc40;
  border-radius: 4px;
  width: 100%;
  height: 2em;
  position: relative;
}

.portfolio-overall .chart .bar .face.side-a, .portfolio-overall .chart .bar .face.side-b {
  width: 2em;
}

.portfolio-overall .chart .bar .side-a {
  transform: rotateX(90deg)rotateY(-90deg)translateX(2em)translateY(1em)translateZ(1em);
}

.portfolio-overall .chart .bar .side-b {
  position: absolute;
  right: 0;
  transform: rotateX(90deg)rotateY(-90deg)translateX(4em)translateY(1em)translateZ(-1em);
}

.portfolio-overall .chart .bar .side-0 {
  transform: rotateX(90deg)rotateY(0)translateX(0)translateY(1em)translateZ(-1em);
}

.portfolio-overall .chart .bar .side-1 {
  transform: rotateX(90deg)rotateY(0)translateX(0)translateY(1em)translateZ(3em);
}

.portfolio-overall .chart .bar .top {
  transform: rotateX(0)rotateY(0)translateX(0)translateY(4em)translateZ(2em);
}

.portfolio-overall .chart .bar .floor {
  box-shadow: 0 .1em .6em #0000004d, .6em -.5em 3em #0000004d, 1em -1em 8em #fff;
}

.portfolio-overall .chart .growing-bar {
  background-image: linear-gradient(to right, red, #f26807, #f26808, #f26807, #29fc00, #29fc00, #29fc00);
  background-size: 11em 1.95em;
  border-radius: 3px;
  height: 2em;
  animation-name: width;
  animation-duration: 3s;
  position: relative;
}

.portfolio-overall .chart .growing-bar .precentage-block {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(360deg);
}

.portfolio-overall .chart .growing-bar .precentage-block .arrow-right {
  font-size: 9px;
  position: absolute;
  inset: 80px -30px auto auto;
  transform: rotateX(180deg);
}

.portfolio-overall .chart .growing-bar .precentage-block .arrow-right:before {
  border-top: 19px solid #bcd7ff99;
  border-bottom: 8px solid #0000;
  border-left: 8px solid #0000;
  top: 16px;
  right: 50%;
  transform: translateX(-50%);
}

.portfolio-overall .chart .growing-bar .precentage-block i {
  margin-top: 17px;
  margin-right: 4px;
  font-size: 11px;
  display: block;
  transform: rotateX(180deg);
}

.portfolio-overall .chart .text-center-area {
  text-align: center;
  width: 100%;
  margin-bottom: 0;
  font-size: 11px;
  position: absolute;
  bottom: 49px;
}

.new-report-btn.reports-btn-area {
  width: 100%;
}

.new-report-btn.reports-btn-area span {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2px 10px 5px 0;
  display: flex;
}

.new-report-btn.reports-btn-area span i {
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 0;
}

.new-report-btn.reports-btn-area span i svg {
  transition: all .3s ease-in-out;
  transform: rotate(180deg);
}

.new-report-btn.reports-btn-area.active span i svg {
  transition: all .3s ease-in-out;
  transform: rotate(0);
}

select.new-report-btn {
  background-color: #f0f0f8;
  padding: 4px 10px;
}

.company-audit-page .report-start {
  width: 80%;
  margin: 10px auto 0;
}

.company-audit-page .report-start .right-list {
  list-style: none;
}

.company-audit-page .report-start .right-list li {
  justify-content: space-between;
  font-weight: 600;
  display: flex;
}

.company-audit-page .report-start .right-list li span {
  font-weight: 400;
}

.benchmark-report-page {
  padding: 0 0 30px;
}

.benchmark-report-page .container {
  padding: 0;
}

.benchmark-report-page .investmentcertificate-page .header-area {
  height: 381px;
}

.benchmark-report-page .investmentcertificate-page .certificate-area {
  margin-bottom: 44px;
}

.benchmark-report-page .investmentcertificate-page .certificate-area .portfolio-overall .progress {
  width: 106px;
  height: 1rem;
}

.benchmark-report-page .investmentcertificate-page .certificate-area .portfolio-overall .progress .progress-bar {
  background-size: 9em 1.95em;
  border-radius: 3px;
}

.benchmark-report-page .investmentcertificate-page .certificate-area .portfolio-overall .progress-box-vertical .text-center-area {
  text-align: center;
  width: 100%;
  bottom: -67px;
  transform: translateX(-42%);
  font-size: 11px !important;
}

.checklist-page {
  padding: 40px 0;
}

.h0-5rem {
  height: .7rem;
}

.w0-5rem {
  width: .7rem;
}

.edit-pencil {
  top: 3px;
}

table thead {
  z-index: 2;
}

.checklist-page .table-area-company-log .table-company-log tbody tr td, .checklist-page .table-area-company-log .table-company-log thead tr th {
  width: auto;
  min-width: 100px;
}

.reprot-btn-dropdowns {
  width: 265px;
}

.reprot-btn-dropdowns .MuiFormControl-root {
  min-width: 95px;
  margin: -4px 0 0;
  font-size: 14px;
}

.reprot-btn-dropdowns .MuiFormControl-root #demo-simple-select-label {
  font-size: 14px;
  top: -9px;
}

.reprot-btn-dropdowns .MuiFormControl-root #demo-simple-select-label.Mui-focused {
  top: 0;
}

.reprot-btn-dropdowns .MuiFormControl-root #demo-simple-select {
  padding: 5px 25px 5px 15px;
}

.reprot-btn-dropdowns .MuiFormControl-root label {
  margin-top: -5px;
  font-size: 14px;
}

.reprot-btn-dropdowns .MuiSelect-select {
  border-radius: 5px;
  background: #e4effa !important;
  border-bottom: 2px solid #1976d2 !important;
  padding: 9px 32px 10px 11px !important;
}

.dropdown-company-dahboard .MuiFormControl-root {
  min-width: 95px;
  margin: 11px 10px 0;
  font-size: 14px;
}

.dropdown-company-dahboard .MuiFormControl-root #demo-simple-select-label {
  font-size: 14px;
  top: -9px;
}

.dropdown-company-dahboard .MuiFormControl-root #demo-simple-select-label.Mui-focused {
  top: 0;
}

.dropdown-company-dahboard .MuiFormControl-root #demo-simple-select {
  padding: 5px 25px 5px 15px;
}

.dropdown-company-dahboard .MuiFormControl-root label {
  margin: -8px 0 0;
  font-size: 14px;
}

.dropdown-company-dahboard .MuiSelect-select {
  background: #e4effa !important;
  border-bottom: 2px solid #1976d2 !important;
  border-radius: 5px !important;
  padding: 4px 32px 8px 11px !important;
}

.company-audit-page .dropdown-add-group {
  width: 350px;
  margin: 0 auto;
}

.dropdown-company-dahboard .certificate-area-dashboard img {
  width: 93px;
}

.w180px {
  width: 180px;
}

.w60px {
  width: 60px;
}

.aic.advisor-index .table-company-repository {
  width: 600px;
}

.client-tab .user-area {
  width: 86%;
}

.all-reprots-area.extenstion-pack-btn-groups.op20 {
  opacity: 1;
}

.all-reprots-area.extenstion-pack-btn-groups.op20 .btn-packges {
  color: #002d6340;
  background-color: #f0f0f880;
  border-color: #ccc;
  transition: all .3s ease-in-out;
}

.all-reprots-area.extenstion-pack-btn-groups.op20 .btn-packges .btn-packge {
  pointer-events: none;
  color: #002d6340;
  background-color: #f0f0f880;
  border-color: #ccc;
}

.all-reprots-area.extenstion-pack-btn-groups.op20 .btn-packges .btn-packge:hover {
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.advisor-bg {
  background-color: #fff6a680;
  border-color: #fff6a680;
}

.advisor-bg-lite {
  background-color: #fffacd80;
}

.investor-bg {
  background-color: #aafed680;
  border-color: #aafed680;
}

.client-bg {
  background-color: #fff;
}

.advisor-profile {
  color: #000;
}

.investor-profile {
  color: #000;
  background-color: #fff;
  border: 2px solid #7385be80;
}

.client-profile {
  color: #000;
  background-color: #c5cfff80;
}

.investor-circle .CircularProgressbar .CircularProgressbar-trail {
  stroke: #f6f6f6 !important;
}

.report-start.invest-reports .progress-area-report .progress-box {
  text-align: center;
  margin: 0 auto !important;
}

.report-start.invest-reports .progress-area-report .progress-box:before, .report-start.invest-reports .progress-area-report .progress-box:after {
  display: none;
}

.report-start.invest-reports .progress-area-report .right-list li span {
  width: auto;
}

.report-start .progress-area-report.progress-fit-report .progress-area .progress-box:before, .report-start .progress-area-report.progress-fit-report .progress-area .progress-box:after {
  display: none;
}

.fitmetrics-reports.pr .matrics-box:nth-child(odd) {
  background-color: #f0f0f870;
}

.fitmetrics-reports.pr .matrics-box {
  padding: 5px 10px;
}

.fitmetrics-reports.pr .matrics-box .box-text {
  text-align: center;
  word-break: break-all;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #00000020;
  border-radius: 4px;
  width: 190px;
  padding: 0 7px;
  font-size: 11px;
  box-shadow: 0 6px 12px -2px #32325d10, 0 3px 7px -3px #0000004d;
}

.fitmetrics-reports.pr .matrics-box p {
  width: 201px;
  font-size: 12px;
}

.invest-box-funds .group-inputs p b {
  font-size: 16px;
  display: block;
}

.invest-box-funds .group-inputs p b .tooltip-area-company-settings {
  position: relative;
  top: 0;
}

.invest-box-funds .group-inputs p b .tooltip-area-company-settings .icon img {
  width: 15px;
}

.switch-box span.Mui-checked {
  color: #40dda1;
}

.switch-box span.Mui-checked + .MuiSwitch-track {
  background-color: #40dda1;
}

.funds-box .item-box {
  width: 100%;
}

.funds-box .item-box label {
  margin-bottom: 7px;
}

.funds-box .item-box .MuiInputBase-root:before, .funds-box .item-box .MuiInputBase-root:after {
  display: none;
}

.funds-box .item-box .MuiInputBase-root .MuiSelect-select {
  background-color: #f0f0f8;
  border-radius: 4px;
  padding: 10px 27px 10px 15px;
}

.select-box .MuiInputBase-root {
  min-width: 330px;
}

.content-area button {
  text-align: center;
  min-width: 87px;
  margin: -7px auto -3px;
  padding: 4px 10px;
  font-size: 11px;
  display: block;
}

.content-area .right-list {
  text-align: right;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.content-area .right-list li {
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 700;
}

.content-area .right-list li span {
  width: 290px;
  font-weight: 400;
  display: inline-block;
}

.content-area .logo-box {
  text-align: center;
  margin-bottom: 20px;
}

.bottomlist-area .list-box .list {
  margin: 9px 0 0;
  padding: 0;
  list-style: none;
}

.bottomlist-area .list-box .list li {
  margin-bottom: 5px;
  position: relative;
}

.bottomlist-area .list-box .list li span img {
  width: 11px;
}

.bottomlist-area .list-box .list li .tooltip-area-company-settings {
  top: 0;
  right: 33px;
}

.sidebar-inveter {
  height: 95.3vh;
  position: relative;
}

.sidebar-inveter .mta {
  width: 94%;
  position: absolute;
  bottom: 0;
}

.qustion-box .tooltip-area-company-settings {
  top: -28px;
  right: -38px;
}

.qustion-box .rightanswer-text .df.jce {
  position: absolute;
  top: -15px;
  right: -28px;
}

.background-invest {
  background-image: url("bg-ai.dca51315.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 37%;
}

.background-des {
  background-image: url("ai-text.16302a15.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30%;
}

.background-des textarea {
  background-color: #0000;
}

.fitmetrics-reports.pr .matrics-box .icon {
  text-align: center;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #00000020;
  border-radius: 4px;
  width: 27px;
  height: 22px;
  padding: 2px;
  box-shadow: 0 6px 12px -2px #32325d10, 0 3px 7px -3px #0000004d;
}

.fitmetrics-reports.pr .matrics-box .icon svg {
  width: 17px;
  height: 17px;
}

.fitmetrics-reports.pr .matrics-box.style-two .icon {
  box-shadow: none;
  background-color: #0000;
  border: none;
  width: 27px;
  padding: 0;
}

.fitmetrics-reports.pr .matrics-box.style-two .box-text {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

.report-start.fitmetrics-reports .reports-table thead tr th, .report-start.fitmetrics-reports .reports-table tbody tr td {
  text-align: left;
  vertical-align: top;
  padding: 10px;
  background: none !important;
  width: 20% !important;
  min-width: auto !important;
}

.report-start.fitmetrics-reports .reports-table tbody tr td {
  border-top: 5px solid #002d63;
  border-right: 2px solid #002d63;
  border-radius: 5px;
  padding: 10px 19px;
  box-shadow: 0 6px 12px -2px #32325d20, 0 3px 7px -3px #0000004d;
  background-color: #fff !important;
}

.report-start.fitmetrics-reports .reports-table thead tr th {
  text-align: center;
  border: 1px solid #4cff88;
  border-radius: 12px;
  font-size: 12px;
  transition: all .3s ease-in-out;
  background-color: #f0f0f8 !important;
}

.report-start.fitmetrics-reports .reports-table thead tr th:hover {
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.report-start.fitmetrics-reports .reports-table thead tr th img {
  margin: 0 auto;
  display: block;
}

.report-start.fitmetrics-reports .reports-table tbody tr td ul {
  padding: 0;
}

.report-start.fitmetrics-reports .reports-table tbody tr td ul li, .report-start.fitmetrics-reports .reports-table tbody tr td ul p {
  margin-bottom: 13px;
  font-size: 11px;
}

.report-start.fitmetrics-reports .reports-table tbody tr td ul ul {
  margin-left: 15px;
}

.report-start.fitmetrics-reports .reports-table tbody tr td ul p.border-box {
  border: 1px solid #ccc;
  padding: 5px;
}

.gtcr-bespoked {
  grid-template-columns: 31.33% 31.33% 31.33%;
}

.btn-group.fit-btn-group .new-report-btn {
  margin-bottom: 10px;
  padding: 4px;
}

.btn-group.fit-btn-group .new-report-btn span {
  font-size: 1.1rem;
}

.progress-area ul.list-company-detail {
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  display: flex;
}

.progress-area ul.list-company-detail li {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  width: 47%;
  margin-bottom: 11px;
  margin-left: 15px;
  padding: 11px;
  display: flex;
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.progress-area ul.list-company-detail li.button-list {
  box-shadow: none;
  background-color: #0000;
}

.progress-area ul.list-company-detail li p {
  margin-right: 7px;
}

.modal-bio .user-area {
  align-items: center;
  display: flex;
}

.modal-bio .user-area .text-box {
  margin-left: 16px;
}

.investor-details-form .switch-box {
  top: 15%;
}

.investor-details-form .edit-btn {
  transition: all .3s ease-in-out;
  display: inline-block;
}

.investor-details-form .edit-btn .edit-pencil {
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin-left: 11px;
}

.investor-details-form .edit-btn .edit-pencil svg {
  fill: #fc7785;
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
}

.sidebar-investor {
  width: 78%;
  right: 0;
  left: auto !important;
}

.sidebar-investor .MuiBackdrop-root {
  background-color: #fff;
}

.sidebar-investor .w60 {
  width: 90%;
}

.sidebar-investor .w60 span.w200px {
  display: none;
}

.sidebar-investor .w60 .logo-report.w60 {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.list-fit {
  list-style: none;
}

.multiselect-invest fieldset {
  border: none !important;
}

.blur-disabled {
  filter: blur(2.5px);
  pointer-events: none;
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
}

.view-btn-fit i {
  color: #4a67ff;
  display: block;
}

.view-btn-fit span {
  color: #4a67ff;
}

.view-btn-fit:hover i {
  color: #fc7785;
  display: block;
}

.view-btn-fit:hover span {
  color: #fc7785;
}

.mr16rem {
  margin-right: 16rem;
}

.dots-fit-report i {
  margin: 0 1px;
  font-size: 11px;
}

.investor-dropdown .MuiInputBase-root {
  width: 130px;
}

.investor-dropdown .MuiInputBase-root #investor-dropdown {
  background: #e4edf7 !important;
  border: none !important;
  padding: 7px 32px 7px 10px !important;
  font-size: 13px !important;
}

.investor-dropdown .MuiInputBase-root fieldset {
  border: none !important;
}

[aria-labelledby="investor-multiple-checkbox-label"] .MuiButtonBase-root .MuiTypography-root {
  color: #000;
  font-size: 12px;
}

[aria-labelledby="investor-multiple-checkbox-label"] .MuiButtonBase-root .MuiButtonBase-root {
  margin-right: 9px;
  padding: 0;
}

.tp5px {
  top: 3px;
}

.dropdown-company-dahboard .progress-area-report.progress-fit-report {
  margin-top: -77px;
}

.dropdown-company-dahboard .progress-area-report.progress-fit-report .progress-area .progress-box {
  margin: 0 0 12px 15px;
}

.dropdown-company-dahboard .progress-area-report.progress-fit-report .progress-area .progress-box:before, .dropdown-company-dahboard .progress-area-report.progress-fit-report .progress-area .progress-box:after {
  display: none;
}

.advisor-index .MuiTabs-root {
  z-index: 9;
  position: relative;
}

.dropdown-company-dahboard .progress-area-report.progress-fit-report .tooltip-area .icon img {
  width: 14px;
}

.dropdown-company-dahboard .progress-area-report.progress-fit-report .progress-area .tooltip-area .overlay-box:before {
  display: none;
}

.ReactTable .-pagination .-next .-btn, .ReactTable .-pagination .-previous .-btn {
  color: #fff;
  background-color: #4a67ff;
  border-radius: 5px;
  transition: all .3s ease-in-out;
}

.ReactTable .-pagination .-next .-btn:hover, .ReactTable .-pagination .-previous .-btn:hover {
  background-color: #fc7785 !important;
}

.funds-item.w33 {
  width: 32.33%;
}

.content-area .right-list li span .group-inputs p .edit-btn {
  width: auto;
}

.content-area .right-list li span .group-inputs p .edit-btn .edit-pencil {
  min-width: auto;
  margin: 0 0 0 9px;
  padding: 0;
}

.wordbreak {
  word-break: break-all;
}

.progress-fit-report .progress-area .progress-box {
  margin: 0;
}

.w80 {
  width: 80%;
}

@media (width >= 999px) {
  .a4-fit-pdf {
    width: 210mm;
    height: 297mm;
  }
}

@media (width <= 1600px) {
  .report-page .sidebar-report .mah100.mya.oya.df.fdc.jcsb {
    max-height: 40vh;
  }

  .report-page .sidebar-report .siedbar-dropdwon {
    max-height: 63vh;
  }

  .certificates-page .certificates-current .packge-select .w250px {
    width: 100%;
  }

  .extenstion-pack-btn-groups .btn-packge .trophy-cup img {
    width: 48px !important;
  }

  .sectionWrapper .group-inputs.link-group p a {
    width: 140px;
  }
}

@media (width <= 1500px) {
  .our-thesis-sec .fs3-5rem {
    margin-bottom: 2rem !important;
    font-size: 2.5rem !important;
    line-height: 2rem !important;
  }

  .our-thesis-sec p {
    line-height: 1.5rem !important;
  }
}

@media (width <= 1450px) {
  .report-page .sidebar-report .p0-5rem h3 {
    font-size: 14px;
    line-height: 14px;
  }

  .report-page .sidebar-report .p0-5rem button {
    font-size: 11px;
  }

  .report-page .sidebar-report .mah100.mya.oya.df.fdc.jcsb {
    max-height: 50vh;
  }

  .investmentcertificate-page .header-area {
    background-size: 100% 100%;
    height: 306px;
  }

  .investmentcertificate-page .certificate-area {
    margin-top: -168px;
  }

  .certification-pricing-column {
    right: 390px !important;
  }

  .extenstion-pack-btn-groups .btn-packge .ribbon {
    right: -30px;
  }

  .extenstion-pack-btn-groups .btn-packge .ribbon img {
    width: 80px;
  }

  .extenstion-pack-btn-groups .btn-packge .ribbon.bronze img, .extenstion-pack-btn-groups .btn-packge .ribbon.silver img, .extenstion-pack-btn-groups .btn-packge .ribbon.gold img {
    width: 46px;
  }

  .step-box {
    zoom: .8;
  }

  #confirmDescriptionModal .fs1rem {
    font-size: 11px;
  }

  #confirmDescriptionModal .p3rem {
    padding: 1.5rem;
  }

  #confirmDescriptionModal .my1rem {
    margin: 13px 0 0;
    padding: 12px;
  }

  #welcome2MinuteTestModal .tac p {
    font-size: 12px;
  }

  #welcome2MinuteTestModal .p3rem {
    padding: 2.5rem 1.5rem 1.5rem;
  }

  #welcome2MinuteTestModal .my1rem {
    margin: 13px 0 0;
    padding: 12px;
  }

  #dataRepositoryModal .tac p, #dataRepositoryModal .tac li {
    font-size: 12px;
  }

  #dataRepositoryModal .p3rem {
    padding: 2.5rem 1.5rem 1.5rem;
  }

  #dataRepositoryModal .my1rem {
    margin: 13px 0 0;
    padding: 12px;
  }

  .slider-container .item {
    min-width: 209px;
    max-width: 209px;
  }

  .table-of-content .list-table li:first-child:before, .table-of-content .list-table li:nth-child(2):before, .table-of-content .list-table li:nth-child(3):before {
    width: 74%;
  }

  .table-of-content .list-table li:nth-child(4):before {
    width: 60%;
  }

  .table-of-content.style-two .list-table li:first-child:before, .table-of-content.style-two .list-table li:nth-child(2):before, .table-of-content.style-two .list-table li:nth-child(3):before {
    width: 70%;
  }

  .extenstion-pack-btn-groups .btn-packge .trophy-cup img {
    width: 48px !important;
  }

  .sectionWrapper .group-inputs.link-group p a {
    width: 120px;
  }

  .benchmark-report-page .investmentcertificate-page .header-area {
    height: 165px;
  }

  .benchmark-report-page .investmentcertificate-page .certificate-area {
    margin-top: -60px;
    margin-bottom: 24px;
  }

  .investor-leads-tooltip .tooltip-area.mr16rem {
    margin-right: 12rem;
  }

  .right-slide-box .btn-area button {
    font-size: 15px;
  }

  .btn-plus-sidebar {
    right: 10px;
    padding: 0 !important;
    font-size: 2rem !important;
    top: 146px !important;
  }
}

@media (width <= 1400px) {
  .admin-table .rt-th .rt-resizable-header-content, .admin-table .rt-tr .rt-td {
    font-size: 14px;
  }

  .advisor-index .df.fdc.aic.bss.bw2px.bo5.br10px.p2rem {
    padding: 1rem;
  }
}

@media (width <= 1390px) {
  .admin-table {
    width: 1151px;
  }
}

@media (width <= 1366px) {
  .report-page .sidebar-report .p0-5rem button {
    padding: 5px 8px;
    font-size: 11px;
  }

  .report-page .sidebar-report .mah100.mya.oya.df.fdc.jcsb {
    max-height: 37vh;
  }

  .report-page .sidebar-report .siedbar-dropdwon {
    max-height: 60vh;
  }

  .table-of-content .list-table li:first-child:before, .table-of-content .list-table li:nth-child(2):before, .table-of-content .list-table li:nth-child(3):before {
    width: 70%;
  }

  .table-of-content .list-table li:nth-child(4):before {
    width: 55%;
  }

  .table-of-content.style-two .list-table li:first-child:before, .table-of-content.style-two .list-table li:nth-child(2):before, .table-of-content.style-two .list-table li:nth-child(3):before {
    width: 66%;
  }

  .extenstion-pack-btn-groups .btn-packge .trophy-cup {
    top: 68px;
  }

  .extenstion-pack-btn-groups .btn-packge .trophy-cup img {
    width: 45px !important;
  }

  .sectionWrapper .group-inputs.link-group p a {
    width: 100px;
  }

  .back-btn {
    top: 9px;
    left: -266px;
  }

  .certificate-area-dashboard .w250px {
    width: 230px;
  }

  .right-slide-box .btn-area button {
    font-size: 14px;
  }

  .additional-sections-button .btn-packge {
    font-size: .55rem !important;
  }

  .btn-plus-sidebar {
    top: 134px !important;
  }

  .user-info-sidebar {
    padding: .4rem .5rem;
  }

  .user-info-sidebar .df.jcc .w3rem.h3rem {
    width: 2rem;
    height: 2rem;
  }

  .user-info-sidebar .df.jcc .w5rem.h2rem {
    width: 4rem;
    height: 1.5rem;
  }
}

@media (width <= 1340px) {
  .admin-table {
    width: 1103px;
  }
}

@media (width <= 1300px) {
  .report-page .sidebar-report .mah100.mya.oya.df.fdc.jcsb {
    max-height: 30vh;
  }

  .certification-pricing-column {
    right: 280px !important;
  }

  .sectionWrapper .group-inputs.link-group p a {
    width: 80px;
  }

  .admin-table {
    width: 1083px;
  }

  .admin-table .rt-th .rt-resizable-header-content, .admin-table .rt-tr .rt-td {
    font-size: 12px;
  }

  .login-page .login-container {
    width: 72%;
    padding: 170px 0 70px 146px;
  }

  .contact-us-page .df.w100 .w60.fs2-5rem {
    font-size: 2rem;
    padding-left: 9rem !important;
  }

  .contact-us-page .df.w100 .w35 {
    width: 42%;
  }
}

@media (width <= 1280px) {
  .admin-table {
    width: 1053px;
  }
}

@media (width <= 1240px) {
  .admin-table {
    width: 1013px;
  }
}

@media (width <= 1200px) {
  .certification-pricing-column {
    right: 165px !important;
  }

  .slider-container .item {
    min-width: 220px;
    max-width: 220px;
  }

  .table-of-content .list-table li:first-child:before, .table-of-content .list-table li:nth-child(2):before, .table-of-content .list-table li:nth-child(3):before {
    width: 65%;
  }

  .table-of-content .list-table li:nth-child(4):before {
    width: 50%;
  }

  .table-of-content.style-two .list-table li:first-child:before, .table-of-content.style-two .list-table li:nth-child(2):before, .table-of-content.style-two .list-table li:nth-child(3):before {
    width: 61%;
  }

  .packge-select p {
    font-size: 9px;
  }

  .back-btn {
    left: -223px;
  }

  .right-slide-box .btn-area button {
    min-height: 57px;
  }

  .admin-table {
    width: 967px;
  }
}

@media (width <= 1199px) {
  .hero-saf .w45 {
    width: 100% !important;
  }

  .dropdown-company-dahboard .dropdown-content .certificate-area-dashboard {
    zoom: .4;
  }

  .dropdown-company-dahboard .w10rem {
    width: 7rem;
  }

  #menu {
    z-index: 5;
    background: linear-gradient(45deg, #002d63, #4a67ff);
    width: 230px;
    height: 100vh;
    right: 0;
  }

  #menu.r0 {
    right: -100%;
  }

  #menu.r0.showing {
    right: 0;
  }

  #menu:not(.showing) {
    right: -100%;
  }

  #menu:not(.showing).showing {
    right: 0;
  }

  .hero-saf .ma {
    width: 100%;
  }
}

@media (width <= 1034px) {
  .table-of-content .list-table li:first-child:before, .table-of-content .list-table li:nth-child(2):before, .table-of-content .list-table li:nth-child(3):before {
    width: 55%;
  }

  .table-of-content .list-table li:nth-child(4):before {
    width: 40%;
  }

  .table-of-content.style-two .list-table li:first-child:before, .table-of-content.style-two .list-table li:nth-child(2):before, .table-of-content.style-two .list-table li:nth-child(3):before {
    width: 51%;
  }

  .links-area-social {
    flex-wrap: wrap;
  }

  .links-area-social .group-inputs.link-group .edit-text p {
    width: 100%;
  }
}

@media (width <= 991px) {
  .certification-pricing-column {
    position: relative;
    top: 0;
    right: auto !important;
  }

  .certificates-page .heading-area {
    background-size: 100% 114%;
  }

  .certificates-page .certificates-current .packge-select {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .certificates-page .certificates-current .packge-select .audit-pakcges {
    width: 26%;
    margin: 0;
  }

  .certificates-page .badges-sec .badges-area {
    flex-wrap: wrap;
  }

  .certificates-page .badges-sec .badges-area .left-upgrade-button {
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    margin-right: 0;
    position: relative;
  }

  .certificates-page .data-check-sec .gtcr3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .investmentcertificate-page .certificate-area .left-area .signure-area .date-area.singature-area {
    margin-top: 0;
  }

  .table-of-content {
    width: 91%;
  }

  .progress-dashboard .progress-box {
    margin-top: 10px;
  }

  .progress-dashboard .progress-box .progress-bar {
    width: 40px !important;
    height: 40px !important;
  }

  .progress-dashboard .progress-box .text-center-area {
    font-size: 12px !important;
  }

  .investor-modal-admin .jcsb .ml1rem.w100 {
    margin: 0;
  }

  .investor-modal-admin .jcsb .mr1rem {
    margin-bottom: 20px;
    margin-right: 0 !important;
  }
}

@media (width <= 630px) {
  .certificates-page .certificates-current .packge-select {
    flex-wrap: wrap;
    justify-content: center;
  }

  .certificates-page .certificates-current .packge-select .audit-pakcges {
    width: 50%;
    margin: 0;
  }

  .certificates-page .badges-sec .gtcr3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .certificates-page .data-check-sec .gtcr3 {
    grid-template-columns: repeat(1, 1fr);
  }

  .footer-sec h2 img {
    width: 100%;
  }

  .footer-sec .certificates-current .gtcr3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-sec .upgrade-btn h2 img {
    width: 57%;
  }
}

@media (width <= 490px) {
  .table-permission .table-userPermissions tbody tr td:first-child {
    position: relative;
  }

  .footer-sec h2 img {
    width: 100%;
  }

  .footer-sec .certificates-current .gtcr3 {
    grid-template-columns: repeat(1, 1fr);
  }

  .footer-sec .upgrade-btn h2 img {
    width: 57%;
  }

  .footer-sec .certificates-current .packge-select .audit-pakcges {
    width: 86%;
  }

  .certificates-page .badges-sec .gtcr3 {
    grid-template-columns: repeat(1, 1fr);
  }

  .certificates-page .badges-sec .certificates-current .packge-select .audit-pakcges {
    width: 86%;
  }

  .certificates-page .heading-area p {
    font-size: 11px;
  }

  .certificates-page .heading-area h2 {
    font-size: 1rem;
  }

  .certificates-page .progress-sec .progress-list {
    flex-wrap: wrap;
  }

  .table-of-content {
    width: 80%;
  }

  .table-of-content .list-table li:first-child:before, .table-of-content .list-table li:nth-child(2):before, .table-of-content .list-table li:nth-child(3):before {
    width: 45%;
  }

  .table-of-content .list-table li:nth-child(4):before {
    width: 25%;
  }

  .table-of-content.style-two .list-table li:first-child:before, .table-of-content.style-two .list-table li:nth-child(2):before, .table-of-content.style-two .list-table li:nth-child(3):before {
    width: 41%;
  }

  .investmentcertificate-page .certificate-area .right-area {
    margin: 0 auto 23px;
  }
}

@media print {
  .text-gradient {
    background: none !important;
    background-clip: unset !important;
    text-fill-color: #fff !important;
    color: #fff !important;
  }

  .minh100vh-report, .mih100vh {
    height: 100vh !important;
  }

  .new-report.p3rem {
    padding: 0 !important;
  }

  .new-report .w100 {
    width: auto !important;
  }

  .report-start h2 {
    color: #fff !important;
  }

  .a4-fit-pdf {
    width: 210mm !important;
    height: 280mm !important;
  }
}

.dropdown-section-header {
  zoom: .9;
  border-bottom: 1px solid #00000020;
  border-radius: 5px;
  padding: 5px;
  transition: all .3s ease-in-out;
}

.dropdown-section-header:hover {
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.vh {
  visibility: hidden;
}

.filing-assistant-pos {
  grid-column-end: -1;
}

.badge {
  color: #fff;
  text-align: center;
  vertical-align: initial;
  white-space: nowrap;
  background-color: #dc3545;
  border-radius: .375rem;
  margin-left: .5rem;
  padding: .35em .65rem;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

.file-import-Summary table tr:nth-child(2n), .file-import-Summary .tableRow:nth-child(2n), .file-import-Summary table tr > :nth-child(2n), .file-import-Summary .tableRow > :nth-child(2n) {
  background: #e1e1f155;
}

.file-import-Summary table {
  border-spacing: 0;
}

.file-import-Summary table th {
  font-weight: 600;
}

.file-import-Summary table th, .file-import-Summary table td {
  outline: 0;
  width: 20%;
  min-width: 200px;
  padding: .5rem 1rem;
}

.file-import-Summary table thead th {
  text-align: center;
  border-bottom: 2px solid #002d63;
  font-size: 1.5rem;
  background: #fff !important;
}

.file-import-Summary table td {
  text-align: center;
}

.file-import-Summary table tbody th {
  background: #fff;
  border-right: 2px solid #002d63;
}

.bbn {
  border-bottom: none !important;
}

.fis-th {
  text-align: center;
  border-bottom: 2px solid #002d63;
  font-size: 1.5rem;
  background: #fff !important;
}

.fis-th1 {
  border-bottom: 2px solid #002d63;
  font-size: 1.5rem;
  background: #fff !important;
}

.fis-th-action {
  width: 10%;
  min-width: 170px !important;
}

.fis-td {
  outline: 0;
  width: 20%;
  min-width: 200px;
  padding: .5rem 3rem;
  background: #fff !important;
}

.fis-td-action {
  width: 10% !important;
  min-width: 0 !important;
}

.fis-tr {
  border-bottom: 1px solid #002d63;
}

.bcc {
  border-collapse: collapse;
}

.frs-th {
  min-width: unset !important;
}

.frs-td {
  min-width: unset !important;
  width: unset !important;
}

.frs-table td:nth-child(odd) {
  background: #e1e1f155 !important;
}

.frs-table tbody tr:hover {
  background-color: #e1e1f1;
}

.uf-td {
  min-width: unset !important;
  width: unset !important;
  background: #fff !important;
}

.uf-table tbody tr {
  background: #fff !important;
}

.uf-table tbody tr:hover {
  background-color: #e1e1f1 !important;
}

.uf-table-tr {
  border-bottom: 1px solid #002d63;
  background: #fff !important;
}

.uf-th {
  text-align: center;
  border-bottom: 2px solid #002d63;
  font-size: 1.5rem;
  min-width: unset !important;
  background: #fff !important;
}

.fg1 {
  flex-grow: 1;
}

.rv {
  resize: vertical;
}

.filing-assistant-info {
  margin-top: -24px;
  margin-right: -10px;
}

.repository-step {
  border: 3px solid #4a67ff;
  border-radius: 10px;
  align-items: center;
  width: 80%;
  min-height: 50px;
  padding: 5px 35px;
  display: flex;
}

.repository-manual {
  height: 514px !important;
}

.question-button {
  width: 21px;
  height: auto;
}

.tooltip-area-custom {
  position: absolute;
  top: -6px;
  right: -8px;
}

.tooltip-area-custom .icon {
  transition: all .3s ease-in-out;
  display: inline-block;
  position: relative;
}

.tooltip-area-custom .icon img {
  width: 18px;
  height: auto;
}

.tooltip-area-custom .icon .overlay-box {
  z-index: 999;
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  min-width: 350px;
  padding: 16px;
  transition: all .3s ease-in-out;
  display: none;
  position: absolute;
  top: 50%;
  right: 34px;
  transform: translateY(-50%);
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.tooltip-area-custom .icon .overlay-box p {
  text-transform: none;
  font-size: 13px;
  font-style: normal;
}

.tooltip-area-custom .icon .overlay-box .bottom-text p {
  font-size: 11px;
}

.tooltip-area-custom .icon .overlay-box .price {
  text-align: center;
  margin-top: 13px;
  font-size: 19px;
  font-style: normal;
}

.tooltip-area-custom .icon .overlay-box button {
  z-index: 999;
}

.tooltip-area-custom:hover .overlay-box {
  transition: all .3s ease-in-out;
  display: block;
}

.additional-sections-button .btn-packge {
  border-radius: 5px;
  flex-wrap: wrap;
  align-items: center;
  margin: 8px 0 2px;
  font-size: .75rem;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
}

.additional-sections-button .btn-packge button {
  width: 100%;
  margin-top: 3px;
  transition: all .3s ease-in-out;
  transform: scale(.6);
}

.additional-sections-button .btn-packge:hover {
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.additional-sections-button .btn-packge .tooltip-area {
  position: absolute;
  top: -6px;
  right: -8px;
}

.additional-sections-button .btn-packge .tooltip-area .icon {
  transition: all .3s ease-in-out;
  display: inline-block;
  position: relative;
}

.additional-sections-button .btn-packge .tooltip-area .icon img {
  width: 21px;
  height: auto;
}

.additional-sections-button .btn-packge .tooltip-area .icon .overlay-box {
  z-index: 999;
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  min-width: 350px;
  padding: 16px;
  transition: all .3s ease-in-out;
  display: none;
  position: absolute;
  top: 50%;
  right: 34px;
  transform: translateY(-50%);
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.additional-sections-button .btn-packge .tooltip-area .icon .overlay-box:before {
  content: "";
  border: 15px solid #0000;
  border-left: 15.9px solid #fff;
  border-right-width: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 49%;
  right: -14px;
  transform: translateY(-50%);
}

.additional-sections-button .btn-packge .tooltip-area .icon .overlay-box p {
  text-transform: none;
  margin-bottom: 5px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.additional-sections-button .btn-packge .tooltip-area .icon .overlay-box .bottom-text p {
  font-size: 11px;
}

.additional-sections-button .btn-packge .tooltip-area .icon .overlay-box .price {
  text-align: center;
  margin-top: 13px;
  font-size: 19px;
  font-style: normal;
}

.additional-sections-button .btn-packge .tooltip-area .icon .overlay-box button {
  z-index: 999;
}

.additional-sections-button .btn-packge .tooltip-area:hover .overlay-box {
  transition: all .3s ease-in-out;
  display: block;
}

.additional-sections-button .btn-packge .tooltip-area.style-two {
  top: -25px;
  right: -15px;
}

.additional-sections-info .tooltip-area {
  position: absolute;
  top: -6px;
  right: -8px;
}

.additional-sections-info .tooltip-area .icon {
  transition: all .3s ease-in-out;
  display: inline-block;
  position: relative;
}

.additional-sections-info .tooltip-area .icon img {
  width: 21px;
  height: auto;
}

.additional-sections-info .tooltip-area .icon .overlay-box {
  z-index: 999;
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  min-width: 350px;
  padding: 16px;
  transition: all .3s ease-in-out;
  display: none;
  position: absolute;
  top: 50%;
  right: 34px;
  transform: translateY(-50%);
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.additional-sections-info .tooltip-area .icon .overlay-box:before {
  content: "";
  border: 15px solid #0000;
  border-left: 15.9px solid #fff;
  border-right-width: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 49%;
  right: -14px;
  transform: translateY(-50%);
}

.additional-sections-info .tooltip-area .icon .overlay-box p {
  text-transform: none;
  margin-bottom: 5px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.additional-sections-info .tooltip-area .icon .overlay-box .bottom-text p {
  font-size: 11px;
}

.additional-sections-info .tooltip-area .icon .overlay-box .price {
  text-align: center;
  margin-top: 13px;
  font-size: 19px;
  font-style: normal;
}

.additional-sections-info .tooltip-area .icon .overlay-box button {
  z-index: 999;
}

.additional-sections-info .tooltip-area:hover .overlay-box {
  transition: all .3s ease-in-out;
  display: block;
}

.additional-sections-info .tooltip-area.style-two {
  top: -25px;
  right: -15px;
}

.menuHover .btn-filing-assistant .tooltip-area {
  position: absolute;
  top: -6px;
  right: -8px;
}

.menuHover .btn-filing-assistant .tooltip-area .icon {
  transition: all .3s ease-in-out;
  display: inline-block;
  position: relative;
}

.menuHover .btn-filing-assistant .tooltip-area .icon img {
  width: 21px;
  height: auto;
}

.menuHover .btn-filing-assistant .tooltip-area .icon .overlay-box {
  z-index: 999;
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  min-width: 350px;
  padding: 16px;
  transition: all .3s ease-in-out;
  display: none;
  position: absolute;
  top: 50%;
  left: 34px;
  transform: translateY(-50%);
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.menuHover .btn-filing-assistant .tooltip-area .icon .overlay-box:before {
  content: "";
  border: 15px solid #0000;
  border-left-width: 0;
  border-right: 15.9px solid #fff;
  width: 0;
  height: 0;
  position: absolute;
  top: 49%;
  left: -14px;
  transform: translateY(-50%);
}

.menuHover .btn-filing-assistant .tooltip-area .icon .overlay-box p {
  color: #002d63;
  text-transform: none;
  margin-bottom: 5px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.menuHover .btn-filing-assistant .tooltip-area .icon .overlay-box button {
  z-index: 999;
}

.menuHover .btn-filing-assistant .tooltip-area:hover .overlay-box {
  transition: all .3s ease-in-out;
  display: block;
}

.menuHover .btn-filing-assistant .tooltip-area.style-two {
  top: -25px;
  right: -15px;
}

.investor-leads-tooltip .tooltip-area {
  position: absolute;
}

.investor-leads-tooltip .tooltip-area .icon {
  transition: all .3s ease-in-out;
  display: inline-block;
  position: relative;
}

.investor-leads-tooltip .tooltip-area .icon img {
  width: 18px;
  height: auto;
}

.investor-leads-tooltip .tooltip-area .icon .overlay-box {
  z-index: 999;
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  min-width: 300px;
  padding: 16px;
  transition: all .3s ease-in-out;
  display: none;
  position: absolute;
  left: 34px;
  transform: translateY(-50%);
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.investor-leads-tooltip .tooltip-area .icon .overlay-box:before {
  content: "";
  border: 15px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 49%;
  left: -14px;
  transform: translateY(-50%);
}

.investor-leads-tooltip .tooltip-area .icon .overlay-box p {
  color: #002d63;
  text-transform: none;
  margin-bottom: 5px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.investor-leads-tooltip .tooltip-area:hover .overlay-box {
  transition: all .3s ease-in-out;
  display: block;
}

.investor-leads-tooltip .unselectable {
  color: #bfbfbf;
  cursor: not-allowed;
}

.inner-container {
  padding: 15px 370px;
}

.work-container {
  max-width: 1160px !important;
  padding: 60px 0 !important;
}

.work-container .fs2-125rem {
  font-size: 50px;
}

.work-container .work-card-box .fs1-25rem {
  font-size: 20px;
}

.client-review-container {
  padding: 125px 221px;
}

.video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem {
  width: 790px;
  margin: 0 auto;
  font-size: 50px;
  line-height: 54.2px !important;
}

.lplcbs {
  box-shadow: 6px 13px 13px #07070726;
}

.how-its-work-container {
  padding: 4ss0px 120px;
}

.video-container {
  padding: 100px 230px;
}

.pricing-container {
  padding: 120px 150px;
}

.register-container {
  width: 45%;
  padding: 70px 0 70px 170px;
}

.register-container-not-sure {
  box-sizing: border-box;
  width: 60%;
  padding: 100px 170px 70px;
}

.register-page-container {
  width: 65%;
  padding: 80px 0 5px 100px;
}

.login-container {
  width: 50%;
  padding: 170px 0 70px 170px;
}

.login-disabled:hover {
  background-color: #c5c5c5 !important;
}

.lpins {
  border: 1px solid #00000057;
}

.lpbb {
  border-bottom: 8px solid #4a67ff;
}

.not-sure-container {
  padding: 100px 266px;
}

.investorLookup-container {
  padding: 0 250px 100px;
}

.bsflp {
  box-shadow: 0 4px 54px #07070726;
}

.fsi {
  font-style: italic;
}

.main-banner-container {
  padding: 0 100px 0 150px;
}

.lead-report-switch .css-1xvpzln-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #50ee00;
}

.how-it-works {
  list-style-type: disclosure-closed;
}

.how-it-works li::marker {
  color: #fc7785;
}

.shadowPaperCrasouel {
  padding: 0 !important;
}

.custom-list li .df img {
  width: 22px;
}

.pricing-container .pricing-box {
  width: 22%;
  margin-left: 15px;
  margin-right: 15px;
}

.pricing-container .pricing-box.mr5rem {
  margin-right: 150px;
}

.comapnies-page-font .pricing-container .pricing-box.mr5rem {
  margin-right: 15px;
}

.pricing-title .left-text-for {
  padding-left: 3.8rem;
}

.pricing-title .right-text-for {
  padding-right: 11rem;
}

.vcs-page-font .pricing-title .right-text-for, .pes-page-font .pricing-title .right-text-for {
  padding-right: 6rem;
}

.angelsnetwork-page-font .pricing-title .right-text-for, .angels-page-font .pricing-title .right-text-for {
  padding-right: 8rem;
}

.carousel.carousel-slider .df .h60 {
  width: 190px !important;
}

.how-its-work-circle {
  width: 43px !important;
  height: 43px !important;
}

.pricing-container .pricing-box .df.fdc h1 {
  margin-bottom: 5px;
  line-height: 1.2rem !important;
}

.work-card-box {
  width: 30%;
  margin: 0 15px;
}

.poppins-font-style .inner-container .shadowContainer {
  zoom: .8;
  letter-spacing: 1px;
  border-radius: 40px;
  padding: 33px;
}

.poppins-font-style .inner-container.style-two .shadowContainer {
  border-radius: 25px;
}

.features-icon svg {
  width: 60px;
}

.comapnies-page-font #homeMainBanner .container:nth-child(3) .w60 {
  margin-top: 4rem;
}

.comapnies-page-font #homeMainBanner .container:nth-child(4) .w40 img {
  width: 111%;
}

.comapnies-page-font #homeMainBanner .container:nth-child(4) .w60 {
  margin-top: 4rem;
}

.for-you-text {
  padding-bottom: 23px;
  padding-left: 23%;
  padding-right: 32%;
}

.empty-text {
  text-align: center;
  max-width: 350px;
  font-size: large;
}

button.carousel-button svg {
  width: 200px;
}

.quote-area p {
  letter-spacing: 1px;
  font-size: 11px;
  font-weight: 300;
  line-height: 1.7;
}

.quote-icon img {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}

.glow-on-hover.glowing {
  background-image: -webkit-linear-gradient(top left, #fff3 0% 37%, #fffc 45%, #fff0 50%);
  background-position: -120px -120px, 0 0;
  background-repeat: no-repeat;
  background-image: -moz-linear-gradient(0 0, #fff3 0%, #fff3 37%, #fffc 45%, #fff0 50%);
  background-image: -o-linear-gradient(0 0, #fff3 0%, #fff3 37%, #fffc 45%, #fff0 50%);
  background-image: linear-gradient(0 0, #fff3 0%, #fff3 37%, #fffc 45%, #fff0 50%);
  -moz-background-size: 250% 250%, 100% 100%;
  -o-transition: background-position 0s ease;
  background-size: 250% 250%, 100% 100%;
  transition: background-position;
  position: relative;
}

.glow-on-hover.glowing:hover {
  background-position: 0 0, 0 0;
  transition-duration: .5s;
}

#homeMainBanner .container {
  max-width: 1400px !important;
}

#homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem {
  width: 300px;
}

#homeMainBanner .container:nth-child(3) .w60 .fs1-5rem {
  width: 400px;
}

#homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem {
  width: 176px;
}

#homeMainBanner .container:nth-child(8) .w60 .fs1-5rem {
  width: 166px;
}

#homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem {
  width: 209px;
}

#homeMainBanner .container:nth-child(13) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem {
  width: 343px;
}

#homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem {
  width: 248px;
}

.company-searchbox {
  min-height: 325px;
}

#homeMainBanner .container:nth-child(4) .w50 .pl3rem.pt1rem {
  width: 370px;
}

#homeMainBanner .container:nth-child(5) .w50 .custom-list li:last-child {
  padding-right: 58px;
}

#homeMainBanner .container:nth-child(8) .w50 .custom-list li:nth-child(3), #homeMainBanner .container:nth-child(8) .w50 .custom-list li:nth-child(2), #homeMainBanner .container:nth-child(13) .w50 .custom-list li:nth-child(2), #homeMainBanner .container:nth-child(14) .w50 .custom-list li:nth-child(2), #homeMainBanner .container:nth-child(14) .w50 .custom-list li:nth-child(3) {
  width: 350px;
}

#homeMainBanner .container:nth-child(12) .w50 .custom-list {
  width: 367px;
}

#homeMainBanner .container:nth-child(14) .w50 .custom-list li:first-child {
  width: 400px;
}

.company-searchbox .verify-btn {
  border-radius: 0 7px 7px 0;
  padding: 14px 16px;
  top: 10.5px;
  right: 3px;
}

.company-searchbox .verify-btn.mr6rem {
  border-radius: 7px 0 0 7px;
  margin-right: 0;
  right: 108px;
}

.home-banner-right-side-zak {
  width: 120px;
  top: 0;
  left: 0;
}

.carousal-crical-top {
  top: -28px;
  left: -1%;
  width: 5% !important;
}

#homeMainBanner .container {
  max-width: 100% !important;
  padding-left: 17.4% !important;
  padding-right: 17.4% !important;
}

.accounts-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem {
  width: 176px;
}

.privacy-policy-page .policy-box {
  padding: 0 90px 90px;
}

.team-sec {
  padding: 5rem 12rem 1rem;
}

.action-sec {
  padding: 7rem 17rem 7rem 7rem !important;
}

#homeMainBanner .container .testmonial-user {
  margin-top: -9px !important;
}

.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user {
  margin-top: 2px !important;
}

.comapnies-page-font #homeMainBanner .container:nth-child(5) .w40 img {
  width: 109%;
}

.comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user {
  margin-top: 2px !important;
}

.comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user {
  margin-top: -33px !important;
}

.comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user {
  margin-top: 5px !important;
}

.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user {
  margin-top: -33px !important;
}

.comapnies-page-font #homeMainBanner .container:nth-child(7) .w40 img, .comapnies-page-font #homeMainBanner .container:nth-child(10) .w40 img, .comapnies-page-font #homeMainBanner .container:nth-child(14) .w40 img, .accounts-page-font #homeMainBanner .container:nth-child(2) .w40 img, .accounts-page-font #homeMainBanner .container:nth-child(7) .w40 img, .accounts-page-font #homeMainBanner .container:nth-child(10) .w40 img, .accounts-page-font #homeMainBanner .container:nth-child(12) .w40 img, .accounts-page-font #homeMainBanner .container:nth-child(14) .w40 img, .lawyers-page-font #homeMainBanner .container:nth-child(2) .w40 img, .lawyers-page-font #homeMainBanner .container:nth-child(7) .w40 img, .lawyers-page-font #homeMainBanner .container:nth-child(10) .w40 img, .lawyers-page-font #homeMainBanner .container:nth-child(12) .w40 img, .lawyers-page-font #homeMainBanner .container:nth-child(14) .w40 img, .vcs-page-font #homeMainBanner .container:nth-child(6) .w40 img, .vcs-page-font #homeMainBanner .container:nth-child(9) .w40 img, .vcs-page-font #homeMainBanner .container:nth-child(14) .w40 img, .pes-page-font #homeMainBanner .container:nth-child(6) .w40 img, .pes-page-font #homeMainBanner .container:nth-child(9) .w40 img, .pes-page-font #homeMainBanner .container:nth-child(14) .w40 img, .angelsnetwork-page-font #homeMainBanner .container:nth-child(6) .w40 img, .angels-page-font #homeMainBanner .container:nth-child(6) .w40 img, .angels-page-font #homeMainBanner .container:nth-child(9) .w40 img {
  width: 110%;
}

.comapnies-page-font #homeMainBanner .container:nth-child(13) .w40 img, .vcs-page-font #homeMainBanner .container:nth-child(8) .w40 img, .pes-page-font #homeMainBanner .container:nth-child(8) .w40 img, .angelsnetwork-page-font #homeMainBanner .container:nth-child(8) .w40 img, .angels-page-font #homeMainBanner .container:nth-child(8) .w40 img, .angels-page-font #homeMainBanner .container:nth-child(7) .w40 img {
  width: 105%;
}

.comapnies-page-font #homeMainBanner .container:nth-child(10) .w60, .accounts-page-font #homeMainBanner .container:nth-child(3) .w60, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60, .angels-page-font #homeMainBanner .container:nth-child(8) .w60 {
  margin-top: 2rem;
}

.vcs-page-font #homeMainBanner .container:nth-child(2) .w40 img, .pes-page-font #homeMainBanner .container:nth-child(2) .w40 img, .angelsnetwork-page-font #homeMainBanner .container:nth-child(2) .w40 img, .angels-page-font #homeMainBanner .container:nth-child(2) .w40 img {
  width: 115%;
}

.vcs-page-font #homeMainBanner .container:nth-child(10) .w60, .vcs-page-font #homeMainBanner .container:nth-child(3) .w60, .pes-page-font #homeMainBanner .container:nth-child(10) .w60, .pes-page-font #homeMainBanner .container:nth-child(3) .w60, .angelsnetwork-page-font #homeMainBanner .container:nth-child(10) .w60, .angelsnetwork-page-font #homeMainBanner .container:nth-child(3) .w60, .angels-page-font #homeMainBanner .container:nth-child(10) .w60, .angels-page-font #homeMainBanner .container:nth-child(3) .w60, .angelsnetwork-page-font #homeMainBanner .container:nth-child(11) .w60 {
  margin-top: 2rem;
}

.angelsnetwork-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem {
  width: 170px;
}

.angelsnetwork-page-font #homeMainBanner .container:nth-child(5) .w40 img {
  width: 120%;
  margin-top: 3rem;
}

.angelsnetwork-page-font #homeMainBanner .container:nth-child(14) .w40 img {
  width: 120%;
}

.angelsnetwork-page-font #homeMainBanner .container:nth-child(7) .w40 img {
  width: 115%;
  margin-top: 3rem;
}

.angelsnetwork-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem {
  width: 250px;
}

.vcs-page-font .work-container, .pes-page-font .work-container, .angelsnetwork-page-font .work-container, .angels-page-font .work-container {
  max-width: 1400px;
}

.beta-logo {
  width: 90px;
  height: 50px;
  margin-top: 11px;
}

.automated-sec .fs2-2rem {
  font-size: 35px;
  line-height: 48px;
}

.certificates-page .certificates-current .packge-select img.w70px {
  width: 120px;
}

#homeMainBanner .container:nth-child(odd) .w60 {
  padding-left: 7rem;
}

.accounts-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem {
  width: 240px;
}

.review-img img {
  width: 80% !important;
  height: auto !important;
}

.client-review-container p {
  letter-spacing: 1px;
  font-size: 20px;
  line-height: 34px !important;
}

.client-review-container .fs2-125rem {
  font-size: 50px;
}

.investmentcertificate-page .certificate-area .right-area .certificate-fail-pass {
  position: absolute;
}

.investmentcertificate-page .certificate-area .right-area .toparea {
  top: 27px;
}

.investmentcertificate-page .certificate-area .right-area .bottom-area {
  bottom: 6px;
}

.dashbaord-certficate-page .w250px {
  width: 120px;
}

.dashbaord-certficate-page .certificate-fail-pass {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.dashbaord-certficate-page .certificate-fail-pass .toparea.pa {
  top: 22px;
}

.dashbaord-certficate-page .certificate-fail-pass .bottom-area {
  bottom: 3px;
}

.progress-area-report .certification-area {
  margin-bottom: 15px;
}

.progress-area-report .certification-area .w250px {
  width: 130px;
}

.progress-area-report .certification-area .certificate-fail-pass {
  position: absolute;
}

.progress-area-report .certification-area .certificate-fail-pass .toparea {
  top: 21px;
}

.progress-area-report .certification-area .certificate-fail-pass .toparea p {
  margin-bottom: -6px;
}

.progress-area-report .certification-area .certificate-fail-pass .bottom-area {
  bottom: 2px;
}

.tooltip-box .tooltip-area .overlay-box .pricing-box img {
  height: 100%;
}

.tooltip-box .tooltip-area .overlay-box .pricing-box .small-img {
  height: 120px;
}

.pricing-tooltbox .tooltip-area {
  zoom: .6;
}

#homeMainBanner .container:nth-child(12) .w60 .custom-list, #homeMainBanner .container:nth-child(14) .w60 .custom-list {
  width: 500px;
}

.calcluter-sec .fs2rem {
  font-size: 33px;
}

.calcluter-sec .fs2-5rem {
  font-size: 44px;
}

.carousel .slide .fs2-125rem {
  font-size: 40px;
}

.investor-box .left-certificate-area {
  background-image: url("investor-bg.db3fac81.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.investor-box .left-certificate-area .round-box {
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
}

.investor-box .list-right-area .benefits-box {
  min-height: 291px;
}

.investor-box .custom-list li {
  font-size: 18px;
}

.w35 {
  width: 35%;
}

.bo28 {
  border-color: #00000034;
}

.step-box-modal .step-box-box-1 {
  width: 100%;
  padding: 0 10px;
}

.step-box-modal .step-box-box-2 {
  width: 37%;
  padding: 0 10px;
}

.step-box-modal.style-two {
  padding: 0 61px;
}

.step-box-modal.style-two .step-box-box-1 {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.form-modal-steps {
  background-image: url("form-bg.59f9f7d3.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-modal-steps .dropdown-box {
  z-index: 99;
  width: 97%;
  padding: 10px;
  top: 47px;
  left: -4px;
}

.form-modal-steps .dropdown-box .cp.df.fww {
  border-bottom: 1px solid #32325d40;
}

.register-container .pt0-5rem.pb0-5rem svg {
  transform: translateY(-50%);
  top: 50% !important;
}

.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user {
  margin-top: -19px !important;
}

.register-container .password-group input, .register-page-container .password-group input, .login-container .password-group input {
  padding: 1rem 1rem 1rem 3rem;
}

.register-container .password-group .MuiInputBase-root, .register-page-container .password-group .MuiInputBase-root, .login-container .password-group .MuiInputBase-root {
  background-color: #fff;
  border-radius: 11px;
}

.register-container .password-group .MuiFormControl-root, .register-page-container .password-group .MuiFormControl-root, .login-container .password-group .MuiFormControl-root {
  position: relative;
}

.register-container .password-group .MuiFormControl-root p, .register-page-container .password-group .MuiFormControl-root p, .login-container .password-group .MuiFormControl-root p {
  font-size: 11px;
  line-height: 13px;
  position: absolute;
  bottom: -27px;
  left: -9px;
}

.login-container input {
  padding: 1rem 1rem 1rem 3rem;
}

.login-container .MuiInputBase-root {
  background-color: #fff;
  border-radius: 11px;
}

.login-container .MuiFormControl-root {
  position: relative;
}

.login-container .MuiFormControl-root p {
  font-size: 11px;
  line-height: 13px;
  position: absolute;
  bottom: -20px;
  left: -9px;
}

.search-center-btn {
  justify-content: center;
}

.investor-back-btn {
  margin: 0;
}

.two-min-invest-image {
  text-align: right;
}

.two-min-invest-image img {
  width: 250px;
}

.certificates-page .packge-select .certificate-audited {
  margin-top: 2rem;
}

.certificates-page .valid-date {
  bottom: 12px;
}

.dashbaord-certficate-page .certificate-audited {
  margin-top: 1.3rem;
}

.dashbaord-certficate-page .certificate-audited p {
  font-size: 7px;
}

.appstor-heading {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  top: -71px;
  left: 10px;
}

.certifcate-box-pass-fail {
  top: 13px;
  right: 6px;
}

.certifcate-box-pass-fail img {
  width: 39px;
}

.button-groups-new .module-tooltip-box .tooltip-area .overlay-box {
  width: 100% !important;
}

.keyfeautre-container {
  box-sizing: border-box;
  width: 1600px;
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.keyfeature-box-free {
  background-color: #f8f9fe;
  border: 1px solid #d6dcee;
}

.keyfeature-box-portfolio {
  background-color: #f0f2fdcc;
  border: 1px solid #7385be50;
}

.keyfeature-box-core {
  background-color: #f0f2fd;
  border: 1px solid #13c33a;
}

.keyfeature-box-core-pro {
  background-color: #e3e8ff;
  border: 1px solid #13c33a;
}

.keyfeature-box-appstore {
  background-color: #e3e8ff;
  border: 1px solid #f58da7;
}

.keyfeature-box-portfolio-innner:before {
  content: "";
  background-color: #cbd5ff;
  border-radius: 200px 50px 50px 200px;
  width: 90%;
  height: 96%;
  position: absolute;
  top: 48%;
  left: 50px;
  transform: translateY(-50%);
}

.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before {
  content: "";
  background-color: #f0f2fd;
  border-radius: 200px 50px 50px 200px;
  width: 88%;
  height: 81%;
  position: absolute;
  top: 49%;
  left: 80px;
  transform: translateY(-50%);
}

.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
  margin: 0 28px 30px;
  width: 28% !important;
}

.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .seemore-box {
  margin-top: 80px;
  position: absolute;
}

.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(5) {
  margin-right: 28px;
}

.keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(2) {
  margin-left: 28px;
}

.for-yoy-box {
  background-color: #767b90;
  margin-bottom: -13px;
  padding-top: .65rem;
  padding-bottom: .65rem;
}

.accounts-page-font .keyfeature-box-portfolio-innner:before, .lawyers-page-font .keyfeature-box-portfolio-innner:before {
  background-color: #fff6a6;
}

.accounts-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3), .lawyers-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3) {
  background-color: #fffbd3;
  border: 1px solid #f9e84b;
}

.accounts-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3):before, .lawyers-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3):before, .keyfeature-box .keyfeature-wihte-item.bgyellow:before {
  border-top-color: #fffbd3;
}

.vcs-page-font .keyfeature-box-portfolio-innner:before, .pes-page-font .keyfeature-box-portfolio-innner:before, .angelsnetwork-page-font .keyfeature-box-portfolio-innner:before, .angels-page-font .keyfeature-box-portfolio-innner:before {
  background-color: #aafed680;
}

.vcs-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3), .pes-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3), .angelsnetwork-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3), .angels-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3) {
  background-color: #e3fff2;
  border: 1px solid #4deb9f;
}

.vcs-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3):before, .pes-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3):before, .angelsnetwork-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3):before, .angels-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3):before {
  border-top-color: #e3fff2;
}

.keyfeature-box-portfolio .seemore-box {
  width: 100%;
  margin-top: 15px;
}

.keyfeature-box .keyfeature-wihte-item {
  width: 19.1%;
  margin: 0 12px 30px;
}

.keyfeature-box .keyfeature-wihte-item .features-icon {
  width: 100%;
}

.keyfeature-box .keyfeature-wihte-item .features-icon svg {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.keyfeature-box .keyfeature-wihte-item:nth-child(5) {
  margin-right: 0;
}

.keyfeature-box .keyfeature-wihte-item:nth-child(2) {
  margin-left: 0;
}

.keyfeature-box .keyfeature-wihte-item:nth-child(7) {
  margin-right: 0;
}

.keyfeature-box .keyfeature-wihte-item:nth-child(8) {
  margin-left: 0;
}

.keyfeature-box .keyfeature-wihte-item .fs1rem.c15 {
  font-size: 15px;
}

.keyfeature-box .keyfeature-wihte-item .seemore-box {
  z-index: 99;
  width: 1278px;
  position: absolute;
  top: 149px;
  left: 0;
}

.keyfeature-box .seemore-inner {
  zoom: .8;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px;
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.keyfeature-box .seemore-inner:hover .seemore-box {
  opacity: 1;
  visibility: visible;
}

.keyfeature-box-portfolio-bg .keyfeature-wihte-item .seemore-box {
  width: 800px;
  top: 100px;
}

.work-card-box .df.jce.pr img {
  position: relative;
  top: 22px;
}

.pricing-tabs .pricing-advisor-table {
  flex-wrap: wrap;
  display: flex;
}

.pricing-tabs .pricing-advisor-table .pricing-box {
  width: 21%;
}

.pricing-tabs .booking-sec {
  z-index: 3;
  width: 114%;
  margin-top: 9px;
  margin-bottom: -86px !important;
  padding: 0 !important;
}

.pricing-tabs .booking-sec .w30 button.bg4 {
  border-radius: 0 0 15px 15px;
  height: 60px;
}

.pricing-tabs .booking-sec .pricing-bottom-box p {
  font-size: 13px;
}

.pricing-table-saf {
  width: 1290px;
}

.disclaimer-modal .header-area {
  background: linear-gradient(315deg, #ffa733 0%, #ffc04b 50%, #f6cf6c 100%);
  border-radius: 10px 10px 0 0;
}

.work-card-box .df.jce.pr svg {
  width: 45px;
  height: 68px;
}

.work-card-box .df.jce.pr img {
  width: auto;
  height: 52px;
}

.accounts-page-font .video-container img.pa, .accounts-page-font .video-container img.w10.pa, .lawyers-page-font .video-container img.pa, .lawyers-page-font .video-container img.w10.pa, .vcs-page-font .video-container img.pa, .vcs-page-font .video-container img.w10.pa, .pes-page-font .video-container img.pa, .pes-page-font .video-container img.w10.pa {
  display: none;
}

.pes-page-font .review-img img {
  width: 79% !important;
}

.top-pricing-area {
  filter: drop-shadow(0 0 13px #32325d20);
  width: 91%;
  margin: 0 auto;
  top: -92px;
}

.pricing-inner {
  margin-top: -83px;
}

.tabs-portfolio .pt10rem {
  padding-top: 2rem;
}

.tabs-portfolio .pt10rem .df.jcsb.w100.mb2rem.px4rem {
  margin-bottom: 8rem;
}

.pricing-tabs .booking-sec .pricing-bottom-box {
  zoom: .8;
  min-height: 163px;
}

.about-us-page {
  overflow: hidden;
}

.lisn {
  list-style: none;
}

.banner-advisor-dashboard {
  background-color: #0000;
}

.banner-advisor-dashboard:before {
  content: "";
  border-radius: 24px;
  width: 95%;
  height: 104%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.banner-advisor-dashboard.investor-bg:before {
  background-color: #aafed680;
}

.tabs-navlist .MuiTabs-root {
  border: 1px solid #4a67ff;
  border-radius: 39px;
}

.tabs-navlist .MuiTabs-root .MuiTabs-flexContainer button {
  color: #4a67ff;
  text-transform: capitalize;
  min-width: 243px;
  font-size: 17px;
  font-weight: 600;
  transition: all .3s ease-in-out;
  padding: 13px !important;
}

.tabs-navlist .MuiTabs-root .MuiTabs-flexContainer button:nth-child(2), .tabs-navlist .MuiTabs-root .MuiTabs-flexContainer button:nth-child(3) {
  border-left: 1px solid;
  border-right: 1px solid;
}

.tabs-navlist .MuiTabs-root .MuiTabs-flexContainer button:last-child {
  border: none;
}

.tabs-navlist .MuiTabs-root .MuiTabs-flexContainer button:hover, .tabs-navlist .MuiTabs-root .MuiTabs-flexContainer button.Mui-selected {
  color: #fff;
  background: linear-gradient(185deg, #3956f0 35%, #8496f4 100%);
}

.tabs-navlist .MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator {
  display: none !important;
}

.certificate-area-dashboard .certificate-audited {
  margin-top: 1.5rem;
}

.certificate-company-settings .certificate-audited {
  width: 70% !important;
}

.banner-advisor-dashboard.advisor-bg:before {
  background-color: #fff6a680;
}

.dahboard-tabs .MuiTabPanel-root {
  box-sizing: border-box;
  width: 98%;
  margin: 0 auto;
  padding: 0;
}

.dashboard-container {
  width: 86%;
  margin: 0 auto;
}

.box-dashbord-container .keyfeature-box-portfolio .keyfeature-box-portfolio-innner:before {
  display: none;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item {
  border: 1px solid #b5bfde;
  min-height: 200px;
  margin: 0 15px 40px;
  padding-bottom: 1.4rem;
  transition: all .3s ease-in-out;
}

.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box.keyfeature-box-anylist .keyfeature-wihte-item {
  border: 1px solid #2cff28;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:hover, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item:hover {
  box-shadow: 0 0 15px #32325d40;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details {
  align-items: end;
  display: flex;
  position: absolute;
  bottom: 13px;
}

.icon-diligenstia svg {
  width: 40px;
  height: 40px;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details p {
  font-size: 14px;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-bottom-area, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-bottom-area, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area {
  align-items: center;
  display: flex;
  position: absolute;
  bottom: -19px;
  right: -8px;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .hover-list, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .hover-list {
  position: absolute;
  top: -11px;
  left: -8px;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:before, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item:before {
  display: none;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item h2, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item h2 {
  line-height: 1.5rem;
}

.box-dashbord-container .keyfeature-box-portfolio-innner {
  width: 100%;
}

.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box-core-pro {
  background-color: #f7f8fe;
}

.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box-appstore {
  background-color: #f0f2fd;
}

.dahboard-tabs.investor-bg .box-dashbord-container .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3) {
  background-color: #e3fff2;
  border-color: #4deb9f;
}

.dahboard-tabs.advisor-bg .box-dashbord-container .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3) {
  background-color: #fffbd3;
  border-color: #f9e84b;
}

.dahboard-tabs {
  background-color: #0000 !important;
}

.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item {
  width: 22.5%;
}

.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item h4, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item h4 {
  font-size: 16px;
}

.dahboard-tabs .MuiTabPanel-root .dashboard-firm-tab {
  width: 95%;
  margin: 0 auto;
}

.dahboard-tabs .MuiTabPanel-root .dashboard-firm-tab .box-dashbord-container .keyfeature-box-core-dashboard {
  background-color: #0000;
  border: none;
}

.dahboard-tabs .MuiTabPanel-root .dashboard-firm-tab .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box {
  background-color: #f0f2fd50;
  border-color: #7385be50;
}

.dahboard-tabs .MuiTabPanel-root .dashboard-firm-tab .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item {
  width: 23%;
  min-height: 178px;
}

.dahboard-tabs .MuiTabPanel-root .dashboard-firm-tab .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box-workplace {
  background-color: #0000;
  border: none;
  padding: 0;
}

.dahboard-tabs .MuiTabPanel-root .dashboard-firm-tab .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box-workplace .keyfeature-wihte-item .report-btn {
  inset: -15px auto auto 38px;
}

.dahboard-tabs .MuiTabPanel-root .add-btn-dashboard {
  border-color: #dbdfee;
  padding: 5px 1rem 5px 5px;
  font-size: 14px;
  transition: all .3s ease-in-out;
}

.dahboard-tabs .MuiTabPanel-root .add-btn-dashboard:hover {
  box-shadow: 0 0 13px #32325d20;
}

.dahboard-tabs .MuiTabPanel-root .add-btn-dashboard.add-btn-dashboard-switch {
  padding: 5px 1rem;
}

.dahboard-tabs .MuiTabPanel-root .add-btn-dashboard.add-btn-dashboard-switch .MuiSwitch-root {
  width: 70px;
}

.dahboard-tabs .MuiTabPanel-root .add-btn-dashboard.add-btn-dashboard-switch .MuiSwitch-root .MuiButtonBase-root {
  color: #fff;
  left: 5px;
}

.dahboard-tabs .MuiTabPanel-root .add-btn-dashboard.add-btn-dashboard-switch .MuiSwitch-root .MuiSwitch-track {
  opacity: .2;
  border-radius: 44px;
  height: 25px;
}

.dahboard-tabs .MuiTabPanel-root .add-btn-dashboard.add-btn-dashboard-switch .MuiSwitch-root .Mui-checked + .MuiSwitch-track {
  opacity: 1;
  background: linear-gradient(185deg, #3956f0 35%, #8496f4 100%);
}

.dropdown-company-dahboard .reprot-btn-dropdowns .MuiFormControl-root {
  width: 100%;
  min-width: 100%;
}

.dropdown-company-dahboard .reprot-btn-dropdowns .MuiFormControl-root label {
  margin: -4px 0 0 14px;
}

.dropdown-company-dahboard .reprot-btn-dropdowns .MuiFormControl-root .MuiInputBase-root {
  background-color: #0000 !important;
}

.dropdown-company-dahboard .reprot-btn-dropdowns .MuiFormControl-root .MuiInputBase-root:before {
  display: none;
}

.dropdown-company-dahboard .reprot-btn-dropdowns .MuiFormControl-root .MuiInputBase-root .MuiSelect-select {
  background-color: #fff !important;
  border: 1px solid #dbdfee !important;
  border-radius: 35px !important;
  padding: 11px 32px 9px 11px !important;
}

.reprot-btn-dropdowns .Mui-focused:after, .dropdown-company-dahboard .reprot-btn-dropdowns .MuiFormControl-root .MuiInputBase-root:after {
  display: none;
}

.dahboard-tabs .MuiTabPanel-root .progress-box .progress-bar {
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #f1f1f1;
  border-radius: 50%;
  margin: 0 auto;
  padding: 5px;
  position: relative;
}

.progress-box-vertical .progress-bar-vertical {
  background-color: #0000;
  background-image: url("progress-bar-bg.8cb03876.png");
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
  height: 17px;
}

.progress-box-vertical .progress-bar-vertical .progress-bar {
  background-color: #0000;
  background-image: url("progress-bar-active-bg.336c9b67.png");
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
  height: 17px;
}

.progress-box-vertical .progress-bar-vertical.style-two .progress-bar {
  background-image: url("progress-bar-active-bg2.115d4187.png");
}

.progress-box-vertical .progress-bar-vertical .progress-bar .precentage-block .icon {
  filter: drop-shadow(0 0 3px #32325d40);
  background-color: #0000;
  border-radius: 0;
  padding: 0;
}

.company-details-area .certficate-area .certficate-box {
  zoom: .7;
}

.table-advisor {
  background-color: #f0f2fd50;
  border: 1px solid #f0f2fd;
  border-radius: 30px;
  padding: 2rem;
}

.table-advisor thead th {
  text-align: center;
  border-bottom: 1px solid #b5bfde;
  width: auto;
  line-height: 4rem;
  background: none !important;
}

.table-advisor tr:nth-child(2n), .table-advisor tr > :nth-child(2n) {
  background-color: #0000;
}

.table-advisor th, .table-advisor td {
  text-align: center;
  width: auto;
}

.table-advisor button {
  background-color: #0000;
  border: none;
}

.bottom-support .upgrading-pro svg.bg1 {
  border-radius: 50%;
  margin-top: -32px;
  margin-bottom: 6px;
}

.bottom-support .upgrading-pro button {
  margin-top: 15px;
  padding: 8px 22px;
  font-size: 12px;
}

#expandHeader .upgrading-pro {
  text-align: left;
  background-color: #0000;
}

#expandHeader .upgrading-pro svg.bg1 {
  fill: #fff;
  background-color: #fc7785;
  width: 32px;
  height: 32px;
  margin-left: -15px;
  padding: 5px;
}

#expandHeader .upgrading-pro svg.bg1 path {
  fill: #fff;
  stroke: #fff;
}

#expandHeader .upgrading-pro h4, #expandHeader .upgrading-pro p, #expandHeader .upgrading-pro button {
  display: none;
}

#expandHeader:hover .upgrading-pro h4, #expandHeader:hover .upgrading-pro p, #expandHeader:hover .upgrading-pro button {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

#expandHeader:hover .upgrading-pro {
  text-align: center;
  background-color: #f0f2fd;
}

#expandHeader:hover .upgrading-pro svg.bg1 {
  fill: #4a67ff;
  background-color: #fff;
  width: 3rem;
  height: 3rem;
  margin-left: auto;
  padding: 1rem;
}

#expandHeader:hover .upgrading-pro svg.bg1 path {
  fill: #4a67ff;
  stroke: #4a67ff;
}

#expandHeader #logo {
  width: 6rem;
  margin-bottom: 1rem;
}

#expandHeader:hover #logo {
  width: 10rem;
  margin-bottom: 0;
}

#expandHeader .h90vh {
  height: 88vh;
}

.box-dashbord-container .keyfeature-box .keyfeature-wihte-item:nth-child(2) {
  margin-left: 12px;
}

#expandHeader .pr.df a:hover svg path, #expandHeader .pr.df span.usn:hover svg path {
  fill: #4a67ff;
}

#expandHeader .pr.df a:hover svg, #expandHeader .pr.df span.usn:hover svg {
  left: 4px;
}

.certificate-area-dashboard .certificate-fail-pass {
  position: absolute;
}

.certificate-area-dashboard .certificate-fail-pass .toparea {
  line-height: 6px;
  top: 20px;
}

.certificate-area-dashboard .certificate-fail-pass .bottom-area {
  bottom: 3px;
}

.banner-advisor-dashboard .client-tab {
  padding-bottom: 1.4rem;
}

.dahboard-tabs .MuiTabPanel-root.free-tools .dashboard-tools-area {
  width: 97%;
  margin: 0 auto;
}

.table-permission .table-userPermissions.table-leades thead tr th, .table-permission .table-userPermissions.table-leades tbody tr td {
  min-width: 113px !important;
}

.table-permission .table-userPermissions.table-leades .compnay-name {
  min-width: 200px !important;
}

.table-permission .table-userPermissions.table-leades .fit-score, .table-permission .table-userPermissions.table-leades .fit-report {
  min-width: 77px !important;
}

.table-permission .table-userPermissions.table-leades .allocated-member {
  min-width: 95px !important;
}

.table-permission .table-userPermissions.table-leades .allocated-member .MuiInputBase-root {
  width: 166px;
  border: none !important;
}

.table-permission .table-userPermissions.table-leades .allocated-member .MuiInputBase-root fieldset {
  border: none;
}

.table-permission .table-userPermissions.table-leades .allocated-member .MuiInputBase-root .MuiSelect-select {
  border: 1px solid #dbdfee;
  font-size: 13px;
  background-color: #fff !important;
  border-bottom: 1px solid #dbdfee !important;
  border-radius: 25px !important;
}

.table-permission .table-userPermissions.table-leades .accepted-rejected {
  min-width: 100px !important;
}

.table-permission .table-userPermissions.table-leades tbody tr td {
  text-align: center;
}

.table-permission .table-userPermissions.table-leades tbody tr td img {
  margin: 0 auto;
}

.dropdown-company-dahboard.leads-page .progress-area-report, .dropdown-company-dahboard.leads-page .progress-area-report .progress-box {
  margin: 0 !important;
}

.advisor-box-content::-webkit-scrollbar {
  border-radius: 6px;
  width: 6px;
}

.advisor-box-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

.advisor-box-content::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 6px;
}

.advisor-box-content::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

.center-logo-area .w9rem.h9rem {
  background-color: #f5f6f930;
}

.center-logo-area .input-file {
  cursor: pointer;
  z-index: 2;
  background: none;
  border: none;
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 1px;
  right: -30px;
}

.center-logo-area .input-file .add-btn {
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  padding: 5px;
  line-height: 1.8rem;
  position: absolute;
  top: 7px;
  left: -12px;
  box-shadow: 0 0 5px #0003;
}

.center-logo-area .input-file .add-btn svg {
  opacity: .9;
  fill: #748aff;
  width: 17px;
}

.center-logo-web .center-logo-area {
  margin-left: -45px;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .hover-list-icon svg, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .hover-list-icon svg {
  width: 1.7rem;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .questionmark-icon .overlay-box, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .questionmark-icon .overlay-box, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-btn .overlay-box, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-btn .overlay-box, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box, .icon-diligenstia .activedeactive-area .overlay-box, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .questionmark-icon .overlay-box, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn .overlay-box, .add-client-area .right-info-hyper-icon .hover-list-icon .overlay-box {
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  background: linear-gradient(185deg, #fff27c 75%, #e8bb1f 100%);
  border-radius: 7px;
  min-width: 83px;
  padding: 5px 7px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: -47px;
  left: 50%;
  transform: translateX(-50%);
}

.show-tooltip {
  opacity: .8 !important;
  visibility: visible !important;
}

.right-info-hyper-icon {
  align-items: center;
  display: flex;
  position: absolute;
  top: -14px;
  right: -6px;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box, .add-client-area .right-info-hyper-icon .hover-list-icon .overlay-box {
  min-width: 126px;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .questionmark-icon .overlay-box p, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .questionmark-icon .overlay-box p, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-btn .overlay-box p, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-btn .overlay-box p, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box p, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box p, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box p, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box p, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box p, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .questionmark-icon .overlay-box p, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn .overlay-box p, .add-client-area .right-info-hyper-icon .hover-list-icon .overlay-box p {
  font-size: 11px;
}

.icon-diligenstia .activedeactive-area .overlay-box p {
  justify-content: center;
  font-size: 11px;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .questionmark-icon:hover .overlay-box, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .questionmark-icon:hover .overlay-box, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-btn:hover .overlay-box, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-btn:hover .overlay-box, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .info-icon:hover .overlay-box, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .info-icon:hover .overlay-box, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon:hover .overlay-box, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon:hover .overlay-box, .icon-diligenstia .activedeactive-area:hover .overlay-box, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .info-icon:hover .overlay-box, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon:hover .overlay-box, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .questionmark-icon:hover .overlay-box, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn:hover .overlay-box, .add-client-area .right-info-hyper-icon .hover-list-icon:hover .overlay-box {
  opacity: 1;
  visibility: visible;
  z-index: 999;
  background: linear-gradient(185deg, #fcf4a3 75%, #f1da77 100%);
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .questionmark-icon .overlay-box:before, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .questionmark-icon .overlay-box:before, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-btn .overlay-box:before, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-btn .overlay-box:before, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box:before, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box:before, .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box:before, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box:before, .icon-diligenstia .activedeactive-area .overlay-box:before, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box:before, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box:before, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .questionmark-icon .overlay-box:before, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn .overlay-box:before, .add-client-area .right-info-hyper-icon .hover-list-icon .overlay-box:before {
  content: "";
  clip-path: polygon(0% 0, 100% 0%, 46% 100%);
  background-color: #fff27c;
  background-image: linear-gradient(185deg, #e8bb1f80 75%, #fff27c 100%);
  width: 19px;
  height: 12px;
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}

.remove-edit-area .edit-remove {
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.step-box-images .step-img img {
  backdrop-filter: drop-shadow(0 0 13px #32325d20);
}

.add-client-area .right-info-hyper-icon {
  top: -5px;
  right: 15px;
}

.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box {
  min-width: 135px;
}

.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .questionmark-icon .overlay-box, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .questionmark-icon .overlay-box {
  min-width: 203px;
  top: -62px;
  left: 50%;
  transform: translateX(-50%);
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-btn .overlay-box, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-btn .overlay-box, .icon-diligenstia .activedeactive-area .overlay-box, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn .overlay-box {
  min-width: 124px;
  top: 48px;
  left: 50%;
  transform: translateX(-50%);
}

.icon-diligenstia .activedeactive-area .overlay-box {
  top: -56px;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-btn .overlay-box:before, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-btn .overlay-box:before, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn .overlay-box:before {
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  background-color: #fcf4a3;
  background-image: linear-gradient(185deg, #fcf4a3 0% 100%);
  top: -11px;
  bottom: auto;
}

.icon-diligenstia .activedeactive-area .overlay-box p .icon svg {
  width: 12px;
  height: 12px;
}

.step-top-box img {
  margin-left: 35px;
}

.company-dashboard .center-logo-area {
  margin-top: -46px;
}

.company-dashboard .center-logo-area h2 .icon {
  margin-top: -28px;
}

.company-dashboard .company-bg:before {
  background-color: #c5cfff80;
}

.company-dashboard .advisor-profile {
  color: #fff;
}

.w25 {
  width: 25%;
}

.checklist-area {
  padding: 14px 57px;
}

.checklist-area .checklist-inner .checklist-box {
  padding-left: 20px;
  font-size: 12px;
}

.checklist-area .checklist-inner .checklist-box .icon {
  top: 50%;
  transform: translateY(-50%);
}

.right-certificate-area .progress-bar-box .progress-area .progress-bar {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 50%;
  margin: 0 auto;
  padding: 3px;
  position: relative;
}

.right-certificate-area .progress-bar-box .progress-area span {
  font-size: 10px;
}

.adivsor-user-company {
  border: 2px solid #dbdfee;
  padding: 0 1rem 0 .5rem;
}

.adivsor-user-company img {
  width: 30px;
  height: 30px;
}

.company-dashboard-bg:before {
  background-color: #c5cfff80;
}

.company-tab.advisor-bg-lite {
  background-color: #fff;
}

.advisor-bg-lite:before {
  background-color: #fff6a680;
}

.investor-bg:before {
  background-color: #aafed680;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root {
  width: 94%;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-box-company-pro {
  background-color: #f0f2fd50;
}

.tabs-navlist.tabs-dashboard button {
  color: #15131360;
  background-color: #0000;
  margin: 0 15px;
  padding: 0;
  position: relative;
}

.tabs-navlist.tabs-dashboard button:before {
  content: "";
  opacity: 0;
  background-color: #2947ef;
  width: 79%;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
}

.tabs-navlist.tabs-dashboard button.active {
  color: #2947ef;
}

.tabs-navlist.tabs-dashboard button.active:before {
  opacity: 1;
}

.right-info-hyper-icon .hover-list-icon .overlay-box {
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  border-radius: 5px;
  min-width: 150px;
  padding: 5px 10px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 13px #32325d20;
}

.right-info-hyper-icon .hover-list-icon .overlay-box p {
  font-size: 13px;
}

.right-info-hyper-icon .hover-list-icon:hover .overlay-box {
  visibility: visible;
  opacity: 1;
}

.right-info-hyper-icon .questionmark-icon .overlay-box {
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  border-radius: 5px;
  min-width: 250px;
  padding: 5px 10px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: -75px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 13px #32325d20;
}

.right-info-hyper-icon .questionmark-icon .overlay-box p {
  font-size: 13px;
}

.right-info-hyper-icon .questionmark-icon:hover .overlay-box {
  visibility: visible;
  opacity: 1;
}

.right-info-hyper-icon .info-icon .overlay-box {
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  border-radius: 5px;
  min-width: 150px;
  padding: 5px 10px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 13px #32325d20;
}

.right-info-hyper-icon .info-icon .overlay-box p {
  font-size: 11px;
}

.right-info-hyper-icon .info-icon:hover .overlay-box {
  visibility: visible;
  opacity: 1;
}

.module-tooltip-box .hover-list {
  position: absolute;
  top: -8px;
  left: -8px;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item {
  border: 1px solid #b5bfde;
  width: 22.5%;
  min-height: 175px;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item:before {
  display: none;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .tooltip-area.style-two {
  bottom: 10px;
  left: 22px;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .ribbon.ribbon-bottom-right {
  position: absolute;
  bottom: 14px;
  right: 17px;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .ribbon.ribbon-bottom-right img {
  width: 101px;
  height: auto;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .all-reprots-area {
  width: 23.1%;
  min-height: 175px;
  margin: 0 12px;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .all-reprots-area .keyfeature-wihte-item {
  margin: 0 9px 0 0;
  width: 100% !important;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .ribbon.ribbon-bottom-right.trophy-cup img {
  width: 61px;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .certificate-tooltip-box {
  width: 23.1%;
  min-height: 175px;
}

.payment-modal .header-modal {
  background-size: cover;
  height: 95px;
  position: relative;
}

.payment-modal .header-modal .number-header {
  text-align: center;
  color: #151313;
  background-color: #fff;
  border: 3px solid #fc7785;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  font-size: 20px;
  font-weight: 500;
  line-height: 45px;
  position: absolute;
  top: -10px;
  left: -6px;
}

.payment-modal .body-modal .StripeElement {
  border: 1px solid #00000080;
  border-radius: 8px;
  padding: 10px;
}

.payment-modal .body-modal input {
  background-color: #ffeff0;
  border: 1px solid #fc778554;
}

.payment-modal .body-modal .btn-call-book {
  color: #15131380;
  background-color: #0000;
  background-size: 100% 100%;
  font-size: 15px;
  position: relative;
}

.payment-modal .body-modal .btn-call-book .icon {
  position: absolute;
  top: 16px;
  left: 27px;
}

.payment-modal .body-modal .access-code {
  background-color: #fff;
  border: 1px solid #00000050;
}

.lock-icon.wf-report svg {
  width: 52px;
}

.keyfeature-box .keyfeature-wihte-item .title-box-feature {
  position: relative;
  top: -6px;
}

.keyfeature-box .keyfeature-wihte-item .title-box-feature .icon {
  position: relative;
  top: 3px;
  left: -5px;
}

.keyfeature-box .keyfeature-wihte-item .title-box-feature .icon img {
  width: 20px;
}

.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details p, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details p {
  font-size: 14px;
}

.keyfeature-box-anylist {
  border-color: #4a67ff;
}

.tooltip-whatis {
  z-index: 9;
  opacity: .8;
  background: linear-gradient(185deg, #fff27c 75%, #e8bb1f 100%);
  border-radius: 7px;
  min-width: 90px;
  padding: 5px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: -42px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-whatis:before {
  content: "";
  clip-path: polygon(0% 0, 100% 0%, 46% 100%);
  background-color: #fff27c;
  background-image: linear-gradient(185deg, #e8bb1f 75%, #fff27c 100%);
  width: 19px;
  height: 12px;
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-whatis p {
  font-size: 11px;
}

.icon-diligenstia .activedeactive-area {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  top: -4px;
  left: -4px;
}

.icon-diligenstia .icon-diligenstia .activedeactive-area {
  background-color: #15d757;
}

.icon-diligenstia .active-btn {
  color: #151313;
  border-color: #151313;
}

.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item-not-active {
  border-color: #b5bfde;
}

.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item-not-active .icon-diligenstia .activedeactive-area {
  background-color: #d9d9d9;
}

.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item-not-active .active-btn {
  color: #15d757;
  border-color: #15d757;
}

.dahboard-tabs .MuiTabPanel-root .dashboard-firm-tab .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .score-btn {
  bottom: 35px;
  right: 23px;
}

.dahboard-tabs .MuiTabPanel-root .anylist-builder-tab .add-btn-dashboard {
  float: right;
  clear: both;
  justify-content: end;
  width: 145px;
  margin: 0 auto;
  position: relative;
  top: auto;
  right: auto;
}

.right-area-details .dropdown-details .dropdown-btn {
  text-align: left;
  width: 83px;
  padding: 4px 6px;
  font-size: 10px;
}

.right-area-details .dropdown-details .dropdown-btn .icon-right {
  top: 6px;
  right: 5px;
}

.right-area-details .dropdown-details .dropdown-btn .icon-right svg {
  width: 12px;
  height: 12px;
}

.right-area-details .dropdown-status .dropdown-btn {
  margin-right: 11px;
  padding: 3px 15px;
  font-size: 10px;
}

.icon-textare .icon-plus {
  background-color: #0000;
}

.task-notes-scroll {
  max-height: 30vh;
  overflow: scroll;
}

.task-notes-scroll::-webkit-scrollbar {
  width: 7px !important;
}

.notes-details .notes-box .notes-left-area {
  width: 86%;
}

.notes-details .notes-box .notes-left-area .notes-text h4 {
  font-size: 11px;
}

.notes-details .notes-box .right-area-porirty {
  width: 14%;
}

.notes-details .notes-box .right-area-porirty .dropdown-btn {
  padding: 3px 10px;
  font-size: 7px;
}

.priority-dropdown ul.dropdown-content, .status-dropdown ul.dropdown-content {
  z-index: 999;
  background: none;
  list-style-type: none;
  position: absolute;
}

.priority-dropdown ul.dropdown-content li button, .status-dropdown ul.dropdown-content li button {
  background: none;
  color: #fff !important;
}

.priority-dropdown .first-item, .status-dropdown .first-item {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.priority-dropdown .last-item, .status-dropdown .last-item {
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.priority-dropdown .grey-bg, .status-dropdown .grey-bg {
  color: #fff !important;
  background-color: #bbb !important;
  border-color: #bbb !important;
}

.priority-dropdown .sky-bg, .status-dropdown .sky-bg {
  color: #fff !important;
  background-color: #87ddec !important;
  border-color: #87ddec !important;
}

.priority-dropdown .blue-bg, .status-dropdown .blue-bg {
  color: #fff !important;
  background-color: #20a5de !important;
  border-color: #20a5de !important;
}

.priority-dropdown .yellow-bg, .status-dropdown .yellow-bg {
  color: #fff !important;
  background-color: #f1d52f !important;
  border-color: #f1d52f !important;
}

.priority-dropdown .red-bg, .status-dropdown .red-bg {
  color: #fff !important;
  background-color: #eb5a46 !important;
  border-color: #eb5a46 !important;
}

.priority-dropdown .green-bg, .status-dropdown .green-bg {
  color: #fff !important;
  background-color: #35e586 !important;
  border-color: #35e586 !important;
}

.priority-dropdown .orange-bg, .status-dropdown .orange-bg {
  color: #fff !important;
  background-color: #fd9e31 !important;
  border-color: #fd9e31 !important;
}

.dropdown-content {
  shadow: none;
  background-color: #fff;
}

.dropdown-content .dropdown-item {
  text-align: center;
  padding: 4px 15px;
  font-size: 13px;
  display: block;
}

.dropdown-content .dropdown-item.status-bg {
  border-radius: 10px 10px 0 0;
}

.dropdown-content .dropdown-item.completed {
  border-radius: 0 0 10px 10px;
}

.workflow-report-progress .CircularProgressbar .CircularProgressbar-path {
  stroke: #fbfbfb !important;
}

.workflow-report-progress .CircularProgressbar .CircularProgressbar-text {
  fill: #151313 !important;
}

.workflow-progress-grey {
  background-color: #ddd !important;
}

.workflow-progress-green {
  background-color: #affd8b !important;
}

.workflow-progress-pink {
  background-color: #fda9a4 !important;
}

.workflow-progress-yellow {
  background-color: #fde47e !important;
}

.tabs-dashboard .MuiTabs-root {
  border: none;
}

.tabs-dashboard .MuiTabs-root .MuiTabs-flexContainer button {
  color: #15131370;
  border-bottom: 1px solid #0000;
  margin: 0 20px;
  background: none !important;
  border-left: none !important;
  min-width: auto !important;
  padding: 0 !important;
}

.tabs-dashboard .MuiTabs-root .MuiTabs-flexContainer button:hover, .tabs-dashboard .MuiTabs-root .MuiTabs-flexContainer button.Mui-selected {
  color: #3956f0;
  border-bottom: 1px solid #3956f0;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .dashboard-keybox .keyfeature-wihte-item:nth-child(8) {
  margin-left: 30px;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .dashboard-keybox .keyfeature-wihte-item:nth-child(5) {
  margin-right: 15px;
}

.right-info-hyper-icon.right-info-dashboard-area .info-icon .overlay-box {
  width: 350px;
  min-width: auto;
  opacity: 1 !important;
  background: #fff !important;
  top: 50% !important;
  left: -389px !important;
  transform: translate(0, -50%) !important;
}

.right-info-hyper-icon.right-info-dashboard-area .info-icon .overlay-box:before {
  top: 50%;
  right: -28px;
  clip-path: polygon(64% 50%, 0% 0%, 0% 100%) !important;
  background-color: #fff !important;
  background-image: linear-gradient(185deg, #fff 75% 100%) !important;
  left: auto !important;
  transform: translateX(-50%) !important;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item.op20 {
  opacity: 1;
  border-color: #ccc;
  transition: all .3s ease-in-out;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item.op20 .df.aic, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item.op20 .bottom-features {
  opacity: .6;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .df.aic, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .bottom-features {
  z-index: 9;
}

.box-dashbord-container .keyfeature-box .keyfeature-wihte-item {
  margin: 0 12px 40px;
  min-height: 200px !important;
}

.tabs-dashboard .MuiTabs-root .MuiTabs-flexContainer button:nth-child(2) {
  border-right: none !important;
}

.company-dashboard-view .company-details-area {
  margin-top: -18px;
}

.company-dashboard-view .company-details-area .left-company-area {
  margin-top: -72px;
}

.company-dashboard-view .company-details-area .center-logo-area {
  margin-top: -58px;
}

.company-dashboard-view .company-details-area .right-certificate-area {
  margin-bottom: -31px;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .certificate-tooltip-box .keyfeature-wihte-item {
  width: 100%;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .certificate-tooltip-box {
  border: none;
}

.company-dashboard-view .dashboard-container {
  width: 93%;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .registerButtonAnimation {
  text-align: center;
  border-radius: 61px;
  margin: 0 36px;
  padding: 5px 35px;
  font-size: 14px;
  position: absolute;
  bottom: 45px;
  left: 30px;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .registerButtonAnimation .bottom-text {
  font-size: 8px;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .certifcate-box-pass-fail {
  flex-direction: row;
  top: auto;
  bottom: -90px;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .certifcate-box-pass-fail img {
  width: 56px;
  margin: 0 8px;
}

.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item.op20 .bottom-certification, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item.op20 .bottom-packge {
  opacity: .6;
}

.ases {
  align-self: flex-start !important;
}

.allocationSectionAdmin table th, .allocationSectionAdmin table td {
  padding: .5rem .2rem;
  font-size: 10px;
  min-width: 75px !important;
}

.businessCardText {
  font-size: 10px;
}

.taci {
  text-align: center !important;
}

.table-report {
  padding: 1rem !important;
}

.table-report td, .table-report th {
  width: auto !important;
  min-width: 50px !important;
}

.error {
  border: 1px solid red;
}

.errorMessage {
  color: red;
  font-size: 11px;
}

.didagent_target {
  display: none;
  width: 424px !important;
}

#chatbot .didagent__fabio {
  display: block !important;
  position: relative !important;
}

#chatbot {
  width: 290px;
  height: 700px;
  margin-right: 16rem;
  position: relative;
}

#chatbot .didagent_target {
  display: block !important;
}

@media (width <= 1920px) {
  .main-banner-container .fs3rem, .main-banner-container .fs3rem .c4 {
    font-size: 70px !important;
  }

  .ceo-sec .ceo-inner .left-ceo-text blockquote {
    font-size: 20px;
    line-height: 37px !important;
  }

  .investorLookup-container {
    padding: 0 150px 100px;
  }

  .investor-box .custom-list li {
    font-size: 14px !important;
  }

  .client-review-container p {
    font-size: 20px;
    line-height: 32px !important;
  }

  .review-img img {
    width: 91% !important;
  }

  .accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img {
    width: 80% !important;
  }

  .pricing-container .pricing-box {
    zoom: .8;
  }

  .pricing-container .pricing-box img.pr.w100 {
    height: 131px;
  }
}

@media (width <= 1850px) {
  .pillIcon {
    bottom: 54px !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user {
    margin-top: -4px !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user {
    margin-top: -11px !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user {
    margin-top: -25px !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user {
    margin-top: 10px !important;
  }

  .inner-container .px6rem {
    padding: 0;
  }

  .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item {
    width: 21.8%;
  }

  .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-box-company-pro .coreworkspace-update .keyfeature-wihte-item {
    margin: 0 30px 40px;
    width: 30% !important;
  }
}

@media (width <= 1720px) {
  .pillIcon {
    bottom: 34px !important;
  }

  .ceo-sec .ceo-inner .left-ceo-text blockquote {
    font-size: 16px;
    line-height: 27px !important;
  }

  .shadowPaperCrasouel .right-icon-btn span.fs1rem {
    font-size: 12px !important;
  }

  .keyfeautre-container {
    width: 1200px;
  }

  .keyfeautre-container .keyfeature-box-portfolio {
    zoom: .8;
  }

  .inner-container .px6rem {
    padding: 0;
  }

  .client-review-container p {
    font-size: 14px;
    line-height: 23px !important;
  }

  .calcluter-sec {
    width: 1237px;
  }

  .register-page-container {
    width: 65%;
    padding: 5px 0 5px 100px;
  }

  #homeMainBanner .container {
    padding-left: 11.4% !important;
    padding-right: 11.4% !important;
  }

  .company-searchbox .verify-btn.mr6rem {
    right: 103px;
  }

  .main-banner-container .pt3rem {
    padding-top: 2rem;
  }

  .automated-sec .mb4rem {
    margin-bottom: 3rem;
  }

  .automated-sec .c15 {
    font-size: 30px !important;
    line-height: 38px !important;
  }

  .automated-sec .c14 {
    font-size: 17px !important;
    line-height: 25px !important;
  }

  .calcluter-sec .c15 {
    font-size: 1.6rem;
    line-height: 36px !important;
  }

  .calcluter-sec .fs3rem {
    font-size: 2.5rem;
  }

  .register-container .pr10rem {
    padding-right: 4rem;
  }

  .register-container input {
    padding: 10px;
    font-size: .75rem !important;
  }

  .login-container input, .register-container .password-group input, .register-page-container .password-group input, .login-container input {
    padding: 10px 10px 10px 3rem;
  }

  .automated-sec .w80 {
    width: 74%;
  }

  .automated-sec .w60 {
    width: 58%;
  }

  .inner-container p.fs1-25rem {
    font-size: 1.2rem;
  }

  .not-sure-container {
    padding: 100px 250px;
  }

  .inner-container {
    padding: 15px 150px !important;
  }

  .menu-bottom {
    margin-top: -40px !important;
  }

  .main-banner-container .mt7rem {
    margin-top: 4rem;
  }

  .calcluter-sec .fs2-5rem {
    font-size: 39px;
  }

  .client-review-container p {
    letter-spacing: 1px;
    font-size: 16px;
    line-height: 25px !important;
  }

  .custom-list li {
    font-size: 16px !important;
  }

  #homeMainBanner .container .w60 .fs1-5rem {
    font-size: 1.3rem;
  }

  .work-container .work-card-box .fs1-25rem {
    font-size: 16px;
    line-height: 25px !important;
  }

  #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem {
    width: 160px;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user {
    margin-top: -32px !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user {
    margin-top: -37px !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user {
    margin-top: -54px !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user {
    margin-top: -50px !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user {
    margin-top: -48px !important;
  }

  .video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem {
    width: 477px;
    font-size: 30px;
    line-height: 40.2px !important;
  }

  .register-container .w80 {
    width: 100%;
  }

  .not-sure-container p.w100 {
    width: 85%;
  }

  .work-container .fs1-5rem {
    font-size: 1.25rem;
    line-height: 30px !important;
  }

  #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem {
    width: 237px;
  }

  .accounts-page-font .client-review-container .review-img img {
    width: 68% !important;
  }

  .accounts-page-font .client-review-container p {
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 21px !important;
  }

  .pricing-container .pricing-box .br100 {
    zoom: .7;
  }

  .pricing-container .pricing-title {
    margin-bottom: 2rem;
  }

  .vcs-page-font .client-review-container p {
    font-size: 14px;
    line-height: 21px !important;
  }

  .vcs-page-font .review-img img {
    width: 81% !important;
  }

  .client-review-container .mt4rem {
    margin-top: 2rem;
  }

  .client-review-container .mb2-5rem {
    margin-bottom: 0;
  }

  .register-container input#searchCompany {
    padding: 10px 15px 10px 57px;
  }

  .register-container .shadow.bg1.br5px {
    padding: .7rem;
  }

  .register-container .shadow.bg1.br5px span, .register-container .pr8rem .shadow.bg1 span {
    font-size: 12px;
  }

  .slider-wrapper .first-slide .inner-container img {
    width: 70%;
  }

  .slider-wrapper .inner-container .row.df.jcsb .df.jce.aic img.h80 {
    height: 66%;
  }

  .bottom-button-slider .w50 {
    width: auto;
  }

  .main-banner-container .fs3rem, .main-banner-container .fs3rem .c4 {
    margin-top: .5rem;
    font-size: 53px !important;
    line-height: 84px !important;
  }

  .main-banner-container .fs3-5rem {
    font-size: 2.8rem;
    line-height: 56px !important;
  }

  .right-img-area {
    height: 440px !important;
    margin-top: 310px !important;
  }

  .main-banner-container .col-sm.df.jcc.fdc.gg2rem {
    padding-right: 3rem;
  }

  .shadowPaperCrasouel span.w70 {
    font-size: 13px;
  }

  .shadowPaperCrasouel span.w30 {
    text-align: center;
    padding: 10px 8px !important;
  }

  .bottom-button-slider .w40 {
    width: 50%;
  }

  .inner-container .df.jcc.aic img.h60 {
    height: 179px !important;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg, .keyfeautre-container .keyfeature-box {
    zoom: .8;
  }

  .keyfeature-wihte-item {
    width: 18% !important;
  }

  .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) {
    width: 35% !important;
  }

  .not-sure-register .not-sure-container {
    padding: 30px 120px !important;
  }

  .bottom-button-slider {
    position: relative;
  }

  #expandHeader .h90vh {
    height: 88vh;
  }

  .dropdown-company-dahboard {
    zoom: .82;
  }

  .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item {
    width: 22% !important;
  }

  .dahboard-tabs .MuiTabPanel-root .dashboard-firm-tab .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details.w90 .lock-icon {
    zoom: .8;
    margin-right: 1rem;
  }

  .table-advisor {
    zoom: .8;
  }

  .client-tab .user-area {
    width: 82%;
  }

  .registration-inner .registration-box {
    zoom: .8;
  }

  .advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon {
    margin: 0 20px;
    font-size: 18px;
  }

  .report-page .sidebar-report.repository-sidebar .mah100.mya.oya.df.fdc.jcsb {
    max-height: 50vh;
  }

  .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item {
    width: 23.1% !important;
  }

  .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .dashboard-keybox .keyfeature-wihte-item:nth-child(8) {
    margin-left: 20px;
  }

  .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .certificate-tooltip-box .keyfeature-wihte-item {
    width: 100% !important;
  }
}

@media (width <= 1600px) {
  .pillIcon {
    bottom: -95px !important;
  }

  .inner-container .row.df.jcc img.mb3rem {
    width: 70% !important;
  }

  .inner-container .px6rem {
    padding: 0;
  }

  .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) {
    width: 33% !important;
  }

  .inner-container {
    padding: 15px 150px !important;
  }

  .home-page-bg {
    height: 89vh !important;
  }

  .main-banner-container.home-page-banner {
    height: 93%;
  }

  .right-img-area {
    height: 330px !important;
    margin-top: 14px !important;
  }

  .right-img-area .person-img {
    height: 140% !important;
  }

  .keyfeautre-container .keyfeature-box {
    margin-bottom: 1.5rem;
    padding: 2rem 2rem 0;
  }

  .keyfeautre-container .keyfeature-box .mb2rem {
    margin-bottom: 1rem;
  }

  #homeMainBanner .mt4rem {
    margin-top: 1rem;
  }

  #homeMainBanner .mt4rem .fs2-5rem {
    font-size: 2rem;
  }

  .main-banner-container .pt5rem {
    padding-top: 3rem;
  }

  .automated-sec.pt4rem {
    padding-top: 1rem;
  }

  .keyfeautre-container {
    width: 1170px;
  }

  .keyfeature-wihte-item {
    zoom: .9;
    width: 18% !important;
  }

  .keyfeature-wihte-item .fs1rem.c15 {
    font-size: 13px !important;
  }

  .keyfeature-wihte-item .seemore-box {
    width: 1107px !important;
  }

  .register-container .company-searchbox .pt0-5rem svg {
    width: 1.4rem;
  }

  .register-container .company-searchbox .pt0-5rem input {
    padding: 10px 10px 10px 47px;
  }

  .register-container .company-searchbox .pt0-5rem input.verify-btn {
    padding: 8px;
  }

  .investorLookup-container {
    padding: 0 100px 70px;
  }

  .how-its-work .blue-animation {
    height: 100px !important;
  }

  #homeMainBanner .container {
    padding-left: 12.6% !important;
    padding-right: 12.6% !important;
  }

  .main-banner-container .fs1-5rem {
    margin-top: 2rem;
    font-size: 1.22rem;
  }

  .main-banner-container span.c4 {
    font-size: 38px !important;
    line-height: 50px !important;
  }

  .automated-sec .c15 {
    font-size: 28px !important;
    line-height: 35px !important;
  }

  .pricing-container {
    padding: 120px 95px;
  }

  .automated-sec .w80 {
    width: 73%;
  }

  .automated-sec .w60 {
    width: 57%;
  }

  .inner-container p.fs1-25rem {
    font-size: 1rem;
  }

  .calcluter-sec .fs3rem {
    font-size: 2.3rem;
  }

  .client-review-container {
    padding: 45px 200px 60px;
  }

  .work-container {
    max-width: 930px !important;
  }

  .work-container .work-card-box {
    zoom: .8;
  }

  .work-container .fs1-25rem {
    font-size: 1rem;
  }

  .not-sure-container {
    padding: 100px;
  }

  .pricing-title .right-text-for {
    padding-right: 8rem;
  }

  .angelsnetwork-page-font .pricing-title .right-text-for, .angels-page-font .pricing-title .right-text-for {
    padding-right: 6rem;
  }

  .vcs-page-font .pricing-title .right-text-for, .pes-page-font .pricing-title .right-text-for {
    padding-right: 4rem;
  }

  .pricing-title .left-text-for {
    padding-left: 2.1rem;
  }

  .visin-sec .w30 img {
    height: 61% !important;
  }

  .main-banner-container {
    padding: 0 165px 0 149px;
  }

  .main-banner-container .mt7rem {
    margin-top: 4rem;
    font-size: 1.3rem;
  }

  .main-banner-container .fs2rem {
    font-size: 1.1rem;
    line-height: 1.4rem !important;
  }

  .main-banner-container span.c4, .carousel .slide .fs2-125rem {
    font-size: 33px !important;
    line-height: 47px !important;
  }

  .carousel .slide .fs1-25rem {
    font-size: 1rem;
    line-height: 28px !important;
  }

  .client-review-container .fs2-125rem {
    font-size: 33px;
  }

  .review-img img {
    width: 100% !important;
    height: auto !important;
  }

  .work-container .df.jce {
    width: 39px !important;
  }

  .work-container .pb1-5rem.df.jcsb {
    height: 46px !important;
    min-height: 46px !important;
  }

  .poppins-font-style .inner-container .shadowContainer {
    zoom: .7;
  }

  .angelsnetwork-page-font .inner-container .row.df.jcc img {
    width: 41% !important;
  }

  .carousel.carousel-slider .df .h60 {
    width: 85px !important;
  }

  .pricing-container .pricing-box img.pr.w100 {
    height: 116px;
  }

  .register-container .fs2-125rem {
    font-size: 1.6rem;
    line-height: 35px !important;
  }

  .register-container .w80 {
    padding-top: 5px;
  }

  .register-container .form-right-image .book-call {
    width: 75% !important;
  }

  .register-container .pt0-5rem.pb0-5rem.pr svg, .register-container .pr.w50 svg {
    transform: translateY(-50%);
    top: 50% !important;
  }

  .register-container .fs1-125rem.pb0-5rem, .register-container .pb1rem.gg1rem {
    padding: 10px 0 0;
  }

  .register-container .pb1rem.gg1rem label {
    font-size: 14px;
  }

  .register-container .registerButtonAnimation {
    margin: 6px 0;
  }

  .pricing-container .pricing-box {
    zoom: .7;
  }

  .video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem {
    width: 459px;
    font-size: 25px;
  }

  .pricing-container .pb1rem.df, .investorLookup-container .pb2rem {
    padding-bottom: 0;
  }

  .investorLookup-container .investor-box {
    margin-top: -56px;
  }

  .button-disabled, .investor-search-btn, .investor-back-btn {
    zoom: .8;
  }

  .investor-modal-database .pr input {
    padding: 10px 15px;
    font-size: 14px;
  }

  .investor-back-btn {
    margin: 0;
    padding: 10px 29px;
  }

  #menu a {
    font-size: 14px;
  }

  #menu a.w200px {
    zoom: .8;
  }

  .automated-sec .fs1rem-screen1, .narBarAnimation .fs1rem-screen1 {
    font-size: 14px;
  }

  .main-banner-container.home-page-banner .pt2rem {
    padding-top: 0;
  }

  .main-banner-container.home-page-banner .fs1-25rem {
    font-size: 1rem;
  }

  .calcluter-sec {
    margin-top: -11px;
    padding-top: 0;
  }

  .calcluter-sec .c15 {
    font-size: 1.4rem;
    line-height: 29px !important;
  }

  .main-banner-container .col-sm.df.jcc.fdc.gg2rem .fs1-25rem {
    font-size: 1rem;
  }

  .not-sure-container .scaleAnimation {
    zoom: .8;
  }

  .register-container-not-sure .form-right-image {
    right: -27% !important;
  }

  .shadowPaperCrasouel span.w30 img {
    width: 31px !important;
  }

  .shadowPaperCrasouel span.w50 {
    font-size: 13px;
    padding: 8px !important;
  }

  .client-review-container p {
    font-size: 14px;
    line-height: 21px !important;
  }

  .accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img {
    width: 76% !important;
  }

  .keyfeautre-container .keyfeature-box-portfolio {
    padding: 1.5rem 2rem;
  }

  .comapnies-page-font .companies-crousel .first-slide .c15.br30px {
    zoom: .8;
  }

  .comapnies-page-font .review-img img {
    width: 74% !important;
    height: auto !important;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg, .keyfeautre-container .keyfeature-box {
    zoom: .9;
  }

  .register-not-sure-sec {
    zoom: .8;
  }

  .not-sure-register .not-sure-container {
    padding: 30px 120px 30px 221px !important;
  }

  .angels-page-font .client-review-container p {
    line-height: 21px !important;
  }

  svg.pa.cp, .investment-box-pricing {
    zoom: .7;
  }

  #expandHeader .h90vh {
    height: 85vh;
  }

  .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
    zoom: .8;
  }

  .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .reprot-btn-dropdowns, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .reprot-btn-dropdowns {
    width: 250px;
  }

  .dahboard-tabs .MuiTabPanel-root .add-btn-dashboard {
    zoom: .8;
  }

  .advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon {
    font-size: 16px;
  }

  .banner-advisor-dashboard, .table-permission .table-userPermissions {
    zoom: .8;
  }

  #expandHeader .pr.df a svg, #expandHeader .pr.df span.usn svg {
    width: 22px;
  }

  #expandHeader .c1.c6.w100.jcsb.td500.df.fdc.jcc.wsn {
    position: relative;
    left: 5px;
  }

  #expandHeader .pr.df a, #expandHeader .pr.df span.usn {
    padding: 8px 15px 8px 40px;
  }

  .dashboard-tools-area h2 {
    font-size: 1.7rem;
  }

  .progress-dashboard .progress-box {
    margin: 0 1rem;
  }

  .report-page .sidebar-report.repository-sidebar .mah100.mya.oya.df.fdc.jcsb {
    max-height: 40vh;
  }
}

@media (width <= 1570px) {
  .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user {
    margin-top: -32px !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user {
    margin-top: -34px !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user {
    margin-top: -21px !important;
  }

  .form-right-image .triangle-vector {
    width: 134px;
    bottom: -60px !important;
    right: 364px !important;
  }

  .investorLookup-container .investor-box {
    zoom: .6;
    margin-top: -56px;
  }
}

@media (width <= 1475px) {
  .pillIcon {
    height: 34px !important;
    bottom: -64px !important;
  }

  .ceo-sec .ceo-inner .left-ceo-text blockquote {
    font-size: 13px;
    line-height: 1rem !important;
  }

  .calcluter-sec {
    width: 1037px;
  }

  .calcluter-sec .secend-sec-btn {
    zoom: .7;
  }

  .register-container-not-sure {
    padding: 100px 170px 14px;
  }

  .automated-sec .c15 {
    font-size: 26px !important;
    line-height: 32px !important;
  }

  .automated-sec .c14 {
    font-size: 1rem !important;
    line-height: 1.7rem !important;
  }

  #homeMainBanner .container {
    max-width: 100% !important;
    padding-left: 10.6% !important;
    padding-right: 10.6% !important;
  }

  .pricing-container {
    padding: 120px 30px;
  }

  .not-sure-container .fs1-5rem {
    font-size: 1.2rem;
    line-height: 30px !important;
  }

  .carousel-root .carousel-button svg {
    width: 84px;
  }

  .shadowPaperCrasouel .fs1-5rem {
    font-size: 1.4rem;
  }

  .shadowPaperCrasouel .bg19 {
    text-align: center;
    width: 37px;
    height: 37px;
    padding: 0;
  }

  .shadowPaperCrasouel .bg19 svg {
    width: 16px;
  }

  .inner-container {
    zoom: .9;
    padding: 15px 120px !important;
  }

  .fs2-125rem {
    font-size: 1.8rem;
  }

  .fs2rem {
    font-size: 1.75rem;
  }

  .fs1-25rem {
    font-size: 1rem;
  }

  .client-review-container {
    padding: 45px 256px 60px !important;
  }

  .client-review-container .pt1rem.pb1rem {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .client-review-container .pt1rem.pb1rem input {
    padding: .7rem;
  }

  .client-review-container .pt1rem.pb1rem .w50 svg {
    top: 17px !important;
  }

  .client-review-container .pt1rem.pb1rem svg {
    top: 24px !important;
  }

  .client-review-container .pt1rem.pb1rem .fs1-125rem {
    font-size: 1rem;
  }

  .client-review-container label.pt2rem.pb2rem {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .client-review-container button {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .register-container .pr10rem {
    padding-right: 3rem;
  }

  #homeMainBanner .container:nth-child(odd) .w60 {
    padding-left: 8rem;
  }

  #homeMainBanner .container:nth-child(2n) .w60 {
    padding-right: 5rem;
  }

  .calcluter-sec .fs3rem {
    font-size: 2rem;
  }

  .register-container .pr8rem {
    padding-right: 3rem;
  }

  .about-carousal-slider .slide {
    zoom: .8;
  }

  .action-sec {
    padding: 7rem 5rem !important;
  }

  .accounts-page-font .work-container .fs1-5rem {
    font-size: 1.3rem;
  }

  .accounts-page-font .work-container .fs1rem {
    font-size: .85rem;
  }

  .fs3-125rem {
    font-size: 2.8rem;
  }

  .card-style-about {
    padding: 1rem 2rem 7rem !important;
  }

  .beta-logo {
    width: 61px;
    height: 37px;
    margin-top: 8px;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem {
    width: 148px;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem {
    width: 350px;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, .comapnies-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, .comapnies-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem {
    width: 153px;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem {
    width: 200px;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem {
    width: 170px;
  }

  .accounts-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem {
    width: 270px;
  }

  .lawyers-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem {
    width: 224px;
  }

  .calcluter-sec .fs2-5rem {
    font-size: 1.6rem;
  }

  .calcluter-sec .fs2rem {
    font-size: 1.5rem;
  }

  #homeMainBanner .container .testmonial-user {
    margin-top: 0 !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem {
    width: 212px;
  }

  .angels-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem {
    width: 126px;
  }

  .client-review-container p {
    font-size: 12px;
    line-height: 17px !important;
  }

  .vcs-page-font .client-review-container p {
    font-size: 11px;
    line-height: 16px !important;
  }

  #openLoginPopup {
    zoom: .8;
    width: 150px !important;
  }

  .registerButtonAnimation, .see-aal-features {
    zoom: .8;
  }

  .register-container .registerButtonAnimation {
    width: auto !important;
    padding: 9px 40px !important;
  }

  .register-container button.fs1-25rem {
    zoom: .8;
    width: auto !important;
    height: 100% !important;
    padding: 9px 40px !important;
  }

  .calcluter-sec {
    padding-top: 1rem;
  }

  .comapnies-page-font .review-img img {
    width: 84% !important;
  }

  .main-banner-container.home-page-banner {
    padding-top: 29px;
  }

  .main-banner-container.home-page-banner .pt4rem {
    padding-top: 1rem;
  }

  .main-banner-container.home-page-banner .pt4rem .fs2-5rem {
    font-size: 1.5rem;
  }

  .main-banner-container.home-page-banner .fs1-25rem {
    font-size: 13px;
  }

  .main-banner-container.home-page-banner .fs3rem, .main-banner-container.home-page-banner .fs3rem .c4 {
    margin-top: 0;
    font-size: 58px !important;
    line-height: 62px !important;
  }

  .right-img-area {
    width: 40% !important;
    height: 294px !important;
    margin-top: 100px !important;
  }

  .main-banner-container .col-sm.df.jcc.fdc.gg2rem {
    padding-right: 2rem;
    width: 60% !important;
  }

  .main-banner-container .fs3-5rem .fs5rem {
    font-size: 3.8rem;
  }

  .calcluter-sec .c15 {
    font-size: 1.3rem;
    line-height: 29px !important;
  }

  .calcluter-sec .w60 a {
    zoom: .8;
  }

  .main-banner-container .fs3-5rem {
    font-size: 2.4rem;
    line-height: 50px !important;
  }

  .main-banner-container .fs1-75rem {
    font-size: 1.3rem;
  }

  .home-page-menu.automated-sec .menu-bottom {
    margin-top: -76px !important;
  }

  .main-banner-container .col-sm.df.jcc.fdc.gg2rem .fs1-25rem {
    font-size: 15px;
  }

  .register-not-sure-sec .not-sure-register .not-sure-container .fs2rem {
    font-size: 1.5rem;
    line-height: 26px !important;
  }

  .register-not-sure-sec .not-sure-register .not-sure-container p {
    font-size: 14px;
  }

  .inner-container .px6rem {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .register-container-not-sure .form-right-image {
    right: -33% !important;
  }

  .register-container-not-sure .form-right-image .book-call {
    width: 60% !important;
  }

  .inner-container .df.jce {
    justify-content: center;
    margin: 0 auto;
    gap: 8px !important;
    width: 16% !important;
  }

  .inner-container .df.jce img.h70 {
    height: 64%;
  }

  .comapnies-page-font .companies-crousel .first-slide .c15.br30px .icon .w30px {
    width: 22px;
  }

  .inner-container .df.jce.right-icon-btn {
    justify-content: end;
    gap: 0 !important;
    width: 70% !important;
  }

  .shadowPaperCrasouel .fs1-5rem {
    font-size: 1.3rem;
    line-height: 30px !important;
  }

  .bottom-button-slider, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
    zoom: .8;
  }

  .keyfeautre-container {
    width: 1060px;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem {
    padding-top: 5rem;
  }

  .keyfeature-wihte-item {
    zoom: .8;
  }

  .accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img {
    width: 300px !important;
    height: auto !important;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
    zoom: .7;
    width: 29% !important;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .pt10rem {
    padding-top: 8rem;
  }

  .dropdown-company-dahboard .w60px.mr2rem {
    margin-right: 1rem;
  }

  .dropdown-company-dahboard .w100 .w200px {
    width: 150px;
  }

  .progress-box-vertical-dashboard {
    zoom: .8;
  }

  .dahboard-tabs .MuiTabPanel-root .fs1-75rem {
    font-size: 1.5rem;
  }

  .dahboard-tabs .MuiTabPanel-root .fs1-5rem {
    font-size: 1.25rem;
  }

  .dropdown-company-dahboard {
    zoom: .82;
  }

  .advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon {
    margin: 0 16px;
    font-size: 14px;
  }

  .dahboard-tabs .MuiTabPanel-root .py2rem {
    padding-top: 1rem;
  }

  .dahboard-tabs .MuiTabPanel-root .pr9rem {
    padding-right: 5rem;
  }

  .dropdown-company-dahboard .w250px {
    width: 200px;
  }

  .report-page .sidebar-report.repository-sidebar .mah100.mya.oya.df.fdc.jcsb {
    max-height: 51vh;
  }

  .report-page .sidebar-report.repository-sidebar .user-info-sidebar, .report-page .sidebar-report.repository-sidebar .additional-sections-button {
    zoom: .8;
  }

  .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-box-company-pro .w250px.pricing-tooltbox {
    zoom: .9;
    width: 213px;
  }

  .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .all-reprots-area {
    margin: 0 12px 0 0;
  }
}

@media (width <= 1400px) {
  .home-page-banner .col-sm.df.jce.pr {
    width: 45% !important;
  }

  .keyfeautre-container {
    width: 1070px;
  }

  .keyfeature-wihte-item {
    margin: 0 10px 30px !important;
  }

  .keyfeature-wihte-item .fs1rem.c15 {
    font-size: 12px !important;
  }

  .keyfeature-wihte-item .seemore-box {
    width: 999px !important;
  }

  .investorLookup-container {
    padding: 0 70px 70px;
  }

  .main-banner-container span.c4 {
    font-size: 30px !important;
    line-height: 38px !important;
  }

  .automated-sec .c15 {
    font-size: 24px !important;
    line-height: 28px !important;
  }

  .automated-sec .c14 {
    padding-top: 1rem;
    font-size: 1rem !important;
    line-height: 1.3rem !important;
  }

  .calcluter-sec .fs2rem {
    font-size: 1.5rem;
  }

  .fs2-125rem {
    font-size: 1.75rem;
  }

  .fs2rem {
    font-size: 1.5rem;
  }

  .fs1-5rem {
    font-size: 1rem;
  }

  .fs1rem {
    font-size: .75rem;
  }

  .work-container {
    padding: 70px 160px;
  }

  .work-container .pt1-5rem.pb1rem {
    padding: .5rem 0;
  }

  .work-container .pb1-5rem.df.jcsb {
    height: 50px !important;
    min-height: 50px !important;
  }

  .video-container p.fs2-125rem {
    line-height: 40px !important;
  }

  .pricing-container .pl8rem.pr8rem {
    padding: 0 4rem;
  }

  .not-sure-container .fs1-5rem {
    font-size: 1rem;
    line-height: 30px !important;
  }

  .not-sure-container .mt1rem button {
    font-size: 14px;
    padding: 10px 22px !important;
  }

  .not-sure-container .bsflp.bg1.br10px {
    padding: 27px 15px;
  }

  .not-sure-container .bsflp.bg1.br10px .pa.df.jcc.aic.p2rem.bg3 {
    padding: 1rem;
    top: -33px !important;
  }

  .not-sure-container .bsflp.bg1.br10px .pa.df.jcc.aic.p2rem.bg3 img {
    width: 100%;
  }

  .not-sure-container .mt7rem.mb3rem {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem {
    width: 200px;
  }

  #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem {
    width: 112px;
  }

  #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem {
    width: 147px;
  }

  #homeMainBanner .container .w60 .fs1-5rem {
    font-size: 1.2rem !important;
  }

  #homeMainBanner .container .custom-list li {
    font-size: 17px;
  }

  .work-container .pt1-5rem.pb1rem {
    font-size: 1rem;
    line-height: 27px !important;
  }

  #homeMainBanner .container {
    max-width: 100% !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .automated-sec .w80 {
    width: 70%;
  }

  .client-review-container p {
    font-size: .75rem;
    line-height: 19px !important;
  }

  .fs1-25rem {
    font-size: 1rem;
  }

  .register-container .pr8rem {
    padding-right: 4rem;
  }

  .story-sec {
    padding: 3rem 5rem !important;
  }

  .accounts-page-font .work-container {
    padding: 70px 110px;
  }

  .accounts-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem {
    width: 245px;
  }

  .not-sure-container p.w100 {
    width: 100%;
    margin-bottom: 30px;
  }

  .lawyers-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem {
    width: 254px;
  }

  #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem {
    width: 225px;
  }

  #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem {
    width: 195px;
  }

  .client-review-container .mb2-5rem {
    margin-bottom: 1rem !important;
  }

  .client-review-container .mt4rem {
    margin-top: 2rem;
  }

  .angelsnetwork-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem {
    width: 200px;
  }

  .angels-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem {
    width: 140px;
  }

  .angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem {
    width: 263px;
  }

  .angels-page-font #homeMainBanner .container:nth-child(13) .w60 .fs1-5rem {
    width: 243px;
  }

  .main-banner-container.home-page-banner {
    padding-top: 10px;
  }

  .home-page-bg {
    height: 89vh !important;
  }

  .right-img-area {
    width: 42% !important;
    height: 290px !important;
    margin-top: 211px !important;
  }

  .main-banner-container .col-sm.df.jcc.fdc.gg2rem .fs1-25rem {
    font-size: 14px;
  }

  .shadowPaperCrasouel .fs1rem {
    font-size: 1rem;
  }

  .comapnies-page-font .companies-crousel .first-slide .new-shadow .icon {
    width: 18px;
  }

  .register-container-not-sure .form-right-image {
    bottom: -6px !important;
    right: -35% !important;
  }

  .register-not-sure-sec .not-sure-register .not-sure-container h1 {
    font-size: 1rem;
  }

  .accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img {
    width: 100% !important;
  }

  .main-banner-container .fs3-5rem {
    font-size: 2.2rem;
    line-height: 50px !important;
  }

  .pricing-table-saf {
    width: 1090px;
  }

  .pricing-tabs .booking-sec .pricing-bottom-box {
    zoom: .8;
  }

  .pricing-tabs .pricing-advisor-table .pricing-box {
    width: 20%;
  }

  .pricing-tabs .pricing-advisor-table .pricing-box .shadow.bg1 {
    zoom: .7;
  }

  .pes-page-font .review-img img {
    width: 100% !important;
  }

  .angelsnetwork-page-font .review-img img {
    margin-top: 40px;
    width: 100% !important;
  }

  .top-pricing-area {
    zoom: .8;
    top: -99px;
  }

  .top-pricing-area .w100.df.jcc .br100 {
    zoom: .9;
  }

  .banner-advisor-dashboard, .table-permission .table-userPermissions {
    zoom: .7;
  }

  .progress-area.progress-dashboard {
    zoom: .9;
  }

  .dahboard-tabs .MuiTabPanel-root .pr9rem {
    padding-left: 4rem;
    padding-right: 5rem;
  }

  .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item {
    min-height: 170px;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
    width: 30% !important;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .pt10rem {
    padding-top: 5rem;
  }

  .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item {
    width: 22% !important;
  }
}

@media (width <= 1380px) {
  .businessCardText {
    font-size: 8px;
  }

  .right-img-area {
    height: 235px !important;
    margin-top: 73px !important;
  }

  .main-banner-container .col-sm.df.jcc.fdc.gg2rem .fs1-25rem {
    font-size: 13px;
  }

  .main-banner-container .gg2rem {
    grid-gap: 1rem;
  }

  .main-banner-container .fs3-5rem {
    font-size: 2rem;
    line-height: 39px !important;
  }

  .main-banner-container .fs3-5rem .fs5rem {
    font-size: 3rem;
  }

  .calcluter-sec {
    margin-top: -32px;
  }

  .calcluter-sec .c15 {
    font-size: 1.2rem;
    line-height: 25px !important;
  }

  .calcluter-sec .p2rem {
    padding: 1rem;
  }

  .automated-sec .w60 {
    width: 63%;
  }

  .client-review-container {
    padding: 45px 233px 60px !important;
  }

  .work-container {
    padding: 70px 100px;
  }

  .work-container .pt1-5rem.pb1rem {
    font-size: .75rem;
    line-height: 27px !important;
  }

  .fs1-25rem {
    font-size: .98rem;
  }

  #homeMainBanner .container {
    max-width: 100% !important;
    padding-left: 11% !important;
    padding-right: 11% !important;
  }

  .privacy-policy-page .w60 .fs3-5rem {
    top: 142px !important;
  }

  .privacy-policy-page .policy-box {
    padding: 0 60px 60px;
  }

  .team-sec {
    padding: 3rem 5rem 1rem !important;
  }

  .team-sec .card-style-about {
    padding: 1rem 2rem 5rem !important;
  }

  .team-sec ul li {
    font-size: .75rem;
  }

  .team-sec button {
    padding: .7rem 2.125rem !important;
  }

  .team-sec button img {
    width: 30px;
  }

  .team-sec .fs3-125rem {
    font-size: 2.5rem;
  }

  .action-sec .fs2-5rem {
    font-size: 2rem;
    line-height: 2.6rem !important;
  }

  .accounts-page-font .work-container {
    padding: 70px 85px;
  }

  .accounts-page-font .work-container .how-its-work-circle {
    top: -24px !important;
    left: -27px !important;
  }

  .calcluter-sec .fs3rem {
    font-size: 1.8rem;
  }

  .main-banner-container .mt7rem {
    margin-top: 2rem;
    font-size: 1.2rem;
  }

  .work-container .fs2-125rem {
    font-size: 39px;
  }

  .not-sure-container p.w100 {
    font-size: 12px !important;
  }

  .automated-sec .c15 {
    font-size: 23px !important;
    line-height: 28px !important;
  }

  .carousel .slide .fs1-25rem {
    width: auto;
    font-size: 12px;
    line-height: 21px !important;
  }

  .shadowPaperCrasouel .fs1-5rem {
    font-size: 1.2rem;
    line-height: 27px !important;
  }

  #homeMainBanner .fs2-5rem {
    font-size: 2rem;
  }

  .investor-box .list-right-area .benefits-box {
    min-height: 276px;
    line-height: 19px !important;
  }

  .custom-list li {
    font-size: 13px !important;
  }

  .investor-box {
    zoom: .65;
  }

  .calcluter-sec .fs2rem {
    font-size: 1.2rem;
  }

  .main-banner-container span.c4, .carousel .slide .fs2-125rem {
    font-size: 25px !important;
    line-height: 29px !important;
  }

  .comapnies-page-font .silder-box {
    padding: 1rem 0;
  }

  .comapnies-page-font .review-img img {
    width: 73% !important;
  }

  .not-sure-container .mt6rem.mb3rem {
    margin-top: 3rem;
    margin-bottom: 0;
  }

  .not-sure-container .p2rem.bg3 {
    zoom: .7;
  }

  .not-sure-container .mt5rem {
    margin-top: 3rem;
  }

  .calcluter-sec .fs2-5rem {
    font-size: 1.4rem;
  }

  .carousel.carousel-slider .df .h60 {
    width: 68px !important;
  }

  .login-container .MuiFormControl-root p {
    bottom: -25px;
  }

  .shadowPaperCrasouel span.w30 {
    height: 100% !important;
    padding: 10px 4px !important;
  }

  .keyfeature-box-portfolio-bg .w30 {
    padding-left: 5rem;
  }

  .register-container {
    padding: 35px 0 36px 127px;
  }

  .video-container {
    padding: 50px 230px;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
    padding: 1rem;
  }

  .for-yoy-box {
    font-size: .7rem;
  }

  .keyfeature-box-portfolio-bg .w30 p.pr3rem {
    padding-right: 1rem;
  }

  .keyfeautre-container {
    width: 1000px;
  }

  .poppins-font-style .inner-container .shadowContainer {
    zoom: .6;
  }

  .fs2-125rem {
    font-size: 1.65rem;
  }

  .inner-container .df.jcc.aic img.h60 {
    height: 112px !important;
  }

  .keyfeautre-container .keyfeature-box-portfolio {
    justify-content: center;
  }

  .fs1-75rem {
    font-size: 1.25rem;
  }

  .df.bg1.pr.brne.bscov.h90vh {
    height: 100%;
  }

  .mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80 {
    zoom: .8;
  }

  .angels-page-font .client-review-container p {
    font-size: 12px;
    line-height: 17px !important;
  }

  .dashboard-tools-area h2 {
    font-size: 1.4rem;
  }

  #expandHeader .h90vh {
    height: 82vh;
  }

  #expandHeader:hover #logo {
    width: 7rem;
  }

  #expandHeader #logo {
    margin-bottom: .4rem;
  }

  .advisor-dashboard-seemore .m1rem.pr.br10px.pr {
    zoom: .8;
  }

  .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details {
    bottom: 18px;
  }

  .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details img, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details img {
    width: 80%;
  }

  .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details .lock-icon .w60px, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details .lock-icon .w60px {
    width: 55px;
  }

  .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details .lock-icon svg, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details .lock-icon svg {
    width: 45px;
  }

  .box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-bottom-area .report-btn svg, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-bottom-area .report-btn svg, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn svg, .right-info-hyper-icon svg {
    height: 25px;
    width: 25px !important;
  }

  .report-page .sidebar-report.repository-sidebar .mah100.mya.oya.df.fdc.jcsb {
    max-height: 43vh;
  }

  .report-page .sidebar-report.repository-sidebar .mah100.mya.oya.df.fdc.jcsb button span {
    font-size: 9px;
  }

  .login-page {
    height: 100%;
  }

  .login-page .login-container {
    padding: 122px 0 120px 170px;
  }

  .register-page {
    height: 100%;
  }
}

@media (width <= 1280px) {
  .inner-container p.fs1-25rem {
    font-size: .85rem;
    line-height: 1.5rem !important;
  }

  #homeMainBanner .container {
    max-width: 100% !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .privacy-policy-page .w60 {
    width: 70%;
  }

  .privacy-policy-page .policy-box p {
    line-height: 24px !important;
  }

  .visin-sec {
    padding-bottom: 3rem;
  }

  .visin-sec .container {
    align-items: center;
  }

  .visin-sec .w30 img {
    width: 100%;
    height: 100% !important;
    margin-top: 106px !important;
  }

  .accounts-page-font .work-container {
    padding: 70px;
  }

  .automated-sec .c15 {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .client-review-container p {
    font-size: .65rem;
    line-height: 15px !important;
  }

  #homeMainBanner .container {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .quote-area p {
    font-size: 9px;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user {
    margin-top: -21px !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user {
    margin-top: -9px !important;
  }

  .home-page-bg {
    height: 86vh !important;
  }

  .register-container-not-sure .pr8rem {
    padding-right: 11rem;
  }

  .main-banner-container .col-sm.df.jcc.fdc.gg2rem .fs1-25rem {
    font-size: 12px;
  }

  .not-sure-container .scaleAnimation a {
    zoom: .7;
  }

  .register-container-not-sure .form-right-image {
    bottom: -6px !important;
    right: -37% !important;
  }

  .inner-container {
    padding: 11px 118px !important;
  }

  .inner-container .px6rem {
    padding-left: 0;
    padding-right: 0;
  }

  .df.bg1.pr.brne.bscov.h90vh {
    height: 95vh;
  }

  .home-page-menu.automated-sec .menu-bottom {
    margin-bottom: 15px;
    margin-top: -51px !important;
  }

  .inner-container .df.jce img.h70 {
    height: 101px;
  }

  .comapnies-page-font .companies-crousel .first-slide .c15.br30px {
    zoom: .7;
  }

  .client-review-container .fs2-125rem {
    font-size: 27px;
  }

  .angels-page-font .client-review-container p {
    line-height: 18px !important;
  }

  .comapnies-page-font .review-img img {
    width: 100% !important;
  }

  .shadowPaperCrasouel {
    width: 100%;
  }

  span.wid-btn {
    width: 60%;
  }

  .progress-area.progress-dashboard {
    zoom: .8;
  }

  .dropdown-company-dahboard .reprot-btn-dropdowns {
    width: 231px;
  }

  .dropdown-company-dahboard {
    zoom: .77;
  }

  .dahboard-tabs .MuiTabPanel-root .add-btn-dashboard {
    zoom: .7;
  }

  .advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon {
    font-size: 14px;
  }

  .dahboard-tabs .MuiTabPanel-root .fs1-5rem {
    font-size: 1.1rem;
  }

  .dahboard-tabs .MuiTabPanel-root .fs1-75rem {
    font-size: 1.35rem;
  }
}

@media (width <= 1250px) {
  .automated-sec .w80 {
    width: 66%;
  }

  .automated-sec .c14 {
    font-size: .9rem !important;
  }

  .inner-container {
    padding: 11px 90px !important;
  }

  .client-review-container {
    padding: 45px 124px 60px !important;
  }

  .work-container {
    padding: 70px 167px;
  }

  .not-sure-container {
    padding: 100px 118px;
  }

  .accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img {
    width: 100% !important;
  }

  .main-banner-container .fs3-5rem {
    font-size: 1.8rem;
    line-height: 39px !important;
  }

  .video-container img.w10.pa {
    width: 60px !important;
  }
}

@media (width <= 1199px) {
  .calcluter-sec {
    width: 88%;
  }

  .poppins-font-style {
    overflow: hidden;
  }

  .investor-box {
    zoom: .7;
  }

  .calcluter-sec .c15 {
    font-size: 1.2rem;
    line-height: 26px !important;
  }

  .calcluter-sec .fs3rem {
    font-size: 1.5rem;
    line-height: 35px !important;
  }

  .work-container {
    padding: 70px 97px;
  }

  #homeMainBanner .container:nth-child(odd) .w60 {
    padding-left: 10rem;
  }

  #homeMainBanner .container:nth-child(2n) .w60 {
    padding-right: 6rem;
  }

  #homeMainBanner .container .w90 .fs1-25rem {
    font-size: .8rem;
    line-height: 21px !important;
  }

  #homeMainBanner .container .w100.pr.df {
    margin-top: 10px;
  }

  .video-container {
    padding: 84px 123px 0;
  }

  .register-container {
    padding: 70px 0 70px 95px;
  }

  .register-container .pr8rem {
    padding-right: 0;
  }

  .triangle-vector {
    width: 150px;
    right: 50% !important;
  }

  .pricing-container .pl8rem.pr8rem {
    padding: 0;
  }

  .pricing-container .pricing-box {
    width: 21%;
  }

  .privacy-policy-page .w60 {
    width: 82%;
  }

  .fs3-5rem {
    font-size: 3rem;
  }

  .terms-and-conditions-page [style*="width: 41%"] {
    width: 60% !important;
  }

  .our-thesis-sec img.ar.pa {
    width: 68px;
  }

  .visin-sec .fs3rem {
    font-size: 2.5rem;
  }

  .about-carousal-slider .slide {
    zoom: .6;
  }

  .action-sec .fs2-5rem {
    font-size: 1.8rem;
    line-height: 2.4rem !important;
  }

  .accounts-page-font .work-container {
    padding: 70px 30px;
  }

  .accounts-page-font .work-container .fs1-5rem {
    font-size: 1.1rem;
  }

  .accounts-page-font .work-container .df.jcsb .pb1-5rem.df.jcsb {
    flex-wrap: wrap;
    align-items: center !important;
  }

  .accounts-page-font .work-container .df.jcsb .pb1-5rem.df.jcsb .w80 {
    width: 90%;
  }

  .accounts-page-font .work-container .df.jcsb .pb1-5rem.df.jcsb .df.jce {
    width: 20px !important;
  }

  .how-its-work .blue-animation {
    height: 15% !important;
  }

  .comapnies-page-font .main-banner-container, .accounts-page-font .main-banner-container, .lawyers-page-font .main-banner-container, .vcs-page-font .main-banner-container, .pes-page-font .main-banner-container, .angelsnetwork-page-font .main-banner-container, .angels-page-font .main-banner-container {
    hight: 56.5vh;
  }

  .right-img-area {
    height: 215px !important;
    margin-top: -22px !important;
  }

  .register-container-not-sure {
    padding: 106px 191px 28px 57px;
  }

  .main-banner-container .col-sm.df.jcc.fdc.gg2rem .fs1-25rem {
    font-size: 11px;
  }

  .inner-container {
    padding: 11px 103px !important;
  }

  .keyfeature-box-portfolio-bg .w30 {
    padding-top: 6rem;
    padding-left: 5rem;
  }

  .pricing-table-saf {
    width: 760px;
  }

  .beta-logo {
    width: 61px;
    height: 37px;
    margin-top: 0;
    position: absolute;
    top: 17px;
    left: 283px;
  }

  .pricing-tabs .pricing-advisor-table .pricing-box {
    width: 46%;
  }

  .pricing-plan-page .w60.fs3-125rem {
    font-size: 2.5rem;
    padding-left: 9rem !important;
  }

  .pricing-plan-page .df.w100.pt6rem {
    padding-top: 2rem;
  }

  .slider-wrapper .first-slide .inner-container img.pa.ar.zi1 {
    width: 40px !important;
  }

  .register-page-container {
    width: 90%;
    padding: 5px 0 5px 100px;
  }

  .right-img-area .person-img {
    height: 115% !important;
  }
}

@media (width <= 1099px) {
  .client-review-container {
    padding: 45px 59px 60px !important;
  }

  .work-container {
    padding: 70px 45px;
  }

  .bottom-features {
    width: 100%;
  }

  .team-sec {
    padding: 2rem 30px 1rem;
  }

  .our-thesis-sec {
    padding-left: 3rem;
  }

  .visin-sec .container {
    padding: 0 2rem !important;
  }

  .accounts-page-font .work-container .df.jcsb .pb1-5rem.df.jcsb {
    flex-wrap: nowrap;
  }

  .accounts-page-font .work-container .fs1-5rem {
    font-size: 1rem;
  }
}

@media (width <= 1024px) {
  .home-page-bg {
    height: 600px !important;
  }

  .main-banner-container .fs3-5rem .fs5rem {
    font-size: 2.5rem;
  }

  .right-img-area {
    height: 212px !important;
    margin-top: 39px !important;
  }

  .right-img-area .person-img {
    height: 118% !important;
  }

  .main-banner-container .col-sm.df.jcc.fdc.gg2rem {
    padding-right: 3rem;
  }

  .calcluter-sec .c15 {
    font-size: 1rem;
    line-height: 23px !important;
  }

  .calcluter-sec .fdc.pl2rem {
    padding-left: 0;
  }

  .keyfeautre-container {
    width: 74%;
  }

  .comapnies-page-font .keyfeautre-container {
    width: 82%;
  }

  .main-banner-container .fs3-5rem {
    font-size: 1.4rem;
    line-height: 39px !important;
  }

  .video-container {
    padding: 32px 123px;
  }

  .slider-wrapper .first-slide .inner-container img {
    width: 70% !important;
  }

  .poppins-font-style .inner-container .shadowContainer {
    zoom: .5;
  }

  .inner-container .df.jcc.aic img.h60 {
    height: 74px !important;
  }

  .angels-page-font .inner-container img.mb6rem {
    margin-bottom: 0;
    width: 89% !important;
  }

  .main-banner-container .col-sm.right-img-area .cubes-img {
    top: 50px !important;
    left: -37px !important;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg, .keyfeautre-container .keyfeature-box {
    zoom: .8;
  }

  .keyfeature-wihte-item {
    width: 17% !important;
  }

  .work-container {
    zoom: .8;
  }

  .not-sure-register .not-sure-container {
    padding: 30px 96px 30px 173px !important;
  }

  .register-not-sure-sec {
    zoom: .6;
  }

  .client-review-container {
    zoom: .95;
  }

  .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) {
    width: 38% !important;
  }

  .comapnies-page-font .review-img img {
    width: 64% !important;
  }

  .keyfeature-wihte-item {
    zoom: .7;
  }

  .df.bg1.pr.brne.bscov.h90vh {
    zoom: .6;
    height: 100%;
  }

  .msg-btn, .top-arrow-bt {
    zoom: .8;
  }

  .registration-inner .registration-box {
    width: 50%;
  }
}

@media (width <= 991px) {
  .partners-page .w60 {
    font-size: 25px !important;
  }

  .partners-page .w70.df.fdc.jcc {
    zoom: .8;
  }

  .register-page-container {
    width: 111%;
    padding: 5px 0 5px 100px;
  }

  .login-page .login-container .pr8rem {
    padding-right: 0;
  }

  .home-page-bg {
    height: 100% !important;
  }

  .home-page-menu.automated-sec .menu-bottom {
    margin-top: 0 !important;
  }

  .home-page-banner .col-sm.df.jce.pr {
    justify-content: center;
    margin: 0 auto;
    width: 60% !important;
  }

  .comapnies-page-font .companies-crousel .first-slide .c15.br30px {
    width: 73%;
  }

  .investor-box {
    zoom: .6;
  }

  #homeMainBanner .container {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .main-banner-container .col-sm {
    justify-content: center;
    width: 100% !important;
  }

  .main-banner-container .col-sm.right-img-area {
    margin: 24px auto 0;
  }

  .main-banner-container .col-sm.right-img-area .person-img {
    margin: 0 auto;
  }

  .main-banner-container .row {
    flex-wrap: wrap !important;
  }

  .automated-sec .menu-bottom {
    width: 85%;
  }

  .automated-sec .c15 {
    font-size: 19px !important;
    line-height: 25px !important;
  }

  .automated-sec .w60 {
    border-radius: 10px;
    width: 92%;
    padding: 10px;
  }

  .right-side-zak {
    width: 94px;
    height: auto !important;
  }

  .calcluter-sec {
    padding: 2rem 3rem;
  }

  .calcluter-sec .w100 {
    width: 91%;
    margin: 0 auto;
  }

  .landing-page-font .inner-container {
    padding: 11px 102px !important;
  }

  .landing-page-font .inner-container .shadowContainer {
    border-radius: 14px;
    padding: 20px;
  }

  .client-review-container {
    padding: 45px 33px 60px !important;
  }

  .client-review-container .pr3rem.pl3rem {
    padding: 1.5rem 1.5rem 0;
  }

  .client-review-container .w60 {
    padding-right: 0;
  }

  #homeMainBanner .container:nth-child(odd) .w60 {
    padding-left: 5rem;
  }

  #homeMainBanner .container:nth-child(2n) .w60 {
    padding-right: 3rem;
  }

  #homeMainBanner .row.pl2rem.pr2rem.w50 {
    width: 100%;
  }

  .pricing-container .pricing-box {
    width: 30%;
  }

  .pricing-container .pl8rem.pr8rem {
    padding: 0;
  }

  .ar.pa.blue-circle {
    right: 29px !important;
  }

  .privacy-policy-page .policy-box {
    padding: 0 30px 40px;
  }

  .privacy-policy-page .w60 {
    border-radius: 10px;
    width: 90%;
  }

  .privacy-policy-page .w60 .fs1rem-screen1 {
    font-size: .75rem;
  }

  .privacy-policy-page .w60 .fs3-5rem {
    font-size: 2.5rem;
  }

  .privacy-policy-page .policy-box .pb2rem {
    padding-bottom: 1.3rem;
  }

  .our-thesis-sec {
    padding-left: 30px;
    padding-right: 30px;
  }

  .our-thesis-sec .w60, .our-thesis-sec .w40 {
    width: 100%;
  }

  .visin-sec .container {
    align-items: center;
    padding: 0 30px !important;
  }

  .visin-sec .container .w60 {
    width: 100%;
    padding-right: 0;
  }

  .visin-sec .container .w30 {
    text-align: center;
    width: 100%;
  }

  .visin-sec .container .w30 img {
    width: 62%;
    height: 100% !important;
    margin: 20px auto 0 !important;
  }

  .story-sec {
    padding: 3rem !important;
  }

  .team-sec .dg.aic {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .team-sec .dg.aic .df.fdc {
    justify-content: center;
    width: 47%;
    margin-bottom: -150px;
  }

  .about-carousal-slider .slide {
    zoom: .4;
  }

  .action-sec.dg {
    flex-wrap: wrap;
    display: flex;
  }

  .action-sec.dg .left-area {
    width: 100%;
    margin-bottom: 22px;
  }

  .action-sec.dg .right-area {
    width: 39%;
    margin: 0 auto;
  }

  .accounts-page-font .work-container .df.jcsb {
    flex-wrap: wrap;
  }

  .accounts-page-font .work-container .df.jcsb .work-card-box {
    width: 24%;
    margin-bottom: 28px;
  }

  .our-thesis-sec .w40 {
    margin-top: -63px;
    margin-right: -60px;
  }

  .our-thesis-sec img.ar.pa {
    width: 39px;
  }

  .card-style-about {
    padding: 20px 30px 60px !important;
  }

  .story-sec h4 {
    font-size: 2.5rem !important;
  }

  .narBarAnimation {
    display: none;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user {
    margin-top: -8px !important;
  }

  .comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user {
    margin-top: 1px !important;
  }

  .main-banner-container .col-sm.df.jcc.fdc.gg2rem {
    padding-right: 0;
    width: 100% !important;
  }

  .keyfeature-wihte-item {
    width: 17% !important;
  }

  .work-container {
    zoom: .7;
    max-width: 75% !important;
  }

  .slider-wrapper .first-slide .inner-container img.pa.ar.zi1 {
    width: 52px !important;
  }

  .calcluter-sec .w60 a {
    text-align: center;
    padding-left: 2rem;
  }

  .pricing-plan-page .w60.fs3-125rem {
    font-size: 2rem;
    padding-left: 8rem !important;
  }

  .df.bg1.pr.brne.bscov.h90vh {
    height: 100%;
  }

  .shadowPaperCrasouel {
    width: 100%;
  }

  .inner-container {
    padding: 11px 66px !important;
  }

  .inner-container .df.jcc.aic img.h60 {
    height: 120px !important;
  }

  .accounts-page-font .client-review-container {
    zoom: .7;
  }

  .pricing-tabs .booking-sec {
    width: 102% !important;
  }

  .contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df {
    zoom: .7;
  }
}

@media (width <= 860px) {
  .keyfeature-box-portfolio-innner:before, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before {
    width: 93%;
    left: 0;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before {
    left: 21px;
  }

  .keyfeature-wihte-item {
    width: 38% !important;
  }

  .work-container .df.jcsb {
    flex-wrap: wrap;
    justify-content: center;
  }

  .work-container .df.jcsb .work-card-box {
    width: 40%;
    margin-bottom: 30px;
  }

  .work-container .df.jcc {
    flex-wrap: wrap;
  }

  .work-container .df.jcc .work-card-box {
    width: 40%;
    margin: 0 15px 34px;
  }

  .slider-wrapper .first-slide .inner-container img {
    width: 44% !important;
  }

  .pricing-table-saf {
    width: 570px;
  }

  .pricing-tabs .booking-sec {
    flex-wrap: wrap;
    margin-top: -8px;
  }

  .pricing-tabs .booking-sec .pricing-bottom-box {
    width: 100%;
    margin: 15px 0 0 !important;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30 {
    width: 25%;
    padding-left: 1rem;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w70 {
    width: 75%;
  }

  .inner-container {
    zoom: .7;
    padding: 11px 58px !important;
  }
}

@media (width <= 790px) {
  .login-page {
    flex-wrap: wrap;
    height: 100%;
  }

  .login-page .login-container {
    width: 100%;
    padding: 100px 50px 50px;
  }

  .login-page .form-right-image {
    height: 373px;
    width: 100% !important;
  }

  .investorLookup-container {
    padding: 0 30px 70px;
  }

  .investor-box {
    zoom: .5;
  }

  .main-banner-container {
    padding: 0 45px;
  }

  .new-header .container {
    padding: 0 45px !important;
  }

  .calcluter-sec .c15 {
    font-size: 1rem;
    line-height: 23px !important;
  }

  .calcluter-sec .fs3rem {
    margin-top: 1rem;
    font-size: 1.2rem;
    line-height: 27px !important;
  }

  .landing-page-font .silder-box {
    padding: 1rem;
  }

  .landing-page-font .inner-container p.fs1-25rem {
    font-size: .75rem;
    line-height: 1.3rem !important;
  }

  .work-container {
    padding: 45px 30px;
  }

  .work-container .jcsb {
    flex-wrap: wrap;
    justify-content: center;
  }

  .work-container .jcsb .work-card-box {
    width: 33%;
    margin: 0 15px 25px;
  }

  #homeMainBanner .container .w60 {
    width: 100%;
  }

  #homeMainBanner .container:nth-child(odd) .w60, #homeMainBanner .container:nth-child(2n) .w60 {
    padding: 0;
  }

  #homeMainBanner .container .w40 {
    width: 60%;
    margin: 0 auto;
  }

  #homeMainBanner .container .w100.pr.df {
    width: 100%;
    margin-top: 0;
    justify-content: center !important;
  }

  #homeMainBanner .container .w100.pr.df .br40px.pt1rem.pb1rem.pl3rem.pr2rem {
    width: 100% !important;
  }

  .video-container {
    padding: 49px 30px 0;
  }

  .not-sure-container .df.jcsb {
    flex-wrap: wrap;
    justify-content: center;
  }

  .not-sure-container .df.jcsb .scaleAnimation {
    margin-bottom: 43px;
  }

  #homeMainBanner .container .w50, #homeMainBanner .container .br40px.pt1rem {
    width: 100% !important;
  }

  .beta-logo {
    position: absolute;
    top: 15%;
    left: 21%;
  }

  .calcluter-sec {
    zoom: .8;
    padding: 1rem;
  }

  .inner-container .df.jce.aic {
    justify-content: center;
    margin: 0 auto;
    gap: 5px !important;
    width: 55% !important;
  }

  .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) {
    width: 100% !important;
  }

  .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) .df.fw500.fs1rem.c15 {
    justify-content: space-between;
    align-items: center;
  }

  .keyfeature-wihte-item {
    width: 39% !important;
  }

  .work-container .df.jcsb .work-card-box, .work-container .df.jcc .work-card-box, .accounts-page-font .work-container .df.jcsb .work-card-box {
    width: 38%;
  }

  .main-banner-container .fs1-75rem {
    font-size: 1rem;
  }

  .df.bg1.pr.brne.bscov.h90vh {
    height: 100%;
  }

  .pricing-plan-page .w60.fs3-125rem {
    padding-left: 3rem !important;
  }

  .pricing-plan-page .df.fdc.aic.mt1rem {
    width: 50% !important;
  }

  .pricing-plan-page .w70 .df.jcsb.w100, .pricing-plan-page .w80 .df.jcsa.w100 {
    flex-wrap: wrap;
    justify-content: center;
  }

  .register-page {
    flex-wrap: wrap;
    height: 100%;
  }

  .register-page .register-page-container {
    box-sizing: border-box;
    width: 100%;
    padding: 5px 38px;
  }

  .register-page .register-page-container .pr8rem {
    padding-right: 0;
  }

  .register-page .form-right-image {
    height: 400px;
    position: relative;
    width: 100% !important;
  }

  .inner-container .df.jcc.aic img.h60 {
    height: 109px !important;
  }

  .pricing-tabs .booking-sec {
    width: 100% !important;
  }

  .pricing-tabs .booking-sec .pricing-bottom-box {
    min-height: auto;
  }

  .pricing-tabs .booking-sec .pricing-bottom-box p {
    padding: 0 0 10px;
  }

  .tabs-portfolio .pt10rem {
    padding-top: 9rem;
  }

  .partners-page .w70.df.fdc.jcc, .partners-page .w60 {
    width: 100% !important;
  }

  .partners-page .df.w100 {
    flex-wrap: wrap;
    justify-content: center;
  }

  .partners-page .w40 {
    background-position: 100% 0;
    background-size: contain;
    width: 100%;
    padding-right: 0;
  }

  .partners-page .w40 img {
    text-align: center;
    margin: 0 auto;
    width: 50% !important;
  }

  .partners-page .w30 {
    width: 100%;
    margin: 0 auto;
  }

  .partners-page .df.w100 .df {
    flex-wrap: wrap;
  }

  .partners-page .df.w100 .df img {
    width: 50% !important;
  }

  .partners-page .df.w100 .w60.fw500 {
    width: 100% !important;
    padding-left: 3rem !important;
  }

  .partners-page .df.w100 .df .cp.bg4.br40px.df.jcsb.aic.pl1rem.pr1rem.mt1-5rem {
    margin-bottom: 25px;
    flex-wrap: nowrap !important;
    width: 152px !important;
  }

  .contact-us-page .df.w100 {
    flex-wrap: wrap;
  }

  .contact-us-page .df.w100 .w60.fs2-5rem {
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 38px;
    font-size: 2rem;
    padding-left: 0 !important;
  }

  .contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df {
    zoom: .5;
  }

  .beta-logo {
    top: 21%;
    left: 23%;
  }
}

@media (width <= 690px) {
  .calcluter-sec .w30, .calcluter-sec .w70 {
    width: 100%;
  }

  .automated-sec .menu-bottom .fs1rem-screen1, .automated-sec .menu-bottom, #root[style*="margin-top: 72px"] .narBarAnimationUP .fs1rem-screen1, #root[style*="margin-top: 72px"] .narBarAnimation .fs1rem-screen1 {
    font-size: .7rem;
  }

  .landing-page-font .inner-container {
    padding: 11px 95px 0 !important;
  }

  .landing-page-font .inner-container .shadowContainer {
    margin-bottom: 17px;
    width: 100% !important;
  }

  .landing-page-font .inner-container .df.jce.aic {
    justify-content: center;
    margin: 0 auto;
    gap: 5px !important;
    width: 100% !important;
  }

  .landing-page-font .inner-container .df.jce.aic img {
    height: auto;
    width: 131px !important;
  }

  .client-review-container .df.w100 {
    flex-wrap: wrap;
  }

  .client-review-container .w60 {
    width: 100%;
    padding-right: 0;
  }

  .client-review-container .w40 {
    justify-content: center;
    width: 60%;
    margin: 0 auto;
  }

  .work-container .jcsb .work-card-box, .accounts-page-font .work-container .df.jcsb .work-card-box {
    width: 100%;
    margin: 0 0 25px;
  }

  .work-card-box {
    width: 100%;
    margin-bottom: 25px;
  }

  .work-container .df.jcc {
    flex-wrap: wrap;
  }

  .pricing-container .pricing-box {
    width: 44%;
  }

  .triangle-vector {
    width: 100px;
  }

  .pricing-container .pricing-box.mr5rem {
    margin-right: 15px;
  }

  .pricing-title .right-text-for {
    padding-right: 0 !important;
  }

  .pricing-title .left-text-for {
    padding-left: 0;
  }

  .action-sec {
    padding: 3rem 30px !important;
  }

  .action-sec .fs2-5rem {
    font-size: 1.5rem;
  }

  .action-sec.dg .right-area {
    width: 58%;
  }

  .beta-logo {
    top: 16px;
    left: 183px !important;
  }

  .pricing-plan-page .w60.fs3-125rem {
    width: 100%;
    padding-bottom: 2rem;
  }

  .angelsnetwork-page-font .inner-container .row.df.jcc img {
    width: 66% !important;
    height: auto !important;
  }
}

@media (width <= 600px) {
  .main-banner-container.home-page-banner .fs3rem, .main-banner-container.home-page-banner .fs3rem .c4 {
    margin: 20px 0;
    font-size: 39px !important;
    line-height: 46px !important;
  }

  .main-banner-container.home-page-banner .pt4rem .fs2-5rem {
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  .pricing-tabs .pricing-advisor-table .pricing-box {
    width: 99%;
  }

  .investor-box {
    zoom: .4;
  }

  .main-banner-container, .new-header .container {
    padding: 0 30px 30px;
  }

  .main-banner-container .fs1-5rem {
    font-size: 1rem;
  }

  .main-banner-container .fs2rem {
    font-size: 1.3rem;
  }

  .main-banner-container span.c4 {
    font-size: 25px !important;
  }

  .calcluter-sec {
    flex-wrap: wrap;
  }

  .calcluter-sec .w100 {
    flex-wrap: wrap;
    padding: 2rem 1rem 1rem;
  }

  .calcluter-sec .w60 {
    width: 100%;
  }

  .calcluter-sec .w40 {
    width: 100%;
    margin-top: 20px;
  }

  #homeMainBanner .container {
    max-width: 100% !important;
    padding: 0 30px !important;
  }

  .blue-animation {
    width: 77px !important;
    height: auto !important;
    top: -40px !important;
  }

  .circle-animation {
    width: 68px !important;
    height: auto !important;
  }

  .quote-icon {
    width: 20px !important;
  }

  .quote-icon svg {
    width: 20px;
  }

  #homeMainBanner .container .w90 .fs1-25rem {
    padding-left: .5rem;
  }

  #homeMainBanner .container:nth-child(odd) .w60 {
    margin-bottom: 13px;
  }

  #homeMainBanner .container .w60 .custom-list, #homeMainBanner .container .w60 .custom-list li {
    font-size: 17px;
    width: 95% !important;
  }

  .bottom-features .column {
    flex: 0 0 50%;
  }

  .video-container p.fs2-125rem {
    font-size: 1.2rem;
    line-height: 33px !important;
  }

  .video-container .w70 {
    width: 99%;
  }

  .pricing-container .pricing-box {
    width: 43%;
  }

  .register-container .df.gg1rem.pt0-5rem.pb0-5rem {
    flex-wrap: wrap;
  }

  .register-container .w50 {
    width: 100%;
  }

  .not-sure-container .bsflp.bg1.br10px {
    padding: 27px 15px;
    width: 41% !important;
  }

  .not-sure-container .w100.df.jcc p {
    width: 100% !important;
  }

  .side-blue-circle {
    width: 70px;
    height: auto;
    bottom: 0 !important;
    right: 38px !important;
  }

  .not-sure-container .side-blue-circle {
    height: auto;
    width: 70px !important;
    bottom: 0 !important;
    right: 0 !important;
  }

  .privacy-policy-page .w60 {
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
  }

  .privacy-policy-page .w60 .fs1rem-screen1 {
    margin: 0 5px;
  }

  .privacy-policy-page .policy-box .pt2rem {
    padding-top: 1rem;
  }

  .privacy-policy-page .policy-box .pt1rem {
    padding-top: .6rem;
  }

  .fs3-5rem {
    font-size: 2.5rem;
  }

  .terms-and-conditions-page [style*="width: 41%"] {
    border-radius: 10px;
    padding: 12px 10px;
    width: 88% !important;
  }

  .team-sec .dg.aic .df.fdc {
    justify-content: center;
    width: 100%;
    margin-bottom: -120px;
  }

  .team-sec .fs3-125rem {
    font-size: 2rem;
  }

  .about-carousal-slider .slide {
    zoom: .3;
  }

  .video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem {
    width: 100%;
    font-size: 25px;
  }

  #homeMainBanner .fs2-5rem {
    font-size: 1.5rem;
  }

  .work-container .fs1-5rem {
    font-size: .7rem;
    line-height: 16px !important;
  }

  .poppins-font-style .inner-container .shadowContainer {
    width: 49% !important;
  }

  .register-not-sure-sec {
    overflow: hidden;
  }

  .calcluter-sec .w40 img {
    width: 60%;
    margin-bottom: 20px;
  }

  .keyfeature-wihte-item {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .work-container .df.jcsb .work-card-box, .work-container .df.jcc .work-card-box, .accounts-page-font .work-container .df.jcsb .work-card-box {
    width: 75%;
  }

  .register-not-sure-sec .not-sure-register .not-sure-container .fdc {
    flex-wrap: wrap;
  }

  .register-not-sure-sec .not-sure-register .not-sure-container .fdc .scaleAnimation {
    margin-bottom: 45px;
  }

  .bottom-button-slider .fs1rem.df.jcc {
    width: 69%;
  }

  #homeMainBanner .mt4rem .fs2-5rem {
    font-size: 1.5rem;
  }

  .inner-container.style-two .row {
    width: 100%;
  }

  .investorLookup-container .fs2rem {
    padding: 50px 0;
    font-size: 1.5rem;
    line-height: 2rem !important;
  }

  .pricing-table-saf {
    width: 80%;
  }

  .pricing-plan-page .w60.fs3-125rem {
    padding-bottom: 2rem;
    font-size: 1.5rem;
    line-height: 2rem !important;
  }

  .pricing-plan-page .w40.pr.df {
    justify-content: center;
    width: 56%;
    margin: 0 auto;
  }

  .bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem {
    zoom: .6;
    padding: 34px 15px;
  }

  .bg1.pricing-plan-page h4.fs2rem.fw400.mb2rem.c15 {
    font-size: 1.1rem;
    max-width: 100% !important;
    line-height: 28px !important;
  }

  .comapnies-page-font .inner-container .shadowContainer .df.aic, .comapnies-page-font .inner-container .shadowContainer .df.aic p {
    justify-content: start;
  }

  .poppins-font-style .inner-container .shadowContainer .br10px.mt1rem svg {
    width: 39px;
  }

  .register-page .register-page-container .w50 {
    width: 100%;
  }

  .register-page .register-page-container .df.gg1rem, .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) .df.fw500.fs1-25rem.c15 {
    flex-wrap: wrap;
  }

  .inner-container .row.df.jcsb .df.jce.aic img.h70 {
    height: 50%;
  }

  .comapnies-page-font .companies-crousel .first-slide .c15.br30px {
    zoom: .8;
    width: 73%;
  }

  .comapnies-page-font .companies-crousel .first-slide .new-shadow .icon img.w30px {
    width: 20px;
  }

  .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) .df.fw500.fs1-25rem.c15 a {
    margin-top: 15px;
  }

  .inner-container .df.jce.right-icon-btn {
    justify-content: flex-start;
    margin: 20px 0 12px;
    gap: 0 !important;
    width: 64% !important;
  }

  .inner-container .df.jcc.aic img.h60 {
    height: 142px !important;
  }

  .step-box-modal.style-two {
    padding: 0;
  }

  .mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80 {
    zoom: .8;
    padding: 10px;
  }

  .client-review-container .mt4rem {
    padding: 2rem 1rem 0;
  }

  .register-container-not-sure {
    width: 89%;
    padding: 106px 276px 28px 72px;
  }

  .not-sure-register.w40 {
    width: 100%;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem {
    flex-flow: wrap;
    width: 100%;
    padding-top: 4rem;
    padding-left: 3rem;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem p {
    width: 100%;
  }

  #keyFeatureSeeMore svg.pa.cp {
    bottom: -3px !important;
  }

  .registration-inner .registration-box {
    width: 100%;
  }
}

@media (width <= 480px) {
  .not-sure-register .not-sure-container {
    padding: 30px 96px !important;
  }

  .home-page-menu.automated-sec .menu-bottom {
    margin-top: -37px !important;
  }

  .main-banner-container .fs3-5rem .fs5rem {
    margin: 10px 0;
    font-size: 1.8rem;
    line-height: 2.5rem;
    display: block;
  }

  .login-page .login-container {
    width: 100%;
    padding: 100px 25px 50px;
  }

  .login-page .login-container .pr8rem .df.jcsb {
    flex-wrap: wrap;
  }

  .comapnies-page-font .companies-crousel .first-slide .c15.br30px {
    zoom: .8;
  }

  .main-banner-container span.c4 {
    font-size: 22px !important;
  }

  .main-banner-container .fs2rem {
    font-size: 1rem;
  }

  .main-banner-container .col-sm {
    grid-gap: .5rem;
  }

  .menu-bottom {
    flex-wrap: wrap;
    justify-content: center;
  }

  .menu-bottom .fs1rem-screen1 {
    margin: 0 5px;
  }

  #root[style*="margin-top: 72px"] .narBarAnimationUP, #root[style*="margin-top: 72px"] .narBarAnimation {
    flex-wrap: wrap;
    justify-content: center;
    display: none;
  }

  .carousel .slider-wrapper .slide .c15 {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .carousel .slider-wrapper .slide .fs1rem.c15 {
    font-size: 1rem;
  }

  .carousel-button.prev {
    left: -24px !important;
  }

  .carousel-button.next {
    right: -24px !important;
  }

  .landing-page-font .inner-container {
    padding: 11px 0 0 !important;
  }

  .landing-page-font .inner-container .shadowPaperCrasouel .c15 {
    font-size: 1rem;
    line-height: 1.3rem !important;
  }

  .landing-page-font .inner-container .df.jce.aic img {
    width: 61px !important;
  }

  #homeMainBanner .container .w100.pr.df .br40px.pt1rem.pb1rem.pl3rem.pr2rem {
    padding: 0 1.7rem 20px;
  }

  .quote-area {
    flex-wrap: wrap;
  }

  .quote-area .w90 {
    width: 100%;
  }

  .pricing-title .right-text-for {
    display: none;
  }

  .pricing-title {
    justify-content: center;
    width: 100%;
  }

  #homeMainBanner .container .w90 .fs1-25rem {
    padding: 0;
  }

  .feature-review-img {
    flex-wrap: wrap;
  }

  .feature-review-img img {
    width: 40px;
    height: 40px;
    margin: 15px 0;
  }

  .feature-review-img p {
    padding: 0 0 8px;
    line-height: 1rem !important;
  }

  #homeMainBanner .container .w40 {
    width: 100%;
    margin: 18px auto;
  }

  #homeMainBanner .container .w100.pr.df {
    margin-bottom: 20px;
  }

  .bottom-features .column {
    flex: 0 0 100%;
  }

  .pricing-container {
    padding: 51px 10px;
  }

  .pricing-container .pricing-box {
    width: 100%;
  }

  .ar.pa.blue-circle {
    top: 34px !important;
    right: 29px !important;
  }

  .register-container {
    padding: 70px 45px;
    width: 100% !important;
  }

  .register-container button {
    width: 100% !important;
  }

  .not-sure-container .bsflp.bg1.br10px {
    padding: 27px 15px;
    width: 93% !important;
  }

  #homeMainBanner .container {
    padding: 0 10px !important;
  }

  .client-review-container {
    padding: 45px 13px 60px !important;
  }

  .client-review-container .fs2-125rem {
    text-align: center;
  }

  .fs2-125rem {
    font-size: 1.25rem;
  }

  .review-img img {
    width: 212px !important;
  }

  .landing-page-font .silder-box {
    padding: 1rem;
  }

  .calcluter-sec {
    padding: 2rem 1rem;
  }

  .video-container {
    padding: 49px 10px 0;
  }

  .triangle-vector {
    width: 59px;
    right: 34px !important;
  }

  #homeMainBanner .container .w60 .pl3rem {
    padding-left: 1rem;
  }

  .df.jcc.mt5rem {
    margin-top: 2rem;
  }

  .df.jcc.mt5rem p {
    width: 100%;
  }

  .form-right-image {
    width: 0 !important;
  }

  .fs3-5rem {
    font-size: 2.2rem;
  }

  .privacy-policy-page .policy-box p, .privacy-policy-page .policy-box ol li, .privacy-policy-page .policy-box ul li {
    word-break: break-all;
  }

  .terms-and-conditions-page [style*="width: 41%"] {
    flex-wrap: wrap;
    justify-content: center;
  }

  .privacy-policy-page ul, .privacy-policy-page ol {
    padding-left: 5px;
  }

  .privacy-policy-page .policy-box .fs2rem.p3rem {
    text-align: center;
    padding: 10px;
    font-size: 1.2rem;
  }

  .our-thesis-sec .fs3-5rem {
    font-size: 1.8rem !important;
  }

  .visin-sec .fs3rem {
    font-size: 2rem;
  }

  .story-sec {
    padding: 50px 30px !important;
  }

  .about-carousal-slider {
    padding: 0 15px !important;
  }

  .about-carousal-slider .slide {
    zoom: .2;
    min-width: 100% !important;
  }

  .carousel.carousel-slider {
    padding: 0 0 4rem !important;
  }

  .action-sec.dg .right-area {
    zoom: .5;
    width: 76%;
  }

  .team-sec .fs3-125rem {
    font-size: 1.7rem;
  }

  .bottom-features {
    flex-direction: column;
    width: 100%;
  }

  .bottom-features [style*="width: 20%"] {
    width: 100% !important;
  }

  #homeMainBanner h1 {
    text-align: center;
  }

  .video-container img.w10.pa {
    width: 50px !important;
  }

  .not-sure-container .df.jcsb .scaleAnimation {
    margin-bottom: 43px;
    width: 100% !important;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide .fw400.fs1-25rem.c15.mb1rem {
    font-size: .9rem;
  }

  .carousel .slider-wrapper .slide .fs1rem.c15 {
    width: 78%;
    font-size: 1rem;
  }

  .slider-wrapper .inner-container .row.df.jcsb .df.jce.aic img.h80 {
    height: 49%;
  }

  .keyfeautre-container {
    width: 90%;
    max-width: 90%;
  }

  .keyfeautre-container .keyfeature-box, .keyfeature-box-free .keyfeature-wihte-item {
    box-sizing: border-box;
  }

  .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) {
    width: 100% !important;
  }

  .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) .df.fw500.fs1rem.c15 {
    flex-wrap: wrap;
  }

  .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) a {
    width: 100%;
    margin-top: 16px;
  }

  .how-its-work .blue-animation {
    height: 5% !important;
  }

  .poppins-font-style .inner-container .shadowContainer .df.aic, .poppins-font-style .inner-container .shadowContainer .df.aic p {
    flex-wrap: wrap;
  }

  .poppins-font-style .inner-container .shadowContainer .df.aic span {
    width: 100%;
  }

  .poppins-font-style .inner-container .shadowContainer .df.aic p .cp.w100.df.jce.c1 {
    flex-wrap: nowrap;
    justify-content: center;
    margin: 10px 0;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .poppins-font-style {
    overflow: hidden;
  }

  .investor-box {
    zoom: .3;
  }

  .work-container .fs2-125rem {
    font-size: 25px;
  }

  .main-banner-container .fs3-5rem {
    font-size: 1.3rem;
    line-height: 39px !important;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before {
    top: 47%;
  }

  .slider-wrapper .first-slide .inner-container img.pa.ar.zi1 {
    display: none;
  }

  .pricing-tabs .booking-sec .pricing-bottom-box {
    width: 100%;
    margin: 15px auto !important;
  }

  .pricing-plan-page .df.fdc.aic.mt1rem {
    width: 100% !important;
  }

  .main-banner-container .col-sm.right-img-area .cubes-img {
    top: 46px !important;
    left: -28px !important;
  }

  .poppins-font-style .inner-container .shadowContainer {
    margin-bottom: 24px;
    width: 95% !important;
  }

  .calcluter-sec .w30 img.w90 {
    height: auto;
  }

  .inner-container .row.df.jcc img {
    width: 100% !important;
    height: auto !important;
  }

  .inner-container .df.jce.right-icon-btn {
    width: 94% !important;
  }

  .inner-container .df.jcc.aic img.h60 {
    height: 106px !important;
  }

  .shadowPaperCrasouel .bg19 {
    box-sizing: border-box;
    width: 53px;
    height: 53px;
    margin-bottom: 10px;
    padding: 5px;
  }

  .shadowPaperCrasouel .bg19 svg {
    width: 23px;
  }

  .poppins-font-style .inner-container .shadowContainer {
    zoom: .6;
    width: 98% !important;
  }

  .comapnies-page-font .review-img img {
    width: 100% !important;
  }

  .inner-container .jcsa[style="width: 47%; gap: 25px;"], .inner-container .jcsb[style="width: 47%; gap: 25px;"] {
    width: 98% !important;
  }

  .inner-container .jcsa[style="width: 47%; gap: 25px;"] .shadowContainer, .inner-container .jcsb[style="width: 47%; gap: 25px;"] .shadowContainer {
    box-sizing: border-box;
    width: 100% !important;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem {
    width: 100%;
    padding-top: 4rem;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before {
    border-radius: 31px;
    width: 100%;
    height: 93%;
    top: 53%;
  }

  .keyfeature-box-portfolio-innner:before {
    border-radius: 31px;
    width: 100%;
    height: 100%;
    top: 53%;
  }

  .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w70 {
    width: 100%;
    padding-left: 34px;
    display: flex;
  }

  .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3) {
    order: 5;
    top: 0;
  }

  .investorLookup-container .investor-box {
    zoom: .3;
    margin-top: -56px;
  }

  .form-right-image .triangle-vector {
    display: none;
  }

  svg.pa.cp {
    zoom: .7;
    bottom: 182px !important;
  }

  .bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem .w60, .bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem .w40 {
    width: 100%;
    padding: 0 !important;
  }

  .bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem {
    flex-direction: column;
  }

  .pricing-plan-page .w40.pr.df {
    width: 100%;
  }

  .pricing-plan-page .w60.fs3-125rem {
    padding: 1rem 2rem 0 !important;
  }

  .action-sec h4 {
    font-size: 1.4rem;
    width: 100% !important;
    line-height: 29px !important;
  }

  .partners-page .df.w100 .p2-5rem {
    padding: 1rem;
  }

  .contact-us-page .df.w100 .w35 {
    text-align: end;
    justify-content: end;
    padding: 0;
    display: flex;
    width: 100% !important;
  }

  .contact-us-page .df.w100 .w35 img {
    width: 100%;
  }

  #homeMainBanner .mt4rem .fs2-5rem {
    font-size: 1.1rem;
  }
}

@media (width <= 410px) {
  .main-banner-container span.c4 {
    font-size: 18px !important;
  }

  .main-banner-container .fs2rem {
    font-size: .8rem;
  }

  .main-banner-container .col-sm {
    grid-gap: .4rem;
  }

  .automated-sec .c15 {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .automated-sec .c14 {
    font-size: .7rem !important;
    line-height: 1rem !important;
  }

  .register-container .pt1rem.pb1rem .fs1-125rem {
    margin-left: .4rem;
    font-size: .7rem;
  }

  .privacy-policy-page .w60 .fs3-5rem {
    font-size: 1.8rem;
  }

  #homeMainBanner .fs2-5rem {
    font-size: 1.3rem;
  }

  .main-banner-container .fs3-5rem {
    font-size: 1.2rem;
    line-height: 26px !important;
  }

  .new-header .container {
    padding: 0 10px !important;
  }

  .client-review-container .fs2-125rem {
    text-align: center;
    font-size: 1.4rem;
    line-height: 40px !important;
  }

  .beta-logo {
    top: 16px;
    left: 147px !important;
  }

  .contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df {
    zoom: .3;
  }

  .contact-us-page .df.w100 .w60.fs2-5rem {
    font-size: 1.5rem;
  }
}

@media (width <= 380px) {
  .main-banner-container .fs3-5rem {
    font-size: 1.1rem;
    line-height: 21px !important;
  }

  .contact-us-page .df.w100 .w60.fs2-5rem {
    font-size: 1.2rem;
  }

  .contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df {
    zoom: .2;
  }

  .contact-us-page img.pa.ar {
    zoom: .4;
  }
}

@supports ((-webkit-appearance: none)) and (stroke-color: transparent) {
  .menu-safari {
    z-index: 5;
    background-color: #4a67ff;
    height: 100%;
    right: -100%;
  }

  .menu-safari.r0.showing {
    height: 100%;
    right: -75px;
  }

  .menu-safari:not(.showing).showing {
    right: 0;
  }

  .report-page .sidebar-report .p0-5rem button {
    padding: 5px 8px;
    font-size: 11px;
  }

  .report-page .sidebar-report .mta .fs1-2rem {
    font-size: 1rem;
  }

  .report-page .sidebar-report .mah100.mya.oya.df.fdc.jcsb {
    max-height: 43vh;
  }

  .table-of-content .list-table li:first-child:before, .table-of-content .list-table li:nth-child(2):before, .table-of-content .list-table li:nth-child(3):before {
    width: 70%;
  }

  .table-of-content .list-table li:nth-child(4):before {
    width: 55%;
  }

  .table-of-content.style-two .list-table li:first-child:before, .table-of-content.style-two .list-table li:nth-child(2):before, .table-of-content.style-two .list-table li:nth-child(3):before {
    width: 66%;
  }

  .extenstion-pack-btn-groups .btn-packge .trophy-cup {
    top: 68px;
  }

  .extenstion-pack-btn-groups .btn-packge .trophy-cup img {
    width: 45px !important;
  }

  .sectionWrapper .group-inputs.link-group p a {
    width: 100px;
  }

  .back-btn {
    top: 9px;
    left: -266px;
  }

  #root {
    overflow: hidden;
  }

  .right-text {
    text-align: right;
  }

  .fdrr-sf {
    flex-direction: row-reverse;
  }

  .company-dashboard-sf {
    justify-content: space-between;
    display: flex;
  }

  .company-dashboard-sf .fs1rem {
    font-size: .75rem;
  }

  .company-dashboard-sf .fs0-75rem {
    font-size: .55rem;
  }

  .certificate-area-dashboard {
    zoom: .6;
    margin-top: 24px;
  }

  .certificate-area-dashboard .w7rem {
    width: 4rem;
  }

  .extenstion-pack-sf {
    display: flex;
  }

  .extenstion-pack-sf .button-groups-new {
    width: 15.5%;
    font-size: 12px;
  }

  .extenstion-pack-sf .button-groups-new .percentage-area {
    zoom: .6;
  }

  .extenstion-pack-sf .button-groups-new .tooltip-area.style-two {
    top: -15px;
  }

  .extenstion-pack-sf .certificate-tooltip-box {
    width: 15.5%;
    font-size: 12px;
  }

  .extenstion-pack-sf .certificate-tooltip-box .btn-packge {
    width: 100%;
    font-size: 12px;
  }

  .extanstion-pack-two-sf .btn-packge {
    width: 20%;
    font-size: 12px;
  }

  .extanstion-pack-two-sf .btn-packge.style-two {
    width: 30%;
  }

  .extanstion-pack-two-sf .btn-packge .audit-pakcges {
    width: 295px;
    margin-bottom: 10px;
    padding: 10px 7px;
  }

  .extanstion-pack-two-sf .btn-packge .audit-pakcges .right-area p {
    font-size: 11px;
  }

  .extanstion-pack-two-sf .btn-packge .audit-pakcges .h3rem {
    height: 1rem;
  }

  .extanstion-pack-two-sf .btn-packge .percentage-area {
    zoom: .6;
  }

  .extanstion-pack-two-sf .btn-packge .tooltip-area.style-two {
    top: -15px;
  }

  .extenstion-pack-btn-groups .fs1-5rem {
    font-size: 1rem;
  }

  .extenstion-pack-btn-groups .button-groups-new {
    padding-right: 0;
  }

  .extenstion-pack-btn-groups .btn-packge .ribbon {
    zoom: .7;
  }

  .certificates-page .certificates-current .packge-select {
    zoom: .5;
  }

  .certificates-page .certificates-current .packge-select .w7rem {
    width: 4rem;
  }

  .certificates-page .certificates-current .packge-select .w250px {
    width: 312px;
  }

  .footer-sec .check-box .certification-pricing-column {
    zoom: .6;
    width: 364px;
    right: 150px;
  }

  .pricing-table-saf {
    flex-wrap: wrap;
    display: flex;
  }

  .pricing-table-saf .pricing-column-saf {
    width: 26%;
  }

  .pricing-table-saf .price-box-saf {
    width: 60px;
    height: 60px;
    font-size: 1rem;
  }

  .company-dashboard-sf h4 {
    width: 6rem;
  }

  .company-dashboard-sf .w20.mr2rem {
    margin-right: 16px;
  }

  .company-dashboard-sf .dashboard-btn {
    padding: .4rem;
  }

  .company-dashboard-sf .username-area.mr0-5rem {
    margin-right: 0;
  }

  .company-dashboard-sf .username-area .w2rem {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0;
    font-size: 12px;
  }

  .dropdown-company-dahboard .dropdown-content h4 {
    width: 4rem;
  }

  .dropdown-company-dahboard .dropdown-content .certificate-area-dashboard {
    zoom: .6;
  }

  .container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .footar-saf {
    flex-wrap: wrap;
    display: flex;
  }

  .footar-saf .footer-column {
    width: 47%;
  }

  .report-page .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .report-upload-area-saf {
    flex-wrap: wrap;
    display: flex;
  }

  .report-upload-area-saf > div {
    flex-grow: 1;
    flex-basis: 46%;
  }

  .btn-dataroom-saf {
    justify-content: space-between;
    display: flex;
  }

  .extanstion-pack-two-sf {
    flex-wrap: wrap;
    display: flex;
  }

  .extanstion-pack-two-sf .btn-packges {
    width: 18.5%;
  }

  .extanstion-pack-two-sf .btn-packges .btn-packge {
    width: 100%;
  }

  @media (orientation: landscape) {
    .investor-box .custom-list li {
      font-size: 14px !important;
    }

    .review-img img {
      width: 91% !important;
    }

    .team-sec {
      padding: 3rem 4rem 0;
      position: relative;
    }

    .team-sec .team-about-column .team-box .card-style-about ul li {
      font-size: 16px;
      line-height: 1.2rem !important;
    }

    .keyfeature-box .seemore-box-pro {
      left: 30% !important;
    }

    .shadowPaperCrasouel .right-icon-btn span.fs1rem {
      font-size: 12px !important;
    }

    .keyfeautre-container .keyfeature-box-portfolio {
      zoom: .8;
    }

    .company-searchbox .verify-btn.mr6rem {
      right: 103px;
    }

    .main-banner-container .pt3rem {
      padding-top: 2rem;
    }

    .automated-sec .mb4rem {
      margin-bottom: 3rem;
    }

    .register-container input {
      padding: 10px;
      font-size: .75rem !important;
    }

    .login-container input, .register-container .password-group input, .register-page-container .password-group input, .login-container input {
      padding: 10px 10px 10px 3rem;
    }

    .menu-bottom {
      margin-top: -40px !important;
    }

    .main-banner-container .mt7rem {
      margin-top: 4rem;
    }

    .client-review-container p {
      letter-spacing: 1px;
      font-size: 16px;
      line-height: 25px !important;
    }

    .work-container .work-card-box .fs1-25rem {
      font-size: 16px;
      line-height: 25px !important;
    }

    #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem {
      width: 160px;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user {
      margin-top: -32px !important;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user {
      margin-top: -37px !important;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user {
      margin-top: -50px !important;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user {
      margin-top: -48px !important;
    }

    .video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem {
      width: 477px;
      font-size: 30px;
      line-height: 40.2px !important;
    }

    .register-container .w80 {
      width: 100%;
    }

    .not-sure-container p.w100 {
      width: 85%;
    }

    .work-container .fs1-5rem {
      font-size: 1.25rem;
      line-height: 30px !important;
    }

    .accounts-page-font .client-review-container .review-img img {
      width: 60% !important;
    }

    .accounts-page-font .client-review-container p {
      letter-spacing: .7px;
      font-size: 13px;
      line-height: 17px !important;
    }

    .investor-box .left-certificate-area .certificate-box {
      width: 20% !important;
    }

    .pricing-container .pricing-box .br100 {
      zoom: .7;
    }

    .pricing-container .pricing-title {
      margin-bottom: 2rem;
    }

    .vcs-page-font .review-img img {
      width: 81% !important;
    }

    .register-container input#searchCompany {
      padding: 10px 15px 10px 57px;
    }

    .register-container .shadow.bg1.br5px {
      padding: .7rem;
    }

    .register-container .shadow.bg1.br5px span, .register-container .pr8rem .shadow.bg1 span {
      font-size: 12px;
    }

    .slider-wrapper .inner-container .row.df.jcsb .df.jce.aic img.h80 {
      height: 66%;
    }

    .bottom-button-slider .w50 {
      width: auto;
    }

    .shadowPaperCrasouel span.w70 {
      font-size: 13px;
    }

    .shadowPaperCrasouel span.w30 {
      text-align: center;
      padding: 10px 8px !important;
    }

    .bottom-button-slider .w40 {
      width: 50%;
    }

    .keyfeature-wihte-item {
      width: 18% !important;
    }

    .bottom-button-slider {
      position: relative;
    }

    .inner-container .row.df.jcc img.mb3rem {
      width: 70% !important;
    }

    .inner-container .px6rem {
      padding: 0;
    }

    .main-banner-container.home-page-banner {
      height: 93%;
    }

    .keyfeautre-container .keyfeature-box {
      margin-bottom: 1.5rem;
      padding: 2rem 2rem 0;
    }

    .keyfeautre-container .keyfeature-box .mb2rem {
      margin-bottom: 1rem;
    }

    #homeMainBanner .mt4rem {
      margin-top: 1rem;
    }

    #homeMainBanner .mt4rem .fs2-5rem {
      font-size: 2rem;
    }

    .automated-sec.pt4rem {
      padding-top: 1rem;
    }

    .keyfeature-wihte-item {
      zoom: .9;
      width: 18% !important;
    }

    .register-container .company-searchbox .pt0-5rem svg {
      width: 1.4rem;
    }

    .register-container .company-searchbox .pt0-5rem input {
      padding: 10px 10px 10px 47px;
    }

    .register-container .company-searchbox .pt0-5rem input.verify-btn {
      padding: 8px;
    }

    #homeMainBanner .container {
      padding-left: 12.6% !important;
      padding-right: 12.6% !important;
    }

    .main-banner-container .fs1-5rem {
      margin-top: 2rem;
      font-size: 1.22rem;
    }

    .inner-container p.fs1-25rem {
      font-size: 1rem;
    }

    .work-container {
      max-width: 930px !important;
    }

    .work-container .work-card-box {
      zoom: .8;
    }

    .work-container .fs1-25rem {
      font-size: 1rem;
    }

    .pricing-title .right-text-for {
      padding-right: 8rem;
    }

    .angelsnetwork-page-font .pricing-title .right-text-for, .angels-page-font .pricing-title .right-text-for {
      padding-right: 6rem;
    }

    .vcs-page-font .pricing-title .right-text-for, .pes-page-font .pricing-title .right-text-for {
      padding-right: 4rem;
    }

    .pricing-title .left-text-for {
      padding-left: 2.1rem;
    }

    .visin-sec .w30 img {
      height: 61% !important;
    }

    .main-banner-container {
      padding: 0 165px 0 149px;
    }

    .main-banner-container .fs2rem {
      font-size: 1.1rem;
      line-height: 1.4rem !important;
    }

    .carousel .slide .fs1-25rem {
      font-size: 1rem;
      line-height: 28px !important;
    }

    .review-img img {
      width: 100% !important;
      height: auto !important;
    }

    .work-container .df.jce {
      width: 39px !important;
    }

    .angelsnetwork-page-font .inner-container .row.df.jcc img {
      width: 41% !important;
    }

    .pricing-container .pricing-box img.pr.w100 {
      height: 116px;
    }

    .register-container .fs2-125rem {
      font-size: 1.6rem;
      line-height: 35px !important;
    }

    .register-container .w80 {
      padding-top: 5px;
    }

    .register-container .form-right-image .book-call {
      width: 75% !important;
    }

    .register-container .pt0-5rem.pb0-5rem.pr svg, .register-container .pr.w50 svg {
      transform: translateY(-50%);
      top: 50% !important;
    }

    .register-container .fs1-125rem.pb0-5rem, .register-container .pb1rem.gg1rem {
      padding: 10px 0 0;
    }

    .register-container .pb1rem.gg1rem label {
      font-size: 14px;
    }

    .register-container .registerButtonAnimation {
      margin: 6px 0;
    }

    .pricing-container .pricing-box {
      zoom: .7;
    }

    .video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem {
      width: 459px;
      font-size: 25px;
    }

    .pricing-container .pb1rem.df, .investorLookup-container .pb2rem {
      padding-bottom: 0;
    }

    .investorLookup-container .investor-box {
      margin-top: -56px;
    }

    .button-disabled, .investor-search-btn, .investor-back-btn {
      zoom: .8;
    }

    .investor-modal-database .pr input {
      padding: 10px 15px;
      font-size: 14px;
    }

    .investor-back-btn {
      margin: 0;
      padding: 10px 29px;
    }

    #menu a {
      font-size: 14px;
    }

    #menu a.w200px {
      zoom: .8;
    }

    .automated-sec .fs1rem-screen1, .narBarAnimation .fs1rem-screen1 {
      font-size: 14px;
    }

    .main-banner-container.home-page-banner .pt2rem {
      padding-top: 0;
    }

    .calcluter-sec {
      margin-top: -11px;
      padding-top: 0;
    }

    .not-sure-container .scaleAnimation {
      zoom: .8;
    }

    .shadowPaperCrasouel span.w30 img {
      width: 31px !important;
    }

    .shadowPaperCrasouel span.w50 {
      font-size: 13px;
      padding: 8px !important;
    }

    .keyfeautre-container .keyfeature-box-portfolio {
      padding: 1.5rem 2rem;
    }

    .comapnies-page-font .review-img img {
      width: 74% !important;
      height: auto !important;
    }

    svg.pa.cp, .investment-box-pricing {
      zoom: .7;
    }

    .carousel-button.prev {
      left: 30px;
    }

    .carousel-button.next {
      right: 30px;
    }

    .team-sec .team-about-column .team-box .card-style-about {
      padding: 1rem 3rem 6rem !important;
    }

    .team-sec .team-about-column .team-box {
      zoom: .5;
    }

    .silder-box {
      padding: 3rem 0 4rem;
    }

    .form-right-image .triangle-vector {
      width: 134px;
      bottom: -60px !important;
      right: 364px !important;
    }

    .investorLookup-container .investor-box {
      zoom: .6;
      margin-top: -56px;
    }

    .story-sec p {
      margin-bottom: 10px;
      line-height: 1.5rem !important;
    }

    .silder-box .c15.fw500.df.jcc, .story-sec h4.fw500.mb1-5rem {
      font-size: 37px !important;
      line-height: 2.8rem !important;
    }

    .main-banner-container .pt5rem {
      padding-top: 1rem;
    }

    .silder-box .slick-arrow:before {
      width: 90px;
      height: 90px;
    }

    .ceo-sec .ceo-inner .left-ceo-text blockquote {
      font-size: 13px;
      line-height: 1rem !important;
    }

    .calcluter-sec .secend-sec-btn {
      zoom: .7;
    }

    .automated-sec .c14 {
      font-size: 1rem !important;
      line-height: 1.7rem !important;
    }

    .pricing-container {
      padding: 120px 30px;
    }

    .carousel-root .carousel-button svg {
      width: 84px;
    }

    .shadowPaperCrasouel .fs1-5rem {
      font-size: 1.4rem;
    }

    .shadowPaperCrasouel .bg19 {
      text-align: center;
      width: 37px;
      height: 37px;
      padding: 0;
    }

    .shadowPaperCrasouel .bg19 svg {
      width: 16px;
    }

    .inner-container {
      zoom: .9;
      padding: 15px 120px !important;
    }

    .client-review-container .pt1rem.pb1rem {
      padding-top: .5rem;
      padding-bottom: .5rem;
    }

    .client-review-container .pt1rem.pb1rem input {
      padding: .7rem;
    }

    .client-review-container .pt1rem.pb1rem .w50 svg {
      top: 17px !important;
    }

    .client-review-container .pt1rem.pb1rem svg {
      top: 24px !important;
    }

    .client-review-container .pt1rem.pb1rem .fs1-125rem {
      font-size: 1rem;
    }

    .client-review-container label.pt2rem.pb2rem {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .client-review-container button {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .register-container .pr10rem {
      padding-right: 3rem;
    }

    .action-sec {
      padding: 7rem 5rem !important;
    }

    .accounts-page-font .work-container .fs1rem {
      font-size: .85rem;
    }

    .fs3-125rem {
      font-size: 2.8rem;
    }

    .card-style-about {
      padding: 1rem 2rem 7rem !important;
    }

    .beta-logo {
      width: 61px;
      height: 37px;
      margin-top: 8px;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem {
      width: 148px;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem {
      width: 350px;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, .comapnies-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, .comapnies-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem {
      width: 153px;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem {
      width: 200px;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem {
      width: 170px;
    }

    #homeMainBanner .container .testmonial-user {
      margin-top: 0 !important;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem {
      width: 212px;
    }

    .angels-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem {
      width: 126px;
    }

    .vcs-page-font .client-review-container p {
      font-size: 11px;
      line-height: 16px !important;
    }

    #openLoginPopup {
      zoom: .8;
      width: 150px !important;
    }

    .registerButtonAnimation, .see-aal-features {
      zoom: .8;
    }

    .register-container .registerButtonAnimation {
      width: auto !important;
      padding: 9px 40px !important;
    }

    .register-container button.fs1-25rem {
      zoom: .8;
      width: auto !important;
      height: 100% !important;
      padding: 9px 40px !important;
    }

    .calcluter-sec, .main-banner-container.home-page-banner .pt4rem {
      padding-top: 1rem;
    }

    .main-banner-container.home-page-banner .pt4rem .fs2-5rem {
      font-size: 1.5rem;
    }

    .main-banner-container.home-page-banner .fs1-25rem {
      font-size: 13px;
    }

    .main-banner-container.home-page-banner .fs3rem, .main-banner-container.home-page-banner .fs3rem .c4 {
      margin-top: 0;
      font-size: 58px !important;
      line-height: 62px !important;
    }

    .main-banner-container .col-sm.df.jcc.fdc.gg2rem {
      padding-right: 2rem;
      width: 60% !important;
    }

    .calcluter-sec .w60 a {
      zoom: .8;
    }

    .main-banner-container .fs1-75rem {
      font-size: 1.3rem;
    }

    .home-page-menu.automated-sec .menu-bottom {
      margin-top: -76px !important;
    }

    .register-not-sure-sec .not-sure-register .not-sure-container .fs2rem {
      font-size: 1.5rem;
      line-height: 26px !important;
    }

    .register-not-sure-sec .not-sure-register .not-sure-container p {
      font-size: 14px;
    }

    .register-container-not-sure .form-right-image {
      right: -33% !important;
    }

    .register-container-not-sure .form-right-image .book-call {
      width: 60% !important;
    }

    .inner-container .df.jce {
      justify-content: center;
      margin: 0 auto;
      gap: 8px !important;
      width: 16% !important;
    }

    .comapnies-page-font .companies-crousel .first-slide .c15.br30px .icon .w30px {
      width: 22px;
    }

    .inner-container .df.jce.right-icon-btn {
      justify-content: end;
      gap: 0 !important;
      width: 100% !important;
    }

    .shadowPaperCrasouel .right-icon-btn span.fs1rem {
      padding: 10px 20px !important;
    }

    .bottom-button-slider, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
      zoom: .8;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem {
      padding-top: 5rem;
    }

    .accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img {
      width: 300px !important;
      height: auto !important;
    }

    .home-page-banner .col-sm.df.jce.pr {
      width: 45% !important;
    }

    .keyfeature-wihte-item {
      margin: 0 10px 30px !important;
    }

    .keyfeature-wihte-item .fs1rem.c15 {
      font-size: 12px !important;
    }

    .keyfeature-wihte-item .seemore-box {
      width: 999px !important;
    }

    .investorLookup-container {
      padding: 0 70px 70px;
    }

    .main-banner-container span.c4 {
      font-size: 30px !important;
      line-height: 38px !important;
    }

    .automated-sec .c14 {
      padding-top: 1rem;
      font-size: 1rem !important;
      line-height: 1.3rem !important;
    }

    .fs2rem {
      font-size: 1.5rem;
    }

    .fs1-5rem {
      font-size: 1rem;
    }

    .fs1rem {
      font-size: .75rem;
    }

    .work-container .pt1-5rem.pb1rem {
      padding: .5rem 0;
    }

    .work-container .pb1-5rem.df.jcsb {
      height: 50px !important;
      min-height: 50px !important;
    }

    .video-container p.fs2-125rem {
      line-height: 40px !important;
    }

    .not-sure-container .fs1-5rem {
      font-size: 1rem;
      line-height: 30px !important;
    }

    .not-sure-container .mt1rem button {
      font-size: 14px;
      padding: 10px 22px !important;
    }

    .not-sure-container .bsflp.bg1.br10px {
      padding: 27px 15px;
    }

    .not-sure-container .bsflp.bg1.br10px .pa.df.jcc.aic.p2rem.bg3 {
      padding: 1rem;
      top: -33px !important;
    }

    .not-sure-container .bsflp.bg1.br10px .pa.df.jcc.aic.p2rem.bg3 img {
      width: 100%;
    }

    .not-sure-container .mt7rem.mb3rem {
      margin-top: 4rem;
      margin-bottom: 2rem;
    }

    #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem {
      width: 200px;
    }

    #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem {
      width: 112px;
    }

    #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem {
      width: 147px;
    }

    #homeMainBanner .container .w60 .fs1-5rem {
      font-size: 1.2rem !important;
    }

    #homeMainBanner .container .custom-list li {
      font-size: 17px;
    }

    .story-sec {
      padding: 3rem 5rem !important;
    }

    .accounts-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem {
      width: 245px;
    }

    .not-sure-container p.w100 {
      width: 100%;
      margin-bottom: 30px;
    }

    .lawyers-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem {
      width: 254px;
    }

    #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem {
      width: 225px;
    }

    #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem {
      width: 195px;
    }

    .client-review-container .mb2-5rem {
      margin-bottom: 1rem !important;
    }

    .client-review-container .mt4rem {
      margin-top: 2rem;
    }

    .angelsnetwork-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem {
      width: 200px;
    }

    .angels-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem {
      width: 140px;
    }

    .angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem {
      width: 263px;
    }

    .angels-page-font #homeMainBanner .container:nth-child(13) .w60 .fs1-5rem {
      width: 243px;
    }

    .main-banner-container.home-page-banner {
      padding-top: 10px;
    }

    .right-img-area {
      width: 42% !important;
      height: 290px !important;
      margin-top: 211px !important;
    }

    .shadowPaperCrasouel .fs1rem {
      font-size: 1rem;
    }

    .comapnies-page-font .companies-crousel .first-slide .new-shadow .icon {
      width: 18px;
    }

    .register-not-sure-sec .not-sure-register .not-sure-container h1 {
      font-size: 1rem;
    }

    .pricing-tabs .booking-sec .pricing-bottom-box {
      zoom: .8;
    }

    .pricing-tabs .pricing-advisor-table .pricing-box .shadow.bg1 {
      zoom: .7;
    }

    .pes-page-font .review-img img {
      width: 100% !important;
    }

    .angelsnetwork-page-font .review-img img {
      margin-top: 40px;
      width: 100% !important;
    }

    .top-pricing-area {
      zoom: .8;
      top: -99px;
    }

    .top-pricing-area .w100.df.jcc .br100 {
      zoom: .9;
    }

    .team-sec .team-about-column .team-box img.team-img {
      width: 63%;
    }

    .story-sec:after {
      width: 9.688rem;
      height: 9.688rem;
      right: -2.25rem;
    }

    .story-sec:before {
      width: 9.813rem;
      height: 9.813rem;
      top: -15%;
      left: -2.5%;
    }

    .animation-img-box, .animation-img-box-down {
      width: 69px;
      bottom: 119px;
      right: -79px;
    }

    .inner-container.style-two .row {
      width: 700px !important;
    }

    .main-banner-container .gg2rem {
      grid-gap: 1rem;
    }

    .calcluter-sec {
      margin-top: -32px;
    }

    .calcluter-sec .p2rem {
      padding: 1rem;
    }

    .automated-sec .w60 {
      width: 63%;
    }

    .work-container .pt1-5rem.pb1rem {
      font-size: .75rem;
      line-height: 27px !important;
    }

    .fs1-25rem {
      font-size: .98rem;
    }

    .privacy-policy-page .w60 .fs3-5rem {
      top: 142px !important;
    }

    .privacy-policy-page .policy-box {
      padding: 0 60px 60px;
    }

    .team-sec .card-style-about {
      padding: 1rem 2rem 5rem !important;
    }

    .team-sec ul li {
      font-size: .75rem;
    }

    .team-sec button {
      padding: .7rem 2.125rem !important;
    }

    .team-sec button img {
      width: 30px;
    }

    .team-sec .fs3-125rem {
      font-size: 2.5rem;
    }

    .accounts-page-font .work-container .how-its-work-circle {
      top: -24px !important;
      left: -27px !important;
    }

    .calcluter-sec .fs3rem {
      font-size: 1.8rem;
    }

    .main-banner-container .mt7rem {
      margin-top: 2rem;
      font-size: 1.2rem;
    }

    .work-container .fs2-125rem {
      font-size: 39px;
    }

    .not-sure-container p.w100 {
      font-size: 12px !important;
    }

    .carousel .slide .fs1-25rem {
      width: auto;
      font-size: 12px;
      line-height: 21px !important;
    }

    .shadowPaperCrasouel .fs1-5rem {
      font-size: 1.2rem;
      line-height: 27px !important;
    }

    #homeMainBanner .fs2-5rem {
      font-size: 2rem;
    }

    .investor-box .list-right-area .benefits-box {
      min-height: 276px;
      line-height: 19px !important;
    }

    .custom-list li {
      font-size: 13px !important;
    }

    .calcluter-sec .fs2rem {
      font-size: 1.2rem;
    }

    .main-banner-container span.c4, .carousel .slide .fs2-125rem {
      font-size: 25px !important;
      line-height: 29px !important;
    }

    .comapnies-page-font .silder-box {
      padding: 1rem 0;
    }

    .not-sure-container .mt6rem.mb3rem {
      margin-top: 3rem;
      margin-bottom: 0;
    }

    .not-sure-container .p2rem.bg3 {
      zoom: .7;
    }

    .not-sure-container .mt5rem {
      margin-top: 3rem;
    }

    .calcluter-sec .fs2-5rem {
      font-size: 1.4rem;
    }

    .carousel.carousel-slider .df .h60 {
      width: 68px !important;
    }

    .login-container .MuiFormControl-root p {
      bottom: -25px;
    }

    .shadowPaperCrasouel span.w30 {
      height: 100% !important;
      padding: 10px 4px !important;
    }

    .keyfeature-box-portfolio-bg .w30 {
      padding-left: 5rem;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
      padding: 1rem;
    }

    .for-yoy-box {
      font-size: .7rem;
    }

    .keyfeature-box-portfolio-bg .w30 p.pr3rem {
      padding-right: 1rem;
    }

    .fs2-125rem {
      font-size: 1.65rem;
    }

    .keyfeautre-container .keyfeature-box-portfolio {
      justify-content: center;
    }

    .fs1-75rem {
      font-size: 1.25rem;
    }

    .mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80 {
      zoom: .8;
    }

    .angels-page-font .client-review-container p {
      font-size: 12px;
      line-height: 17px !important;
    }

    .inner-container p.fs1-25rem {
      font-size: .85rem;
      line-height: 1.2rem !important;
    }

    #homeMainBanner .container {
      max-width: 100% !important;
      padding-left: 10% !important;
      padding-right: 10% !important;
    }

    .privacy-policy-page .policy-box p {
      line-height: 24px !important;
    }

    .visin-sec {
      padding-bottom: 3rem;
    }

    .visin-sec .container {
      align-items: center;
    }

    .visin-sec .w30 img {
      width: 100%;
      height: 100% !important;
      margin-top: 106px !important;
    }

    .automated-sec .c15 {
      font-size: 20px !important;
      line-height: 24px !important;
    }

    .client-review-container p {
      font-size: .65rem;
      line-height: 15px !important;
    }

    #homeMainBanner .container {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .quote-area p {
      font-size: 9px;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user {
      margin-top: -21px !important;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user {
      margin-top: -9px !important;
    }

    .register-container-not-sure .pr8rem {
      padding-right: 11rem;
    }

    .not-sure-container .scaleAnimation a {
      zoom: .7;
    }

    .register-container-not-sure .form-right-image {
      bottom: -6px !important;
      right: -37% !important;
    }

    .inner-container .px6rem {
      padding-left: 0;
      padding-right: 0;
    }

    .df.bg1.pr.brne.bscov.h90vh {
      height: 95vh;
    }

    .home-page-menu.automated-sec .menu-bottom {
      margin-bottom: 15px;
      margin-top: -51px !important;
    }

    .inner-container .df.jce img.h70 {
      height: 101px;
    }

    .comapnies-page-font .companies-crousel .first-slide .c15.br30px {
      zoom: .7;
    }

    .client-review-container .fs2-125rem {
      font-size: 27px;
    }

    .angels-page-font .client-review-container p {
      line-height: 18px !important;
    }

    .shadowPaperCrasouel {
      width: 100%;
    }

    span.wid-btn {
      width: 60%;
    }

    .automated-sec .w80 {
      width: 66%;
    }

    .automated-sec .c14 {
      font-size: .9rem !important;
    }

    .not-sure-container {
      padding: 100px 118px;
    }

    .accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img {
      width: 100% !important;
    }

    .video-container img.w10.pa {
      width: 60px !important;
    }

    .calcluter-sec {
      width: 88%;
    }

    .poppins-font-style {
      overflow: hidden;
    }

    .investor-box {
      zoom: .7;
    }

    .calcluter-sec .fs3rem {
      font-size: 1.5rem;
      line-height: 35px !important;
    }

    #homeMainBanner .container:nth-child(odd) .w60 {
      padding-left: 10rem;
    }

    #homeMainBanner .container:nth-child(2n) .w60 {
      padding-right: 6rem;
    }

    #homeMainBanner .container .w90 .fs1-25rem {
      font-size: .8rem;
      line-height: 21px !important;
    }

    #homeMainBanner .container .w100.pr.df {
      margin-top: 10px;
    }

    .register-container {
      padding: 70px 0 70px 95px;
    }

    .register-container .pr8rem {
      padding-right: 0;
    }

    .triangle-vector {
      width: 150px;
      right: 50% !important;
    }

    .pricing-container .pl8rem.pr8rem {
      padding: 0;
    }

    .pricing-container .pricing-box {
      width: 21%;
    }

    .privacy-policy-page .w60 {
      width: 82%;
    }

    .fs3-5rem {
      font-size: 3rem;
    }

    .terms-and-conditions-page [style*="width: 41%"] {
      width: 60% !important;
    }

    .our-thesis-sec img.ar.pa {
      width: 68px;
    }

    .visin-sec .fs3rem {
      font-size: 2.5rem;
    }

    .about-carousal-slider .slide {
      zoom: .6;
    }

    .action-sec .fs2-5rem {
      font-size: 1.8rem;
      line-height: 2.4rem !important;
    }

    .accounts-page-font .work-container {
      padding: 70px 30px;
    }

    .accounts-page-font .work-container .df.jcsb .pb1-5rem.df.jcsb {
      flex-wrap: wrap;
      align-items: center !important;
    }

    .accounts-page-font .work-container .df.jcsb .pb1-5rem.df.jcsb .w80 {
      width: 90%;
    }

    .accounts-page-font .work-container .df.jcsb .pb1-5rem.df.jcsb .df.jce {
      width: 20px !important;
    }

    .how-its-work .blue-animation {
      height: 15% !important;
    }

    .comapnies-page-font .main-banner-container, .accounts-page-font .main-banner-container, .lawyers-page-font .main-banner-container, .vcs-page-font .main-banner-container, .pes-page-font .main-banner-container, .angelsnetwork-page-font .main-banner-container, .angels-page-font .main-banner-container {
      hight: 56.5vh;
    }

    .right-img-area {
      height: 215px !important;
      margin-top: -22px !important;
    }

    .register-container-not-sure {
      padding: 106px 191px 28px 57px;
    }

    .main-banner-container .col-sm.df.jcc.fdc.gg2rem .fs1-25rem {
      font-size: 11px;
    }

    .inner-container {
      padding: 11px 103px !important;
    }

    .keyfeature-box-portfolio-bg .w30 {
      padding-top: 6rem;
      padding-left: 5rem;
    }

    .pricing-table-saf {
      width: 760px;
    }

    .beta-logo {
      width: 61px;
      height: 37px;
      margin-top: 0;
      position: absolute;
      top: 17px;
      left: 283px;
    }

    .pricing-tabs .pricing-advisor-table .pricing-box {
      width: 46%;
    }

    .pricing-plan-page .w60.fs3-125rem {
      font-size: 2.5rem;
      padding-left: 9rem !important;
    }

    .pricing-plan-page .df.w100.pt6rem {
      padding-top: 2rem;
    }

    .slider-wrapper .first-slide .inner-container img.pa.ar.zi1 {
      width: 40px !important;
    }

    .register-page-container {
      width: 90%;
      padding: 5px 0 5px 100px;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user {
      margin-top: 1px !important;
    }

    .pricing-tabs .booking-sec .pricing-bottom-box {
      min-height: auto;
    }

    .pricing-tabs .pricing-box:nth-child(3), .pricing-tabs .pricing-box:nth-child(4) {
      margin-bottom: 0;
    }

    .pricing-tabs .booking-sec {
      width: 110%;
    }

    .pricing-tabs .booking-sec .pricing-bottom-box {
      margin-left: -14px;
    }

    .pricing-tabs .pricing-box {
      margin-bottom: 13rem;
    }

    .client-review-container {
      padding: 45px 59px 60px !important;
    }

    .work-container {
      padding: 70px 45px;
    }

    .bottom-features {
      width: 100%;
    }

    .team-sec {
      padding: 2rem 30px 1rem;
    }

    .our-thesis-sec {
      padding-left: 3rem;
    }

    .visin-sec .container {
      padding: 0 2rem !important;
    }

    .accounts-page-font .work-container .df.jcsb .pb1-5rem.df.jcsb {
      flex-wrap: nowrap;
    }

    .accounts-page-font .work-container .fs1-5rem {
      font-size: 1rem;
    }

    .home-page-bg {
      height: 600px !important;
    }

    .main-banner-container .fs3-5rem .fs5rem {
      font-size: 2.5rem;
    }

    .right-img-area {
      justify-content: flex-start;
      height: 212px !important;
      margin-top: 39px !important;
    }

    .right-img-area .person-img {
      height: 118% !important;
    }

    .main-banner-container .col-sm.df.jcc.fdc.gg2rem {
      padding-right: 3rem;
    }

    .calcluter-sec .c15 {
      font-size: 1rem;
      line-height: 23px !important;
    }

    .calcluter-sec .fdc.pl2rem {
      padding-left: 0;
    }

    .keyfeautre-container {
      width: 74%;
    }

    .comapnies-page-font .keyfeautre-container {
      width: 82%;
    }

    .main-banner-container .fs3-5rem {
      font-size: 1.4rem;
      line-height: 39px !important;
    }

    .video-container {
      padding: 32px 123px;
    }

    .slider-wrapper .first-slide .inner-container img {
      width: 70% !important;
    }

    .poppins-font-style .inner-container .shadowContainer {
      zoom: .5;
    }

    .inner-container .df.jcc.aic img.h60 {
      height: 74px !important;
    }

    .angels-page-font .inner-container img.mb6rem {
      margin-bottom: 0;
      width: 89% !important;
    }

    .main-banner-container .col-sm.right-img-area .cubes-img {
      width: 129px !important;
      top: 50px !important;
      left: -37px !important;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg, .keyfeautre-container .keyfeature-box {
      zoom: .8;
    }

    .keyfeature-wihte-item {
      padding: 1rem;
      width: 18% !important;
    }

    .work-container {
      zoom: .8;
    }

    .not-sure-register .not-sure-container {
      padding: 30px 96px 30px 173px !important;
    }

    .register-not-sure-sec {
      zoom: .6;
    }

    .client-review-container {
      zoom: .95;
    }

    .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) {
      width: 38% !important;
    }

    .comapnies-page-font .review-img img {
      width: 64% !important;
    }

    .keyfeature-wihte-item {
      zoom: .7;
    }

    .df.bg1.pr.brne.bscov.h90vh {
      zoom: .6;
      height: 100%;
    }

    .msg-btn, .top-arrow-bt {
      zoom: .8;
    }

    .comapnies-page-font .main-banner-container, .accounts-page-font .main-banner-container, .lawyers-page-font .main-banner-container, .vcs-page-font .main-banner-container, .pes-page-font .main-banner-container, .angelsnetwork-page-font .main-banner-container, .angels-page-font .main-banner-container {
      height: 389px !important;
    }

    .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item {
      width: 23.2% !important;
    }

    .dahboard-tabs .MuiTabPanel-root .dashboard-firm-tab .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details.w90 .lock-icon {
      zoom: .8;
      margin-right: 1rem;
    }

    .table-advisor {
      zoom: .8;
    }

    .client-tab .user-area {
      width: 82%;
    }

    .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
      zoom: .8;
    }

    .dropdown-company-dahboard .reprot-btn-dropdowns {
      width: 250px;
    }

    .dahboard-tabs .MuiTabPanel-root .add-btn-dashboard, .banner-advisor-dashboard, .table-permission .table-userPermissions {
      zoom: .8;
    }

    #expandHeader .pr.df a svg, #expandHeader .pr.df span.usn svg {
      width: 22px;
    }

    #expandHeader .c1.c6.w100.jcsb.td500.df.fdc.jcc.wsn {
      position: relative;
      left: 5px;
    }

    #expandHeader .pr.df a, #expandHeader .pr.df span.usn {
      padding: 8px 15px 8px 40px;
    }

    .bottom-button-slider, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
      zoom: .7;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .pt10rem {
      padding-top: 8rem;
    }

    .dropdown-company-dahboard .w60px.mr2rem {
      margin-right: 1rem;
    }

    .dropdown-company-dahboard .w100 .w200px {
      width: 150px;
    }

    .dahboard-tabs .MuiTabPanel-root .fs1-75rem {
      font-size: 1.5rem;
    }

    .dahboard-tabs .MuiTabPanel-root .fs1-5rem {
      font-size: 1.25rem;
    }

    .banner-advisor-dashboard, .table-permission .table-userPermissions {
      zoom: .7;
    }

    .dashboard-tools-area h2 {
      font-size: 1.4rem;
    }

    .advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon {
      font-size: 14px;
    }

    #expandHeader .h90vh {
      height: 82vh;
    }

    #expandHeader:hover #logo {
      width: 7rem;
    }

    #expandHeader #logo {
      margin-bottom: .4rem;
    }

    .advisor-dashboard-seemore .m1rem.pr.br10px.pr, .progress-box-vertical-dashboard {
      zoom: .8;
    }

    .dropdown-company-dahboard {
      zoom: .82;
    }

    .advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon {
      margin: 0 9px;
      font-size: 14px;
    }

    .progress-area.progress-dashboard {
      zoom: .9;
    }
  }

  @media (orientation: portrait) {
    .main-banner-container .gg2rem {
      grid-gap: 1rem;
    }

    .calcluter-sec {
      margin-top: -32px;
    }

    .calcluter-sec .p2rem {
      padding: 1rem;
    }

    .automated-sec .w60 {
      width: 63%;
    }

    .work-container .pt1-5rem.pb1rem {
      font-size: .75rem;
      line-height: 27px !important;
    }

    #homeMainBanner .container {
      max-width: 100% !important;
      padding-left: 11% !important;
      padding-right: 11% !important;
    }

    .privacy-policy-page .w60 .fs3-5rem {
      top: 142px !important;
    }

    .team-sec .card-style-about {
      padding: 1rem 2rem 5rem !important;
    }

    .team-sec ul li {
      font-size: .75rem;
    }

    .team-sec button {
      padding: .7rem 2.125rem !important;
    }

    .team-sec button img {
      width: 30px;
    }

    .accounts-page-font .work-container .how-its-work-circle {
      top: -24px !important;
      left: -27px !important;
    }

    .calcluter-sec .fs3rem {
      font-size: 1.8rem;
    }

    .main-banner-container .mt7rem {
      margin-top: 2rem;
      font-size: 1.2rem;
    }

    .not-sure-container p.w100 {
      font-size: 12px !important;
    }

    .carousel .slide .fs1-25rem {
      width: auto;
      font-size: 12px;
      line-height: 21px !important;
    }

    .shadowPaperCrasouel .fs1-5rem {
      font-size: 1.2rem;
      line-height: 27px !important;
    }

    .investor-box .list-right-area .benefits-box {
      min-height: 276px;
      line-height: 19px !important;
    }

    .custom-list li {
      font-size: 13px !important;
    }

    .calcluter-sec .fs2rem {
      font-size: 1.2rem;
    }

    .main-banner-container span.c4, .carousel .slide .fs2-125rem {
      font-size: 25px !important;
      line-height: 29px !important;
    }

    .comapnies-page-font .silder-box {
      padding: 1rem 0;
    }

    .not-sure-container .mt6rem.mb3rem {
      margin-top: 3rem;
      margin-bottom: 0;
    }

    .not-sure-container .p2rem.bg3 {
      box-sizing: border-box;
      padding: 1.2rem;
      zoom: .7 !important;
    }

    .not-sure-container .mt5rem {
      margin-top: 3rem;
    }

    .silder-box .slick-arrow {
      width: 30px !important;
      height: 30px !important;
    }

    .calcluter-sec .fs2-5rem {
      font-size: 1.4rem;
    }

    .carousel.carousel-slider .df .h60 {
      width: 68px !important;
    }

    .login-container .MuiFormControl-root p {
      bottom: -25px;
    }

    .shadowPaperCrasouel span.w30 {
      height: 100% !important;
      padding: 10px 4px !important;
    }

    .keyfeature-box-portfolio-bg .w30 {
      padding-left: 5rem;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
      padding: 1rem;
    }

    .for-yoy-box {
      font-size: .7rem;
    }

    .keyfeature-box-portfolio-bg .w30 p.pr3rem {
      padding-right: 1rem;
    }

    .keyfeautre-container .keyfeature-box-portfolio {
      justify-content: center;
    }

    .fs1-75rem {
      font-size: 1.25rem;
    }

    .angels-page-font .client-review-container p {
      font-size: 12px;
      line-height: 17px !important;
    }

    .right-img-area .person-img {
      width: 100%;
      height: 326px !important;
    }

    .calcluter-sec .pl2rem {
      padding-left: 0;
    }

    .register-not-sure-sec .register-container-not-sure {
      width: 100%;
    }

    .register-not-sure-sec .form-right-image {
      bottom: -6px !important;
      right: -28% !important;
    }

    .register-not-sure-sec .not-sure-register {
      width: 100%;
    }

    .register-not-sure-sec .not-sure-register .not-sure-container {
      padding: 78px 30px !important;
    }

    .register-not-sure-sec .not-sure-register .not-sure-container .fdc {
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }

    .register-not-sure-sec .not-sure-register .not-sure-container .fdc .scaleAnimation {
      text-align: center;
      flex-direction: column;
      margin: 0 15px;
      display: flex;
    }

    .keyfeature-box-portfolio-bg .w30 {
      width: 100%;
      padding-top: 3rem;
      padding-left: 0;
    }

    .keyfeature-box-portfolio-bg .w70 {
      width: 100%;
      padding-left: 6rem;
    }

    .automated-sec .w80 {
      width: 66%;
    }

    .automated-sec .c14 {
      font-size: .9rem !important;
    }

    .accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img {
      width: 100% !important;
    }

    .calcluter-sec .fs3rem {
      font-size: 1.5rem;
      line-height: 35px !important;
    }

    #homeMainBanner .container .w90 .fs1-25rem {
      font-size: .8rem;
      line-height: 21px !important;
    }

    #homeMainBanner .container .w100.pr.df {
      margin-top: 10px;
    }

    .privacy-policy-page .w60 {
      width: 82%;
    }

    .terms-and-conditions-page [style*="width: 41%"] {
      width: 60% !important;
    }

    .action-sec .fs2-5rem {
      font-size: 1.8rem;
      line-height: 2.4rem !important;
    }

    .accounts-page-font .work-container {
      padding: 70px 30px;
    }

    .accounts-page-font .work-container .df.jcsb .pb1-5rem.df.jcsb {
      flex-wrap: wrap;
      align-items: center !important;
    }

    .accounts-page-font .work-container .df.jcsb .pb1-5rem.df.jcsb .w80 {
      width: 90%;
    }

    .accounts-page-font .work-container .df.jcsb .pb1-5rem.df.jcsb .df.jce {
      width: 20px !important;
    }

    .comapnies-page-font .main-banner-container, .accounts-page-font .main-banner-container, .lawyers-page-font .main-banner-container, .vcs-page-font .main-banner-container, .pes-page-font .main-banner-container, .angelsnetwork-page-font .main-banner-container, .angels-page-font .main-banner-container {
      hight: 56.5vh;
    }

    .register-container-not-sure {
      padding: 106px 191px 28px 57px;
    }

    .main-banner-container .col-sm.df.jcc.fdc.gg2rem .fs1-25rem {
      font-size: 11px;
    }

    .keyfeature-box-portfolio-bg .w30 {
      padding-top: 6rem;
      padding-left: 5rem;
    }

    .beta-logo {
      width: 61px;
      height: 37px;
      margin-top: 0;
      position: absolute;
      top: 17px;
      left: 283px;
    }

    .pricing-plan-page .df.w100.pt6rem {
      padding-top: 2rem;
    }

    .bottom-features {
      width: 100%;
    }

    .our-thesis-sec {
      padding-left: 3rem;
    }

    .visin-sec .container {
      padding: 0 2rem !important;
    }

    .accounts-page-font .work-container .df.jcsb .pb1-5rem.df.jcsb {
      flex-wrap: nowrap;
    }

    .accounts-page-font .work-container .fs1-5rem {
      font-size: 1rem;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user {
      margin-top: 1px !important;
    }

    .pricing-tabs .pricing-box:nth-child(3), .pricing-tabs .pricing-box:nth-child(4) {
      margin-bottom: 0;
    }

    .pricing-tabs .booking-sec .pricing-bottom-box {
      margin-left: -14px;
    }

    .pricing-tabs .pricing-box {
      margin-bottom: 13rem !important;
    }

    .certification-pricing-column {
      position: relative;
      top: 0;
      right: auto !important;
    }

    .certificates-page .heading-area {
      background-size: 100% 114%;
    }

    .certificates-page .certificates-current .packge-select {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .certificates-page .certificates-current .packge-select .audit-pakcges {
      width: 26%;
      margin: 0;
    }

    .certificates-page .badges-sec .badges-area {
      flex-wrap: wrap;
    }

    .certificates-page .badges-sec .badges-area .left-upgrade-button {
      text-align: center;
      margin-top: 0;
      margin-bottom: 20px;
      margin-right: 0;
      position: relative;
    }

    .certificates-page .data-check-sec .gtcr3 {
      grid-template-columns: repeat(2, 1fr);
    }

    .investmentcertificate-page .certificate-area .left-area .signure-area .date-area.singature-area {
      margin-top: 0;
    }

    .table-of-content {
      width: 91%;
    }

    .progress-dashboard .progress-box {
      margin-top: 10px;
    }

    .progress-dashboard .progress-box .progress-bar {
      width: 40px !important;
      height: 40px !important;
    }

    .progress-dashboard .progress-box .text-center-area {
      font-size: 12px !important;
    }

    .investor-modal-admin .jcsb .ml1rem.w100 {
      margin: 0;
    }

    .investor-modal-admin .jcsb .mr1rem {
      margin-bottom: 20px;
      margin-right: 0 !important;
    }

    .register-container {
      width: 100%;
      padding: 70px 0 70px 30px;
    }

    .not-sure-container {
      padding: 70px 30px;
    }

    .inner-container {
      justify-content: center;
      display: flex;
      padding: 11px 15px !important;
    }

    .register-not-sure-sec .form-right-image {
      display: none;
    }

    .work-container {
      max-width: 90% !important;
    }

    .poppins-font-style .inner-container .shadowContainer {
      zoom: .7;
      margin: 0 auto 35px;
      width: 80% !important;
    }

    .poppins-font-style .inner-container .df.fdc.jcsb {
      margin: 0 auto;
      width: 77% !important;
    }

    .poppins-font-style .inner-container .df.fdc.jcsb .shadowContainer {
      width: 100% !important;
    }

    .inner-container .row.df.jcsb .df.jce.aic {
      justify-content: center;
      width: 100% !important;
    }

    .inner-container .row.df.jcsb {
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
      width: 46% !important;
    }

    .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3) {
      top: 0;
    }

    .keyfeature-box-portfolio-innner:before, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before {
      border-radius: 50px;
      width: 97%;
      left: 0;
    }

    .keyfeature-box-portfolio-bg .w70 {
      padding-left: 2rem;
    }

    .inner-container .df.fdc.jcsa {
      width: 100% !important;
    }

    .team-sec .team-about-column {
      flex-wrap: wrap;
      justify-content: center;
    }

    .inner-container.style-two .row, .calcluter-sec .w30, .calcluter-sec .w70 {
      width: 100%;
    }

    .poppins-font-style .inner-container .shadowContainer {
      padding: 15px;
      width: 100% !important;
    }

    .comapnies-page-font .companies-crousel .first-slide .c15.br30px .icon img {
      vertical-align: middle;
    }

    .register-container {
      padding: 70px 15px;
    }

    .slider-wrapper .inner-container .row.df.jcsb .df.jce.aic img.h80 {
      zoom: .3;
      height: 100%;
    }

    .poppins-font-style .inner-container .shadowContainer .df.aic p .cp.w100.df.jce.c1 {
      flex-wrap: nowrap;
      justify-content: center;
      margin: 10px 0;
    }

    .beta-logo {
      width: 60px;
      height: 40px;
      top: 4px;
      left: 147px !important;
    }

    .calcluter-sec {
      width: 100%;
    }

    .calcluter-sec .secend-sec-btn {
      width: 90% !important;
    }

    button.carousel-button svg {
      width: 90px;
    }

    .client-review-container p {
      font-size: 13px;
      line-height: 22px !important;
    }

    .work-card-box .df.jce.pr svg {
      width: 33px;
      height: 48px;
    }

    .work-card-box .df.jce.pr img {
      width: auto;
      height: 40px;
      top: 32px;
      right: 0;
    }

    .register-container .pr8rem {
      padding-right: 15px;
    }

    .register-container .w50 {
      margin-bottom: 20px;
    }

    .register-not-sure-sec .not-sure-register .not-sure-container .fdc .scaleAnimation .bg3 {
      box-sizing: border-box;
    }

    .register-not-sure-sec .not-sure-register .not-sure-container .fdc {
      margin-top: 27px;
    }

    .footar-saf .footer-column {
      width: 100%;
    }

    .first-slide .inner-container .px6rem {
      padding: 0;
    }

    .carousel .slide .fs2-125rem {
      font-size: 26px;
    }

    .carousel .slider-wrapper .slide .w70 {
      width: 90%;
      text-align: left !important;
    }

    .poppins-font-style .inner-container .shadowContainer .fs1rem {
      padding-right: 17px;
      line-height: 1.2rem !important;
    }

    .carousel.carousel-slider .df .h60 {
      zoom: .4;
    }

    .keyfeature-box-portfolio-bg .w30 {
      zoom: .7;
    }

    .inner-container .px6rem {
      padding: 0;
    }

    .team-sec .team-about-column .team-box {
      width: 100% !important;
    }

    .main-banner-container .fs3-5rem .fs5rem {
      font-size: 2.5rem;
    }

    .right-img-area {
      height: 212px !important;
      margin-top: 39px !important;
    }

    .main-banner-container .col-sm.df.jcc.fdc.gg2rem {
      padding-right: 3rem;
    }

    .calcluter-sec .fdc.pl2rem {
      padding-left: 0;
    }

    .keyfeautre-container {
      width: 74%;
    }

    .comapnies-page-font .keyfeautre-container {
      width: 82%;
    }

    .poppins-font-style .inner-container .shadowContainer {
      zoom: .5;
    }

    .angels-page-font .inner-container img.mb6rem {
      margin-bottom: 0;
      width: 89% !important;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg, .keyfeautre-container .keyfeature-box, .work-container {
      zoom: .8;
    }

    .register-not-sure-sec {
      zoom: .6;
    }

    .client-review-container {
      zoom: .95;
    }

    .keyfeature-wihte-item {
      zoom: .7;
    }

    .df.bg1.pr.brne.bscov.h90vh {
      zoom: .6;
      height: 100%;
    }

    .msg-btn, .top-arrow-bt {
      zoom: .8;
    }

    .register-page-container {
      width: 111%;
      padding: 5px 0 5px 100px;
    }

    .login-page .login-container .pr8rem {
      padding-right: 0;
    }

    .home-page-bg {
      height: 100% !important;
    }

    .home-page-banner .col-sm.df.jce.pr {
      justify-content: center;
      margin: 0 auto;
      width: 60% !important;
    }

    .comapnies-page-font .companies-crousel .first-slide .c15.br30px {
      width: 73%;
    }

    #homeMainBanner .container {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    .main-banner-container .col-sm {
      justify-content: center;
      width: 100% !important;
    }

    .main-banner-container .col-sm.right-img-area {
      margin: 24px auto 0;
    }

    .main-banner-container .col-sm.right-img-area .person-img {
      margin: 0 auto;
    }

    .main-banner-container .row {
      flex-wrap: wrap !important;
    }

    .automated-sec .menu-bottom {
      width: 85%;
    }

    .automated-sec .w60 {
      border-radius: 10px;
      width: 92%;
      padding: 10px;
    }

    .right-side-zak {
      width: 94px;
      height: auto !important;
    }

    .calcluter-sec .w100 {
      width: 91%;
      margin: 0 auto;
    }

    .landing-page-font .inner-container .shadowContainer {
      border-radius: 14px;
      padding: 20px;
    }

    .client-review-container .pr3rem.pl3rem {
      padding: 1.5rem 1.5rem 0;
    }

    .client-review-container .w60 {
      padding-right: 0;
    }

    #homeMainBanner .container:nth-child(odd) .w60 {
      padding-left: 5rem;
    }

    #homeMainBanner .container:nth-child(2n) .w60 {
      padding-right: 3rem;
    }

    #homeMainBanner .row.pl2rem.pr2rem.w50 {
      width: 100%;
    }

    .pricing-container .pl8rem.pr8rem {
      padding: 0;
    }

    .ar.pa.blue-circle {
      right: 29px !important;
    }

    .privacy-policy-page .policy-box {
      padding: 0 30px 40px;
    }

    .privacy-policy-page .w60 {
      border-radius: 10px;
      width: 90%;
    }

    .privacy-policy-page .w60 .fs1rem-screen1 {
      font-size: .75rem;
    }

    .privacy-policy-page .policy-box .pb2rem {
      padding-bottom: 1.3rem;
    }

    .our-thesis-sec {
      padding-left: 30px;
      padding-right: 30px;
    }

    .our-thesis-sec .w60, .our-thesis-sec .w40 {
      width: 100%;
    }

    .visin-sec .container {
      align-items: center;
      padding: 0 30px !important;
    }

    .visin-sec .container .w60 {
      width: 100%;
      padding-right: 0;
    }

    .visin-sec .container .w30 {
      text-align: center;
      width: 100%;
    }

    .visin-sec .container .w30 img {
      width: 62%;
      height: 100% !important;
      margin: 20px auto 0 !important;
    }

    .team-sec .dg.aic {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .action-sec.dg {
      flex-wrap: wrap;
      display: flex;
    }

    .action-sec.dg .left-area {
      width: 100%;
      margin-bottom: 22px;
    }

    .action-sec.dg .right-area {
      width: 39%;
      margin: 0 auto;
    }

    .accounts-page-font .work-container .df.jcsb .work-card-box {
      width: 24%;
      margin-bottom: 28px;
    }

    .our-thesis-sec .w40 {
      margin-top: -63px;
      margin-right: -60px;
    }

    .our-thesis-sec img.ar.pa {
      width: 39px;
    }

    .card-style-about {
      padding: 20px 30px 60px !important;
    }

    .story-sec h4 {
      font-size: 2.5rem !important;
    }

    .narBarAnimation {
      display: none;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user {
      margin-top: -8px !important;
    }

    .comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user {
      margin-top: 1px !important;
    }

    .main-banner-container .col-sm.df.jcc.fdc.gg2rem {
      padding-right: 0;
      width: 100% !important;
    }

    .work-container {
      zoom: .7;
      max-width: 75% !important;
    }

    .slider-wrapper .first-slide .inner-container img.pa.ar.zi1 {
      width: 52px !important;
    }

    .calcluter-sec .w60 a {
      text-align: center;
      padding-left: 2rem;
    }

    .pricing-plan-page .w60.fs3-125rem {
      font-size: 2rem;
      padding-left: 8rem !important;
    }

    .shadowPaperCrasouel {
      width: 100%;
    }

    .inner-container {
      padding: 11px 66px !important;
    }

    .accounts-page-font .client-review-container {
      zoom: .7;
    }

    span.wid-btn {
      width: 70% !important;
    }

    .pricing-tabs .pricing-advisor-table .pricing-box.pricing-box-mobile {
      display: none !important;
    }

    .top-pricing-area .fdc.br100 {
      zoom: .7 !important;
    }

    .poppins-font-style.mah100.bg1.shadow.w100.p4rem.df.fdc.m1rem.pr.br10px.pr {
      zoom: .8 !important;
    }

    .team-sec .team-about-column .team-box .card-style-about {
      min-height: 100% !important;
      max-height: 100% !important;
    }

    .keyfeature-box-portfolio-innner:before, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before {
      width: 93%;
      left: 0;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before {
      left: 21px;
    }

    .work-container .df.jcsb .work-card-box {
      width: 40%;
      margin-bottom: 30px;
    }

    .work-container .df.jcc .work-card-box {
      width: 40%;
      margin: 0 15px 34px;
    }

    .slider-wrapper .first-slide .inner-container img {
      width: 44% !important;
    }

    .pricing-tabs .booking-sec {
      flex-wrap: wrap;
      margin-top: -8px;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30 {
      width: 25%;
      padding-left: 1rem;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w70 {
      width: 75%;
    }

    .inner-container {
      zoom: .7;
      padding: 11px 58px !important;
    }

    .login-page {
      flex-wrap: wrap;
      height: 100%;
    }

    .login-page .form-right-image {
      height: 373px;
      width: 100% !important;
    }

    .investorLookup-container {
      padding: 0 30px 70px;
    }

    .main-banner-container {
      padding: 0 45px;
    }

    .calcluter-sec .c15 {
      font-size: 1rem;
      line-height: 23px !important;
    }

    .calcluter-sec .fs3rem {
      margin-top: 1rem;
      font-size: 1.2rem;
      line-height: 27px !important;
    }

    .landing-page-font .inner-container p.fs1-25rem {
      font-size: .75rem;
      line-height: 1.3rem !important;
    }

    .work-container {
      padding: 45px 30px;
    }

    #homeMainBanner .container .w60 {
      width: 100%;
    }

    #homeMainBanner .container:nth-child(odd) .w60, #homeMainBanner .container:nth-child(2n) .w60 {
      padding: 0;
    }

    #homeMainBanner .container .w100.pr.df {
      width: 100%;
      margin-top: 0;
      justify-content: center !important;
    }

    #homeMainBanner .container .w100.pr.df .br40px.pt1rem.pb1rem.pl3rem.pr2rem {
      width: 100% !important;
    }

    .not-sure-container .df.jcsb {
      flex-wrap: wrap;
      justify-content: center;
    }

    .not-sure-container .df.jcsb .scaleAnimation {
      margin-bottom: 43px;
    }

    #homeMainBanner .container .w50, #homeMainBanner .container .br40px.pt1rem {
      width: 100% !important;
    }

    .beta-logo {
      position: absolute;
      top: 15%;
      left: 21%;
    }

    .calcluter-sec {
      zoom: .8;
      padding: 1rem;
    }

    .inner-container .df.jce.aic {
      justify-content: center;
      margin: 0 auto;
      gap: 5px !important;
      width: 55% !important;
    }

    .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) .df.fw500.fs1rem.c15 {
      justify-content: space-between;
      align-items: center;
    }

    .keyfeature-wihte-item {
      width: 39% !important;
    }

    .main-banner-container .fs1-75rem {
      font-size: 1rem;
    }

    .df.bg1.pr.brne.bscov.h90vh {
      height: 100%;
    }

    .pricing-plan-page .w60.fs3-125rem {
      padding-left: 3rem !important;
    }

    .pricing-plan-page .w70 .df.jcsb.w100, .pricing-plan-page .w80 .df.jcsa.w100 {
      flex-wrap: wrap;
      justify-content: center;
    }

    .register-page {
      flex-wrap: wrap;
      height: 100%;
    }

    .register-page .register-page-container {
      box-sizing: border-box;
      width: 100%;
      padding: 5px 38px;
    }

    .register-page .register-page-container .pr8rem {
      padding-right: 0;
    }

    .register-page .form-right-image {
      height: 400px;
      position: relative;
      width: 100% !important;
    }

    .pricing-tabs .booking-sec .pricing-bottom-box {
      min-height: auto;
    }

    .pricing-tabs .booking-sec .pricing-bottom-box p {
      padding: 0 0 10px;
    }

    .tabs-portfolio .pt10rem {
      padding-top: 9rem;
    }

    .team-sec .team-about-column .team-box {
      width: 46% !important;
      padding: 0 15px !important;
    }

    .tabs-portfolio .pt10rem .df.jcsb.w100.mb2rem.px4rem {
      margin-bottom: 8rem !important;
      display: none !important;
    }

    .ceo-sec {
      padding: 3rem 1.5rem !important;
    }

    .ceo-sec .left-ceo-text {
      width: 100% !important;
      margin-bottom: 33px !important;
    }

    .ceo-sec .righ-ceo-pic {
      text-align: center !important;
      width: 100% !important;
      margin: 0 auto !important;
    }

    .team-sec {
      padding: 2rem 1.5rem 1rem !important;
    }

    .silder-box .bg1.aic {
      padding: 0 1.5rem !important;
    }

    .team-sec .team-about-column {
      justify-content: center;
      flex-wrap: wrap !important;
    }

    .action-sec.dg .tac.bg1.cp {
      margin: 0 auto !important;
    }

    .pricing-tabs .pricing-box, .pricing-tabs .pricing-box:nth-child(3), .pricing-tabs .pricing-advisor-table .pricing-box:nth-child(4) {
      margin-bottom: 6rem !important;
    }

    .pricing-tabs .pricing-advisor-table .pricing-box::nth-child(5) {
      margin-bottom: 0 !important;
    }

    .w70.fw500.fs1rem.pl9rem {
      padding: 0 !important;
    }

    .calcluter-sec .w30, .calcluter-sec .w70 {
      width: 100%;
    }

    .automated-sec .menu-bottom .fs1rem-screen1, .automated-sec .menu-bottom, #root[style*="margin-top: 72px"] .narBarAnimationUP .fs1rem-screen1, #root[style*="margin-top: 72px"] .narBarAnimation .fs1rem-screen1 {
      font-size: .7rem;
    }

    .landing-page-font .inner-container .shadowContainer {
      margin-bottom: 17px;
      width: 100% !important;
    }

    .landing-page-font .inner-container .df.jce.aic {
      justify-content: center;
      margin: 0 auto;
      gap: 5px !important;
      width: 100% !important;
    }

    .landing-page-font .inner-container .df.jce.aic img {
      height: auto;
      width: 131px !important;
    }

    .client-review-container .df.w100 {
      flex-wrap: wrap;
    }

    .client-review-container .w60 {
      width: 100%;
      padding-right: 0;
    }

    .client-review-container .w40 {
      justify-content: center;
      width: 60%;
      margin: 0 auto;
    }

    .work-container .jcsb .work-card-box, .accounts-page-font .work-container .df.jcsb .work-card-box {
      width: 100%;
      margin: 0 0 25px;
    }

    .work-card-box {
      width: 100%;
      margin-bottom: 25px;
    }

    .work-container .df.jcc {
      flex-wrap: wrap;
    }

    .triangle-vector {
      width: 100px;
    }

    .pricing-container .pricing-box.mr5rem {
      margin-right: 15px;
    }

    .pricing-title .right-text-for {
      padding-right: 0 !important;
    }

    .pricing-title .left-text-for {
      padding-left: 0;
    }

    .action-sec .fs2-5rem {
      font-size: 1.5rem;
    }

    .action-sec.dg .right-area {
      width: 58%;
    }

    .pricing-plan-page .w60.fs3-125rem {
      width: 100%;
      padding-bottom: 2rem;
    }

    .angelsnetwork-page-font .inner-container .row.df.jcc img {
      width: 66% !important;
      height: auto !important;
    }

    .main-banner-container.home-page-banner .fs3rem, .main-banner-container.home-page-banner .fs3rem .c4 {
      margin: 20px 0;
      font-size: 39px !important;
      line-height: 46px !important;
    }

    .main-banner-container.home-page-banner .pt4rem .fs2-5rem {
      margin-top: 1rem;
      font-size: 1.2rem;
    }

    .pricing-tabs .pricing-advisor-table .pricing-box {
      width: 99%;
    }

    .main-banner-container, .new-header .container {
      padding: 0 30px 30px;
    }

    .main-banner-container .fs1-5rem {
      font-size: 1rem;
    }

    .calcluter-sec {
      flex-wrap: wrap;
    }

    .calcluter-sec .w100 {
      flex-wrap: wrap;
      padding: 2rem 1rem 1rem;
    }

    .calcluter-sec .w60 {
      width: 100%;
    }

    .calcluter-sec .w40 {
      width: 100%;
      margin-top: 20px;
    }

    #homeMainBanner .container {
      max-width: 100% !important;
      padding: 0 30px !important;
    }

    .blue-animation {
      width: 77px !important;
      height: auto !important;
      top: -40px !important;
    }

    .circle-animation {
      width: 68px !important;
      height: auto !important;
    }

    .quote-icon {
      width: 20px !important;
    }

    .quote-icon svg {
      width: 20px;
    }

    #homeMainBanner .container .w90 .fs1-25rem {
      padding-left: .5rem;
    }

    #homeMainBanner .container:nth-child(odd) .w60 {
      margin-bottom: 13px;
    }

    #homeMainBanner .container .w60 .custom-list, #homeMainBanner .container .w60 .custom-list li {
      font-size: 17px;
      width: 95% !important;
    }

    .video-container p.fs2-125rem {
      font-size: 1.2rem;
      line-height: 33px !important;
    }

    .video-container .w70 {
      width: 99%;
    }

    .register-container .df.gg1rem.pt0-5rem.pb0-5rem {
      flex-wrap: wrap;
    }

    .register-container .w50 {
      width: 100%;
    }

    .not-sure-container .w100.df.jcc p {
      width: 100% !important;
    }

    .side-blue-circle {
      width: 70px;
      height: auto;
      bottom: 0 !important;
      right: 38px !important;
    }

    .not-sure-container .side-blue-circle {
      height: auto;
      width: 70px !important;
      bottom: 0 !important;
      right: 0 !important;
    }

    .privacy-policy-page .w60 {
      flex-wrap: wrap;
      justify-content: center;
      padding: 5px;
    }

    .privacy-policy-page .w60 .fs1rem-screen1 {
      margin: 0 5px;
    }

    .privacy-policy-page .policy-box .pt2rem {
      padding-top: 1rem;
    }

    .privacy-policy-page .policy-box .pt1rem {
      padding-top: .6rem;
    }

    .terms-and-conditions-page [style*="width: 41%"] {
      border-radius: 10px;
      padding: 12px 10px;
      width: 88% !important;
    }

    .team-sec .dg.aic .df.fdc {
      justify-content: center;
      width: 100%;
      margin-bottom: -120px;
    }

    .about-carousal-slider .slide {
      zoom: .3;
    }

    .video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem {
      width: 100%;
      font-size: 25px;
    }

    .work-container .fs1-5rem {
      font-size: .7rem;
      line-height: 16px !important;
    }

    .poppins-font-style .inner-container .shadowContainer {
      width: 49% !important;
    }

    .register-not-sure-sec {
      overflow: hidden;
    }

    .calcluter-sec .w40 img {
      width: 60%;
      margin-bottom: 20px;
    }

    .keyfeature-wihte-item {
      width: 100% !important;
      margin-right: 0 !important;
    }

    .work-container .df.jcsb .work-card-box, .work-container .df.jcc .work-card-box, .accounts-page-font .work-container .df.jcsb .work-card-box {
      width: 75%;
    }

    .register-not-sure-sec .not-sure-register .not-sure-container .fdc {
      flex-wrap: wrap;
    }

    .register-not-sure-sec .not-sure-register .not-sure-container .fdc .scaleAnimation {
      margin-bottom: 45px;
    }

    .bottom-button-slider .fs1rem.df.jcc {
      width: 69%;
    }

    .inner-container.style-two .row {
      width: 100%;
    }

    .investorLookup-container .fs2rem {
      padding: 50px 0;
      font-size: 1.5rem;
      line-height: 2rem !important;
    }

    .pricing-plan-page .w60.fs3-125rem {
      padding-bottom: 2rem;
      font-size: 1.5rem;
      line-height: 2rem !important;
    }

    .pricing-plan-page .w40.pr.df {
      justify-content: center;
      width: 56%;
      margin: 0 auto;
    }

    .bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem {
      zoom: .6;
      padding: 34px 15px;
    }

    .bg1.pricing-plan-page h4.fs2rem.fw400.mb2rem.c15 {
      font-size: 1.1rem;
      max-width: 100% !important;
      line-height: 28px !important;
    }

    .comapnies-page-font .inner-container .shadowContainer .df.aic, .comapnies-page-font .inner-container .shadowContainer .df.aic p {
      justify-content: start;
    }

    .poppins-font-style .inner-container .shadowContainer .br10px.mt1rem svg {
      width: 39px;
    }

    .register-page .register-page-container .w50 {
      width: 100%;
    }

    .register-page .register-page-container .df.gg1rem, .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) .df.fw500.fs1-25rem.c15 {
      flex-wrap: wrap;
    }

    .inner-container .row.df.jcsb .df.jce.aic img.h70 {
      height: 50%;
    }

    .comapnies-page-font .companies-crousel .first-slide .c15.br30px {
      zoom: .8;
      width: 73%;
    }

    .comapnies-page-font .companies-crousel .first-slide .new-shadow .icon img.w30px {
      width: 20px;
    }

    .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) .df.fw500.fs1-25rem.c15 a {
      margin-top: 15px;
    }

    .inner-container .df.jce.right-icon-btn {
      justify-content: flex-start;
      margin: 20px 0 12px;
      gap: 0 !important;
      width: 64% !important;
    }

    .step-box-modal.style-two {
      padding: 0;
    }

    .mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80 {
      zoom: .8;
      padding: 10px;
    }

    .client-review-container .mt4rem {
      padding: 2rem 1rem 0;
    }

    .register-container-not-sure {
      width: 89%;
      padding: 106px 276px 28px 72px;
    }

    .not-sure-register.w40 {
      width: 100%;
    }

    .poppins-font-style.mah100.bg1.shadow.w100.p4rem.df.fdc.m1rem.pr.br10px.pr {
      zoom: .7 !important;
      padding: 1rem !important;
    }

    .poppins-font-style.mah100.bg1.shadow.w100.p4rem.df.fdc.m1rem.pr.br10px.pr .df.w100.mb1rem {
      flex-wrap: nowrap !important;
    }

    .team-sec .team-about-column .team-box {
      zoom: 1 !important;
      width: 100% !important;
      padding: 0 15px !important;
    }

    .action-sec {
      padding: 2rem 1.5rem !important;
    }

    .poppins-font-style .df.w100 div[style="width: 55%; height: 100%;"] {
      width: 100% !important;
    }

    .poppins-font-style .df.w100 {
      flex-wrap: wrap !important;
    }

    .poppins-font-style .df.w100 .df.aic.jce {
      width: 100% !important;
    }

    .poppins-font-style .df.w100 .w90.fw500.c15.pl1rem.df.aic {
      font-size: 20px !important;
      line-height: 30px !important;
    }

    .seemore-box .seemore-inner {
      padding: 25px 15px 15px !important;
    }

    .seemore-box .seemore-inner .pl3rem {
      padding-left: 1rem;
      width: 100% !important;
    }

    .pricing-tabs .pricing-advisor-table .px4rem {
      margin: 0 auto !important;
      padding: 0 !important;
    }

    .not-sure-register .not-sure-container {
      padding: 30px 96px !important;
    }

    .home-page-menu.automated-sec .menu-bottom {
      margin-top: -37px !important;
    }

    .main-banner-container .fs3-5rem .fs5rem {
      margin: 10px 0;
      font-size: 1.8rem;
      line-height: 2.5rem;
      display: block;
    }

    .login-page .login-container {
      width: 100%;
      padding: 100px 25px 50px;
    }

    .login-page .login-container .pr8rem .df.jcsb {
      flex-wrap: wrap;
    }

    .comapnies-page-font .companies-crousel .first-slide .c15.br30px {
      zoom: .8;
    }

    .menu-bottom {
      flex-wrap: wrap;
      justify-content: center;
    }

    .menu-bottom .fs1rem-screen1 {
      margin: 0 5px;
    }

    #root[style*="margin-top: 72px"] .narBarAnimationUP, #root[style*="margin-top: 72px"] .narBarAnimation {
      flex-wrap: wrap;
      justify-content: center;
      display: none;
    }

    .carousel .slider-wrapper .slide .c15 {
      font-size: 1.2rem;
      line-height: 2rem;
    }

    .carousel .slider-wrapper .slide .fs1rem.c15 {
      font-size: 1rem;
    }

    .carousel-button.prev {
      left: -24px !important;
    }

    .carousel-button.next {
      right: -24px !important;
    }

    .landing-page-font .inner-container {
      padding: 11px 0 0 !important;
    }

    .landing-page-font .inner-container .shadowPaperCrasouel .c15 {
      font-size: 1rem;
      line-height: 1.3rem !important;
    }

    .landing-page-font .inner-container .df.jce.aic img {
      width: 61px !important;
    }

    #homeMainBanner .container .w100.pr.df .br40px.pt1rem.pb1rem.pl3rem.pr2rem {
      padding: 0 1.7rem 20px;
    }

    .quote-area {
      flex-wrap: wrap;
    }

    .quote-area .w90 {
      width: 100%;
    }

    .pricing-title .right-text-for {
      display: none;
    }

    .pricing-title {
      justify-content: center;
      width: 100%;
    }

    #homeMainBanner .container .w90 .fs1-25rem {
      padding: 0;
    }

    .feature-review-img {
      flex-wrap: wrap;
    }

    .feature-review-img img {
      width: 40px;
      height: 40px;
      margin: 15px 0;
    }

    .feature-review-img p {
      padding: 0 0 8px;
      line-height: 1rem !important;
    }

    #homeMainBanner .container .w40 {
      width: 100%;
      margin: 18px auto;
    }

    #homeMainBanner .container .w100.pr.df {
      margin-bottom: 20px;
    }

    .bottom-features .column {
      flex: 0 0 100%;
    }

    .pricing-container {
      padding: 51px 10px;
    }

    .pricing-container .pricing-box {
      width: 100%;
    }

    .ar.pa.blue-circle {
      top: 34px !important;
      right: 29px !important;
    }

    .register-container {
      padding: 70px 45px;
      width: 100% !important;
    }

    .register-container button {
      width: 100% !important;
    }

    .not-sure-container .bsflp.bg1.br10px {
      padding: 27px 15px;
      width: 93% !important;
    }

    #homeMainBanner .container {
      padding: 0 10px !important;
    }

    .client-review-container {
      padding: 45px 13px 60px !important;
    }

    .client-review-container .fs2-125rem {
      text-align: center;
    }

    .fs2-125rem {
      font-size: 1.25rem;
    }

    .review-img img {
      width: 212px !important;
    }

    .landing-page-font .silder-box {
      padding: 1rem;
    }

    .calcluter-sec {
      padding: 2rem 1rem;
    }

    .video-container {
      padding: 49px 10px 0;
    }

    .triangle-vector {
      width: 59px;
      right: 34px !important;
    }

    #homeMainBanner .container .w60 .pl3rem {
      padding-left: 1rem;
    }

    .df.jcc.mt5rem {
      margin-top: 2rem;
    }

    .df.jcc.mt5rem p {
      width: 100%;
    }

    .form-right-image {
      width: 0 !important;
    }

    .fs3-5rem {
      font-size: 2.2rem;
    }

    .privacy-policy-page .policy-box p, .privacy-policy-page .policy-box ol li, .privacy-policy-page .policy-box ul li {
      word-break: break-all;
    }

    .terms-and-conditions-page [style*="width: 41%"] {
      flex-wrap: wrap;
      justify-content: center;
    }

    .privacy-policy-page ul, .privacy-policy-page ol {
      padding-left: 5px;
    }

    .privacy-policy-page .policy-box .fs2rem.p3rem {
      text-align: center;
      padding: 10px;
      font-size: 1.2rem;
    }

    .our-thesis-sec .fs3-5rem {
      font-size: 1.8rem !important;
    }

    .visin-sec .fs3rem {
      font-size: 2rem;
    }

    .story-sec {
      padding: 50px 30px !important;
    }

    .about-carousal-slider {
      padding: 0 15px !important;
    }

    .about-carousal-slider .slide {
      zoom: .2;
      min-width: 100% !important;
    }

    .carousel.carousel-slider {
      padding: 0 0 4rem !important;
    }

    .action-sec.dg .right-area {
      zoom: .5;
      width: 76%;
    }

    .team-sec .fs3-125rem {
      font-size: 1.7rem;
    }

    .bottom-features {
      flex-direction: column;
      width: 100%;
    }

    .bottom-features [style*="width: 20%"] {
      width: 100% !important;
    }

    #homeMainBanner h1 {
      text-align: center;
    }

    .video-container img.w10.pa {
      width: 50px !important;
    }

    .not-sure-container .df.jcsb .scaleAnimation {
      margin-bottom: 43px;
      width: 100% !important;
    }

    .carousel .slider-wrapper.axis-horizontal .slider .slide .fw400.fs1-25rem.c15.mb1rem {
      font-size: .9rem;
    }

    .carousel .slider-wrapper .slide .fs1rem.c15 {
      width: 78%;
      font-size: 1rem;
    }

    .slider-wrapper .inner-container .row.df.jcsb .df.jce.aic img.h80 {
      height: 49%;
    }

    .keyfeautre-container {
      width: 90%;
      max-width: 90%;
    }

    .keyfeautre-container .keyfeature-box, .keyfeature-box-free .keyfeature-wihte-item {
      box-sizing: border-box;
    }

    .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) {
      width: 100% !important;
    }

    .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) .df.fw500.fs1rem.c15 {
      flex-wrap: wrap;
    }

    .keyfeature-box-free .keyfeature-wihte-item:nth-child(2) a {
      width: 100%;
      margin-top: 16px;
    }

    .how-its-work .blue-animation {
      height: 5% !important;
    }

    .poppins-font-style .inner-container .shadowContainer .df.aic, .poppins-font-style .inner-container .shadowContainer .df.aic p {
      flex-wrap: wrap;
    }

    .poppins-font-style .inner-container .shadowContainer .df.aic span {
      width: 100%;
    }

    .poppins-font-style .inner-container .shadowContainer .df.aic p .cp.w100.df.jce.c1 {
      justify-content: center;
      margin: 10px 0;
      flex-wrap: nowrap !important;
    }

    .shadowPaperCrasouel .right-icon-btn span img {
      width: 1rem !important;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .investor-box {
      zoom: .3;
    }

    .work-container .fs2-125rem {
      font-size: 25px;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before {
      top: 47%;
    }

    .slider-wrapper .first-slide .inner-container img.pa.ar.zi1 {
      display: none;
    }

    .pricing-tabs .booking-sec .pricing-bottom-box {
      width: 100%;
      margin: 15px auto !important;
    }

    .pricing-plan-page .df.fdc.aic.mt1rem {
      width: 100% !important;
    }

    .main-banner-container .col-sm.right-img-area .cubes-img {
      top: 46px !important;
      left: -28px !important;
    }

    .poppins-font-style .inner-container .shadowContainer {
      margin-bottom: 24px;
      width: 95% !important;
    }

    .calcluter-sec .w30 img.w90 {
      height: auto;
    }

    .inner-container .row.df.jcc img {
      width: 100% !important;
      height: auto !important;
    }

    .inner-container .df.jce.right-icon-btn {
      width: 94% !important;
    }

    .inner-container .df.jcc.aic img.h60 {
      height: 106px !important;
    }

    .shadowPaperCrasouel .bg19 {
      box-sizing: border-box;
      width: 53px;
      height: 53px;
      margin-bottom: 10px;
      padding: 5px;
    }

    .shadowPaperCrasouel .bg19 svg {
      width: 23px;
    }

    .poppins-font-style .inner-container .shadowContainer {
      zoom: .6;
      width: 98% !important;
    }

    .comapnies-page-font .review-img img {
      width: 100% !important;
    }

    .inner-container .jcsa[style="width: 47%; gap: 25px;"], .inner-container .jcsb[style="width: 47%; gap: 25px;"] {
      width: 98% !important;
    }

    .inner-container .jcsa[style="width: 47%; gap: 25px;"] .shadowContainer, .inner-container .jcsb[style="width: 47%; gap: 25px;"] .shadowContainer {
      box-sizing: border-box;
      width: 100% !important;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem {
      width: 100%;
      padding-top: 4rem;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before {
      border-radius: 31px;
      width: 100%;
      height: 93%;
      top: 53%;
    }

    .keyfeature-box-portfolio-innner:before {
      border-radius: 31px;
      width: 100%;
      height: 100%;
      top: 53%;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w70 {
      width: 100%;
      padding-left: 34px;
      display: flex;
    }

    .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3) {
      order: 5;
      top: 0;
    }

    .form-right-image .triangle-vector {
      display: none;
    }

    svg.pa.cp {
      zoom: .7;
      bottom: -53px !important;
    }

    .poppins-font-style .df.w100 {
      line-height: 1.3rem !important;
    }

    .silder-box .slick-slider .slick-arrow.slick-prev {
      left: 0 !important;
    }

    .silder-box .slick-slider .slick-arrow.slick-next {
      right: 0 !important;
    }

    .pricing-tabs .MuiTabs-root button {
      margin-right: 4px !important;
      font-size: 12px !important;
    }

    .pricing-tabs .pricing-advisor-table .px4rem {
      display: none !important;
    }

    .silder-box .slick-slider {
      padding: 0 20px !important;
    }

    .fs1-25rem {
      font-size: .8rem !important;
    }

    .silder-box .slick-arrow:before {
      width: 68px !important;
      height: 68px !important;
    }

    .pricing-table-saf {
      width: 84% !important;
    }

    .main-banner-container span.c4 {
      font-size: 18px !important;
    }

    .main-banner-container .fs2rem {
      font-size: .8rem;
    }

    .main-banner-container .col-sm {
      grid-gap: .4rem;
    }

    .automated-sec .c15 {
      font-size: 16px !important;
      line-height: 20px !important;
    }

    .automated-sec .c14 {
      font-size: .7rem !important;
      line-height: 1rem !important;
    }

    .register-container .pt1rem.pb1rem .fs1-125rem {
      margin-left: .4rem;
      font-size: .7rem;
    }

    .privacy-policy-page .w60 .fs3-5rem {
      font-size: 1.8rem;
    }

    #homeMainBanner .fs2-5rem {
      font-size: 1.3rem;
    }

    .new-header .container {
      padding: 0 10px !important;
    }

    .client-review-container .fs2-125rem {
      text-align: center;
      font-size: 1.4rem;
      line-height: 40px !important;
    }

    .banner-bg {
      background-position: center !important;
      background-size: 100% !important;
    }

    .main-banner-container .fs3-5rem {
      font-size: 1.1rem;
      line-height: 21px !important;
    }

    .inner-container p.fs1-25rem {
      line-height: 1.1rem !important;
    }

    .investorLookup-container .investor-box {
      zoom: .3;
      margin-top: -56px;
    }

    .keyfeature-box .keyfeature-wihte-item {
      margin: 0 0 30px !important;
    }

    .work-container .df.jcsb.mt4rem {
      flex-wrap: wrap;
      justify-content: center;
    }

    .register-not-sure-sec .not-sure-register .not-sure-container .fdc .scaleAnimation .bg3 {
      top: -89px !important;
    }

    .shadowPaperCrasouel p {
      padding-right: 0 !important;
    }

    .poppins-font-style .first-slide .inner-container .shadowContainer {
      width: 94% !important;
    }

    .shadowPaperCrasouel p .right-icon-btn {
      flex-wrap: nowrap !important;
    }

    .review-img img {
      width: 100% !important;
      height: auto !important;
    }

    .left-certificate-area.df.fww.w70.pt3rem.pb1rem {
      zoom: .3 !important;
    }

    .investor-lookup-full-img {
      display: block !important;
    }

    .investor-box .left-certificate-area, .investor-box .list-right-area {
      display: none;
    }

    .pricing-tabs .booking-sec {
      width: 102% !important;
    }

    .bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem .w60, .bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem .w40 {
      width: 100%;
      padding: 0 !important;
    }

    .bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem {
      flex-direction: column;
    }

    .pricing-plan-page .w40.pr.df {
      width: 100%;
    }

    .about-us-page .silder-box .slick-dots {
      bottom: -142px !important;
    }

    .team-sec img.ar.pa {
      width: 13% !important;
    }

    .story-sec:before {
      width: 5.813rem !important;
      height: 5.813rem !important;
      top: -4% !important;
      left: -5.5% !important;
    }

    .story-sec:after {
      width: 7.688rem !important;
      height: 7.688rem !important;
      bottom: -5% !important;
      right: -2.25rem !important;
    }

    .silder-box .bg1.aic p {
      line-height: 1.5rem !important;
    }

    .action-sec h4 {
      font-size: 1.4rem;
      width: 100% !important;
      line-height: 29px !important;
    }

    .partners-page .w60 {
      font-size: 25px !important;
    }

    .partners-page .w70.df.fdc.jcc {
      zoom: .8;
    }

    .partners-page .w60 {
      width: 100% !important;
    }

    .partners-page .df.w100 {
      flex-wrap: wrap;
      justify-content: center;
    }

    .partners-page .w40 {
      background-position: 100% 0;
      background-size: contain;
      width: 100%;
      padding-right: 0;
    }

    .partners-page .w40 img {
      text-align: center;
      margin: 0 auto;
      width: 50% !important;
    }

    .partners-page .w30 {
      width: 100%;
      margin: 0 auto;
    }

    .partners-page .df.w100 .df {
      flex-wrap: wrap;
    }

    .partners-page .df.w100 .df img {
      width: 50% !important;
    }

    .partners-page .w70.df.fdc.jcc {
      width: 100% !important;
    }

    .partners-page .df.w100 .w60.fw500 {
      width: 100% !important;
      padding-left: 3rem !important;
    }

    .partners-page .df.w100 .df .cp.bg4.br40px.df.jcsb.aic.pl1rem.pr1rem.mt1-5rem {
      margin-bottom: 25px;
      flex-wrap: nowrap !important;
      width: 152px !important;
    }

    .partners-page .df.w100 .p2-5rem {
      padding: 1rem;
    }

    .partners-page .df.w100 .w60.fw500 {
      font-size: 20px !important;
      line-height: 1.6rem !important;
    }

    .contact-us-page .df.w100 {
      flex-wrap: wrap;
    }

    .contact-us-page .df.w100 .w60.fs2-5rem {
      text-align: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 38px;
      font-size: 2rem;
      padding-left: 0 !important;
    }

    .beta-logo {
      top: 21%;
      left: 23%;
    }

    .contact-us-page .df.w100 .w35 {
      text-align: end;
      justify-content: end;
      padding: 0;
      display: flex;
      width: 100% !important;
    }

    .contact-us-page .df.w100 .w35 img {
      width: 100%;
    }

    .contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df {
      zoom: .3;
    }

    .contact-us-page .df.w100 .w60.fs2-5rem {
      font-size: 1.2rem;
    }

    .contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df {
      zoom: .2;
      padding-left: 2rem;
    }

    .contact-us-page img.pa.ar {
      zoom: .4;
    }

    .team-sec .team-about-column .team-box .card-style-about, .poppins-font-style .df.w100 p.bg6.c1.fw600.br15px {
      zoom: 1 !important;
    }

    .team-sec .team-about-column .team-box .team-img {
      width: 100%;
    }

    .team-sec .team-about-column .team-box .card-style-about .fs2rem, .poppins-font-style .df.w100 p.bg6.c1.fw600.br15px .fs2rem {
      font-size: 1.5rem;
    }

    .right-img-area .person-img {
      height: auto !important;
    }

    .comapnies-page-font .main-banner-container, .accounts-page-font .main-banner-container, .lawyers-page-font .main-banner-container, .vcs-page-font .main-banner-container, .pes-page-font .main-banner-container, .angelsnetwork-page-font .main-banner-container, .angels-page-font .main-banner-container {
      height: 759px !important;
    }

    .accounts-page-font .automated-sec .menu-bottom, .vcs-page-font .automated-sec .menu-bottom {
      margin-top: -93px !important;
    }

    .mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80 {
      zoom: .5;
    }

    .mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80 button {
      margin-right: 0 !important;
    }

    .poppins-font-style {
      overflow: visible;
    }

    .backdrop.td500.zi4.pf.t0.l0.w100vw.h100vh.df.jcc.aic.MuiModal-root.css-79ws1d-MuiModal-root svg.pa.cp {
      bottom: 50px !important;
    }

    .register-not-sure-sec .not-sure-register .not-sure-container .fdc .scaleAnimation {
      margin: 104px 50px 0;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem {
      flex-flow: wrap;
      width: 100%;
      padding-top: 4rem;
      padding-left: 3rem;
    }

    .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem p {
      width: 100%;
    }

    #homeMainBanner .mt4rem .fs2-5rem {
      font-size: 1.1rem;
    }

    .cookies-box.p3rem {
      padding: 10px 20px;
    }

    .cookies-box.p3rem .c15.fs1-5rem.mb1rem {
      margin-bottom: 5px;
    }

    .cookies-box.p3rem .c14.pb1rem {
      padding-bottom: 5px;
      font-size: 12px;
    }

    .cookies-box.p3rem .pt2rem.pb1rem.divedier {
      padding: 10px 0 0;
    }

    .cookies-box.p3rem button {
      margin-top: 15px;
      padding: 10px;
      font-size: 14px;
    }
  }
}
/*# sourceMappingURL=index.f30c073d.css.map */
