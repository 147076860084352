body
	color: #002d63
b
	font-weight: 600
@for $i from 1 through 24
	.tr#{$i*15}deg
		transform: rotate(#{$i*15}deg)
::-webkit-scrollbar-track
	border: 3px solid white
	background-color: #4a67ff44
::-webkit-scrollbar
	width: 10px
	border-radius: 10px
	background-color: #fff
::-webkit-scrollbar-thumb
	border-radius: 10px
	background-color: #fc7785
h1, h2, h3, h4, h5, h6
	font-weight: 600
.bg-gradient
	background: -webkit-linear-gradient(45deg, #002d63, #4A67FF)
.bg-gradient2
	background: -webkit-linear-gradient(45deg, #3956F0, #8496F4)
.bg-none	
	background: none
.text-gradient
	background: -webkit-linear-gradient(45deg, #002d63, #4A67FF)
	-webkit-background-clip: text
	-webkit-text-fill-color: transparent
.gradient-shadow:before
	content: ""
	/*z-index: -1*/
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0
	background: linear-gradient(-45deg, #4a67ff 0%, #fc7785 100%	)
	transform: translate3d(0px, 0px, 0) scale(1.05)
	filter: blur(20px)
	opacity: 0.7
	transition: opacity 0.3s
	border-radius: inherit

$weights: ( 100: "thin", 200: "extra_light", 300: "light", 400: "regular", 500: "medium", 600: "semi_bold", 700: "bold" )
@each $key, $text in $weights
	@font-face
		font-family: "Kanit"
		font-style: normal
		src: url('../fonts/kanit_#{$text}.ttf')
		font-weight: #{$key}
		font-display: swap

$weights: ( 100: "thin", 200: "extra_light", 300: "light", 400: "regular", 500: "medium", 600: "semi_bold", 700: "bold" )
@each $key, $text in $weights
	@font-face
		font-family: "Poppins"
		font-style: normal
		src: url('../fonts/poppins_#{$text}.ttf')
		font-weight: #{$key}
		font-display: swap

*
	margin: 0
	font-family: "Poppins", sans-serif

.poppins-font-style 
	margin: 0
	font-family: "Poppins" ,sans-serif

h1, h2, h3, h4, h5, h6, p, span, li, strong, input, textarea, select, a, i
	align-self: center
a
	text-decoration: none
	display: inline-block
	color: inherit
input, textarea, select, button
	border: none
	outline: none
	box-sizing: border-box
	font-size: 1rem
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button 
	-webkit-appearance: none
hr
	border-style: solid
.fi
	filter: invert(1)
.backdrop
	backdrop-filter: brightness(0.5) blur(5px) drop-shadow(0 4px 8px #000)
	height: 100vh
	&.td100
		&.zi5
			height: auto
.fa-secondary
	opacity: 0.4
.wsn
	white-space: nowrap
.bsbb
	box-sizing: border-box
.cp
	cursor: pointer
.cg
	cursor: grab
.pen
	pointer-events: none
.pea
	pointer-events: auto
.tdu
	text-decoration: underline
.ttu
	text-transform: uppercase
.ttc
	text-transform: capitalize
.tti
	text-transform: initial
.usn
	-webkit-touch-callout: none
	-webkit-user-select: none
	-khtml-user-select: none
	-moz-user-select: none
	-ms-user-select: none
	user-select: none
.brnr
	background-repeat: no-repeat
.oh
	overflow: hidden
.oxh
	overflow-x: hidden
.oyh
	overflow-y: hidden
.ov
	overflow: visible
.oxv
	overflow-x: visible
.oyv
	overflow-y: visible
.vv
	visibility: visible
.vh	
	visibility: hidden
.op1
	opacity:1
.op0
	opacity:0
.oa
	overflow: auto
.oxa
	overflow-x: auto
.oya
	overflow-y: auto
.bpl
	background-position: left
.bpc
	background-position: center
.bpr
	background-position: right
.bscov
	background-size: cover
.bscon
	background-size: contain
.opc
	object-position: center
.ofcov
	object-fit: cover
.ofcon
	object-fit: contain
.tpa
	transition-property: all
.tph
	transition-property: height
.tpw
	transition-property: width
.tpo
	transition-property: opacity
.tpb
	transition-property: background
.tslp
	transition: opacity 0.5s, transform 0.9s
.tty0
	transform: translateY(0)
.ttx20
	transform: translateY(20px)
.wfull
	width: 100%
.ar
	animation: rotate 10s linear infinite
.da-cursor
	cursor: not-allowed !important


@for $i from 1 through 20
	.zi-#{$i}
		z-index: -#{$i}
	.zi#{$i}
		z-index: #{$i}
.td50
	transition-duration: 50ms
.td100
	transition-duration: 100ms
	-webkit-transition-duration: 100ms
	-moz-transition-duration: 100ms
	-o-transition-duration: 100ms
.td300
	transition-duration: 300ms
	-webkit-transition-duration: 300ms
	-moz-transition-duration: 300ms
	-o-transition-duration: 300ms
	
@for $i from 1 through 9
	.td#{$i}50
		transition-duration: #{$i}50ms
	.td#{$i + 1}00
		transition-duration: #{$i + 1}00ms
.bsd
	border-style: dashed
.bss
	border-style: solid
.brss
	border-right-style: solid
@for $i from 0 through 9
	.bs#{$i}px
		border: #{$i}px solid
	.bw#{$i}px
		border-width: #{$i}px
	.btw#{$i}px
		border-top-width: #{$i}px
	.brw#{$i}px
		border-right-width: #{$i}px
	.bbw#{$i}px
		border-bottom-width: #{$i}px
	.blw#{$i}px
		border-left-width: #{$i}px
@for $i from 1 through 9
	.fw#{$i}00 
		font-weight: #{$i}00
		
.lh0
	line-height: 0
@for $i from 1 through 5
	.lh#{$i}
		line-height: #{$i * 0.25 + 0.75}
@for $i from 0 through 20
	.op#{$i * 5}
		opacity: #{calc($i / 20)}



.container
	box-sizing: border-box
	margin-left: auto
	margin-right: auto
	max-width: calc(100vw)
	padding-left: 1rem
	padding-right: 1rem
.center
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	.center-vertically
		top: 50%
		transform: translateY(-50%)
.center-horizontally
	left: 50%
	transform: translateX(-50%)
.center-vertically
	top: 50%
	transform: translateY(-50%)
	-webkit-transform: -webkit-translateY(-50%)
	-moz-transform: -moz-translateY(-50%)
	-o-transform: -o-translateY(-50%)
.filing-assistant-file
	background-color: #7fa5a9
	color: #7fa5a9
	&:before
		background-color: #7fa5a9
.sharepoint-file
	background-color: #038589
	color: #038589
	&:before
		background-color: #038589
.dropbox-file
	background-color: #0160ff
	color: #0160ff
	&:before
		background-color: #0160ff
.gdrive-file
	background-color: #f2da00
	color: #f2da00
	&:before
		background-color: #f2da00
.upload-file
	background-color: #fb7784
	color: #fb7784
	&:before
		background-color: #fb7784
.prepopulated-file
	background-color: #007ee5
	color: #007ee5
	&:before
		background-color: #007ee5
.right-img-area
	height: 549px !important
	margin-top: 217px !important
.calcluter-sec
	width: 1487px
	margin: 0 auto
.font-poppins
	font-family: Poppins
$breakpoints: ("-xs": "max-width: 35.99rem", "-sm": "min-width: 36rem", "-md": "min-width: 48rem", "-lg": "min-width: 62rem", "-xl": "min-width: 75rem","-screen1":'min-width: 1276.8px',"-screen4":'min-width:1536px',"-screen5":'min-width:1920px')
@mixin responsive
	@content
	@each $key, $value in $breakpoints
		@media (#{$value})
			@content($key)
@include responsive using ($suffix: null)
	//white(1), black(2), blue(3), pink(4), offwhite(5), logo text blue(6), green(7), amber(8), red(9), teal green(10), azure blue(11), spanish gray(12), orange(13)
	$colors: ( 1: #fff, 2: #000, 3: #4A67FF, 4: #FC7785, 5: #f0f0f8, 6: #002D63, 7: #40dda1, 8: #ddb640, 9: #dd4040, 10: #038589, 11: #007EE5, 12: #969696, 13: #fb7a27, 14: #505050, 15: #151313, 16: #D9D9D9, 17: #D3D9FC, 18: #6775B9, 19: #F5F6F9, 20: #f0f2fd, 21: #F9F9F9, 22: #F6F6F6, 23: #FD6474, 24: #EFEFEF, 25: #F8F8F8, 26: #0126ED, 27: #5E5F66, 28: #AAACB5, 29: #DDE2F2, 30: #09B255, 31: #DBDFEE, 32: #737171, 33: #767B90, 34: #15D757, 35: #E8EBF5, 36: #4C6B92, 37: #818181, 38: #F0F2FD, 39: #f99229, 40: #2947ef, 41: #F5F6FF)
	@each $key, $hex in $colors
		.c#{$key}#{$suffix}, .c#{$key}-hover#{$suffix}:hover
			color: $hex
		.bg#{$key}#{$suffix}, .bg#{$key}-hover#{$suffix}:hover
			background-color: $hex
		.bo#{$key}#{$suffix}, .bo#{$key}-hover#{$suffix}:hover
			border-color: $hex
		.f#{$key}#{$suffix}, .f#{$key}-hover#{$suffix}:hover
			fill: $hex
	@if $suffix == -sm
		.container
			max-width: calc(100vw - 4rem)
	@if $suffix == -md
		.container
			padding-left: 2rem
			padding-right: 2rem
	@else if $suffix == -xl
		.container
			max-width: 1500px
	.tal#{$suffix}
		text-align: left
	.tac#{$suffix}
		text-align: center
	.tar#{$suffix}
		text-align: right
	.taj#{$suffix}
		text-align: justify
	.tas#{$suffix}
		text-align: start
	.tae#{$suffix}
		text-align: end
	.fs0#{$suffix}
		font-size: 0
	@for $i from 0 through 6
		.fs#{$i}-25rem#{$suffix}
			font-size: #{$i}#{"."}25rem
		.fs#{$i}-125rem#{$suffix}
			font-size: #{$i}#{"."}125rem
		.fs#{$i}-5rem#{$suffix}
			font-size: #{$i}#{"."}5rem
		.fs#{$i}-75rem#{$suffix}
			font-size: #{$i}#{"."}75rem
		.fs#{$i}-875rem#{$suffix}
			font-size: #{$i}#{"."}875rem
		.fs#{$i + 1}rem#{$suffix}
			font-size: #{$i + 1}rem
	.db#{$suffix}
		display: block
	.dib#{$suffix}
		display: inline-block
	.di#{$suffix}
		display: inline
	.df#{$suffix}
		display: flex
	.dg#{$suffix}
		display: grid
	.fdc#{$suffix}
		flex-direction: column
	.fdr#{$suffix}
		flex-direction: row
	.fdcr#{$suffix}
		flex-direction: column-reverse
	.fdrr#{$suffix}
		flex-direction: row-reverse
	.fww#{$suffix}
		flex-wrap: wrap
	.jcfs#{$suffix}
		justify-content: flex-start
	.jcc#{$suffix}
		justify-content: center
	.jcfe#{$suffix}
		justify-content: flex-end
	.jcsb#{$suffix}
		justify-content: space-between
	.jcsa#{$suffix}
		justify-content: space-around
	.jcse#{$suffix}
		justify-content: space-evenly
	.jce#{$suffix}
		justify-content: end
	.aifs#{$suffix}
		align-items: flex-start
	.aic#{$suffix}
		align-items: center
	.aife#{$suffix}
		align-items: flex-end
	.jss#{$suffix}
		justify-self: start
	.jsfs#{$suffix}
		justify-self: flex-start
	.jsc#{$suffix}
		justify-self: center
	.jsfe#{$suffix}
		justify-self: flex-end
	.jse#{$suffix}
		justify-self: end
	.ass#{$suffix}
		align-self: start
	.asfs#{$suffix}
		align-self: flex-start
	.asc#{$suffix}
		align-self: center
	.asfe#{$suffix}
		align-self: flex-end
	.ase#{$suffix}
		align-self: end
	.dif#{$suffix}
		display: inline-flex
	.dg#{$suffix}
		display: grid
	.brss#{$suffix}
		border-right-style: solid
	@for $i from 1 through 2
		.brw#{$i}px#{$suffix}
			border-right-width: #{$i}px
	@for $i from 1 through 10
		.gtccr#{$suffix} 
			grid-template-columns: 2fr 1fr 1fr 1fr 1fr
		.gtcr#{$i}#{$suffix}
			grid-template-columns: repeat(#{$i}, 1fr)
		.ftcr#{$i}#{$suffix}
			grid-column:(#{$i}/1fr)
		.gtrr#{$i}#{$suffix}
			grid-template-rows: repeat(#{$i}, 1fr)
		.gtcr#{$i}a#{$suffix}
			grid-template-columns: repeat(#{$i}, auto)
		.gtrr#{$i}a#{$suffix}
			grid-template-rows: repeat(#{$i}, auto)
		.gcs#{$i}#{$suffix}
			grid-column-start: #{$i} 
		.gce#{$i + 1}#{$suffix}
			grid-column-end: #{$i + 1} 
		.grs#{$i}#{$suffix}
			grid-row-start: #{$i} 
		.gre#{$i + 1}#{$suffix}
			grid-row-end: #{$i + 1} 
		
	@for $i from 0 through 4
		.gg#{$i}-5rem#{$suffix}
			grid-gap: #{$i}#{"."}5rem
		.gg#{$i + 1}rem#{$suffix}
			grid-gap: #{$i + 1}rem
		.rg#{$i}-5rem#{$suffix}
			row-gap: #{$i}#{"."}5rem
		.rg#{$i + 1}rem#{$suffix}
			row-gap: #{$i + 1}rem
		.cg#{$i}-5rem#{$suffix}
			column-gap: #{$i}#{"."}5rem
		.cg#{$i + 1}rem#{$suffix}
			column-gap: #{$i + 1}rem
	.dn#{$suffix}
		display: none
	.pa#{$suffix}
		position: absolute
	.pr#{$suffix}
		position: relative
	.pf#{$suffix}
		position: fixed
	.ps#{$suffix}
		position: sticky
		-webkit-position: sticky
		position: -webkit-sticky
	.pst#{$suffix}
		position: static
	.t0#{$suffix}
		top: 0
	.r0#{$suffix}
		right: 0
	.b0#{$suffix}
		bottom: 0
	.l0#{$suffix}
		left: 0
	.t100#{$suffix}
		top: 100%
	.r100#{$suffix}
		right: 100%
	.b100#{$suffix}
		bottom: 100%
	.l100#{$suffix}
		left: 100%
	.fl#{$suffix}
		float: left
	.fr#{$suffix}
		float: right
	.fn#{$suffix}
		float: none
	.ma#{$suffix}
		margin: auto
	.mta#{$suffix}, .mya#{$suffix}
		margin-top: auto
	.mra#{$suffix}, .mxa#{$suffix}
		margin-right: auto
	.mba#{$suffix}, .mya#{$suffix}
		margin-bottom: auto
	.mla#{$suffix}, .mxa#{$suffix}
		margin-left: auto
	.m0#{$suffix}
		margin: 0
	.mt0#{$suffix}, .my0#{$suffix}
		margin-top: 0
	.mr0#{$suffix}, .mx0#{$suffix}
		margin-right: 0
	.mb0#{$suffix}, .my0#{$suffix}
		margin-bottom: 0
	.ml0#{$suffix}, .mx0#{$suffix}
		margin-left: 0
	.p0#{$suffix}
		padding: 0
	.pt0#{$suffix}, .py0#{$suffix}
		padding-top: 0
	.pr0#{$suffix}, .px0#{$suffix}
		padding-right: 0
	.pb0#{$suffix}, .py0#{$suffix}
		padding-bottom: 0
	.pl0#{$suffix}, .px0#{$suffix}
		padding-left: 0
	@for $i from 0 through 9
		.m#{$i + 1}0#{$suffix}
			margin: #{$i + 1}0#{"%"}
		.mt#{$i + 1}0#{$suffix}, .my#{$i + 1}0#{$suffix}
			margin-top: #{$i + 1}0#{"%"}
		.mr#{$i + 1}0#{$suffix}, .mx#{$i + 1}0#{$suffix}
			margin-right: #{$i + 1}0#{"%"}
		.mb#{$i + 1}0#{$suffix}, .my#{$i + 1}0#{$suffix}
			margin-bottom: #{$i + 1}0#{"%"}
		.ml#{$i + 1}0#{$suffix}, .mx#{$i + 1}0#{$suffix}
			margin-left: #{$i + 1}0#{"%"}
		.p#{$i + 1}0#{$suffix}
			padding: #{$i + 1}0#{"%"}
		.pt#{$i + 1}0#{$suffix}, .py#{$i + 1}0#{$suffix}
			padding-top: #{$i + 1}0#{"%"}
		.pr#{$i + 1}0#{$suffix}, .px#{$i + 1}0#{$suffix}
			padding-right: #{$i + 1}0#{"%"}
		.pb#{$i + 1}0#{$suffix}, .py#{$i + 1}0#{$suffix}
			padding-bottom: #{$i + 1}0#{"%"}
		.pl#{$i + 1}0#{$suffix}, .px#{$i + 1}0#{$suffix}
			padding-left: #{$i + 1}0#{"%"}
		.m#{$i}-5rem#{$suffix}
			margin: #{$i}#{"."}5rem
		.m#{$i + 1}rem#{$suffix}
			margin: #{$i + 1}rem
		.mt#{$i}-5rem#{$suffix}, .my#{$i}-5rem#{$suffix}
			margin-top: #{$i}#{"."}5rem
		.mt#{$i + 1}rem#{$suffix}, .my#{$i + 1}rem#{$suffix}
			margin-top: #{$i + 1}rem
		.mr#{$i}-5rem#{$suffix}, .mx#{$i}-5rem#{$suffix}
			margin-right: #{$i}#{"."}5rem
		.mr#{$i + 1}rem#{$suffix}, .mx#{$i + 1}rem#{$suffix}
			margin-right: #{$i + 1}rem
		.mb#{$i}-5rem#{$suffix}, .my#{$i}-5rem#{$suffix}
			margin-bottom: #{$i}#{"."}5rem
		.mb#{$i + 1}rem#{$suffix}, .my#{$i + 1}rem#{$suffix}
			margin-bottom: #{$i + 1}rem
		.ml#{$i}-5rem#{$suffix}, .mx#{$i}-5rem#{$suffix}
			margin-left: #{$i}#{"."}5rem
		.ml#{$i + 1}rem#{$suffix}, .mx#{$i + 1}rem#{$suffix}
			margin-left: #{$i + 1}rem
		.p#{$i}-5rem#{$suffix}
			padding: #{$i}#{"."}5rem
		.p#{$i + 1}rem#{$suffix}
			padding: #{$i + 1}rem
		.pt#{$i}-5rem#{$suffix}, .py#{$i}-5rem#{$suffix}
			padding-top: #{$i}#{"."}5rem
		.pt#{$i + 1}rem#{$suffix}, .py#{$i + 1}rem#{$suffix}
			padding-top: #{$i + 1}rem
		.pr#{$i}-5rem#{$suffix}, .px#{$i}-5rem#{$suffix}
			padding-right: #{$i}#{"."}5rem
		.pr#{$i + 1}rem#{$suffix}, .px#{$i + 1}rem#{$suffix}
			padding-right: #{$i + 1}rem
		.pb#{$i}-5rem#{$suffix}, .py#{$i}-5rem#{$suffix}
			padding-bottom: #{$i}#{"."}5rem
		.pb#{$i + 1}rem#{$suffix}, .py#{$i + 1}rem#{$suffix}
			padding-bottom: #{$i + 1}rem
		.pl#{$i}-5rem#{$suffix}, .px#{$i}-5rem#{$suffix}
			padding-left: #{$i}#{"."}5rem
		.pl#{$i + 1}rem#{$suffix}, .px#{$i + 1}rem#{$suffix}
			padding-left: #{$i + 1}rem
	.wa#{$suffix}
		width: auto
	.ha#{$suffix}
		height: auto
	.w0#{$suffix}
		width: 0
	.h0#{$suffix}
		height: 0
	.maw0#{$suffix}
		max-width: 0
	.mah0#{$suffix}
		max-height: 0
	.mih0#{$suffix}
		min-height: 0
	@for $i from 1 through 10
		.w#{$i}rem#{$suffix}
			width: #{$i}rem
		.w#{$i}0px#{$suffix}
			width: #{$i}0px
		.w#{$i}00px#{$suffix}
			width: #{$i}00px
		.w#{$i}0
			&#{$suffix}
				width: #{$i}0#{"%"}
			&vw#{$suffix}
				width: #{$i}0vw
		.h#{$i}rem#{$suffix}
			height: #{$i}rem
		.h#{$i}0px#{$suffix}
			height: #{$i}0px
		.h#{$i}00px#{$suffix}
			height: #{$i}00px
		.h#{$i}0
			&#{$suffix}
				height: #{$i}0#{"%"}
			&vh#{$suffix}
				height: #{$i}0vh
		.miw#{$i}rem#{$suffix}
			min-width: #{$i}rem
		.miw#{$i}00px#{$suffix}
			min-width: #{$i}00px
		.miw#{$i}0
			&#{$suffix}
				min-width: #{$i}0#{"%"}
			&vw#{$suffix}
				min-width: #{$i}0vw
		.mih#{$i}rem#{$suffix}
			min-height: #{$i}rem
		.mih#{$i}00px#{$suffix}
			min-height: #{$i}00px
		.mih#{$i}0
			&#{$suffix}
				min-height: #{$i}0#{"%"}
			&vh#{$suffix}
				min-height: #{$i}0vh
		.maw#{$i}rem#{$suffix}
			max-width: #{$i}rem
		.maw#{$i}00px#{$suffix}
			max-width: #{$i}00px
		.maw#{$i}0
			&#{$suffix}
				max-width: #{$i}0#{"%"}
			&vw#{$suffix}
				max-width: #{$i}0vw
		.mah#{$i}rem#{$suffix}
			max-height: #{$i}rem
		.mah#{$i}00px#{$suffix}
			max-height: #{$i}00px
		.mah#{$i}0
			&#{$suffix}
				max-height: #{$i}0#{"%"}
			&vh#{$suffix}
				max-height: #{$i}0vh
	@for $i from 0 through 10
		.or#{$i}#{$suffix}
			order: #{$i}
		.br#{$i * 5}px#{$suffix}
			border-radius: #{$i * 6}px 
		.br#{$i * 8}px#{$suffix}
			border-radius: #{$i * 8}px
		.br#{$i * 19}px#{$suffix}
			border-radius: #{$i * 19}px 
		.btlr#{$i * 5}px#{$suffix}
			border-top-left-radius: #{$i * 5}px
		.btrr#{$i * 5}px#{$suffix}
			border-top-right-radius: #{$i * 5}px
		.bblr#{$i * 5}px#{$suffix}
			border-bottom-left-radius: #{$i * 5}px
		.bbrr#{$i * 5}px#{$suffix}
			border-bottom-right-radius: #{$i * 5}px
	.br100
		border-radius: 100%
	.btlr100
		border-top-left-radius: 100%
	.btrr100
		border-top-right-radius: 100%
	.bblr100
		border-bottom-left-radius: 100%
	.bbrr100
		border-bottom-right-radius: 100%

/*CUSTOM CODE HERE*/
.all-sidebar-report .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded
	margin: 0 !important
.all-sidebar-report .css-o4b71y-MuiAccordionSummary-content.Mui-expanded
	margin: 7px 0px
.all-sidebar-report .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded
	min-height: 40px
.all-sidebar-report .css-ahj2mt-MuiTypography-root
	font-size: 12px
	font-weight: 500
.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root,
.advisor-index button.MuiTab-labelIcon
	font-size: 21px
	font-weight: 300
	text-transform: capitalize
	color: #151313
	padding: 0
	margin: 0 31px 0 19px
	box-sizing: border-box
.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root:before,
.advisor-index button.MuiTab-labelIcon:before
	content: ""
	position: absolute
	left: 54%
	bottom: 9px
	width: 90%
	height: 2px
	background-color: transparent
	transform: translateX(-50%)
.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected:before,
.advisor-index button.Mui-selected:before
	background-color: #2947EF
.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
.advisor-index button.Mui-selected
	background: none
	color: #2947EF
	font-weight: 500
	font-size: 22px !important
.advisor-index button.css-1q2h7u5,
.advisor-index button.Mui-selected
	font-size: 18px !important
	text-transform: capitalize !important
.dahboard-tabs .MuiTabPanel-root .container
	max-width: 100%
.advisor-index .MuiTabs-root .MuiTabs-indicator
	display: none
.advisor-index .css-67l5gl.Mui-expanded
	margin: 0
.advisor-index .css-1iji0d4.Mui-expanded
	min-height: 50px
.advisor-index .css-9l3uo3
	font-size: 11px
.menuHover:hover .menu, .menu:hover
	pointer-events: auto
	opacity: 1
.shadow
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px
.new-shadow
	box-shadow: 0 0 13px #32325d20
.cpflp
	padding: 0.3rem 0.5rem
#hero
	background-image: url(../images/icons/hero.svg)
	background-size: 10rem
	background-position: center
	animation: heroMoveBackground 1000s linear infinite
.checkbox
	pointer-events: none
.checkbox > input
	-webkit-appearance: none
	-moz-appearance: none
	-o-appearance: none
	appearance: none
	pointer-events: auto
.checkbox > input:checked
	background-color: #FC7785
	border-color: #FC7785
.checkbox.active > input:checked
	background-color: #FC7785
	border-color: #FC7785
.checkbox::before
	content: ""
	position: absolute
	top: 52.5%
	left: 20%
	transform: rotate(45deg)
	background-color: #f0f0f8
	border-radius: 2px 0 2px 2px
	width: 30%
	height: 15%
.activeTabBlog::after
	content: ""
	border-radius: 20px
	width: 53px
	height: 2px
	position: absolute
	bottom: -10
	left: 50%
	transform: translateX(-50%)
	background-color: #2947ef
.checkbox::after
	content: ""
	position: absolute
	top: 42.5%
	left: 32.5%
	transform: rotate(-45deg)
	background-color: #f0f0f8
	border-radius: 0 2px 2px 0
	width: 50%
	height: 15%
	opacity: 0.4
.checkobox-report
	.checkbox
		-webkit-appearance: none
		-moz-appearance: none
		-o-appearance: none
		appearance: none
		pointer-events: auto
		background-color: #f0f0f8
		&::before
			content: ""
			position: absolute
			top: 52.5%
			left: 20%
			transform: rotate(45deg)
			background-color: #f0f0f8
			border-radius: 2px 0 2px 2px
			width: 30%
			height: 15%
			opacity: 0
		&::after
			content: ""
			position: absolute
			top: 42.5%
			left: 32.5%
			transform: rotate(-45deg)
			background-color: #f0f0f8
			border-radius: 0 2px 2px 0
			width: 50%
			height: 15%
			opacity: 0
		&:checked
			background-color: #FC7785
			border-color: #FC7785
			&::before
				opacity: 1
			&::after
				opacity: 0.4
.checkbox
	&.checkobox-round
		&::after
			display: none
	&.checkobox-round
		&::before 
			background-color: #f0f0f890
			width: 50%
			height: 50%
			top: 50%
			left: 50%
			transform: translate(-50%, -50%)
			border-radius: 50%
#popup-message.hidden
	transform: translateX(-125%)
@keyframes heroMoveBackground
	from
		background-position: 0 0
	to
		background-position: 1000rem -1000rem
@keyframes slideRight 
	from 
		right: 0
	to 
		right: -267px
.girlImage 
	width: 267px
	position: absolute
	top: -190px
	height: 381px
	animation: slideRight 1s forwards
#testimonialWrapper::before
	content: ""
	position: absolute
	top: 0
	left: 0
	width: 2.5rem
	height: 100%
	z-index: 1
	background: linear-gradient(90deg, #f0f0f8, transparent)
#testimonialWrapper::after
	content: ""
	position: absolute
	top: 0
	right: 0
	width: 2.5rem
	height: 100%
	z-index: 1
	background: linear-gradient(90deg, transparent, #f0f0f8)
table tr:nth-child(2n), .tableRow:nth-child(2n)
	background: #E1E1F155
table tr > *:nth-child(2n), .tableRow > *:nth-child(2n)
	background: #E1E1F155
.table-permission .table-userPermissions tr:nth-child(2n), .table-permission .table-userPermissions .tableRow:nth-child(2n)
	background: none
.table-permission .table-userPermissions tr > *:nth-child(2n), .table-permission .table-userPermissions .tableRow > *:nth-child(2n)
	background: none
.table-permission 
	.table-userPermissions 
		.advisor-profile
			background-color: #7385BE70
			border: 2px solid #7385BE50
			width: 2.5rem
			height: 2.5rem
table
	border-spacing: 0px
table th
	font-weight: 600
table th, table td
	padding: 0.5rem 1rem
	outline: 0
	min-width: 200px
	width: 20%
table thead th
	font-size: 1.5rem
	border-bottom: 2px solid #002d63
	background: #fff !important
	text-align: center
table td
	text-align: center
table tbody th
	background: #fff
	border-right: 2px solid #002d63

.table-advisor 
	th,td
		width: 150px
		min-width: auto
		svg
			width: 21px
			height: 21px
			display: inline-block
			margin-top: 8px
.table-advisor 
	td
		&:nth-child(4) 
			text-align: center
			svg
				width: 38px
				height: 38px
.audit-log-btn
	top: -125px
	right: 21px

.chat-gpt-drawer .css-4t3x6l-MuiPaper-root-MuiDrawer-paper,
.chat-gpt-drawer .css-wf16b5
	width: 60%

// Admin Table Styles
.speech-bubble::before
	content: ""
	background: linear-gradient(45deg, transparent 49%, #4a67ff 50%)
	position: absolute
	bottom: -1rem
	right: 0
	width: 1rem
	height: 1rem
.speech-bubble.bg5::before
	background: linear-gradient(-45deg, transparent 49%, #f0f0f8 50%)
	left: 0
.fds
	filter: drop-shadow(0 4px 8px #0008)
	//filter: drop-shadow(rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px)
#expandHeader
	width: 4rem
#expandHeader:hover
	width: 230px
.a4
	width: 220mm
	height: 340mm
.a4-pdf
	width: 210mm
	height: 297.7mm
.all-a4-pdf
	width: 210mm
	height: 350mm
.workflow-pdf
	width: 210mm
	height: 297.7mm
.lst
	list-style-type: none
@media (max-width: 74.99rem)
	#menu
		background: linear-gradient(45deg, #002d63, #4a67ff)
		width: 230px
		height: 100vh
		z-index: 5
		right: 0
	#menu:not(.showing)
		right:-100%
		&.showing
			right:0
.riskSlider input
	height: 1rem
	-webkit-appearance: none
	background: linear-gradient(90deg, #40dda1, #dd4040)
	height: 5px
.riskSlider input::-webkit-slider-thumb
	-webkit-appearance: none
	pointer-events: all
	position: relative
	z-index: 1
	outline: 0
	width: 2rem
	height: 2rem
	border-radius: 100%
	background: white
	background-position: center
	background-size: cover
	background-repeat: no-repeat
.riskSlider input::-moz-range-thumb
	pointer-events: all
	position: relative
	z-index: 10
	-moz-appearance: none
	width: 2rem
	height: 2rem
	border-radius: 100%
	background: white
	background-position: center
	background-size: cover
	background-repeat: no-repeat
.riskSlider input.riskIfYes::-webkit-slider-thumb
	background-image: url(../images/icons/Yes.png)
.riskSlider input.riskIfYes::-moz-range-thumb
	background-image: url(../images/icons/Yes.png)
.riskSlider input.riskIfNo::-webkit-slider-thumb
	background-image: url(../images/icons/No.png)
.riskSlider input.riskIfNo::-moz-range-thumb
	background-image: url(../images/icons/No.png)
.riskSlider input.riskIf1::-webkit-slider-thumb
	background-image: url(../images/icons/1.png)
.riskSlider input.riskIf1::-moz-range-thumb
	background-image: url(../images/icons/1.png)
.riskSlider input.riskIf2::-webkit-slider-thumb
	background-image: url(../images/icons/2.png)
.riskSlider input.riskIf2::-moz-range-thumb
	background-image: url(../images/icons/2.png)
.riskSlider input.riskIf3::-webkit-slider-thumb
	background-image: url(../images/icons/3.png)
.riskSlider input.riskIf3::-moz-range-thumb
	background-image: url(../images/icons/3.png)
.riskSlider input.riskIf4::-webkit-slider-thumb
	background-image: url(../images/icons/4.png)
.riskSlider input.riskIf4::-moz-range-thumb
	background-image: url(../images/icons/4.png)
.riskSlider input.riskIf5::-webkit-slider-thumb
	background-image: url(../images/icons/5.png)
.riskSlider input.riskIf5::-moz-range-thumb
	background-image: url(../images/icons/5.png)
.riskSlider input.riskIfD::-webkit-slider-thumb
	background-image: url(../images/icons/1.png)
.riskSlider input.riskIfD::-moz-range-thumb
	background-image: url(../images/icons/1.png)
.riskSlider input.riskIfNS::-webkit-slider-thumb
	background-image: url(../images/icons/2.png)
.riskSlider input.riskIfNS::-moz-range-thumb
	background-image: url(../images/icons/2.png)
.riskSlider input.riskIfND::-webkit-slider-thumb
	background-image: url(../images/icons/3.png)
.riskSlider input.riskIfND::-moz-range-thumb
	background-image: url(../images/icons/3.png)
.riskSlider input::-moz-range-track
	position: relative
	z-index: -1
	background-color: rgba(0, 0, 0, 1)
	border: 0
.riskSlider input:last-of-type::-moz-range-track
	-moz-appearance: none
	background: none transparent
	border: 0
.riskSlider input[type=range]::-moz-focus-outer
	border: 0
.gtcr-audit
	display: grid
	grid-template-columns: 175px 75px 203px 203px
	grid-gap: 10px
	padding: 7px 10px
	a
		overflow-wrap: anywhere
.gtcr-workflow-head
	grid-template-columns: 175px 75px 203px 203px
.gtcr-audit-two
	display: grid
	grid-template-columns: 175px 75px 413px
	padding: 10px
	grid-gap: 10px
.gtcr-audit-workflow
	display: grid
	grid-template-columns: 210px 75px 435px
	padding: 10px
.gtcr-audit-fit-report
	display: grid
	grid-template-columns: 335px 340px
	padding: 10px
	grid-gap: 10px
.gtcr-audit-fit-report-about
	display: grid
	grid-template-columns: 130px 165px 165px 165px
	padding: 10px
	grid-gap: 10px
.logo-img 
	width: 100px
.button-groups-new
	.close-popup
		background-color: #ffffff00
		position: absolute
		right: 0
		svg
			box-shadow: none
			background-color: #ffffff00
	button
		position: relative
		svg
			top: auto
			bottom: -10px
			left: -8px
			background-color: #fff
			padding: 5px
		.director-tooltip
			bottom: -25px
			left: -70px
.packge-select
	display: flex
	align-items: center
	justify-content: center
	&.bottom-packge
		.audit-pakcges
			&.bronze-pakcge-brown
				background-image: url('../images/bronze.png')
			&.silver-pakcge
				background-image: url('../images/silver.png')
			&.gold-pakcge
				background-image: url('../images/gold.png')
			.left-area
				position: absolute
				bottom: 6px
				display: flex
				align-items: center
				justify-content: center
				width: 100%
				p, span
					font-size: 0.7rem
					margin-left: 3px
			.right-area
				width: 100%
				position: absolute
				bottom: 33px
				p
					font-size: 0.40rem
					color: #000
	.audit-pakcges
		background-repeat: no-repeat
		background-size: 100% 100%
		flex-wrap: wrap
		align-items: center
		width: 122px
		margin-bottom: 10px
		display: flex
		position: relative
		height: 119px
		margin: 0 8px
	.currentz
		box-shadow: 0px 40px rgba(65 220 159)
		border-radius: 97px
.op70
	opacity: 0.8
.z12
	zoom: 1.2
.extenstion-pack-btn-groups
	.btn-packge
		background-color: #f0f0f8
		position: relative
		border-radius: 12px
		display: flex
		flex-wrap: wrap
		align-items: center
		font-size:16px
		min-height:115px
		transition: all 0.3s ease-in-out
		.ribbon
			position: absolute
			bottom: 0
			right: -37px
			transform: rotate(-45deg)
			img
				width: 99px
				height: auto !important
			&.bronze, &.silver, &.gold
				transform: rotate(0deg)
				right: -20px
				bottom: -14px
				img
					width: 53px
		.trophy-cup
			transform: rotate(0deg) !important
			position: absolute
			top: 65px
			right: -7px !important
			img
				width: 53px !important
		&.op20 
			background-color: #f0f0f880g
			color: #002d6340
			opacity: 1
			border-color: #ccc
			transition: all 0.3s ease-in-out
		button
			width: 70%
			font-size: 12px !important
			transition: all 0.3s ease-in-out
		&:hover
			box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
		.tooltip-area
			position: absolute
			top: -6px
			right: -8px
			.icon
				position: relative
				display: inline-block
				transition: all 0.3s ease-in-out
				img
					width: 21px
					height: auto
				.overlay-box
					font-style: normal
					position: absolute
					right: 25px
					min-width: 350px
					background-color: #fff
					z-index: 999
					top: 50%
					box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
					padding: 20px 16px 25px
					border-radius: 20px
					text-align: left
					transition: all 0.3s ease-in-out
					display: none
					transform: translateY(-50%)
					&:before
						content: ""
						position: absolute
						right: -14px
						width: 0px
						height: 0px
						border-style: solid
						border-width: 15px 0 15px 15.9px
						border-color: transparent transparent transparent #fff
						transform: translateY(-50%)
						top: 49%
					p
						font-size: 13px
						margin-bottom: 5px
						font-weight: 400
						font-style: normal
						text-transform: none
					.bottom-text
						p
							font-size: 11px
					.price
						font-size: 19px
						text-align: center
						margin-top: 13px
						font-style: normal
					button
						z-index: 999
			&:hover
				.overlay-box
					display: block
					transition: all 0.3s ease-in-out
			&.style-two
				top: -25px
				right: -15px
		.percentage-area
			background-color: #ffffff90
			padding: 5px
			box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
			width: 55px
			height: 55px
			border-radius: 50%
			h4
				span
					font-weight: 400
				&.red-text
					color: #fc7785
					font-size: 12px
					line-height: 1.1
					span
						color:#002d63
						font-size: 9px
			.progress-bar
				svg
					background-color: transparent
					box-shadow: none
					padding: 0
			.text-center-area
				font-size: 6px !important
				line-height: 6px !important
				display: block
.white
	color: #fff
.blue
	color: #4a67ff
.red
	color: #ff3d3d
.yellow
	color: #fcb22b
.green
	color: #5ee15e

.fw500
	font-weight: 500
.w24
	width: 18%
.w250px
	width: 270px
.w45
	width: 45%
.w50
	width: 50%
.w43
	width: 43%
.w33
	width: 33.33%
.CircularProgressbar
	&.red
		.CircularProgressbar-path
			stroke: #ff3d3d
		.CircularProgressbar-text
			fill: #151313
	&.yellow
		.CircularProgressbar-path
			stroke: yellow
		.CircularProgressbar-text
			fill: #151313
	&.orange
		.CircularProgressbar-path
			stroke: #fcb22b
		.CircularProgressbar-text
			fill: #151313
	&.green
		.CircularProgressbar-path
			stroke: #5ee15e
		.CircularProgressbar-text
			fill: #151313

.CircularProgressbar.red .CircularProgressbar-trail
	stroke: #ff3d3d40 !important
.CircularProgressbar.blue .CircularProgressbar-trail
	stroke: #4a67ff40 !important
.CircularProgressbar.yellow .CircularProgressbar-trail
	stroke: #ffff0040 !important
.CircularProgressbar.green .CircularProgressbar-trail
	stroke: #5ee15e40 !important
.CircularProgressbar.orange .CircularProgressbar-trail
	stroke: #fcb22b40 !important
#expandHeader .pr.df a, #expandHeader .pr.df span.usn
	position: relative
	font-family: "Poppins", sans-serif
	color: #151313 !important
	font-size: 14px
	padding: 13px 15px 13px 40px
	box-sizing: border-box
	margin: 0 0 7px
	border-radius: 7px
	.dropdown
		position: absolute
		left: 0
		background-color: #fff
		z-index: 99
		display: block
		top: 32px
		box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
		padding: 10px
		border-radius: 5px
		width:100%
		display: none
		a
			display: block
			width: 100%
			padding: 0
			color: #002d63
			font-size: 15px
			margin-bottom:5px 
			&:hover
				color:#fc7785
	.icon
		position: absolute
		right: -4px
		top: 1px
		svg
			width: 46px
			zoom: 0.6
			opacity:0.5
	&:hover
		.dropdown
				display: block
	.dropdown
		position: relative
		.dropdown-menu
			left: 0
			background-color: #fff
			z-index: 99
			box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
			padding: 10px
			border-radius: 5px
			width: 100%
			display: none
			transition: all 0.3s ease-in-out
			.dropdown-item
				display: block
				width: 100%
				font-size: 16px
		button
			position: relative
			cursor: pointer
			background-color: transparent
			border: none
			padding-left: 1rem
			svg
				top: 15px
			.icon
				position: absolute
				right: -3px
				top: -7px
				transition: all 0.3s ease-in-out
				transform: rotate(0deg)
				svg
					width: 29px
					opacity: 0.5
	.dropdown-show
		position: relative
		.icon
			transition: all .3s ease-in-out
			transform: rotate(90deg)
			top: -3px
		.dropdown-menu
			left: 0
			background-color: #fff
			z-index: 99
			box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
			padding: 10px
			border-radius: 5px
			width: 100%
			display: block
			transition: all 0.3s ease-in-out
			.dropdown-item
				display: block
				width: 100%
				font-size: 16px
				padding-left: 0
#expandHeader .pr.df a:hover, #expandHeader .pr.df span.usn:hover
	background-color: #F5F6F9
	border-radius: 10px
.progress
	position: relative
	&:before
		content: attr(data-name)
		position: absolute
		width: 100%
		bottom: -2rem
		font-weight: 400
	&:after
		content: attr(data-percent)
		position: absolute
		width: 100%
		top: 3.7rem
		left: 0
		font-size: 2rem
		text-align: center
	svg
		width: 10rem
		height: 10rem
		&:nth-child(2)
			position: absolute
			left: 0
			top: 0
			transform: rotate(-90deg)
			-webkit-transform: rotate(-90deg)
			-moz-transform: rotate(-90deg)
			-ms-transform: rotate(-90deg)
			path
				fill: none
				stroke-width: 25	
				stroke-dasharray: 629
				stroke: #fc7785
				-webkit-animation: load 10s
				-moz-animation: load 10s
				-o-animation: load 10s
				animation: load 10s
.progress-bar
	position: relative
	margin: 0 auto
	.text-center-area
		position: absolute
		bottom: 24px
		left: 50%
		transform: translateX(-50%)
		font-size: 11px
		text-align: center

.tooltip-input,
.progress-area
	position: relative
	.tooltip-area
		position: absolute
		right: 0
		top: 0
		cursor: pointer
		.icon
			position: relative
			img
				height: auto
				width: 24px
			.overlay-box
				position: absolute
				display: none
				left: -300px
				top: 50%
				width: 250px
				transform: translateY(-50%)
				background-color: #fff
				padding: 16px
				border-radius: 8px
				text-align: left
				transition: all 0.3s ease-in-out
				box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
				&:before
					content: ""
					position: absolute
					right: -14px
					width: 0px
					height: 0px
					border-style: solid
					border-width: 15px 0 15px 15.9px
					border-color: transparent transparent transparent #fff
					transform: translateY(-50%)
					top: 46%
				p
					font-size: 14px
					font-style: normal
					font-weight: 400
		&:hover
			.overlay-box
				display: block
.button-text
	display: block
	position: relative
	width: 83%
	svg
		position: absolute
		margin: 0
		top: 50%
		right: 19px
		transform: translateY(-50%)
	p
		padding: 0
		border: none
		margin: 0
.tooltip-box
	position: relative
	.tooltip-area
		position: absolute
		top: 50%
		transform: translateY(-50%)
		right: -262px
		width: 248px
		display: none
		z-index: 999
		.overlay-box
			position: relative
			background-color: #f0f0f8
			padding: 16px
			border-radius: 8px
			text-align: center
			transition: all 0.3s ease-in-out
			box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
			img
				height: 350px
				margin: 0 auto
				text-align: center
		&:before
			content: ""
			position: absolute
			left: -14px
			width: 0px
			height: 0px
			border-style: solid
			border-width: 15px 15px 15.9px 0
			border-color: transparent #f0f0f8 transparent transparent
			transform: translateY(-50%)
			top: 50%
	&:hover
		.tooltip-area
			display: block
.tooltip-box-modal
	position: relative
	.tooltip-area
		position: relative
		margin-left: 12px
		cursor: pointer
		.icon
			position: relative
			display: inline-block
			transition: all 0.3s ease-in-out
			img
				width: 21px
				height: auto
			.overlay-box
				position: absolute
				right: 34px
				min-width: 350px
				background-color: #fff
				z-index: 9
				top: 50%
				box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
				padding: 16px
				border-radius: 8px
				text-align: left
				transition: all 0.3s ease-in-out
				display: none
				transform: translateY(-50%)
				&:before
					content: ""
					position: absolute
					right: -14px
					width: 0px
					height: 0px
					border-style: solid
					border-width: 15px 0 15px 15.9px
					border-color: transparent transparent transparent #fff
					transform: translateY(-50%)
					top: 49%
				p
					font-size: 13px
					margin-bottom: 5px
					font-weight: 400
					font-style: normal
				.bottom-text
					p
						font-size: 11px
				.price
					font-size: 19px
					text-align: center
					margin-top: 13px
					font-style: normal
		&:hover
			.overlay-box
				display: block
				transition: all 0.3s ease-in-out
		&.style-two
			top: -29px
			right: -25px
			z-index: 2
.module-tooltip-box
	position: static
	.tooltip-area
		z-index: 999
		top: 50% !important
		left: 6% !important
		transform: translateY(-50%)
		width: 220px
		display: none
		background-color: #fff
		border-radius: 8px
		padding: 8px
		transition: all .3s ease-in-out
		position: absolute
		box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
		.overlay-box
			background-color: #f0f0f8
			font-size: 8px
			color: #000
			padding: 10px
			transition: all 0.3s ease-in-out
			box-sizing: border-box
		.dashboard-permission-overlaybox
			padding: 4px 0px
	.tooltip-area-permissions
		top: 90% !important
		left: -75% !important
	&:hover
		.tooltip-area
			display: block
	svg
		width: 8%
		background-color: #fff
		padding: 5px
		top: auto
		bottom: -12px
		left: -12px
		box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
		z-index: 1
.certificate-tooltip-box
	position: relative
	.certificate-tooltip-area
		display: none
		position: absolute
		top: -100px
		left: 70px
		width: 195px
		height: 7vh
		text-align: center
		padding: 20px
		z-index: 9
		background-color: #f0f0f8
		border-radius: 8px
		transition: all .3s ease-in-out
		box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
	&:hover
		.certificate-tooltip-area
			display: block
.progress-box
	text-align: center
	.text-center-area
		text-align: center !important
.expansionpack-page
	position: relative
	.container
		flex-wrap: wrap
		.lef-img-area
			width: 25%
			.img-box
				position: relative
				img
					width: 100%
					height: auto
		.right-text-area
			width: 43%
			margin-left: -126px
			position: relative
			&:before
				content: ""
				position: absolute
				top: -54px
				left: -287px
				width: 551px
				height: 348px
				background-repeat: no-repeat
				background-size: 100%
				background-image: url('../images/text-bg.png')
			p
				text-align: center
				position: relative
				&:before
					content: ""
					position: absolute
					bottom: -26px
					left: -27px
					width: 57px
					height: 57px
					background-repeat: no-repeat
					background-size: 100%
					background-image: url('../images/left-angle.png')
				&:after
					content: ""
					position: absolute
					top: -46px
					right: -27px
					width: 57px
					height: 57px
					background-repeat: no-repeat
					background-size: 100%
					background-image: url('../images/right-angle.png')
			.number-sec
				position: relative
				margin-top: 64px
				&:before
					content: ""
					position: absolute
					left: -19px
					width: 105%
					height: 77%
					bottom: 26px
					background-color: #002d63
					border-radius: 25px
				ul
					list-style: none
					position: relative
					padding: 15px
					margin-left: 0 !important
					li
						position: relative
						padding-bottom: 13px
						font-weight: 600
						.icon
							margin-right: 15px
							img
								height: auto
								width: 11px
								position: relative
								top: 3px
						span
							color: #4a67ff
							margin-right: 1px
			.about-img
				position: relative
				display: flex
				flex-wrap: wrap
				margin-top: 43px
				.img-box 
					width: 28%
					position: relative
					img
						width: 172px
				.text-box
					width: 66%
					height: 138px
					margin-top: 6px
					padding: 11px 12px
					margin-left: -2px
					p
						&:before,
						&:after

							display: none

// CUSTOM STYLES CLASSES // 




.p0-5rem
	padding: 0.5rem
.h75vh
	height:75vh
.mb0-7rem
	margin-bottom: 0.7rem !important
.mb1-7rem
	margin-bottom: 1.7rem !important
.minh100vh button
	font-size: 14px
#e1jygW4piZH1
	z-index: 1500
#fileUploadRepository,
#fileUploadFilingAssistant,
#repositoryToDataRoom,
#syncRepositoryDataRoom
	text
		font-size:50px !important
#cloud
	min-height: 600px

.tooltip-btn
	position: absolute
	top: -51px
	background-color: #22369b
	padding: 6px 22px
	right: 0
	border-radius: 43px 6px 6px 3px
	&:before
		content: ""
		position: absolute
		right: 6px
		bottom: -10px
		width: 0
		height: 0
		border-style: solid
		border-width: 11px 11px 0 11px
		border-color: #22369b transparent transparent transparent
.benfits-sec
	background-image: url('../images/benifts-bg.png')
	background-size: 100%
	background-repeat: no-repeat
.modal-sec
	background-image: url('../images/modal-bg.png')
	background-size: 100% 100%
	background-repeat: no-repeat
	.heading
		text-align: center
		margin-top: -68px
		margin-bottom: 28px
.new-report
	background-image: url('../images/psd-bg.jpg')
	background-position: top center
	background-repeat: no-repeat
	background-size: 100% 100%
	padding: 2rem 3rem
	&.report-ai
		background-image: url('../images/bg-ai.jpg')
	.logo-report
		position: relative
		border-bottom: 3px solid #002d63
	.right-list
		position: relative
		text-align: right
		list-style: none
		padding: 0
		margin: 0
		li
			margin-bottom: 3px
			font-size: 14px
			font-weight: 700
			span
				font-weight: 400
				width: 250px
				display: inline-block
.workflow-report
	padding: 2rem
.new-report-btn
	padding: 0
	margin-bottom: 4px
	display: flex
	border-radius: 5px
	width: 100%
	cursor: pointer
	&:hover
		box-shadow: 0 0 8px rgba(0 0 0 / 10%)
	span
		padding: 2px 11px
		color: #002d63
		font-weight:600
		position: relative
		margin-left: 25px
	.left-heading
		border-right: 3px solid #fff
		width: 150px
		display: inline-block
		padding: 2px 11px
		text-align: center
		margin-left: 0
		&:before
			content:""
			position: absolute
			left: 0
			top: 50%
			width: 6px
			height: 74%
			background-color: #002d63
			border-radius: 0 10px 10px 0
			transform: translateY(-50%)
.progress-area-report
	position: relative
	.all-report-progress-box
		margin: 0 27px 22px 33px 
	.progress-box
		position: relative
		margin: 0 27px 32px 33px
		&::before
			content: ""
			position: absolute
			width: 27px
			height: 27px
			bottom: -10px
			left: -31px
			background-size: 100%
			background-repeat: no-repeat
			background-image: url('../images/triangle-progress.png')
		&::after
			content: ""
			position: absolute
			right: -31px
			top: -10px
			width: 27px
			height: 27px
			background-size: 100%
			background-repeat: no-repeat
			background-image: url('../images/triangle-progress.png')
			transform: rotate(180deg)
		.text-center-area
			bottom: 42px
	.progress-box-hidden
		width: 25%
.tables-area
	position: relative
	background-color: #f5f6ff
	padding: 15px
	box-shadow: 0 0 10px rgba(0 0 0 / 20%)
	.new-report-btn
		.left-heading
			border-right: 3px solid #f5f6ff
.register-btn-area
	position: relative
	margin: 85px 0 28px
	text-align: center
	p
		margin-bottom: 36px
	.register-btn
		position: relative
		padding: 11px 75px
.kpis-text
	position: relative
	margin: 74px 0 250px
	text-align: center
	h3
		font-size: 75px
		color: #002d6350
.mb1-3rem
	margin-bottom: 1.2rem

.certificates-page,
.company-audit-page,
.checklist-page
	background-image: url('../images/dashboard-certificates-bg.jpg')
	background-position: top center
	background-repeat: no-repeat
	background-size: 100% 100%
	.requirement-area
		text-align: center
		margin-left: 362px
		margin-top: 15px
		font-size: 14px
		font-weight: 600
	.left-upgrade-button
				text-align: center
				padding: 35px 0px 20px
				position: relative
				width: 100%
				transition: all 0.3s ease-in-out
				.badge-btn
					background-size: 100% 100%
					background-color: transparent
					border: none
					color: #fff
					padding: 16px 75px 17px 29px
					font-size: 14px
					background-repeat: no-repeat
					background-position: top center
					background-image: url('../images/upgrage-button.png')
					transition: all 0.3s ease-in-out
					transform: scale(1)
					&:hover
						transform: scale(1.1)
	&.disabled-certificates-page
		width: 100%
		.badge-btn
			pointer-events: none
		.left-upgrade-button
			a
				pointer-events: none
		.upgrade-btns, butoon
			pointer-events: none
		.certification-pricing-column
			a
				pointer-events: none
	.heading-area
		position: relative
		background-position: top center
		background-repeat: no-repeat
		background-size: 574px 63px
		text-align: center
		&.bronze
			background-image: url('../images/bronze-level.png')
		&.silver
			background-image: url('../images/sliver-level.png')
		&.gold
			background-image: url('../images/gold-level.png')
	.badges-sec
		padding: 10px 0
		position: relative
		.badges-area
			align-items: center
			display: flex
			position: relative
			justify-content: center
			width: 100%	
			flex-wrap: wrap
	.certificates-current
		.top-heading
			padding: 0 10px 35px
		.packge-select
			zoom: 0.9
			display: flex
			justify-content: space-between
			width: 100%
			.current
				.w250px
					box-shadow: 0 0 26px #41dc9f60
					border-radius: 97px
	.progress-sec
		position: relation
		.progress-list
			position: relative
			list-style: none
			margin: 15px 0
			justify-content: space-between
			padding: 9px 8px 4px
			list-style: none
			display: flex
			position: relative
			background-size: 100% 100%
			background-repeat: no-repeat
			background-position: top right
			background-image: url('../images/certificat-bg-list.png')
			li
				opacity: 0.5
				.certificate-btn
					background-color: transparent
					border: none
					img
						width: 100%
				&.current
					opacity:1
	.certificate-progress-bar
		.progress
			width: 100%
			height: 18px
			border-radius: 15px
			.progress-bar
				background: #16b216 !important
				border-radius: 15px
	.data-check-box
		position: relative
		padding: 20px
		box-shadow: 0 0 10px rgba(0 0 0 / 30%)
		border-radius: 21px
		margin: 20px 0 0
		&.data-check-box-empty
			box-shadow: 0 0 0 rgba(0 0 0 / 30%)
		.packge-select
			position: absolute
			bottom: 31px
			text-align: center
			img
				margin: 0 auto
		.stuts-btn
			position: absolute
			right: 11px
			bottom: 11px
			background-color: transparent
			border: none
			img
				width: 109px
		.data-list
			list-style: none
			padding: 0
			margin: 0 0 30px 0
			position: relative
			&:before
				content: ""
				position: absolute
				left: 22px
				top: 0
				width: 2px
				height: 100%
				background-color: #e7e7e7
			li
				position: relative
				padding-left: 44px
				padding-bottom: 15px
				opacity: 0.5
				&:before
					content: ""
					position: absolute
					left: 19px
					top: 9px
					width: 8px
					height: 8px
					background-color: #e7e7e7
					border-radius: 50%
				h4
					font-size: 15px
					font-weight: 500
				p
					font-size: 12px
				.icon
					position: absolute
					left: -7px
					top: 4px
					display: none
					&.round
						display: block
						height: 14px
						width: 14px
						background-color: #0000004d
						border-radius: 50%
					img
						width: 17px
				&.check
					opacity: 1
					.icon
						&.round
							display: none
						&.check
							display: block
	.btn-back
		text-align: center
		position: relative
		.upgrade-btns
			position: relative
			&:before
				position: absolute
				content: ""
				border-top: 18px solid transparent
				border-bottom: 18px solid transparent
				border-left: 21px solid #4A67FE
				right: -20px
				top: 2px
				transition-duration: .25s
			&:hover
				&:before
					border-left: 21px solid #fc7785

.footer-sec
	position: relative
	background-size: 100% 100%
	background-repeat: no-repeat
	background-position: top center
	padding: 20px 0
	.check-box
		border-radius: 21px
		padding: 40px 0 141px
		position: relative
		box-shadow: 0 0 10px #0000004d
		.certification-pricing-column
			position: absolute
			right: 650px
			top: 40px
			height: 0
			width: 315px
			zoom: 0.7
			span
				&.h100px
					width: 50px
					height: 50px
	h2
		width: 100%
		text-align: center
		img
			width: 550px
	.certificates-current
		position: relative
		width: 68%
		margin: 30px auto
		.audit-pakcges
			zoom: 0.6
			opacity: 1 !important
			box-shadow: none !important
	.upgrade-btn
		position: relative
		text-align: center
		width: 100%
		background-color: transparent
		border: none
		zoom: 0.5
		transition: all 0.3s ease-in-out
		transform: scale(1)
		&:hover
			transform: scale(1.1)
.question-box
	padding: 15px
	color: #002d63
	margin-top: 10px
	position: relative
	border-radius: 8px
	border: 2px solid #281ac9
	h3
		position: relative
		padding-left: 25px
		padding-bottom: 13px
		&:before
			content: ""
			position: absolute
			left: 0
			top: 11px
			width: 10px
			height: 10px
			background-color: #fc7785
			border-radius: 50%
.recomended-box
	padding: 5px 15px 5px
	margin-bottom: 20px
	&:before
		content: ""
		position: absolute
		left: 50%
		bottom: -5px
		width: 30%
		height: 4px
		background-color: #9f9f9f
		transform: translateX(-50%)
#sidebar
	button
		.close-svg
			display: none
		&.close-report
			.close-svg
				display: inline-block
.investmentcertificate-page
	position: relative
	background-image: url('../images/investmen-certificate-bg.png')
	background-size: 100%
	background-repeat: no-repeat
	background-position: bottom center
	background-color: #fff
	.header-area
		background-image: url('../images/investmen-certificate-header-bg.png')
		background-size: 100%
		background-repeat: no-repeat
		background-position: bottom center
		position: relative
		height: 407px
		text-align: center
		padding-top: 10px
	.certificate-area
		position: relative
		margin-top: -196px
		.left-area
			width: 100%
			position: relative
			padding: 0 27px
			display: block
			box-sizing: border-box
			width: 100%
			h5
				font-style: italic
			.status-area
				position: relative
				padding-left: 33px
				margin: 20px 0
				display: block
				font-size: 18px
				font-weight: 500
				&:before
					content: ""
					position: absolute
					left: 0
					top: 12px
					width: 24px
					height: 6px
					background-color: #002d63
				.PassFail
					position: relative
					font-style: normal
					padding: 2px 10px
					display: inline-block
					color: #999999
					text-transform: uppercase
					&.Pass
						background-color: #40dda1
					&.Fail
						background-color: #dd4040
					&.Uncertified
						background-color: #e5e3e3
			.signure-area
				position: relative
				.date-area
					display: inline-block
					text-align: center
					.logo-certified
						width: 180px
					&.singature-area
						margin-top: -45px
					p, figure
						position: relative
						padding-bottom: 10px
						margin-bottom: 10px
						display: block
						&::before
							content: ""
							position: absolute
							left: 50%
							bottom: 0
							width: 200px
							height: 4px
							background-color: #416ffd
							transform: translateX(-50%)
		.right-area
			position: relative
			width: 81%
			margin: 0 auto
			.op70
				opacity: 1
				img
					width: 150px
					&.w7rem
						width: 127px
				.fs1-5rem
					font-size: 0.7rem
				
			.w7rem
				width: 13rem
	.logo-area
		text-align: center
.step-box
	text-align: center
	color: #a5a4a7
	background-color: transparent
	border-radius: 8px 8px 0 0
	padding: 4px 16px 2px
	font-size: 9px
	position: absolute
	bottom: 0
	border: 1px solid #a5a4a780
	border-bottom: none
	left: 50%
	transform: translateX(-50%)
.button-left-area
	display: flex
	align-items: center
.contact-btn
	position: relative
	margin-top: 8px
	margin-bottom: 21px
	display: block
	width: 100%
	text-align: center
	.contact-us-btn
		border-bottom: 1px solid #fc7785
.questionnaire-sidebar, .esg-sidebar
	display: block
	height: 100vh
	.mah100
		margin: 15px 0
	.mta
		position: absolute
		bottom: 0
		width: 93%
.verify-btn
	position: absolute
	right: 0
	border-radius: 0 5px 5px 0
.report-page
	.sidebar-report
		display: block
		height: 100vh
		.mah100
			&.mya
				&.oya
					&.df
						&.fdc
							&.jcsb
								max-height: 55vh
								margin: 5px 0 0
		.mta
			position: absolute
			bottom: 0
			width: 94%
		.siedbar-dropdwon
			max-height: 71vh
			.oya.df.fdc.jcsb
				max-height: 100% !important
				border-left: 2px solid #fff
			.MuiPaper-root
				background-color: transparent !important
.report-page
	.sidebar-report
		&.repository-sidebar
			.mah100
				&.mya
					&.oya
						&.df
							&.fdc
								&.jcsb
									max-height: 53vh
.showDirectors
	.inner-showDirectors
		position: relative
		width: 900px
		background-image: url(../images/popup-modal-bg.jpg)
		background-size: 100% 100%
		background-repeat: no-repeat
		background-position: top center
		padding: 60px
		border-radius: 10px
		.top-header
			position: relative
			margin-bottom: 40px
			width: 100%
			button
				margin: 0
				top: 41px
				right: -20px
				font-size: 14px
				position: absolute
				padding: 7px 20px
		.table-area-showDirectors
			max-width: 100%
			max-height: 60vh
			min-height: auto
			overflow: scroll
			position: relative
			box-shadow: 0 0 5px rgba(0 0 0 / 20%)
			border-radius: 0 0 10px 10px
			.table-showDirectors
				width: 100%
				position: relative
				thead
					position: relative
					tr
						position: relative
						th
							font-size: 15px
							text-align: left
							box-shadow: 0 0 5px rgba(0 0 0 / 20%)
							color: #002d63
							font-weight: 400
							border-bottom: none
							position: relative
							border-bottom: 5px solid #e3e8ee
							width: auto !important
							min-width: auto !important
							background-color: transparent
							padding: 9px 23px
							&:before
								content: ""
								position: absolute
								right: 1px
								top: 0
								width: 5px
								height: 100%
								background-color: #fff
								box-shadow: 0 0 5px rgba(0 0 0 / 20%)
				tbody
					position: relative
					tr
						position: relative
						background: none !important
						td
							text-align: left
							width: auto !important
							min-width: auto !important
							padding: 9px 23px
							border-bottom: 1px solid #e3e8ee
							background-color: transparent !important
							position: relative
							font-size: 12px
							&:before
								content: ""
								position: absolute
								right: 1px
								top: 0
								width: 5px
								height: 100%
								background-color: #fff
								box-shadow: 0 0 5px rgba(0 0 0 / 20%)
							button
								padding: 4px 20px
			.logo-modal
				position: relative
				width: 100%
				text-align: center
				padding: 10px 0
.close-popup
	position: absolute
	right: 15px
	top: 15px
	background-color: transparent
	box-shadow: none
	cursor: pointer
	svg
		fill: #4a67ff
	&.style-two
		svg
			fill: #fc7785
.sectionWrapper
	.group-inputs
		position: relative
		margin-bottom: 5px
		input, textarea
			width: 100%
			background-color: #f0f0f800
			border: none
			border-radius: 5px
			padding: 0
			position: relative
			top: 0
			left: 8px
			font-size: 15px
			color: #002d63
			font-size: 0.75rem
		.show-input
			opacity: 1
			position: relative
			left: 0
			top: 0
			background-color: #f0f0f8
		textarea
			height: auto
		p
			position: relative
			display: inline-block
			transition: all 0.3s ease-in-out
			z-index: 1
			width: 100%
			&:hover
				.edit-btn					
					visibility: visible
					opacity: 1
		.edit-btn
			display: inline-block
			transition: all 0.3s ease-in-out
			.edit-pencil
				cursor: pointer
				background-color: transparent
				border: none
				margin-left: 11px
				svg
					fill: #fc7785
					width: 15px
					position: relative
					top: 2px
					height: 15px
		.check-btn
			display: inline-block
			transition: all 0.3s ease-in-out
			opacity: 1
			visibility: visible
			.ok-btn
				cursor: pointer
				background-color: transparent
				border: none
				margin-left: 11px
				img
					width: 15px
		&.link-group
			p
				display: inline-block
				width: auto
				a
					display: inline-block
					width: 250px
					white-space: nowrap
					overflow: hidden !important
					text-overflow: ellipsis
					position: relative
					top: 5px
		.edit-text
			display: flex
			&:hover
				.edit-btn
					opacity: 1
					visibility: visible
	.company-name
		position: relative
		display: inline-block
		margin-bottom: 1rem
		&:before
			content: ""
			position: absolute
			left: 0
			bottom: -8px
			width: 100%
			height: 2px
			background-color: #002d63
.edit-button-top
	cursor: pointer
	background-color: transparent
	border: none
	svg
		width: 21px
		position: relative
		top: 7px
		fill: #fc7785
.company-settings
	position: absolute
	right: 33px
	top: -22px
	svg
		width: 49px
.avtar-area
	img
		width: 39px
		margin-bottom: 10px
		height: auto
.slider-items
	position: relative
	overflow: hidden
.slider-container
	padding: 0 10px
	display: flex
	overflow-x: hidden
	scroll-behavior: smooth
	.item
		min-width: 19.2%
		max-width: 19.2%
		position: relative
		padding-bottom: 58px
		font-size: 14px
		.readmore-btn
			position: absolute
			bottom: 14px
			left: 50%
			transform: translateX(-50%)
			font-size: 14px
			padding: 7px 15px
.btn-area-slider
	button
		.icon
			img
				height: auto
				width: 7px
				position: relative
				top: 2px
				filter: brightness(100)
		&.pre-btn
			.icon
				transform: rotate(180deg)
.directors-info-box
	button
		.icon
			img
				height: auto
				width: 18px
				filter: brightness(100)
				margin-right: 11px
				position: relative
				top: 1px
.multi-dropdown .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked
	color: #fc7785

ul.MuiList-root li.multi-dropdown-list .MuiCheckbox-root.Mui-checked, 
ul.MuiList-root li.multi-dropdown-list .MuiCheckbox-root.MuiCheckbox-indeterminate
	color: #fc7785
.multi-dropdown .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline
	border-color: #f0f0f8 !important
.multi-dropdown fieldset.MuiOutlinedInput-notchedOutline
	border-color: #f0f0f8 !important
ul.MuiList-root li.multi-dropdown-list.Mui-selected
	background-color: #fc778510
#sidebar-company-settings
	button
		transition: all 0.3s ease-in-out
		&.active,
		&:hover
			background-color: #002d63
			color: #fff !important
#sidebar-company-settings .delete-active
	color: #f50100
	&:hover
		background-color: #f50100 !important
		color: #fff !important
.company-snapshot-btn
	background-color: transparent
	border-bottom: 1px solid #4a67ff00
	&.active, &:hover
		border-bottom: 1px solid #4a67ff
		color: #4a67ff
		span
			color: #4a67ff
.user-tabs
	.user-btn
		position: relative
		&.active, &:hover
			button
				background-color: #002d63
.table-of-content
	margin: 0 auto
	.list-table
		padding: 0
		li
			position: relative
			margin-bottom: 10px
			&:before
				content: ""
				top: 50%
				height: 5px
				border-bottom: 2px dashed
				transform: translateY(-50%)
				right: 57px
				position: absolute
			&:nth-child(1)
				&:before
					width: 76%
			&:nth-child(2)
				&:before
					width: 76%
			&:nth-child(3)
				&:before
					width: 76%
			&:nth-child(4)
				&:before
					width: 62%
			&:nth-child(5)
				&:before
					width: 76%
			i
				font-style: normal
	&.style-two
		.list-table
			li
				&:nth-child(1)
					&:before
						width: 72%
				&:nth-child(2)
					&:before
						width: 72%
				&:nth-child(3)
					&:before
						width: 72%
.policy-page-data
	p
		margin-bottom: 20px
.type-customer
	.checkbox
		&.active
			input
				&:checked
					background-color: #bbb
					border-color: #bbb
.type-customer
	.checkbox
		input
			&:checked
				background-color: #bbb
				border-color: #bbb
.poupup-img
	width: 120px
	height: 120px
	border: 2px dashed
	border-radius: 18px
	padding: 10px
	background-color: #f0f0f8
.tooltip-area-company-settings
	top: -11px
	right: -11px
	.icon
		img
			width: 18px
.button-groups-new
	.company-settings-btn
		position: absolute
		bottom: -16px
		left: 6px
		background-color: transparent
		padding: 0
		margin: 0
		width: 2.6rem
		svg
			background-color: transparent
			box-shadow: none
			bottom: auto
			left: auto
			width: 3rem !important
			height: 3rem
.certificate-area-dashboard
	.fs0-75rem
		font-size: 0.6rem
.w20rem
	width: 20rem
.valid-date
	bottom: 6px
	p
		font-size: 7px
.dashbaord-certficate-page
	.w70px
		width: 85px
.certificate-area-dashboard
	.fs0-5rem
		font-size: 0.37rem
.dropdown-add-group
	position: relative
	height: 80px
	.dropdown-content
		width: 100%
		max-height: 255px
		position: absolute
		top: 58px
		overflow-y: scroll
		overflow-x: hidden
		background-color: #fff
		border-bottom: 2px solid #4a67ff
		z-index: 1
		padding: 4px 8px
		box-sizing: border-box
		.item
			border-bottom: 1px solid #00000020
			border-radius: 5px
			margin: 3px 0 10px 0
			padding: 10px 15px
			transition: all .3s ease-in-out
			width: 100%
			&:hover
				box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
			h4
				font-size: 10px
			.progress-box-horizantal
				width: 110px
				label
					display: block
				.progress
					height: 1rem
					font-size: .75rem
					border-radius: 25px
					box-shadow: #000
					color: #fff
					background-color: #0d6efd
					transition: width .6s ease
					height: 13px
					font-size: .55rem
					background-color: #d6d6d6
					border-radius: 25px
					display: flex
					overflow: hidden
					.progress-bar
						margin: 0
						color: #fff
						text-align: center
						white-space: nowrap
						flex-direction: column
						justify-content: center
						display: flex
						overflow: hidden
						transition: var(--bs-progress-bar-transition)
					&.red
						.progress-bar
							background-color: #ff3d3d
					&.yellow
						.progress-bar
							background-color: yellow
					&.orange
						.progress-bar
							background-color: #fcb22b
					&.green
						.progress-bar
							background-color: #5ee15e
	&.dropdown
		position: relative
		#dropdown-basic
			width: 100%
			text-align: left
			text-transform: capitalize
		.dropdown-content
			&.dropdown-menu
				display: none
				.dropdown-item
					display: block
					width: 96%
		&.show
			.dropdown-content
				&.dropdown-menu
					display: block
					z-index: 3
.mt0-3rem
	margin-top: .3rem
.back-btn
	top: 28px
	left: -500px
	.icon
		top: 3px
		margin-right: .3rem
		svg
			width: 15px
			fill: #4a67ff
			transition: all 0.3s ease-in-out
	&:hover
		.icon
			svg
				fill: #fc7785
.searchWrapper
	display: flex
	align-items: center
	width: 300px
	border: none !important
	position: relative
.advisor-select
	position: relative
	&:before
		contact: ""
		position: absolute
		right: 0
		top: 0
		width: 20px
		height: 20px
		background-image: url(../images/icons/arrow.svg)
		background-size: 100%
		background-repeat: no-repeat
.company-detail-box
	.company-detail
		.percentage-area
			.input-area
				width: 100%
				display: inline-block
				input
					font-size: 10px
					width: 100%
					-webkit-appearance: none
				input::before
					position: absolute
					top: 0
					content:"€"
.orange
	color: #fcb22b
.fs0-50rem
	font-size: .50rem
.bottom-company-area
	max-height: 100vh
	overflow-y: scroll
	padding: 0 5px
.mr0-2rem
	margin-right: 0.2rem
.dropdown-company-dahboard
	position: relative
	transition: all 0.5s ease-in-out
	.dropdown-content
		position: relative !important
		width: 90%
		margin: 0 auto
		transition: all 0.5s ease-in-out
		.certificate-area-dashboard
			zoom: 0.7
			margin-top: 10px
	.MuiFormControl-root
		width: 105px
		margin-left: 19px
.advisor-modal 
	.user-advisor-modal-icon
		width: 54px
		height: 50px
.advisor-modal-form
	zoom: 0.7
	.pr
		margin-bottom: 20px
		&.checkbox
			margin-bottom: 0
.form-group 
	.img-upload-area
		position: relative
		display: flex
		flex-wrap: wrap
		width: 100%
		justify-content: center
		.img-box
			position: relative
			display: flex
			align-items: center
			justify-content: center
			border-radius: 50%
			img
				object-fit: revert
				border-radius: 50%
				padding: 0
				border: 2px solid #15D757
				width: 98px
				height: 98px
			.input-file
				cursor: pointer
				background: none
				border: none
				position: absolute
				bottom: 1px
				left: 85px
				width: 35px
				height: 35px
				z-index: 2
				input 
					z-index: 99
					cursor: pointer
					width: 35px
					height: 35px
					display: block
					position: relative
					top: -4px
					left: -2px
					opacity: 0 !important
				.add-btn
					cursor: pointer
					position: absolute
					top: 7px
					left: -12px
					text-align: center
					border-radius: 50%
					width: 23px
					height: 23px 
					padding: 5px
					box-shadow: 0 0 5px rgba(0 0 0 / 20%)
					line-height: 1.8rem
					background-color: #fff
					svg
						opacity: .9
						fill: #748AFF
						width: 17px
.upload-pic-area
	#profile
		position: absolute
		left: 0
		height: 82px
		top: -2px
		width: 100%
		opacity: 0
		cursor: pointer
		z-index: 99
	label
		display: block
		width: 100%
.report-btn-r
	z-index: 1
	position: absolute
	bottom: -9px
	right: -6px
.label-checkbox
	position: relative
	.tooltiparea
		position: absolute
		background-color: #fff
		padding: 10px
		width: 300px
		z-index: 2
		box-shadow: 0 0 5px rgba(0 0 0 / 20%)
		border-radius: 7px
		top: 39px
		opacity: 0
		visible: hidden
	&:hover
		.tooltiparea
			opacity: 1
			visible: visible
.list-file-manger
	display: flex
	justify-content: space-between
	align-items: center
	position: relative
	list-style: none
	margin: 0 0 20px
	padding: 0
	li
		position: relative
		padding-left: 9px
		font-size: 0.75rem
		&:before
			content: ""
			position: absolute
			left: -9px
			top: 6px
			width: 11px
			height: 11px
			
			border-radius: 50%
		&.blue-list
			&:before
				background-color: #0055aa
		&.lightblue-list
			&:before
				background-color: #5d9afc
		&.green-list
			&:before
				background-color: #40dda1
		&.dropbox-list
			&:before
				background: #0160ff
		&.gdrive-list
			&:before
				background: #ffbd19
.table-permission
	width: 100%
	overflow-x: auto
	.table-userPermissions
		width: 100%
		background-color: #f0f2fd50
		border: 1px solid #f0f2fd
		border-radius: 30px
		padding: 2rem
		margin-top: 1rem
		thead
			tr
				th
					background-color: transparent !important
					font-size: 18px
					color: #151313
					border-bottom: 1px solid #B5BFDE
					position: sticky
					position: -webkit-sticky
					top: 0
					text-align: left
					z-index: 1
					&:first-child
						position: sticky
						position: -webkit-sticky
						left: 0
					&.user-area
						text-align: left
		tbody
			tr
				td
					&:nth-child(1)
						text-align: left
						position: sticky
						position: -webkit-sticky
						left: 0
						z-index: 2
					.checkbox
						&:before
							background-color: #e9ebf4
						&:after
							background-color: #e9ebf4
						input
							opacity: 0
							&:checked
								opacity: 1
						&.active
							input
								&:checked
									opacity: 1
		&.table-clientPermissions
			thead
				tbody
					tr
						td
							input
								background-color: #fff
								opacity: 1
							.dropdowns-advisor-permission
								.dropdown-add-group.dropdown
									.dropdown-item
										.deta-dropdown-company
											.checkbox
												&:before
													background: #fff !important
												&:after
													background: #fff !important
.table-permission 
	.table-userPermissions 
		thead 
			tr 
				th.user-area 
					span
						font-size: 12px
.table-clientPermissions 
	margin: 0 auto
	td, th
		width: auto !important
		min-width: 200px !important
	.select-advisor
		font-size: 3px
		width: auto
		margin-left: 10px
		.MuiSelect-select
			padding: 14px 24px
		fieldset
			border: none !important
.MuiList-root
	.MuiButtonBase-root
		font-size: 13px
.disable-advisor-checkbox
	span
		svg
			opacity: 0.4 !important
			color: #fc7785 !important
			margin-right: 10px !important
.select-advisor-list
	border-bottom: 1px solid #ccc !important
	padding-bottom: 8px !important
	margin-bottom: 8px !important
	&:last-child
		margin-bottom: 0 !important
		padding-bottom: 0 !important
		border-bottom: none !important
	span
		padding: 0
		font-size: 14px
		svg
			color: #fc7785
			margin-right: 10px
		&.Mui-checked
			svg
				color: #fc7785
	.MuiListItemText-root
		font-size: 13px
.close-table-prem 
	width: 11px
	height: 11px
	top: -3px
	right: -11px
	background-color: #fc7785
	border-radius: 50%
	padding: 1px
.expand-button
	background-color: #0000
	width: 28px
	font-size: 1rem
	padding: 0
	margin-left: -20px
.sub-folders
	text-align: left
	margin: 5px 7px
	position: relative
	box-shadow: 0 0 5px #0003
	border-radius: 5px
	span
		font-size: 0.75rem
		display: block
		width: 80%
		margin: 5px 0px
	.date
		color: #000 !important
		font-size: .5rem
.help-icon
	padding: 0
	margin: 0
	background-color: transparent
	top: -3px
	right: 0
	svg
		width: 1.5rem
.progress-box-vertical
	position: relative
	margin-left: 0
.progress
	background-color: #e9ecef
	border-radius: 0.25rem
	height: 0.8rem
	font-size: .75rem
	display: flex
	overflow: visible
	width: 70px

	.progress-bar
		display: flex
		justify-content: end
		overflow: visible
		color: #fff
		white-space: nowrap
		animation-duration: 3s
		animation-name: width
		background-image: linear-gradient(to right, #ff0000, #ff0000, #f26807, #f26807, #29fc00, #29fc00)
		background-size: 6em 1.95em
		background-repeat: no-repeat
		margin: 0
		span
			&.precentage-block
				
				color: #000
				font-size: 9px
				margin-right: -8px
				i
					background-color: #4a67ff
					border-radius: 5px
					font-size: 7px
					color: #fff
					padding: 2px 5px
					font-style: normal
@keyframes width
	0%, 100%
		transition-timing-function: cubic-bezier(1, 0, 0.45, 0.85)
	0%
		width: 0
	33%
		width: 23%
	66%
		width: 66%
	100%
		width: 100%
.arrow-right
	color: #222
	white-space: initial
	background-color: #dde2f2
	border-radius: 36px
	justify-content: center
	align-items: center
	width: 115px
	margin-top: -18px
	padding: 2px 4px
	display: flex
	position: absolute
	top: -53px
	left: auto
	padding: 11px 5px
	margin-left: -68px
	font-size: 11px
	zoom: 0.6
	&:before
		content: ""
		border-top: 8px solid #dde2f200
		border-bottom: 8px solid #dde2f200
		border-left: 15px solid #dde2f2
		width: 0
		height: 0
		position: absolute
		bottom: -13px
		right: 35px
		transform: rotate(90deg)
.dropdowns-advisor-permission
	position: relative
	width: 100%
	.dropdown-add-group.dropdown
		height: auto
		#dropdown-basic
			background-color: transparent
			font-size: 11px
			padding-left: 42px
			svg
				width: 15px
.all-reprots-area 
	.reports-logs
		z-index: 2
		font-size: 12px
		padding: 3px 5px
		border-radius: 5px
		left: 50%
		transform: translateX(-50%)
.company-audit-page
	.table-area-company-log
		margin: 0 auto
		width: 100%
		.tabs-btn
			display: flex
			justify-content: center
			margin: 25px 0
			button
				border-radius: 8px
				margin: 0 10px
				padding: 10px 35px
		.table-company-log
			margin: 0 auto
			thead
				tr
					th
						font-size: 17px
			tbody
				tr
					td
						font-size: 14px
						max-width: 300px
	.table-company-log
		tbody
			tr
				td
					.progress-box-vertical
						margin-left: 43px
						height: 80px
						&.style-two
							.text-center-area
								left: 1%
		thead 
			tr
				th
					.label-checkbox
						.checkbox
							&::before
								top: 19px
							&::after
								top: 17px
.portfolio-overall
	.chart
		perspective: 1000px
		perspective-origin: 50% 50%
		backface-visibility: visible
		width: 176px
		margin: 0 37px
		.bar
			position: relative
			height: 10em
			transform: rotateX(60deg) rotateY(0deg)
			transform-style: preserve-3d
			.face
				position: relative
				width: 100%
				height: 2em
				background-color: #cccccc40
				border-radius: 4px
				&.side-a,
				&.side-b
					width: 2em
			.side-a
				transform: rotateX(90deg) rotateY(-90deg) translateX(2em) translateY(1em) translateZ(1em)
			.side-b
				transform: rotateX(90deg) rotateY(-90deg) translateX(4em) translateY(1em) translateZ(-1em)
				position: absolute
				right: 0
			.side-0
				transform: rotateX(90deg) rotateY(0) translateX(0) translateY(1em) translateZ(-1em)
			.side-1
				transform: rotateX(90deg) rotateY(0) translateX(0) translateY(1em) translateZ(3em)
			.top
				transform: rotateX(0deg) rotateY(0) translateX(0em) translateY(4em) translateZ(2em)
			.floor
				box-shadow: 0 .1em 0.6em rgba(0,0,0,.3), .6em -0.5em 3em rgba(0,0,0,.3), 1em -1em 8em #fff
		.growing-bar
			height: 2em
			animation-duration: 3s
			animation-name: width
			background-image: linear-gradient(to right, #ff0000,  #f26807, #F26808, #F26807, #29fc00, #29fc00, #29fc00)
			background-size: 11em 1.95em
			position: relative
			border-radius: 3px
			.precentage-block
				position: absolute
				right: 0 
				top: 0
				transform: rotate(360deg)
				.arrow-right
					position: absolute
					bottom: auto
					left: auto
					right: -30px
					transform: rotateX(180deg)
					top: 80px
					font-size: 9px
					&:before
						top: 16px
						right: 50%
						border-bottom: 8px solid #0000
						border-left: 8px solid #0000
						border-top: 19px solid #bcd7ff99
						transform: translateX(-50%)
				i
					font-size: 11px
					display: block
					transform: rotateX(180deg)
					margin-top: 17px
					margin-right: 4px
		.text-center-area
			position: absolute
			bottom: 49px
			text-align: center
			width: 100%
			margin-bottom: 0px
			font-size: 11px
.new-report-btn.reports-btn-area
	width: 100%
	span
		padding: 2px 10px 5px 0
		width: 100%
		display: flex
		align-items: center
		justify-content: center
		i
			width: 20px
			height: 20px
			line-height: 20px
			position: absolute
			right: 0
			svg
				transform: rotate(180deg)
				transition: all 0.3s ease-in-out
	&.active
		span
			i
				svg
					transform: rotate(0deg)
					transition: all 0.3s ease-in-out
select.new-report-btn
	background-color: rgb(240, 240, 248)
	padding: 4px 10px

.company-audit-page 
	.report-start
		width: 80%
		margin: 10px auto 0
		.right-list
			list-style: none
			li
				display: flex
				justify-content: space-between
				font-weight: 600
				span 
					font-weight: 400
.benchmark-report-page
	padding: 0
	padding-bottom: 30px
	.container
		padding: 0
	.investmentcertificate-page
		.header-area
			height: 381px
		.certificate-area
			margin-bottom: 44px
			.portfolio-overall
				.progress
					width: 106px
					height: 1rem
					.progress-bar
						background-size: 9em 1.95em
						border-radius: 3px
				.progress-box-vertical
					.text-center-area
						bottom: -67px
						width: 100%
						text-align: center
						transform: translateX(-42%)
						font-size: 11px !important
.checklist-page
	padding: 40px 0
.h0-5rem
	height: 0.7rem
.w0-5rem
	width: 0.7rem
.edit-pencil
	top: 3px
table 
	thead
		z-index: 2
.checklist-page 
	.table-area-company-log 
		.table-company-log 
			tbody 
				tr 
					td
						min-width: 100px
						width: auto
			thead 
				tr 
					th
						min-width: 100px
						width: auto
.reprot-btn-dropdowns
	width: 265px
	.MuiFormControl-root
		margin: 0
		min-width: 95px
		font-size: 14px
		margin-top: -4px
		#demo-simple-select-label
			font-size: 14px
			top: -9px
			&.Mui-focused
				top: 0px	
		#demo-simple-select
			padding: 5px 25px 5px 15px
		label
			margin-top: -5px
			font-size: 14px
	.MuiSelect-select
		background: #e4effa !important
		border-bottom: 2px solid #1976d2 !important
		padding: 9px 32px 10px 11px !important
		border-radius: 5px
.dropdown-company-dahboard
	.MuiFormControl-root
		margin: 0
		min-width: 95px
		font-size: 14px
		margin: 11px 10px 0
		#demo-simple-select-label
			font-size: 14px
			top: -9px
			&.Mui-focused
				top: 0px	
		#demo-simple-select
			padding: 5px 25px 5px 15px
		label
			margin: -8px 0 0 0
			font-size: 14px
	.MuiSelect-select
		background: #e4effa !important
		border-bottom: 2px solid #1976d2 !important
		padding: 4px 32px 8px 11px !important
		border-radius: 5px !important
.company-audit-page 
	.dropdown-add-group
		width: 350px
		margin: 0 auto
.dropdown-company-dahboard 
	.certificate-area-dashboard 
		img
			width: 93px
.w180px
	width: 180px
.w60px
	width: 60px
.aic.advisor-index
	.table-company-repository
		width: 600px
.client-tab 
	.user-area
		width: 86%
.all-reprots-area
	&.extenstion-pack-btn-groups
		&.op20
			opacity: 1
			.btn-packges
				color: #002d6340
				background-color: #f0f0f880
				border-color: #ccc
				transition: all .3s ease-in-out
				.btn-packge
					pointer-events: none
					color: #002d6340
					background-color: #f0f0f880
					border-color: #ccc
					&:hover
						box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
.advisor-bg
	background-color: #fff6a680
	border-color: #fff6a680
.advisor-bg-lite
	background-color: #fffacd80
.investor-bg
	background-color: #AAFED680
	border-color: #AAFED680
.client-bg
	background-color: #fff

.advisor-profile
	color: #000
.investor-profile
	color: #000
	background-color: #fff
	border: 2px solid #7385BE80
.client-profile
	background-color: #c5cfff80
	color: #000

.investor-circle 
	.CircularProgressbar .CircularProgressbar-trail
		stroke: #f6f6f6 !important
.report-start
	&.invest-reports
		.progress-area-report
			.progress-box
				margin: 0 auto !important
				text-align: center
				&:before, &:after
					display: none
			.right-list
				li
					span
						width: auto
.report-start
	.progress-area-report
		&.progress-fit-report
			.progress-area
				.progress-box
					&:before, &:after
						display: none
.fitmetrics-reports.pr .matrics-box:nth-child(odd)
	background-color: rgb(240, 240, 248, 44%)
.fitmetrics-reports.pr .matrics-box
	padding: 5px 10px
.fitmetrics-reports.pr .matrics-box .box-text
	text-align: center
	border: 1px solid #00000020
	width: 190px
	font-size: 11px
	border-radius: 4px
	background-color: #fff
	box-shadow: 0 6px 12px -2px #32325d10, 0 3px 7px -3px #0000004d
	word-break: break-all
	padding: 0 7px
	box-sizing: border-box
.fitmetrics-reports.pr .matrics-box p
	width: 201px
	font-size: 12px
.invest-box-funds .group-inputs p b
	display: block
	font-size: 16px
.invest-box-funds .group-inputs p b .tooltip-area-company-settings
	position: relative
	top: 0
.invest-box-funds .group-inputs p b .tooltip-area-company-settings .icon img 
	width: 15px
.switch-box span.Mui-checked
	color: #40dda1
.switch-box span.Mui-checked+.MuiSwitch-track
	background-color: #40dda1
.funds-box
	.item-box
		width: 100%
		label
			margin-bottom: 7px
		.MuiInputBase-root
			&::before, &::after
				display: none
			.MuiSelect-select
				padding: 10px 27px 10px 15px
				background-color: #f0f0f8
				border-radius: 4px
.select-box .MuiInputBase-root
	min-width: 330px
.content-area 
	button
		min-width: 87px
		font-size: 11px
		display: block
		text-align: center
		margin: -7px auto -3px
		padding: 4px 10px
	.right-list
		text-align: right
		margin: 0
		padding: 0
		list-style: none
		position: relative
		li
			margin-bottom: 3px
			font-size: 14px
			font-weight: 700
			span
				width: 290px
				font-weight: 400
				display: inline-block
	.logo-box
		text-align: center
		margin-bottom: 20px
.bottomlist-area
	.list-box
		.list
			margin: 9px 0 0 0
			padding: 0
			list-style: none
			li
				margin-bottom: 5px
				position: relative
				span
					img
						width: 11px
				.tooltip-area-company-settings
					right: 33px
					top: 0
.sidebar-inveter
	height: 95.3vh
	position: relative
	.mta
		position: absolute
		bottom: 0
		width: 94%
.qustion-box 
	.tooltip-area-company-settings
		top: -28px
		right: -38px
	.rightanswer-text
		.df.jce
			top: -15px
			position: absolute
			right: -28px
.background-invest
	background-image: url(../images/bg-ai.jpg)
	background-size: 37%
	background-repeat: no-repeat
	background-position: center
.background-des
	background-image: url(../images/ai-text.png)
	background-size: 30%
	background-repeat: no-repeat
	background-position: center
	textarea
		background-color: transparent
.fitmetrics-reports.pr .matrics-box .icon
	text-align: center
	background-color: #fff
	border: 1px solid #00000020
	border-radius: 4px
	padding: 2px
	box-shadow: 0 6px 12px -2px #32325d10, 0 3px 7px -3px #0000004d
	width: 27px
	height: 22px
	box-sizing: border-box
.fitmetrics-reports.pr .matrics-box .icon svg
	width: 17px
	height: 17px
.fitmetrics-reports.pr .matrics-box.style-two .icon
	box-shadow: none
	padding: 0
	width: 27px
	border: none
	background-color: transparent
.fitmetrics-reports.pr .matrics-box.style-two .box-text
	border: none
	box-shadow: none
	background-color: transparent
.report-start.fitmetrics-reports .reports-table thead tr th, 
.report-start.fitmetrics-reports .reports-table tbody tr td
	min-width: auto !important
	width: 20% !important
	padding: 10px
	text-align: left
	background: none !important
	vertical-align: top
.report-start.fitmetrics-reports .reports-table tbody tr td
	background-color: #fff !important
	box-shadow: 0 6px 12px -2px #32325d20, 0 3px 7px -3px #0000004d
	padding: 10px 19px
	border-radius: 5px
	border-right: 2px solid #002d63
	border-top: 5px solid #002d63
.report-start.fitmetrics-reports .reports-table thead tr th
	text-align: center
	font-size: 12px
	background-color: #f0f0f8 !important
	border-radius: 12px
	transition: all .3s ease-in-out
	border: 1px solid #4cff88
.report-start.fitmetrics-reports .reports-table thead tr th:hover
	box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
.report-start.fitmetrics-reports .reports-table thead tr th img
	display: block
	margin: 0 auto
.report-start.fitmetrics-reports .reports-table tbody tr td ul
	padding: 0
.report-start.fitmetrics-reports .reports-table tbody tr td ul li, 
.report-start.fitmetrics-reports .reports-table tbody tr td ul p
	font-size: 11px
	margin-bottom: 13px
.report-start.fitmetrics-reports .reports-table tbody tr td ul ul
	margin-left: 15px
.report-start.fitmetrics-reports .reports-table tbody tr td ul p.border-box
	border: 1px solid #ccc
	padding: 5px
.gtcr-bespoked
	grid-template-columns: 31.33% 31.33% 31.33%
.btn-group.fit-btn-group
	.new-report-btn
		margin-bottom: 10px
		padding: 4px
		span
			font-size: 1.1rem
.progress-area ul.list-company-detail
	display: flex
	flex-wrap: wrap
	width: 100%
	padding: 0
	li
		display: flex
		width: 47%
		background-color: #fff
		box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
		border-radius: 5px
		padding: 11px
		box-sizing: border-box
		margin-left: 15px
		margin-bottom: 11px
		&.button-list
			background-color: transparent
			box-shadow: none
		p
			margin-right: 7px

.modal-bio .user-area
	display: flex
	align-items: center
.modal-bio .user-area .text-box
	margin-left: 16px
.investor-details-form .switch-box
	top: 15%
.investor-details-form
	.edit-btn
		transition: all .3s ease-in-out
		display: inline-block
		.edit-pencil
			cursor: pointer
			background-color: transparent
			border: none
			margin-left: 11px
			svg
				fill: #fc7785
				width: 15px
				height: 15px
				position: relative
				top: 2px
.sidebar-investor
	right: 0
	width: 78%
	left: auto !important
	.MuiBackdrop-root
		background-color: #fff
	.w60
		width: 90%
		span.w200px
			display: none
		.logo-report.w60
			margin: 0 auto
			width: 100%
			text-align: center
.list-fit
	list-style: none
.multiselect-invest
	fieldset
		border: none !important
.blur-disabled
	filter: blur(2.5px)
	pointer-events: none
	cursor: not-allowed
	user-select: none
.view-btn-fit
	i
		display: block
		color: #4a67ff
	span
		color: #4a67ff
	&:hover
		i
			display: block
			color: #fc7785
		span
			color: #fc7785
.mr16rem
	margin-right: 16rem
.dots-fit-report i
	font-size: 11px
	margin: 0 1px
.investor-dropdown
	.MuiInputBase-root
		width: 130px
		#investor-dropdown
			background: #e4edf7 !important
			border: none !important
			font-size: 13px !important
			padding: 7px 32px 7px 10px !important
		fieldset
			border: none !important
[aria-labelledby="investor-multiple-checkbox-label"]
	.MuiButtonBase-root
		.MuiTypography-root
			font-size: 12px
			color: #000
		.MuiButtonBase-root
			padding: 0
			margin-right: 9px
.tp5px
	top: 3px

.dropdown-company-dahboard .progress-area-report.progress-fit-report
	margin-top: -77px
.dropdown-company-dahboard .progress-area-report.progress-fit-report .progress-area .progress-box
	margin: 0 0px 12px 15px
.dropdown-company-dahboard .progress-area-report.progress-fit-report .progress-area .progress-box::before, 
.dropdown-company-dahboard .progress-area-report.progress-fit-report .progress-area .progress-box::after
	display: none
.advisor-index .MuiTabs-root
	position: relative
	z-index: 9
.dropdown-company-dahboard .progress-area-report.progress-fit-report .tooltip-area .icon img
	width: 14px
.dropdown-company-dahboard .progress-area-report.progress-fit-report .progress-area .tooltip-area .overlay-box::before
	display: none
.ReactTable .-pagination .-next .-btn, 
.ReactTable .-pagination .-previous .-btn
	border-radius: 5px
	background-color: #4a67ff
	color: #fff
	transition: all 0.3s ease-in-out
.ReactTable .-pagination .-next .-btn:hover,
.ReactTable .-pagination .-previous .-btn:hover
	background-color: #fc7785 !important
.funds-item.w33
	width: 32.33%
.content-area 
	.right-list 
		li 
			span 
				.group-inputs 
					p 
						.edit-btn 
							width: auto
							.edit-pencil
								min-width: auto
								margin: 0 0 0 9px
								padding: 0
.wordbreak
	word-break: break-all
.progress-fit-report 
	.progress-area 
		.progress-box
			margin: 0
.w80
	width: 80%
@media (min-width: 999px)
	.a4-fit-pdf
		width: 210mm
		height: 297mm
@media (max-width: 1600px)
	.report-page
		.sidebar-report
			.mah100
				&.mya
					&.oya
						&.df
							&.fdc
								&.jcsb
									max-height: 40vh
			.siedbar-dropdwon
				max-height:63vh
	.certificates-page
		.certificates-current
			.packge-select
				.w250px
					width: 100%
	.extenstion-pack-btn-groups
		.btn-packge
			.trophy-cup
				img
					width: 48px !important
	.sectionWrapper
		.group-inputs
			&.link-group
				p
					a
						width: 140px
@media (max-width: 1525px) 
@media (max-width: 1500px) 
	.our-thesis-sec
		.fs3-5rem
			font-size: 2.5rem !important
			margin-bottom: 2rem !important
			line-height: 2rem !important
		p
			line-height: 1.5rem !important
@media (max-width: 1450px)
	.report-page
			.sidebar-report
				.p0-5rem
					h3
						font-size: 14px
						line-height: 14px
					button
						font-size: 11px
				.mah100
					&.mya
						&.oya
							&.df
								&.fdc
									&.jcsb
										max-height: 50vh 
	.investmentcertificate-page
		.header-area
			background-size: 100% 100%
			height: 306px
		.certificate-area
			margin-top: -168px
	.certification-pricing-column
		right: 390px !important
	.extenstion-pack-btn-groups
		.btn-packge
			.ribbon
				right: -30px
				img
					width: 80px
				&.bronze, &.silver, &.gold
					img
						width: 46px
	.step-box
		zoom: 0.8
	#confirmDescriptionModal
		.fs1rem
			font-size: 11px
		.p3rem
			padding: 1.5rem
		.my1rem
			margin: 13px 0 0
			padding: 12px
	#welcome2MinuteTestModal
		.tac
			p
				font-size: 12px
		.p3rem
			padding: 2.5rem 1.5rem 1.5rem
		.my1rem
			margin: 13px 0 0
			padding: 12px
	#dataRepositoryModal
		.tac
			p
				font-size: 12px
			li
				font-size: 12px
		.p3rem
			padding: 2.5rem 1.5rem 1.5rem
		.my1rem
			margin: 13px 0 0
			padding: 12px
	.slider-container 
		.item
			min-width: 209px
			max-width: 209px
	.table-of-content
		.list-table
			li
				&:nth-child(1)
					&:before
						width: 74%
				&:nth-child(2)
					&:before
						width: 74%
				&:nth-child(3)
					&:before
						width: 74%
				&:nth-child(4)
					&:before
						width: 60%
		&.style-two
			.list-table
				li
					&:nth-child(1)
						&:before
							width: 70%
					&:nth-child(2)
						&:before
							width: 70%
					&:nth-child(3)
						&:before
							width: 70%
	.extenstion-pack-btn-groups
		.btn-packge
			.trophy-cup
				img
					width: 48px !important
	.sectionWrapper
		.group-inputs
			&.link-group
				p
					a
						width: 120px
	.benchmark-report-page
		.investmentcertificate-page
			.header-area
				height: 165px
			.certificate-area
				margin-bottom: 24px
				margin-top: -60px
	.investor-leads-tooltip 
		.tooltip-area
			&.mr16rem
				margin-right: 12rem
	.right-slide-box 
		.btn-area
			button
				font-size: 15px
	.btn-plus-sidebar
		font-size: 2rem !important
		padding: 0 !important
		right: 10px
		top: 146px !important
@media (max-width: 1420px)
@media (max-width: 1400px)
	.admin-table .rt-th .rt-resizable-header-content, .admin-table .rt-tr .rt-td
		font-size: 14px
	.advisor-index .df.fdc.aic.bss.bw2px.bo5.br10px.p2rem
		padding: 1rem
@media (max-width: 1390px)
	.admin-table
		width: 1151px
@media (max-width: 1366px)
	.report-page
		.sidebar-report
			.p0-5rem
				button
					font-size: 11px
					padding: 5px 8px
			.mah100
				&.mya
					&.oya
						&.df
							&.fdc
								&.jcsb
									max-height: 37vh
			.siedbar-dropdwon
				max-height: 60vh
	.table-of-content
		.list-table
			li
				&:nth-child(1)
					&:before
						width: 70%
				&:nth-child(2)
					&:before
						width: 70%
				&:nth-child(3)
					&:before
						width: 70%
				&:nth-child(4)
					&:before
						width: 55%
		&.style-two
			.list-table
				li
					&:nth-child(1)
						&:before
							width: 66%
					&:nth-child(2)
						&:before
							width: 66%
					&:nth-child(3)
						&:before
							width: 66%
	.extenstion-pack-btn-groups
		.btn-packge
			.trophy-cup
				top: 68px
				img
					width: 45px !important
	.sectionWrapper
		.group-inputs
			&.link-group
				p
					a
						width: 100px
	.back-btn
		top: 9px
		left: -266px
	.certificate-area-dashboard 
		.w250px
			width: 230px
	.right-slide-box 
		.btn-area
			button
				font-size: 14px
	.additional-sections-button
		.btn-packge
			font-size: .55rem !important
	.btn-plus-sidebar
		top: 134px !important
	.user-info-sidebar
		padding: .4rem .5rem
		.df.jcc
			.w3rem.h3rem
				width: 2rem
				height: 2rem
			.w5rem.h2rem
				width: 4rem
				height: 1.5rem
@media (max-width: 1340px)
	.admin-table
		width: 1103px
@media (max-width: 1320px)
@media (max-width: 1300px)
	.report-page
		.sidebar-report
			.mah100
				&.mya
					&.oya
						&.df
							&.fdc
								&.jcsb
									max-height: 30vh
	.certification-pricing-column
		right: 280px !important
	.sectionWrapper
		.group-inputs
			&.link-group
				p
					a
						width: 80px
	.admin-table
		width: 1083px
		.rt-th .rt-resizable-header-content, .rt-tr .rt-td
			font-size: 12px
	.login-page 
		.login-container
			width: 72%
			padding: 170px 0 70px 146px
	.contact-us-page 
		.df.w100 
			.w60.fs2-5rem
				font-size: 2rem
				padding-left: 9rem !important
			.w35
				width: 42%
@media (max-width: 1280px)
	.admin-table
		width: 1053px
@media (max-width: 1240px)
	.admin-table
		width: 1013px
@media (max-width: 1200px)
	.certification-pricing-column
		right: 165px !important
	.slider-container 
		.item
			min-width: 220px
			max-width: 220px
	.table-of-content
		.list-table
			li
				&:nth-child(1)
					&:before
						width: 65%
				&:nth-child(2)
					&:before
						width: 65%
				&:nth-child(3)
					&:before
						width: 65%
				&:nth-child(4)
					&:before
						width: 50%
		&.style-two
			.list-table
				li
					&:nth-child(1)
						&:before
							width: 61%
					&:nth-child(2)
						&:before
							width: 61%
					&:nth-child(3)
						&:before
							width: 61%
	.packge-select 
		p
			font-size: 9px
	.back-btn
		left: -223px
	.right-slide-box 
		.btn-area
			button
				min-height: 57px
	.admin-table
		width: 967px
@media (max-width: 1199px)
	.hero-saf
		.w45
			width: 100% !important
	.dropdown-company-dahboard
		.dropdown-content 
			.certificate-area-dashboard
				zoom: 0.4
	.dropdown-company-dahboard 
		.w10rem
			width: 7rem
	#menu
		background: linear-gradient(45deg, #002d63, #4a67ff)
		width: 230px
		height: 100vh
		z-index: 5
		right: 0
		&.r0
			right: -100%
			&.showing
				right: 0
	#menu:not(.showing)
		right:-100%
		&.showing
			right:0
	.hero-saf 
		.ma
			width: 100%
@media (max-width: 1034px)
	.table-of-content
		.list-table
			li
				&:nth-child(1)
					&:before
						width: 55%
				&:nth-child(2)
					&:before
						width: 55%
				&:nth-child(3)
					&:before
						width: 55%
				&:nth-child(4)
					&:before
						width: 40%
		&.style-two
			.list-table
				li
					&:nth-child(1)
						&:before
							width: 51%
					&:nth-child(2)
						&:before
							width: 51%
					&:nth-child(3)
						&:before
							width: 51%
	.links-area-social
		flex-wrap: wrap
		.group-inputs
			&.link-group
				.edit-text
					p
						width: 100%
@media (max-width: 991px)
	.certification-pricing-column
		right: auto !important
		position: relative
		top: 0
	.certificates-page
		.heading-area
			background-size: 100% 114%
		.certificates-current
			.packge-select
				flex-wrap: wrap
				justify-content: space-between
				.audit-pakcges
					width: 26%
					margin: 0
		.badges-sec
			.badges-area
					flex-wrap: wrap
					.left-upgrade-button
						text-align: center
						margin-top: 0
						margin-right: 0
						position: relative
						margin-bottom: 20px
		.data-check-sec
			.gtcr3
				grid-template-columns: repeat(2, 1fr)
	.investmentcertificate-page 
		.certificate-area
			.left-area
				.signure-area
					.date-area
						&.singature-area
							margin-top: 0
	.table-of-content
		width: 91%
	.progress-dashboard
		.progress-box
			margin-top: 10px
			.progress-bar
				width: 40px !important
				height: 40px !important
			.text-center-area
				font-size: 12px !important
	.investor-modal-admin .jcsb .ml1rem.w100
		margin: 0
	.investor-modal-admin .jcsb .mr1rem
		margin-right: 0 !important
		margin-bottom: 20px
@media (max-width: 630px)
	.certificates-page
		.certificates-current
			.packge-select
				flex-wrap: wrap
				justify-content: center
				.audit-pakcges
					width: 50%
					margin: 0
		.badges-sec
			.gtcr3
				grid-template-columns: repeat(2, 1fr)
		.data-check-sec
			.gtcr3
				grid-template-columns: repeat(1, 1fr)
	.footer-sec
		h2
			img
				width: 100%
		.certificates-current
			.gtcr3
				grid-template-columns: repeat(2, 1fr)
		.upgrade-btn
			h2
				img
					width: 57%
@media (max-width: 490px)
	.table-permission 
		.table-userPermissions 
			tbody 
				tr 
					td
						&:first-child
							position: relative
	.footer-sec
		h2
			img
				width: 100%
		.certificates-current
			.gtcr3
				grid-template-columns: repeat(1, 1fr)
		.upgrade-btn
			h2
				img
					width: 57%
		.certificates-current
			.packge-select
				.audit-pakcges
					width: 86%
	.certificates-page
		.badges-sec
			.gtcr3
				grid-template-columns: repeat(1, 1fr)
			.certificates-current
				.packge-select
					.audit-pakcges
						width: 86%
		.heading-area
			p
				font-size: 11px
			h2
				font-size: 1rem
		.progress-sec
			.progress-list
				flex-wrap: wrap
	.table-of-content
		width: 80%
		.list-table
			li
				&:nth-child(1)
					&:before
						width: 45%
				&:nth-child(2)
					&:before
						width: 45%
				&:nth-child(3)
					&:before
						width: 45%
				&:nth-child(4)
					&:before
						width: 25%
		&.style-two
			.list-table
				li
					&:nth-child(1)
						&:before
							width: 41%
					&:nth-child(2)
						&:before
							width: 41%
					&:nth-child(3)
						&:before
							width: 41%
	.investmentcertificate-page 
		.certificate-area 
			.right-area
				margin: 0 auto 23px

@media print
	.text-gradient
		background: none !important
		background-clip: unset !important
		text-fill-color: #fff !important
		color:#fff !important
	.minh100vh-report
		height: 100vh !important	
	.mih100vh
		height: 100vh !important
	.new-report
		&.p3rem
			padding: 0 !important
		.w100
			width: auto !important
	.report-start
		h2
			color: #fff !important
	.a4-fit-pdf
		width: 210mm !important
		height: 280mm !important
.dropdown-section-header 
	padding: 5px
	zoom: .9
	border-bottom: 1px solid #00000020
	border-radius: 5px
	transition: all .3s ease-in-out
	&:hover
		box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
.vh
	visibility: hidden

.filing-assistant-pos
	grid-column-end: -1

.badge
	border-radius: 0.375rem
	color: #fff
	display: inline-block
	font-size: 0.75em
	font-weight: 700
	line-height: 1
	padding: 0.35em 0.65rem
	text-align: center
	vertical-align: initial
	white-space: nowrap
	margin-left: 0.5rem
	background-color: #dc3545

.file-import-Summary
	table tr:nth-child(2n), .tableRow:nth-child(2n)
		background: #E1E1F155
	table tr > *:nth-child(2n), .tableRow > *:nth-child(2n)
		background: #E1E1F155
	table
		border-spacing: 0px
	table th
		font-weight: 600
	table th, table td
		padding: 0.5rem 1rem
		outline: 0
		min-width: 200px
		width: 20%
	table thead th
		font-size: 1.5rem
		border-bottom: 2px solid #002d63
		background: #fff !important
		text-align: center
	table td
		text-align: center
	table tbody th
		background: #fff
		border-right: 2px solid #002d63

.bbn
	border-bottom: none !important

.fis-th
	font-size: 1.5rem
	border-bottom: 2px solid #002d63
	background: #fff !important
	text-align: center

.fis-th1
	font-size: 1.5rem
	border-bottom: 2px solid #002d63
	background: #fff !important

.fis-th-action
	width: 10%
	min-width: 170px !important

.fis-td
	padding: 0.5rem 3rem
	outline: 0
	min-width: 200px
	width: 20%
	background: #fff !important

.fis-td-action
	min-width: 0px !important
	width: 10% !important

.fis-tr
	border-bottom: 1px solid #002d63

.bcc
	border-collapse: collapse

.frs-th
	min-width: unset !important

.frs-td
	min-width: unset !important
	width: unset !important

.frs-table td:nth-child(odd)
	background: #E1E1F155 !important

.frs-table tbody tr
	&:hover
		background-color: #E1E1F1

.uf-td
	min-width: unset !important
	width: unset !important
	background :#fff !important

.uf-table tbody tr
	background: #fff !important

.uf-table tbody tr
	&:hover
		background-color: #E1E1F1 !important

.uf-table-tr
	border-bottom: 1px solid #002d63
	background :#fff !important

.uf-th
	font-size: 1.5rem
	border-bottom: 2px solid #002d63
	background: #fff !important
	text-align: center
	min-width: unset !important

.fg1
	flex-grow : 1
.rv
	resize: vertical

.filing-assistant-info
	margin-top: -24px
	margin-right: -10px
.repository-step
	border-radius: 10px
	border-width: 3px
	border-color: #4A67FF
	padding-left: 35px
	padding-right: 35px
	padding-top: 5px
	padding-bottom: 5px
	border-style: solid
	width: 80%
	display: flex
	align-items: center
	min-height: 50px

.repository-manual
	height: 514px !important

.question-button
	width: 21px
	height: auto
.tooltip-area-custom
	position: absolute
	top: -6px
	right: -8px
	.icon
		position: relative
		display: inline-block
		transition: all 0.3s ease-in-out
		img
			width: 18px
			height: auto
		.overlay-box
			position: absolute
			right: 34px
			min-width: 350px
			background-color: #fff
			z-index: 999
			top: 50%
			box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
			padding: 16px
			border-radius: 8px
			text-align: left
			transition: all 0.3s ease-in-out
			display: none
			transform: translateY(-50%)
			p
				font-size: 13px
				font-style: normal
				text-transform: none
			.bottom-text
				p
					font-size: 11px
			.price
				font-size: 19px
				text-align: center
				margin-top: 13px
				font-style: normal
			button
				z-index: 999
	&:hover
		.overlay-box
			display: block
			transition: all 0.3s ease-in-out
.additional-sections-button
	.btn-packge
		margin: 8px 0px 2px 0px
		position: relative
		border-radius: 5px
		display: flex
		flex-wrap: wrap
		align-items: center
		font-size:0.75rem
		transition: all 0.3s ease-in-out
		button
			width: 100%
			margin-top: 3px
			transition: all 0.3s ease-in-out
			transform: scale(0.6)
		&:hover
			box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
		.tooltip-area
			position: absolute
			top: -6px
			right: -8px
			.icon
				position: relative
				display: inline-block
				transition: all 0.3s ease-in-out
				img
					width: 21px
					height: auto
				.overlay-box
					position: absolute
					right: 34px
					min-width: 350px
					background-color: #fff
					z-index: 999
					top: 50%
					box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
					padding: 16px
					border-radius: 8px
					text-align: left
					transition: all 0.3s ease-in-out
					display: none
					transform: translateY(-50%)
					&:before
						content: ""
						position: absolute
						right: -14px
						width: 0px
						height: 0px
						border-style: solid
						border-width: 15px 0 15px 15.9px
						border-color: transparent transparent transparent #fff
						transform: translateY(-50%)
						top: 49%
					p
						font-size: 13px
						margin-bottom: 5px
						font-weight: 400
						font-style: normal
						text-transform: none
					.bottom-text
						p
							font-size: 11px
					.price
						font-size: 19px
						text-align: center
						margin-top: 13px
						font-style: normal
					button
						z-index: 999
			&:hover
				.overlay-box
					display: block
					transition: all 0.3s ease-in-out
			&.style-two
				top: -25px
				right: -15px
		
.additional-sections-info
	.tooltip-area
		position: absolute
		top: -6px
		right: -8px
		.icon
			position: relative
			display: inline-block
			transition: all 0.3s ease-in-out
			img
				width: 21px
				height: auto
			.overlay-box
				position: absolute
				right: 34px
				min-width: 350px
				background-color: #fff
				z-index: 999
				top: 50%
				box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
				padding: 16px
				border-radius: 8px
				text-align: left
				transition: all 0.3s ease-in-out
				display: none
				transform: translateY(-50%)
				&:before
					content: ""
					position: absolute
					right: -14px
					width: 0px
					height: 0px
					border-style: solid
					border-width: 15px 0 15px 15.9px
					border-color: transparent transparent transparent #fff
					transform: translateY(-50%)
					top: 49%
				p
					font-size: 13px
					margin-bottom: 5px
					font-weight: 400
					font-style: normal
					text-transform: none
				.bottom-text
					p
						font-size: 11px
				.price
					font-size: 19px
					text-align: center
					margin-top: 13px
					font-style: normal
				button
					z-index: 999
		&:hover
			.overlay-box
				display: block
				transition: all 0.3s ease-in-out
		&.style-two
			top: -25px
			right: -15px

.menuHover
	.btn-filing-assistant
		.tooltip-area
			position: absolute
			top: -6px
			right: -8px
			.icon
				position: relative
				display: inline-block
				transition: all 0.3s ease-in-out
				img
					width: 21px
					height: auto
				.overlay-box
					position: absolute
					left: 34px
					min-width: 350px
					background-color: #fff
					z-index: 999
					top: 50%
					box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
					padding: 16px
					border-radius: 8px
					text-align: left
					transition: all 0.3s ease-in-out
					display: none
					transform: translateY(-50%)
					&:before
						content: ""
						position: absolute
						left: -14px
						width: 0px
						height: 0px
						border: 15px solid transparent
						border-right: 15.9px solid #fff
						border-left-width: 0px
						transform: translateY(-50%)
						top: 49%
					p
						color: #002D63
						font-size: 13px
						margin-bottom: 5px
						font-weight: 400
						font-style: normal
						text-transform: none
					button
						z-index: 999
			&:hover
				.overlay-box
					display: block
					transition: all 0.3s ease-in-out
			&.style-two
				top: -25px
				right: -15px

.investor-leads-tooltip
	.tooltip-area
			position: absolute
			.icon
				position: relative
				display: inline-block
				transition: all 0.3s ease-in-out
				img
					width: 18px
					height: auto
				.overlay-box
					position: absolute
					min-width: 300px
					left: 34px
					background-color: #fff
					z-index: 999
					box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
					padding: 16px
					border-radius: 8px
					text-align: left
					transition: all 0.3s ease-in-out
					display: none
					transform: translateY(-50%)
					&:before
						content: ""
						position: absolute
						left: -14px
						width: 0px
						height: 0px
						border: 15px solid transparent
						transform: translateY(-50%)
						top: 49%
					p
						color: #002D63
						font-size: 13px
						margin-bottom: 5px
						font-weight: 400
						font-style: normal
						text-transform: none
			&:hover
				.overlay-box
					display: block
					transition: all 0.3s ease-in-out

.investor-leads-tooltip
	.unselectable
		color: #bfbfbf
		cursor: not-allowed

.inner-container
	padding: 15px 370px
.work-container
	padding: 60px 0 !important
	max-width: 1160px !important
	.fs2-125rem
		font-size: 50px
	.work-card-box
		.fs1-25rem
			font-size: 20px
.client-review-container
	padding: 125px 221px
.video-container p,
.pricing-container p.fs2-125rem,
.not-sure-container p.fs2-125rem
	width: 790px
	font-size: 50px
	line-height: 54.2px !important
	margin: 0 auto
.lplcbs
	box-shadow: 6px 13px 13px rgba(7, 7, 7, 0.15)
.how-its-work-container
	padding:4ss0px 120px
.video-container
	padding: 100px 230px
.pricing-container
	padding: 120px 150px 120px 150px
.register-container
	width: 45%, 
	padding: 70px 0px 70px  170px
.register-container-not-sure
	width: 60%
	padding: 100px 170px 70px 170px
	box-sizing: border-box
.register-page-container
	width: 65%, 
	padding: 80px 0px 5px  100px
.login-container
	width: 50%, 
	padding: 170px 0px 70px  170px
.login-disabled:hover
	background-color: #c5c5c5 !important
.lpins
	border: 1px solid #00000057
.lpbb
	border-bottom :8px solid #4A67FF
.not-sure-container	
	padding: 100px 266px
.investorLookup-container
	padding: 0px 250px 100px
.bsflp
	box-shadow:0px 4px 54px 0px #07070726
.fsi
	font-style: italic
.main-banner-container
	padding : 0 100px 0 150px
.lead-report-switch
	.css-1xvpzln-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
		color: #50ee00
.how-it-works
	list-style-type: disclosure-closed
	li::marker
		color: #fc7785

.shadowPaperCrasouel
	padding: 0 !important
.custom-list li .df img
	width: 22px
.pricing-container
	.pricing-box
		width: 22%
		margin-left: 15px
		margin-right: 15px
		&.mr5rem
			margin-right: 150px
.comapnies-page-font 
	.pricing-container 
		.pricing-box
			&.mr5rem
				margin-right: 15px
.pricing-title 
	.left-text-for
		padding-left: 3.8rem
	.right-text-for
		padding-right: 11rem
.vcs-page-font, .pes-page-font
	.pricing-title 
		.right-text-for
			padding-right: 6rem
.angelsnetwork-page-font, .angels-page-font
	.pricing-title 
		.right-text-for
			padding-right: 8rem
.carousel.carousel-slider
	.df
		.h60
			width: 190px !important
.how-its-work-circle
	width: 43px !important
	height: 43px !important
.pricing-container .pricing-box .df.fdc h1
	line-height: 1.2rem !important
	margin-bottom: 5px
.work-card-box
	width: 30%
	margin: 0 15px
.poppins-font-style .inner-container .shadowContainer
	padding: 33px
	border-radius: 40px
	zoom: 0.8
	letter-spacing: 1px
.poppins-font-style .inner-container.style-two .shadowContainer
	border-radius: 25px
.features-icon svg
	width: 60px
.comapnies-page-font #homeMainBanner .container:nth-child(3) .w60
	margin-top: 4rem
.comapnies-page-font #homeMainBanner .container:nth-child(4) .w40 img
	width: 111%
.comapnies-page-font #homeMainBanner .container:nth-child(4) .w60
	margin-top: 4rem
.for-you-text 
	padding-left: 23%
	padding-right: 32%
	padding-bottom: 23px
.empty-text
	max-width: 350px
	text-align: center
	font-size: large
button.carousel-button svg
	width: 200px
.quote-area p
	font-size: 11px
	font-weight: 300
	line-height: 1.7
	letter-spacing: 1px
.quote-icon img
	width: 50px
	height: 50px
	margin-right: 1rem
.glow-on-hover.glowing
	position: relative
	background-repeat: no-repeat
	background-position: -120px -120px, 0 0
	background-image: -webkit-linear-gradient(top left, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 37%, rgba(255, 255, 255, 0.8) 45%, rgba(255, 255, 255, 0.0) 50%)
	background-image: -moz-linear-gradient(0 0, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 37%, rgba(255, 255, 255, 0.8) 45%, rgba(255, 255, 255, 0.0) 50%)
	background-image: -o-linear-gradient(0 0, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 37%, rgba(255, 255, 255, 0.8) 45%, rgba(255, 255, 255, 0.0) 50%)
	background-image: linear-gradient(0 0, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 37%, rgba(255, 255, 255, 0.8) 45%, rgba(255, 255, 255, 0.0) 50%)
	-moz-background-size: 250% 250%, 100% 100%
	background-size: 250% 250%, 100% 100%
	-webkit-transition: background-position 0s ease
	-moz-transition: background-position 0s ease
	-o-transition: background-position 0s ease
	transition: background-position 0s ease
	&:hover
		background-position: 0 0, 0 0
		-webkit-transition-duration: 0.5s
		-moz-transition-duration: 0.5s
		transition-duration: 0.5s
#homeMainBanner .container
	max-width: 1400px !important
#homeMainBanner .container:nth-child(2) .w60 .fs1-5rem,
#homeMainBanner .container:nth-child(3) .w60 .fs1-5rem,
#homeMainBanner .container:nth-child(4) .w60 .fs1-5rem,
#homeMainBanner .container:nth-child(6) .w60 .fs1-5rem,
#homeMainBanner .container:nth-child(11) .w60 .fs1-5rem,
#homeMainBanner .container:nth-child(12) .w60 .fs1-5rem,
.accounts-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem,
.lawyers-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem,
.vcs-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem,
.vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem,
.vcs-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem,
.pes-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem,
.pes-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem,
.pes-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem,
.angelsnetwork-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem,
.angels-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem,
.angels-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem,
.angels-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem,
.angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem
	width: 300px
#homeMainBanner .container:nth-child(3) .w60 .fs1-5rem
	width: 400px
#homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, 
#homeMainBanner .container:nth-child(7) .w60 .fs1-5rem,
#homeMainBanner .container:nth-child(10) .w60 .fs1-5rem,
.vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem,
.pes-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem,
.pes-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem,
.angelsnetwork-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem
	width: 176px
#homeMainBanner .container:nth-child(8) .w60 .fs1-5rem
	width: 166px
#homeMainBanner .container:nth-child(9) .w60 .fs1-5rem,
.angels-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem,
.angels-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem,
.angels-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem
	width: 209px
#homeMainBanner .container:nth-child(13) .w60 .fs1-5rem,
.angelsnetwork-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem,
.angels-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem,
.angels-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem,
.angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem
	width: 343px
#homeMainBanner .container:nth-child(14) .w60 .fs1-5rem,
.lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem,
.lawyers-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem,
.vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
	width: 248px
.company-searchbox
	min-height: 325px
#homeMainBanner .container:nth-child(4) .w50 .pl3rem.pt1rem
	width: 370px
#homeMainBanner .container:nth-child(5) .w50 .custom-list li:last-child
	padding-right: 58px
#homeMainBanner .container:nth-child(8) .w50 .custom-list li:nth-child(3),
#homeMainBanner .container:nth-child(8) .w50 .custom-list li:nth-child(2),
#homeMainBanner .container:nth-child(13) .w50 .custom-list li:nth-child(2),
#homeMainBanner .container:nth-child(14) .w50 .custom-list li:nth-child(2),
#homeMainBanner .container:nth-child(14) .w50 .custom-list li:nth-child(3)
	width: 350px
#homeMainBanner .container:nth-child(12) .w50 .custom-list
	width: 367px
#homeMainBanner .container:nth-child(14) .w50 .custom-list li:nth-child(1)
	width: 400px
.company-searchbox .verify-btn
	padding: 14px 16px
	top: 10.5px
	right: 3px
	border-radius: 0px 7px 7px 0px
.company-searchbox .verify-btn.mr6rem
	margin-right: 0
	right: 108px
	border-radius: 7px 0px 0px 7px

.home-banner-right-side-zak
	top: 0px
	left: 0px
	width: 120px
.carousal-crical-top
	top: -28px
	left: -1%
	width: 5% !important
#homeMainBanner .container
	max-width: 100% !important
	padding-left: 17.4% !important
	padding-right: 17.4% !important 
.accounts-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, 
.accounts-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem
	width: 176px
.privacy-policy-page 
	.policy-box
		padding: 0px 90px 90px 90px
.team-sec
	padding: 5rem 12rem 1rem
.action-sec
	padding: 7rem 17rem 7rem 7rem !important
#homeMainBanner .container .testmonial-user
	margin-top: -9px !important
.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user,
.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
	margin-top: 2px !important
.comapnies-page-font #homeMainBanner .container:nth-child(5) .w40 img
	width: 109%
.comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user,
.comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user
	margin-top: 2px !important
.comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user
	margin-top: -33px !important
.comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user
	margin-top: 5px !important
.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
	margin-top: -33px !important
.comapnies-page-font #homeMainBanner .container:nth-child(7) .w40 img,
.comapnies-page-font #homeMainBanner .container:nth-child(10) .w40 img,
.comapnies-page-font #homeMainBanner .container:nth-child(14) .w40 img,
.accounts-page-font #homeMainBanner .container:nth-child(2) .w40 img,
.accounts-page-font #homeMainBanner .container:nth-child(7) .w40 img,
.accounts-page-font #homeMainBanner .container:nth-child(10) .w40 img,
.accounts-page-font #homeMainBanner .container:nth-child(12) .w40 img,
.accounts-page-font #homeMainBanner .container:nth-child(14) .w40 img,
.lawyers-page-font #homeMainBanner .container:nth-child(2) .w40 img,
.lawyers-page-font #homeMainBanner .container:nth-child(7) .w40 img,
.lawyers-page-font #homeMainBanner .container:nth-child(10) .w40 img,
.lawyers-page-font #homeMainBanner .container:nth-child(12) .w40 img,
.lawyers-page-font #homeMainBanner .container:nth-child(14) .w40 img,
.vcs-page-font #homeMainBanner .container:nth-child(6) .w40 img,
.vcs-page-font #homeMainBanner .container:nth-child(9) .w40 img,
.vcs-page-font #homeMainBanner .container:nth-child(14) .w40 img,
.pes-page-font #homeMainBanner .container:nth-child(6) .w40 img,
.pes-page-font #homeMainBanner .container:nth-child(9) .w40 img,
.pes-page-font #homeMainBanner .container:nth-child(14) .w40 img,
.angelsnetwork-page-font #homeMainBanner .container:nth-child(6) .w40 img,
.angels-page-font #homeMainBanner .container:nth-child(6) .w40 img,
.angels-page-font #homeMainBanner .container:nth-child(9) .w40 img
	width: 110%
.comapnies-page-font #homeMainBanner .container:nth-child(13) .w40 img,
.vcs-page-font #homeMainBanner .container:nth-child(8) .w40 img,
.pes-page-font #homeMainBanner .container:nth-child(8) .w40 img,
.angelsnetwork-page-font #homeMainBanner .container:nth-child(8) .w40 img,
.angels-page-font #homeMainBanner .container:nth-child(8) .w40 img,
.angels-page-font #homeMainBanner .container:nth-child(7) .w40 img
	width: 105%
.comapnies-page-font #homeMainBanner .container:nth-child(10) .w60,
.accounts-page-font #homeMainBanner .container:nth-child(3) .w60,
.lawyers-page-font #homeMainBanner .container:nth-child(3) .w60,
.angels-page-font #homeMainBanner .container:nth-child(8) .w60
	margin-top: 2rem
.vcs-page-font #homeMainBanner .container:nth-child(2) .w40 img,
.pes-page-font #homeMainBanner .container:nth-child(2) .w40 img,
.angelsnetwork-page-font #homeMainBanner .container:nth-child(2) .w40 img,
.angels-page-font #homeMainBanner .container:nth-child(2) .w40 img
	width: 115%
.vcs-page-font #homeMainBanner .container:nth-child(10) .w60,
.vcs-page-font #homeMainBanner .container:nth-child(3) .w60,
.pes-page-font #homeMainBanner .container:nth-child(10) .w60,
.pes-page-font #homeMainBanner .container:nth-child(3) .w60,
.angelsnetwork-page-font #homeMainBanner .container:nth-child(10) .w60,
.angelsnetwork-page-font #homeMainBanner .container:nth-child(3) .w60,
.angels-page-font #homeMainBanner .container:nth-child(10) .w60,
.angels-page-font #homeMainBanner .container:nth-child(3) .w60,
.angelsnetwork-page-font #homeMainBanner .container:nth-child(11) .w60
	margin-top: 2rem
.angelsnetwork-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem
	width: 170px
.angelsnetwork-page-font #homeMainBanner .container:nth-child(5) .w40 img
	width: 120%
	margin-top: 3rem
.angelsnetwork-page-font #homeMainBanner .container:nth-child(14) .w40 img
	width: 120%
.angelsnetwork-page-font  #homeMainBanner .container:nth-child(7) .w40 img
	width: 115%
	margin-top: 3rem
.angelsnetwork-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem
	width: 250px
.vcs-page-font, .pes-page-font, .angelsnetwork-page-font, .angels-page-font
	.work-container
		max-width: 1400px
.beta-logo
	width: 90px
	height: 50px
	margin-top: 11px
.automated-sec 
	.fs2-2rem
		font-size: 35px
		line-height: 48px
.certificates-page .certificates-current .packge-select img.w70px
	width: 120px
#homeMainBanner .container:nth-child(odd) .w60
	padding-left: 7rem
.accounts-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem
	width: 240px
.review-img 
	img
		width: 80% !important
		height: auto !important
.client-review-container 
	p
		font-size: 20px
		line-height: 34px !important
		letter-spacing: 1px
.client-review-container .fs2-125rem
	font-size: 50px
.investmentcertificate-page .certificate-area .right-area .certificate-fail-pass
	position: absolute
.investmentcertificate-page .certificate-area .right-area .toparea
	top: 27px
.investmentcertificate-page .certificate-area .right-area .bottom-area
	bottom: 6px
.dashbaord-certficate-page
	.w250px
		width: 120px
	.certificate-fail-pass
		position: absolute
		left: 50%
		transform: translateX(-50%)
		.toparea.pa
			top: 22px
		.bottom-area
			bottom: 3px
.progress-area-report 
	.certification-area
		margin-bottom: 15px
		.w250px
			width: 130px
		.certificate-fail-pass
			position: absolute
			.toparea
				top: 21px
				p
					margin-bottom: -6px
			.bottom-area
				bottom: 2px
.tooltip-box .tooltip-area .overlay-box .pricing-box img	
	height: 100%
.tooltip-box .tooltip-area .overlay-box .pricing-box .small-img
	height: 120px
.pricing-tooltbox .tooltip-area
	zoom: 0.6
#homeMainBanner 
	.container:nth-child(12), .container:nth-child(14)
		.w60 
			.custom-list
				width: 500px
.calcluter-sec 
	.fs2rem
		font-size: 33px
	.fs2-5rem
		font-size: 44px
.carousel 
	.slide 
		.fs2-125rem
			font-size: 40px
.investor-box
	.left-certificate-area
		background-image: url(../images/landingpage/investor-bg.png)
		background-size: cover
		background-position: top center
		background-repeat: no-repeat
		.round-box
			left: 50%
			transform: translateX(-50%)
			top: -40px
	.list-right-area 
		.benefits-box
			min-height: 291px
	.custom-list 
		li
			font-size: 18px
.w35
	width: 35%
.bo28
	border-color: #00000034
.step-box-modal
	.step-box-box-1
		width: 100%
		padding: 0 10px
	.step-box-box-2
		width: 37%
		padding: 0 10px
.step-box-modal.style-two
	padding: 0 61px
	.step-box-box-1
		width: 100%
		margin: 0 auto
		padding: 0
.form-modal-steps
	background-image: url(../images/landingpage/form-bg.png)
	background-size: cover
	background-repeat: no-repeat
	background-position: top center
	.dropdown-box
		z-index: 99
		width: 97%
		padding: 10px
		top: 47px
		left: -4px
		.cp.df.fww
			border-bottom: 1px solid #32325d40
.register-container .pt0-5rem.pb0-5rem svg
	top: 50% !important
	transform: translateY(-50%)
.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user
	margin-top: -19px !important
.register-container, .register-page-container, .login-container
	.password-group 
		input
			padding: 1rem 1rem 1rem 3rem
		.MuiInputBase-root
			border-radius: 11px
			background-color: #fff
		.MuiFormControl-root
			position: relative
			p
				font-size: 11px
				position: absolute
				bottom: -27px
				left: -9px
				line-height: 13px
.login-container
	input
		padding: 1rem 1rem 1rem 3rem
	.MuiInputBase-root
		border-radius: 11px
		background-color: #fff
	.MuiFormControl-root
		position: relative
		p
			font-size: 11px
			position: absolute
			bottom: -20px
			left: -9px
			line-height: 13px
.search-center-btn
	justify-content: center
.investor-back-btn
	margin: 0
.two-min-invest-image
	text-align: right
	img
		width: 250px
.certificates-page 
	.packge-select 
		.certificate-audited
			margin-top: 2rem
	.valid-date
		bottom: 12px
.dashbaord-certficate-page 
	.certificate-audited 
		margin-top: 1.3rem
		p
			font-size: 7px
.appstor-heading
	top: -71px
	border-bottom: none
	border-radius: 10px 10px 0 0
	left: 10px
.certifcate-box-pass-fail
	right: 6px
	top: 13px
	img
		width: 39px
.button-groups-new 
	.module-tooltip-box 
		.tooltip-area 
			.overlay-box
				width: 100% !important
.keyfeautre-container
	width: 1600px
	box-sizing: border-box
	max-width: 100vw
	margin-left: auto
	margin-right: auto
.keyfeature-box-free
	border: 1px solid #d6dcee
	background-color: #f8f9fe
.keyfeature-box-portfolio
	border: 1px solid #7385BE50
	background-color: #f0f2fdcc
.keyfeature-box-core
	border: 1px solid #13C33A
	background-color: #F0F2FD
.keyfeature-box-core-pro
	border: 1px solid #13C33A
	background-color: #E3E8FF
.keyfeature-box-appstore
	border: 1px solid #F58DA7
	background-color: #E3E8FF
.keyfeature-box-portfolio-innner
	&:before
		content: ""
		background-color: #cbd5ff
		border-radius: 200px 50px 50px 200px
		width: 90%
		height: 96%
		position: absolute
		top: 48%
		left: 50px
		transform: translateY(-50%)
	.keyfeature-box-portfolio-bg 
		&:before
			content: ""
			background-color: #f0f2fd
			border-radius: 200px 50px 50px 200px
			width: 88%
			height: 81%
			position: absolute
			top: 49%
			left: 80px
			transform: translateY(-50%)
		.keyfeature-wihte-item
			width: 28% !important
			margin: 0 28px 30px
		.seemore-box
			position: absolute
			margin-top: 80px
.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(5)
	margin-right: 28px
.keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(2)
	margin-left: 28px
.for-yoy-box
	background-color: #767B90
	margin-bottom: -13px
	padding-top: 0.65rem
	padding-bottom: 0.65rem
.accounts-page-font .keyfeature-box-portfolio-innner:before,
.lawyers-page-font .keyfeature-box-portfolio-innner:before
	background-color: #FFF6A6
.accounts-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3),
.lawyers-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3)
	background-color: #FFFBD3
	border: 1px solid #F9E84B
.accounts-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3)::before,
.lawyers-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3)::before,
.keyfeature-box .keyfeature-wihte-item.bgyellow::before
	border-top-color: #FFFBD3

.vcs-page-font .keyfeature-box-portfolio-innner:before,
.pes-page-font .keyfeature-box-portfolio-innner:before,
.angelsnetwork-page-font .keyfeature-box-portfolio-innner:before,
.angels-page-font .keyfeature-box-portfolio-innner:before
	background-color: #AAFED680
.vcs-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3),
.pes-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3),
.angelsnetwork-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3),
.angels-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3)
	background-color: #E3FFF2
	border: 1px solid #4DEB9F

.vcs-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3)::before,
.pes-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3)::before,
.angelsnetwork-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3)::before,
.angels-page-font .keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3)::before
	border-top-color: #E3FFF2
.keyfeature-box-portfolio 
	.seemore-box
		width: 100%
		margin-top: 15px
.keyfeature-box
	.keyfeature-wihte-item
		width: 19.1%
		margin: 0 12px 30px
		.features-icon
			width: 100%
			svg
				width: 40px
				height: 40px
				margin-bottom: 10px
		&:nth-child(5)
			margin-right: 0
		&:nth-child(2)
			margin-left: 0
		&:nth-child(7)
			margin-right: 0
		&:nth-child(8)
			margin-left: 0
		.fs1rem.c15
			font-size: 15px
		.seemore-box
			z-index: 99
			width: 1278px
			position: absolute
			top: 149px
			left: 0
	.seemore-inner 
		background-color: #fff
		border-radius: 15px
		box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d
		padding: 25px
		zoom: 0.8
		&:hover
			.seemore-box
				opacity: 1
				visibility: visible
.keyfeature-box-portfolio-bg 
	.keyfeature-wihte-item
		.seemore-box
			width: 800px
			top: 100px
.work-card-box .df.jce.pr img
	position: relative
	top: 22px
.pricing-tabs
	.pricing-advisor-table 
		display: flex
		flex-wrap: wrap
		.pricing-box
			width: 21%
	.booking-sec
		z-index: 3
		margin-top: 9px
		margin-bottom: -86px !important
		padding: 0 !important
		width: 114%
		.w30 
			button.bg4
				height: 60px
				border-radius: 0 0 15px 15px
	.booking-sec 
		.pricing-bottom-box 
			p
				font-size: 13px
.pricing-table-saf
	width: 1290px
.disclaimer-modal 
	.header-area 
		background: rgb(255, 145, 0)
		background: linear-gradient(315deg, #ffa733 0%, #ffc04b 50%, #f6cf6c 100%)
		border-radius: 10px 10px 0 0

.work-card-box 
	.df.jce.pr 
		svg
			width: 45px
			height: 68px
		img
			width: auto
			height: 52px
.accounts-page-font .video-container img.pa,
.accounts-page-font .video-container img.w10.pa,
.lawyers-page-font .video-container img.pa,
.lawyers-page-font .video-container img.w10.pa,
.vcs-page-font .video-container img.pa,
.vcs-page-font .video-container img.w10.pa,
.pes-page-font .video-container img.pa,
.pes-page-font .video-container img.w10.pa
	display: none
.pes-page-font .review-img img
	width: 79% !important
.top-pricing-area
	top: -92px
	width: 91%
	margin: 0 auto
	filter: drop-shadow(0px 0px 13px #32325d20)
.pricing-inner
	margin-top: -83px
.tabs-portfolio .pt10rem
	padding-top: 2rem
.tabs-portfolio .pt10rem .df.jcsb.w100.mb2rem.px4rem
	margin-bottom: 8rem
.pricing-tabs .booking-sec .pricing-bottom-box
	zoom: 0.8
	min-height: 163px
.about-us-page
	overflow: hidden
.lisn
	list-style: none
.banner-advisor-dashboard
	background-color: transparent
.banner-advisor-dashboard:before
	content: ""
	position: absolute
	left: 50%
	top: 50%
	width: 95%
	height: 104%
	transform: translate(-50%, -50%)
	border-radius: 24px
.banner-advisor-dashboard.investor-bg:before
	background-color: #AAFED680
.tabs-navlist 
	.MuiTabs-root
		border: 1px solid #4A67FF
		border-radius: 39px
		.MuiTabs-flexContainer
			button
				min-width: 243px
				padding: 13px !important
				font-size: 17px
				color: #4A67FF
				text-transform: capitalize
				font-weight: 600
				transition: all 0.3s ease-in-out
				&:nth-child(2), &:nth-child(3)
					border-left: 1px solid
					border-right: 1px solid
				&:last-child
					border: none
				&:hover, &.Mui-selected
					background: rgb(57,86,240)
					background: linear-gradient(185deg, rgba(57,86,240,1) 35%, rgba(132,150,244,1) 100%)
					color: #fff
.tabs-navlist .MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator
	display: none !important
.certificate-area-dashboard 
	.certificate-audited
		margin-top: 1.5rem
.certificate-company-settings 
	.certificate-audited
		width: 70% !important
.banner-advisor-dashboard.advisor-bg:before
	background-color: #fff6a680
.dahboard-tabs 
	.MuiTabPanel-root
		padding: 0
		box-sizing: border-box
		width: 98%
		margin: 0 auto
.dashboard-container
	width: 86%
	margin: 0 auto
.box-dashbord-container .keyfeature-box-portfolio .keyfeature-box-portfolio-innner::before
	display: none
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item,
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item
	border: 1px solid #B5BFDE
	padding-bottom: 1.4rem
	min-height: 200px
	transition: all 0.3s ease-in-out
	margin: 0 15px 40px

.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box.keyfeature-box-anylist .keyfeature-wihte-item
	border: 1px solid #2cff28
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:hover,
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item:hover
	box-shadow: 0 0 15px #32325d40
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details,
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details
	display: flex
	position: absolute
	bottom: 13px
	align-items: end
.icon-diligenstia
	svg
		width: 40px
		height: 40px
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details p
	font-size: 14px
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-bottom-area,
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-bottom-area,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area
	position: absolute
	bottom: -19px
	right: -8px
	display: flex
	align-items: center
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .hover-list,
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .hover-list
	position: absolute
	top: -11px
	left: -8px
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:before,
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item:before
	display: none
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item h2,
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item h2
	line-height: 1.5rem
.box-dashbord-container .keyfeature-box-portfolio-innner
	width: 100%
.box-dashbord-container 
	.keyfeature-box-core-dashboard
		.keyfeature-box-core-pro
			background-color: #F7F8FE
		.keyfeature-box-appstore
			background-color: #F0F2FD
.dahboard-tabs.investor-bg 
	.box-dashbord-container 
		.keyfeature-box-portfolio 
			.keyfeature-box-portfolio-innner 
				.keyfeature-box-portfolio-bg 
					.keyfeature-wihte-item:nth-child(3)
						background-color: #E3FFF2
						border-color: #4DEB9F
.dahboard-tabs.advisor-bg
	.box-dashbord-container 
		.keyfeature-box-portfolio 
			.keyfeature-box-portfolio-innner 
				.keyfeature-box-portfolio-bg 
					.keyfeature-wihte-item:nth-child(3)
						background-color: #fffbd3
						border-color: #f9e84b
.dahboard-tabs
	background-color: transparent !important
.box-dashbord-container 
	.keyfeature-box-core-dashboard 
		.keyfeature-box 
			.keyfeature-wihte-item
				width: 22.5%
				h4
					font-size: 16px
.box-dashbord-container 
	.keyfeature-box-portfolio-innner 
		.keyfeature-box-portfolio-bg 
			.keyfeature-wihte-item
				h4
					font-size: 16px
.dahboard-tabs 
	.MuiTabPanel-root 
		.dashboard-firm-tab
			width: 95%
			margin: 0 auto
			.box-dashbord-container 
				.keyfeature-box-core-dashboard
					background-color: transparent
					border: none
					.keyfeature-box
						background-color: #F0F2FD50
						border-color: #7385BE50
						.keyfeature-wihte-item
							min-height: 178px
							width: 23%
.dahboard-tabs 
	.MuiTabPanel-root 
		.dashboard-firm-tab 
			.box-dashbord-container 
				.keyfeature-box-core-dashboard 
					.keyfeature-box-workplace
						padding: 0
						border: none
						background-color: transparent
						.keyfeature-wihte-item 
							.report-btn
								bottom: auto
								top: -15px
								right: auto
								left: 38px
		.add-btn-dashboard
			font-size: 14px
			padding: 5px 1rem 5px 5px
			transition: all 0.3s ease-in-out
			border-color: #DBDFEE
			&:hover
				box-shadow: 0 0 13px #32325d20
			&.add-btn-dashboard-switch
				padding: 5px 1rem
				.MuiSwitch-root
					width: 70px
					.MuiButtonBase-root
						color: #fff
						left: 5px
					.MuiSwitch-track
						height: 25px
						border-radius: 44px
						opacity: 0.2
.dahboard-tabs .MuiTabPanel-root .add-btn-dashboard.add-btn-dashboard-switch .MuiSwitch-root .Mui-checked+.MuiSwitch-track
	background: linear-gradient(185deg, #3956f0 35%, #8496f4 100%)
	opacity: 1
.dropdown-company-dahboard 
	.reprot-btn-dropdowns
		.MuiFormControl-root
			width: 100%
			min-width: 100%
			label
				margin: -4px 0 0 14px
			.MuiInputBase-root
				background-color: transparent !important
				&:before
					display: none
				.MuiSelect-select
					background-color: #fff !important
					padding: 11px 32px 9px 11px !important
					border-radius: 35px !important
					border: 1px solid #dbdfee !important
.reprot-btn-dropdowns .Mui-focused:after,
.dropdown-company-dahboard .reprot-btn-dropdowns .MuiFormControl-root .MuiInputBase-root:after
	display: none
.dahboard-tabs 
	.MuiTabPanel-root
		.progress-box 
			.progress-bar
				margin: 0 auto
				position: relative
				background-color: #fff
				padding: 5px
				box-sizing: border-box
				border-radius: 50%
				border: 2px solid #F1F1F1
.progress-box-vertical 
	.progress-bar-vertical
		width: 100%
		height: 17px
		background-color: transparent
		background-size: auto
		background-repeat: no-repeat
		background-image: url(../images/progress-bar-bg.png)
.progress-box-vertical 
	.progress-bar-vertical 
		.progress-bar
			width: 100%
			height: 17px
			background-color: transparent
			background-size: auto
			background-repeat: no-repeat
			background-image: url(../images/progress-bar-active-bg.png)
		&.style-two
			.progress-bar
				background-image: url(../images/progress-bar-active-bg2.png)
.progress-box-vertical
	.progress-bar-vertical 
		.progress-bar 
			.precentage-block
				.icon
					background-color: transparent
					padding: 0
					border-radius: 0
					filter: drop-shadow(0px 0px 3px #32325d40)
.company-details-area 
	.certficate-area 
		.certficate-box
			zoom: 0.7
.table-advisor
	background-color: #F0F2FD50
	border-radius: 30px
	border: 1px solid #F0F2FD
	padding: 2rem
	thead
		th
			background: transparent !important
			line-height: 4rem
			text-align: center
			width: auto
			border-bottom: 1px solid #B5BFDE
.table-advisor tr:nth-child(2n),  
.table-advisor tr > :nth-child(2n)
	background-color: transparent
.table-advisor th, .table-advisor td
	width: auto
	text-align: center
.table-advisor 
	button
		background-color: transparent
		border: none
.bottom-support
	.upgrading-pro 
		svg.bg1
			border-radius: 50%
			margin-top: -32px
			margin-bottom: 6px
		button
			padding: 8px 22px
			font-size: 12px
			margin-top: 15px

#expandHeader 
	.upgrading-pro
		background-color: transparent
		text-align: left
		svg.bg1
			margin-left: -15px
			background-color: #FC7785
			width: 32px
			height: 32px
			padding: 5px
			fill: #fff
			path
				fill: #fff
				stroke: #fff
#expandHeader .upgrading-pro h4, #expandHeader .upgrading-pro  p, #expandHeader .upgrading-pro  button
	display: none
#expandHeader:hover .upgrading-pro h4, #expandHeader:hover .upgrading-pro  p, #expandHeader:hover .upgrading-pro  button
	display: flex
	flex-direction: column
	justify-content: center
#expandHeader
	&:hover
		.upgrading-pro
			background-color: #F0F2FD
			text-align: center
			svg.bg1
				margin-left: auto
				background-color: #fff
				width: 3rem
				height: 3rem
				padding: 1rem
				fill: #4A67FF
				path
					fill: #4A67FF
					stroke: #4A67FF
#expandHeader #logo
	width: 6rem
	margin-bottom: 1rem
#expandHeader:hover #logo
	width: 10rem
	margin-bottom: 0
#expandHeader .h90vh
	height: 88vh
.box-dashbord-container 
	.keyfeature-box 
		.keyfeature-wihte-item:nth-child(2)
			margin-left: 12px
#expandHeader .pr.df a:hover svg path, #expandHeader .pr.df span.usn:hover svg path
	fill: #4a67ff
#expandHeader .pr.df a:hover svg, #expandHeader .pr.df span.usn:hover svg
	left: 4px
.certificate-area-dashboard
	.certificate-fail-pass
		position: absolute
		.toparea
			top: 20px
			line-height: 6px
		.bottom-area
			bottom: 3px
.banner-advisor-dashboard 
	.client-tab
		padding-bottom: 1.4rem
.dahboard-tabs .MuiTabPanel-root.free-tools .dashboard-tools-area
	width: 97%
	margin: 0 auto
.table-permission .table-userPermissions.table-leades thead tr th, .table-permission .table-userPermissions.table-leades tbody tr td
	min-width: 113px !important
.table-permission .table-userPermissions.table-leades .compnay-name
	min-width: 200px !important
.table-permission .table-userPermissions.table-leades .fit-score, .table-permission .table-userPermissions.table-leades .fit-report
	min-width: 77px !important
.table-permission .table-userPermissions.table-leades .allocated-member
	min-width: 95px !important
.table-permission .table-userPermissions.table-leades .allocated-member .MuiInputBase-root
	width: 166px
	border: none !important
.table-permission .table-userPermissions.table-leades .allocated-member .MuiInputBase-root fieldset 
	border: none
.table-permission .table-userPermissions.table-leades .allocated-member .MuiInputBase-root .MuiSelect-select
	font-size: 13px
	background-color: #fff !important
	border-radius: 25px !important
	border: 1px solid rgb(219, 223, 238)
	border-bottom: 1px solid rgb(219, 223, 238) !important
.table-permission .table-userPermissions.table-leades .accepted-rejected
	min-width: 100px !important
.table-permission .table-userPermissions.table-leades tbody tr td
	text-align: center
.table-permission .table-userPermissions.table-leades tbody tr td img
	margin: 0 auto
.dropdown-company-dahboard.leads-page .progress-area-report, .dropdown-company-dahboard.leads-page .progress-area-report .progress-box
	margin: 0 !important

.advisor-box-content::-webkit-scrollbar
	width: 6px
	border-radius: 6px
.advisor-box-content::-webkit-scrollbar-track
	background: #f1f1f1
	border-radius: 6px
.advisor-box-content::-webkit-scrollbar-thumb
	background: #D9D9D9
	border-radius: 6px
.advisor-box-content::-webkit-scrollbar-thumb:hover
	background: #D9D9D9
.center-logo-area .w9rem.h9rem
	background-color: #f5f6f930
.center-logo-area
	.input-file
		cursor: pointer
		z-index: 2
		background: none
		border: none
		width: 35px
		height: 35px
		position: absolute
		bottom: 1px
		right: -30px
		.add-btn
			cursor: pointer
			text-align: center
			background-color: #fff
			border-radius: 50%
			width: 23px
			height: 23px
			padding: 5px
			line-height: 1.8rem
			position: absolute
			top: 7px
			left: -12px
			box-shadow: 0 0 5px #0003
			svg 
				opacity: .9
				fill: #748aff
				width: 17px
.center-logo-web 
	.center-logo-area
		margin-left: -45px
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .hover-list-icon svg, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .hover-list-icon svg
	width: 1.7rem
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .questionmark-icon .overlay-box, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .questionmark-icon .overlay-box, 
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-btn .overlay-box, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-btn .overlay-box,
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box,
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box,
.icon-diligenstia .activedeactive-area .overlay-box,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .questionmark-icon .overlay-box,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn .overlay-box,
.add-client-area .right-info-hyper-icon .hover-list-icon .overlay-box
	background: rgb(255, 242, 124)
	background: linear-gradient(185deg, rgba(255, 242, 124, 1) 75%, rgba(232, 187, 31, 1) 100%)
	padding: 5px 7px
	min-width: 83px
	z-index: 9
	top: -47px
	left: 50%
	transition: all .3s ease-in-out
	position: absolute
	transform: translateX(-50%)
	border-radius: 7px
	opacity: 0
	visibility: hidden
.show-tooltip
	opacity: 0.8 !important
	visibility: visible !important
.right-info-hyper-icon
	display: flex
	align-items: center
	position: absolute
	top: -14px
	right: -6px
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box,
.add-client-area .right-info-hyper-icon .hover-list-icon .overlay-box
	min-width: 126px
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .questionmark-icon .overlay-box p, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .questionmark-icon .overlay-box p, 
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-btn .overlay-box p, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-btn .overlay-box p,
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box p, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box p,
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box p, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box p,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box p,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .questionmark-icon .overlay-box p,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn .overlay-box p,
.add-client-area .right-info-hyper-icon .hover-list-icon .overlay-box p
	font-size: 11px
.icon-diligenstia .activedeactive-area .overlay-box p
	font-size: 11px
	justify-content: center
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .questionmark-icon:hover .overlay-box, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .questionmark-icon:hover .overlay-box, 
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-btn:hover .overlay-box, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-btn:hover .overlay-box,
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .info-icon:hover .overlay-box, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .info-icon:hover .overlay-box,
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon:hover .overlay-box, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon:hover .overlay-box,
.icon-diligenstia .activedeactive-area:hover .overlay-box,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .info-icon:hover .overlay-box,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon:hover .overlay-box,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .questionmark-icon:hover .overlay-box,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn:hover .overlay-box,
.add-client-area .right-info-hyper-icon .hover-list-icon:hover .overlay-box
	background: rgb(255, 242, 124)
	background: linear-gradient(185deg, rgba(252, 244, 163, 1) 75%, rgba(241, 218, 119, 1) 100%) 
	opacity: 1
	visibility: visible	
	z-index: 999
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .questionmark-icon .overlay-box:before, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .questionmark-icon .overlay-box:before, 
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-btn .overlay-box:before, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-btn .overlay-box:before, 
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box:before, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box:before, 
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box:before, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box:before,
.icon-diligenstia .activedeactive-area .overlay-box:before,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .info-icon .overlay-box:before,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box:before,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .questionmark-icon .overlay-box:before,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn .overlay-box:before,
.add-client-area .right-info-hyper-icon .hover-list-icon .overlay-box:before
	content: ""
	background-color: #fff27c
	background-image: linear-gradient(185deg, #e8bb1f80 75%, #fff27c 100%)
	clip-path: polygon(0% 0, 100% 0%, 46% 100%)
	width: 19px
	height: 12px
	position: absolute
	bottom: -11px
	left: 50%
	transform: translateX(-50%)
.remove-edit-area
	.edit-remove
		background-color: transparent
		border: none
		cursor: pointer
.step-box-images 
	.step-img 
		img
			backdrop-filter: drop-shadow(0 0 13px #32325d20)
.add-client-area 
	.right-info-hyper-icon
		top: -5px
		right: 15px
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .right-info-hyper-icon .hover-list-icon .overlay-box
	min-width: 135px
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .questionmark-icon .overlay-box,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .right-info-hyper-icon .questionmark-icon .overlay-box
	min-width: 203px
	left: 50%
	transform: translateX(-50%)
	top: -62px
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-btn .overlay-box, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-btn .overlay-box,
.icon-diligenstia .activedeactive-area .overlay-box,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn .overlay-box
	min-width: 124px
	left: 50%
	transform: translateX(-50%)
	top: 48px
.icon-diligenstia .activedeactive-area .overlay-box
	top: -56px
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-btn .overlay-box:before, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-btn .overlay-box:before,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn .overlay-box:before
	clip-path: polygon(50% 0, 100% 100%, 0 100%)
	bottom: auto
	top: -11px
	background-image: linear-gradient(185deg, #fcf4a3 0%, #fcf4a3 100%)
	background-color: #fcf4a3
.icon-diligenstia .activedeactive-area .overlay-box p .icon svg
	width: 12px
	height: 12px
.step-top-box
	img
		margin-left: 35px
.company-dashboard
	.center-logo-area
		margin-top: -46px
		h2
			.icon
				margin-top: -28px
	.company-bg
		&::before
			background-color: #C5CFFF80
.company-dashboard 
	.advisor-profile
		color: #fff
.w25
	width: 25%
.checklist-area
	padding: 14px 57px
	.checklist-inner
		.checklist-box
			padding-left: 20px
			font-size: 12px
			.icon
				top: 50%
				transform: translateY(-50%)
.right-certificate-area 
	.progress-bar-box 
		.progress-area 
			.progress-bar
				box-sizing: border-box
				background-color: #fff
				border: 1px solid #f1f1f1
				border-radius: 50%
				margin: 0 auto
				padding: 3px
				position: relative
			span
				font-size: 10px
.adivsor-user-company
	border: 2px solid rgb(219, 223, 238)
	padding: 0 1rem 0 0.5rem
	img
		width: 30px
		height: 30px
.company-dashboard-bg::before
	background-color: #C5CFFF80
.company-tab.advisor-bg-lite
	background-color: #fff
.advisor-bg-lite::before
	background-color: #fff6a680
.investor-bg::before
	background-color: #AAFED680
.company-dashboard-view 
	.dahboard-tabs 
		.MuiTabPanel-root
			width: 94%
			.keyfeature-box-company-pro
				background-color: #F0F2FD50
.tabs-navlist.tabs-dashboard 
	button
		background-color: transparent
		padding: 0
		margin: 0 15px
		color: #15131360
		position: relative
		&:before
			content: ""
			position: absolute
			left: 50%
			bottom: -1px
			width: 79%
			height: 1px
			background-color: #2947ef
			transform: translateX(-50%)
			opacity: 0
		&.active
			color: #2947EF
			&:before
				opacity: 1
.right-info-hyper-icon 
	.hover-list-icon
		.overlay-box
			position: absolute
			top: -38px
			left: 50%
			transform: translateX(-50%)
			min-width: 150px
			background-color: #fff
			border-radius: 5px
			padding: 5px 10px
			visibility: hidden
			opacity: 0
			transition: all 0.3s ease-in-out
			box-shadow: 0 0 13px #32325d20
			p
				font-size: 13px
		&:hover
			.overlay-box
				visibility: visible
				opacity: 1
.right-info-hyper-icon 
	.questionmark-icon
		.overlay-box
			position: absolute
			top: -75px
			min-width: 250px
			left: 50%
			transform: translateX(-50%)
			background-color: #fff
			border-radius: 5px
			padding: 5px 10px
			visibility: hidden
			opacity: 0
			transition: all 0.3s ease-in-out
			box-shadow: 0 0 13px #32325d20
			p
				font-size: 13px
		&:hover
			.overlay-box
				visibility: visible
				opacity: 1
.right-info-hyper-icon 
	.info-icon
		.overlay-box
			position: absolute
			top: -38px
			left: 50%
			transform: translateX(-50%)
			min-width: 150px
			background-color: #fff
			border-radius: 5px
			padding: 5px 10px
			visibility: hidden
			opacity: 0
			transition: all 0.3s ease-in-out
			box-shadow: 0 0 13px #32325d20
			p
				font-size: 11px
		&:hover
			.overlay-box
				visibility: visible
				opacity: 1
.module-tooltip-box 
	.hover-list
		position: absolute
		top: -8px
		left: -8px
.company-dashboard-view 
	.dahboard-tabs 
		.MuiTabPanel-root 
			.keyfeature-wihte-item
				min-height: 175px
				width: 22.5%
				border: 1px solid #b5bfde
				&::before
					display: none
				.tooltip-area.style-two
					bottom: 10px
					left: 22px
				.ribbon.ribbon-bottom-right
					position: absolute
					bottom: 14px
					right: 17px
					img
						width: 101px
						height: auto
			.all-reprots-area
				min-height: 175px
				width: 23.1%
				margin: 0 12px 0
				.keyfeature-wihte-item
					width: 100% !important
					margin: 0
					margin-right: 9px
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .ribbon.ribbon-bottom-right.trophy-cup img
	width: 61px
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .certificate-tooltip-box
	width: 23.1%
	min-height: 175px
.payment-modal 
	.header-modal
		background-size: cover
		height: 95px
		position: relative
		.number-header
			position: absolute
			width: 45px
			height: 45px
			background-color: #fff
			text-align: center
			line-height: 45px
			border-radius: 50%
			font-size: 20px
			color: #151313
			font-weight: 500
			border: 3px solid #FC7785
			left: -6px
			top: -10px
	.body-modal 
		.StripeElement
			border: 1px solid #00000080
			padding: 10px
			border-radius: 8px
		input
			background-color: #FFEFF0
			border: 1px solid #FC778554
		.btn-call-book
			background-color: transparent
			background-size: 100% 100%
			font-size: 15px
			color: #15131380
			position: relative
			.icon
				position: absolute
				left: 27px
				top: 16px
		.access-code
			background-color: #fff
			border: 1px solid #00000050
.lock-icon.wf-report
	svg
		width: 52px
.keyfeature-box .keyfeature-wihte-item .title-box-feature
	position: relative
	top: -6px
.keyfeature-box .keyfeature-wihte-item .title-box-feature .icon
	position: relative
	left: -5px
	top: 3px
.keyfeature-box .keyfeature-wihte-item .title-box-feature .icon img
	width: 20px
.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details p, 
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details p
	font-size: 14px
.keyfeature-box-anylist
	border-color: #4A67FF
.tooltip-whatis
	position: absolute
	min-width: 90px
	z-index: 9
	background: linear-gradient(185deg, #fff27c 75%, #e8bb1f 100%)
	border-radius: 7px
	padding: 5px
	transition: all .3s ease-in-out
	top: -42px
	left: 50%
	transform: translateX(-50%)
	opacity: .8
	&::before
		content: ""
		clip-path: polygon(0% 0, 100% 0%, 46% 100%)
		background-color: #fff27c
		background-image: linear-gradient(185deg, #e8bb1f 75%, #fff27c 100%)
		width: 19px
		height: 12px
		position: absolute
		bottom: -11px
		left: 50%
		transform: translateX(-50%)
	p
		font-size: 11px
	
.icon-diligenstia 
	.activedeactive-area
		position: absolute
		left: -4px
		top: -4px
		width: 14px
		height: 14px
		border-radius: 50%
	.icon-diligenstia 
		.activedeactive-area
			background-color: #15D757
	.active-btn
		border-color: #151313
		color: #151313
.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item-not-active
	border-color: #B5BFDE
	.icon-diligenstia 
		.activedeactive-area
			background-color: #D9D9D9
	.active-btn
		border-color: #15D757
		color: #15D757
.dahboard-tabs .MuiTabPanel-root 
	.dashboard-firm-tab 
		.box-dashbord-container 
			.keyfeature-box-core-dashboard 
				.keyfeature-box 
					.keyfeature-wihte-item 
						.score-btn
							bottom: 35px
							right: 23px
.dahboard-tabs 
	.MuiTabPanel-root 
		.anylist-builder-tab 
			.add-btn-dashboard
				position: relative
				top: auto
				right: auto
				width: 145px
				justify-content: end
				margin: 0 auto
				float: right
				clear: both
.right-area-details 
	.dropdown-details 
		.dropdown-btn
			width: 83px
			padding: 4px 6px
			text-align: left
			font-size: 10px
			.icon-right
				right: 5px
				top: 6px
				svg
					width: 12px
					height: 12px
	.dropdown-status 
		.dropdown-btn
			padding: 3px 15px
			margin-right: 11px
			font-size: 10px
.icon-textare
	.icon-plus
		background-color: transparent
.task-notes-scroll
	max-height: 30vh
	overflow: scroll
.task-notes-scroll::-webkit-scrollbar
	width: 7px !important
.notes-details
	.notes-box
		.notes-left-area
			width: 86%
			.notes-text
				h4
					font-size: 11px
		.right-area-porirty 
			width: 14%
			.dropdown-btn
				padding: 3px 10px
				font-size: 7px
.priority-dropdown,
.status-dropdown
	ul.dropdown-content
		position: absolute
		list-style-type: none
		z-index: 999
		background: transparent
		li button
			color: #fff !important
			background: transparent
	.first-item
		border-top-left-radius: 7px
		border-top-right-radius: 7px
	.last-item
		border-bottom-left-radius: 7px
		border-bottom-right-radius: 7px
	.grey-bg
		color: #fff !important
		background-color: #BBBBBB !important
		border-color: #BBBBBB !important
	.sky-bg
		color: #fff !important
		background-color: #87DDEC !important
		border-color: #87DDEC !important
	.blue-bg
		color: #fff !important
		background-color: #20A5DE !important
		border-color: #20A5DE !important
	.yellow-bg
		color: #fff !important
		background-color: #F1D52F !important
		border-color: #F1D52F !important
	.orange-bg
		color: #fff !important
		background-color: #ffa954 !important
		border-color: #ffa954 !important
	.red-bg
		color: #fff !important
		background-color: #EB5A46 !important
		border-color: #EB5A46 !important
	.green-bg
		color: #fff !important
		background-color: #35E586 !important
		border-color: #35E586 !important
	.orange-bg
		color: #fff !important
		background-color: #FD9E31 !important
		border-color: #FD9E31 !important

.dropdown-content 
	background-color: #fff
	shadow: none
	.dropdown-item
		display: block
		font-size: 13px
		padding: 4px 15px
		text-align: center
		&.status-bg
			border-radius: 10px 10px 0 0
		&.completed
			border-radius: 0 0 10px 10px
.workflow-report-progress 
	.CircularProgressbar 
		.CircularProgressbar-path
			stroke: #FBFBFB !important
		.CircularProgressbar-text
			fill: #151313 !important
.workflow-progress-grey
	background-color: #dddddd !important
.workflow-progress-green
	background-color: #affd8b !important
.workflow-progress-pink
	background-color: #fda9a4 !important
.workflow-progress-yellow
	background-color: #fde47e !important
.tabs-dashboard .MuiTabs-root
	border: none
.tabs-dashboard .MuiTabs-root .MuiTabs-flexContainer button
	background: none !important
	color: #15131370
	border-bottom: 1px solid transparent
	border-left: none !important
	padding: 0 !important
	min-width: auto !important
	margin: 0 20px
.tabs-dashboard .MuiTabs-root .MuiTabs-flexContainer button:hover, .tabs-dashboard .MuiTabs-root .MuiTabs-flexContainer button.Mui-selected
	color: #3956f0
	border-bottom: 1px solid #3956f0
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .dashboard-keybox .keyfeature-wihte-item:nth-child(8)
	margin-left: 30px
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .dashboard-keybox .keyfeature-wihte-item:nth-child(5)
	margin-right: 15px
.right-info-hyper-icon.right-info-dashboard-area .info-icon .overlay-box
	background: #fff !important
	opacity: 1 !important
	width: 350px
	min-width: auto
	left: -389px !important
	transform: translate(0, -50%) !important
	top: 50% !important
.right-info-hyper-icon.right-info-dashboard-area .info-icon .overlay-box::before
	top: 50%
	right: -28px
	background-color: #fff !important
	background-image: linear-gradient(185deg, #fff 75% 100%) !important
	left: auto !important
	clip-path: polygon(64% 50%, 0% 0%, 0% 100%) !important
	transform: translateX(-50%) !important
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item.op20
	opacity: 1
	border-color: #ccc
	transition: all .3s ease-in-out
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item.op20 .df.aic, 
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item.op20 .bottom-features
	opacity: 0.6
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .df.aic, 
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .bottom-features
	z-index: 9
.box-dashbord-container .keyfeature-box .keyfeature-wihte-item
	min-height: 200px !important
	margin: 0 12px 40px
.tabs-dashboard .MuiTabs-root .MuiTabs-flexContainer button:nth-child(2)
	border-right: none !important
.company-dashboard-view 
	.company-details-area
		margin-top: -18px
		.left-company-area
			margin-top: -72px
		.center-logo-area
			margin-top: -58px
		.right-certificate-area
			margin-bottom: -31px
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .certificate-tooltip-box .keyfeature-wihte-item
	width: 100%
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .certificate-tooltip-box
	border: none
.company-dashboard-view .dashboard-container
	width: 93%
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .registerButtonAnimation
	left: 30px
	bottom: 45px
	margin: 0 36px
	font-size: 14px
	padding: 5px 35px
	text-align: center
	position: absolute
	border-radius: 61px
	.bottom-text
		font-size: 8px
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .certifcate-box-pass-fail
	flex-direction: row
	top: auto
	bottom: -90px
	img
		width: 56px
		margin: 0 8px
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item.op20 .bottom-certification,
.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item.op20 .bottom-packge
	opacity: 0.6
.ases 
	align-self: flex-start !important
.allocationSectionAdmin
	table
		th 
			font-size: 10px
			min-width: 75px !important
			padding: .5rem 0.2rem
	table
		td 
			font-size: 10px
			min-width: 75px !important
			padding: .5rem 0.2rem
.businessCardText
	font-size: 10px
.taci 
	text-align: center !important
.table-report
	padding: 1rem !important
	td, th
		width: auto !important
		min-width: 50px !important
.error
	border: 1px solid red
.errorMessage 
	font-size: 11px
	color: red
.didagent_target 
	display: none
	width:424px!important
#chatbot .didagent__fabio
	position: relative !important
	display: block !important
#chatbot
	position: relative
	width: 290px
	height: 700px
	margin-right: 16rem
#chatbot .didagent_target
	display: block !important
@media (max-width: 1920px)
	.main-banner-container .fs3rem, .main-banner-container .fs3rem .c4
		font-size: 70px!important
	.ceo-sec .ceo-inner .left-ceo-text blockquote
		font-size: 20px
		line-height: 37px !important
	.investorLookup-container
		padding: 0px 150px 100px
	.investor-box
		.custom-list
			li
				font-size: 14px !important
	.client-review-container p
		font-size: 20px
		line-height: 32px !important
	.review-img
		img
			width: 91% !important
	.accounts-page-font, .lawyers-page-font
		.client-review-container
			.review-img
				img
					width: 80% !important
	.pricing-container 
		.pricing-box
			zoom: 0.8
			img.pr.w100
				height: 131px
@media (max-width: 1850px)
	.pillIcon
		bottom: 54px !important
	.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
		margin-top: -4px !important
	.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user
		margin-top: -11px !important
	.comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user
		margin-top: -25px !important
	.comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user
		margin-top: 10px !important
	.inner-container .px6rem
		padding: 0
	.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item
		width: 21.8%
	.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-box-company-pro .coreworkspace-update .keyfeature-wihte-item
		width: 30% !important
		margin: 0 30px 40px
@media (max-width: 1720px)
	.pillIcon
		bottom: 34px !important
	.ceo-sec .ceo-inner .left-ceo-text blockquote
		font-size: 16px
		line-height: 27px !important
	.shadowPaperCrasouel .right-icon-btn span.fs1rem
		font-size: 12px !important
	.inner-container .px6rem
		padding: 3rem
	.keyfeautre-container
		width: 1200px
		.keyfeature-box-portfolio
			zoom: 0.8
	.inner-container 
		.px6rem
			padding: 0
	.client-review-container p
		font-size: 14px
		line-height: 23px !important
	.calcluter-sec
		width: 1237px
		.c15
			font-size: 1.6rem
			line-height: 36px !important
	.register-page-container
		width: 65%, 
		padding: 5px 0px 5px  100px
	#homeMainBanner .container
		padding-left: 11.4% !important
		padding-right: 11.4% !important
	.company-searchbox .verify-btn.mr6rem
		right: 103px
	.main-banner-container
		.pt3rem
			padding-top: 2rem
	.automated-sec
		.mb4rem
			margin-bottom: 3rem
	.automated-sec .c15
		font-size: 30px !important
		line-height: 38px !important
	.automated-sec .c14
		font-size: 17px !important
		line-height: 25px !important
	.calcluter-sec .c15
		font-size: 1.6rem
		line-height: 36px !important
	.calcluter-sec .fs3rem
		font-size: 2.5rem
	.register-container
		.pr10rem
			padding-right: 4rem
		input
			font-size: 0.75rem !important
			padding: 10px
	.login-container
		input
			padding: 10px 10px 10px 3rem
	.register-container .password-group input, .register-page-container .password-group input
		padding: 10px 10px 10px 3rem
	.login-container
		input
			padding: 10px 10px 10px 3rem		
	.automated-sec 
		.w80
			width: 74%
		.w60
			width: 58%
	.inner-container 
		p.fs1-25rem
			font-size: 1.2rem
	.not-sure-container
		padding: 100px 250px
	.inner-container
		padding: 15px 150px !important
	.menu-bottom
		margin-top: -40px !important
	.main-banner-container 
		.mt7rem
			margin-top: 4rem
	.calcluter-sec 
		.fs2-5rem
			font-size: 39px
	.client-review-container 
		p
			letter-spacing: 1px
			font-size: 16px
			line-height: 25px !important
	.custom-list 
		li
			font-size: 16px !important
	#homeMainBanner 
		.container 
			.w60 
				.fs1-5rem
					font-size: 1.3rem
	.work-container .work-card-box .fs1-25rem
		font-size: 16px
		line-height: 25px !important
	#homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem
		width: 160px
	.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
		margin-top: -32px !important
	.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user,
	.comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user
		margin-top: -37px !important
	.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
		margin-top: -54px !important
	.comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user
		margin-top: -50px !important
	.comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user
		margin-top: -48px !important
	.video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem
		width: 477px
		font-size: 30px
		line-height: 40.2px !important
	.register-container .w80
		width: 100%
	.not-sure-container p.w100
		width: 85%
	.work-container .fs1-5rem
		font-size: 1.25rem
		line-height: 30px !important
	#homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
		width: 237px
	.accounts-page-font .client-review-container .review-img img
		width: 68% !important
	.accounts-page-font .client-review-container p
		letter-spacing: 1px
		font-size: 14px
		line-height: 21px !important
	.pricing-container
		.pricing-box
			.br100
				zoom: 0.7
		.pricing-title
			margin-bottom: 2rem
	.vcs-page-font 
		.client-review-container 
			p
				font-size: 14px
				line-height: 21px !important
		.review-img 
			img
				width: 81% !important
	.client-review-container 
		.mt4rem
			margin-top: 2rem
		.mb2-5rem
			margin-bottom: 0
	.register-container 
		input#searchCompany
			padding: 10px 15px 10px 57px
		.shadow.bg1.br5px
			padding: 0.7rem
			span
				font-size: 12px
		.pr8rem 
			.shadow.bg1
				span
					font-size: 12px
	.slider-wrapper 
		.first-slide 
			.inner-container 
				img
					width: 70%
		.inner-container
			.row.df.jcsb
				.df.jce.aic
					img.h80
						height: 66%
	.bottom-button-slider
		.w50
			width: auto
	.main-banner-container .fs3rem, .main-banner-container .fs3rem .c4
		font-size: 53px !important
		line-height: 84px !important
		margin-top: 0.5rem
	.main-banner-container 
		.fs3-5rem
			font-size: 2.8rem
			line-height: 56px !important
	.right-img-area
		height: 440px !important
		margin-top: 310px !important
	.main-banner-container 
		.col-sm.df.jcc.fdc.gg2rem
			padding-right: 3rem
	.shadowPaperCrasouel 
		span.w70
			font-size: 13px
		span.w30
			padding: 10px 8px !important
			text-align: center
	.bottom-button-slider .w40
		width: 50%
	.inner-container 
		.df.jcc.aic 
			img.h60
				height: 179px !important
	.keyfeature-box-portfolio-innner 
		.keyfeature-box-portfolio-bg
			zoom: 0.8
	.keyfeautre-container 
		.keyfeature-box
			zoom: 0.8
	.keyfeature-wihte-item
		width: 18% !important
	.keyfeature-box-free 
		.keyfeature-wihte-item:nth-child(2)
			width: 35% !important
	.not-sure-register 
		.not-sure-container
			padding: 30px 120px !important
	.bottom-button-slider
		position: relative

	#expandHeader .h90vh
		height: 88vh
	.dropdown-company-dahboard
		zoom: .82
	.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item
		width:  22% !important
	.dahboard-tabs .MuiTabPanel-root .dashboard-firm-tab .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details.w90 .lock-icon
		zoom: 0.8
		margin-right: 1rem
	.table-advisor
		zoom: 0.8
	.client-tab .user-area
		width: 82%
	.registration-inner .registration-box
		zoom: 0.8
	.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon
		margin: 0 20px
		font-size: 18px
	.report-page
		.sidebar-report
			&.repository-sidebar
				.mah100
					&.mya
						&.oya
							&.df
								&.fdc
									&.jcsb
										max-height: 50vh
	.company-dashboard-view 
		.dahboard-tabs 
			.MuiTabPanel-root 
				.keyfeature-wihte-item
					width: 23.1% !important
				.dashboard-keybox 
					.keyfeature-wihte-item:nth-child(8)
						margin-left: 20px
	.company-dashboard-view 
		.dahboard-tabs 
			.MuiTabPanel-root 
				.certificate-tooltip-box 
					.keyfeature-wihte-item
						width: 100% !important
@media (max-width: 1600px)
	.pillIcon
		bottom: -95px !important
	.inner-container .row.df.jcc img.mb3rem
		width: 70% !important
	.inner-container 
		.px6rem
			padding: 0
	.keyfeature-box-free 
		.keyfeature-wihte-item:nth-child(2)
			width: 33% !important
	.inner-container 
		padding: 15px 150px !important
	.home-page-bg
		height: 89vh !important
	.main-banner-container.home-page-banner
		height: 93%
	.right-img-area
		height: 330px !important
		margin-top: 14px !important
		.person-img
			height: 140% !important
	.keyfeautre-container
		.keyfeature-box
			margin-bottom: 1.5rem
			padding: 2rem 2rem 0
			.mb2rem
				margin-bottom: 1rem
	#homeMainBanner 
		.mt4rem
			margin-top: 1rem
			.fs2-5rem
				font-size: 2rem
	.main-banner-container
		.pt5rem
			padding-top: 3rem
	.automated-sec.pt4rem
		padding-top: 1rem
	.keyfeautre-container
		width: 1170px
	.keyfeature-wihte-item
		width: 18% !important
		zoom: 0.9
		.fs1rem.c15
			font-size: 13px !important
		.seemore-box
			width: 1107px !important
	.register-container 
		.company-searchbox 
			.pt0-5rem 
				svg
					width: 1.4rem
				input
					padding: 10px 10px 10px 47px
					&.verify-btn
						padding: 8px
	.investorLookup-container
		padding: 0  100px 70px
	.how-its-work .blue-animation
		height: 100px !important
	#homeMainBanner .container
		padding-left: 12.6% !important
		padding-right: 12.6% !important
	.main-banner-container
		.fs1-5rem
			margin-top: 2rem
			font-size: 1.22rem
		.fs2rem
			font-size: 1.5rem
			line-height: 1.8rem !important
		span.c4
			font-size: 38px !important
			line-height: 50px !important
	.automated-sec
		.c15
			font-size: 28px !important
			line-height: 35px !important
	.pricing-container
		padding: 120px 95px
	.automated-sec 
		.w80
			width: 73%
		.w60
			width: 57%
	.inner-container 
		p.fs1-25rem
			font-size: 1rem
	.calcluter-sec 
		.c15
			font-size: 1.7rem
			line-height: 37px !important
		.fs3rem
			font-size: 2.3rem
	.client-review-container
		padding: 45px 200px 60px
	.work-container
		max-width: 930px !important
		.work-card-box
			zoom: 0.8
		.fs1-25rem
			font-size: 1rem
	.not-sure-container
		padding: 100px
	.pricing-title .right-text-for
		padding-right: 8rem
	.angelsnetwork-page-font .pricing-title .right-text-for, 
	.angels-page-font .pricing-title .right-text-for
		padding-right: 6rem
	.vcs-page-font  .pricing-title .right-text-for, 
	.pes-page-font .pricing-title .right-text-for
		padding-right: 4rem
	.pricing-title .left-text-for
		padding-left: 2.1rem
	.visin-sec .w30 img
		height: 61% !important
	.main-banner-container
		padding: 0 165px 0 149px
	.main-banner-container .mt7rem
		margin-top: 4rem
		font-size: 1.30rem
	.main-banner-container .fs2rem
		font-size: 1.1rem
		line-height: 1.4rem !important
	.main-banner-container span.c4,
	.carousel .slide .fs2-125rem
		font-size: 33px !important
		line-height: 47px !important
	.carousel .slide .fs1-25rem
		font-size: 1rem
		line-height: 28px !important
	.client-review-container .fs2-125rem
		font-size: 33px
	.review-img img
		width: 100% !important
		height: auto !important
	.work-container
		.df.jce
			width: 39px !important
		.pb1-5rem.df.jcsb
			min-height: 46px !important
			height: 46px !important
	.poppins-font-style .inner-container .shadowContainer
		zoom: 0.7
	.angelsnetwork-page-font .inner-container .row.df.jcc img
		width: 41% !important
	.carousel.carousel-slider .df .h60
		width: 85px !important
	.pricing-container 
		.pricing-box 
			img.pr.w100
				height: 116px
	.register-container
		.fs2-125rem
			line-height: 35px !important
			font-size: 1.6rem
		.w80
			padding-top: 5px
	.register-container 
		.form-right-image 
			.book-call
				width: 75% !important
		.pt0-5rem.pb0-5rem.pr 
			svg
				top: 50% !important
				transform: translateY(-50%)
		.pr.w50 
			svg
				top: 50% !important
				transform: translateY(-50%)
		.fs1-125rem.pb0-5rem, .pb1rem.gg1rem
			padding: 10px 0 0
		.pb1rem.gg1rem
			label
				font-size: 14px
		.registerButtonAnimation
			margin: 6px 0
	.pricing-container 
		.pricing-box
			zoom: .7
	.video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem
		width: 459px
		font-size: 25px
	.pricing-container .pb1rem.df
		padding-bottom: 0
	.investorLookup-container
		.pb2rem
			padding-bottom: 0
		.investor-box
			margin-top: -56px
	.button-disabled, .investor-search-btn, .investor-back-btn
		zoom: 0.8
	.investor-modal-database 
		.pr
			input
				font-size: 14px
				padding: 10px 15px
	.investor-back-btn
		padding: 10px 29px
		margin: 0
	#menu
		a
			font-size: 14px
		a.w200px
			zoom: 0.8
	.automated-sec .fs1rem-screen1
		font-size: 14px
	.narBarAnimation
		.fs1rem-screen1
			font-size: 14px
	.main-banner-container.home-page-banner 
		.pt2rem
			padding-top: 0
		.fs1-25rem
			font-size: 1rem
	.calcluter-sec
		padding-top: 0
		margin-top: -11px
		.c15
			font-size: 1.4rem
			line-height: 29px !important
	.main-banner-container 
		.col-sm.df.jcc.fdc.gg2rem 
			.fs1-25rem
				font-size: 1rem
	.not-sure-container 
		.scaleAnimation
			zoom: 0.8
	.register-container-not-sure 
		.form-right-image
			right: -27% !important
	.shadowPaperCrasouel 
		span.w30 
			img
				width: 31px !important
		span.w50
			padding: 8px !important
			font-size: 13px
	.client-review-container 
		p
			font-size: 14px
			line-height: 21px !important
	.accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img
		width: 76% !important
	.keyfeautre-container 
		.keyfeature-box-portfolio
			padding: 1.5rem 2rem
	.comapnies-page-font 
		.companies-crousel 
			.first-slide 
				.c15.br30px
					zoom: 0.8
	.comapnies-page-font 
		.review-img 
			img
				width: 74% !important
				height: auto !important
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg, .keyfeautre-container .keyfeature-box
		zoom: .9
	.register-not-sure-sec
		zoom: 0.8
	.not-sure-register 
		.not-sure-container
			padding: 30px 120px 30px 221px !important
	.angels-page-font 
		.client-review-container p
			line-height: 21px !important
	svg.pa.cp
		zoom: 0.7
	.investment-box-pricing
		zoom: 0.7


	#expandHeader .h90vh
		height: 85vh
	.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item
		zoom: 0.8
		.reprot-btn-dropdowns
			width: 250px
	.dahboard-tabs .MuiTabPanel-root .add-btn-dashboard
		zoom: 0.8
	.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon
		font-size: 16px
	.banner-advisor-dashboard
		zoom: 0.8
	.table-permission .table-userPermissions
		zoom: 0.8
	#expandHeader .pr.df a svg, #expandHeader .pr.df span.usn svg
		width: 22px
	#expandHeader .c1.c6.w100.jcsb.td500.df.fdc.jcc.wsn
		left: 5px
		position: relative
	#expandHeader .pr.df a, #expandHeader .pr.df span.usn
		padding: 8px 15px 8px 40px
	.dashboard-tools-area 
		h2
			font-size: 1.7rem
	.progress-dashboard .progress-box
		margin: 0 1rem
	.report-page
		.sidebar-report
			&.repository-sidebar
				.mah100
					&.mya
						&.oya
							&.df
								&.fdc
									&.jcsb
										max-height: 40vh
@media (max-width: 1570px)
	.comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user
		margin-top: -32px !important
	.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
		margin-top: -34px !important
	.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user
		margin-top: -21px !important
	.form-right-image .triangle-vector
		width: 134px
		right: 364px !important
		bottom: -60px !important
	.investorLookup-container 
		.investor-box
			margin-top: -56px
			zoom: 0.6
@media (max-width: 1475px)
	.pillIcon
		bottom: -64px !important
		height: 34px !important
	.ceo-sec .ceo-inner .left-ceo-text blockquote
		font-size: 13px
		line-height: 1rem !important
	.calcluter-sec
		width: 1037px
		.secend-sec-btn
			zoom: 0.7
	.register-container-not-sure
		padding: 100px 170px 14px
	.automated-sec 
		.c15
			font-size: 26px !important
			line-height: 32px !important
		.c14
			font-size: 1rem !important
			line-height: 1.7rem !important
	.client-review-container
		p
			font-size: 0.75rem
			line-height: 22px !important
	#homeMainBanner 
		.container
			max-width: 100% !important
			padding-left: 10.6% !important
			padding-right: 10.6% !important
	.pricing-container
		padding: 120px 30px
	.not-sure-container 
		.fs1-5rem
			font-size: 1.2rem
			line-height: 30px !important
	.carousel-root 
		.carousel-button 
			svg
				width: 84px
	.shadowPaperCrasouel 
		.fs1-5rem
			font-size: 1.4rem
		.bg19
			width: 37px
			height: 37px
			padding: 0
			text-align: center
			svg
				width: 16px
	.inner-container
		padding: 15px 120px !important
		zoom: 0.9
	.fs2-125rem
		font-size: 1.8rem
	.fs2rem
		font-size: 1.75rem
	.fs1-25rem
		font-size: 1rem
	.client-review-container
		padding: 45px 256px 60px !important
		.pt1rem.pb1rem
			padding-top: 0.5rem
			padding-bottom: 0.5rem
			input
				padding: 0.7rem
			.w50
				svg
					top: 17px !important
			svg
				top: 24px !important
			.fs1-125rem
				font-size: 1rem
		label.pt2rem.pb2rem
			padding-top: 1rem
			padding-bottom: 1rem
		button
			margin-top: 1rem
			margin-bottom: 1rem
	.register-container .pr10rem
		padding-right: 3rem
	#homeMainBanner .container:nth-child(odd) .w60
		padding-left: 8rem
	#homeMainBanner .container:nth-child(2n) .w60
		padding-right: 5rem
	.calcluter-sec .c15
		font-size: 1.5rem
		line-height: 38px !important
	.calcluter-sec .fs3rem
		font-size: 2rem
	.register-container .pr8rem
		padding-right: 3rem
	.about-carousal-slider .slide
		zoom: 0.8
	.action-sec
		padding: 7rem 5rem !important
	.accounts-page-font 
		.work-container
			.fs1-5rem
				font-size: 1.3rem
			.fs1rem
				font-size: 0.85rem
	.fs3-125rem
		font-size: 2.8rem
	.card-style-about
		padding: 1rem 2rem 7rem !important
	.beta-logo
		width: 61px
		height: 37px
		margin-top: 8px
	.comapnies-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem,
	.accounts-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem,
	.lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem,
	.lawyers-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
		width: 200px
	.comapnies-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem,
	.vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
		width: 148px
	.comapnies-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem
		width: 350px
	.comapnies-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem,
	.comapnies-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, 
	.comapnies-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem,
	.accounts-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem,
	.accounts-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem,
	.lawyers-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem,
	.lawyers-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem,
	.vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem,
	.vcs-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem,
	#homeMainBanner .container:nth-child(8) .w60 .fs1-5rem,
	#homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem
		width: 153px
	.comapnies-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem,
	.accounts-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
		width: 200px
	.comapnies-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem
		width: 170px
	.accounts-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem,
	.lawyers-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem,
	.lawyers-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem,
	.vcs-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem,
	#homeMainBanner .container:nth-child(4) .w60 .fs1-5rem,
	.vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem,
	#homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem
		width: 270px
	.lawyers-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem
		width: 224px
	.calcluter-sec 
		.fs2-5rem
			font-size: 1.6rem
		.fs2rem
			font-size: 1.5rem
	#homeMainBanner .container .testmonial-user
		margin-top: 0 !important
	.comapnies-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
		width: 212px
	.angels-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem
		width: 126px
	.client-review-container p
		font-size: 12px
		line-height: 17px !important
	.vcs-page-font .client-review-container p
		font-size: 11px
		line-height: 16px !important
	#openLoginPopup
		width: 150px !important
		zoom: 0.8
	.registerButtonAnimation, .see-aal-features
		zoom: 0.8
	.register-container 
		.registerButtonAnimation
			width: auto !important
			padding: 9px 40px !important
	.register-container 
		button.fs1-25rem
			width: auto !important
			padding: 9px 40px !important
			zoom: 0.8
			height: 100% !important
	.calcluter-sec
		padding-top: 1rem
	.comapnies-page-font 
		.review-img 
			img
				width: 84% !important
	.main-banner-container.home-page-banner
		padding-top: 29px
		.pt4rem
			padding-top: 1rem
			.fs2-5rem
				font-size: 1.5rem
		.fs1-25rem
			font-size: 13px
	.main-banner-container.home-page-banner .fs3rem, .main-banner-container.home-page-banner .fs3rem .c4
		margin-top: 0
		font-size: 58px !important
		line-height: 62px !important
	.right-img-area
		width: 40% !important
		height: 294px !important
		margin-top: 100px !important
	.main-banner-container .col-sm.df.jcc.fdc.gg2rem
		padding-right: 2rem
		width: 60% !important
	.main-banner-container 
		.fs3-5rem 
			.fs5rem
				font-size: 3.8rem
	.calcluter-sec 
		.c15
			line-height: 29px !important
			font-size: 1.3rem
		.w60 
			a
				zoom: 0.8
	.main-banner-container 
		.fs3-5rem
			font-size: 2.4rem
			line-height: 50px !important
		.fs1-75rem
			font-size: 1.30rem
	.home-page-menu.automated-sec
		.menu-bottom
			margin-top: -76px !important
	.main-banner-container 
		.col-sm.df.jcc.fdc.gg2rem 
			.fs1-25rem
				font-size: 15px
	.register-not-sure-sec 
		.not-sure-register 
			.not-sure-container
				.fs2rem
					font-size: 1.5rem
					line-height: 26px !important
				p
					font-size: 14px
	.inner-container 
		.px6rem
			padding-left: 1rem
			padding-right: 1rem
	.register-container-not-sure 
		.form-right-image
			right: -33% !important
			.book-call
				width: 60% !important
	.inner-container .df.jce
		width: 16% !important
		gap: 8px !important
		justify-content: center
		margin: 0 auto
		img.h70
			height: 64%	
	.comapnies-page-font .companies-crousel .first-slide .c15.br30px .icon .w30px
		width: 22px
	.inner-container 
		.df.jce.right-icon-btn
			width: 70% !important
			gap: 0 !important
			justify-content: end
	.shadowPaperCrasouel .fs1-5rem
		font-size: 1.3rem
		line-height: 30px !important
	.bottom-button-slider
		zoom: 0.8
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item
		zoom: 0.8
	.keyfeautre-container
		width: 1060px
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem
		padding-top: 5rem
	.keyfeature-wihte-item
		zoom: .8
	.accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img
		width: 300px !important
		height: auto !important 
	

	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item
		zoom: 0.7
		width: 29% !important
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .pt10rem
		padding-top: 8rem
	.dropdown-company-dahboard .w60px.mr2rem
		margin-right: 1rem
	.dropdown-company-dahboard .w100 .w200px
		width: 150px
	.progress-box-vertical-dashboard
		zoom: 0.8
	.dahboard-tabs .MuiTabPanel-root .fs1-75rem
		font-size: 1.5rem
	.dahboard-tabs .MuiTabPanel-root .fs1-5rem
		font-size: 1.25rem

	.dropdown-company-dahboard
		zoom: .82
	.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon
		font-size: 14px
		margin: 0 16px
	.dahboard-tabs .MuiTabPanel-root .py2rem
		padding-top: 1rem
	.dahboard-tabs .MuiTabPanel-root .pr9rem
		padding-right: 5rem
	.dropdown-company-dahboard .w250px
		width: 200px

	.report-page
		.sidebar-report
			&.repository-sidebar
				.mah100
					&.mya
						&.oya
							&.df
								&.fdc
									&.jcsb
										max-height: 51vh
				.user-info-sidebar
					zoom: 0.8
				.additional-sections-button
					zoom: 0.8
	.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-box-company-pro .w250px.pricing-tooltbox
		width: 213px
		zoom: 0.9
	.company-dashboard-view .dahboard-tabs .MuiTabPanel-root .all-reprots-area
		margin: 0 12px 0 0
@media (max-width: 1400px)
	.home-page-banner 
		.col-sm.df.jce.pr
			width: 45% !important
	.keyfeautre-container
		width: 1070px
	.keyfeature-wihte-item
		margin: 0 10px 30px !important
		.fs1rem.c15
			font-size: 12px !important
		.seemore-box
			width: 999px !important
	.investorLookup-container
		padding: 0  70px 70px
	.main-banner-container 
		span.c4
			font-size: 30px !important
			line-height: 38px !important
	.automated-sec 
		.c15
			font-size: 24px !important
			line-height: 28px !important
		.c14
			font-size: 1rem !important
			line-height: 1.3rem !important
			padding-top: 1rem
	.calcluter-sec
		.fs2rem
			font-size: 1.5rem
	.fs2-125rem
		font-size: 1.75rem
	.fs2rem
		font-size: 1.50rem
	.fs1-5rem 
		font-size: 1rem
	.fs1rem
		font-size: 0.75rem
	.work-container
		padding: 70px 160px
		.pt1-5rem.pb1rem
			padding: 0.5rem 0
		.pb1-5rem.df.jcsb
			min-height: 50px !important
			height: 50px !important
	.client-review-container
		p
			font-size: .65rem
			line-height: 17px !important
	.video-container 
		p.fs2-125rem
			line-height: 40px !important
	.pricing-container 
		.pl8rem.pr8rem
			padding: 0 4rem
	.not-sure-container 
		.fs1-5rem
			font-size: 1rem
			line-height: 30px !important
		.mt1rem 
			button
				font-size: 14px
				padding: 10px 22px !important
		.bsflp.bg1.br10px
			padding: 27px 15px
			.pa.df.jcc.aic.p2rem.bg3
				padding: 1rem
				top: -33px !important
				img
					width: 100%
		.mt7rem.mb3rem
			margin-top: 4rem
			margin-bottom: 2rem
	#homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, 
	#homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, 
	#homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, 
	#homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, 
	#homeMainBanner .container:nth-child(12) .w60 .fs1-5rem
		width: 200px
	#homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, 
	#homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, 
	#homeMainBanner .container:nth-child(10) .w60 .fs1-5rem
		width: 120px
	#homeMainBanner .container:nth-child(8) .w60 .fs1-5rem
		width: 112px
	#homeMainBanner .container:nth-child(9) .w60 .fs1-5rem
		width: 147px
	#homeMainBanner .container .w60 .fs1-5rem
		font-size: 1.2rem !important
	#homeMainBanner .container .custom-list li
		font-size: 17px
	.work-container .pt1-5rem.pb1rem
		font-size: 1rem
		line-height: 27px !important
	#homeMainBanner .container
		max-width: 100% !important
		padding-left: 10% !important
		padding-right: 10% !important
	.automated-sec .w80
		width: 70%
	.client-review-container 
		p
			font-size: .75rem
			line-height: 19px !important
	.fs1-25rem
		font-size: 1rem
	.register-container .pr8rem
		padding-right: 4rem
	.story-sec
		padding: 3rem 5rem !important
	.accounts-page-font 
		.work-container
			padding: 70px 110px
	.accounts-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem
		width: 245px
	.not-sure-container p.w100
		width: 100%
		margin-bottom: 30px
	.lawyers-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem
		width: 254px
	#homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
		width: 225px
	#homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem
		width: 195px
	.client-review-container .mb2-5rem
		margin-bottom: 1rem !important
	.client-review-container .mt4rem
		margin-top: 2rem
	.angelsnetwork-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem,
	.angels-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
		width: 200px
	.angels-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem
		width: 140px
	.angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem
		width: 263px
	.angels-page-font #homeMainBanner .container:nth-child(13) .w60 .fs1-5rem
		width: 243px
	.main-banner-container.home-page-banner
		padding-top: 10px
	.home-page-bg
		height: 89vh !important	
	.right-img-area
		width: 42% !important
		height: 290px !important
		margin-top: 211px !important
	.main-banner-container 
		.col-sm.df.jcc.fdc.gg2rem 
			.fs1-25rem
				font-size: 14px
	.shadowPaperCrasouel 
		.fs1rem
			font-size: 1rem
	.comapnies-page-font 
		.companies-crousel 
			.first-slide 
				.new-shadow 
					.icon
						width: 18px
	.register-container-not-sure 
		.form-right-image
			bottom: -6px !important
			right: -35% !important
	.register-not-sure-sec 
		.not-sure-register 
			.not-sure-container 
				h1
					font-size: 1rem
	.accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img
		width: 100% !important
	.main-banner-container 
		.fs3-5rem
			font-size: 2.2rem
			line-height: 50px !important
	.pricing-table-saf
		width: 1090px
	.pricing-tabs 
		.booking-sec 
			.pricing-bottom-box
				zoom: 0.8 
		.pricing-advisor-table 
			.pricing-box
				width: 20%
				.shadow.bg1
					zoom: 0.7
	.pes-page-font 
		.review-img 
			img
				width: 100% !important
	.angelsnetwork-page-font 
		.review-img 
			img
				margin-top: 40px
				width: 100% !important
	.top-pricing-area
		top: -99px
		zoom: 0.8
		.w100.df.jcc 
			.br100
				zoom: 0.9


	.banner-advisor-dashboard, .table-permission .table-userPermissions
		zoom: .7
	.progress-area.progress-dashboard
		zoom: 0.9
	.dahboard-tabs .MuiTabPanel-root .pr9rem
		padding-right: 5rem
		padding-left: 4rem
	.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item
		min-height: 170px
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item
		width: 30% !important
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .pt10rem 
		padding-top: 5rem
	.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item
		width: 22% !important
@media (max-width: 1380px)
	.businessCardText
		font-size: 8px
	.right-img-area
		height: 235px !important
		margin-top: 73px !important
	.main-banner-container 
		.col-sm.df.jcc.fdc.gg2rem 
			.fs1-25rem
				font-size: 13px
	.main-banner-container 
		.gg2rem
			grid-gap: 1rem
		.fs3-5rem 
			font-size: 2rem
			line-height: 39px !important
			.fs5rem
				font-size: 3rem
	.calcluter-sec
		margin-top: -32px
		.c15
			font-size: 1.2rem
			line-height: 25px !important
		.p2rem
			padding: 1rem
	.automated-sec .w60
		width: 63%
	.client-review-container
		padding: 45px 233px 60px !important
	.work-container
		padding: 70px 100px
		.pt1-5rem.pb1rem
			font-size: 0.75rem
			line-height: 27px !important
	.fs1-25rem
		font-size: 0.98rem
	#homeMainBanner .container
		max-width: 100% !important
		padding-left: 11% !important
		padding-right: 11% !important
	.privacy-policy-page 
		.w60 
			.fs3-5rem
				top: 142px !important
	.privacy-policy-page 
		.policy-box
			padding: 0px 60px 60px 60px
	.team-sec
		padding: 3rem 5rem 1rem !important
		.card-style-about
			padding: 1rem 2rem 5rem !important
		ul 
			li
				font-size: 0.75rem
		button
			padding: 0.7rem 2.125rem !important
			img
				width: 30px
		.fs3-125rem
			font-size: 2.5rem
	.action-sec .fs2-5rem
		font-size: 2rem
		line-height: 2.6rem !important
	.accounts-page-font
		.work-container
			padding: 70px 85px
			.how-its-work-circle
				top: -24px !important
				left: -27px !important
	.calcluter-sec 
		.fs3rem
			font-size: 1.8rem
	.main-banner-container .mt7rem
		margin-top: 2rem
		font-size: 1.2rem
	.work-container .fs2-125rem
		font-size: 39px
	.not-sure-container p.w100
		font-size: 12px !important
	.automated-sec .c15
		font-size: 23px !important
		line-height: 28px !important
	.carousel .slide .fs1-25rem
		font-size: 12px
		line-height: 21px !important
		width: auto
	.shadowPaperCrasouel .fs1-5rem
		font-size: 1.2rem
		line-height: 27px !important
	#homeMainBanner .fs2-5rem
		font-size: 2rem
	.investor-box
		zoom: 0.8
	.investor-box
		.list-right-area
			.benefits-box
				min-height: 276px
				line-height: 19px !important
	.custom-list li
		font-size: 13px !important
	.investor-box
		zoom: .65
	.calcluter-sec 
		.fs2rem
			font-size: 1.2rem
	.main-banner-container span.c4, .carousel .slide .fs2-125rem
		font-size: 25px !important
		line-height: 29px !important
	.comapnies-page-font 
		.silder-box
			padding: 1rem 0
		.review-img
			img
				width: 73% !important
	.not-sure-container 
		.mt6rem.mb3rem
			margin-top: 3rem
			margin-bottom: 0
		.p2rem.bg3
			zoom: 0.7
		.mt5rem
			margin-top: 3rem
	.calcluter-sec 
		.fs2-5rem
			font-size: 1.4rem
	.carousel.carousel-slider 
		.df 
			.h60
				width: 68px !important
	.login-container .MuiFormControl-root p
		bottom: -25px
	.shadowPaperCrasouel 
		span.w30
			padding: 10px 4px !important
			height: 100% !important
	.keyfeature-box-portfolio-bg 
		.w30
			padding-left: 5rem
	.register-container
		padding: 35px 0 36px 127px
	.video-container
		padding: 50px 230px
	.keyfeature-box-portfolio-innner 
		.keyfeature-box-portfolio-bg 
			.keyfeature-wihte-item
				padding: 1rem
	.for-yoy-box
		font-size: 0.7rem
	.keyfeature-box-portfolio-bg 
		.w30 
			p.pr3rem
				padding-right: 1rem
	.keyfeautre-container
		width: 1000px
	.poppins-font-style 
		.inner-container 
			.shadowContainer
				zoom: .6
	.fs2-125rem 
		font-size: 1.65rem
	.inner-container .df.jcc.aic img.h60
		height: 112px !important
	.keyfeautre-container .keyfeature-box-portfolio
		justify-content: center
	.fs1-75rem
		font-size: 1.25rem
	.df.bg1.pr.brne.bscov.h90vh
		height: 100%
	.mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80
		zoom: 0.8
	.angels-page-font .client-review-container p
		line-height: 17px !important
		font-size: 12px

	.dashboard-tools-area h2
		font-size: 1.4rem
	#expandHeader .h90vh
		height: 82vh
	#expandHeader:hover #logo
		width: 7rem
	#expandHeader #logo
		margin-bottom: 0.4rem
	.advisor-dashboard-seemore .m1rem.pr.br10px.pr
		zoom: 0.8
	.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details
		bottom: 18px
	.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details img, 
	.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details img
		width: 80%
	.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details .lock-icon .w60px, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details .lock-icon .w60px
		width: 55px
	.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .bottom-details .lock-icon svg, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details .lock-icon svg
		width: 45px
	.box-dashbord-container .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item .report-bottom-area .report-btn svg, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .report-bottom-area .report-btn svg, .company-dashboard-view .dahboard-tabs .MuiTabPanel-root .keyfeature-wihte-item .report-bottom-area .report-btn svg, .right-info-hyper-icon svg
		width: 25px !important
		height: 25px
	.report-page
		.sidebar-report
			&.repository-sidebar
				.mah100
					&.mya
						&.oya
							&.df
								&.fdc
									&.jcsb
										max-height: 43vh
										button 
											span
												font-size: 9px
	.login-page
		height: 100%
		.login-container
			padding: 122px 0 120px 170px
	.register-page
		height: 100%
@media (max-width: 1280px)
	.inner-container 
		p.fs1-25rem
			font-size: 0.85rem
			line-height: 1.5rem !important
	#homeMainBanner .container
		max-width: 100% !important
		padding-left: 10% !important
		padding-right: 10% !important
	.privacy-policy-page
		.w60
			width: 70%
		.policy-box
			p
				line-height: 24px !important
	.visin-sec 
		padding-bottom: 3rem
		.container
			align-items: center
		.w30 
			img
				height: 100% !important
				margin-top: 106px !important
				width: 100%
	.accounts-page-font
		.work-container
			padding: 70px 70px
	.automated-sec .c15
		font-size: 20px !important
		line-height: 24px !important
	.client-review-container p
		font-size: .65rem
		line-height: 15px !important
	#homeMainBanner .container
		padding-top: 1.5rem
		padding-bottom: 1.5rem
	.quote-area p
		font-size: 9px
	.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
		margin-top: -21px !important
	.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user
		margin-top: -9px !important
	.home-page-bg
		height: 86vh !important
	.register-container-not-sure 
		.pr8rem
			padding-right: 11rem
	.main-banner-container 
		.col-sm.df.jcc.fdc.gg2rem 
			.fs1-25rem
				font-size: 12px
	.not-sure-container 
		.scaleAnimation 
			a
				zoom: 0.7
	.register-container-not-sure 
		.form-right-image
			bottom: -6px !important
			right: -37% !important
	.inner-container
		padding: 11px 118px !important
		.px6rem
			padding-left: 0
			padding-right: 0
	.df.bg1.pr.brne.bscov.h90vh
		height: 95vh
	.home-page-menu.automated-sec .menu-bottom
		margin-top: -51px !important
		margin-bottom: 15px
	.inner-container .df.jce img.h70
		height: 101px
	.comapnies-page-font .companies-crousel .first-slide .c15.br30px
		zoom: .7
	.client-review-container .fs2-125rem
		font-size: 27px
	.angels-page-font .client-review-container p
		line-height: 18px !important
	.comapnies-page-font 
		.review-img img
			width: 100% !important
	.shadowPaperCrasouel
		width: 100%
	span.wid-btn
		width: 60%

	.progress-area.progress-dashboard
		zoom: 0.8
	.dropdown-company-dahboard .reprot-btn-dropdowns
		width: 231px
	.dropdown-company-dahboard
		zoom: .77
	.dahboard-tabs .MuiTabPanel-root .add-btn-dashboard
		zoom: .7
	.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon
		font-size: 14px
	.dahboard-tabs .MuiTabPanel-root .fs1-5rem
		font-size: 1.1rem
	.dahboard-tabs .MuiTabPanel-root .fs1-75rem
		font-size: 1.35rem
@media (max-width: 1250px)
	.automated-sec
		.w80
			width: 66%
		.c14
			font-size: 0.9rem !important
	.inner-container
		padding: 11px 90px !important
	.client-review-container
		padding: 45px 124px 60px !important
	.work-container
		padding: 70px 167px
	.not-sure-container
		padding: 100px 118px
	.accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img
		width: 100% !important
	.main-banner-container 
		.fs3-5rem
			font-size: 1.8rem
			line-height: 39px !important
	.video-container 
		img.w10.pa
				width: 60px !important
@media (max-width: 1199px)
	.calcluter-sec
		width: 88%
	.poppins-font-style
		overflow: hidden
	.investor-box
		zoom: .7
	.calcluter-sec 
		.c15
			font-size: 1.2rem
			line-height: 26px !important
		.fs3rem
			font-size: 1.5rem
			line-height: 35px !important
	.inner-container
		padding: 11px 155px !important
	.work-container
		padding: 70px 97px
	#homeMainBanner .container:nth-child(odd) .w60
		padding-left: 10rem
	#homeMainBanner .container:nth-child(2n) .w60
		padding-right: 6rem
	#homeMainBanner .container .w90 .fs1-25rem
		font-size: .8rem
		line-height: 21px !important
	#homeMainBanner .container .w100.pr.df
		margin-top: 10px
	.video-container
		padding: 84px 123px 0
	.register-container
		padding: 70px 0 70px 95px
		.pr8rem
			padding-right: 0
	.triangle-vector
		right: 50% !important
		width: 150px
	.pricing-container .pl8rem.pr8rem
		padding: 0 0rem
	.pricing-container .pricing-box
		width: 21%
	.privacy-policy-page 
		.w60 
			width: 82%
	.fs3-5rem
		font-size: 3rem
	.terms-and-conditions-page 
		[style*="width: 41%"]
			width: 60% !important
	.our-thesis-sec img.ar.pa
		width: 68px
	.visin-sec
		.fs3rem
			font-size: 2.5rem
	.about-carousal-slider .slide
		zoom: 0.6
	.action-sec .fs2-5rem
		font-size: 1.8rem
		line-height: 2.4rem !important
	.accounts-page-font
		.work-container
			padding: 70px 30px
			.fs1-5rem
				font-size: 1.1rem
			.df.jcsb 
				.pb1-5rem.df.jcsb
					flex-wrap: wrap
					align-items: center !important
					.w80
						width: 90%
					.df.jce
						width: 20px !important
	.how-its-work 
		.blue-animation
			height: 15% !important
	.comapnies-page-font .main-banner-container, .accounts-page-font .main-banner-container, .lawyers-page-font .main-banner-container, .vcs-page-font .main-banner-container, .pes-page-font .main-banner-container, .angelsnetwork-page-font .main-banner-container, .angels-page-font .main-banner-container
		hight: 56.5vh
	.right-img-area
		height: 215px !important
		margin-top: -22px !important
	.register-container-not-sure
		padding: 106px 191px 28px 57px
	.main-banner-container 
		.col-sm.df.jcc.fdc.gg2rem 
			.fs1-25rem
				font-size: 11px
	.inner-container
		padding: 11px 103px !important
	.keyfeature-box-portfolio-bg 
		.w30
			padding-left: 5rem
			padding-top: 6rem
	.pricing-table-saf
		width: 760px
	.beta-logo
		width: 61px
		height: 37px
		margin-top: 0
		position: absolute
		left: 283px
		top: 17px
	.pricing-tabs 
		.pricing-advisor-table 
			.pricing-box
				width: 46%
	.pricing-plan-page 
		.w60.fs3-125rem
			font-size: 2.5rem
			padding-left: 9rem !important
		.df.w100.pt6rem
			padding-top: 2rem
	.slider-wrapper .first-slide .inner-container img.pa.ar.zi1
		width: 40px !important
	.register-page-container
		width: 90%
		padding: 5px 0 5px 100px
	.right-img-area .person-img
		height: 115% !important
@media (max-width: 1099px)
	.client-review-container
		padding: 45px 59px 60px !important
	.work-container
		padding: 70px 45px
	.bottom-features
		width: 100%
	.team-sec
		padding: 2rem 30px 1rem
	.our-thesis-sec
		padding-left: 3rem
	.visin-sec .container
		padding: 0 2rem !important
	.accounts-page-font
		.work-container 
			.df.jcsb
				.pb1-5rem.df.jcsb
					flex-wrap: nowrap
			.fs1-5rem
				font-size: 1rem

@media (max-width: 1024px)
	.home-page-bg
		height: 600px !important
	.main-banner-container 
		.fs3-5rem 
			.fs5rem
				font-size: 2.5rem
	.right-img-area
		height: 212px !important
		margin-top: 39px !important
		.person-img
			height: 118% !important
	.main-banner-container 
		.col-sm.df.jcc.fdc.gg2rem
			padding-right: 3rem
	.calcluter-sec 
		.c15
			font-size: 1rem
			line-height: 23px !important
		.fdc.pl2rem
			padding-left: 0 
	.keyfeautre-container
		width: 74%
	.comapnies-page-font .keyfeautre-container
		width: 82%
	.main-banner-container 
		.fs3-5rem
			font-size: 1.4rem
			line-height: 39px !important
	.video-container
		padding: 32px 123px
	.slider-wrapper 
		.first-slide 
			.inner-container 
				img
					width: 70% !important
	.poppins-font-style .inner-container .shadowContainer
		zoom: .5
	.inner-container .df.jcc.aic img.h60
		height: 74px !important
	.angels-page-font .inner-container img.mb6rem
		width: 89% !important
		margin-bottom: 0
	.main-banner-container .col-sm.right-img-area .cubes-img
		left: -37px !important
		top: 50px !important
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg, .keyfeautre-container .keyfeature-box
		zoom: .8
	.keyfeature-wihte-item 
		width: 17% !important
	.work-container
		zoom: 0.8
	.not-sure-register .not-sure-container
		padding: 30px 96px 30px 173px !important
	.register-not-sure-sec
		zoom: .6
	.client-review-container
		zoom: 0.95
	.keyfeature-box-free .keyfeature-wihte-item:nth-child(2)
		width: 38% !important
	.comapnies-page-font 
		.review-img img
			width: 64% !important
	.keyfeature-wihte-item
		zoom: .7
	.df.bg1.pr.brne.bscov.h90vh
		height: 100%
		zoom: 0.6
	.msg-btn, .top-arrow-bt
		zoom: 0.8
	.registration-inner .registration-box
		width: 50%
@media (max-width: 991px)
	.partners-page .w60
		font-size: 25px !important
	.partners-page .w70.df.fdc.jcc
		zoom: 0.8
	.register-page-container
		width: 111%
		padding: 5px 0 5px 100px
	.login-page 
		.login-container 
			.pr8rem
				padding-right: 0
	.home-page-bg
		height: 100% !important
	.home-page-menu.automated-sec .menu-bottom
		margin-top: 0 !important
	.home-page-banner .col-sm.df.jce.pr
		width: 60% !important
		justify-content: center
		margin: 0 auto
	.comapnies-page-font .companies-crousel .first-slide .c15.br30px
		width: 73%
	.investor-box
		zoom: 0.6
	#homeMainBanner .container
		padding-left: 30px !important
		padding-right: 30px !important
	.main-banner-container
		.col-sm
			width: 100% !important
			justify-content: center
			&.right-img-area
				margin: 24px auto 0
				.person-img
					margin: 0 auto
		.row
			flex-wrap: wrap !important
	.automated-sec 
		.menu-bottom
			width: 85%
	.automated-sec 
		.c15
			font-size: 19px !important
			line-height: 25px !important
		.w60
			width: 92%
			padding: 10px
			border-radius: 10px
	.right-side-zak
		width: 94px
		height: auto !important
	.calcluter-sec
		padding: 2rem 3rem
		.w100
			width: 91%
			margin: 0 auto
	.landing-page-font 
		.inner-container 
			padding: 11px 102px !important
			.shadowContainer
				border-radius: 14px
				padding: 20px
	.client-review-container
		padding: 45px 33px 60px !important
		.pr3rem.pl3rem
			padding: 1.5rem 1.5rem 0
		.w60
			padding-right: 0
	#homeMainBanner .container:nth-child(odd) .w60
		padding-left: 5rem
	#homeMainBanner .container:nth-child(2n) .w60
		padding-right: 3rem
	#homeMainBanner .row.pl2rem.pr2rem.w50
		width: 100%
	.pricing-container 
		.pricing-box
			width: 30%
		.pl8rem.pr8rem
			padding: 0
	.ar.pa.blue-circle
		right: 29px !important
	.privacy-policy-page 
		.policy-box
			padding: 0px 30px 40px 30px
		.w60
			width: 90%
			border-radius: 10px
			.fs1rem-screen1
				font-size: 0.75rem
	.privacy-policy-page 
		.w60 
			.fs3-5rem
				font-size: 2.5rem
		.policy-box 
			.pb2rem
				padding-bottom: 1.3rem
	.our-thesis-sec 
		padding-left: 30px
		padding-right: 30px
		.w60, .w40
			width: 100%
	.visin-sec 
		.container
			align-items: center
			padding: 0 30px !important
			.w60
				width: 100%
				padding-right: 0
			.w30
				width: 100%
				text-align: center
				img
					width: 62%
					height: 100% !important
					margin: 20px auto 0 !important
	.story-sec
		padding: 3rem !important
	.team-sec 
		.dg.aic
			display: flex
			align-items: center
			flex-wrap: wrap
			justify-content: center
			.df.fdc
				width: 47%
				justify-content: center
				margin-bottom: -150px
	.about-carousal-slider .slide
		zoom: .4
	.action-sec.dg
		display: flex
		flex-wrap: wrap
		.left-area 
			width: 100%
			margin-bottom: 22px
		.right-area
			width: 39%
			margin: 0 auto
	.accounts-page-font 
		.work-container 
			.df.jcsb
				flex-wrap: wrap
				.work-card-box
					width: 24%
					margin-bottom: 28px
	.our-thesis-sec 
		.w40
			margin-right: -60px
			margin-top: -63px
	.our-thesis-sec img.ar.pa
		width: 39px
	.card-style-about
		padding: 20px 30px 60px !important
	.story-sec h4
			font-size: 2.5rem !important
	.narBarAnimation
		display: none
	.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
		margin-top: -8px !important
	.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user,
	comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user
		margin-top: 1px !important
	.main-banner-container 
		.col-sm.df.jcc.fdc.gg2rem
			padding-right: 0
			width: 100% !important
	.keyfeature-wihte-item
		width: 17% !important
	.work-container
		max-width: 75% !important
		zoom: 0.7
	.slider-wrapper 
		.first-slide 
			.inner-container 
				img.pa.ar.zi1
					width: 52px !important
	.calcluter-sec 
		.w60 
			a
				text-align: center
				padding-left: 2rem
	.pricing-plan-page 
		.w60.fs3-125rem
			font-size: 2rem
			padding-left: 8rem !important
	.inner-container .df.jcc.aic img.h60
		height: 100% !important
	.df.bg1.pr.brne.bscov.h90vh
		height: 100%
	.shadowPaperCrasouel
		width: 100%
	.inner-container
		padding: 11px 66px !important
	.inner-container .df.jcc.aic img.h60
		height: 120px !important
	.accounts-page-font .client-review-container
		zoom: 0.7
	.pricing-tabs 
		.booking-sec
			width: 102% !important
	.contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df
		zoom: 0.7
@media (max-width: 860px)
	.keyfeature-box-portfolio-innner:before, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before
		width: 93%
		left: 0
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before
		left: 21px
	.keyfeature-wihte-item
		width: 38% !important
	.work-container 
		.df.jcsb 
			justify-content: center
			flex-wrap: wrap
			.work-card-box
				width: 40%
				margin-bottom: 30px
		.df.jcc
			flex-wrap: wrap
			.work-card-box
				width: 40%
				margin: 0 15px 34px
	.slider-wrapper 
		.first-slide 
			.inner-container 
				img
					width: 44% !important
	.pricing-table-saf
		width: 570px
	.pricing-tabs 
		.booking-sec
			flex-wrap: wrap
			margin-top: -8px
			.pricing-bottom-box
				width: 45%
				margin: 15px 0 0 !important
				width: 100%
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30
		width: 25%
		padding-left: 1rem
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w70
		width: 75%
	.inner-container
		padding: 11px 58px !important
		zoom: 0.7
@media (max-width: 790px)
	.login-page
		flex-wrap: wrap
		height: 100%
		.login-container
			width: 100%
			padding: 100px 50px 50px
		.form-right-image
			width: 100% !important
			height: 373px
	.investorLookup-container
		padding: 0 30px 70px
	.investor-box
		zoom: 0.5
	.main-banner-container
		padding: 0 45px 
	.new-header 
		.container
			padding: 0 45px !important
	.calcluter-sec 
		.c15
			font-size: 1rem
			line-height: 23px !important
		.fs3rem
			margin-top: 1rem
			font-size: 1.2rem
			line-height: 27px !important
	.landing-page-font 
		.silder-box
			padding: 1rem
		.inner-container 
			p.fs1-25rem
				font-size: .75rem
				line-height: 1.3rem !important
	.work-container
		padding: 45px 30px
		.jcsb
			justify-content: center
			flex-wrap: wrap
			.work-card-box
				width: 33%
				margin: 0 15px 25px
	#homeMainBanner 
		.container
			.w60
				width: 100%
	#homeMainBanner .container:nth-child(odd) .w60,
	#homeMainBanner .container:nth-child(2n) .w60
		padding: 0rem
	#homeMainBanner .container .w40
		margin: 0 auto
		width: 60%
	#homeMainBanner .container .w100.pr.df
		margin-top: 0
		width: 100%
		justify-content: center !important
	#homeMainBanner .container .w100.pr.df .br40px.pt1rem.pb1rem.pl3rem.pr2rem
		width: 100% !important
	.video-container
		padding: 49px 30px 0
	.not-sure-container
		.df.jcsb
			flex-wrap: wrap
			justify-content: center
			.scaleAnimation
				margin-bottom: 43px
	#homeMainBanner .container .w50,
	#homeMainBanner .container .br40px.pt1rem
		width: 100% !important
	.beta-logo
		position: absolute
		left: 21%
		top: 15%
	.calcluter-sec
		padding: 1rem 1rem
		zoom: 0.8
	.inner-container 
		.df.jce.aic
			width: 55% !important
			justify-content: center
			margin: 0 auto
			gap: 5px !important
	.keyfeature-box-free 
		.keyfeature-wihte-item:nth-child(2)
			width: 100% !important
			.df.fw500.fs1rem.c15
				justify-content: space-between
				align-items: center
	.keyfeature-wihte-item
		width: 39% !important
	.work-container .df.jcsb .work-card-box, .work-container .df.jcc .work-card-box, .accounts-page-font .work-container .df.jcsb .work-card-box
		width: 38%
	.main-banner-container 
		.fs1-75rem
			font-size: 1rem
	.df.bg1.pr.brne.bscov.h90vh
		height: 100%
	.pricing-plan-page 
		.w60.fs3-125rem 
			padding-left: 3rem !important
	.pricing-plan-page .df.fdc.aic.mt1rem
		width: 50% !important
	.pricing-plan-page .w70 .df.jcsb.w100, .pricing-plan-page .w80 .df.jcsa.w100
		justify-content: center
		flex-wrap: wrap
	.register-page
		flex-wrap: wrap
		height: 100%
		.register-page-container
			width: 100%
			padding: 5px 38px
			box-sizing: border-box
			.pr8rem
				padding-right: 0
		.form-right-image
			width: 100% !important
			position: relative
			height: 400px
	.inner-container .df.jcc.aic img.h60
		height: 109px !important
	.pricing-tabs .booking-sec
		width: 100% !Important
	.pricing-tabs 
		.booking-sec 
			.pricing-bottom-box
				min-height: auto
	.pricing-tabs 
		.booking-sec 
			.pricing-bottom-box 
				p
					padding: 0 0 10px
	.tabs-portfolio 
		.pt10rem
			padding-top: 9rem
	.partners-page 
		.w70.df.fdc.jcc
			width: 100% !important
		.w60
			width: 100% !important
		.df.w100 
			flex-wrap: wrap
			justify-content: center
		.w40
			width: 100%
			background-size: contain
			background-position: top right
			padding-right: 0
			img
				margin: 0 auto
				text-align: center
				width: 50% !important
		.w30
			width: 100%
			margin: 0 auto
		.df.w100 
			.df
				flex-wrap: wrap
				img
					width: 50% !important
	.partners-page  .df.w100 .w60.fw500
		width: 100% !important
		padding-left: 3rem !important
	.partners-page .df.w100 .df .cp.bg4.br40px.df.jcsb.aic.pl1rem.pr1rem.mt1-5rem
		flex-wrap: nowrap !important
		width: 152px !important
		margin-bottom: 25px
	.contact-us-page 
		.df.w100 
			flex-wrap: wrap
			.w60.fs2-5rem
				font-size: 2rem
				padding-left: 0rem !important
				width: 100%
				text-align: center
				justify-content: center
				margin-bottom: 38px
	.contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df
		zoom: .5
	.beta-logo
		top: 21%
		left: 23%
@media (max-width: 690px)
	.calcluter-sec .w30, .calcluter-sec .w70
		width: 100%
	.automated-sec
		.menu-bottom
			.fs1rem-screen1
				font-size: 0.7rem
	.automated-sec .menu-bottom, #root[style*="margin-top: 72px"] .narBarAnimationUP .fs1rem-screen1, 
	#root[style*="margin-top: 72px"] .narBarAnimation .fs1rem-screen1
		font-size: 0.7rem
	.landing-page-font 
		.inner-container 
			padding: 11px 95px 0 !important
			.shadowContainer
				width: 100% !important
				margin-bottom: 17px
			.df.jce.aic
				width: 100% !important
				gap: 5px !important
				margin: 0 auto
				justify-content: center
				img
					height: auto
					width: 131px !important
	.client-review-container 
		.df.w100
			flex-wrap: wrap
		.w60
			padding-right: 0
			width: 100%
		.w40
			width: 60%
			justify-content: center
			margin: 0 auto
	.work-container 
		.jcsb 
			.work-card-box
				width: 100%
				margin: 0 0 25px
	.accounts-page-font 
		.work-container 
			.df.jcsb 
				.work-card-box
					width: 100%
					margin: 0 0 25px
	.work-card-box
		width: 100%
		margin-bottom: 25px
	.work-container 
		.df.jcc
			flex-wrap: wrap 
	.pricing-container 
		.pricing-box
			width: 44%
	.triangle-vector
		width: 100px
	.pricing-container .pricing-box.mr5rem
		margin-right: 15px
	.pricing-title .right-text-for
		padding-right: 0 !important
	.pricing-title .left-text-for
		padding-left: 0
	.action-sec
		padding: 3rem 30px !important
		.fs2-5rem
			font-size: 1.5rem
	.action-sec.dg .right-area
		width: 58%
	.beta-logo
		left: 183px !important
		top: 16px
	.pricing-plan-page 
		.w60.fs3-125rem
			width: 100%
			padding-bottom: 2rem
	.angelsnetwork-page-font .inner-container .row.df.jcc img
		width: 66% !important
		height: auto !important
@media (max-width: 600px)
	.main-banner-container.home-page-banner .fs3rem, .main-banner-container.home-page-banner .fs3rem .c4
		font-size: 39px !important
		line-height: 46px !important
		margin: 20px 0
	.main-banner-container.home-page-banner .pt4rem .fs2-5rem
		font-size: 1.2rem
		margin-top: 1rem
	.pricing-tabs 
		.pricing-advisor-table 
			.pricing-box
				width: 99%
	.investor-box
		zoom: 0.4
	.main-banner-container,
	.new-header .container
		padding: 0 30px 30px
	.main-banner-container 
		.fs1-5rem
			font-size: 1rem
		.fs2rem
			font-size: 1.3rem
		span.c4
			font-size: 25px !important
	.calcluter-sec
		flex-wrap: wrap
		.w100
			flex-wrap: wrap
			padding: 2rem 1rem 1rem
		.w60
			width: 100%
		.w40
			width: 100%
			margin-top: 20px
	#homeMainBanner .container
		max-width: 100% !important
		padding: 0 30px !important
	.blue-animation
		width: 77px !important
		height: auto !important
		top: -40px !important
	.circle-animation
		width: 68px !important
		height: auto !important
	.quote-icon
		width: 20px !important
		svg
			width: 20px
	#homeMainBanner .container .w90 .fs1-25rem
		padding-left: 0.5rem
	#homeMainBanner .container:nth-child(odd) .w60
		margin-bottom: 13px
	#homeMainBanner .container .w60 .custom-list,
	#homeMainBanner .container .w60 .custom-list li
		width: 95% !important
		font-size: 17px
	.bottom-features 
		.column
			flex: 0 0 50%
	.video-container p.fs2-125rem
		line-height: 33px !important
		font-size: 1.2rem
	.video-container .w70
		width: 99%
	.pricing-container 
		.pricing-box
			width: 43%
	.register-container 
		.df.gg1rem.pt0-5rem.pb0-5rem
			flex-wrap: wrap
		.w50
			width: 100%	
	.not-sure-container .bsflp.bg1.br10px
		padding: 27px 15px
		width: 41% !important
	.not-sure-container .w100.df.jcc p
		width: 100% !important
	.side-blue-circle
		width: 70px
		height: auto
		right: 38px !important
		bottom: 0 !important
	.not-sure-container
		.side-blue-circle
			width: 70px !important
			height: auto
			bottom: 0 !important
			right: 0 !important
	.privacy-policy-page 
		.w60
			justify-content: center
			flex-wrap: wrap
			padding: 5px
			.fs1rem-screen1
				margin: 0 5px
		.policy-box
			.pt2rem
				padding-top: 1rem
			.pt1rem
				padding-top: 0.6rem
	.fs3-5rem
		font-size: 2.5rem
	.terms-and-conditions-page 
		[style*="width: 41%"]
			width: 88% !important
			border-radius: 10px
			padding: 12px 10px
	.team-sec .dg.aic .df.fdc
		justify-content: center
		width: 100%
		margin-bottom: -120px
	.team-sec .fs3-125rem
		font-size: 2rem
	.about-carousal-slider .slide
		zoom: .3
	.video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem
		width: 100%
		font-size: 25px
	#homeMainBanner .fs2-5rem
		font-size: 1.5rem
	.work-container .fs1-5rem
		font-size: 0.7rem
		line-height: 16px !important
	.poppins-font-style .inner-container .shadowContainer
		width: 49% !important
	.register-not-sure-sec
		overflow: hidden
	.calcluter-sec 
		.w40 
			img
				width: 60%
				margin-bottom: 20px
	.keyfeature-wihte-item
		width: 100% !important
		margin-right: 0 !important
	.work-container .df.jcsb .work-card-box, .work-container .df.jcc .work-card-box, .accounts-page-font .work-container .df.jcsb .work-card-box
		width: 75%
	.register-not-sure-sec 
		.not-sure-register 
			.not-sure-container 
				.fdc
					flex-wrap: wrap
	.register-not-sure-sec 
		.not-sure-register 
			.not-sure-container 
				.fdc 
					.scaleAnimation
						margin-bottom: 45px
	.bottom-button-slider 
		.fs1rem.df.jcc
			width: 69%
	#homeMainBanner 
		.mt4rem 
			.fs2-5rem
				font-size: 1.5rem
	.inner-container.style-two 
		.row
			width: 100%
	.investorLookup-container 
		.fs2rem
			font-size: 1.5rem
			line-height: 2rem !important
			padding: 50px 0
	.pricing-table-saf
		width: 80%
	.pricing-plan-page 
		.w60.fs3-125rem
			padding-bottom: 2rem
			font-size: 1.5rem
			line-height: 2rem !important
	.pricing-plan-page 
		.w40.pr.df
			width: 56%
			justify-content: center
			margin: 0 auto
	.bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem
		zoom: 0.6
		padding: 34px 15px
	.bg1.pricing-plan-page  h4.fs2rem.fw400.mb2rem.c15
		line-height: 28px !important
		max-width: 100% !important
		font-size: 1.1rem
	.comapnies-page-font .inner-container .shadowContainer .df.aic, .comapnies-page-font .inner-container .shadowContainer .df.aic p
		justify-content: start
	.poppins-font-style .inner-container .shadowContainer .br10px.mt1rem svg
		width: 39px
	.register-page .register-page-container .w50
		width: 100%
	.register-page .register-page-container .df.gg1rem
		flex-wrap: wrap
	.keyfeature-box-free .keyfeature-wihte-item:nth-child(2) .df.fw500.fs1-25rem.c15
		flex-wrap: wrap
	.inner-container .row.df.jcsb .df.jce.aic img.h70
		height: 50%
	.comapnies-page-font .companies-crousel .first-slide .c15.br30px
		width: 73%
		zoom: 0.8
	.comapnies-page-font .companies-crousel .first-slide .new-shadow .icon img.w30px
		width: 20px
	.keyfeature-box-free 
		.keyfeature-wihte-item:nth-child(2) 
			.df.fw500.fs1-25rem.c15 a
				margin-top: 15px
	.inner-container .df.jce.right-icon-btn
		gap: 0 !important
		width: 64% !important
		margin: 20px 0 12px
		justify-content: flex-start
	.inner-container .df.jcc.aic img.h60
		height: 142px !important
	.step-box-modal.style-two
		padding: 0
	.mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80
		zoom: .8
		padding: 10px
	.client-review-container .mt4rem
		padding: 2rem 1rem 0
	.register-container-not-sure
		padding: 106px 276px 28px 72px
		width: 89%
	.not-sure-register.w40
		width: 100%
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem
		width: 100%
		padding-top: 4rem
		flex-direction: row
		flex-wrap: wrap
		padding-left: 3rem
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem p
		width: 100%
	#keyFeatureSeeMore svg.pa.cp
		bottom: -3px !important
	.registration-inner .registration-box
		width: 100%
@media (max-width: 480px)
	.not-sure-register .not-sure-container
		padding: 30px 96px !important
	.home-page-menu.automated-sec .menu-bottom
		margin-top: -37px !important
	.main-banner-container .fs3-5rem .fs5rem
		font-size: 1.8rem
		margin: 10px 0
		display: block
		line-height: 2.5rem
	.login-page 
		.login-container
			width: 100%
			padding: 100px 25px 50px
			.pr8rem 
				.df.jcsb
					flex-wrap: wrap
	.comapnies-page-font .companies-crousel .first-slide .c15.br30px
		zoom: 0.8
	.main-banner-container 
		span.c4
			font-size: 22px !important
		.fs2rem
			font-size: 1rem
		.col-sm
			grid-gap: 0.5rem
	.menu-bottom
		flex-wrap: wrap
		justify-content: center
		.fs1rem-screen1
			margin: 0 5px
	#root[style*="margin-top: 72px"] .narBarAnimationUP, 
	#root[style*="margin-top: 72px"] .narBarAnimation
		flex-wrap: wrap
		justify-content: center
		display: none
	.carousel .slider-wrapper .slide .c15
		font-size: 1.2rem
		line-height: 2rem
	.carousel .slider-wrapper .slide .fs1rem.c15
		font-size: 1rem
	.carousel-button.prev
		left: -24px !important
	.carousel-button.next
		right: -24px !important
	.landing-page-font .inner-container
		padding: 11px 0px 0 !important
	.landing-page-font .inner-container .shadowPaperCrasouel .c15
		font-size: 1rem
		line-height: 1.3rem !important
	.landing-page-font .inner-container .df.jce.aic img
		width: 61px !important
	#homeMainBanner .container .w100.pr.df .br40px.pt1rem.pb1rem.pl3rem.pr2rem
		padding: 0 1.7rem 20px
	.quote-area
		flex-wrap: wrap
		.w90
			width: 100%
	.pricing-title .right-text-for
		display: none
	.pricing-title
		width: 100%
		justify-content: center
	#homeMainBanner .container .w90 .fs1-25rem
		padding: 0
	.feature-review-img
		flex-wrap: wrap
		img
			width: 40px
			height: 40px
			margin: 15px 0
		p
			line-height: 1rem !important
			padding: 0 0 8px
	#homeMainBanner 
		.container 
			.w40
				width: 100%
				margin: 18px auto
			.w100.pr.df
				margin-bottom: 20px
	.bottom-features 
		.column
			flex: 0 0 100%
	.pricing-container 
		padding: 51px 10px
		.pricing-box
			width: 100%
	.ar.pa.blue-circle
		right: 29px !important
		top: 34px !important
	.register-container 
		padding: 70px 45px
		width: 100% !important
	.register-container button
		width: 100% !important
	.not-sure-container .bsflp.bg1.br10px
		padding: 27px 15px
		width: 93% !important
	#homeMainBanner .container
		padding: 0 10px !important
	.client-review-container
		padding: 45px 13px 60px !important
		.fs2-125rem
			text-align: center
	.fs2-125rem
		font-size: 1.25rem
	.review-img img
		width: 212px !important
	.landing-page-font .silder-box
		padding: 1rem
	.calcluter-sec
		padding: 2rem 1rem
	.video-container
		padding: 49px 10px 0
	.triangle-vector
		width: 59px
		right: 34px !important
	#homeMainBanner .container .w60 .pl3rem
		padding-left: 1rem
	.df.jcc.mt5rem
		margin-top: 2rem
		p
			width: 100%
	.form-right-image
		width: 0 !important
	.fs3-5rem
		font-size: 2.2rem
	.privacy-policy-page
		.policy-box
			p, ol li, ul li
				word-break: break-all
	.terms-and-conditions-page 
		[style*="width: 41%"]
			justify-content: center
			flex-wrap: wrap
	.privacy-policy-page
		ul, ol
			padding-left: 5px
	.privacy-policy-page 
		.policy-box 
			.fs2rem.p3rem
				padding: 10px
				text-align: center
				font-size: 1.2rem
	.our-thesis-sec .fs3-5rem
		font-size: 1.8rem !important
	.visin-sec
		.fs3rem
			font-size: 2rem
	.story-sec
		padding: 50px 30px !important
	.about-carousal-slider 
		padding: 0 15px !important
		.slide
			zoom: .2
			min-width: 100% !important
	.carousel.carousel-slider
		padding: 0rem 0 4rem !important
	.action-sec.dg .right-area
		width: 76%
		zoom: 0.5
	.team-sec .fs3-125rem
		font-size: 1.7rem
	.bottom-features
		flex-direction: column
		width: 100%
		[style*="width: 20%"]
			width: 100% !important
	#homeMainBanner h1
		text-align: center
	.video-container img.w10.pa
		width: 50px !important
	.not-sure-container .df.jcsb .scaleAnimation
		margin-bottom: 43px
		width: 100% !important
	.carousel .slider-wrapper.axis-horizontal 
		.slider 
			.slide 
				.fw400.fs1-25rem.c15.mb1rem
					font-size: 0.9rem
	.carousel .slider-wrapper 
		.slide 
			.fs1rem.c15
				font-size: 1rem
				width: 78%
	.slider-wrapper 
		.inner-container 
			.row.df.jcsb 
				.df.jce.aic 
					img.h80
						height: 49%
	.keyfeautre-container
		max-width: 90%
		width: 90%
		.keyfeature-box
			box-sizing: border-box
	.keyfeature-box-free 
		.keyfeature-wihte-item
			box-sizing: border-box
		.keyfeature-wihte-item:nth-child(2)
			width: 100% !important
			.df.fw500.fs1rem.c15
				flex-wrap: wrap
			a
				width: 100%
				margin-top: 16px
	.how-its-work 
		.blue-animation
			height: 5% !important
	.poppins-font-style .inner-container .shadowContainer .df.aic, .poppins-font-style .inner-container .shadowContainer .df.aic p
		flex-wrap: wrap
	.poppins-font-style .inner-container .shadowContainer .df.aic span
		width: 100% 
	.poppins-font-style .inner-container .shadowContainer .df.aic p .cp.w100.df.jce.c1
		justify-content: center
		margin: 10px 0
		flex-wrap: nowrap
	.keyfeature-box-portfolio-innner 
		.keyfeature-box-portfolio-bg 
			.keyfeature-wihte-item
				width: 100% !important
				margin-right: 0 !important
				margin-left: 0 !important
	.poppins-font-style
		overflow: hidden
	.investor-box
		zoom: .3
	.work-container 
		.fs2-125rem
			font-size: 25px
	.main-banner-container 
		.fs3-5rem
			font-size: 1.3rem
			line-height: 39px !important
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before
		top: 47%
	.slider-wrapper .first-slide .inner-container img.pa.ar.zi1
		display: none
	.pricing-tabs 
		.booking-sec 
			.pricing-bottom-box
				width: 100%
				margin: 15px auto !important
	.pricing-plan-page .df.fdc.aic.mt1rem
		width: 100% !important
	.main-banner-container .col-sm.right-img-area .cubes-img
		left: -28px !important
		top: 46px !important
	.poppins-font-style .inner-container .shadowContainer
		width: 95% !important
		margin-bottom: 24px
	.calcluter-sec .w30 img.w90
		height: auto
	.inner-container .row.df.jcc img
		width: 100% !important
		height: auto !important
	.inner-container .df.jce.right-icon-btn
		width: 94% !important
	.inner-container .df.jcc.aic img.h60
		height: 106px !important
	.shadowPaperCrasouel .bg19
		width: 53px
		height: 53px
		padding: 5px
		box-sizing: border-box
		margin-bottom: 10px
	.shadowPaperCrasouel .bg19 svg
		width: 23px
	.poppins-font-style .inner-container .shadowContainer
		zoom: .6
		width: 98% !important
	.comapnies-page-font .review-img img
		width: 100% !important
	.inner-container .jcsa[style="width: 47%; gap: 25px;"], .inner-container .jcsb[style="width: 47%; gap: 25px;"]
		width: 98% !important
	.inner-container .jcsa[style="width: 47%; gap: 25px;"] .shadowContainer, .inner-container .jcsb[style="width: 47%; gap: 25px;"] .shadowContainer
		width: 100% !important
		box-sizing: border-box

	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem
		padding-top: 4rem
		width: 100%
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before
		border-radius: 31px
		width: 100%
		height: 93%
		top: 53%
	.keyfeature-box-portfolio-innner:before
		border-radius: 31px
		width: 100%
		height: 100%
		top: 53%
	.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w70
		width: 100%
		padding-left: 34px
		display: flex
	.keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3)
		top: 0
		order: 5
	.investorLookup-container .investor-box
		zoom: .3
		margin-top: -56px
	.form-right-image .triangle-vector
		display: none
	svg.pa.cp
		zoom: .7
		bottom: 182px !important
	.bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem .w60, .bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem .w40
		width: 100%
		padding: 0 !important
	.bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem
		flex-direction: column
	.pricing-plan-page .w40.pr.df
		width: 100%
	.pricing-plan-page .w60.fs3-125rem
		padding: 1rem 2rem 0 !important
	.action-sec h4
		font-size: 1.4rem
		line-height: 29px !important
		width: 100% !important
	.partners-page .df.w100 .p2-5rem
		padding: 1rem
	.contact-us-page .df.w100 .w35
		width: 100% !important
		justify-content: end
		display: flex
		text-align: end
		padding: 0
	.contact-us-page .df.w100 .w35 img
		width: 100%
	#homeMainBanner .mt4rem .fs2-5rem
		font-size: 1.1rem
@media (max-width: 410px)
	.main-banner-container 
		span.c4
			font-size: 18px !important
		.fs2rem
			font-size: 0.8rem
		.col-sm
			grid-gap: 0.4rem
	.automated-sec 
		.c15
			font-size: 16px !important
			line-height: 20px !important
		.c14
			font-size: .7rem !important
			line-height: 1rem !important
	.register-container .pt1rem.pb1rem .fs1-125rem
		font-size: 0.7rem
		margin-left: 0.4rem
	.privacy-policy-page 
		.w60 
			.fs3-5rem
				font-size: 1.8rem
	#homeMainBanner .fs2-5rem
		font-size: 1.3rem
	.main-banner-container 
		.fs3-5rem
			font-size: 1.2rem
			line-height: 26px !important
	.new-header 
		.container
			padding: 0 10px !important
	.client-review-container 
		.fs2-125rem
			text-align: center
			line-height: 40px !important
			font-size: 1.4rem
	.beta-logo
		top: 16px
		left: 147px !important
	.contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df
		zoom: .3
	.contact-us-page .df.w100 .w60.fs2-5rem
		font-size: 1.5rem
@media (max-width: 380px)
	.main-banner-container 
		.fs3-5rem
			font-size: 1.1rem
			line-height: 21px !important
	.contact-us-page .df.w100 .w60.fs2-5rem
		font-size: 1.2rem
	.contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df
		zoom: .2
	.contact-us-page img.pa.ar
		zoom: 0.4
@supports (-webkit-appearance:none) and (stroke-color:transparent)
	.menu-safari
		z-index: 5
		right: -100%
		height: 100%
		background-color: #4a67ff
		&.r0
			&.showing
				right: -75px
				height: 100%
	.menu-safari:not(.showing)
		&.showing
			right:0
	.report-page
		.sidebar-report
			.p0-5rem
				button
					font-size: 11px
					padding: 5px 8px
			.mta
				.fs1-2rem
					font-size: 1rem
			.mah100
				&.mya
					&.oya
						&.df
							&.fdc
								&.jcsb
									max-height: 43vh
	.table-of-content
		.list-table
			li
				&:nth-child(1)
					&:before
						width: 70%
				&:nth-child(2)
					&:before
						width: 70%
				&:nth-child(3)
					&:before
						width: 70%
				&:nth-child(4)
					&:before
						width: 55%
		&.style-two
			.list-table
				li
					&:nth-child(1)
						&:before
							width: 66%
					&:nth-child(2)
						&:before
							width: 66%
					&:nth-child(3)
						&:before
							width: 66%
	.extenstion-pack-btn-groups
		.btn-packge
			.trophy-cup
				top: 68px
				img
					width: 45px !important
	.sectionWrapper
		.group-inputs
			&.link-group
				p
					a
						width: 100px
	.back-btn
		top: 9px
		left: -266px
	#root
		overflow: hidden
	.right-text
		text-align: right
	.fdrr-sf
		flex-direction: row-reverse
	.company-dashboard-sf
		display: flex
		justify-content: space-between
		.fs1rem
			font-size: 0.75rem
		.fs0-75rem
			font-size: .55rem
	.certificate-area-dashboard
		zoom: 0.6
		margin-top: 24px		
		.w7rem
			width: 4rem
	.extenstion-pack-sf
		display: flex
		
		.button-groups-new
			width: 15.5%
			font-size: 12px
			.percentage-area
				zoom: 0.6
			.tooltip-area
				&.style-two
					top: -15px
		.certificate-tooltip-box
			width: 15.5%
			font-size: 12px
			.btn-packge
				width: 100%
				font-size: 12px
	.extanstion-pack-two-sf
		display: flex
		flex-wrap: wrap
		.btn-packge
			width: 20%
			font-size: 12px
			&.style-two
				width: 30%
			.audit-pakcges
				width: 295px
				margin-bottom: 10px
				padding: 10px 7px
				.right-area
					p
						font-size: 11px
				.h3rem
					height: 1rem
			.percentage-area
				zoom: 0.6
			.tooltip-area
				&.style-two
					top: -15px
	.extenstion-pack-btn-groups
		.fs1-5rem
			font-size: 1rem
		.button-groups-new
			padding-right: 0
		.btn-packge 
			.ribbon
				zoom: 0.7
	.certificates-page 
		.certificates-current 
			.packge-select
				zoom: .5
				.w7rem
					width: 4rem
				.w250px
					width: 312px
	.footer-sec 
		.check-box 
			.certification-pricing-column
				zoom: .6
				width: 364px
				right: 150px
	.pricing-table-saf
		display: flex
		flex-wrap: wrap
		.pricing-column-saf
			width: 26%
		.price-box-saf
			width: 60px
			height: 60px
			font-size: 1rem
	.company-dashboard-sf 
		h4
			width: 6rem
		.w20
			&.mr2rem
				margin-right: 16px
		.dashboard-btn
			padding: 0.4rem
		.username-area
			&.mr0-5rem
				margin-right: 0px
			.w2rem
				width: 1.6rem
				height: 1.6rem
				font-size: 12px
				margin-right: 0
	.dropdown-company-dahboard 
		.dropdown-content
			h4
				width: 4rem 
			.certificate-area-dashboard
				zoom: .6
	.container 
		padding-left: 2.5rem
		padding-right: 2.5rem
	.footar-saf
		display: flex
		flex-wrap: wrap
		.footer-column
			width: 47%
	.report-page 
		.container
			padding-right: 1rem
			padding-left: 1rem
	.report-upload-area-saf
		display: flex
		flex-wrap: wrap
		> div
			flex-grow: 1
			flex-basis: 46%
	.btn-dataroom-saf
		display: flex
		justify-content: space-between
	.extanstion-pack-two-sf
		display: flex
		flex-wrap: wrap
		.btn-packges
			width: 18.5%
			.btn-packge
				width: 100% 
	@media all and (orientation: landscape)
		.ceo-sec .ceo-inner .left-ceo-text blockquote
			font-size: 20px
			line-height: 37px !important
		.investorLookup-container
			padding: 0px 150px 100px
		.investor-box
			.custom-list
				li
					font-size: 14px !important
		.client-review-container p
			font-size: 20px
			line-height: 32px !important
		.review-img
			img
				width: 91% !important
		.accounts-page-font, .lawyers-page-font
			.client-review-container
				.review-img
					img
						width: 80% !important
		.pricing-container 
			.pricing-box
				zoom: 0.8
				img.pr.w100
					height: 131px
		.team-sec
			padding: 3rem 4rem 0
			position: relative
		.shadowPaperCrasouel .right-icon-btn span.fs1rem
			font-size: 12px
		.team-sec .team-about-column .team-box .card-style-about ul li
			font-size: 16px
			line-height: 1.2rem !important
		.keyfeature-box .seemore-box-pro
			left: 30% !important
		.comapnies-page-font .main-banner-container, 
		.accounts-page-font .main-banner-container, 
		.lawyers-page-font .main-banner-container, 
		.vcs-page-font .main-banner-container, 
		.pes-page-font .main-banner-container, 
		.angelsnetwork-page-font .main-banner-container, 
		.angels-page-font .main-banner-container
				height: 85.5vh

		.ceo-sec .ceo-inner .left-ceo-text blockquote
			font-size: 16px
			line-height: 27px !important
		.shadowPaperCrasouel .right-icon-btn span.fs1rem
			font-size: 12px !important
		.inner-container .px6rem
			padding: 3rem
		.keyfeautre-container
			width: 1200px
			.keyfeature-box-portfolio
				zoom: 0.8
		.inner-container 
			.px6rem
				padding: 0
		.client-review-container p
			font-size: 14px
			line-height: 23px !important
		.calcluter-sec
			width: 1237px
			.c15
				font-size: 1.6rem
				line-height: 36px !important
		.register-page-container
			width: 65%, 
			padding: 5px 0px 5px  100px
		#homeMainBanner .container
			padding-left: 11.4% !important
			padding-right: 11.4% !important
		.company-searchbox .verify-btn.mr6rem
			right: 103px
		.main-banner-container
			.pt3rem
				padding-top: 2rem
		.automated-sec
			.mb4rem
				margin-bottom: 3rem
		.automated-sec .c15
			font-size: 30px !important
			line-height: 38px !important
		.automated-sec .c14
			font-size: 17px !important
			line-height: 25px !important
		.calcluter-sec .c15
			font-size: 1.6rem
			line-height: 36px !important
		.calcluter-sec .fs3rem
			font-size: 2.5rem
		.register-container
			.pr10rem
				padding-right: 4rem
			input
				font-size: 0.75rem !important
				padding: 10px
		.login-container
			input
				padding: 10px 10px 10px 3rem
		.register-container .password-group input, .register-page-container .password-group input
			padding: 10px 10px 10px 3rem
		.login-container
			input
				padding: 10px 10px 10px 3rem		
		.automated-sec 
			.w80
				width: 74%
			.w60
				width: 58%
		.inner-container 
			p.fs1-25rem
				font-size: 1.2rem
		.not-sure-container
			padding: 100px 250px
		.inner-container
			padding: 15px 150px !important
		.menu-bottom
			margin-top: -40px !important
		.main-banner-container 
			.mt7rem
				margin-top: 4rem
		.calcluter-sec 
			.fs2-5rem
				font-size: 39px
		.client-review-container 
			p
				letter-spacing: 1px
				font-size: 16px
				line-height: 25px !important
		.custom-list 
			li
				font-size: 16px !important
		#homeMainBanner 
			.container 
				.w60 
					.fs1-5rem
						font-size: 1.3rem
		.work-container .work-card-box .fs1-25rem
			font-size: 16px
			line-height: 25px !important
		#homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem
			width: 160px
		.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
			margin-top: -32px !important
		.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user,
		.comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user
			margin-top: -37px !important
		.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
			margin-top: -54px !important
		.comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user
			margin-top: -50px !important
		.comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user
			margin-top: -48px !important
		.video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem
			width: 477px
			font-size: 30px
			line-height: 40.2px !important
		.register-container .w80
			width: 100%
		.not-sure-container p.w100
			width: 85%
		.work-container .fs1-5rem
			font-size: 1.25rem
			line-height: 30px !important
		#homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
			width: 237px
		.accounts-page-font .client-review-container .review-img img
			width: 60% !important
		.accounts-page-font .client-review-container p
			letter-spacing: 0.7px
			font-size: 13px
			line-height: 17px !important
		.investor-box .left-certificate-area .certificate-box
			width: 20% !important
		.pricing-container
			.pricing-box
				.br100
					zoom: 0.7
			.pricing-title
				margin-bottom: 2rem
		.vcs-page-font 
			.client-review-container 
				p
					font-size: 14px
					line-height: 21px !important
			.review-img 
				img
					width: 81% !important
		.client-review-container 
			.mt4rem
				margin-top: 2rem
			.mb2-5rem
				margin-bottom: 0
		.register-container 
			input#searchCompany
				padding: 10px 15px 10px 57px
			.shadow.bg1.br5px
				padding: 0.7rem
				span
					font-size: 12px
			.pr8rem 
				.shadow.bg1
					span
						font-size: 12px
		.slider-wrapper 
			.first-slide 
				.inner-container 
					img
						width: 70%
			.inner-container
				.row.df.jcsb
					.df.jce.aic
						img.h80
							height: 66%
		.bottom-button-slider
			.w50
				width: auto
		.main-banner-container.home-page-banner .fs3rem, .main-banner-container.home-page-banner .fs3rem .c4
			font-size: 70px !important
			line-height: 84px !important
			margin-top: 0.5rem
		.main-banner-container 
			.fs3-5rem
				font-size: 2.8rem
				line-height: 56px !important
		.right-img-area
			height: 440px !important
			margin-top: 310px !important
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem
				padding-right: 3rem
		.shadowPaperCrasouel 
			span.w70
				font-size: 13px
			span.w30
				padding: 10px 8px !important
				text-align: center
		.bottom-button-slider .w40
			width: 50%
		.inner-container 
			.df.jcc.aic 
				img.h60
					height: 179px !important
		.keyfeature-box-portfolio-innner 
			.keyfeature-box-portfolio-bg
				zoom: 0.8
		.keyfeautre-container 
			.keyfeature-box
				zoom: 0.8
		.keyfeature-wihte-item
			width: 18% !important
		.keyfeature-box-free 
			.keyfeature-wihte-item:nth-child(2)
				width: 35% !important
		.not-sure-register 
			.not-sure-container
				padding: 30px 120px !important
		.bottom-button-slider
			position: relative

		.inner-container .row.df.jcc img.mb3rem
			width: 70% !important
		.inner-container 
			.px6rem
				padding: 0
		.keyfeature-box-free 
			.keyfeature-wihte-item:nth-child(2)
				width: 33% !important
		.inner-container 
			padding: 15px 150px !important
		.home-page-bg
			height: 89vh !important
		.main-banner-container.home-page-banner
			height: 93%
		.right-img-area
			height: 330px !important
			margin-top: 14px !important
			.person-img
				height: 140% !important
		.keyfeautre-container
			.keyfeature-box
				margin-bottom: 1.5rem
				padding: 2rem 2rem 0
				.mb2rem
					margin-bottom: 1rem
		#homeMainBanner 
			.mt4rem
				margin-top: 1rem
				.fs2-5rem
					font-size: 2rem
		.main-banner-container
			.pt5rem
				padding-top: 3rem
		.automated-sec.pt4rem
			padding-top: 1rem
		.keyfeautre-container
			width: 1170px
		.keyfeature-wihte-item
			width: 18% !important
			zoom: 0.9
			.fs1rem.c15
				font-size: 13px !important
			.seemore-box
				width: 1107px !important
		.register-container 
			.company-searchbox 
				.pt0-5rem 
					svg
						width: 1.4rem
					input
						padding: 10px 10px 10px 47px
						&.verify-btn
							padding: 8px
		.investorLookup-container
			padding: 0  100px 70px
		.how-its-work .blue-animation
			height: 100px !important
		#homeMainBanner .container
			padding-left: 12.6% !important
			padding-right: 12.6% !important
		.main-banner-container
			.fs1-5rem
				margin-top: 2rem
				font-size: 1.22rem
			.fs2rem
				font-size: 1.5rem
				line-height: 1.8rem !important
			span.c4
				font-size: 38px !important
				line-height: 50px !important
		.automated-sec
			.c15
				font-size: 28px !important
				line-height: 35px !important
		.pricing-container
			padding: 120px 95px
		.automated-sec 
			.w80
				width: 73%
			.w60
				width: 57%
		.inner-container 
			p.fs1-25rem
				font-size: 1rem
		.calcluter-sec 
			.c15
				font-size: 1.7rem
				line-height: 37px !important
			.fs3rem
				font-size: 2.3rem
		.client-review-container
			padding: 45px 200px 60px
		.work-container
			max-width: 930px !important
			.work-card-box
				zoom: 0.8
			.fs1-25rem
				font-size: 1rem
		.not-sure-container
			padding: 100px
		.pricing-title .right-text-for
			padding-right: 8rem
		.angelsnetwork-page-font .pricing-title .right-text-for, 
		.angels-page-font .pricing-title .right-text-for
			padding-right: 6rem
		.vcs-page-font  .pricing-title .right-text-for, 
		.pes-page-font .pricing-title .right-text-for
			padding-right: 4rem
		.pricing-title .left-text-for
			padding-left: 2.1rem
		.visin-sec .w30 img
			height: 61% !important
		.main-banner-container
			padding: 0 165px 0 149px
		.main-banner-container .mt7rem
			margin-top: 4rem
			font-size: 1.30rem
		.main-banner-container .fs2rem
			font-size: 1.1rem
			line-height: 1.4rem !important
		.main-banner-container span.c4,
		.carousel .slide .fs2-125rem
			font-size: 33px !important
			line-height: 47px !important
		.carousel .slide .fs1-25rem
			font-size: 1rem
			line-height: 28px !important
		.client-review-container .fs2-125rem
			font-size: 33px
		.review-img img
			width: 100% !important
			height: auto !important
		.work-container
			.df.jce
				width: 39px !important
			.pb1-5rem.df.jcsb
				min-height: 46px !important
				height: 46px !important
		.poppins-font-style .inner-container .shadowContainer
			zoom: 0.7
		.angelsnetwork-page-font .inner-container .row.df.jcc img
			width: 41% !important
		.carousel.carousel-slider .df .h60
			width: 85px !important
		.pricing-container 
			.pricing-box 
				img.pr.w100
					height: 116px
		.register-container
			.fs2-125rem
				line-height: 35px !important
				font-size: 1.6rem
			.w80
				padding-top: 5px
		.register-container 
			.form-right-image 
				.book-call
					width: 75% !important
			.pt0-5rem.pb0-5rem.pr 
				svg
					top: 50% !important
					transform: translateY(-50%)
			.pr.w50 
				svg
					top: 50% !important
					transform: translateY(-50%)
			.fs1-125rem.pb0-5rem, .pb1rem.gg1rem
				padding: 10px 0 0
			.pb1rem.gg1rem
				label
					font-size: 14px
			.registerButtonAnimation
				margin: 6px 0
		.pricing-container 
			.pricing-box
				zoom: .7
		.video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem
			width: 459px
			font-size: 25px
		.pricing-container .pb1rem.df
			padding-bottom: 0
		.investorLookup-container
			.pb2rem
				padding-bottom: 0
			.investor-box
				margin-top: -56px
		.button-disabled, .investor-search-btn, .investor-back-btn
			zoom: 0.8
		.investor-modal-database 
			.pr
				input
					font-size: 14px
					padding: 10px 15px
		.investor-back-btn
			padding: 10px 29px
			margin: 0
		#menu
			a
				font-size: 14px
			a.w200px
				zoom: 0.8
		.automated-sec .fs1rem-screen1
			font-size: 14px
		.narBarAnimation
			.fs1rem-screen1
				font-size: 14px
		.main-banner-container.home-page-banner 
			.pt2rem
				padding-top: 0
			.fs1-25rem
				font-size: 1rem
		.calcluter-sec
			padding-top: 0
			margin-top: -11px
			.c15
				font-size: 1.4rem
				line-height: 29px !important
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem 
				.fs1-25rem
					font-size: 1rem
		.not-sure-container 
			.scaleAnimation
				zoom: 0.8
		.register-container-not-sure 
			.form-right-image
				right: -27% !important
		.shadowPaperCrasouel 
			span.w30 
				img
					width: 31px !important
			span.w50
				padding: 8px !important
				font-size: 13px
		.client-review-container 
			p
				font-size: 14px
				line-height: 21px !important
		.accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img
			width: 76% !important
		.keyfeautre-container 
			.keyfeature-box-portfolio
				padding: 1.5rem 2rem
		.comapnies-page-font 
			.companies-crousel 
				.first-slide 
					.c15.br30px
						zoom: 0.8
		.comapnies-page-font 
			.review-img 
				img
					width: 74% !important
					height: auto !important
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg, .keyfeautre-container .keyfeature-box
			zoom: .9
		.register-not-sure-sec
			zoom: 0.8
		.not-sure-register 
			.not-sure-container
				padding: 30px 120px 30px 221px !important
		.angels-page-font 
			.client-review-container p
				line-height: 21px !important
		svg.pa.cp
			zoom: 0.7
		.investment-box-pricing
			zoom: 0.7

		.carousel-button.prev
			left: 30px
		.carousel-button.next
			right: 30px
		.team-sec .team-about-column .team-box .card-style-about
			padding: 1rem 3rem 6rem !important
		.team-sec .team-about-column .team-box
			zoom: .5
		.silder-box
			padding: 3rem 0 4rem
		.animation-img-box,
		.animation-img-box-down
			bottom: 114px
			right: -135px
			width: 131px
		.comapnies-page-font .main-banner-container, 
		.accounts-page-font .main-banner-container, 
		.lawyers-page-font .main-banner-container, 
		.vcs-page-font .main-banner-container, 
		.pes-page-font .main-banner-container, 
		.angelsnetwork-page-font .main-banner-container, 
		.angels-page-font .main-banner-container
			height: 84.5vh
		.angels-page-font .client-review-container p
			line-height: 20px !important
		
		.form-right-image .triangle-vector
			width: 134px
			right: 364px !important
			bottom: -60px !important
		.investorLookup-container 
			.investor-box
				margin-top: -56px
				zoom: 0.6
		.story-sec p
			line-height: 1.5rem !important
			margin-bottom: 10px
		.action-sec
			padding: 4rem 5rem !important
		.silder-box .c15.fw500.df.jcc,
		.story-sec h4.fw500.mb1-5rem
			font-size: 37px !important
			line-height: 2.8rem !important
		.main-banner-container .pt5rem
			padding-top: 1rem
		.animation-img-box,
		.animation-img-box-down
			width: 100px
			bottom: 115px
			right: -110px
		.silder-box .slick-arrow:before
			width: 90px
			height: 90px
		.angels-page-font .client-review-container p
			line-height: 20px !important


		.ceo-sec .ceo-inner .left-ceo-text blockquote
			font-size: 13px
			line-height: 1rem !important
		.calcluter-sec
			width: 1037px
			.secend-sec-btn
				zoom: 0.7
		.register-container-not-sure
			padding: 100px 170px 14px
		.automated-sec 
			.c15
				font-size: 26px !important
				line-height: 32px !important
			.c14
				font-size: 1rem !important
				line-height: 1.7rem !important
		.client-review-container
			p
				font-size: 0.75rem
				line-height: 22px !important
		#homeMainBanner 
			.container
				max-width: 100% !important
				padding-left: 10.6% !important
				padding-right: 10.6% !important
		.pricing-container
			padding: 120px 30px
		.not-sure-container 
			.fs1-5rem
				font-size: 1.2rem
				line-height: 30px !important
		.carousel-root 
			.carousel-button 
				svg
					width: 84px
		.shadowPaperCrasouel 
			.fs1-5rem
				font-size: 1.4rem
			.bg19
				width: 37px
				height: 37px
				padding: 0
				text-align: center
				svg
					width: 16px
		.inner-container
			padding: 15px 120px !important
			zoom: 0.9
		.fs2-125rem
			font-size: 1.8rem
		.fs2rem
			font-size: 1.75rem
		.fs1-25rem
			font-size: 1rem
		.client-review-container
			padding: 45px 256px 60px !important
			.pt1rem.pb1rem
				padding-top: 0.5rem
				padding-bottom: 0.5rem
				input
					padding: 0.7rem
				.w50
					svg
						top: 17px !important
				svg
					top: 24px !important
				.fs1-125rem
					font-size: 1rem
			label.pt2rem.pb2rem
				padding-top: 1rem
				padding-bottom: 1rem
			button
				margin-top: 1rem
				margin-bottom: 1rem
		.register-container .pr10rem
			padding-right: 3rem
		#homeMainBanner .container:nth-child(odd) .w60
			padding-left: 8rem
		#homeMainBanner .container:nth-child(2n) .w60
			padding-right: 5rem
		.calcluter-sec .c15
			font-size: 1.5rem
			line-height: 38px !important
		.calcluter-sec .fs3rem
			font-size: 2rem
		.register-container .pr8rem
			padding-right: 3rem
		.about-carousal-slider .slide
			zoom: 0.8
		.action-sec
			padding: 7rem 5rem !important
		.accounts-page-font 
			.work-container
				.fs1-5rem
					font-size: 1.3rem
				.fs1rem
					font-size: 0.85rem
		.fs3-125rem
			font-size: 2.8rem
		.card-style-about
			padding: 1rem 2rem 7rem !important
		.beta-logo
			width: 61px
			height: 37px
			margin-top: 8px
		.comapnies-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem,
		.accounts-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem,
		.lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem,
		.lawyers-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
			width: 200px
		.comapnies-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem,
		.vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
			width: 148px
		.comapnies-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem
			width: 350px
		.comapnies-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem,
		.comapnies-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem, 
		.comapnies-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem,
		.accounts-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem,
		.accounts-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem,
		.lawyers-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem,
		.lawyers-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem,
		.vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem,
		.vcs-page-font #homeMainBanner .container:nth-child(8) .w60 .fs1-5rem,
		#homeMainBanner .container:nth-child(8) .w60 .fs1-5rem,
		#homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem
			width: 153px
		.comapnies-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem,
		.accounts-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
			width: 200px
		.comapnies-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem
			width: 170px
		.accounts-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem,
		.lawyers-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem,
		.lawyers-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem,
		.vcs-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem,
		#homeMainBanner .container:nth-child(4) .w60 .fs1-5rem,
		.vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem,
		#homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem
			width: 270px
		.lawyers-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem
			width: 224px
		.calcluter-sec 
			.fs2-5rem
				font-size: 1.6rem
			.fs2rem
				font-size: 1.5rem
		#homeMainBanner .container .testmonial-user
			margin-top: 0 !important
		.bottom-features
			width: 72%
		.comapnies-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
			width: 212px
		.angels-page-font #homeMainBanner .container:nth-child(14) .w60 .fs1-5rem
			width: 126px
		.client-review-container p
			font-size: 12px
			line-height: 17px !important
		.vcs-page-font .client-review-container p
			font-size: 11px
			line-height: 16px !important
		#openLoginPopup
			width: 150px !important
			zoom: 0.8
		.registerButtonAnimation, .see-aal-features
			zoom: 0.8
		.register-container 
			.registerButtonAnimation
				width: auto !important
				padding: 9px 40px !important
		.register-container 
			button.fs1-25rem
				width: auto !important
				padding: 9px 40px !important
				zoom: 0.8
				height: 100% !important
		.calcluter-sec
			padding-top: 1rem
		.comapnies-page-font 
			.review-img 
				img
					width: 84% !important
		.main-banner-container.home-page-banner
			padding-top: 29px
			.pt4rem
				padding-top: 1rem
				.fs2-5rem
					font-size: 1.5rem
			.fs1-25rem
				font-size: 13px
		.main-banner-container.home-page-banner .fs3rem, .main-banner-container.home-page-banner .fs3rem .c4
			margin-top: 0
			font-size: 58px !important
			line-height: 62px !important
		.right-img-area
			width: 40% !important
			height: 294px !important
			margin-top: 100px !important
		.main-banner-container .col-sm.df.jcc.fdc.gg2rem
			padding-right: 2rem
			width: 60% !important
		.main-banner-container 
			.fs3-5rem 
				.fs5rem
					font-size: 3.8rem
		.calcluter-sec 
			.c15
				line-height: 29px !important
				font-size: 1.3rem
			.w60 
				a
					zoom: 0.8
		.main-banner-container 
			.fs3-5rem
				font-size: 2.4rem
				line-height: 50px !important
			.fs1-75rem
				font-size: 1.30rem
		.home-page-menu.automated-sec
			.menu-bottom
				margin-top: -76px !important
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem 
				.fs1-25rem
					font-size: 15px
		.register-not-sure-sec 
			.not-sure-register 
				.not-sure-container
					.fs2rem
						font-size: 1.5rem
						line-height: 26px !important
					p
						font-size: 14px
		.inner-container 
			.px6rem
				padding-left: 1rem
				padding-right: 1rem
		.register-container-not-sure 
			.form-right-image
				right: -33% !important
				.book-call
					width: 60% !important
		.inner-container .df.jce
			width: 16% !important
			gap: 8px !important
			justify-content: center
			margin: 0 auto
			img.h70
				height: 64%	
		.comapnies-page-font .companies-crousel .first-slide .c15.br30px .icon .w30px
			width: 22px
		.inner-container 
			.df.jce.right-icon-btn
				width: 100% !important
				gap: 0 !important
				justify-content: end
		.shadowPaperCrasouel .right-icon-btn span.fs1rem
			padding: 10px 20px !important
		.shadowPaperCrasouel .fs1-5rem
			font-size: 1.3rem
			line-height: 30px !important
		.bottom-button-slider
			zoom: 0.8
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item
			zoom: 0.8
		.keyfeautre-container
			width: 1060px
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem
			padding-top: 5rem
		.keyfeature-wihte-item
			zoom: .8
		.accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img
			width: 300px !important
			height: auto !important 
		

		.home-page-banner 
			.col-sm.df.jce.pr
				width: 45% !important
		.keyfeautre-container
			width: 1070px
		.keyfeature-wihte-item
			margin: 0 10px 30px !important
			.fs1rem.c15
				font-size: 12px !important
			.seemore-box
				width: 999px !important
		.investorLookup-container
			padding: 0  70px 70px
		.main-banner-container 
			span.c4
				font-size: 30px !important
				line-height: 38px !important
		.automated-sec 
			.c15
				font-size: 24px !important
				line-height: 28px !important
			.c14
				font-size: 1rem !important
				line-height: 1.3rem !important
				padding-top: 1rem
		.calcluter-sec
			.fs2rem
				font-size: 1.5rem
		.fs2-125rem
			font-size: 1.75rem
		.fs2rem
			font-size: 1.50rem
		.fs1-5rem 
			font-size: 1rem
		.fs1rem
			font-size: 0.75rem
		.work-container
			padding: 70px 160px
			.pt1-5rem.pb1rem
				padding: 0.5rem 0
			.pb1-5rem.df.jcsb
				min-height: 50px !important
				height: 50px !important
		.client-review-container
			p
				font-size: .65rem
				line-height: 17px !important
		.video-container 
			p.fs2-125rem
				line-height: 40px !important
		.pricing-container 
			.pl8rem.pr8rem
				padding: 0 4rem
		.not-sure-container 
			.fs1-5rem
				font-size: 1rem
				line-height: 30px !important
			.mt1rem 
				button
					font-size: 14px
					padding: 10px 22px !important
			.bsflp.bg1.br10px
				padding: 27px 15px
				.pa.df.jcc.aic.p2rem.bg3
					padding: 1rem
					top: -33px !important
					img
						width: 100%
			.mt7rem.mb3rem
				margin-top: 4rem
				margin-bottom: 2rem
		#homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, 
		#homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, 
		#homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, 
		#homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, 
		#homeMainBanner .container:nth-child(12) .w60 .fs1-5rem
			width: 200px
		#homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, 
		#homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, 
		#homeMainBanner .container:nth-child(10) .w60 .fs1-5rem
			width: 120px
		#homeMainBanner .container:nth-child(8) .w60 .fs1-5rem
			width: 112px
		#homeMainBanner .container:nth-child(9) .w60 .fs1-5rem
			width: 147px
		#homeMainBanner .container .w60 .fs1-5rem
			font-size: 1.2rem !important
		#homeMainBanner .container .custom-list li
			font-size: 17px
		.work-container .pt1-5rem.pb1rem
			font-size: 1rem
			line-height: 27px !important
		#homeMainBanner .container
			max-width: 100% !important
			padding-left: 10% !important
			padding-right: 10% !important
		.automated-sec .w80
			width: 70%
		.client-review-container 
			p
				font-size: .75rem
				line-height: 19px !important
		.fs1-25rem
			font-size: 1rem
		.register-container .pr8rem
			padding-right: 4rem
		.story-sec
			padding: 3rem 5rem !important
		.accounts-page-font 
			.work-container
				padding: 70px 110px
		.accounts-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(15) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem, .accounts-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .pes-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angelsnetwork-page-font #homeMainBanner .container:nth-child(9) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(11) .w60 .fs1-5rem, .angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem
			width: 245px
		.not-sure-container p.w100
			width: 100%
			margin-bottom: 30px
		.lawyers-page-font #homeMainBanner .container:nth-child(2) .w60 .fs1-5rem
			width: 254px
		#homeMainBanner .container:nth-child(14) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(3) .w60 .fs1-5rem, .lawyers-page-font #homeMainBanner .container:nth-child(4) .w60 .fs1-5rem, .vcs-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
			width: 225px
		#homeMainBanner .container:nth-child(5) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem, #homeMainBanner .container:nth-child(10) .w60 .fs1-5rem
			width: 195px
		.client-review-container .mb2-5rem
			margin-bottom: 1rem !important
		.client-review-container .mt4rem
			margin-top: 2rem
		.angelsnetwork-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem,
		.angels-page-font #homeMainBanner .container:nth-child(6) .w60 .fs1-5rem
			width: 200px
		.angels-page-font #homeMainBanner .container:nth-child(7) .w60 .fs1-5rem
			width: 140px
		.angels-page-font #homeMainBanner .container:nth-child(12) .w60 .fs1-5rem
			width: 263px
		.angels-page-font #homeMainBanner .container:nth-child(13) .w60 .fs1-5rem
			width: 243px
		.main-banner-container.home-page-banner
			padding-top: 10px
		.home-page-bg
			height: 89vh !important	
		.right-img-area
			width: 42% !important
			height: 290px !important
			margin-top: 211px !important
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem 
				.fs1-25rem
					font-size: 14px
		.shadowPaperCrasouel 
			.fs1rem
				font-size: 1rem
		.comapnies-page-font 
			.companies-crousel 
				.first-slide 
					.new-shadow 
						.icon
							width: 18px
		.register-container-not-sure 
			.form-right-image
				bottom: -6px !important
				right: -35% !important
		.register-not-sure-sec 
			.not-sure-register 
				.not-sure-container 
					h1
						font-size: 1rem
		.accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img
			width: 100% !important
		.main-banner-container 
			.fs3-5rem
				font-size: 2.2rem
				line-height: 50px !important
		.pricing-table-saf
			width: 1090px
		.pricing-tabs 
			.booking-sec 
				.pricing-bottom-box
					zoom: 0.8 
			.pricing-advisor-table 
				.pricing-box
					width: 20%
					.shadow.bg1
						zoom: 0.7
		.pes-page-font 
			.review-img 
				img
					width: 100% !important
		.angelsnetwork-page-font 
			.review-img 
				img
					margin-top: 40px
					width: 100% !important
		.top-pricing-area
			top: -99px
			zoom: 0.8
			.w100.df.jcc 
				.br100
					zoom: 0.9

		.team-sec .team-about-column .team-box img.team-img
			width: 63%
		.story-sec:after
			width: 9.688rem
			height: 9.688rem
			right: -2.25rem
		.story-sec:before
			width: 9.813rem
			height: 9.813rem
			top: -15%
			left: -2.5%
		.team-sec
			padding: 2rem 5rem 1rem !important
		.animation-img-box,
		.animation-img-box-down
			width: 69px
			bottom: 119px
			right: -79px
		.inner-container.style-two .row
			width: 700px !important


		.right-img-area
			height: 235px !important
			margin-top: 73px !important
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem 
				.fs1-25rem
					font-size: 13px
		.main-banner-container 
			.gg2rem
				grid-gap: 1rem
			.fs3-5rem 
				font-size: 2rem
				line-height: 39px !important
				.fs5rem
					font-size: 3rem
		.calcluter-sec
			margin-top: -32px
			.c15
				font-size: 1.2rem
				line-height: 25px !important
			.p2rem
				padding: 1rem
		.automated-sec .w60
			width: 63%
		.client-review-container
			padding: 45px 233px 60px !important
		.work-container
			padding: 70px 100px
			.pt1-5rem.pb1rem
				font-size: 0.75rem
				line-height: 27px !important
		.fs1-25rem
			font-size: 0.98rem
		#homeMainBanner .container
			max-width: 100% !important
			padding-left: 11% !important
			padding-right: 11% !important
		.privacy-policy-page 
			.w60 
				.fs3-5rem
					top: 142px !important
		.privacy-policy-page 
			.policy-box
				padding: 0px 60px 60px 60px
		.team-sec
			padding: 3rem 5rem 1rem !important
			.card-style-about
				padding: 1rem 2rem 5rem !important
			ul 
				li
					font-size: 0.75rem
			button
				padding: 0.7rem 2.125rem !important
				img
					width: 30px
			.fs3-125rem
				font-size: 2.5rem
		.action-sec .fs2-5rem
			font-size: 2rem
			line-height: 2.6rem !important
		.accounts-page-font
			.work-container
				padding: 70px 85px
				.how-its-work-circle
					top: -24px !important
					left: -27px !important
		.calcluter-sec 
			.fs3rem
				font-size: 1.8rem
		.main-banner-container .mt7rem
			margin-top: 2rem
			font-size: 1.2rem
		.work-container .fs2-125rem
			font-size: 39px
		.not-sure-container p.w100
			font-size: 12px !important
		.automated-sec .c15
			font-size: 23px !important
			line-height: 28px !important
		.carousel .slide .fs1-25rem
			font-size: 12px
			line-height: 21px !important
			width: auto
		.shadowPaperCrasouel .fs1-5rem
			font-size: 1.2rem
			line-height: 27px !important
		#homeMainBanner .fs2-5rem
			font-size: 2rem
		.investor-box
			zoom: 0.8
		.investor-box
			.list-right-area
				.benefits-box
					min-height: 276px
					line-height: 19px !important
		.custom-list li
			font-size: 13px !important
		.investor-box
			zoom: .65
		.calcluter-sec 
			.fs2rem
				font-size: 1.2rem
		.main-banner-container span.c4, .carousel .slide .fs2-125rem
			font-size: 25px !important
			line-height: 29px !important
		.comapnies-page-font 
			.silder-box
				padding: 1rem 0
			.review-img
				img
					width: 73% !important
		.not-sure-container 
			.mt6rem.mb3rem
				margin-top: 3rem
				margin-bottom: 0
			.p2rem.bg3
				zoom: 0.7
			.mt5rem
				margin-top: 3rem
		.calcluter-sec 
			.fs2-5rem
				font-size: 1.4rem
		.carousel.carousel-slider 
			.df 
				.h60
					width: 68px !important
		.login-container .MuiFormControl-root p
			bottom: -25px
		.shadowPaperCrasouel 
			span.w30
				padding: 10px 4px !important
				height: 100% !important
		.keyfeature-box-portfolio-bg 
			.w30
				padding-left: 5rem
		.register-container
			padding: 35px 0 36px 127px
		.video-container
			padding: 50px 230px
		.keyfeature-box-portfolio-innner 
			.keyfeature-box-portfolio-bg 
				.keyfeature-wihte-item
					padding: 1rem
		.for-yoy-box
			font-size: 0.7rem
		.keyfeature-box-portfolio-bg 
			.w30 
				p.pr3rem
					padding-right: 1rem
		.keyfeautre-container
			width: 1000px
		.poppins-font-style 
			.inner-container 
				.shadowContainer
					zoom: .6
		.fs2-125rem 
			font-size: 1.65rem
		.inner-container .df.jcc.aic img.h60
			height: 112px !important
		.keyfeautre-container .keyfeature-box-portfolio
			justify-content: center
		.fs1-75rem
			font-size: 1.25rem
		.df.bg1.pr.brne.bscov.h90vh
			height: 100%
		.mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80
			zoom: 0.8
		.angels-page-font .client-review-container p
			line-height: 17px !important
			font-size: 12px


		.inner-container 
			p.fs1-25rem
				font-size: 0.85rem
				line-height: 1.2rem !important
		#homeMainBanner .container
			max-width: 100% !important
			padding-left: 10% !important
			padding-right: 10% !important
		.privacy-policy-page
			.w60
				width: 70%
			.policy-box
				p
					line-height: 24px !important
		.visin-sec 
			padding-bottom: 3rem
			.container
				align-items: center
			.w30 
				img
					height: 100% !important
					margin-top: 106px !important
					width: 100%
		.accounts-page-font
			.work-container
				padding: 70px 70px
		.automated-sec .c15
			font-size: 20px !important
			line-height: 24px !important
		.client-review-container p
			font-size: .65rem
			line-height: 15px !important
		#homeMainBanner .container
			padding-top: 1.5rem
			padding-bottom: 1.5rem
		.quote-area p
			font-size: 9px
		.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
			margin-top: -21px !important
		.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user, .comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user
			margin-top: -9px !important
		.home-page-bg
			height: 86vh !important
		.register-container-not-sure 
			.pr8rem
				padding-right: 11rem
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem 
				.fs1-25rem
					font-size: 12px
		.not-sure-container 
			.scaleAnimation 
				a
					zoom: 0.7
		.register-container-not-sure 
			.form-right-image
				bottom: -6px !important
				right: -37% !important
		.inner-container
			padding: 11px 118px !important
			.px6rem
				padding-left: 0
				padding-right: 0
		.df.bg1.pr.brne.bscov.h90vh
			height: 95vh
		.home-page-menu.automated-sec .menu-bottom
			margin-top: -51px !important
			margin-bottom: 15px
		.inner-container .df.jce img.h70
			height: 101px
		.comapnies-page-font .companies-crousel .first-slide .c15.br30px
			zoom: .7
		.client-review-container .fs2-125rem
			font-size: 27px
		.angels-page-font .client-review-container p
			line-height: 18px !important
		.comapnies-page-font 
			.review-img img
				width: 100% !important
		.shadowPaperCrasouel
			width: 100%
		span.wid-btn
			width: 60%

		.automated-sec
			.w80
				width: 66%
			.c14
				font-size: 0.9rem !important
		.inner-container
			padding: 11px 90px !important
		.client-review-container
			padding: 45px 124px 60px !important
		.work-container
			padding: 70px 167px
		.not-sure-container
			padding: 100px 118px
		.accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img
			width: 100% !important
		.main-banner-container 
			.fs3-5rem
				font-size: 1.8rem
				line-height: 39px !important
		.video-container 
			img.w10.pa
					width: 60px !important

		.calcluter-sec
			width: 88%
		.poppins-font-style
			overflow: hidden
		.investor-box
			zoom: .7
		.calcluter-sec 
			.c15
				font-size: 1.2rem
				line-height: 26px !important
			.fs3rem
				font-size: 1.5rem
				line-height: 35px !important
		.inner-container
			padding: 11px 155px !important
		.work-container
			padding: 70px 97px
		#homeMainBanner .container:nth-child(odd) .w60
			padding-left: 10rem
		#homeMainBanner .container:nth-child(2n) .w60
			padding-right: 6rem
		#homeMainBanner .container .w90 .fs1-25rem
			font-size: .8rem
			line-height: 21px !important
		#homeMainBanner .container .w100.pr.df
			margin-top: 10px
		.video-container
			padding: 84px 123px 0
		.register-container
			padding: 70px 0 70px 95px
			.pr8rem
				padding-right: 0
		.triangle-vector
			right: 50% !important
			width: 150px
		.pricing-container .pl8rem.pr8rem
			padding: 0 0rem
		.pricing-container .pricing-box
			width: 21%
		.privacy-policy-page 
			.w60 
				width: 82%
		.fs3-5rem
			font-size: 3rem
		.terms-and-conditions-page 
			[style*="width: 41%"]
				width: 60% !important
		.our-thesis-sec img.ar.pa
			width: 68px
		.visin-sec
			.fs3rem
				font-size: 2.5rem
		.about-carousal-slider .slide
			zoom: 0.6
		.action-sec .fs2-5rem
			font-size: 1.8rem
			line-height: 2.4rem !important
		.accounts-page-font
			.work-container
				padding: 70px 30px
				.fs1-5rem
					font-size: 1.1rem
				.df.jcsb 
					.pb1-5rem.df.jcsb
						flex-wrap: wrap
						align-items: center !important
						.w80
							width: 90%
						.df.jce
							width: 20px !important
		.how-its-work 
			.blue-animation
				height: 15% !important
		.comapnies-page-font .main-banner-container, .accounts-page-font .main-banner-container, .lawyers-page-font .main-banner-container, .vcs-page-font .main-banner-container, .pes-page-font .main-banner-container, .angelsnetwork-page-font .main-banner-container, .angels-page-font .main-banner-container
			hight: 56.5vh
		.right-img-area
			height: 215px !important
			margin-top: -22px !important
		.register-container-not-sure
			padding: 106px 191px 28px 57px
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem 
				.fs1-25rem
					font-size: 11px
		.inner-container
			padding: 11px 103px !important
		.keyfeature-box-portfolio-bg 
			.w30
				padding-left: 5rem
				padding-top: 6rem
		.pricing-table-saf
			width: 760px
		.beta-logo
			width: 61px
			height: 37px
			margin-top: 0
			position: absolute
			left: 283px
			top: 17px
		.pricing-tabs 
			.pricing-advisor-table 
				.pricing-box
					width: 46%
		.pricing-plan-page 
			.w60.fs3-125rem
				font-size: 2.5rem
				padding-left: 9rem !important
			.df.w100.pt6rem
				padding-top: 2rem
		.slider-wrapper .first-slide .inner-container img.pa.ar.zi1
			width: 40px !important
		.register-page-container
			width: 90%
			padding: 5px 0 5px 100px
		.right-img-area .person-img
			height: 115% !important


		.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user,
		.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user,
		.comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user,
		.comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user
			margin-top: 1px !important
		.pricing-tabs .booking-sec .pricing-bottom-box
			min-height: auto
		.pricing-tabs .pricing-box:nth-child(3), .pricing-tabs .pricing-box:nth-child(4) 
			margin-bottom: 0
		.pricing-tabs .booking-sec
			width: 110%
		.pricing-tabs .booking-sec .pricing-bottom-box 
			margin-left: -14px
		.pricing-tabs .pricing-box
			margin-bottom: 13rem


		.client-review-container
			padding: 45px 59px 60px !important
		.work-container
			padding: 70px 45px
		.bottom-features
			width: 100%
		.team-sec
			padding: 2rem 30px 1rem
		.our-thesis-sec
			padding-left: 3rem
		.visin-sec .container
			padding: 0 2rem !important
		.accounts-page-font
			.work-container 
				.df.jcsb
					.pb1-5rem.df.jcsb
						flex-wrap: nowrap
				.fs1-5rem
					font-size: 1rem


		.home-page-bg
			height: 600px !important
		.main-banner-container 
			.fs3-5rem 
				.fs5rem
					font-size: 2.5rem
		.right-img-area
			height: 212px !important
			margin-top: 39px !important
			justify-content: flex-start
			.person-img
				height: 118% !important
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem
				padding-right: 3rem
		.calcluter-sec 
			.c15
				font-size: 1rem
				line-height: 23px !important
			.fdc.pl2rem
				padding-left: 0 
		.keyfeautre-container
			width: 74%
		.comapnies-page-font .keyfeautre-container
			width: 82%
		.main-banner-container 
			.fs3-5rem
				font-size: 1.4rem
				line-height: 39px !important
		.video-container
			padding: 32px 123px
		.slider-wrapper 
			.first-slide 
				.inner-container 
					img
						width: 70% !important
		.poppins-font-style .inner-container .shadowContainer
			zoom: .5
		.inner-container .df.jcc.aic img.h60
			height: 74px !important
		.angels-page-font .inner-container img.mb6rem
			width: 89% !important
			margin-bottom: 0
		.main-banner-container .col-sm.right-img-area .cubes-img
			left: -37px !important
			top: 50px !important
			width: 129px !important
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg, .keyfeautre-container .keyfeature-box
			zoom: .8
		.keyfeature-wihte-item 
			width: 18% !important
			padding: 1rem
		.work-container
			zoom: 0.8
		.not-sure-register .not-sure-container
			padding: 30px 96px 30px 173px !important
		.register-not-sure-sec
			zoom: .6
		.client-review-container
			zoom: 0.95
		.keyfeature-box-free .keyfeature-wihte-item:nth-child(2)
			width: 38% !important
		.comapnies-page-font 
			.review-img img
				width: 64% !important
		.keyfeature-wihte-item
			zoom: .7
		.df.bg1.pr.brne.bscov.h90vh
			height: 100%
			zoom: 0.6
		.msg-btn, .top-arrow-bt
			zoom: 0.8

		.comapnies-page-font .main-banner-container, 
		.accounts-page-font .main-banner-container, 
		.lawyers-page-font .main-banner-container, 
		.vcs-page-font .main-banner-container, 
		.pes-page-font .main-banner-container, 
		.angelsnetwork-page-font .main-banner-container, 
		.angels-page-font .main-banner-container
				height: 389px !important

		#expandHeader .h90vh
			height: 88vh
		.dropdown-company-dahboard
			zoom: 0.9
		.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item
			width: 23.2% !important
		.dahboard-tabs .MuiTabPanel-root .dashboard-firm-tab .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item .bottom-details.w90 .lock-icon
			zoom: 0.8
			margin-right: 1rem
		.table-advisor
			zoom: 0.8
		.client-tab .user-area
			width: 82%
		#expandHeader .h90vh
			height: 85vh
		#expandHeader .h90vh
			height: 85vh
		.box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item
			zoom: 0.8
		.dropdown-company-dahboard
			zoom: .84
			.reprot-btn-dropdowns
				width: 250px
		.dahboard-tabs .MuiTabPanel-root .add-btn-dashboard
			zoom: 0.8
		.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon
			font-size: 16px
		.banner-advisor-dashboard
			zoom: 0.8
		.table-permission .table-userPermissions
			zoom: 0.8
		#expandHeader .pr.df a svg, #expandHeader .pr.df span.usn svg
			width: 22px
		#expandHeader .c1.c6.w100.jcsb.td500.df.fdc.jcc.wsn
			left: 5px
			position: relative
		#expandHeader .pr.df a, #expandHeader .pr.df span.usn
			padding: 8px 15px 8px 40px
		.dashboard-tools-area 
			h2
				font-size: 1.7rem

		.bottom-button-slider, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item, .box-dashbord-container .keyfeature-box-core-dashboard .keyfeature-box .keyfeature-wihte-item, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item
			zoom: 0.7
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .pt10rem
			padding-top: 8rem
		.dropdown-company-dahboard .w60px.mr2rem
			margin-right: 1rem
		.dropdown-company-dahboard .w100 .w200px
			width: 150px
		.dahboard-tabs .MuiTabPanel-root .fs1-75rem
			font-size: 1.5rem
		.dahboard-tabs .MuiTabPanel-root .fs1-5rem
			font-size: 1.25rem

		.banner-advisor-dashboard, .table-permission .table-userPermissions
			zoom: .7
		.dropdown-company-dahboard
			zoom: .8

		.dashboard-tools-area h2
			font-size: 1.4rem
		.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon
			font-size: 14px
		#expandHeader .h90vh
			height: 82vh
		#expandHeader:hover #logo
			width: 7rem
		#expandHeader #logo
			margin-bottom: 0.4rem
		.advisor-dashboard-seemore .m1rem.pr.br10px.pr
			zoom: 0.8
		.progress-box-vertical-dashboard
			zoom: 0.8
		.dropdown-company-dahboard
			zoom: .82
		.advisor-index .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .advisor-index button.MuiTab-labelIcon
			font-size: 14px
			margin: 0 9px
		.progress-area.progress-dashboard
			zoom: 0.9
	@media all and (orientation: portrait)
		.right-img-area
			height: 235px !important
			margin-top: 73px !important
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem 
				.fs1-25rem
					font-size: 13px
		.main-banner-container 
			.gg2rem
				grid-gap: 1rem
			.fs3-5rem 
				font-size: 2rem
				line-height: 39px !important
				.fs5rem
					font-size: 3rem
		.calcluter-sec
			margin-top: -32px
			.c15
				font-size: 1.2rem
				line-height: 25px !important
			.p2rem
				padding: 1rem
		.automated-sec .w60
			width: 63%
		.client-review-container
			padding: 45px 233px 60px !important
		.work-container
			padding: 70px 100px
			.pt1-5rem.pb1rem
				font-size: 0.75rem
				line-height: 27px !important
		.fs1-25rem
			font-size: 0.98rem
		#homeMainBanner .container
			max-width: 100% !important
			padding-left: 11% !important
			padding-right: 11% !important
		.privacy-policy-page 
			.w60 
				.fs3-5rem
					top: 142px !important
		.privacy-policy-page 
			.policy-box
				padding: 0px 60px 60px 60px
		.team-sec
			padding: 3rem 5rem 1rem !important
			.card-style-about
				padding: 1rem 2rem 5rem !important
			ul 
				li
					font-size: 0.75rem
			button
				padding: 0.7rem 2.125rem !important
				img
					width: 30px
			.fs3-125rem
				font-size: 2.5rem
		.action-sec .fs2-5rem
			font-size: 2rem
			line-height: 2.6rem !important
		.accounts-page-font
			.work-container
				padding: 70px 85px
				.how-its-work-circle
					top: -24px !important
					left: -27px !important
		.calcluter-sec 
			.fs3rem
				font-size: 1.8rem
		.main-banner-container .mt7rem
			margin-top: 2rem
			font-size: 1.2rem
		.work-container .fs2-125rem
			font-size: 39px
		.not-sure-container p.w100
			font-size: 12px !important
		.automated-sec .c15
			font-size: 23px !important
			line-height: 28px !important
		.carousel .slide .fs1-25rem
			font-size: 12px
			line-height: 21px !important
			width: auto
		.shadowPaperCrasouel .fs1-5rem
			font-size: 1.2rem
			line-height: 27px !important
		#homeMainBanner .fs2-5rem
			font-size: 2rem
		.investor-box
			zoom: 0.8
		.investor-box
			.list-right-area
				.benefits-box
					min-height: 276px
					line-height: 19px !important
		.custom-list li
			font-size: 13px !important
		.investor-box
			zoom: .65
		.calcluter-sec 
			.fs2rem
				font-size: 1.2rem
		.main-banner-container span.c4, .carousel .slide .fs2-125rem
			font-size: 25px !important
			line-height: 29px !important
		.comapnies-page-font 
			.silder-box
				padding: 1rem 0
			.review-img
				img
					width: 73% !important
		.not-sure-container 
			.mt6rem.mb3rem
				margin-top: 3rem
				margin-bottom: 0
			.p2rem.bg3
				zoom: 0.7 !important
				padding: 1.2rem
				box-sizing: border-box
			.mt5rem
				margin-top: 3rem
		.silder-box .slick-arrow
			width: 30px !important
			height: 30px !important
		.calcluter-sec 
			.fs2-5rem
				font-size: 1.4rem
		.carousel.carousel-slider 
			.df 
				.h60
					width: 68px !important
		.login-container .MuiFormControl-root p
			bottom: -25px
		.shadowPaperCrasouel 
			span.w30
				padding: 10px 4px !important
				height: 100% !important
		.keyfeature-box-portfolio-bg 
			.w30
				padding-left: 5rem
		.register-container
			padding: 35px 0 36px 127px
		.video-container
			padding: 50px 230px
		.keyfeature-box-portfolio-innner 
			.keyfeature-box-portfolio-bg 
				.keyfeature-wihte-item
					padding: 1rem
		.for-yoy-box
			font-size: 0.7rem
		.keyfeature-box-portfolio-bg 
			.w30 
				p.pr3rem
					padding-right: 1rem
		.keyfeautre-container
			width: 1000px
		.poppins-font-style 
			.inner-container 
				.shadowContainer
					zoom: .6
		.fs2-125rem 
			font-size: 1.65rem
		.inner-container .df.jcc.aic img.h60
			height: 112px !important
		.keyfeautre-container .keyfeature-box-portfolio
			justify-content: center
		.fs1-75rem
			font-size: 1.25rem
		.df.bg1.pr.brne.bscov.h90vh
			height: 100%
		.mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80
			zoom: 0.8
		.angels-page-font .client-review-container p
			line-height: 17px !important
			font-size: 12px
		.home-page-bg
			height: 600px !important
		.main-banner-container 
			.fs3-5rem 
				.fs5rem
					font-size: 2.5rem
		.right-img-area
			height: 212px !important
			margin-top: 39px !important
			.person-img
				height: 326px !important
				width: 100%
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem
				padding-right: 3rem
		.calcluter-sec 
			.c15
				font-size: 1rem
				line-height: 23px !important
			.pl2rem
				padding-left: 0
		.keyfeautre-container
			width: 90%
		.keyfeature-wihte-item
			width: 26% !important
		.keyfeature-box-free 
			.keyfeature-wihte-item:nth-child(2)
				width: 50% !important
		.register-not-sure-sec 
			.register-container-not-sure
				width: 100%
			.form-right-image
				bottom: -6px !important
				right: -28% !important
			.not-sure-register
				width: 100%
				.not-sure-container
					padding: 78px 30px !important
					.fdc
						flex-direction: row
						justify-content: center
						width: 100%
						.scaleAnimation
							margin: 0 15px
							display: flex
							text-align: center
							flex-direction: column
		.main-banner-container 
			.fs3-5rem
				font-size: 1.4rem
				line-height: 39px !important
		.video-container
			padding: 32px 123px
		.keyfeature-box-portfolio-bg 
			.w30
				padding-top: 3rem
				padding-left: 0rem
				width: 100%
			.w70
				width: 100%
				padding-left: 6rem
		.slider-wrapper 
			.first-slide 
				.inner-container 
					img
						width: 70% !important
		.df.bg1.pr.brne.bscov.h90vh
			height: 45vh

		.automated-sec
			.w80
				width: 66%
			.c14
				font-size: 0.9rem !important
		.inner-container
			padding: 11px 90px !important
		.client-review-container
			padding: 45px 124px 60px !important
		.work-container
			padding: 70px 167px
		.not-sure-container
			padding: 100px 118px
		.accounts-page-font .client-review-container .review-img img, .lawyers-page-font .client-review-container .review-img img
			width: 100% !important
		.main-banner-container 
			.fs3-5rem
				font-size: 1.8rem
				line-height: 39px !important
		.video-container 
			img.w10.pa
					width: 60px !important
					
		.calcluter-sec
			width: 88%
		.poppins-font-style
			overflow: hidden
		.investor-box
			zoom: .7
		.calcluter-sec 
			.c15
				font-size: 1.2rem
				line-height: 26px !important
			.fs3rem
				font-size: 1.5rem
				line-height: 35px !important
		.inner-container
			padding: 11px 155px !important
		.work-container
			padding: 70px 97px
		#homeMainBanner .container:nth-child(odd) .w60
			padding-left: 10rem
		#homeMainBanner .container:nth-child(2n) .w60
			padding-right: 6rem
		#homeMainBanner .container .w90 .fs1-25rem
			font-size: .8rem
			line-height: 21px !important
		#homeMainBanner .container .w100.pr.df
			margin-top: 10px
		.video-container
			padding: 84px 123px 0
		.register-container
			padding: 70px 0 70px 95px
			.pr8rem
				padding-right: 0
		.triangle-vector
			right: 50% !important
			width: 150px
		.pricing-container .pl8rem.pr8rem
			padding: 0 0rem
		.pricing-container .pricing-box
			width: 21%
		.privacy-policy-page 
			.w60 
				width: 82%
		.fs3-5rem
			font-size: 3rem
		.terms-and-conditions-page 
			[style*="width: 41%"]
				width: 60% !important
		.our-thesis-sec img.ar.pa
			width: 68px
		.visin-sec
			.fs3rem
				font-size: 2.5rem
		.about-carousal-slider .slide
			zoom: 0.6
		.action-sec .fs2-5rem
			font-size: 1.8rem
			line-height: 2.4rem !important
		.accounts-page-font
			.work-container
				padding: 70px 30px
				.fs1-5rem
					font-size: 1.1rem
				.df.jcsb 
					.pb1-5rem.df.jcsb
						flex-wrap: wrap
						align-items: center !important
						.w80
							width: 90%
						.df.jce
							width: 20px !important
		.how-its-work 
			.blue-animation
				height: 15% !important
		.comapnies-page-font .main-banner-container, .accounts-page-font .main-banner-container, .lawyers-page-font .main-banner-container, .vcs-page-font .main-banner-container, .pes-page-font .main-banner-container, .angelsnetwork-page-font .main-banner-container, .angels-page-font .main-banner-container
			hight: 56.5vh
		.right-img-area
			height: 215px !important
			margin-top: -22px !important
		.register-container-not-sure
			padding: 106px 191px 28px 57px
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem 
				.fs1-25rem
					font-size: 11px
		.inner-container
			padding: 11px 103px !important
		.keyfeature-box-portfolio-bg 
			.w30
				padding-left: 5rem
				padding-top: 6rem
		.pricing-table-saf
			width: 760px
		.beta-logo
			width: 61px
			height: 37px
			margin-top: 0
			position: absolute
			left: 283px
			top: 17px
		.pricing-tabs 
			.pricing-advisor-table 
				.pricing-box
					width: 46%
		.pricing-plan-page 
			.w60.fs3-125rem
				font-size: 2.5rem
				padding-left: 9rem !important
			.df.w100.pt6rem
				padding-top: 2rem
		.slider-wrapper .first-slide .inner-container img.pa.ar.zi1
			width: 40px !important
		.register-page-container
			width: 90%
			padding: 5px 0 5px 100px
			
		.client-review-container
			padding: 45px 59px 60px !important
		.work-container
			padding: 70px 45px
		.bottom-features
			width: 100%
		.team-sec
			padding: 2rem 30px 1rem
		.our-thesis-sec
			padding-left: 3rem
		.visin-sec .container
			padding: 0 2rem !important
		.accounts-page-font
			.work-container 
				.df.jcsb
					.pb1-5rem.df.jcsb
						flex-wrap: nowrap
				.fs1-5rem
					font-size: 1rem

		.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user,
		.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user,
		.comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user,
		.comapnies-page-font #homeMainBanner .container:nth-child(12) .testmonial-user
			margin-top: 1px !important
		.pricing-tabs .booking-sec .pricing-bottom-box
			min-height: auto !important
		.pricing-tabs .pricing-box:nth-child(3), .pricing-tabs .pricing-box:nth-child(4)
			margin-bottom: 0
		.pricing-tabs .booking-sec
			width: 110% !important
		.pricing-tabs .booking-sec .pricing-bottom-box
			margin-left: -14px
		.pricing-tabs .pricing-box
			margin-bottom: 13rem !important
		.comapnies-page-font .main-banner-container, 
		.accounts-page-font .main-banner-container, 
		.lawyers-page-font .main-banner-container, 
		.vcs-page-font .main-banner-container, 
		.pes-page-font .main-banner-container, 
		.angelsnetwork-page-font .main-banner-container, 
		.angels-page-font .main-banner-container
			height: 495px !important
		.certification-pricing-column
			right: auto !important
			position: relative
			top: 0
		.certificates-page
			.heading-area
				background-size: 100% 114%
			.certificates-current
				.packge-select
					flex-wrap: wrap
					justify-content: space-between
					.audit-pakcges
						width: 26%
						margin: 0
			.badges-sec
				.badges-area
						flex-wrap: wrap
						.left-upgrade-button
							text-align: center
							margin-top: 0
							margin-right: 0
							position: relative
							margin-bottom: 20px
			.data-check-sec
				.gtcr3
					grid-template-columns: repeat(2, 1fr)
		.investmentcertificate-page 
			.certificate-area
				.left-area
					.signure-area
						.date-area
							&.singature-area
								margin-top: 0
		.table-of-content
			width: 91%
		.progress-dashboard
			.progress-box
				margin-top: 10px
				.progress-bar
					width: 40px !important
					height: 40px !important
				.text-center-area
					font-size: 12px !important
		.investor-modal-admin .jcsb .ml1rem.w100
			margin: 0
		.investor-modal-admin .jcsb .mr1rem
			margin-right: 0 !important
			margin-bottom: 20px
		.register-page-container
			width: 111%
			padding: 5px 0 5px 100px
		.login-page 
			.login-container 
				.pr8rem
					padding-right: 0
		.home-page-bg
			height: 100% !important
		.home-page-menu.automated-sec .menu-bottom
			margin-top: 0 !important
		.home-page-banner .col-sm.df.jce.pr
			width: 60% !important
			justify-content: center
			margin: 0 auto
		.comapnies-page-font .companies-crousel .first-slide .c15.br30px
			width: 73%
		.investor-box
			zoom: 0.6
		#homeMainBanner .container
			padding-left: 30px !important
			padding-right: 30px !important
		.main-banner-container
			.col-sm
				width: 100% !important
				justify-content: center
				&.right-img-area
					margin: 24px auto 0
					.person-img
						margin: 0 auto
			.row
				flex-wrap: wrap !important
		.automated-sec 
			.menu-bottom
				width: 85%
		.automated-sec 
			.c15
				font-size: 19px !important
				line-height: 25px !important
			.w60
				width: 92%
				padding: 10px
				border-radius: 10px
		.right-side-zak
			width: 94px
			height: auto !important
		.calcluter-sec
			padding: 2rem 3rem
			.w100
				width: 91%
				margin: 0 auto
		.landing-page-font 
			.inner-container 
				padding: 11px 102px !important
				.shadowContainer
					border-radius: 14px
					padding: 20px
		.client-review-container
			padding: 45px 33px 60px !important
			.pr3rem.pl3rem
				padding: 1.5rem 1.5rem 0
			.w60
				padding-right: 0
		#homeMainBanner .container:nth-child(odd) .w60
			padding-left: 5rem
		#homeMainBanner .container:nth-child(2n) .w60
			padding-right: 3rem
		#homeMainBanner .row.pl2rem.pr2rem.w50
			width: 100%
		.pricing-container 
			.pricing-box
				width: 30%
			.pl8rem.pr8rem
				padding: 0
		.register-container
			width: 100%	
			padding: 70px 0px 70px 30px
		.ar.pa.blue-circle
			right: 29px !important
		.not-sure-container
			padding: 70px 30px
		.privacy-policy-page 
			.policy-box
				padding: 0px 30px 40px 30px
			.w60
				width: 90%
				border-radius: 10px
				.fs1rem-screen1
					font-size: 0.75rem
		.privacy-policy-page 
			.w60 
				.fs3-5rem
					font-size: 2.5rem
			.policy-box 
				.pb2rem
					padding-bottom: 1.3rem
		.our-thesis-sec 
			padding-left: 30px
			padding-right: 30px
			.w60, .w40
				width: 100%
		.visin-sec 
			.container
				align-items: center
				padding: 0 30px !important
				.w60
					width: 100%
					padding-right: 0
				.w30
					width: 100%
					text-align: center
					img
						width: 62%
						height: 100% !important
						margin: 20px auto 0 !important
		.story-sec
			padding: 3rem !important
		.team-sec 
			.dg.aic
				display: flex
				align-items: center
				flex-wrap: wrap
				justify-content: center
				.df.fdc
					width: 47%
					justify-content: center
					margin-bottom: -150px
		.about-carousal-slider .slide
			zoom: .4
		.action-sec.dg
			display: flex
			flex-wrap: wrap
			.left-area 
				width: 100%
				margin-bottom: 22px
			.right-area
				width: 39%
				margin: 0 auto
		.accounts-page-font 
			.work-container 
				.df.jcsb
					.work-card-box
						width: 24%
						margin-bottom: 28px
		.our-thesis-sec 
			.w40
				margin-right: -60px
				margin-top: -63px
		.our-thesis-sec img.ar.pa
			width: 39px
		.card-style-about
			padding: 20px 30px 60px !important
		.story-sec h4
				font-size: 2.5rem !important
		.narBarAnimation
			display: none
		.inner-container
			padding: 11px 15px !important
			justify-content: center
			display: flex
			.row.df.jcc 
				img
					width: 100% !important
					height: 100% !important
		.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
			margin-top: -8px !important
		.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user,
		comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user
			margin-top: 1px !important
		.register-not-sure-sec 
			.form-right-image
				display: none
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem
				padding-right: 0
				width: 100% !important
		.keyfeature-wihte-item
			width: 24% !important
		.work-container
			max-width: 90% !important
		.register-container
			padding: 48px 30px
		.slider-wrapper 
			.first-slide 
				.inner-container 
					img.pa.ar.zi1
						width: 52px !important
		.poppins-font-style 
			.inner-container 
				.shadowContainer
					zoom: .7
					width: 80% !important
					margin: 0 auto 35px
				.df.fdc.jcsb
					width: 77% !important
					margin: 0 auto
					.shadowContainer
						width: 100% !important
		.inner-container .row.df.jcsb .df.jce.aic
			width: 100% !important
			justify-content: center
		.calcluter-sec 
			.w60 
				a
					text-align: center
					padding-left: 2rem
		.pricing-plan-page 
			.w60.fs3-125rem
				font-size: 2rem
				padding-left: 8rem !important
		.inner-container .row.df.jcsb
			justify-content: center
			flex-wrap: wrap
			width: 100%
		.team-sec .team-about-column .team-box
			width: 33%
			padding: 0 15px
		.silder-box .slick-arrow:before
			width: 90px
			height: 90px
		.team-sec .team-about-column .team-box .card-style-about
			max-height: 100% !important
			min-height: 100% !important
		.ceo-sec
			padding: 3rem 5.5rem
		.comapnies-page-font .main-banner-container, 
		.accounts-page-font .main-banner-container, 
		.lawyers-page-font .main-banner-container, 
		.vcs-page-font .main-banner-container, 
		.pes-page-font .main-banner-container, 
		.angelsnetwork-page-font .main-banner-container, 
		.angels-page-font .main-banner-container
			height: 543px
		.keyfeature-box-portfolio-innner 
			.keyfeature-box-portfolio-bg 
				.keyfeature-wihte-item
					width: 46% !important
		.keyfeature-box-portfolio 
			.keyfeature-box-portfolio-innner 
				.keyfeature-box-portfolio-bg 
					.keyfeature-wihte-item:nth-child(3)
						top: 0
		.keyfeature-box-portfolio-innner:before, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before
			border-radius: 50px
			width: 97%
			left: 0
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before
			left: 21px
		.keyfeature-box-portfolio-bg 
			.w70
				padding-left: 2rem
		.keyfeature-wihte-item
			width: 41% !important
		.work-container 
			.df.jcsb 
				.work-card-box
					width: 40%
					margin-bottom: 30px
			.df.jcc
				flex-wrap: wrap
				.work-card-box
					width: 40%
					margin: 0 15px 34px
		.slider-wrapper 
			.first-slide 
				.inner-container 
					img
						width: 44% !important
		.pricing-table-saf
			width: 570px
		.pricing-tabs 
			.booking-sec
				flex-wrap: wrap
				margin-top: -8px
				.pricing-bottom-box
					width: 45%
					margin: 15px auto !important
		.login-page
			flex-wrap: wrap
			height: 100%
			.login-container
				width: 100%
				padding: 100px 50px 50px
			.form-right-image
				width: 100% !important
				height: 373px
		.investorLookup-container
			padding: 0 30px 70px
		.investor-box
			zoom: 0.5
		.main-banner-container
			padding: 0 45px 
		.new-header 
			.container
				padding: 0 45px !important
		.calcluter-sec 
			.c15
				font-size: 1rem
				line-height: 23px !important
			.fs3rem
				margin-top: 1rem
				font-size: 1.2rem
				line-height: 27px !important
		.landing-page-font 
			.silder-box
				padding: 1rem
			.inner-container 
				p.fs1-25rem
					font-size: .75rem
					line-height: 1.3rem !important
		.work-container
			padding: 45px 30px
			.jcsb
				.work-card-box
					width: 33%
					margin: 0 15px 25px
		#homeMainBanner 
			.container
				.w60
					width: 100%
		#homeMainBanner .container:nth-child(odd) .w60,
		#homeMainBanner .container:nth-child(2n) .w60
			padding: 0rem
		#homeMainBanner .container .w40
			margin: 0 auto
			width: 60%
		#homeMainBanner .container .w100.pr.df
			margin-top: 0
			width: 100%
			justify-content: center !important
		#homeMainBanner .container .w100.pr.df .br40px.pt1rem.pb1rem.pl3rem.pr2rem
			width: 100% !important
		.video-container
			padding: 49px 30px 0
		.not-sure-container
			.df.jcsb
				flex-wrap: wrap
				justify-content: center
				.scaleAnimation
					margin-bottom: 43px
		#homeMainBanner .container .w50,
		#homeMainBanner .container .br40px.pt1rem
			width: 100% !important
		.beta-logo
			position: absolute
			left: 23%
			top: 15%
		.calcluter-sec
			padding: 1rem 1rem
			zoom: 0.8
		.inner-container 
			.df.jce.aic
				width: 55% !important
				justify-content: center
				margin: 0 auto
				gap: 5px !important
		.keyfeature-box-free 
			.keyfeature-wihte-item:nth-child(2)
				width: 100% !important
				.df.fw500.fs1rem.c15
					justify-content: space-between
					align-items: center
		.keyfeature-wihte-item
			width: 40% !important
		.work-container .df.jcsb .work-card-box, .work-container .df.jcc .work-card-box, .accounts-page-font .work-container .df.jcsb .work-card-box
			width: 38%
		.main-banner-container 
			.fs1-75rem
				font-size: 1rem
		.inner-container 
			.df.fdc.jcsa
				width: 100% !important
		.df.bg1.pr.brne.bscov.h90vh
			height: 100%
		.pricing-plan-page 
			.w60.fs3-125rem 
				padding-left: 3rem !important
		.pricing-plan-page .df.fdc.aic.mt1rem
			width: 50% !important
		.pricing-plan-page .w70 .df.jcsb.w100, .pricing-plan-page .w80 .df.jcsa.w100
			justify-content: center
			flex-wrap: wrap
		.register-page
			flex-wrap: wrap
			height: 100%
			.register-page-container
				width: 100%
				padding: 5px 38px
				box-sizing: border-box
				.pr8rem
					padding-right: 0
			.form-right-image
				width: 100% !important
				position: relative
				height: 400px
		.team-sec .team-about-column .team-box
			width: 46%
			padding: 0 15px
		.ceo-sec
			padding: 3rem 1.5rem
		.ceo-sec .left-ceo-text
			width: 100%
			margin-bottom: 33px
		.ceo-sec .righ-ceo-pic
			width: 100%
			margin: 0 auto
			text-align: center
		.team-sec
			padding: 2rem 1.5rem 1rem !important
		.silder-box .bg1.aic
			padding: 0 1.5rem !important
		.team-sec .team-about-column
			justify-content: center
			flex-wrap: wrap
		.action-sec.dg .tac.bg1.cp
			margin: 0 auto
		.carousel-button.prev
			left: 0
		.carousel-button.next
			right: 0
		.inner-container.style-two .row
			width: 100%
		.calcluter-sec .w30, .calcluter-sec .w70
			width: 100%
		.automated-sec
			.menu-bottom
				.fs1rem-screen1
					font-size: 0.7rem
		.automated-sec .menu-bottom, #root[style*="margin-top: 72px"] .narBarAnimationUP .fs1rem-screen1, 
		#root[style*="margin-top: 72px"] .narBarAnimation .fs1rem-screen1
			font-size: 0.7rem
		.landing-page-font 
			.inner-container 
				padding: 11px 95px 0 !important
				.shadowContainer
					width: 100% !important
					margin-bottom: 17px
				.df.jce.aic
					width: 100% !important
					gap: 5px !important
					margin: 0 auto
					justify-content: center
					img
						height: auto
						width: 131px !important
		.client-review-container 
			.df.w100
				flex-wrap: wrap
			.w60
				padding-right: 0
				width: 100%
			.w40
				width: 60%
				justify-content: center
				margin: 0 auto
		.work-container 
			.jcsb 
				.work-card-box
					width: 100%
					margin: 0 0 25px
		.accounts-page-font 
			.work-container 
				.df.jcsb 
					.work-card-box
						width: 100%
						margin: 0 0 25px
		.work-card-box
			width: 100%
			margin-bottom: 25px
		.work-container 
			.df.jcc
				flex-wrap: wrap 
		.pricing-container 
			.pricing-box
				width: 44%
		.triangle-vector
			width: 100px
		.pricing-container .pricing-box.mr5rem
			margin-right: 15px
		.pricing-title .right-text-for
			padding-right: 0 !important
		.pricing-title .left-text-for
			padding-left: 0
		.action-sec
			padding: 3rem 30px !important
			.fs2-5rem
				font-size: 1.5rem
		.action-sec.dg .right-area
			width: 58%
		.beta-logo
			left: 183px !important
			top: 16px
		.pricing-plan-page 
			.w60.fs3-125rem
				width: 100%
				padding-bottom: 2rem
		.main-banner-container.home-page-banner .fs3rem, .main-banner-container.home-page-banner .fs3rem .c4
			font-size: 39px !important
			line-height: 46px !important
			margin: 20px 0
		.main-banner-container.home-page-banner .pt4rem .fs2-5rem
			font-size: 1.2rem
			margin-top: 1rem
		.pricing-tabs 
			.pricing-advisor-table 
				.pricing-box
					width: 99%
		.investor-box
			zoom: 0.4
		.main-banner-container,
		.new-header .container
			padding: 0 30px 30px
		.main-banner-container 
			.fs1-5rem
				font-size: 1rem
			.fs2rem
				font-size: 1.3rem
			span.c4
				font-size: 25px !important
		.calcluter-sec
			flex-wrap: wrap
			.w100
				flex-wrap: wrap
				padding: 2rem 1rem 1rem
			.w60
				width: 100%
			.w40
				width: 100%
				margin-top: 20px
		#homeMainBanner .container
			max-width: 100% !important
			padding: 0 30px !important
		.blue-animation
			width: 77px !important
			height: auto !important
			top: -40px !important
		.circle-animation
			width: 68px !important
			height: auto !important
		.quote-icon
			width: 20px !important
			svg
				width: 20px
		#homeMainBanner .container .w90 .fs1-25rem
			padding-left: 0.5rem
		#homeMainBanner .container:nth-child(odd) .w60
			margin-bottom: 13px
		#homeMainBanner .container .w60 .custom-list,
		#homeMainBanner .container .w60 .custom-list li
			width: 95% !important
			font-size: 17px
		.bottom-features 
			.column
				flex: 0 0 50%
		.video-container p.fs2-125rem
			line-height: 33px !important
			font-size: 1.2rem
		.video-container .w70
			width: 99%
		.pricing-container 
			.pricing-box
				width: 43%
		.register-container 
			.df.gg1rem.pt0-5rem.pb0-5rem
				flex-wrap: wrap
			.w50
				width: 100%	
		.not-sure-container .bsflp.bg1.br10px
			padding: 27px 15px
			width: 41% !important
		.not-sure-container .w100.df.jcc p
			width: 100% !important
		.side-blue-circle
			width: 70px
			height: auto
			right: 38px !important
			bottom: 0 !important
		.not-sure-container
			.side-blue-circle
				width: 70px !important
				height: auto
				bottom: 0 !important
				right: 0 !important
		.privacy-policy-page 
			.w60
				justify-content: center
				flex-wrap: wrap
				padding: 5px
				.fs1rem-screen1
					margin: 0 5px
			.policy-box
				.pt2rem
					padding-top: 1rem
				.pt1rem
					padding-top: 0.6rem
		.fs3-5rem
			font-size: 2.5rem
		.terms-and-conditions-page 
			[style*="width: 41%"]
				width: 88% !important
				border-radius: 10px
				padding: 12px 10px
		.team-sec .dg.aic .df.fdc
			justify-content: center
			width: 100%
			margin-bottom: -120px
		.team-sec .fs3-125rem
			font-size: 2rem
		.about-carousal-slider .slide
			zoom: .3
		.poppins-font-style 
			.inner-container 
				.shadowContainer
					padding: 15px
					width: 100% !important
		.video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem
			width: 100%
			font-size: 25px
		#homeMainBanner .fs2-5rem
			font-size: 1.5rem
		.work-container .fs1-5rem
			font-size: 0.7rem
			line-height: 16px !important
		.poppins-font-style .inner-container .shadowContainer
			width: 51% !important
		.register-not-sure-sec
			overflow: hidden
		.calcluter-sec 
			.w40 
				img
					width: 60%
					margin-bottom: 20px
		.keyfeature-wihte-item
			width: 100% !important
			margin-right: 0 !important
		.work-container .df.jcsb .work-card-box, .work-container .df.jcc .work-card-box, .accounts-page-font .work-container .df.jcsb .work-card-box
			width: 75%
		.register-not-sure-sec 
			.not-sure-register 
				.not-sure-container 
					.fdc
						flex-wrap: wrap
		.register-not-sure-sec 
			.not-sure-register 
				.not-sure-container 
					.fdc 
						.scaleAnimation
							margin-bottom: 45px
		.poppins-font-style 
			.inner-container 
				.shadowContainer
					width: 100% !important
		.bottom-button-slider 
			.fs1rem.df.jcc
				width: 69%
		#homeMainBanner 
			.mt4rem 
				.fs2-5rem
					font-size: 1.5rem
		.inner-container.style-two 
			.row
				width: 100%
		.investorLookup-container 
			.fs2rem
				font-size: 1.5rem
				line-height: 2rem !important
				padding: 50px 0
		.pricing-table-saf
			width: 80%
		.pricing-plan-page 
			.w60.fs3-125rem
				padding-bottom: 2rem
				font-size: 1.5rem
				line-height: 2rem !important
		.pricing-plan-page 
			.w40.pr.df
				width: 56%
				justify-content: center
				margin: 0 auto
		.bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem
			zoom: 0.6
			padding: 34px 15px
		.bg1.pricing-plan-page  h4.fs2rem.fw400.mb2rem.c15
			line-height: 28px !important
			max-width: 100% !important
			font-size: 1.1rem
		.comapnies-page-font .inner-container .shadowContainer .df.aic, .comapnies-page-font .inner-container .shadowContainer .df.aic p
			justify-content: start
		.poppins-font-style .inner-container .shadowContainer .br10px.mt1rem svg
			width: 39px
		.register-page .register-page-container .w50
			width: 100%
		.register-page .register-page-container .df.gg1rem
			flex-wrap: wrap
		.keyfeature-box-free .keyfeature-wihte-item:nth-child(2) .df.fw500.fs1-25rem.c15
			flex-wrap: wrap
		.inner-container .row.df.jcsb .df.jce.aic img.h70
			height: 50%
		.comapnies-page-font .companies-crousel .first-slide .c15.br30px
			width: 73%
			zoom: 0.8
		.comapnies-page-font .companies-crousel .first-slide .new-shadow .icon img.w30px
			width: 20px
		.silder-box .slick-slider
			padding: 0 80px
		.team-sec .team-about-column .team-box
			width: 100%
			padding: 0 15px
			zoom: 1
		.action-sec
			padding: 2rem 1.5rem !important
		.poppins-font-style .df.w100 div[style="width: 55%; height: 100%;"]
			width: 100% !important
		.poppins-font-style .df.w100
			flex-wrap: wrap
		.poppins-font-style .df.w100 .df.aic.jce
			width: 100% !important
		.poppins-font-style .df.w100 .w90.fw500.c15.pl1rem.df.aic
			font-size: 20px !important
			line-height: 30px !important
		.seemore-box .seemore-inner
			padding: 25px 15px 15px
		.seemore-box .seemore-inner .pl3rem
			width: 100% !important
			padding-left: 1rem
		.team-sec .team-about-column .team-box .card-style-about
			zoom: 0.6
		.poppins-font-style .df.w100 p.bg6.c1.fw600.br15px
			zoom: 0.6
		.pricing-tabs .pricing-advisor-table .px4rem
			padding: 0
			margin: 0 auto
		.home-page-menu.automated-sec .menu-bottom
			margin-top: -37px !important
		.main-banner-container .fs3-5rem .fs5rem
			font-size: 1.8rem
			margin: 10px 0
			display: block
			line-height: 2.5rem
		.login-page 
			.login-container
				width: 100%
				padding: 100px 25px 50px
				.pr8rem 
					.df.jcsb
						flex-wrap: wrap
		.comapnies-page-font .companies-crousel .first-slide .c15.br30px
			zoom: 0.5
			.icon
				img
					vertical-align: middle
		.main-banner-container 
			span.c4
				font-size: 22px !important
			.fs2rem
				font-size: 1rem
			.col-sm
				grid-gap: 0.5rem
		.menu-bottom
			flex-wrap: wrap
			justify-content: center
			.fs1rem-screen1
				margin: 0 5px
		#root[style*="margin-top: 72px"] .narBarAnimationUP, 
		#root[style*="margin-top: 72px"] .narBarAnimation
			flex-wrap: wrap
			justify-content: center
			display: none
		.carousel .slider-wrapper .slide .c15
			font-size: 1.2rem
			line-height: 2rem
		.carousel .slider-wrapper .slide .fs1rem.c15
			font-size: 1rem
		.carousel-button.prev
			left: -24px !important
		.carousel-button.next
			right: -24px !important
		.landing-page-font .inner-container
			padding: 11px 0px 0 !important
		.landing-page-font .inner-container .shadowPaperCrasouel .c15
			font-size: 1rem
			line-height: 1.3rem !important
		.landing-page-font .inner-container .df.jce.aic img
			width: 61px !important
		#homeMainBanner .container .w100.pr.df .br40px.pt1rem.pb1rem.pl3rem.pr2rem
			padding: 0 1.7rem 20px
		.quote-area
			flex-wrap: wrap
			.w90
				width: 100%
		.pricing-title .right-text-for
			display: none
		.pricing-title
			width: 100%
			justify-content: center
		#homeMainBanner .container .w90 .fs1-25rem
			padding: 0
		.feature-review-img
			flex-wrap: wrap
			img
				width: 40px
				height: 40px
				margin: 15px 0
			p
				line-height: 1rem !important
				padding: 0 0 8px
		#homeMainBanner 
			.container 
				.w40
					width: 100%
					margin: 18px auto
				.w100.pr.df
					margin-bottom: 20px
		.bottom-features 
			.column
				flex: 0 0 100%
		.pricing-container 
			padding: 51px 10px
			.pricing-box
				width: 100%
		.ar.pa.blue-circle
			right: 29px !important
			top: 34px !important
		.register-container 
			padding: 70px 15px 70px 15px
		.register-container button
			width: 100% !important
		.not-sure-container .bsflp.bg1.br10px
			padding: 27px 15px
			width: 93% !important
		#homeMainBanner .container
			padding: 0 10px !important
		.client-review-container
			padding: 45px 13px 60px !important
			.fs2-125rem
				text-align: center
		.fs2-125rem
			font-size: 1.25rem
		.review-img img
			width: 100% !important
			height: 200px !important
		.landing-page-font .silder-box
			padding: 1rem
		.calcluter-sec
			padding: 2rem 1rem
		.video-container
			padding: 49px 10px 0
		.triangle-vector
			width: 59px
			right: 34px !important
		#homeMainBanner .container .w60 .pl3rem
			padding-left: 1rem
		.df.jcc.mt5rem
			margin-top: 2rem
			p
				width: 100%
		.form-right-image
			width: 0 !important
		.fs3-5rem
			font-size: 2.2rem
		.privacy-policy-page
			.policy-box
				p, ol li, ul li
					word-break: break-all
		.terms-and-conditions-page 
			[style*="width: 41%"]
				justify-content: center
				flex-wrap: wrap
		.privacy-policy-page
			ul, ol
				padding-left: 5px
		.privacy-policy-page 
			.policy-box 
				.fs2rem.p3rem
					padding: 10px
					text-align: center
					font-size: 1.2rem
		.our-thesis-sec .fs3-5rem
			font-size: 1.8rem !important
		.visin-sec
			.fs3rem
				font-size: 2rem
		.story-sec
			padding: 50px 30px !important
		.about-carousal-slider 
			padding: 0 15px !important
			.slide
				zoom: .2
				min-width: 100% !important
		.carousel.carousel-slider
			padding: 0rem 0 4rem !important
		.action-sec.dg .right-area
			width: 76%
			zoom: 0.5
		.team-sec .fs3-125rem
			font-size: 1.7rem
		.bottom-features
			flex-direction: column
			width: 100%
			[style*="width: 20%"]
				width: 100% !important
		#homeMainBanner h1
			text-align: center
		.video-container img.w10.pa
			width: 50px !important
		.not-sure-container .df.jcsb .scaleAnimation
			margin-bottom: 43px
			width: 100% !important
		.carousel .slider-wrapper.axis-horizontal 
			.slider 
				.slide 
					.fw400.fs1-25rem.c15.mb1rem
						font-size: 0.9rem
		.carousel .slider-wrapper 
			.slide 
				.fs1rem.c15
					font-size: 1rem
					width: 78%
		.slider-wrapper 
			.inner-container 
				.row.df.jcsb 
					.df.jce.aic 
						img.h80
							height: 100%
							zoom: 0.3
		.keyfeautre-container
			max-width: 90%
			width: 90%
			.keyfeature-box
				box-sizing: border-box
		.keyfeature-box-free 
			.keyfeature-wihte-item
				box-sizing: border-box
			.keyfeature-wihte-item:nth-child(2)
				width: 100% !important
				.df.fw500.fs1rem.c15
					flex-wrap: wrap
				a
					width: 100%
					margin-top: 16px
		.how-its-work 
			.blue-animation
				height: 5% !important
		.poppins-font-style .inner-container .shadowContainer .df.aic, .poppins-font-style .inner-container .shadowContainer .df.aic p
			flex-wrap: wrap
		.poppins-font-style .inner-container .shadowContainer .df.aic span
			width: 100%
		.poppins-font-style .inner-container .shadowContainer .df.aic p .cp.w100.df.jce.c1
			justify-content: center
			margin: 10px 0
			flex-wrap: nowrap
		.keyfeature-box-portfolio-innner 
			.keyfeature-box-portfolio-bg 
				.keyfeature-wihte-item
					width: 100% !important
					margin-right: 0 !important
					margin-left: 0 !important
		.poppins-font-style
			overflow: hidden
		.investor-box
			zoom: .3
		.work-container 
			.fs2-125rem
				font-size: 25px
		.main-banner-container 
			.fs3-5rem
				font-size: 1.3rem
				line-height: 39px !important
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before
			top: 47%
		.slider-wrapper .first-slide .inner-container img.pa.ar.zi1
			display: none
		.pricing-tabs 
			.booking-sec 
				.pricing-bottom-box
					width: 100%
					margin: 15px auto !important
		.pricing-plan-page .df.fdc.aic.mt1rem
			width: 100% !important
		.main-banner-container .col-sm.right-img-area .cubes-img
			left: -28px !important
			top: 46px !important
		.poppins-font-style .df.w100
			line-height: 1.3rem !important
		.pricing-tabs .MuiTabs-root button
			margin-right: 4px
			font-size: 12px
		.pricing-tabs .pricing-advisor-table .px4rem
			display: none !important
		.silder-box .slick-slider
			padding: 0 41px
		.fs1-25rem
			font-size: .8rem
		.main-banner-container 
			span.c4
				font-size: 18px !important
			.fs2rem
				font-size: 0.8rem
			.col-sm
				grid-gap: 0.4rem
		.automated-sec 
			.c15
				font-size: 16px !important
				line-height: 20px !important
			.c14
				font-size: .7rem !important
				line-height: 1rem !important
		.register-container .pt1rem.pb1rem .fs1-125rem
			font-size: 0.7rem
			margin-left: 0.4rem
		.privacy-policy-page 
			.w60 
				.fs3-5rem
					font-size: 1.8rem
		#homeMainBanner .fs2-5rem
			font-size: 1.3rem
		.main-banner-container 
			.fs3-5rem
				font-size: 1.2rem
				line-height: 26px !important
		.new-header 
			.container
				padding: 0 10px !important
		.client-review-container 
			.fs2-125rem
				text-align: center
				line-height: 40px !important
				font-size: 1.4rem
		.beta-logo
			top: 4px
			left: 147px !important
			width: 60px
			height: 40px
		.comapnies-page-font .main-banner-container, 
		.accounts-page-font .main-banner-container, 
		.lawyers-page-font .main-banner-container, 
		.vcs-page-font .main-banner-container, 
		.pes-page-font .main-banner-container, 
		.angelsnetwork-page-font .main-banner-container, 
		.angels-page-font .main-banner-container
			height: 81.5vh !important
		.main-banner-container 
			.fs3-5rem
				font-size: 1.1rem
				line-height: 21px !important
		.poppins-font-style 
			.inner-container 
				.shadowContainer
					width: 76% !important
					zoom: 0.6
		.calcluter-sec
			width: 100%
			.secend-sec-btn
				width: 90% !important
		button.carousel-button svg
			width: 90px			
		.client-review-container p
			font-size: 13px
			line-height: 22px !important
		.work-card-box .df.jce.pr svg
			width: 33px
			height: 48px
		.work-card-box .df.jce.pr img
			width: auto
			height: 40px
			top: 32px
			right: 0
		.register-container .pr8rem
			padding-right: 15px
		.register-container .w50
			margin-bottom: 20px
		.register-not-sure-sec .not-sure-register .not-sure-container .fdc .scaleAnimation .bg3
			box-sizing: border-box
		.register-not-sure-sec .not-sure-register .not-sure-container .fdc
			margin-top: 27px
		.footar-saf .footer-column
			width: 100%
		.first-slide .inner-container .px6rem
			padding: 0
		.carousel .slide .fs2-125rem
			font-size: 26px
		.poppins-font-style .first-slide .inner-container .shadowContainer
			width: 76% !important
		.carousel .slider-wrapper .slide .w70
			width: 90%
			text-align: left !important 
		.poppins-font-style .inner-container .shadowContainer .fs1rem
			line-height: 1.2rem !important
			padding-right: 17px
		.carousel.carousel-slider .df .h60
			zoom: 0.4
		.keyfeature-box-portfolio-bg .w30
			zoom: 0.7
		.inner-container .px6rem
			padding: 0
		.team-sec .team-about-column .team-box
			width: 100% !important
		.ceo-sec
			padding: 1rem !important
		.team-sec .team-about-column .team-box .card-style-about, .poppins-font-style .df.w100 p.bg6.c1.fw600.br15px
			zoom: 1 !important
			
		.home-page-bg
			height: 600px !important
		.main-banner-container 
			.fs3-5rem 
				.fs5rem
					font-size: 2.5rem
		.right-img-area
			height: 212px !important
			margin-top: 39px !important
			.person-img
				height: 118% !important
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem
				padding-right: 3rem
		.calcluter-sec 
			.c15
				font-size: 1rem
				line-height: 23px !important
			.fdc.pl2rem
				padding-left: 0 
		.keyfeautre-container
			width: 74%
		.comapnies-page-font .keyfeautre-container
			width: 82%
		.main-banner-container 
			.fs3-5rem
				font-size: 1.4rem
				line-height: 39px !important
		.video-container
			padding: 32px 123px
		.slider-wrapper 
			.first-slide 
				.inner-container 
					img
						width: 70% !important
		.poppins-font-style .inner-container .shadowContainer
			zoom: .5
		.inner-container .df.jcc.aic img.h60
			height: 74px !important
		.angels-page-font .inner-container img.mb6rem
			width: 89% !important
			margin-bottom: 0
		.main-banner-container .col-sm.right-img-area .cubes-img
			left: -37px !important
			top: 50px !important
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg, .keyfeautre-container .keyfeature-box
			zoom: .8
		.keyfeature-wihte-item 
			width: 17% !important
		.work-container
			zoom: 0.8
		.not-sure-register .not-sure-container
			padding: 30px 96px 30px 173px !important
		.register-not-sure-sec
			zoom: .6
		.client-review-container
			zoom: 0.95
		.keyfeature-box-free .keyfeature-wihte-item:nth-child(2)
			width: 38% !important
		.comapnies-page-font 
			.review-img img
				width: 64% !important
		.keyfeature-wihte-item
			zoom: .7
		.df.bg1.pr.brne.bscov.h90vh
			height: 100%
			zoom: 0.6
		.msg-btn, .top-arrow-bt
			zoom: 0.8
		.comapnies-page-font .main-banner-container, 
		.accounts-page-font .main-banner-container, 
		.lawyers-page-font .main-banner-container, 
		.vcs-page-font .main-banner-container, 
		.pes-page-font .main-banner-container, 
		.angelsnetwork-page-font .main-banner-container, 
		.angels-page-font .main-banner-container
			height: 355px !important
		.register-page-container
			width: 111%
			padding: 5px 0 5px 100px
		.login-page 
			.login-container 
				.pr8rem
					padding-right: 0
		.home-page-bg
			height: 100% !important
		.home-page-menu.automated-sec .menu-bottom
			margin-top: 0 !important
		.home-page-banner .col-sm.df.jce.pr
			width: 60% !important
			justify-content: center
			margin: 0 auto
		.comapnies-page-font .companies-crousel .first-slide .c15.br30px
			width: 73%
		.investor-box
			zoom: 0.6
		#homeMainBanner .container
			padding-left: 30px !important
			padding-right: 30px !important
		.main-banner-container
			.col-sm
				width: 100% !important
				justify-content: center
				&.right-img-area
					margin: 24px auto 0
					.person-img
						margin: 0 auto
			.row
				flex-wrap: wrap !important
		.automated-sec 
			.menu-bottom
				width: 85%
		.automated-sec 
			.c15
				font-size: 19px !important
				line-height: 25px !important
			.w60
				width: 92%
				padding: 10px
				border-radius: 10px
		.right-side-zak
			width: 94px
			height: auto !important
		.calcluter-sec
			padding: 2rem 3rem
			.w100
				width: 91%
				margin: 0 auto
		.landing-page-font 
			.inner-container 
				padding: 11px 102px !important
				.shadowContainer
					border-radius: 14px
					padding: 20px
		.client-review-container
			padding: 45px 33px 60px !important
			.pr3rem.pl3rem
				padding: 1.5rem 1.5rem 0
			.w60
				padding-right: 0
		#homeMainBanner .container:nth-child(odd) .w60
			padding-left: 5rem
		#homeMainBanner .container:nth-child(2n) .w60
			padding-right: 3rem
		#homeMainBanner .row.pl2rem.pr2rem.w50
			width: 100%
		.pricing-container 
			.pricing-box
				width: 30%
			.pl8rem.pr8rem
				padding: 0
		.ar.pa.blue-circle
			right: 29px !important
		.privacy-policy-page 
			.policy-box
				padding: 0px 30px 40px 30px
			.w60
				width: 90%
				border-radius: 10px
				.fs1rem-screen1
					font-size: 0.75rem
		.privacy-policy-page 
			.w60 
				.fs3-5rem
					font-size: 2.5rem
			.policy-box 
				.pb2rem
					padding-bottom: 1.3rem
		.our-thesis-sec 
			padding-left: 30px
			padding-right: 30px
			.w60, .w40
				width: 100%
		.visin-sec 
			.container
				align-items: center
				padding: 0 30px !important
				.w60
					width: 100%
					padding-right: 0
				.w30
					width: 100%
					text-align: center
					img
						width: 62%
						height: 100% !important
						margin: 20px auto 0 !important
		.story-sec
			padding: 3rem !important
		.team-sec 
			.dg.aic
				display: flex
				align-items: center
				flex-wrap: wrap
				justify-content: center
				.df.fdc
					width: 47%
					justify-content: center
					margin-bottom: -150px
		.about-carousal-slider .slide
			zoom: .4
		.action-sec.dg
			display: flex
			flex-wrap: wrap
			.left-area 
				width: 100%
				margin-bottom: 22px
			.right-area
				width: 39%
				margin: 0 auto
		.accounts-page-font 
			.work-container 
				.df.jcsb
					.work-card-box
						width: 24%
						margin-bottom: 28px
		.our-thesis-sec 
			.w40
				margin-right: -60px
				margin-top: -63px
		.our-thesis-sec img.ar.pa
			width: 39px
		.card-style-about
			padding: 20px 30px 60px !important
		.story-sec h4
				font-size: 2.5rem !important
		.narBarAnimation
			display: none
		.comapnies-page-font #homeMainBanner .container:nth-child(5) .testmonial-user
			margin-top: -8px !important
		.comapnies-page-font #homeMainBanner .container:nth-child(6) .testmonial-user,
		comapnies-page-font #homeMainBanner .container:nth-child(9) .testmonial-user
			margin-top: 1px !important
		.main-banner-container 
			.col-sm.df.jcc.fdc.gg2rem
				padding-right: 0
				width: 100% !important
		.keyfeature-wihte-item
			width: 17% !important
		.work-container
			max-width: 75% !important
			zoom: 0.7
		.slider-wrapper 
			.first-slide 
				.inner-container 
					img.pa.ar.zi1
						width: 52px !important
		.calcluter-sec 
			.w60 
				a
					text-align: center
					padding-left: 2rem
		.pricing-plan-page 
			.w60.fs3-125rem
				font-size: 2rem
				padding-left: 8rem !important
		.inner-container .df.jcc.aic img.h60
			height: 100% !important
		.df.bg1.pr.brne.bscov.h90vh
			height: 100%
		.shadowPaperCrasouel
			width: 100%
		.inner-container
			padding: 11px 66px !important
		.inner-container .df.jcc.aic img.h60
			height: 120px !important
		.accounts-page-font .client-review-container
			zoom: 0.7
		.team-sec .team-about-column .team-box
			width: 33% !important
			padding: 0 15px !important
		span.wid-btn
			width: 70% !important
		.pricing-tabs .pricing-advisor-table .pricing-box.pricing-box-mobile
			display: none !important
		.top-pricing-area .fdc.br100
			zoom: 0.7 !important
		.poppins-font-style.mah100.bg1.shadow.w100.p4rem.df.fdc.m1rem.pr.br10px.pr
			zoom: 0.8 !important
		.silder-box .slick-arrow:before
			width: 90px !important
			height: 90px !important
		.team-sec .team-about-column .team-box .card-style-about
			max-height: 100% !important
			min-height: 100% !important
		.ceo-sec
			padding: 3rem 5.5rem !important
		.comapnies-page-font .main-banner-container, 
		.accounts-page-font .main-banner-container, 
		.lawyers-page-font .main-banner-container, 
		.vcs-page-font .main-banner-container, 
		.pes-page-font .main-banner-container, 
		.angelsnetwork-page-font .main-banner-container, 
		.angels-page-font .main-banner-container
			height: 543px !important

		.keyfeature-box-portfolio-innner:before, .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before
			width: 93%
			left: 0
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before
			left: 21px
		.keyfeature-wihte-item
			width: 38% !important
		.work-container 
			.df.jcsb 
				.work-card-box
					width: 40%
					margin-bottom: 30px
			.df.jcc
				flex-wrap: wrap
				.work-card-box
					width: 40%
					margin: 0 15px 34px
		.slider-wrapper 
			.first-slide 
				.inner-container 
					img
						width: 44% !important
		.pricing-table-saf
			width: 570px
		.pricing-tabs 
			.booking-sec
				flex-wrap: wrap
				margin-top: -8px
				.pricing-bottom-box
					width: 45%
					margin: 15px 0 0 !important
					width: 100%
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30
			width: 25%
			padding-left: 1rem
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w70
			width: 75%
		.inner-container
			padding: 11px 58px !important
			zoom: 0.7	
		.login-page
			flex-wrap: wrap
			height: 100%
			.login-container
				width: 100%
				padding: 100px 50px 50px
			.form-right-image
				width: 100% !important
				height: 373px
		.investorLookup-container
			padding: 0 30px 70px
		.investor-box
			zoom: 0.5
		.main-banner-container
			padding: 0 45px 
		.new-header 
			.container
				padding: 0 45px !important
		.calcluter-sec 
			.c15
				font-size: 1rem
				line-height: 23px !important
			.fs3rem
				margin-top: 1rem
				font-size: 1.2rem
				line-height: 27px !important
		.landing-page-font 
			.silder-box
				padding: 1rem
			.inner-container 
				p.fs1-25rem
					font-size: .75rem
					line-height: 1.3rem !important
		.work-container
			padding: 45px 30px
			.jcsb
				.work-card-box
					width: 33%
					margin: 0 15px 25px
		#homeMainBanner 
			.container
				.w60
					width: 100%
		#homeMainBanner .container:nth-child(odd) .w60,
		#homeMainBanner .container:nth-child(2n) .w60
			padding: 0rem
		#homeMainBanner .container .w40
			margin: 0 auto
			width: 60%
		#homeMainBanner .container .w100.pr.df
			margin-top: 0
			width: 100%
			justify-content: center !important
		#homeMainBanner .container .w100.pr.df .br40px.pt1rem.pb1rem.pl3rem.pr2rem
			width: 100% !important
		.video-container
			padding: 49px 30px 0
		.not-sure-container
			.df.jcsb
				flex-wrap: wrap
				justify-content: center
				.scaleAnimation
					margin-bottom: 43px
		#homeMainBanner .container .w50,
		#homeMainBanner .container .br40px.pt1rem
			width: 100% !important
		.beta-logo
			position: absolute
			left: 21%
			top: 15%
		.calcluter-sec
			padding: 1rem 1rem
			zoom: 0.8
		.inner-container 
			.df.jce.aic
				width: 55% !important
				justify-content: center
				margin: 0 auto
				gap: 5px !important
		.keyfeature-box-free 
			.keyfeature-wihte-item:nth-child(2)
				width: 100% !important
				.df.fw500.fs1rem.c15
					justify-content: space-between
					align-items: center
		.keyfeature-wihte-item
			width: 39% !important
		.work-container .df.jcsb .work-card-box, .work-container .df.jcc .work-card-box, .accounts-page-font .work-container .df.jcsb .work-card-box
			width: 38%
		.main-banner-container 
			.fs1-75rem
				font-size: 1rem
		.df.bg1.pr.brne.bscov.h90vh
			height: 100%
		.pricing-plan-page 
			.w60.fs3-125rem 
				padding-left: 3rem !important
		.pricing-plan-page .df.fdc.aic.mt1rem
			width: 50% !important
		.pricing-plan-page .w70 .df.jcsb.w100, .pricing-plan-page .w80 .df.jcsa.w100
			justify-content: center
			flex-wrap: wrap
		.register-page
			flex-wrap: wrap
			height: 100%
			.register-page-container
				width: 100%
				padding: 5px 38px
				box-sizing: border-box
				.pr8rem
					padding-right: 0
			.form-right-image
				width: 100% !important
				position: relative
				height: 400px
		.inner-container .df.jcc.aic img.h60
			height: 109px !important
		.pricing-tabs .booking-sec
			width: 100% !Important
		.pricing-tabs 
			.booking-sec 
				.pricing-bottom-box
					min-height: auto
		.pricing-tabs 
			.booking-sec 
				.pricing-bottom-box 
					p
						padding: 0 0 10px
		.tabs-portfolio 
			.pt10rem
				padding-top: 9rem


		.login-page
			flex-wrap: wrap
			height: 100%
			.login-container
				width: 100%
				padding: 100px 50px 50px
			.form-right-image
				width: 100% !important
				height: 373px
		.investorLookup-container
			padding: 0 30px 70px
		.investor-box
			zoom: 0.5
		.main-banner-container
			padding: 0 45px 
		.new-header 
			.container
				padding: 0 45px !important
		.calcluter-sec 
			.c15
				font-size: 1rem
				line-height: 23px !important
			.fs3rem
				margin-top: 1rem
				font-size: 1.2rem
				line-height: 27px !important
		.landing-page-font 
			.silder-box
				padding: 1rem
			.inner-container 
				p.fs1-25rem
					font-size: .75rem
					line-height: 1.3rem !important
		.work-container
			padding: 45px 30px
			.jcsb
				.work-card-box
					width: 33%
					margin: 0 15px 25px
		#homeMainBanner 
			.container
				.w60
					width: 100%
		#homeMainBanner .container:nth-child(odd) .w60,
		#homeMainBanner .container:nth-child(2n) .w60
			padding: 0rem
		#homeMainBanner .container .w40
			margin: 0 auto
			width: 60%
		#homeMainBanner .container .w100.pr.df
			margin-top: 0
			width: 100%
			justify-content: center !important
		#homeMainBanner .container .w100.pr.df .br40px.pt1rem.pb1rem.pl3rem.pr2rem
			width: 100% !important
		.video-container
			padding: 49px 30px 0
		.not-sure-container
			.df.jcsb
				flex-wrap: wrap
				justify-content: center
				.scaleAnimation
					margin-bottom: 43px
		#homeMainBanner .container .w50,
		#homeMainBanner .container .br40px.pt1rem
			width: 100% !important
		.beta-logo
			position: absolute
			left: 21%
			top: 15%
		.calcluter-sec
			padding: 1rem 1rem
			zoom: 0.8
		.inner-container 
			.df.jce.aic
				width: 55% !important
				justify-content: center
				margin: 0 auto
				gap: 5px !important
		.keyfeature-box-free 
			.keyfeature-wihte-item:nth-child(2)
				width: 100% !important
				.df.fw500.fs1rem.c15
					justify-content: space-between
					align-items: center
		.keyfeature-wihte-item
			width: 39% !important
		.work-container .df.jcsb .work-card-box, .work-container .df.jcc .work-card-box, .accounts-page-font .work-container .df.jcsb .work-card-box
			width: 38%
		.main-banner-container 
			.fs1-75rem
				font-size: 1rem
		.df.bg1.pr.brne.bscov.h90vh
			height: 100%
		.pricing-plan-page 
			.w60.fs3-125rem 
				padding-left: 3rem !important
		.pricing-plan-page .df.fdc.aic.mt1rem
			width: 50% !important
		.pricing-plan-page .w70 .df.jcsb.w100, .pricing-plan-page .w80 .df.jcsa.w100
			justify-content: center
			flex-wrap: wrap
		.register-page
			flex-wrap: wrap
			height: 100%
			.register-page-container
				width: 100%
				padding: 5px 38px
				box-sizing: border-box
				.pr8rem
					padding-right: 0
			.form-right-image
				width: 100% !important
				position: relative
				height: 400px
		.inner-container .df.jcc.aic img.h60
			height: 109px !important
		.pricing-tabs .booking-sec
			width: 100% !important
		.pricing-tabs 
			.booking-sec 
				.pricing-bottom-box
					min-height: auto
		.pricing-tabs 
			.booking-sec 
				.pricing-bottom-box 
					p
						padding: 0 0 10px
		.tabs-portfolio 
			.pt10rem
				padding-top: 9rem
		
		.team-sec .team-about-column .team-box
			width: 46% !important
			padding: 0 15px !important
		.tabs-portfolio .pt10rem .df.jcsb.w100.mb2rem.px4rem
			margin-bottom: 8rem !important
			display: none !important
		.pricing-tabs .pricing-advisor-table .pricing-box
			width: 100% !important
		.ceo-sec
			padding: 3rem 1.5rem !important
		.pricing-table-saf
			width: 300px !important
		.ceo-sec .left-ceo-text
			width: 100% !important
			margin-bottom: 33px !important
		.ceo-sec .righ-ceo-pic
			width: 100% !important
			margin: 0 auto !important
			text-align: center !important
		.team-sec
			padding: 2rem 1.5rem 1rem !important
		.silder-box .bg1.aic
			padding: 0 1.5rem !important
		.team-sec .team-about-column
			justify-content: center
			flex-wrap: wrap !important
		.action-sec.dg .tac.bg1.cp
			margin: 0 auto !important
		.carousel-button.prev
			left: 0 !important
		.carousel-button.next
			right: 0 !important
		.inner-container.style-two .row
			width: 100% !important
		.pricing-tabs .pricing-box,
		.pricing-tabs .pricing-box:nth-child(3)
			margin-bottom: 6rem !important
		.pricing-tabs .pricing-advisor-table .pricing-box:nth-child(4)
			margin-bottom: 6rem !important
		.pricing-tabs .pricing-advisor-table .pricing-box::nth-child(5)
			margin-bottom: 0 !important
		.w70.fw500.fs1rem.pl9rem
			padding: 0 !important
		
		.calcluter-sec .w30, .calcluter-sec .w70
			width: 100%
		.automated-sec
			.menu-bottom
				.fs1rem-screen1
					font-size: 0.7rem
		.automated-sec .menu-bottom, #root[style*="margin-top: 72px"] .narBarAnimationUP .fs1rem-screen1, 
		#root[style*="margin-top: 72px"] .narBarAnimation .fs1rem-screen1
			font-size: 0.7rem
		.landing-page-font 
			.inner-container 
				padding: 11px 95px 0 !important
				.shadowContainer
					width: 100% !important
					margin-bottom: 17px
				.df.jce.aic
					width: 100% !important
					gap: 5px !important
					margin: 0 auto
					justify-content: center
					img
						height: auto
						width: 131px !important
		.client-review-container 
			.df.w100
				flex-wrap: wrap
			.w60
				padding-right: 0
				width: 100%
			.w40
				width: 60%
				justify-content: center
				margin: 0 auto
		.work-container 
			.jcsb 
				.work-card-box
					width: 100%
					margin: 0 0 25px
		.accounts-page-font 
			.work-container 
				.df.jcsb 
					.work-card-box
						width: 100%
						margin: 0 0 25px
		.work-card-box
			width: 100%
			margin-bottom: 25px
		.work-container 
			.df.jcc
				flex-wrap: wrap 
		.pricing-container 
			.pricing-box
				width: 44%
		.triangle-vector
			width: 100px
		.pricing-container .pricing-box.mr5rem
			margin-right: 15px
		.pricing-title .right-text-for
			padding-right: 0 !important
		.pricing-title .left-text-for
			padding-left: 0
		.action-sec
			padding: 3rem 30px !important
			.fs2-5rem
				font-size: 1.5rem
		.action-sec.dg .right-area
			width: 58%
		.beta-logo
			left: 183px !important
			top: 16px
		.pricing-plan-page 
			.w60.fs3-125rem
				width: 100%
				padding-bottom: 2rem
		.angelsnetwork-page-font .inner-container .row.df.jcc img
			width: 66% !important
			height: auto !important
		
		.main-banner-container.home-page-banner .fs3rem, .main-banner-container.home-page-banner .fs3rem .c4
			font-size: 39px !important
			line-height: 46px !important
			margin: 20px 0
		.main-banner-container.home-page-banner .pt4rem .fs2-5rem
			font-size: 1.2rem
			margin-top: 1rem
		.pricing-tabs 
			.pricing-advisor-table 
				.pricing-box
					width: 99%
		.investor-box
			zoom: 0.4
		.main-banner-container,
		.new-header .container
			padding: 0 30px 30px
		.main-banner-container 
			.fs1-5rem
				font-size: 1rem
			.fs2rem
				font-size: 1.3rem
			span.c4
				font-size: 25px !important
		.calcluter-sec
			flex-wrap: wrap
			.w100
				flex-wrap: wrap
				padding: 2rem 1rem 1rem
			.w60
				width: 100%
			.w40
				width: 100%
				margin-top: 20px
		#homeMainBanner .container
			max-width: 100% !important
			padding: 0 30px !important
		.blue-animation
			width: 77px !important
			height: auto !important
			top: -40px !important
		.circle-animation
			width: 68px !important
			height: auto !important
		.quote-icon
			width: 20px !important
			svg
				width: 20px
		#homeMainBanner .container .w90 .fs1-25rem
			padding-left: 0.5rem
		#homeMainBanner .container:nth-child(odd) .w60
			margin-bottom: 13px
		#homeMainBanner .container .w60 .custom-list,
		#homeMainBanner .container .w60 .custom-list li
			width: 95% !important
			font-size: 17px
		.bottom-features 
			.column
				flex: 0 0 50%
		.video-container p.fs2-125rem
			line-height: 33px !important
			font-size: 1.2rem
		.video-container .w70
			width: 99%
		.pricing-container 
			.pricing-box
				width: 43%
		.register-container 
			.df.gg1rem.pt0-5rem.pb0-5rem
				flex-wrap: wrap
			.w50
				width: 100%	
		.not-sure-container .bsflp.bg1.br10px
			padding: 27px 15px
			width: 41% !important
		.not-sure-container .w100.df.jcc p
			width: 100% !important
		.side-blue-circle
			width: 70px
			height: auto
			right: 38px !important
			bottom: 0 !important
		.not-sure-container
			.side-blue-circle
				width: 70px !important
				height: auto
				bottom: 0 !important
				right: 0 !important
		.privacy-policy-page 
			.w60
				justify-content: center
				flex-wrap: wrap
				padding: 5px
				.fs1rem-screen1
					margin: 0 5px
			.policy-box
				.pt2rem
					padding-top: 1rem
				.pt1rem
					padding-top: 0.6rem
		.fs3-5rem
			font-size: 2.5rem
		.terms-and-conditions-page 
			[style*="width: 41%"]
				width: 88% !important
				border-radius: 10px
				padding: 12px 10px
		.team-sec .dg.aic .df.fdc
			justify-content: center
			width: 100%
			margin-bottom: -120px
		.team-sec .fs3-125rem
			font-size: 2rem
		.about-carousal-slider .slide
			zoom: .3
		.video-container p, .pricing-container p.fs2-125rem, .not-sure-container p.fs2-125rem
			width: 100%
			font-size: 25px
		#homeMainBanner .fs2-5rem
			font-size: 1.5rem
		.work-container .fs1-5rem
			font-size: 0.7rem
			line-height: 16px !important
		.poppins-font-style .inner-container .shadowContainer
			width: 49% !important
		.register-not-sure-sec
			overflow: hidden
		.calcluter-sec 
			.w40 
				img
					width: 60%
					margin-bottom: 20px
		.keyfeature-wihte-item
			width: 100% !important
			margin-right: 0 !important
		.work-container .df.jcsb .work-card-box, .work-container .df.jcc .work-card-box, .accounts-page-font .work-container .df.jcsb .work-card-box
			width: 75%
		.register-not-sure-sec 
			.not-sure-register 
				.not-sure-container 
					.fdc
						flex-wrap: wrap
		.register-not-sure-sec 
			.not-sure-register 
				.not-sure-container 
					.fdc 
						.scaleAnimation
							margin-bottom: 45px
		.bottom-button-slider 
			.fs1rem.df.jcc
				width: 69%
		#homeMainBanner 
			.mt4rem 
				.fs2-5rem
					font-size: 1.5rem
		.inner-container.style-two 
			.row
				width: 100%
		.investorLookup-container 
			.fs2rem
				font-size: 1.5rem
				line-height: 2rem !important
				padding: 50px 0
		.pricing-table-saf
			width: 80%
		.pricing-plan-page 
			.w60.fs3-125rem
				padding-bottom: 2rem
				font-size: 1.5rem
				line-height: 2rem !important
		.pricing-plan-page 
			.w40.pr.df
				width: 56%
				justify-content: center
				margin: 0 auto
		.bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem
			zoom: 0.6
			padding: 34px 15px
		.bg1.pricing-plan-page  h4.fs2rem.fw400.mb2rem.c15
			line-height: 28px !important
			max-width: 100% !important
			font-size: 1.1rem
		.comapnies-page-font .inner-container .shadowContainer .df.aic, .comapnies-page-font .inner-container .shadowContainer .df.aic p
			justify-content: start
		.poppins-font-style .inner-container .shadowContainer .br10px.mt1rem svg
			width: 39px
		.register-page .register-page-container .w50
			width: 100%
		.register-page .register-page-container .df.gg1rem
			flex-wrap: wrap
		.keyfeature-box-free .keyfeature-wihte-item:nth-child(2) .df.fw500.fs1-25rem.c15
			flex-wrap: wrap
		.inner-container .row.df.jcsb .df.jce.aic img.h70
			height: 50%
		.comapnies-page-font .companies-crousel .first-slide .c15.br30px
			width: 73%
			zoom: 0.8
		.comapnies-page-font .companies-crousel .first-slide .new-shadow .icon img.w30px
			width: 20px
		.keyfeature-box-free 
			.keyfeature-wihte-item:nth-child(2) 
				.df.fw500.fs1-25rem.c15 a
					margin-top: 15px
		.inner-container .df.jce.right-icon-btn
			gap: 0 !important
			width: 64% !important
			margin: 20px 0 12px
			justify-content: flex-start
		.inner-container .df.jcc.aic img.h60
			height: 142px !important
		.step-box-modal.style-two
			padding: 0
		.mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80
			zoom: .8
			padding: 10px
		.client-review-container .mt4rem
			padding: 2rem 1rem 0
		.register-container-not-sure
			padding: 106px 276px 28px 72px
			width: 89%
		.not-sure-register.w40
			width: 100%


		.silder-box .slick-slider
			padding: 0 80px !important
		.poppins-font-style.mah100.bg1.shadow.w100.p4rem.df.fdc.m1rem.pr.br10px.pr
			padding: 1rem !important
			zoom: 0.7 !important
		.poppins-font-style.mah100.bg1.shadow.w100.p4rem.df.fdc.m1rem.pr.br10px.pr .df.w100.mb1rem
			flex-wrap: nowrap !important
		.team-sec .team-about-column .team-box
			width: 100% !important
			padding: 0 15px !important
			zoom: 1 !important

		.action-sec
			padding: 2rem 1.5rem !important
		.poppins-font-style .df.w100 div[style="width: 55%; height: 100%;"]
			width: 100% !important
		.poppins-font-style .df.w100
			flex-wrap: wrap !important

		.poppins-font-style .df.w100 .df.aic.jce
			width: 100% !important
		.poppins-font-style .df.w100 .w90.fw500.c15.pl1rem.df.aic
			font-size: 20px !important
			line-height: 30px !important
		.seemore-box .seemore-inner
			padding: 25px 15px 15px !important

		.seemore-box .seemore-inner .pl3rem
			width: 100% !important
			padding-left: 1rem
		.team-sec .team-about-column .team-box .card-style-about
			zoom: 0.6 !important
		.poppins-font-style .df.w100 p.bg6.c1.fw600.br15px
			zoom: 0.6 !important

		.pricing-tabs .pricing-advisor-table .px4rem
			padding: 0 !important
			margin: 0 auto !important
		.comapnies-page-font .main-banner-container, 
		.accounts-page-font .main-banner-container, 
		.lawyers-page-font .main-banner-container, 
		.vcs-page-font .main-banner-container, 
		.pes-page-font .main-banner-container, 
		.angelsnetwork-page-font .main-banner-container, 
		.angels-page-font .main-banner-container
			height: 510px !important
		.silder-box .slick-arrow:before
			width: 60px !important
			height: 60px !important


		.not-sure-register .not-sure-container
			padding: 30px 96px !important
		.home-page-menu.automated-sec .menu-bottom
			margin-top: -37px !important
		.main-banner-container .fs3-5rem .fs5rem
			font-size: 1.8rem
			margin: 10px 0
			display: block
			line-height: 2.5rem
		.login-page 
			.login-container
				width: 100%
				padding: 100px 25px 50px
				.pr8rem 
					.df.jcsb
						flex-wrap: wrap
		.comapnies-page-font .companies-crousel .first-slide .c15.br30px
			zoom: 0.8
		.main-banner-container 
			span.c4
				font-size: 22px !important
			.fs2rem
				font-size: 1rem
			.col-sm
				grid-gap: 0.5rem
		.menu-bottom
			flex-wrap: wrap
			justify-content: center
			.fs1rem-screen1
				margin: 0 5px
		#root[style*="margin-top: 72px"] .narBarAnimationUP, 
		#root[style*="margin-top: 72px"] .narBarAnimation
			flex-wrap: wrap
			justify-content: center
			display: none
		.carousel .slider-wrapper .slide .c15
			font-size: 1.2rem
			line-height: 2rem
		.carousel .slider-wrapper .slide .fs1rem.c15
			font-size: 1rem
		.carousel-button.prev
			left: -24px !important
		.carousel-button.next
			right: -24px !important
		.landing-page-font .inner-container
			padding: 11px 0px 0 !important
		.landing-page-font .inner-container .shadowPaperCrasouel .c15
			font-size: 1rem
			line-height: 1.3rem !important
		.landing-page-font .inner-container .df.jce.aic img
			width: 61px !important
		#homeMainBanner .container .w100.pr.df .br40px.pt1rem.pb1rem.pl3rem.pr2rem
			padding: 0 1.7rem 20px
		.quote-area
			flex-wrap: wrap
			.w90
				width: 100%
		.pricing-title .right-text-for
			display: none
		.pricing-title
			width: 100%
			justify-content: center
		#homeMainBanner .container .w90 .fs1-25rem
			padding: 0
		.feature-review-img
			flex-wrap: wrap
			img
				width: 40px
				height: 40px
				margin: 15px 0
			p
				line-height: 1rem !important
				padding: 0 0 8px
		#homeMainBanner 
			.container 
				.w40
					width: 100%
					margin: 18px auto
				.w100.pr.df
					margin-bottom: 20px
		.bottom-features 
			.column
				flex: 0 0 100%
		.pricing-container 
			padding: 51px 10px
			.pricing-box
				width: 100%
		.ar.pa.blue-circle
			right: 29px !important
			top: 34px !important
		.register-container 
			padding: 70px 45px
			width: 100% !important
		.register-container button
			width: 100% !important
		.not-sure-container .bsflp.bg1.br10px
			padding: 27px 15px
			width: 93% !important
		#homeMainBanner .container
			padding: 0 10px !important
		.client-review-container
			padding: 45px 13px 60px !important
			.fs2-125rem
				text-align: center
		.fs2-125rem
			font-size: 1.25rem
		.review-img img
			width: 212px !important
		.landing-page-font .silder-box
			padding: 1rem
		.calcluter-sec
			padding: 2rem 1rem
		.video-container
			padding: 49px 10px 0
		.triangle-vector
			width: 59px
			right: 34px !important
		#homeMainBanner .container .w60 .pl3rem
			padding-left: 1rem
		.df.jcc.mt5rem
			margin-top: 2rem
			p
				width: 100%
		.form-right-image
			width: 0 !important
		.fs3-5rem
			font-size: 2.2rem
		.privacy-policy-page
			.policy-box
				p, ol li, ul li
					word-break: break-all
		.terms-and-conditions-page 
			[style*="width: 41%"]
				justify-content: center
				flex-wrap: wrap
		.privacy-policy-page
			ul, ol
				padding-left: 5px
		.privacy-policy-page 
			.policy-box 
				.fs2rem.p3rem
					padding: 10px
					text-align: center
					font-size: 1.2rem
		.our-thesis-sec .fs3-5rem
			font-size: 1.8rem !important
		.visin-sec
			.fs3rem
				font-size: 2rem
		.story-sec
			padding: 50px 30px !important
		.about-carousal-slider 
			padding: 0 15px !important
			.slide
				zoom: .2
				min-width: 100% !important
		.carousel.carousel-slider
			padding: 0rem 0 4rem !important
		.action-sec.dg .right-area
			width: 76%
			zoom: 0.5
		.team-sec .fs3-125rem
			font-size: 1.7rem
		.bottom-features
			flex-direction: column
			width: 100%
			[style*="width: 20%"]
				width: 100% !important
		#homeMainBanner h1
			text-align: center
		.video-container img.w10.pa
			width: 50px !important
		.not-sure-container .df.jcsb .scaleAnimation
			margin-bottom: 43px
			width: 100% !important
		.carousel .slider-wrapper.axis-horizontal 
			.slider 
				.slide 
					.fw400.fs1-25rem.c15.mb1rem
						font-size: 0.9rem
		.carousel .slider-wrapper 
			.slide 
				.fs1rem.c15
					font-size: 1rem
					width: 78%
		.slider-wrapper 
			.inner-container 
				.row.df.jcsb 
					.df.jce.aic 
						img.h80
							height: 49%
		.keyfeautre-container
			max-width: 90%
			width: 90%
			.keyfeature-box
				box-sizing: border-box
		.keyfeature-box-free 
			.keyfeature-wihte-item
				box-sizing: border-box
			.keyfeature-wihte-item:nth-child(2)
				width: 100% !important
				.df.fw500.fs1rem.c15
					flex-wrap: wrap
				a
					width: 100%
					margin-top: 16px
		.how-its-work 
			.blue-animation
				height: 5% !important
		.poppins-font-style .inner-container .shadowContainer .df.aic, .poppins-font-style .inner-container .shadowContainer .df.aic p
			flex-wrap: wrap
		.poppins-font-style .inner-container .shadowContainer .df.aic span
			width: 100% 
		.poppins-font-style .inner-container .shadowContainer .df.aic p .cp.w100.df.jce.c1
			justify-content: center
			margin: 10px 0
			flex-wrap: nowrap !important
		.shadowPaperCrasouel .right-icon-btn span img
			width: 1rem !important
		.keyfeature-box-portfolio-innner 
			.keyfeature-box-portfolio-bg 
				.keyfeature-wihte-item
					width: 100% !important
					margin-right: 0 !important
					margin-left: 0 !important
		.poppins-font-style
			overflow: hidden
		.investor-box
			zoom: .3
		.work-container 
			.fs2-125rem
				font-size: 25px
		.main-banner-container 
			.fs3-5rem
				font-size: 1.3rem
				line-height: 39px !important
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before
			top: 47%
		.slider-wrapper .first-slide .inner-container img.pa.ar.zi1
			display: none
		.pricing-tabs 
			.booking-sec 
				.pricing-bottom-box
					width: 100%
					margin: 15px auto !important
		.pricing-plan-page .df.fdc.aic.mt1rem
			width: 100% !important
		.main-banner-container .col-sm.right-img-area .cubes-img
			left: -28px !important
			top: 46px !important
		.poppins-font-style .inner-container .shadowContainer
			width: 95% !important
			margin-bottom: 24px
		.calcluter-sec .w30 img.w90
			height: auto
		.inner-container .row.df.jcc img
			width: 100% !important
			height: auto !important
		.inner-container .df.jce.right-icon-btn
			width: 94% !important
		.inner-container .df.jcc.aic img.h60
			height: 106px !important
		.shadowPaperCrasouel .bg19
			width: 53px
			height: 53px
			padding: 5px
			box-sizing: border-box
			margin-bottom: 10px
		.shadowPaperCrasouel .bg19 svg
			width: 23px
		.poppins-font-style .inner-container .shadowContainer
			zoom: .6
			width: 98% !important
		.comapnies-page-font .review-img img
			width: 100% !important
		.inner-container .jcsa[style="width: 47%; gap: 25px;"], .inner-container .jcsb[style="width: 47%; gap: 25px;"]
			width: 98% !important
		.inner-container .jcsa[style="width: 47%; gap: 25px;"] .shadowContainer, .inner-container .jcsb[style="width: 47%; gap: 25px;"] .shadowContainer
			width: 100% !important
			box-sizing: border-box

		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem
			padding-top: 4rem
			width: 100%
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg:before
			border-radius: 31px
			width: 100%
			height: 93%
			top: 53%
		.keyfeature-box-portfolio-innner:before
			border-radius: 31px
			width: 100%
			height: 100%
			top: 53%
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w70
			width: 100%
			padding-left: 34px
			display: flex
		.keyfeature-box-portfolio .keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .keyfeature-wihte-item:nth-child(3)
			top: 0
			order: 5
		.investorLookup-container .investor-box
			zoom: .3
			margin-top: -56px
		.form-right-image .triangle-vector
			display: none
		svg.pa.cp
			zoom: .7
			bottom: -53px !important
		.poppins-font-style .df.w100
			line-height: 1.3rem !important
		.silder-box .slick-slider .slick-arrow.slick-prev
			left: 0 !important
		.silder-box .slick-slider .slick-arrow.slick-next
			right: 0 !important
		.pricing-tabs .MuiTabs-root button
			margin-right: 4px !important
			font-size: 12px !important
		.pricing-tabs .pricing-advisor-table .px4rem
			display: none !important
		.silder-box .slick-slider
			padding: 0 20px !important
		.fs1-25rem
			font-size: .8rem !important
		.silder-box .slick-arrow:before
			width: 68px !important
			height: 68px !important
		.comapnies-page-font .main-banner-container, 
		.accounts-page-font .main-banner-container, 
		.lawyers-page-font .main-banner-container, 
		.vcs-page-font .main-banner-container, 
		.pes-page-font .main-banner-container, 
		.angelsnetwork-page-font .main-banner-container, 
		.angels-page-font .main-banner-container
			height: 483px !important
		.pricing-table-saf
			width: 84% !important


		.main-banner-container 
			span.c4
				font-size: 18px !important
			.fs2rem
				font-size: 0.8rem
			.col-sm
				grid-gap: 0.4rem
		.automated-sec 
			.c15
				font-size: 16px !important
				line-height: 20px !important
			.c14
				font-size: .7rem !important
				line-height: 1rem !important
		.register-container .pt1rem.pb1rem .fs1-125rem
			font-size: 0.7rem
			margin-left: 0.4rem
		.privacy-policy-page 
			.w60 
				.fs3-5rem
					font-size: 1.8rem
		#homeMainBanner .fs2-5rem
			font-size: 1.3rem
		.main-banner-container 
			.fs3-5rem
				font-size: 1.2rem
				line-height: 26px !important
		.new-header 
			.container
				padding: 0 10px !important
		.client-review-container 
			.fs2-125rem
				text-align: center
				line-height: 40px !important
				font-size: 1.4rem
		.beta-logo
			top: 16px
			left: 147px !important

		.comapnies-page-font .main-banner-container, 
		.accounts-page-font .main-banner-container, 
		.lawyers-page-font .main-banner-container, 
		.vcs-page-font .main-banner-container, 
		.pes-page-font .main-banner-container, 
		.angelsnetwork-page-font .main-banner-container, 
		.angels-page-font .main-banner-container
			height: 520px !important
		.banner-bg
			background-size: 100% !important
			background-position: center center !important
		.main-banner-container 
			.fs3-5rem
				font-size: 1.1rem
				line-height: 21px !important
		.inner-container 
			p.fs1-25rem
				line-height: 1.1rem !important
		.investorLookup-container .investor-box
			zoom: .3
			margin-top: -56px
		.keyfeature-box .keyfeature-wihte-item
			margin: 0 0 30px !important
		.work-container .df.jcsb.mt4rem
			justify-content: center
			flex-wrap: wrap
		.register-not-sure-sec .not-sure-register .not-sure-container .fdc .scaleAnimation .bg3
			top: -89px !important
		.register-not-sure-sec .not-sure-register .not-sure-container .fdc .scaleAnimation
			margin: 104px 15px 0
		.shadowPaperCrasouel p
			padding-right: 0 !important
		.poppins-font-style .first-slide .inner-container .shadowContainer
			width: 94% !important
		.shadowPaperCrasouel p .right-icon-btn
			flex-wrap: nowrap !important
		.review-img img
			width: 100% !important
			height: auto !important
		.left-certificate-area.df.fww.w70.pt3rem.pb1rem
			zoom: 0.3 !important
		.investor-lookup-full-img
			display: block !important
		.investor-box .left-certificate-area, .investor-box .list-right-area 
			display: none
		.pricing-tabs 
			.booking-sec
				width: 102% !important
		.bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem .w60, .bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem .w40
			width: 100%
			padding: 0 !important
		.bg1.pricing-plan-page .w70.p1rem.bg1.df.mt3rem.mb3rem
			flex-direction: column
		.pricing-plan-page .w40.pr.df
			width: 100%
		.about-us-page .silder-box .slick-dots
			bottom: -142px !important
		.team-sec img.ar.pa
			width: 13% !important
		.story-sec:before
			width: 5.813rem !important
			height: 5.813rem !important
			top: -4% !important
			left: -5.5% !important
		.story-sec:after
			width: 7.688rem !important
			height: 7.688rem !important
			right: -2.25rem !important
			bottom: -5% !important
		.silder-box .bg1.aic p
			line-height: 1.5rem !important
		.action-sec h4
			font-size: 1.4rem
			line-height: 29px !important
			width: 100% !important
		.partners-page .w60
			font-size: 25px !important
		.partners-page .w70.df.fdc.jcc
			zoom: 0.8
		.partners-page 
			.w60
				width: 100% !important
			.df.w100 
				flex-wrap: wrap
				justify-content: center
			.w40
				width: 100%
				background-size: contain
				background-position: top right
				padding-right: 0
				img
					margin: 0 auto
					text-align: center
					width: 50% !important
			.w30
				width: 100%
				margin: 0 auto
			.df.w100 
				.df
					flex-wrap: wrap
					img
						width: 50% !important
			.w70.df.fdc.jcc
				width: 100% !important
		.partners-page  .df.w100 .w60.fw500
			width: 100% !important
			padding-left: 3rem !important
		.partners-page .df.w100 .df .cp.bg4.br40px.df.jcsb.aic.pl1rem.pr1rem.mt1-5rem
			flex-wrap: nowrap !important
			width: 152px !important
			margin-bottom: 25px
		.partners-page .df.w100 .p2-5rem
			padding: 1rem
		.partners-page .df.w100 .w60.fw500
			font-size: 20px !important
			line-height: 1.6rem !important
		.contact-us-page 
			.df.w100 
				flex-wrap: wrap
				.w60.fs2-5rem
					font-size: 2rem
					padding-left: 0rem !important
					width: 100%
					text-align: center
					justify-content: center
					margin-bottom: 38px
		.contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df
			zoom: .5
		.beta-logo
			top: 21%
			left: 23%
		.contact-us-page .df.w100 .w35
			width: 100% !important
			justify-content: end
			display: flex
			text-align: end
			padding: 0
		.contact-us-page .df.w100 .w35 img
			width: 100%
		.contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df
			zoom: .3
		.contact-us-page .df.w100 .w60.fs2-5rem
			font-size: 1.5rem
		.contact-us-page .df.w100 .w60.fs2-5rem
			font-size: 1.2rem
		.contact-us-page .pl10rem.pr10rem.pt5rem.pb5rem.w100, .contact-us-page .bg20.pt3rem.pb8rem.px10rem.w100.df
			zoom: .2
			padding-left: 2rem
		.contact-us-page img.pa.ar
			zoom: 0.4
		.team-sec .team-about-column .team-box .card-style-about, .poppins-font-style .df.w100 p.bg6.c1.fw600.br15px
			zoom: 1 !important
		.team-sec .team-about-column .team-box .team-img
			width: 100%
		.team-sec .team-about-column .team-box .card-style-about .fs2rem, .poppins-font-style .df.w100 p.bg6.c1.fw600.br15px .fs2rem
			font-size: 1.5rem
		.right-img-area .person-img
			height: auto !important
		.comapnies-page-font .main-banner-container, .accounts-page-font .main-banner-container, .lawyers-page-font .main-banner-container, .vcs-page-font .main-banner-container, .pes-page-font .main-banner-container, .angelsnetwork-page-font .main-banner-container, .angels-page-font .main-banner-container
			height: 759px !important
		.accounts-page-font .automated-sec .menu-bottom, .vcs-page-font .automated-sec .menu-bottom
			margin-top: -93px !important
		.mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80
			zoom: 0.5
		.mah90.oya.bg20.shadow.w100.pt3rem.pl3rem.pr3rem.pb2rem.bsbb.df.fdc.m1rem.br20px.pr.maw80 button
			margin-right: 0 !important
		.poppins-font-style
			overflow: visible
		.backdrop.td500.zi4.pf.t0.l0.w100vw.h100vh.df.jcc.aic.MuiModal-root.css-79ws1d-MuiModal-root svg.pa.cp
			bottom: 50px !important
		.register-not-sure-sec .not-sure-register .not-sure-container .fdc .scaleAnimation
			margin: 104px 50px 0
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem
			width: 100%
			padding-top: 4rem
			flex-direction: row
			flex-wrap: wrap
			padding-left: 3rem
		.keyfeature-box-portfolio-innner .keyfeature-box-portfolio-bg .w30.pt8rem p
			width: 100%
		#homeMainBanner .mt4rem .fs2-5rem
			font-size: 1.1rem
		.cookies-box.p3rem
			padding: 10px 20px
		.cookies-box.p3rem .c15.fs1-5rem.mb1rem
			margin-bottom: 5px
		.cookies-box.p3rem .c14.pb1rem
			font-size: 12px
			padding-bottom: 5px
		.cookies-box.p3rem .pt2rem.pb1rem.divedier
			padding: 10px 0px 0px
		.cookies-box.p3rem button
			padding: 10px
			font-size: 14px
			margin-top: 15px
